import React, { useState, useEffect, useRef, useReducer } from 'react';
import { Modal } from 'react-bootstrap'
import ReactExport from 'react-export-excel-xlsx-fix';
import moment from 'moment'
import _ from 'lodash'
import { allStockFieldsVM, objStock } from '../stock/viewModel'
import CommonHelper from '../../services/common';
import { ContentOverlay, TableView, SidePanel, PopUpModal } from '../../components'
import toast from 'toasted-notes'
import { StageProvider, StageConsumer } from '../pipeline/provider'
import QuickView from "../pipeline/quickview";
import _images from '../../images'
import Swal from 'sweetalert2'
import ReAuthenticate from '../common/reAuthenticate';

import Filters from '../stock/filters'
import Translate from '../../constants/translate';
import AddStock from '../stock/add';
import StockHistory from '../stock/history';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const StockList = (props) => {
    const csvBtn = useRef();
    const csvDirectBtn = useRef();
    const [hasMore, setHasMore] = useState([1])
    const [allstockFields, setAllStockFields] = useState(allStockFieldsVM)
    const [stocks, setStocks] = useState([])
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 190), windowHeight: (window.innerHeight - 190) })
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [stockFields, setStockFields] = useState(props.dealersettings.stockFields)

    const [searchText, setSearchText] = useState('')
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview' })
    const [filterpageNum, setfilterPageNum] = useState(0);
    const [activityFilter, setactivityFilter] = useState({})
    const [searchKeywords, setSearchKeywords] = useState('')
    const [customFilters, setCustomFilters] = useState([])
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [modelShow, setModelShow] = useState({
        show: false,
        stockID: '',
        title: '',
        clsActive: ''
    })
    const [csvData, setCSVData] = useState([])
    const _moduleSettings = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.moduleSettings)) ? props.dealersettings.client.moduleSettings : null);
    const [csvHeader, setHeader] = useState([])
    const [pageNum, setPageNum] = useState(0);
    const [loader, setLoader] = useState(false);
    const [showReAuthentication, setReAuthentication] = useState(false);
    const [sortConfig, setSortConfig] = useState();
    const [tableLoader, setTableLoader] = useState(false)
    const [editDocumentID, setEditDocument] = useState()
    const [modelHistoryShow, setModelHistoryShow] = useState(false)
    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ? props.dealersettings.rolePermissions.permissions : null);
    const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);
    const deleteStock = ((!_.isEmpty(_permissions) && _permissions.deleteStock) ? true : false);
    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');
    const vbmcurrencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.vbm)) ? props.dealersettings.client.vbm.symbol + ' ' : '$ ');
    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;


    const fetchAct = async (type = 'generic', _pageNum, localFilter, _sortConfig) => {

        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled && type === 'excel') {
            handleReAuthOpen();
            return;
        }

        let num = _pageNum
        let pageLimit = props.counts ? props.counts > 49 ? 50 : props.counts : 50
        const filters = props.filter
        // on export fetch success csvBtn.current && csvBtn.current.link.click();
        // console.log('filters', props.filters)
        let _filter = {
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        }
        if (filters && filters.documentIDs) {
            _filter = {
                documentIDs: filters.documentIDs.join(',')
            }
        }

        if (props?.dealersettings?.group?.enableSharedStock && props.dealersettings.client.group) {
            _filter.groupID = props.dealersettings.client.group;
        }
        if (!_.isEmpty(localFilter)) {
            _filter = { ..._filter, ...localFilter }
            // num = 0
            pageLimit = 50
        }
        let _searchObject = {
            "type": "searchStock",
            "filters": JSON.stringify(_filter),
            "sortOrder": !_.isEmpty(_sortConfig) ? `${_sortConfig.key} ${_sortConfig.sort}` : `modifiedDate desc`,
            "pageNum": num,
            pageLimit
        }
        if (type === 'excel') {
            _searchObject.pageLimit = (!props.counts || props.counts > 999) ? 1000 : props.counts
            _searchObject.pageNum = 0
            toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> {CommonHelper.showLocale(props, 'downloading...')}</div>),
                {
                    position: 'top',
                    duration: null
                },
            )
        }
        if (props?.dealersettings?.group?.enableSharedStock && props.dealersettings.client.group)
            _searchObject.groupID = props.dealersettings.client.group
        else
            _searchObject.clientID = props.dealersettings.client.id;
        if (props.fromOEM === true && props?.dealersettings?.clients?.length > 0) {
            _searchObject.clientID = props?.dashboardFilter?.clients?.length > 0 ? props.dashboardFilter.clients.join(',') :
                props.dealersettings.clients.filter(a => a.settingsID === props.dealersettings.oemID).map(r => { return r.id }).join(',');
        }
        if (type === 'generic' && _.isEmpty(_sortConfig)) {
            setLoader(true)
        }
        const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchEnquiries(_searchObject);
        //console.log('generic-searchData', _rec, response);
        if (resp.data.success) {
            //  console.log('resp', resp)
            let _pageNum = num + 1;
            let stocks_ = resp.data.data.map(a => dataMappingVM(a))
            if (!_.isEmpty(localFilter)) {
                setfilterPageNum(_pageNum)
            } else {
                setPageNum(_pageNum)
            }
            if (type === 'excel') {
                setStocks(stocks_)
                setCSVData(CommonHelper.generateExcelData(stocks_, csvHeader, props))
                setTimeout(() => {csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload();}, 1000)
                Swal.fire({
                    icon: 'success',
                    title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                    showConfirmButton: false,
                    timer: 1500
                })
                toast.closeAll();

                let _objLogData = {
                    notes: 'Excel export from dashboard - stock',
                    type: 'excelExport',
                    subType: 'stock',
                    params: JSON.stringify(_filter),
                }
                _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
                CommonHelper.saveAuditLog(_objLogData);

            } else {
                if (type === 'paging') {
                    setStocks(_.uniqBy([...stocks, ...stocks_], 'documentID'))
                } else {
                    setStocks(stocks_)
                }
            }
            if (type === 'generic') {
                setLoader(false)
            }
        }
        else {
            toast.closeAll();
            Swal.fire(CommonHelper.showLocale(props, 'Something went wrong.'));
            if (type === 'generic') {
                setLoader(false)
            }
        }
        setTableLoader(false)
    }

    const fetchDirectExcel = () => {
        console.log('fetchDirectExcel');
        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        setTimeout(() => { csvDirectBtn.current && csvDirectBtn.current.handleDownload && csvDirectBtn.current.handleDownload(); }, 1000)
    }

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    //#endregion

    useEffect(() => {
        if (props.isFromDashboard) {
            fetchAct('generic', pageNum)
        }
    }, [])

    useEffect(() => {
        let headerFields = stockFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allstockFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allstockFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allstockFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText })
            }
        })
        setHeader(_headers);

    }, [stockFields])

    useEffect(() => {
        setAllStockFields(_.map(allstockFields, function (obj) {
            return obj.value === 'settings' ? {
                ...obj,
                width: 120,
            } : {
                ...obj
            }
        }))
    }, [])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 190),
                windowHeight: (window.innerHeight - 190)
            })
            document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])


    const handleSavesettings = (fields, showToast) => {
        if (!showToast)
            setLoader(true)
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}`)
            .set({ stockFields: fields }, { merge: true })
            .then(() => {
                setStockFields(fields)
                setSettingsLoader(false)
                setLoader(false)
                props.updateDealerSettings('stockFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                setLoader(false)
                console.error(error)
            });

    }

    const handleActionClick = (id, data) => {
        return (
            <div >
                <div
                    className="mini-button cursor-pointer"
                    onClick={(e) => {
                        setEditDocument(id);
                        setModelHistoryShow(true);
                    }}>
                    <i className="ico icon-history"></i>
                </div>

                {
                    readOnlyEnquiryView
                        ?
                        <>
                            <div
                                className="mini-button cursor-pointer ml-2"
                                onClick={(e) => {
                                    setEditDocument(id);
                                    setModelShow(true);
                                }}>
                                <i className="ico icon-zoom"></i>
                            </div>
                        </>
                        :
                        <>
                            <div
                                className="mini-button cursor-pointer ml-2"
                                onClick={(e) => {
                                    setEditDocument(id);
                                    setModelShow(true);
                                }}>
                                <i className="ico icon-edit"></i>
                            </div>
                        </>
                }

                {
                    deleteStock
                        ?
                        <>
                            <div
                                className="mini-button cursor-pointer ml-2"
                                onClick={async (e) => {
                                    handleDeleteStock(id, data);
                                }}
                            >
                                <i className="ico icon-delete"></i>
                            </div>

                        </>
                        :
                        <></>
                }

            </div>
        )
    }

    const handleDetailclick = (e, id, data) => {
        // handleEditStock(data)
    }

    const handleDeleteStock = async (id, data) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete the stock.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(async (result) => {
            if (result.value) {

                let response = await props.handleCheckStock(id);
                //console.log("response", id, response)
                if (response.success) {
                    let _dataType = response.type + (response.id ? ' (' + response.id + ')' : '');
                    Swal.fire(CommonHelper.showLocale(props, 'Cannot delete stock: It is being used in ') + _dataType + '.', '', 'info')
                    return false;
                }
                else {
                    const { dealersettings } = props;
                    let modifiedBy = dealersettings ? dealersettings.id : '';
                    let modifiedDate = window.firebase.firestore.Timestamp.now();

                    const updateRef = window.firebase.firestore().collection(`stock`).doc(id)
                    updateRef.
                        set({
                            isDeleted: true,
                            modifiedFrom: 'web',
                            modifiedBy,
                            modifiedDate
                        }, { merge: true })
                        .then((docRef) => {
                            toast.notify('Stock successfully deleted.', {
                                duration: 2000
                            })


                            if (!_.isEmpty(data)) {
                                setStocks(stocks.filter(a => a.documentID !== id))
                            }


                            let logNotes = 'Stock deleted';
                            if (!_.isEmpty(data) && data.stockNo)
                                logNotes = 'Stock#: ' + data.stockNo + ' deleted';

                            let _objLogData = {
                                notes: logNotes,
                                type: 'stock',
                                subType: 'delete',
                                recordId: id,
                            }
                            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
                            CommonHelper.saveAuditLog(_objLogData);

                        }).catch((error) => {
                            console.error("Error deleting Video: ", error);
                        });
                }
            }
        })
    }



    const handlePagination = () => {
        // console.log('pagination clicked')
        // console.log('run pagination')
        if (!_.isEmpty(activityFilter) && !_.isEmpty(activityFilter.value)) {
            fetchAct('paging', filterpageNum, activityFilter.value)
        } else {
            if (stocks.length >= props.counts) {
                return
            }
            fetchAct('paging', pageNum)
        }
    }

    const handleApplyFilter = (_filter) => {
        //  console.log('handleApplyFilter', _filter)
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            fetchAct('generic', 0, _filter.value)
            setactivityFilter(_filter)
            setFilterApplied(true);
            setPageNum(0);
            setfilterPageNum(0)
            if (_filter.value.hasOwnProperty('keywords')) {
                setSearchText(_filter.value.keywords);
            } else {
                setSearchText('');
            }
            // localStorage.enquirySearchText = '';
            // setDataload(true);
            // setPaging(false);
            // setHasMoreData([])
            setSortConfig(null)
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        //   console.log('handleClearFilter')
        if (!_.isEmpty(activityFilter)) {
            setactivityFilter({})
            fetchAct('generic', pageNum)

            // localStorage.removeItem('activityFilter');
            // localStorage.enquirySearchText = '';
            setFilterApplied(false);
            // setDataload(true);
            // setPaging(false);
            // setHasMoreData([])
            setPageNum(0);
            setfilterPageNum(0)
            setSearchText('');
            setSortConfig(null)
        }
    }

    const handlesearchText = (text) => {
        //  console.log('handlesearchText', text)
        if (text) {
            fetchAct('generic', 0, { keywords: text })
            setactivityFilter({
                name: 'Adv Filter',
                type: 'filter',
                value: { keywords: text }
            })
            setPageNum(0);
            setfilterPageNum(0)
        } else {
            setPageNum(0);
            setfilterPageNum(0)
            setactivityFilter({})
            fetchAct('generic', pageNum)
        }
    }

    const handleDynamicSort = (sortConfig) => {
        setSortConfig(sortConfig);
        setTableLoader(true);
        setPageNum(0);
        if (!_.isEmpty(activityFilter) && !_.isEmpty(activityFilter.value)) {
            fetchAct('generic', 0, activityFilter.value, sortConfig)
        } else {
            fetchAct('generic', 0, null, sortConfig)
        }
    }
    const exportData = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.exportData) ? true : false);

    const dataMappingVM = (doc) => {
        let dealersettings = props.dealersettings;
        doc.regDate = doc.regDate && doc.regDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.regDate._seconds)._d) : doc.regDate;
        doc.regExp = doc.regExp && doc.regExp._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.regExp._seconds)._d) : doc.regExp;
        doc.warrantyStartDate = doc.warrantyStartDate && doc.warrantyStartDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.warrantyStartDate._seconds)._d) : doc.warrantyStartDate;
        doc.warrantyExp = doc.warrantyExp && doc.warrantyExp._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.warrantyExp._seconds)._d) : doc.warrantyExp;
        doc.stockInDate = doc.stockInDate && doc.stockInDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.stockInDate._seconds)._d) : doc.stockInDate;
        doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;


        doc.etaDate = doc.etaDate && doc.etaDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.etaDate._seconds)._d) : doc.etaDate;
        doc.fokDate = doc.fokDate && doc.fokDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.fokDate._seconds)._d) : doc.fokDate;
        doc.soldDate = doc.soldDate && doc.soldDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.soldDate._seconds)._d) : doc.soldDate;
        doc.deliveredDate = doc.deliveredDate && doc.deliveredDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveredDate._seconds)._d) : doc.deliveredDate;
        doc.deliveryDate = doc.deliveryDate && doc.deliveryDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveryDate._seconds)._d) : doc.deliveryDate;
        doc.firstEnqDate = doc.firstEnqDate && doc.firstEnqDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.firstEnqDate._seconds)._d) : doc.firstEnqDate;

        const objStockData = Object.assign({}, doc);
        const stock = Object.assign({}, objStock);
        for (let [key, value] of Object.entries(objStockData)) {
            stock[key] = value;
        }
        if (stock.clientID && (stock.clientID !== dealersettings.client.id) && dealersettings.group) {
            stock.dealerName = CommonHelper.getOtherDealerName(dealersettings, stock.clientID);
        }
        else
            stock.dealerName = '';

        stock.objData = objStockData;

        stock.regDateValue = doc.regDate
        stock.regExpValue = doc.regExp
        stock.warrantyExpValue = doc.warrantyExp
        stock.warrantyStartDateValue = doc.warrantyStartDate
        stock.stockInDateValue = doc.stockInDate
        stock.addedDateValue = doc.addedDate
        stock.modifiedDateValue = doc.modifiedDate
        stock.addedByValue = doc.addedBy
        stock.statusValue = doc.status
        stock.saleTypeValue = doc.saleType
        stock.locationValue = doc.location
        stock.usageValue = doc.usage
        stock.transmissionValue = doc.transmission
        stock.transportMethodValue = doc.transportMethod
        stock.createdOn = moment.unix(stock.addedDate.seconds).format('DD/MM/YYYY hh:mm A');
        stock.updatedOn = moment.unix(stock.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A');

        stock.firstEnquiryOn = stock.firstEnqDate ? moment.unix(stock.firstEnqDate.seconds).format('DD/MM/YYYY') : null;

        if (stock.clientID && dealersettings && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[stock.clientID] && dealersettings.group.clients[stock.clientID].settings) {
            let _settings = dealersettings.group.clients[stock.clientID].settings;
            stock.location = CommonHelper.getNameByValue(_settings.stockLocation, stock.location, '');
            stock.usage = CommonHelper.getNameByValue(_settings.stockUsage, stock.usage, '');
            stock.transmission = CommonHelper.getNameByValue(_settings.transmissions, stock.transmission, '');
            stock.transportMethod = CommonHelper.getNameByValue(_settings.stockTransport, stock.transportMethod, '');
            stock.saleType = CommonHelper.getNameByValue(_settings.salesType, stock.saleType, '');
        }
        else if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            stock.location = CommonHelper.getNameByValue(dealersettings.client.settings.stockLocation, stock.location, '');
            stock.usage = CommonHelper.getNameByValue(dealersettings.client.settings.stockUsage, stock.usage, '');
            stock.transmission = CommonHelper.getNameByValue(dealersettings.client.settings.transmissions, stock.transmission, '');
            stock.transportMethod = CommonHelper.getNameByValue(dealersettings.client.settings.stockTransport, stock.transportMethod, '');
            stock.saleType = CommonHelper.getNameByValue(dealersettings.client.settings.salesType, stock.saleType, '');
        }
        const hideStockPrice = (dealersettings?.rolePermissions?.permissions?.hideStockPrice) ? true : false;
        stock.purchasePriceValue = doc.purchasePrice ? doc.purchasePrice : '';
        stock.priceValue = doc.price ? doc.price : '';
        stock.price = doc.price ? CommonHelper.formatCurrency(currencySymbol, doc.price, hideStockPrice) : '';
        stock.purchasePrice = doc.purchasePrice ? CommonHelper.formatCurrency(currencySymbol, doc.purchasePrice, hideStockPrice) : '';

        stock.marginValue = doc.marginValue ? CommonHelper.formatCurrency(vbmcurrencySymbol, doc.marginValue) : '';
        stock.marginRetail = doc.marginRetail ? CommonHelper.formatCurrency(vbmcurrencySymbol, doc.marginRetail) : '';
        stock.marginOrderFill = doc.marginOrderFill ? CommonHelper.formatCurrency(vbmcurrencySymbol, doc.marginOrderFill) : '';
        stock.marginPayments = doc.marginPayments ? CommonHelper.formatCurrency(vbmcurrencySymbol, doc.marginPayments) : '';
        stock.marginMarketing = doc.marginMarketing ? CommonHelper.formatCurrency(vbmcurrencySymbol, doc.marginMarketing) : '';

        stock.stockAge = ((!_.isEmpty(stock.stockInDateValue))
            ?
            handleDiffDays(stock.stockInDateValue)
            :
            '');


        if (!_.isEmpty(stock.regDate))
            stock.regDate = moment.unix(stock.regDate.seconds).format('DD/MM/YYYY');

        if (!_.isEmpty(stock.regExp))
            stock.regExp = moment.unix(stock.regExp.seconds).format('DD/MM/YYYY');

        if (!_.isEmpty(stock.warrantyExp))
            stock.warrantyExp = moment.unix(stock.warrantyExp.seconds).format('DD/MM/YYYY');

        if (!_.isEmpty(stock.warrantyStartDate))
            stock.warrantyStartDate = moment.unix(stock.warrantyStartDate.seconds).format('DD/MM/YYYY');

        if (!_.isEmpty(stock.buildDate))
            stock.buildDate = moment(stock.buildDate, 'YYYY-MM-DD').format('MMM YYYY');

        if (!_.isEmpty(stock.soldDate))
            stock.soldDate = moment.unix(stock.soldDate.seconds).format('DD/MM/YYYY');
        if (!_.isEmpty(stock.deliveredDate))
            stock.deliveredDate = moment.unix(stock.deliveredDate.seconds).format('DD/MM/YYYY');
        if (!_.isEmpty(stock.deliveryDate))
            stock.deliveryDate = moment.unix(stock.deliveryDate.seconds).format('DD/MM/YYYY');
        if (!_.isEmpty(stock.etaDate)) {
            stock.csvEtaDate = moment.unix(stock.etaDate.seconds).format('DD/MM/YYYY') + (stock.isStockArrived ? ' (Arrived)' : '');
            stock.etaDate = <>
                <>{moment.unix(stock.etaDate.seconds).format('DD/MM/YYYY')}</>
                <>{stock.isStockArrived ? <div className="badge badge-pill badge-available badge-mini ml-1">{'ARRIVED'}</div> : <></>}</>
            </>;
        }


        if (!_.isEmpty(stock.fokDate))
            stock.fokDate = moment.unix(stock.fokDate.seconds).format('DD/MM/YYYY');
        if (!_.isEmpty(stock.stockInDate))
            stock.stockInDate = moment.unix(stock.stockInDate.seconds).format('DD/MM/YYYY');

        stock.statusName = CommonHelper.getNameByValue(dealersettings?.client?.settings?.stockStatus, stock.status, '');
        stock.status = CommonHelper.bindStockLabel(dealersettings?.client?.settings?.stockStatus, stock.status, '');
        // stock.status = (stock.status
        //     ?
        //     <div className={`badge badge-pill badge-${stock.status.trim().toLowerCase()}-fill `}>
        //         {stock.status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
        //     </div>
        //     :
        //     <></>);


        stock.availability = (<div className="Vehicle-availability">
            {stock.isTestDrive ? <div><span>{'Test Drive'}</span></div> : <></>}
            {stock.isLoan ? <div><span>{'Loan'}</span></div> : <></>}
            {stock.isServiceLoan ? <div><span>{'Service Loan'}</span></div> : <></>}
            {stock.isSale ? <div><span>{'Sale'}</span></div> : <></>}
        </div>);


        stock.availabileFor = ((stock.isTestDrive ? 'Test Drive, ' : '') +
            (stock.isLoan ? 'Loan, ' : '') +
            (stock.isServiceLoan ? 'Service Loan, ' : '') +
            (stock.isSale ? 'Sale' : ''));

        stock.addedBy = CommonHelper.getUserNamebyId(allUsers, stock.addedBy);
        stock.modifiedBy = CommonHelper.getUserNamebyId(allUsers, stock.modifiedBy);
        stock.statusUpdatedBy = stock.statusModifiedBy ? CommonHelper.getUserNamebyId(allUsers, stock.statusModifiedBy) : '';
        return stock
    }

    const handleHistoryClose = (_stock) => {
        setModelHistoryShow(false);
        setEditDocument(null);
    }

    const handleClose = (id) => {
        setModelShow(false);
        setEditDocument(null);
    }


    const handleDiffDays = (_date) => {
        const difference = moment().diff(moment.unix(_date.seconds), 'days')
        if (difference > 1 || difference < -1)
            return difference + ' Days';
        else
            return difference + ' Day';

    }
    const updateStock = (objStock) => {
        if (!_.isEmpty(objStock)) {
            let _data = props.dataMappingVM(objStock);

            if (!_.isEmpty(_data)) {
                setStocks(stocks.map((item) => {
                    if (item.documentID === _data.documentID) {
                        for (let [key, value] of Object.entries(_data)) {
                            item[key] = value;
                        }
                        return item;
                    }
                    return item;
                }))
            }
        }

    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered modal-dashboard-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="settings-head">
                                <div className="float-left">
                                    {!props.isFromDashboard ? (
                                        <div className="filter-search search-icon">
                                            <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                        </div>
                                    ) : (
                                        <div className={`filter-search`}>
                                            <div className="input-group ">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="isearch"
                                                    placeholder="quick search..."
                                                    value={searchText ? searchText : ''}
                                                    onChange={(e) => {
                                                        let _val = e.target.value
                                                        setSearchText(_val);
                                                        // localStorage.contactSearchText = _val;

                                                        if (_val && _val.length > 2) {
                                                            handlesearchText(_val)
                                                            // console.log('add searchFilter');
                                                        }

                                                        if (!_val) {
                                                            handlesearchText('')
                                                            // console.log('remove searchFilter')
                                                        }

                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            // console.log('add searchFilter')
                                                            // localStorage.contactSearchText = searchText;
                                                            handlesearchText(searchText)
                                                        }
                                                    }}
                                                    autoComplete="off"
                                                />
                                                <div className="input-group-append input-search-clear">
                                                    {
                                                        searchText && searchText.length > 0
                                                            ?
                                                            <a href="#"
                                                                className="input-search-clear-icon"
                                                                title="Clear"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setSearchText('');
                                                                    // console.log('remove searchFilter ')
                                                                    // localStorage.contactSearchText = '';
                                                                    handlesearchText('')
                                                                }}
                                                            >
                                                                <i className="ico icon-remove"></i>
                                                            </a>
                                                            :
                                                            <>
                                                            </>
                                                    }

                                                    <button
                                                        className="btn btn-default"
                                                        type="button"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            // console.log('add searchFilter')
                                                            // localStorage.contactSearchText = searchText;
                                                            handlesearchText(searchText)
                                                        }}>
                                                        <i className="ico icon-search-fill"></i>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    )}
                                </div>
                                <div className="float-right">
                                    {
                                        (exportData)
                                            ?
                                            <>
                                                <div className="filter-item-btn ">
                                                    {props.isFromDashboard ? (
                                                        <>
                                                            <a href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                if (!_.isEmpty(activityFilter) && !_.isEmpty(activityFilter.value)) {
                                                                    fetchAct('excel', filterpageNum, activityFilter.value, sortConfig)
                                                                } else {
                                                                    fetchAct('excel', pageNum, null, sortConfig)
                                                                }
                                                            }}>
                                                                <i className="ico icon-excel-download"></i>
                                                            </a>
                                                            <ExcelFile ref={csvBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`ams-pro-download`}>
                                                                <ExcelSheet dataSet={csvData} name={`Sheet1`} />
                                                            </ExcelFile>
                                                        </>
                                                    ) : (
                                                        <ExcelFile ref={csvDirectBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`ams-pro-download`}>
                                                            <ExcelSheet dataSet={csvData} name={`Sheet1`} />
                                                        </ExcelFile>
                                                    )}
                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }

                                </div>
                                {props.isFromDashboard && (
                                    <Filters
                                        isFromDashboard={true}
                                        searchKeywords={searchKeywords}
                                        stockFilter={activityFilter}
                                        customFilters={customFilters}
                                        dealersettings={props.dealersettings}
                                        handleApplyFilter={handleApplyFilter}
                                        handleClearFilter={handleClearFilter}
                                        clientUsers={props.clientUsers}
                                        groupUsers={props.groupUsers}
                                        showDateFilter={props.showMore}
                                        noSaveAccess={true}
                                        isFilterApplied={isFilterApplied}

                                    >
                                    </Filters>
                                )}
                            </div>
                            {props.isFromDashboard ? loader ? (

                                <>
                                    <div className='loader-center-screen' style={{ height: windowSize.windowHeight, width: windowSize.windowWidth }}>
                                        <div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
                                    </div>
                                </>
                            ) : (
                                <div className="common-table">
                                    {
                                        tableLoader
                                            ?
                                            <>
                                                <div className="dashboard-widget-loader h-100">
                                                    <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                    <TableView
                                        isReorderable={true}
                                        datalist={stocks}
                                        height={windowSize.windowHeight}
                                        width={windowSize.windowWidth}
                                        columns={allstockFields}
                                        handleSavesettings={handleSavesettings}
                                        dynamicFields={(stockFields && stockFields.length > 0) ? stockFields.filter(item => item !== 'checkbox') : allstockFields.filter(e => e.default === true && e.value !== 'checkbox').map(v => _.pick(v, ['value']).value)}
                                        settingsLoader={settingsLoader}
                                        handleActionClick={handleActionClick}
                                        handleRowSingleClick={handleDetailclick}
                                        isSettings={true}
                                        hasMore={hasMore}
                                        handlePagination={handlePagination}
                                        isPaging={true}
                                        isSorting={true}
                                        dynamicSort={true}
                                        handleDynamicSort={handleDynamicSort}
                                        sortConfig={sortConfig}
                                        displayByValue={true}
                                    />
                                </div>
                            ) : (
                                <div className="common-table">
                                    <TableView
                                        isReorderable={true}
                                        datalist={_.orderBy(stocks, ['addedDateValue'], ['desc'])}
                                        height={windowSize.windowHeight}
                                        width={windowSize.windowWidth}
                                        columns={allstockFields}
                                        handleSavesettings={handleSavesettings}
                                        dynamicFields={(stockFields && stockFields.length > 0) ? stockFields.filter(item => item !== 'checkbox') : allstockFields.filter(e => e.default === true && e.value !== 'checkbox').map(v => _.pick(v, ['value']).value)}
                                        settingsLoader={settingsLoader}
                                        handleActionClick={handleActionClick}
                                        handleRowSingleClick={handleDetailclick}
                                        isSettings={true}
                                        displayByValue={true}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <PopUpModal show={modelShow}>
                <AddStock
                    docID={editDocumentID}
                    show={modelShow}
                    clsActive='overlay-modal active'
                    handleClose={handleClose}
                    title={editDocumentID ? 'stock' : 'addStock'}
                    enableSale={false}
                    enableTestDrive={false}
                    enableLoan={false}
                    enableServiceLoan={!editDocumentID && localStorage.defaultModule === 'service' ? true : false}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    isFilterApplied={true}
                    updateStock={updateStock}
                >
                </AddStock>
            </PopUpModal>
            <PopUpModal show={modelHistoryShow}>
                <StockHistory
                    docID={editDocumentID}
                    show={modelHistoryShow}
                    clsActive='overlay-modal active'
                    handleClose={handleHistoryClose}
                    title={'stockHistory'}
                    dealersettings={props.dealersettings}
                    updateDealerSettings={props.updateDealerSettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    history={props.history}
                >
                </StockHistory>
            </PopUpModal>
        </>
    );
}

export default StockList;