import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { Dropdown } from 'react-bootstrap'
import moment from 'moment'
import _images from '../../../images'
import { CustomToggle, CustomMenu } from '../../../components/customdropdown';
//import ReactExport from 'react-data-export';
import Swal from 'sweetalert2'
import cronstrue from 'cronstrue';
import { AntMonthPicker, BarChart, ContentOverlay, PopUpModal, ReactMultiSelect } from '../../../components';
import EnquiryList from '../../dashboard/enquiryList'
import { columnValues, saleType } from './viewModel';

const YearlyPerformance = (props) => {
    const [loader, setLoader] = useState(true)
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('year')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('year')._d),
        date: moment().format('YYYY-MM-DD'),
        year: moment().year()
    })
    const [modelShow, setModelShow] = useState({
        enquiry: false,
        testdrive: false,
        activity: false
    })
    const [refreshData, setRefreshData] = useState(true)
    const node = useRef();
    const [csvData, setCSVData] = useState([])

    const [schedueReport, setSchedeReport] = useState({});
    const [multiSchedule, setMultiSchedule] = useState([]);
    const [popover, setPopover] = useState({ show: false, target: null })
    const [allData, setAllData] = useState([]);
    const [reportData, setReportData] = useState([])
    const [reportDoc, setReportDoc] = useState({})
    const [makes, setMakes] = useState([])
    const [counts, setCounts] = useState([])
    const [filter, setFilter] = useState(null)
    const [objFilter, setObjFilter] = useState({})


    useEffect(() => {
        if (_.isEmpty(props.dealersettings.clients))
            return

        const _makes = [];
        props.dealersettings.clients.forEach(client => {
            if (client?.brandIDs?.length) {
                _makes.push(...client.brandIDs.map(r => { return _.find(props.dealersettings.allMakeModels, { documentID: r }) }))
            }
        })
        setMakes(_.uniqBy(_makes.filter(a => a?.value), 'value'))
    }, [])

    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
        return () => {
            document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
        }
    }, [])

    useEffect(() => {

        const refScheduleReport = window.firebase.firestore().doc(`schedular/yearlyPerformance-${props.dealersettings.oemID}`)
            .onSnapshot(doc => {
                if (doc.exists && doc.data().enabled === true && !_.isEmpty(doc.data().cron)) {
                    setSchedeReport(doc.data())
                    if (doc.data().multiSchedule?.length > 0) {
                        let refPromise = [];
                        doc.data().multiSchedule.forEach(rpt => {
                            refPromise.push(window.firebase.firestore().doc(`schedular/${rpt}`).get())
                        })
                        Promise.all(refPromise)
                            .then(docs => {
                                let rpts = [];
                                docs.forEach(doc => {
                                    if (doc.exists) {
                                        let arrCron = [];
                                        if (doc.data().cron && doc.data().cron !== '* * * * *') {
                                            arrCron = doc.data().cron.split(' ');
                                        }
                                        rpts.push({
                                            ...doc.data(),
                                            schedulartime: arrCron.length > 0 ?
                                                window.firebase.firestore.Timestamp.fromDate(new Date(`${moment().format('YYYY-MM-DD')} ${arrCron[1]}:${arrCron[0]}`)) : null
                                        })
                                    }
                                })
                                setMultiSchedule([
                                    doc.data(),
                                    ...rpts
                                ]);
                            })
                    }
                }
                else {
                    setSchedeReport(null)
                }
            })
        return () => {
            refScheduleReport && refScheduleReport();
        }

    }, [])

    useEffect(() => {
        if (!refreshData)
            return

        setLoader(true)
        try {
            const reportData = window.firebase.functions().httpsCallable('generic-getData');
            reportData({
                type: 'getOEMSummaryReport',
                params: JSON.stringify({
                    clientID: props.dealersettings.clients.map(client => client.id).join(','),
                    timezone: props?.dealersettings?.client?.timezone || moment.tz.guess(),
                    startDate: moment.unix(dateRange.startDate.seconds).format('YYYY-MM-DD'),
                    endDate: moment.unix(dateRange.endDate.seconds).format('YYYY-MM-DD'),
                    wonLead: true,
                    newLead: true
                })
            }).then((response) => {
                setLoader(false)
                setRefreshData(false)
                if (response.data.success) {
                    setAllData(response.data.data)
                }
                else {
                    Swal.fire('Something went wrong', '', 'error')
                }
            });
        }
        catch (error) {
            setLoader(false)
            Swal.fire('Something went wrong', '', 'error')
        }
    }, [refreshData])

    useEffect(() => {
        if (refreshData)
            return

        const _allData = allData.filter(a => (objFilter?.clientID?.length ? objFilter.clientID.includes(a.cid) : true));
        let _data = [];
        let _saleType = saleType;
        if (props?.dealersettings?.client?.settings?.salesType?.some(a => a.value !== 'New' && a.value !== 'Preowned' && a.value !== 'Demo' && a.active === true)) {
            _saleType.push(...props?.dealersettings?.client?.settings?.salesType?.filter(a => a.value !== 'New' && a.value !== 'Preowned' && a.value !== 'Demo' && a.active === true).map(r => { return r.value }))
        }
        makes.filter(a => a?.value &&
            (objFilter?.make?.length ? objFilter.make.includes(a.value) : true)
        ).forEach(mk => {
            let i = 0;
            _saleType.forEach(rec => {
                _data.push({
                    title: `${mk.name.toUpperCase()} ${_.find(props?.dealersettings?.client?.settings?.salesType, { value: rec })?.name?.toUpperCase() || rec.toUpperCase()}`,
                    months: moment.monthsShort().map(month => {
                        const _startDate = moment(`${dateRange.year} ${month}`).startOf('month').unix();
                        const _endDate = moment(`${dateRange.year} ${month}`).endOf('month').unix();
                        const leadCount = checkDataFilter(_allData.filter((a) => a.col === 'enq' && a.mk === mk.value), _startDate, _endDate, rec);
                        const walkinCount = checkDataFilter(_allData.filter((a) => a.col === 'wk' && a.mk === mk.value), _startDate, _endDate, rec);
                        const wonCount = checkDataFilter(_allData.filter((a) => a.col === 'enq' && a.mk === mk.value), _startDate, _endDate, rec, 'sold', 'wd');
                        return moment(`${dateRange.year} ${month}`).month() <= moment().month() || dateRange.year !== moment().year() ? {
                            title: month.toUpperCase(),
                            leadCount: leadCount.actual,
                            walkinCount: walkinCount.actual,
                            wonCount: wonCount.actual,
                            arrleadCount: leadCount.arractual,
                            arrwalkinCount: walkinCount.arractual,
                            arrwonCount: wonCount.arractual,
                            conv: leadCount.actual > 0 ? Math.round((100 / leadCount.actual) * wonCount.actual) : 0
                        } : {
                            title: month.toUpperCase(),
                            leadCount: null,
                            walkinCount: null,
                            wonCount: null,
                            arrleadCount: [],
                            arrwalkinCount: [],
                            arrwonCount: [],
                            conv: null
                        }
                    })
                })

            });
        })
        if (_data.length) {
            const totalMonths = [];
            _data.map(r => totalMonths.push(...r.months))
            _data.push({
                title: 'TOTAL',
                months: _.chain(totalMonths)
                    .groupBy('title')
                    .map((value, key) => (moment(`${dateRange.year} ${key}`).month() <= moment().month() || dateRange.year !== moment().year() ? {
                        title: key,
                        leadCount: _.sumBy(value, 'leadCount'),
                        walkinCount: _.sumBy(value, 'walkinCount'),
                        wonCount: _.sumBy(value, 'wonCount'),
                        arrleadCount: arrayUnion(value, 'arrleadCount'),
                        arrwalkinCount: arrayUnion(value, 'arrwalkinCount'),
                        arrwonCount: arrayUnion(value, 'arrwonCount'),
                        conv: _.sumBy(value, 'leadCount') > 0 ? Math.round((100 / _.sumBy(value, 'leadCount')) * _.sumBy(value, 'wonCount')) : 0
                    } : {
                        title: key,
                        leadCount: null,
                        walkinCount: null,
                        wonCount: null,
                        arrleadCount: [],
                        arrwalkinCount: [],
                        arrwonCount: [],
                        conv: null
                    }))
                    .value()
            })
        }
        _data = _data.map(r => {
            return {
                ...r,
                months: r.months.map(a => {
                    return {
                        ...a,
                        conv: a.conv > 100 ? '100%' : _.isNull(a.conv) ? null : `${a.conv}%`
                    }
                })
            }
        })
        setReportData(_data);
    }, [allData, objFilter])

    const arrayUnion = (arr, val) => {
        const _arr = [];
        arr.forEach(rec => {
            _arr.push(...(rec[val] || []))
        })
        return _.uniq(_arr);
    }

    const checkDataFilter = (_data, startDt, endDt, saleType, status, dateType, newlead) => {
        const filterData = status === 'sold' || status === 'delivered' ? _.map(_data.filter((a) =>
            ((status === 'sold') ? (a.sts && (a.sts.toLowerCase() === 'won' || a.sts.toLowerCase() === 'delivered')) : true)
            && ((status === 'delivered') ? (a.sts && a.sts.toLowerCase() === 'delivered') : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((saleType !== 'new' && saleType !== 'used') ? (a.st === saleType) : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        )) : _.map(_data.filter((a) =>
            ((status === 'lost') ? (a.sts && (a.sts.toLowerCase() === 'lost' || a.sts.toLowerCase() === 'prospectlost' || a.sts.toLowerCase() === 'pendinglost')) : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((saleType !== 'new' && saleType !== 'used') ? (a.st === saleType) : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        ));
        return {
            arractual: _.uniqBy(filterData, 'eid').map(r => { return r.eid }),
            actual: status === 'sold' || status === 'delivered' ? _.sumBy(_.uniqBy(filterData, 'eid'), 'wc') : _.uniqBy(filterData, 'eid').length
        }
    }

    const handleModelClose = () => {
        setFilter(null)
        setModelShow({
            enquiry: false
        })
    }

    const handleReactMultiSelectChange = (selectedOptions, name) => {
        const value = [];
        const brandIDs = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value);
            if (data?.brandIDs?.length) {
                brandIDs.push(...data.brandIDs);
            }
        })
        setLoader(true)
        setTimeout(() => {
            setLoader(false)
        }, 1000);
        if (brandIDs?.length) {
            setObjFilter({
                ...objFilter,
                [name]: value,
                ['make']: _.uniq(brandIDs)
            });
        } else {
            setObjFilter({
                ...objFilter,
                [name]: value
            });
        }
    }

    return (
        <>
            <div className="middle-wrapper">
                <div className="filter-panel">
                    <div className="float-left fleet-head">
                        <h3>Yearly Conversion Performance Report</h3>
                    </div>
                    <Dropdown className='ml-2 float-right'>
                        <Dropdown.Toggle as={CustomToggle} className='common-button'>
                            <i className='ico icon-more'></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={CustomMenu}
                            ChildClass="more-dropdown dropsub-fix"
                            xplacement="bottom-end"
                        >
                            <Dropdown.Item eventKey="4" as={'div'} bsPrefix={'li-'}>

                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    let url = `/dynamic/report/yearlyperformance/${props.dealersettings.client.id}`;
                                    url += `?year=${dateRange.year}&clientids=${props.dealersettings.clients.filter(a => objFilter?.clientID?.length ? objFilter.clientID.includes(a.id) : true).map(client => client.id).join(',')}`;
                                    url += `&makes=${makes.filter(a => objFilter?.make?.length ? objFilter.make.includes(a.value) : true).map(client => client.documentID).join(',')}`;
                                    window.open(url, '_blank');
                                }} title={'Pdf Report'}>
                                    <i className="ico icon-files-pdf"></i> Pdf Report
                                </a>


                            </Dropdown.Item>
                            {/* <Dropdown.Item eventKey="4" onClick={(e) => {
                                setModelShow({
                                    ...modelShow,
                                    sheduleReport: true
                                })
                            }}><i className="ico icon-email"></i> Schedule Report </Dropdown.Item>  */}
                        </Dropdown.Menu>
                    </Dropdown>
                    <div className="float-right">
                        <AntMonthPicker
                            value={moment().year(dateRange.year).toDate()}
                            name={'year'}
                            onChange={val => {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(val).startOf('year')._d),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(val).endOf('year')._d),
                                    date: moment(val).format('YYYY-MM-DD'),
                                    year: moment(val).year()
                                })
                                setRefreshData(true)
                            }}
                            placeholder='select year'
                            className={`form-control cursor-pointer`}
                            format={'YYYY'}
                            picker={'year'}
                        />
                    </div>
                    <div className="float-right col-2 pr-0 pl-0 mr-2">
                        <ReactMultiSelect
                            options={makes?.map(r => {
                                return {
                                    label: r.name,
                                    value: r.value
                                }
                            }) || []}
                            name={"make"}
                            placeholder={'select make'}
                            onChange={(option) => handleReactMultiSelectChange(option, 'make')}
                            value={objFilter?.make || []}
                            classNamePrefix={`basic-select`}
                            isMulti={true}
                            isSearchable={true}
                        >
                        </ReactMultiSelect>
                    </div>
                    <div className="float-right col-2 pr-0 pl-0 mr-2">
                        <ReactMultiSelect
                            options={props?.dealersettings?.clients?.map(r => {
                                return {
                                    label: r.name,
                                    value: r.id,
                                    brandIDs: makes?.filter(a => r?.brandIDs?.includes(a.documentID))?.map(a => { return a.value }) || []
                                }
                            }) || []}
                            name={"clientID"}
                            placeholder={'select dealer'}
                            onChange={(option) => handleReactMultiSelectChange(option, 'clientID')}
                            value={objFilter?.clientID || []}
                            classNamePrefix={`basic-select`}
                            isMulti={true}
                            isSearchable={true}
                        >
                        </ReactMultiSelect>
                    </div>
                </div>
                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        <div className="conversion-performance-table-wrap">
                            {
                                reportData.map((data, i) => {
                                    return <div key={i} className={`common-table-outer`}>
                                        <div className='col-common-table-6'>
                                            <div className={`common-table`}>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan="13" className="head-bg-main" scope="col">{data.title}</th>
                                                        </tr>
                                                        <tr>
                                                            <th className="head-light" scope="col" width="16%">MONTH</th>
                                                            {
                                                                data.months.map((month, mi) => {
                                                                    return <th key={mi} scope="col" className="head-light text-center" width="7%">{month.title}</th>

                                                                })
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-left performance-list-bg"><b>ENQUIRIES</b></td>
                                                            {
                                                                data.months.map((month, mi) => {
                                                                    return <td key={mi} className="text-center cursor-pointer"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setFilter({
                                                                                documentIDs: month.arrleadCount || []
                                                                            })
                                                                            setCounts(month?.arrleadCount?.length || 0)
                                                                            if (month?.arrleadCount?.length) {
                                                                                setModelShow({
                                                                                    ...modelShow,
                                                                                    enquiry: true
                                                                                })
                                                                            }
                                                                        }}>
                                                                        {month.leadCount}</td>
                                                                })
                                                            }
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left performance-list-bg"><b>WALK INS </b></td>
                                                            {
                                                                data.months.map((month, mi) => {
                                                                    return <td key={mi} className="text-center cursor-pointer"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setFilter({
                                                                                documentIDs: month.arrwalkinCount || []
                                                                            })
                                                                            setCounts(month?.arrwalkinCount?.length || 0)
                                                                            if (month?.arrwalkinCount?.length) {
                                                                                setModelShow({
                                                                                    ...modelShow,
                                                                                    enquiry: true
                                                                                })
                                                                            }
                                                                        }}
                                                                    >{month.walkinCount}</td>
                                                                })
                                                            }

                                                        </tr>
                                                        <tr>
                                                            <td className="text-left performance-list-bg"><b>SOLD</b> </td>
                                                            {
                                                                data.months.map((month, mi) => {
                                                                    return <td key={mi} className="text-center cursor-pointer"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setFilter({
                                                                                documentIDs: month.arrwonCount || []
                                                                            })
                                                                            setCounts(month?.arrwonCount?.length || 0)
                                                                            if (month?.arrwonCount?.length) {
                                                                                setModelShow({
                                                                                    ...modelShow,
                                                                                    enquiry: true
                                                                                })
                                                                            }
                                                                        }}>{month.wonCount}</td>

                                                                })
                                                            }
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left performance-list-bg"><b>CONVERSION %</b></td>
                                                            {
                                                                data.months.map((month, mi) => {
                                                                    return <td key={mi} className="text-center">{month.conv}</td>
                                                                })
                                                            }
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-common-table-6 ">
                                            <div className="performance-col-report-box">
                                                <BarChart
                                                    id={`performance-${i}`}
                                                    height={`340px`}
                                                    data={data.months}
                                                    columns={columnValues.filter(r => !r.lineChart).map(r => { return r.value })}
                                                    lineColumns={['wonCount']}
                                                    dynamicColumns={columnValues}
                                                    xCategory={'title'}
                                                    calculationType={'count'}
                                                    handleShowEnquiryList={(value, rec) => {
                                                        setFilter({
                                                            documentIDs: rec[`arr${value}`] || []
                                                        })
                                                        setCounts(rec[`arr${value}`]?.length || 0)
                                                        if (rec[`arr${value}`]?.length) {
                                                            setModelShow({
                                                                ...modelShow,
                                                                enquiry: true
                                                            })
                                                        }
                                                    }}
                                                    //title={data.title}
                                                    dynColor={true}
                                                    labelMaxWidth={75}
                                                    hideBullet={true}
                                                    sameYAxis={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                }
            </div>
            <PopUpModal show={modelShow.enquiry}>
                <EnquiryList
                    {...props}
                    show={modelShow.enquiry}
                    counts={counts}
                    handleClose={handleModelClose}
                    title={'Enquiry List'}
                    history={props.history}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    filter={filter}
                    isFromDashboard={true}
                ></EnquiryList>
            </PopUpModal>
        </>
    )
}

export default YearlyPerformance;