import React, { useState, useEffect, useContext } from "react";
import _, { isEmpty } from "lodash";
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";
import toast from "toasted-notes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  ContentOverlay,
  ReactSelect,
  PopUpModal,
  InputText,
} from "../../../../components";
import CommonHelper from "../../../../services/common";
import Translate from "../../../../constants/translate";
import images from "../../../../images";
import RequestForm from "../request";
import Options from "../options";
import DisableModal from "../disableModal";
import UpdateGenericApiModal from "./updateGenericApiModal";
import { default as _images } from "../../../../images";
import { TemplateContext } from "../templateContext";
import { removeParamsFromURL } from "../../../../services/helper";
import EmailIntegration from "../emailIntg";
import GenericIntegration from "../genericApis";
import GoogleSheetIntegration from "../googlesheet";
import { allintegrations } from "../../../../admin/settings/integrations/addOns/viewModel";
import DynOptions from "../options/dynOptions";

const inboundModules = [
  "imotor",
  "autogate",
  "autotrader",
  "dealersolution",
  "drivechat",
  "carsguide",
  "facebook",
  "gubagoo",
  "autoforce",
  "autograb",
  "genericApis",
];

const levelOptions = [
  { value: "oem", label: "OEM", index: 1 },
  { value: "region", label: "Region", index: 2 },
  { value: "group", label: "Group", index: 3 },
  { value: "individual", label: "Individual", index: 4 },
];

const podiumBaseURL = "https://accounts.podium.com/oauth";
const podiumClientID = "92577e33-20c4-4b4a-a703-41bdc6890705";
const podiumState = "amstest";
const podiumScopes =
  "%3D{{read_messages}}%20{{write_messages}}%20{{read_locations}}%20{{write_campaign_messages}}%20{{read_users}}%20{{read_campaigns}}%20{{read_contacts}}%20{{read_organizations}}%20{{write_data_feed_event}}";

// For local use
// const podiumRedirectURIEncoded = 'https%3A%2F%2Flocalhost%3A3000%2Fsettings%2Fintegrations'
// const podiumRedirectURIDecoded = 'https://localhost:3000/settings/integrations'

// For QA use
// const podiumRedirectURIEncoded = 'https%3A%2F%2Fqa.fusionamspro.com%2Fsettings%2Fintegrations'
// const podiumRedirectURIDecoded = 'https://qa.fusionamspro.com/settings/integrations'

// For Live use
const podiumRedirectURIEncoded =
  "https%3A%2F%2Fapp.fusionamspro.com%2Fsettings%2Fintegrations";
const podiumRedirectURIDecoded =
  "https://app.fusionamspro.com/settings/integrations";

const IntegrationAddOns = (props) => {
  const [loader, setLoader] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [modules, setModules] = useState({});
  const [integrations, setIntegrations] = useState({});
  const [btnLoader, setBtnLoader] = useState({});
  const [modalRequestShow, setRequestShow] = useState(false);
  const [modalOptionsShow, setOptionsShow] = useState({
    show: false,
    title: "",
    isEdit: false,
  });
  const [modalDisableShow, setDisableShow] = useState({
    show: false,
    title: "",
  });
  const [moduleToChange, setModuleToChange] = useState({
    module: "",
    checked: null,
  });
  const [options, setOptions] = useState({
    origin: "",
    enqType: "",
  });
  const [integrationKey, setIntegrationKey] = useState("");
  const [reqForm, setReqForm] = useState({});
  const [showUpdateGenericApi, setShowUpdateGenericApi] = useState(false);
  const [modalEmailIntgShow, setEmailIntgShow] = useState({
    show: false,
  });
  const [googleSheetIntgShow, setGoogleSheetIntgShow] = useState({
    show: false,
  });
  const [modalGenericIntgShow, setGenericIntgShow] = useState({
    show: false,
  });
  const [show, setShow] = useState({
    titan: true,
    imotor: true,
    messagemedia: true,
    driva: true,
    digitallicence_nsw: true,
    autogate: true,
    smshub: true,
    cmdotcom: true,
    podium: true,
    autotrader: true,
    dealersolutions: true,
    facebook: true,
    autograb: true,
    drivechat: true,
    carsguide: true,
    erapower: true,
    saleslogs: true,
    mailchimp: true,
    vision6: true,
    evalexpert: true,
    nylas: true,
    adtorqueedge: true,
    adtorqueedgeloan: true,
    appraisal_solutions: true,
    autoit: true,
    pmds: true,
    keyloop: true,
    bentley_cab: true,
    carsales: true,
    conversica: true,
    creativo: true,
    dubizzle: true,
    fca: true,
    ferrari_modis: true,
    gumtree: true,
    heroleads: true,
    c2native: true,
    gubagoo: true,
    autoforce: true,
    amsme: true,
    mattaki: true,
    motorweb: true,
    cap: true,
    red_book: true,
    roi: true,
    salesforce: true,
    shiftdigital: true,
    sms_broadcast: true,
    wildjar: true,
    dubaicars: true,
    aapwebsite: true,
    projectcoavme: true,
    nexa: true,
    gforces: true,
    aircall: true,
    ringcentral: true,
    ppsr: true,
    d365: true,
    mailjet: true,
    justcall: true,
    revolution: true,
    impel: true,
    tss: true,
    pandadoc: true
  });
  const [nylasDisableLoader, setNylasDisableLoader] = useState(false);
  const [podiumEnableLoader, setPodiumEnableLoader] = useState(false);
  const [currentSettings, setCurrentSettings] = useState();
  const [drivaTC, setDrivaTC] = useState(``);
  const [mailupLoader, setMailupLoader] = useState(false);

  const [selectedLevel, setSelectedLevel] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [levelName, setLevelName] = useState(props?.dealersettings?.oemName)
  const [ref, setRef] = useState(null);
  const { clients, groups, regions } = useContext(TemplateContext);
  const [modalDynOptionsShow, setDynOptionsShow] = useState({
    show: false,
    title: "",
    isEdit: false,
  });
  const emailIntgSettings = props?.dealersettings?.rolePermissions?.permissions?.emailIntgSettings;

  useEffect(() => {
    const _show = {};
    allintegrations.forEach(rec => {
      _show[rec.value] = true;
    })
    setShow({
      ...show,
      ..._show
    })
  }, [])

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let level = params.get("level");
    let salesforce = params.get("salesforce");
    let documentID = params.get("id");
    let autograb = params.get("autograb");
    if (level === "oem") {
      setSelectedLevel({
        level: "oem",
        levelID: props.dealersettings.oemID,
      });
    } else if (localStorage.defaultModule === "oem")
      setSelectedLevel({
        level: props.dealersettings.level,
        levelID:
          props.dealersettings.level === "oem"
            ? props.dealersettings.oemID
            : props.dealersettings.level === "region"
              ? props.dealersettings.regionID
              : props.dealersettings.level === "group"
                ? props.dealersettings.groupID
                : props.dealersettings.client.id,
      });
    else
      setSelectedLevel({
        level: "individual",
        levelID: props.dealersettings.client.id,
      });

    if (salesforce) {
      let integrationRef;
      if (level === "oem") {
        integrationRef = window.firebase
          .firestore()
          .collection(`clientSettings`)
          .doc(props.dealersettings.client.settingsID)
          .collection("integrations");
      } else {
        integrationRef = window.firebase
          .firestore()
          .collection(`clients`)
          .doc(selectedLevel.levelID)
          .collection("integrations");
      }
      if (documentID)
        integrationRef.doc(documentID).update({
          "settings.success": salesforce === "true" ? true : false,
        });

      if (salesforce === "false") {
        Swal.fire(
          "Something went wrong. Please reauthenticate salesforce.",
          "",
          "error"
        );
      }
    }
    if (Boolean(autograb)) {
      handleEditOptionsOpen("autograb", true, "AutoGrab");
    }
    setIntegrationKey(props?.dealersettings?.client?.integrationKey || "");
  }, []);

  useEffect(() => {
    if (!_.isEmpty(clients)) {
      let _clients = [];
      _.filter(
        clients,
        (v) =>
          _.indexOf(
            props.dealersettings.clients.map((v) => _.pick(v, ["id"]).id),
            v.documentID
          ) >= 0
      ).forEach((_client) => {
        _clients.push({
          label: _client.name,
          value: _client.documentID,
          data: _client,
        });
      });
      setClientOptions(_clients);
    } else {
      setClientOptions([{ name: "oem" }]);
    }
  }, [clients]);

  useEffect(() => {
    if (_.isEmpty(clientOptions)) return;

    setRegionOptions(
      _.filter(
        regions,
        (v) =>
          _.indexOf(
            _.uniq(
              _.map(
                clientOptions.map((rec) => {
                  return rec.data;
                }),
                "region"
              )
            ),
            v.code
          ) >= 0
      ).map((rec) => {
        return {
          value: rec.code,
          label: rec.name,
          data: rec,
          active: _.isBoolean(rec.active) ? rec.active : true,
        };
      })
    );
    setGroupOptions(
      _.filter(
        groups,
        (v) =>
          _.indexOf(
            _.uniq(
              _.map(
                clientOptions.map((rec) => {
                  return rec.data;
                }),
                "group"
              )
            ),
            v.documentID
          ) >= 0
      ).map((rec) => {
        return {
          value: rec.documentID,
          label: rec.name,
          data: rec,
          active: _.isBoolean(rec.active) ? rec.active : true,
          regionID: _.find(clients, { group: rec.documentID })
            ? _.find(clients, { group: rec.documentID }).region
            : "",
        };
      })
    );
  }, [clientOptions, groups, regions]);

  useEffect(() => {
    if (
      !(
        !_.isEmpty(selectedLevel) &&
        (selectedLevel.level === "oem" || !_.isEmpty(selectedLevel.levelID))
      )
    )
      return;

    let integrationRef;
    if (selectedLevel.level === "oem") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID);
    } else if (selectedLevel.level === "region") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID)
        .collection("regions")
        .doc(selectedLevel.levelID);
    } else if (selectedLevel.level === "group") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID)
        .collection("groups")
        .doc(selectedLevel.levelID);
    } else if (selectedLevel.level === "individual") {
      integrationRef = window.firebase
        .firestore()
        .doc(
          `clients/${selectedLevel.levelID}/currentSettings/${selectedLevel.levelID}`
        );
    }

    const currentSettingsSnapshot = integrationRef.onSnapshot(
      (querySnapshot) => {
        if (querySnapshot.exists) {
          setCurrentSettings(querySnapshot.data());
          setIntegrationKey(querySnapshot.data()?.integrationKey || "");
        }
      }
    );
    return () => {
      currentSettingsSnapshot && currentSettingsSnapshot();
    };
  }, [selectedLevel]);

  useEffect(() => {
    const fetchTerms = async () => {
      const querySnapshot = await window.firebase
        .firestore()
        .collection("terms")
        .doc("driva")
        .get();
      const termsData = querySnapshot.data();
      if (termsData && termsData.terms) {
        setDrivaTC(termsData.terms);
      }
    };
    fetchTerms();
  }, []);

  // @info when current client is selected this runs
  useEffect(() => {
    if (
      !(
        !_.isEmpty(selectedLevel) &&
        (selectedLevel.level === "oem" || !_.isEmpty(selectedLevel.levelID))
      )
    )
      return;

    setModules({});
    setLoader(true);
    let integrationRef;
    if (selectedLevel.level === "oem") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID)
        .collection("integrations");
    } else if (selectedLevel.level === "region") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID)
        .collection("regions")
        .doc(selectedLevel.levelID)
        .collection("integrations");
    } else if (selectedLevel.level === "group") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID)
        .collection("groups")
        .doc(selectedLevel.levelID)
        .collection("integrations");
    } else if (selectedLevel.level === "individual") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clients`)
        .doc(selectedLevel.levelID)
        .collection("integrations");
    }
    const integrationsSnapshot = integrationRef.onSnapshot((querySnapshot) => {
      let _integrations = [];
      querySnapshot.docs.forEach((rec) => {
        if (!rec?.generic) {
          _integrations.push({
            ...rec.data(),
            id: rec.id
          });
        }
      });
      //console.log(_integrations, '_integrations')
      if (!_.isEmpty(_integrations)) {
        let _modules = {};
        if (!isEmpty(_.find(_integrations, { type: "imotor" }))) {
          _modules.imotor = _.find(_integrations, { type: "imotor" });
        }
        if (!isEmpty(_.find(_integrations, { type: "messagemedia" }))) {
          _modules.messagemedia = _.find(_integrations, {
            type: "messagemedia",
          });
        }
        if (!isEmpty(_.find(_integrations, { type: "driva" }))) {
          _modules.driva = _.find(_integrations, { type: "driva" });
        }
        if (!isEmpty(_.find(_integrations, { type: "digitallicence_nsw" }))) {
          _modules.digitallicence_nsw = _.find(_integrations, {
            type: "digitallicence_nsw",
          });
        }
        if (!isEmpty(_.find(_integrations, { type: "smshub" }))) {
          _modules.smshub = _.find(_integrations, { type: "smshub" });
        }
        if (!isEmpty(_.find(_integrations, { type: "cmdotcom" }))) {
          _modules.cmdotcom = _.find(_integrations, { type: "cmdotcom" });
        }
        if (!isEmpty(_.find(_integrations, { type: "podium" }))) {
          _modules.podium = _.find(_integrations, { type: "podium" });
        }
        if (!isEmpty(_.find(_integrations, { type: "titan" }))) {
          _modules.titan = _.find(_integrations, { type: "titan" });
        }
        if (!isEmpty(_.find(_integrations, { type: "autogate" }))) {
          _modules.autogate = _.find(_integrations, { type: "autogate" });
        }
        if (!isEmpty(_.find(_integrations, { type: "revolution" }))) {
          _modules.revolution = _.find(_integrations, { type: "revolution" });
        }
        if (!isEmpty(_.find(_integrations, { type: "tss" }))) {
          _modules.tss = _.find(_integrations, { type: "tss" });
        }
        if (!isEmpty(_.find(_integrations, { type: "autotrader" }))) {
          _modules.autotrader = _.find(_integrations, { type: "autotrader" });
        }
        if (!isEmpty(_.find(_integrations, { type: "dealersolutions" }))) {
          _modules.dealersolutions = _.find(_integrations, {
            type: "dealersolutions",
          });
        }
        if (!isEmpty(_.find(_integrations, { type: "drivechat" }))) {
          _modules.drivechat = _.find(_integrations, { type: "drivechat" });
        }
        if (!isEmpty(_.find(_integrations, { type: "carsguide" }))) {
          _modules.carsguide = _.find(_integrations, { type: "carsguide" });
        }
        if (!isEmpty(_.find(_integrations, { type: "erapower" }))) {
          _modules.erapower = _.find(_integrations, { type: "erapower" });
        }
        if (!isEmpty(_.find(_integrations, { type: "saleslogs" }))) {
          _modules.saleslogs = _.find(_integrations, { type: "saleslogs" });
        }
        if (!isEmpty(_.find(_integrations, { type: "aircall" }))) {
          _modules.aircall = _.find(_integrations, { type: "aircall" });
        }
        if (!isEmpty(_.find(_integrations, { type: "ringcentral" }))) {
          _modules.ringcentral = _.find(_integrations, { type: "ringcentral" });
        }
        if (!isEmpty(_.find(_integrations, { type: "ppsr" }))) {
          _modules.ppsr = _.find(_integrations, { type: "ppsr" });
        }
        if (!isEmpty(_.find(_integrations, { type: "d365" }))) {
          _modules.d365 = _.find(_integrations, { type: "d365" });
        }
        if (!isEmpty(_.find(_integrations, { type: "justcall" }))) {
          _modules.justcall = _.find(_integrations, { type: "justcall" });
        }
        if (!isEmpty(_.find(_integrations, { type: "impel" }))) {
          _modules.impel = _.find(_integrations, { type: "impel" });
        }
        if (!isEmpty(_.find(_integrations, { type: "mailchimp" }))) {
          _modules.mailchimp = _.find(_integrations, { type: "mailchimp" });
        }
        if (!isEmpty(_.find(_integrations, { type: "mailup" }))) {
          _modules.mailup = _.find(_integrations, { type: "mailup" });
        }
        if (!isEmpty(_.find(_integrations, { type: "mailjet" }))) {
          _modules.mailjet = _.find(_integrations, { type: "mailjet" });
        }
        if (!isEmpty(_.find(_integrations, { type: "vision6" }))) {
          _modules.vision6 = _.find(_integrations, { type: "vision6" });
        }
        if (!isEmpty(_.find(_integrations, { type: "evalexpert" }))) {
          _modules.evalexpert = _.find(_integrations, { type: "evalexpert" });
        }
        if (!isEmpty(_.find(_integrations, { type: "nylas" }))) {
          _modules.nylas = _.find(_integrations, { type: "nylas" });
          // NEWONES
        }
        if (!isEmpty(_.find(_integrations, { type: "adtorqueedge" }))) {
          _modules.adtorqueedge = _.find(_integrations, {
            type: "adtorqueedge",
          });
        }
        if (!isEmpty(_.find(_integrations, { type: "adtorqueedgeloan" }))) {
          _modules.adtorqueedgeloan = _.find(_integrations, {
            type: "adtorqueedgeloan",
          });
        }
        if (!isEmpty(_.find(_integrations, { type: "appraisal_solutions" }))) {
          _modules.appraisal_solutions = _.find(_integrations, {
            type: "appraisal_solutions",
          });
        }
        if (!isEmpty(_.find(_integrations, { type: "autoit" }))) {
          _modules.autoit = _.find(_integrations, { type: "autoit" });
        }
        if (!isEmpty(_.find(_integrations, { type: "pmds" }))) {
          _modules.pmds = _.find(_integrations, { type: "pmds" });
        }
        if (!isEmpty(_.find(_integrations, { type: "keyloop" }))) {
          _modules.keyloop = _.find(_integrations, { type: "keyloop" });
        }
        if (!isEmpty(_.find(_integrations, { type: "bentley_cab" }))) {
          _modules.bentley_cab = _.find(_integrations, { type: "bentley_cab" });
        }
        if (!isEmpty(_.find(_integrations, { type: "carsales" }))) {
          _modules.carsales = _.find(_integrations, { type: "carsales" });
        }
        if (!isEmpty(_.find(_integrations, { type: "conversica" }))) {
          _modules.conversica = _.find(_integrations, { type: "conversica" });
        }
        if (!isEmpty(_.find(_integrations, { type: "creativo" }))) {
          _modules.creativo = _.find(_integrations, { type: "creativo" });
        }
        if (!isEmpty(_.find(_integrations, { type: "dubizzle" }))) {
          _modules.dubizzle = _.find(_integrations, { type: "dubizzle" });
        }
        if (!isEmpty(_.find(_integrations, { type: "facebook" }))) {
          _modules.facebook = _.find(_integrations, { type: "facebook" });
        }
        if (!isEmpty(_.find(_integrations, { type: "autograb" }))) {
          _modules.autograb = _.find(_integrations, { type: "autograb" });
        }
        if (!isEmpty(_.find(_integrations, { type: "genericApis" }))) {
          _modules.genericApis = _.find(_integrations, { type: "genericApis" });
        }
        if (!isEmpty(_.find(_integrations, { type: "fca" }))) {
          _modules.fca = _.find(_integrations, { type: "fca" });
        }
        if (!isEmpty(_.find(_integrations, { type: "ferrari_modis" }))) {
          _modules.ferrari_modis = _.find(_integrations, {
            type: "ferrari_modis",
          });
        }
        if (!isEmpty(_.find(_integrations, { type: "gumtree" }))) {
          _modules.gumtree = _.find(_integrations, { type: "gumtree" });
        }
        if (!isEmpty(_.find(_integrations, { type: "heroleads" }))) {
          _modules.heroleads = _.find(_integrations, { type: "heroleads" });
        }
        if (!isEmpty(_.find(_integrations, { type: "c2native" }))) {
          _modules.c2native = _.find(_integrations, { type: "c2native" });
        }
        if (!isEmpty(_.find(_integrations, { type: "gubagoo" }))) {
          _modules.gubagoo = _.find(_integrations, { type: "gubagoo" });
        }
        if (!isEmpty(_.find(_integrations, { type: "autoforce" }))) {
          _modules.autoforce = _.find(_integrations, { type: "autoforce" });
        }
        if (!isEmpty(_.find(_integrations, { type: "amsme" }))) {
          _modules.amsme = _.find(_integrations, { type: "amsme" });
        }
        if (!isEmpty(_.find(_integrations, { type: "mattaki" }))) {
          _modules.mattaki = _.find(_integrations, { type: "mattaki" });
        }
        if (!isEmpty(_.find(_integrations, { type: "motorweb" }))) {
          _modules.motorweb = _.find(_integrations, { type: "motorweb" });
        }
        if (!isEmpty(_.find(_integrations, { type: "cap" }))) {
          _modules.cap = _.find(_integrations, { type: "cap" });
        }
        if (!isEmpty(_.find(_integrations, { type: "red_book" }))) {
          _modules.red_book = _.find(_integrations, { type: "red_book" });
        }
        if (!isEmpty(_.find(_integrations, { type: "roi" }))) {
          _modules.roi = _.find(_integrations, { type: "roi" });
        }
        if (!isEmpty(_.find(_integrations, { type: "salesforce" }))) {
          _modules.salesforce = _.find(_integrations, { type: "salesforce" });
        }
        if (!isEmpty(_.find(_integrations, { type: "shiftdigital" }))) {
          _modules.shiftdigital = _.find(_integrations, {
            type: "shiftdigital",
          });
        }
        if (!isEmpty(_.find(_integrations, { type: "sms_broadcast" }))) {
          _modules.sms_broadcast = _.find(_integrations, {
            type: "sms_broadcast",
          });
        }
        if (!isEmpty(_.find(_integrations, { type: "wildjar" }))) {
          _modules.wildjar = _.find(_integrations, { type: "wildjar" });
        }
        if (!isEmpty(_.find(_integrations, { type: "dubaicars" }))) {
          _modules.dubaicars = _.find(_integrations, { type: "dubaicars" });
        }
        if (!isEmpty(_.find(_integrations, { type: "aapwebsite" }))) {
          _modules.aapwebsite = _.find(_integrations, { type: "aapwebsite" });
        }
        if (!isEmpty(_.find(_integrations, { type: "projectcoavme" }))) {
          _modules.projectcoavme = _.find(_integrations, {
            type: "projectcoavme",
          });
        }
        if (!isEmpty(_.find(_integrations, { type: "nexa" }))) {
          _modules.nexa = _.find(_integrations, { type: "nexa" });
        }
        if (!isEmpty(_.find(_integrations, { type: "gforces" }))) {
          _modules.gforces = _.find(_integrations, { type: "gforces" });
        }
        if (!isEmpty(_.find(_integrations, { type: "pandadoc" }))) {
          _modules.pandadoc = _.find(_integrations, { type: "pandadoc" });
        }
        allintegrations.forEach(rec => {
          if (!isEmpty(_.find(_integrations, { 'type': rec.value }))) {
            _modules[rec.value] = _.find(_integrations, { 'type': rec.value })
          }
        })
        setModules(_modules);
        setIntegrations(_integrations)
      }
      setLoader(false);
    });
    return () => {
      integrationsSnapshot && integrationsSnapshot();
    };
  }, [selectedLevel]);

  useEffect(() => {
    const authenticatePodiumFromURLParams = async () => {
      if (_.isEmpty(modules)) {
        return;
      }
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get("code");
      const state = urlParams.get("state");
      const error = urlParams.get("error");
      const error_description = urlParams.get("error_description");

      if (!code || state !== podiumState || error || error_description) {
        if (error_description) {
          toast.notify(error_description, {
            duration: 2000,
          });
        }
        //removeParamsFromURL()
        return;
      }
      setPodiumEnableLoader(true);

      let reqBody = {
        code: code,
        redirect_uri: podiumRedirectURIDecoded,
        client_id: podiumClientID,
        client_secret:
          "07e7730ae5964c48204f68bd35f5c31413ab06fb5db86577d183649c0d7238fe",
        grant_type: "authorization_code",
      };

      const headers = {
        "Content-Type": "application/json",
      };

      const resp = await axios.post(
        `${podiumBaseURL}/token`,
        JSON.stringify(reqBody),
        {
          headers: headers,
        }
      );
      console.log("reqResponse", resp);

      if (
        resp.status === 200 &&
        !_.isEmpty(resp.data) &&
        !_.isEmpty(resp.data.refresh_token)
      ) {
        console.log("authentication successful");
        let settings = { ...resp.data };

        // create webhook and return fields
        const enablePodium = window.firebase
          .functions()
          .httpsCallable("podium-enable");
        const data = await enablePodium({
          refreshToken: settings.refresh_token,
        });

        console.log("enablePodium", data);
        if (data.data.success === true) {
          settings.organizationUid = data.data.organizationUid;
          settings.locationUid = data.data.locationUid;
          settings.webhookUid = data.data.webhookUid;
          settings.podiumPhoneNumber = data.data.podiumPhoneNumber;
          settings.podiumName = data.data.podiumName;

          enableAddons("podium", true, settings);
        } else {
          console.log("not success", resp);
          removeParamsFromURL();
        }
      } else {
        console.log("not success", resp);
        removeParamsFromURL();
      }
      setPodiumEnableLoader(false);
    };
    authenticatePodiumFromURLParams();
  }, [modules]);

  useEffect(() => {
    const authenticatePodiumFromURLParams = async () => {
      if (_.isEmpty(modules)) {
        return;
      }
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get("code");
      const state = urlParams.get("state");

      if (!code || state === podiumState) {
        //removeParamsFromURL()
        return;
      }
      setMailupLoader(true);

      const resp = await axios.get(
        `https://services.mailup.com/Authorization/OAuth/Token?code=${code}&grant_type=authorization_code`
      );
      console.log("reqResponse", resp);

      if (
        resp.status === 200 &&
        !_.isEmpty(resp.data) &&
        !_.isEmpty(resp.data.refresh_token)
      ) {
        removeParamsFromURL();
        console.log("authentication successful");
        let settings = { ...resp.data };
        enableAddons("mailup", true, settings);
      } else {
        console.log("not success", resp);
        removeParamsFromURL();
      }
      setMailupLoader(false);
    };
    authenticatePodiumFromURLParams();
  }, [modules]);

  useEffect(() => {
    const authenticateAircall = async () => {
      if (_.isEmpty(modules)) {
        return;
      }
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const aircall = urlParams.get("aircall");
      const level = urlParams.get("level");
      const intID = urlParams.get("id");

      if (!aircall || aircall === "false" || !level || !intID) {
        //removeParamsFromURL()
        return;
      }
      setBtnLoader({
        ["aircall"]: true,
      });
      const integrationDoc = await window.firebase
        .firestore()
        .doc(`clients/${selectedLevel.levelID}/integrations/${intID}`)
        .get();
      if (integrationDoc?.data()) {
        const apiURL =
          window.location.hostname === "ams-pro-live.web.app" ||
            window.location.hostname === "app.fusionamspro.com"
            ? "https://app.fusionamspro.com/"
            : "https://qa.fusionamspro.com/";
        const response = await axios.post(
          `${props.dealersettings.aircallConfig.endpoint}/v1/webhooks`,
          {
            custom_name: "AMS PRO Webhook",
            url: `${apiURL}api/aircall/webhook`,
            events: ["call.ended", "call.commented"],
          },
          {
            headers: {
              Authorization: `Bearer ${integrationDoc.data().settings.access_token
                }`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        if (response?.data?.webhook?.token) {
          await window.firebase
            .firestore()
            .doc(`clients/${selectedLevel.levelID}`)
            .update({ aircallWebhook: response?.data?.webhook?.token });
        }
      }
      removeParamsFromURL();
      setBtnLoader({
        ["aircall"]: false,
      });
    };
    authenticateAircall();
  }, [modules]);

  // useEffect(() => {
  //     const authenticateRingcentral = async () => {
  //         if (_.isEmpty(modules)) {
  //             return
  //         }
  //         const queryString = window.location.search;
  //         const urlParams = new URLSearchParams(queryString);
  //         const ringcentral = urlParams.get('ringcentral')
  //         const level = urlParams.get('level')
  //         const intID = urlParams.get('id')

  //         if (!ringcentral || ringcentral === 'false' || !level || !intID) {
  //             //removeParamsFromURL()
  //             return
  //         }
  //         setBtnLoader({
  //             ['ringcentral']: true
  //         })
  //         const integrationDoc = await window.firebase.firestore().doc(`clients/${selectedLevel.levelID}/integrations/${intID}`).get();
  //         if (integrationDoc?.data()) {
  //             const webhookURL = (window.location.hostname === "ams-pro-live.web.app" || window.location.hostname === "app.fusionamspro.com") ? "https://app.fusionamspro.com/api/ringcentral/webhook" : "https://qa.fusionamspro.com/api/ringcentral/webhook";
  //             const eventFilters = [];
  //             const extension = [];
  //             let extensionsResponse = null;
  //             extensionsResponse = await axios.get(`${props.dealersettings.ringcentralConfig.endpoint}/restapi/v1.0/account/~/extension`, {
  //                 headers: {
  //                     Authorization: `Bearer ${integrationDoc.data().settings.access_token}`,
  //                     'Content-Type': 'application/json',
  //                     Accept: 'application/json',
  //                 }
  //             });
  //             if (extensionsResponse?.data?.records?.length > 0) {
  //                 extension.push(...extensionsResponse.data.records);
  //                 while (extensionsResponse?.data?.navigation?.nextPage?.uri) {
  //                     extensionsResponse = await axios.get(extensionsResponse.data.navigation.nextPage.uri, {
  //                         headers: {
  //                             Authorization: `Bearer ${integrationDoc.data().settings.access_token}`,
  //                             'Content-Type': 'application/json',
  //                             Accept: 'application/json',
  //                         }
  //                     });
  //                     if (extensionsResponse?.data?.records?.length > 0) {
  //                         extension.push(...extensionsResponse.data.records);
  //                     }
  //                 }
  //             }
  //             console.log(extension);
  //             if (extension.length > 0) {
  //                 extension.forEach((r) => {
  //                     eventFilters.push(`/restapi/v1.0/account/~/extension/${r.id}/message-store/instant?type=SMS`);
  //                     eventFilters.push(`/restapi/v1.0/account/~/extension/${r.id}/telephony/sessions`);
  //                 })
  //             }
  //             else {
  //             eventFilters.push("/restapi/v1.0/account/~/extension/~/message-store/instant?type=SMS")
  //             eventFilters.push("/restapi/v1.0/account/~/telephony/sessions");
  //             }
  //             const response = await axios.post(`${props.dealersettings.ringcentralConfig.endpoint}/restapi/v1.0/subscription`, {
  //                 "eventFilters": eventFilters,
  //                 "expiresIn": 315360000,
  //                 "deliveryMode": {
  //                     "transportType": "WebHook",
  //                     "address": webhookURL
  //                 }
  //             }, {
  //                 headers: {
  //                     Authorization: `Bearer ${integrationDoc.data().settings.access_token}`,
  //                     'Content-Type': 'application/json',
  //                     Accept: 'application/json',
  //                 }
  //             });
  //             if (response?.data?.id) {
  //                 await window.firebase.firestore().doc(`clients/${selectedLevel.levelID}`).update({ ringcentralWebhook: response?.data?.id })
  //             }
  //         }
  //         removeParamsFromURL()
  //         setBtnLoader({
  //             ['ringcentral']: false
  //         })
  //     }
  //     authenticateRingcentral()
  // }, [modules])

  // useEffect(() => {
  //     const authenticateRingcentral = async () => {
  //         if (_.isEmpty(modules)) {
  //             return
  //         }
  //         const queryString = window.location.search;
  //         const urlParams = new URLSearchParams(queryString);
  //         const ringcentral = urlParams.get('ringcentral')
  //         const level = urlParams.get('level')
  //         const intID = urlParams.get('id')

  //         if (!ringcentral || ringcentral === 'false' || !level || !intID) {
  //             //removeParamsFromURL()
  //             return
  //         }
  //         setBtnLoader({
  //             ['ringcentral']: true
  //         })
  //         const integrationDoc = await window.firebase.firestore().doc(`clients/${selectedLevel.levelID}/integrations/${intID}`).get();
  //         if (integrationDoc?.data()) {
  //             // const apiURL = (window.location.hostname === "ams-pro-live.web.app" || window.location.hostname === "app.fusionamspro.com") ? "https://app.fusionamspro.com/" : "https://qa.fusionamspro.com/";
  //             // const response = await axios.post(`${props.dealersettings.ringcentralConfig.endpoint}/v1/webhooks`, {
  //             //     custom_name: "AMS PRO Webhook",
  //             //     url: `${apiURL}api/aircall/webhook`,
  //             //     "events": [
  //             //         "call.ended",
  //             //         "call.commented"
  //             //     ]
  //             // }, {
  //             //     headers: {
  //             //         Authorization: `Bearer ${integrationDoc.data().settings.access_token}`,
  //             //         'Content-Type': 'application/json',
  //             //         Accept: 'application/json',
  //             //     }
  //             // });
  //             // if (response?.data?.webhook?.token) {
  //             //     await window.firebase.firestore().doc(`clients/${selectedLevel.levelID}`).update({ aircallWebhook: response?.data?.webhook?.token })
  //             // }
  //         }
  //         removeParamsFromURL()
  //         setBtnLoader({
  //             ['ringcentral']: false
  //         })
  //     }
  //     authenticateRingcentral()
  // }, [modules])

  const authenticateSalesForce = (
    module,
    checked,
    settings,
    lookup,
    leadSource,
    campaign
  ) => {
    let documentID = window.firebase.firestore().collection("clients").doc().id;
    if (!_.isEmpty(settings.documentID)) {
      documentID = settings.documentID;
    }
    let reqData = {
      type: module,
      enabled: checked,
    };
    if (!isEmpty(settings)) {
      if (!isEmpty(modules[module].settings)) {
        reqData.settings = {
          ...modules[module].settings,
          ...settings,
        };
      } else {
        reqData.settings = settings;
      }
    }
    setBtnLoader({
      [module]: true,
    });
    let integrationRef;
    let levelID;
    if (selectedLevel.level === "oem") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID)
        .collection("integrations");
      levelID = props.dealersettings.client.settingsID;
    } else if (selectedLevel.level === "region") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID)
        .collection("regions")
        .doc(selectedLevel.levelID)
        .collection("integrations");
      levelID = selectedLevel.levelID;
    } else if (selectedLevel.level === "group") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID)
        .collection("groups")
        .doc(selectedLevel.levelID)
        .collection("integrations");
      levelID = selectedLevel.levelID;
    } else if (selectedLevel.level === "individual") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clients`)
        .doc(selectedLevel.levelID)
        .collection("integrations");
      levelID = selectedLevel.levelID;
    }
    integrationRef.doc(documentID).set(reqData, { merge: true });
    const callbackURL =
      window.location.hostname === "ams-pro-live.web.app" ||
        window.location.hostname === "app.fusionamspro.com"
        ? "https://app.fusionamspro.com/api/salesforce/callback"
        : "https://qa.fusionamspro.com/api/salesforce/callback";
    const authURL = `${settings.endpoint}/services/oauth2/authorize?client_id=${settings.clientId}&redirect_uri=${callbackURL}&state=${levelID}&response_type=code`;
    window.location.replace(authURL);
  };
  const authenticateMailup = async (
    module,
    checked,
    settings,
    lookup,
    leadSource,
    campaign
  ) => {
    let reqData = {
      type: module,
      enabled: checked,
    };
    if (!isEmpty(settings)) {
      if (!isEmpty(modules[module].settings)) {
        reqData.settings = {
          ...modules[module].settings,
          ...settings,
        };
      } else {
        reqData.settings = settings;
      }
    }
    setBtnLoader({
      [module]: true,
    });
    let integrationRef;
    let levelID;
    if (selectedLevel.level === "oem") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID)
        .collection("integrations");
      levelID = props.dealersettings.client.settingsID;
    } else if (selectedLevel.level === "region") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID)
        .collection("regions")
        .doc(selectedLevel.levelID)
        .collection("integrations");
      levelID = selectedLevel.levelID;
    } else if (selectedLevel.level === "group") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID)
        .collection("groups")
        .doc(selectedLevel.levelID)
        .collection("integrations");
      levelID = selectedLevel.levelID;
    } else if (selectedLevel.level === "individual") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clients`)
        .doc(selectedLevel.levelID)
        .collection("integrations");
      levelID = selectedLevel.levelID;
    }
    const snap = await integrationRef.where("type", "==", module).get();
    let documentID;
    if (snap.empty) {
      documentID = window.firebase.firestore().collection("clients").doc().id;
    } else {
      snap.forEach((doc) => {
        documentID = doc.id;
      });
    }
    integrationRef.doc(documentID).set(reqData, { merge: true });
    const callbackURL = window.location.href;
    const authURL = `https://services.mailup.com/Authorization/OAuth/LogOn?client_id=${settings.client_id}&response_type=code&redirect_uri=${callbackURL}`;
    window.location.replace(authURL);
  };

  const enableAddons = async (
    module,
    checked,
    settings,
    lookup,
    leadSource,
    campaign,
    dealerCodes,
    formIDs
  ) => {
    if (!checked) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to disable addon.",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: CommonHelper.showLocale(props, "Yes"),
        cancelButtonText: CommonHelper.showLocale(props, "No"),
      }).then((result) => {
        if (result.value) {
          updateAddons(module, checked, settings);
        }
      });
    } else {
      updateAddons(
        module,
        checked,
        settings,
        lookup,
        leadSource,
        campaign,
        dealerCodes,
        formIDs
      );
    }
  };

  const updateAddons = async (
    module,
    checked,
    settings,
    lookup,
    leadSource,
    campaign,
    dealerCodes,
    formIDs
  ) => {
    setBtnLoader({
      [module]: true,
    });

    let reqData = {
      type: module,
      enabled: checked,
    };
    if (inboundModules.includes(module)) {
      reqData.inbound = true;
    }
    if (module === "titan") {
      if (!isEmpty(settings) && !isEmpty(lookup)) {
        if (!isEmpty(modules[module].settings)) {
          reqData.settings = {
            ...modules[module].settings,
            ...settings,
            locIds: lookup,
          };
        } else {
          reqData.settings = {
            ...settings,
            locIds: lookup,
          };
        }
      }
    } else if (module === "saleslogs") {
      if (!isEmpty(settings) && !isEmpty(lookup)) {
        //console.log('settings, lookup', settings, lookup)

        if (!isEmpty(modules[module].settings)) {
          reqData.settings = {
            ...modules[module].settings,
            ...settings,
            Dept: lookup,
          };
        } else {
          reqData.settings = {
            ...settings,
            Dept: lookup,
          };
        }
      }
    } else if (
      module === "carsales" ||
      module === "imotor" ||
      module === "autogate" ||
      module === "dealersolutions" ||
      module === "drivechat" ||
      module === "carsguide" ||
      module === "adtorqueedge" ||
      module === "adtorqueedgeloan" ||
      module === "dubizzle" ||
      module === "facebook" ||
      module === "autograb" ||
      module === "gubagoo" ||
      module === "autoforce" ||
      module === "amsme" ||
      module === "gumtree" ||
      module === "heroleads" ||
      module === "c2native" ||
      module === "shiftdigital" ||
      module === "wildjar" ||
      module === "dubaicars" ||
      module === "aapwebsite" ||
      module === "projectcoavme" ||
      module === "nexa" ||
      module === "autotrader" ||
      module === "tss" ||
      module === "pandadoc" ||
      module === "3cx" ||
      module === "revolution"
    ) {
      // console.log('settings, lookup', settings, lookup)
      if (!isEmpty(settings)) {
        if (!isEmpty(modules[module].settings)) {
          reqData.settings = {
            ...modules[module].settings,
            ...settings,
            originKW: !isEmpty(lookup) ? lookup : [],
            leadSourceKW: !isEmpty(leadSource) ? leadSource : [],
            campaignKW: !isEmpty(campaign) ? campaign : [],
            dealerCodes: !isEmpty(dealerCodes) ? dealerCodes : [],
            formIDs: !isEmpty(formIDs) ? formIDs : [],
          };
        } else {
          reqData.settings = {
            ...settings,
            originKW: !isEmpty(lookup) ? lookup : [],
            leadSourceKW: !isEmpty(leadSource) ? leadSource : [],
            campaignKW: !isEmpty(campaign) ? campaign : [],
            formIDs: !isEmpty(formIDs) ? formIDs : [],
          };
        }
      }
    } else if (module === "podium") {
      if (!isEmpty(settings)) {
        // console.log('settings 551, lookup', settings, lookup)
        if (!isEmpty(modules[module].settings)) {
          reqData.settings = {
            ...modules[module].settings,
            ...settings,
          };
        } else {
          reqData.settings = settings;
        }
      }
      if (checked === false) {
        reqData.settings = {};
        // console.log('563, reqData', reqData)
      }
    } else if (module === "fca") {
      if (!isEmpty(settings)) {
        if (!isEmpty(modules?.[module]?.settings)) {
          reqData.settings = {
            ...modules[module].settings,
            ...settings
          };
        } else {
          reqData.settings = {
            ...settings
          };
        }
      }
    } else {
      if (!isEmpty(settings)) {
        if (!isEmpty(modules?.[module]?.settings)) {
          reqData.settings = {
            ...modules[module].settings,
            ...settings,
            originKW: !isEmpty(lookup) ? lookup : [],
            leadSourceKW: !isEmpty(leadSource) ? leadSource : [],
            campaignKW: !isEmpty(campaign) ? campaign : [],
          };
        } else {
          reqData.settings = {
            ...settings,
            originKW: !isEmpty(lookup) ? lookup : [],
            leadSourceKW: !isEmpty(leadSource) ? leadSource : [],
            campaignKW: !isEmpty(campaign) ? campaign : [],
          };
        }
      }
    }
    if (
      module === "imotor" ||
      module === "autogate" ||
      module === "dealersolutions" ||
      module === "drivechat" ||
      module === "carsguide" ||
      module === "adtorqueedge" ||
      module === "adtorqueedgeloan" ||
      module === "dubizzle" ||
      module === "facebook" ||
      module === "autograb" ||
      module === "gubagoo" ||
      module === "autoforce" ||
      module === "amsme" ||
      module === "fca" ||
      module === "gumtree" ||
      module === "heroleads" ||
      module === "c2native" ||
      module === "shiftdigital" ||
      module === "wildjar" ||
      module === "dubaicars" ||
      module === "aapwebsite" ||
      module === "projectcoavme" ||
      module === "nexa" ||
      module === "autotrader" ||
      module === "gforces"
    ) {
      reqData = {
        ...reqData,
        inbound: true,
      };
    }

    if (module === "adtorqueedgeloan") {
      reqData = {
        ...reqData,
        isLoan: true,
      };
    }

    let integrationRef;
    if (selectedLevel.level === "oem") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID)
        .collection("integrations");
    } else if (selectedLevel.level === "region") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID)
        .collection("regions")
        .doc(selectedLevel.levelID)
        .collection("integrations");
    } else if (selectedLevel.level === "group") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID)
        .collection("groups")
        .doc(selectedLevel.levelID)
        .collection("integrations");
    } else if (selectedLevel.level === "individual") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clients`)
        .doc(selectedLevel.levelID)
        .collection("integrations");
    }
    // check if there is already a where type === module
    const snap = await integrationRef.where("type", "==", module).get();
    let documentID;
    if (snap.empty) {
      documentID = window.firebase.firestore().collection("clients").doc().id;
    } else {
      snap.forEach((doc) => {
        documentID = doc.id;
      });
    }
    if (settings?.id) {
      documentID = settings.id;
      reqData.active = true;
      reqData.inbound = true;
      reqData.email = true;
    }
    if (
      !checked &&
      selectedLevel.level === "individual" &&
      module === "salesforce"
    ) {
      window.firebase
        .firestore()
        .doc(`clients/${selectedLevel.levelID}`)
        .update({
          "clientSettings.salesforceOEM": true,
        });
    }
    console.log("reqData before adding", reqData);
    integrationRef
      .doc(documentID)
      .set(reqData, { merge: true })
      .then(async (snapshot) => {
        let updateModules = {
          ...modules,
          [module]: {
            ...modules[module],
            enabled: checked,
          },
        };
        if (selectedLevel.level === "individual") {
          // update dealer settings
          props.updateDealerSettings("moduleSettings", updateModules);

          //update in role
          // if (module === 'oem' || module === 'frontdesk')
          //     moduleUpdateinRole(module, checked);
          //update settings date and priority
          const clientObjLog = {
            settingsUpdate: window.firebase.firestore.Timestamp.now(),
            settingsUpdatePriority: "critical",
            settingsUpdateText: 'Integration Settings has been updated',
          };
          if (module === "facebook" && checked) {
            clientObjLog.facebookDealerCode =
              reqData.settings.facebookDealerCode;
            clientObjLog.facebookDealerCodes = reqData.settings.dealerCodes
              ? reqData.settings.dealerCodes.map((r) => {
                return r.c;
              })
              : [];
            let formObj = {};
            if (reqData?.settings?.formIDs?.length) {
              reqData.settings.formIDs.filter(a => !Boolean(a.disabled)).forEach(r => {
                formObj = {
                  ...formObj,
                  [r.id]: true
                }
              })
            }
            clientObjLog.facebookFormIDs = formObj;
          } else if (module === "facebook" && !checked) {
            clientObjLog.facebookDealerCode = "";
            clientObjLog.facebookDealerCodes = [];
            clientObjLog.facebookFormIDs = {};
          } else if (module === "gforces") {
            clientObjLog.gforces =
              reqData.settings && reqData.settings.location_hash
                ? reqData.settings.location_hash
                : null;
          } else if (module === "adtorqueedgeloan") {
            if (
              Object.keys(updateModules)
                .map((r) => {
                  return updateModules[r];
                })
                .some(
                  (a) =>
                    a.isLoan === true && a.active === true && a.enabled === true
                )
            ) {
              window.firebase
                .firestore()
                .doc(`clients/${selectedLevel.levelID}`)
                .update({
                  "clientSettings.enableLoanBooking": true,
                });
            } else {
              window.firebase
                .firestore()
                .doc(`clients/${selectedLevel.levelID}`)
                .update({
                  "clientSettings.enableLoanBooking": false,
                });
            }
          } else if (module === "d365") {
            clientObjLog.mclRetailer =
              checked && reqData?.settings?.mclRetailer
                ? reqData.settings.mclRetailer.toLowerCase().trim()
                : null;
          } else if (module === "justcall" && checked) {
            clientObjLog.justcallWebhook = reqData?.settings?.webhookID || null;
            clientObjLog.justcallNumbers = reqData?.settings?.phones || [];
          } else if (module === "revolution") {
            clientObjLog.revDealerCode =
              checked && reqData?.settings?.revDealerCode
                ? reqData.settings.revDealerCode
                : null;
          } else if (module === "tss") {
            clientObjLog.tss = checked;
            clientObjLog.tssDeptId = checked && reqData?.settings?.tssDeptId ? reqData?.settings?.tssDeptId : null;
          } else if (module === "fca") {
            clientObjLog.fcaCategory = checked && reqData?.settings?.fcaCategory ? reqData.settings.fcaCategory : null;
            clientObjLog.fcaDealerCode = checked && reqData?.settings?.fcaDealerCode ? reqData.settings.fcaDealerCode : null;
          }
          window.firebase
            .firestore()
            .doc(`clients/${selectedLevel.levelID}`)
            .update(clientObjLog);
        }

        //end
        toast.notify(
          `Add-on ${checked ? "enabled" : "disabled"} successfully.`,
          {
            duration: 2000,
          }
        );
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const code = urlParams.get("code");
        const state = urlParams.get("state");
        if (module === "podium" && checked && code && state === podiumState) {
          removeParamsFromURL();
          handleEditOptionsOpen("podium", true, "Podium");
        }

        if (
          module === "vision6" &&
          checked &&
          selectedLevel.level === "individual"
        ) {
          const apiURL =
            process.env.NODE_ENV === "development"
              ? "http://localhost:5001/ams-pro-qa/us-central1/"
              : props.dealersettings.apiURL;

          const createVisionWebhookResponse = await axios.post(
            `${apiURL}visionrest-api/api/visionrest/create-webhook`,
            {
              url: `${props.dealersettings.apiURL}visionrest-api/api/visionrest/user-unsubscribed`,
              API_KEY: reqData.settings.api_key,
            }
          );
          // console.log('props.dealersettings.apiURL', props.dealersettings.apiURL)
          // console.log('reqData', reqData)
          // console.log('createVisionWebhookResponse', createVisionWebhookResponse.data.response)
        } else if (module === "vision6" && !checked) {
          // get the API KEY
          // get the webhook and delete the user unsubscribed one
        }
        //create mailchimp batches webhook
        if (
          module === "mailchimp" &&
          checked &&
          selectedLevel.level === "individual"
        ) {
          const apiURL =
            process.env.NODE_ENV === "development"
              ? "http://localhost:5001/ams-pro-qa/us-central1/"
              : props.dealersettings.apiURL;

          axios.post(
            `${apiURL}mailchimp-api/api/mailchimp/create-batch-webhooks`,
            {
              url: `${props.dealersettings.apiURL}mailchimp-batchCallback`,
              API_KEY: reqData.settings.api_key,
              SERVER_PREFIX: reqData.settings.api_key.split("-")[1],
            }
          );
          // console.log('props.dealersettings.apiURL', props.dealersettings.apiURL)
          // console.log('reqData', reqData)
          //console.log('createMailchimpWebhookResponse', createMailchimpWebhookResponse)
        } else if (module === "mailchimp" && !checked) {
          const apiURL =
            process.env.NODE_ENV === "development"
              ? "http://localhost:5001/ams-pro-qa/us-central1/"
              : props.dealersettings.apiURL;
          reqData = snap.docs[0].data();
          axios.post(
            `${apiURL}mailchimp-api/api/mailchimp/delete-batch-webhooks`,
            {
              API_KEY: reqData.settings.api_key,
              SERVER_PREFIX: reqData.settings.api_key.split("-")[1],
            }
          );
        } else if (module === "aircall" && !checked) {
          reqData = snap.docs[0].data();
          try {
            const allWebhook = await axios.get(
              `${props.dealersettings.aircallConfig.endpoint}/v1/webhooks`,
              {
                headers: {
                  Authorization: `Bearer ${reqData.settings.access_token}`,
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              }
            );
            if (allWebhook?.data?.webhooks?.length > 0) {
              for (const webhook of allWebhook?.data?.webhooks) {
                try {
                  await axios.delete(
                    `${props.dealersettings.aircallConfig.endpoint}/v1/webhooks/${webhook.webhook_id}`,
                    {
                      headers: {
                        Authorization: `Bearer ${reqData.settings.access_token}`,
                        "Content-Type": "application/json",
                        Accept: "application/json",
                      },
                    }
                  );
                } catch (err) { }
              }
            }
          } catch (err) { }
          if (selectedLevel.level === "individual")
            await window.firebase
              .firestore()
              .doc(`clients/${selectedLevel.levelID}`)
              .update({ aircallWebhook: null });
        } else if (module === "justcall" && checked) {
          reqData = snap.docs[0].data();
          try {
            const apiURL =
              window.location.hostname === "ams-pro-live.web.app" ||
                window.location.hostname === "app.fusionamspro.com"
                ? "https://us-central1-ams-pro-live.cloudfunctions.net/"
                : "https://us-central1-ams-pro-qa.cloudfunctions.net/";
            await axios.post(`${apiURL}justcall-createWebhook`,
              {
                "data": {
                  "api_key": reqData.settings.api_key,
                  "api_secret": reqData.settings.api_secret,
                  "url": `${apiURL}justcall-api/api/justcall/webhook`
                }
              }
            );
          } catch (err) { }
        } else if (module === "justcall" && !checked) {
          reqData = snap.docs[0].data();
          try {
            const apiURL =
              window.location.hostname === "ams-pro-live.web.app" ||
                window.location.hostname === "app.fusionamspro.com"
                ? "https://us-central1-ams-pro-live.cloudfunctions.net/"
                : "https://us-central1-ams-pro-qa.cloudfunctions.net/";
            await axios.post(`${apiURL}justcall-deleteWebhook`,
              {
                "data": {
                  "api_key": reqData.settings.api_key,
                  "api_secret": reqData.settings.api_secret,
                }
              }
            );
          } catch (err) { }
          if (selectedLevel.level === "individual")
            await window.firebase
              .firestore()
              .doc(`clients/${selectedLevel.levelID}`)
              .update({
                justcallWebhook: null, justcallNumbers: []
              });
        } else if (module === "pandadoc" && checked) {
          reqData = snap.docs[0].data();
          try {
            const apiURL =
              window.location.hostname === "ams-pro-live.web.app" ||
                window.location.hostname === "app.fusionamspro.com"
                ? "https://us-central1-ams-pro-live.cloudfunctions.net/"
                : "https://us-central1-ams-pro-qa.cloudfunctions.net/";
            await axios.post(`${apiURL}pandadoc-createWebhook`,
              {
                "data": {
                  "api_key": reqData.settings.api_key,
                  "payload": {
                    name: 'AMS PandaDoc',
                    url: `${apiURL}pandadoc-api/api/pandadoc/callback`,
                    triggers: ['document_state_changed', 'document_completed_pdf_ready', 'recipient_completed'],
                    payload: ['metadata']
                  }
                }
              }
            );
          } catch (err) { }
        } else if (module === "pandadoc" && !checked) {
          reqData = snap.docs[0].data();
          try {
            const apiURL =
              window.location.hostname === "ams-pro-live.web.app" ||
                window.location.hostname === "app.fusionamspro.com"
                ? "https://us-central1-ams-pro-live.cloudfunctions.net/"
                : "https://us-central1-ams-pro-qa.cloudfunctions.net/";
            await axios.post(`${apiURL}pandadoc-deleteWebhook`,
              {
                "data": {
                  "api_key": reqData.settings.api_key,
                }
              }
            );
          } catch (err) { }
          if (selectedLevel.level === "individual")
            await window.firebase
              .firestore()
              .doc(`clients/${selectedLevel.levelID}`)
              .update({
                justcallWebhook: null, justcallNumbers: []
              });
        } else if (module === "mailjet" && checked) {
          reqData = snap.docs[0].data();
          try {
            const apiURL =
              window.location.hostname === "ams-pro-live.web.app" ||
                window.location.hostname === "app.fusionamspro.com"
                ? "https://us-central1-ams-pro-live.cloudfunctions.net/"
                : "https://us-central1-ams-pro-qa.cloudfunctions.net/";
            await axios.post(`${apiURL}mailjet-createWebhook`,
              {
                "data": {
                  "api_key": reqData.settings.api_key,
                  "api_secret": reqData.settings.api_secret,
                  "url": `${apiURL}mailjet-api/api/mailjet/webhook`
                }
              }
            );
          } catch (err) { }
        } else if (module === "mailjet" && !checked) {
          reqData = snap.docs[0].data();
          try {
            const apiURL =
              window.location.hostname === "ams-pro-live.web.app" ||
                window.location.hostname === "app.fusionamspro.com"
                ? "https://us-central1-ams-pro-live.cloudfunctions.net/"
                : "https://us-central1-ams-pro-qa.cloudfunctions.net/";
            await axios.post(`${apiURL}mailjet-deleteWebhook`,
              {
                "data": {
                  "api_key": reqData.settings.api_key,
                  "api_secret": reqData.settings.api_secret,
                }
              }
            );
          } catch (err) { }
        }
        // else if (module === 'ringcentral' && !checked) {
        //     reqData = snap.docs[0].data();
        //     try {
        //         const apiURL = process.env.NODE_ENV === 'development'
        //             ? 'http://localhost:5001/ams-pro-qa/us-central1/'
        //             : props.dealersettings.apiURL

        //         axios.post(`${apiURL}ringcentral-api/api/ringcentral/delete-webhooks`, {
        //             id: `${props?.dealersettings?.client?.ringcentralWebhook}`,
        //             refresh_token: reqData.settings.refresh_token,
        //             clientID: selectedLevel.levelID,
        //             integrationID: documentID
        //         });
        //     } catch (err) { }
        //     if (selectedLevel.level === 'individual')
        //         await window.firebase.firestore().doc(`clients/${selectedLevel.levelID}`).update({ ringcentralWebhook: null })
        // }

        setBtnLoader({});
      })
      .catch((error) => {
        console.log(error);
        // toast.notify('Something went wrong', {
        //     duration: 2000
        // })
        setBtnLoader({});
      });
  };

  const updateRequestedAddons = (module) => {
    let userID = props.dealersettings ? props.dealersettings.id : "";
    let currentDate = window.firebase.firestore.Timestamp.now();

    setBtnLoader({
      [module]: true,
    });
    window.firebase
      .firestore()
      .doc(`clients/${selectedLevel.levelID}`)
      .set(
        {
          moduleSettings: {
            ...modules,
            [module]: {
              ...modules[module],
              ["requestedBy"]: userID,
              ["requestedDate"]: currentDate,
            },
          },
        },
        { merge: true }
      )
      .then((snapshot) => {
        //update dealer settings
        props.updateDealerSettings("moduleSettings", {
          ...modules,
          [module]: {
            ...modules[module],
            ["requestedBy"]: userID,
            ["requestedDate"]: currentDate,
          },
        });

        //update in role
        // if (module === 'oem' || module === 'frontdesk')
        //     moduleUpdateinRole(module, checked);
        //update settings date and priority
        window.firebase.firestore().doc(`clients/${selectedLevel.levelID}`).set(
          {
            settingsUpdate: window.firebase.firestore.Timestamp.now(),
            settingsUpdatePriority: "critical",
            settingsUpdateText: 'Integration Settings has been updated',
          },
          { merge: true }
        );

        //end
        toast.notify(`Add-on requested successfully.`, {
          duration: 2000,
        });
        setBtnLoader({});
      })
      .catch((error) => {
        console.log(error);
        toast.notify("Something went wrong", {
          duration: 2000,
        });
      });
  };

  const handleOptionsOpen = async (module, checked, title) => {
    if (module === "adtorqueedgeloan" || module === "genericApis") {
      enableAddons(module, checked);
    } else if (
      module === "salesforce" &&
      selectedLevel.level !== "oem" &&
      checked
    ) {
      setBtnLoader({
        [module]: true,
      });
      const oemSalesforceSettings = await window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID)
        .collection("integrations")
        .where("type", "==", module)
        .limit(1)
        .get();
      if (oemSalesforceSettings.docs.length > 0) {
        updateAddons(
          module,
          checked,
          oemSalesforceSettings.docs[0].data().settings
        );
        window.firebase
          .firestore()
          .doc(`clients/${selectedLevel.levelID}`)
          .update({
            "clientSettings.salesforceOEM": true,
          });
      } else {
        setModuleToChange({ module, checked });
        setOptionsShow({
          show: true,
          title,
          isEdit: false,
        });
      }
    } else {
      setModuleToChange({ module, checked });
      setOptionsShow({
        show: true,
        title,
        isEdit: false,
      });
    }
  };

  const handleEditOptionsOpen = (module, checked, title) => {
    setModuleToChange({ module, checked });
    setOptionsShow({
      show: true,
      title,
      isEdit: true,
    });
  };

  const handleDynOptionsOpen = async (module, checked, data) => {
    setModuleToChange({ module: module.value, checked });
    setDynOptionsShow({
      show: true,
      title: module.name,
      isEdit: false,
      intg: module,
      intgSettings: data
    });
  };

  const handleGenericEditOptionsOpen = (module, checked, title, key) => {
    if (!key) {
      setShowUpdateGenericApi(true);
    } else {
      // setModuleToChange({ module, checked });
      // setOptionsShow({
      //   show: true,
      //   title,
      //   isEdit: true,
      // });
      setGenericIntgShow({
        show: true,
        title: 'Generic Api',
        integrationKey: key
      })
    }
  };

  const handleModalClose = (_data) => {
    if (_data?.integrationKey) {
      setIntegrationKey(_data?.integrationKey);
      handleEditOptionsOpen("genericApis", true, "Generic Apis");
    }
    setShowUpdateGenericApi(false);
  };

  const handleOptionsClose = () => {
    setOptionsShow({
      show: false,
      title: "",
      isEdit: false,
    });
    setDynOptionsShow({ show: false });
  };

  const handleDisableOpen = (module, checked, title) => {
    setModuleToChange({ module, checked });
    setDisableShow({
      show: true,
      title,
      isEdit: false,
    });
  };

  const handleDisableClose = () => {
    setDisableShow({
      show: false,
      title: "",
      isEdit: false,
    });
  };

  const handleFormClose = (obj) => {
    // console.log('handleFormClose', obj)
    if (!_.isEmpty(obj) && obj.module) {
      updateRequestedAddons(obj.module);
    }
    setRequestShow(false);
    setReqForm({});
  };

  const handleFormOpen = (_module, _imageurl, _title) => {
    if (selectedLevel && selectedLevel.level === "individual") {
      setRequestShow(true);
      let obj = Object.assign(
        {},
        {
          module: _module,
          image: _imageurl,
          title: _title,
        }
      );
      setReqForm(obj);
    } else {
      window.Intercom("show");
    }
  };

  const handlePodiumAuthStart = () => {
    const authURL = `${podiumBaseURL}/authorize?client_id=${podiumClientID}&redirect_uri=${podiumRedirectURIEncoded}&scope${podiumScopes}&state=${podiumState}`;

    window.location.replace(authURL);
  };

  const handlePodiumDisable = async () => {
    const podiumIntegration = !_.isEmpty(modules) &&
      !_.isEmpty(modules.podium) && { ...modules.podium };
    if (
      _.isEmpty(podiumIntegration) ||
      _.isEmpty(podiumIntegration.settings) ||
      _.isEmpty(podiumIntegration.settings.webhookUid) ||
      _.isEmpty(podiumIntegration.settings.refresh_token)
    ) {
      return;
    }

    const disablePodium = window.firebase
      .functions()
      .httpsCallable("podium-disable");
    const disablePodiumData = await disablePodium({
      refreshToken: podiumIntegration.settings.refresh_token,
      webhookUid: podiumIntegration.settings.webhookUid,
    });

    console.log("disablePodiumData", disablePodiumData);
  };

  const handleAircallAuthStart = () => {
    const callbackURL =
      window.location.hostname === "ams-pro-live.web.app" ||
        window.location.hostname === "app.fusionamspro.com"
        ? "https://app.fusionamspro.com/api/aircall/callback"
        : "https://qa.fusionamspro.com/api/aircall/callback";
    const authURL = `https://dashboard.aircall.io/oauth/authorize?client_id=${props.dealersettings.aircallConfig.client_id}&redirect_uri=${callbackURL}&state=${selectedLevel.levelID}&response_type=code&scope=public_api`;
    window.location.replace(authURL);
  };
  const handleRingCentralAuthStart = () => {
    const callbackURL =
      window.location.hostname === "ams-pro-live.web.app" ||
        window.location.hostname === "app.fusionamspro.com"
        ? "https://app.fusionamspro.com/api/ringcentral/callback"
        : "https://qa.fusionamspro.com/api/ringcentral/callback";
    const authURL = `${props.dealersettings.ringcentralConfig.endpoint}/restapi/oauth/authorize?client_id=${props.dealersettings.ringcentralConfig.client_id}&redirect_uri=${callbackURL}&state=${selectedLevel.levelID};client&response_type=code`;
    window.location.replace(authURL);
  };
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    let val = e.target.value.toLowerCase().trim();
    const _show = {};
    allintegrations.forEach(rec => {
      _show[rec.value] = rec.value.includes(val)
    })
    setShow({
      ...show,
      ..._show,
      messagemedia: "messagemedia".includes(val),
      driva: "driva".includes(val),
      digitallicence_nsw: "digitallicence_nsw".includes(val),
      titan: "titandms".includes(val),
      imotor: "imotor".includes(val),
      autogate: "autogate".includes(val),
      smshub: "smshub".includes(val),
      cmdotcom: "cmdotcom".includes(val),
      podium: "podium".includes(val),
      autotrader: "autotrader".includes(val),
      dealersolutions: "dealersolutions".includes(val),
      drivechat: "drivechat".includes(val),
      carsguide: "carsguide".includes(val),
      erapower: "erapower".includes(val),
      saleslogs: "saleslogs".includes(val),
      mailchimp: "mailchimp".includes(val),
      mailup: "mailup".includes(val),
      mailjet: "mailjet".includes(val),
      aircall: "aircall".includes(val),
      ringcentral: "ringcentral".includes(val),
      ppsr: "ppsr".includes(val),
      d365: "d365".includes(val),
      justcall: "justcall".includes(val),
      impel: "impel".includes(val),
      vision6: "vision6".includes(val),
      evalexpert: "evalexpert".includes(val),
      nylas: "nylas".includes(val),
      adtorqueedge: "adtorqueedge".includes(val),
      adtorqueedgeloan: "adtorqueedgeloan".includes(val),
      appraisal_solutions: "appraisal_solutions".includes(val),
      autoit: "autoit".includes(val),
      pmds: "pmds".includes(val),
      keyloop: "keyloop".includes(val),
      bentley_cab: "bentley_cab".includes(val),
      carsales: "carsales".includes(val),
      conversica: "conversica".includes(val),
      creativo: "creativo".includes(val),
      dubizzle: "dubizzle".includes(val),
      fca: "fca".includes(val),
      ferrari_modis: "ferrari_modis".includes(val),
      gumtree: "gumtree".includes(val),
      heroleads: "heroleads".includes(val),
      c2native: "c2native".includes(val),
      gubagoo: "gubagoo".includes(val),
      autoforce: "autoforce".includes(val),
      mattaki: "mattaki".includes(val),
      motorweb: "motorweb".includes(val),
      cap: "cap".includes(val),
      red_book: "red_book".includes(val),
      roi: "roi".includes(val),
      salesforce: "salesforce".includes(val),
      shiftdigital: "shiftdigital".includes(val),
      sms_broadcast: "sms_broadcast".includes(val),
      wildjar: "wildjar".includes(val),
      dubaicars: "dubaicars".includes(val),
      aapwebsite: "aapwebsite".includes(val),
      projectcoavme: "projectcoavme".includes(val),
      nexa: "nexa".includes(val),
      amsme: "amsme".includes(val),
      gforces: "gforces".includes(val),
      revolution: "revolution".includes(val),
      tss: "tss".includes(val),
      pandadoc: "pandadoc".includes(val),
    });
  };

  const disableAllNylasEmail = async (id) => {
    if (!id) {
      setNylasDisableLoader(false);
      return;
    }
    setNylasDisableLoader(true);
    toast.notify(`Nylas integrations are being disabled...`, {
      duration: 2000,
    });
    const nylasCancelAccount = window.firebase
      .functions()
      .httpsCallable("nylassync-cancelAccount");
    const data = await nylasCancelAccount({
      clientID: id,
    });

    //console.log(data)
    if (data.data.success === true) {
      toast.notify(`All nylas integrations have been disabled successfully.`, {
        duration: 2000,
      });
    } else {
      setLoader(false);
      Swal.fire(data.data.message, "", "error");
    }

    setNylasDisableLoader(false);
  };

  return (
    <>
      <h2>
        <Translate text={"Add-ons"} />
      </h2>
      <div className="divider-line"></div>
      <div className="mt-4">
        <div className="settings-head form-style">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-3 pr-0">
                  <ReactSelect
                    options={
                      props.dealersettings.level === "oem" ||
                        _.isEmpty(props.dealersettings.level) ||
                        !_.find(levelOptions, {
                          value: props.dealersettings.level,
                        })
                        ? levelOptions
                        : levelOptions.filter(
                          (e) =>
                            e.index >=
                            _.find(levelOptions, {
                              value: props.dealersettings.level,
                            }).index
                        )
                    }
                    name={"level"}
                    placeholder={"select level"}
                    onChange={(e, data) => {
                      if (e) {
                        if (e.value === "oem") {
                          setLevelName(props.dealersettings?.oemName)
                          setLoader(true);
                        }
                        setSelectedLevel({
                          level: e.value,
                        });
                      } else setSelectedLevel(null);
                    }}
                    value={selectedLevel ? selectedLevel.level : ""}
                    classNamePrefix={`cursor-pointer basic-select`}
                    removeClearable={true}
                  ></ReactSelect>
                </div>
                {!_.isEmpty(selectedLevel) && selectedLevel.level !== "oem" ? (
                  <div className="col-md-3 pr-0">
                    <ReactSelect
                      options={
                        selectedLevel.level === "group"
                          ? groupOptions
                          : selectedLevel.level === "region"
                            ? regionOptions
                            : clientOptions
                      }
                      name={"levelID"}
                      placeholder={`select ${selectedLevel.level === "individual"
                        ? "client"
                        : selectedLevel.level
                        }`}
                      onChange={(e, data) => {
                        if (e) {
                          setLoader(true);
                          setLevelName(e?.label || null)
                          setSelectedLevel({
                            ...selectedLevel,
                            levelID: e.value,
                          });
                        } else setSelectedLevel(null);
                      }}
                      value={selectedLevel ? selectedLevel.levelID : ""}
                      classNamePrefix={`basic-select`}
                      removeClearable={true}
                    ></ReactSelect>
                  </div>
                ) : (
                  <></>
                )}
                <div className="col-md-3 pr-0">
                  <InputText
                    id={`target-id`}
                    autoComplete="off"
                    placeholder={"search integrations"}
                    className={`form-control`}
                    name="id"
                    onChange={handleSearchChange}
                    value={searchText}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader ? (
        <div className="mt-5">
          <ContentOverlay active={true} />
        </div>
      ) : !loader &&
        !_.isEmpty(selectedLevel) &&
        (selectedLevel.level === "oem" || !_.isEmpty(selectedLevel.levelID)) ? (
        <div className="settings-integration-list">
          <div className="col-md-12">
            <div className="row">
              {show.titan && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.titandms} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Titan DMS</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.titan) ||
                      !modules.titan.active ? (
                      <>
                        {modules.titan && modules.titan.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.titan.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.titan.requestedDate
                                      ? moment
                                        .unix(
                                          modules.titan.requestedDate.seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "titan",
                                  images.titandms,
                                  "Titan"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules && modules.titan && modules.titan.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("titan", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["titan"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("titan", true, "Titan");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("titan", true, "Titan");
                        }}
                      >
                        {btnLoader["titan"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.imotor && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.imotor} />
                    </div>
                    <div className="integration-title">
                      <a href="#">I-Motor</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.imotor) ||
                      !modules.imotor.active ? (
                      <>
                        {modules.imotor && modules.imotor.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.imotor.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.imotor.requestedDate
                                      ? moment
                                        .unix(
                                          modules.imotor.requestedDate.seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "imotor",
                                  images.imotor,
                                  "I-Motor"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules && modules.imotor && modules.imotor.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("imotor", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["imotor"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("imotor", true, "I-Motor");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("imotor", true, "I-Motor");
                        }}
                      >
                        {btnLoader["imotor"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.messagemedia && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.messagemedia} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Message Media</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.messagemedia) ||
                      !modules.messagemedia.active ? (
                      <>
                        {modules.messagemedia &&
                          modules.messagemedia.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.messagemedia.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.messagemedia.requestedDate
                                      ? moment
                                        .unix(
                                          modules.messagemedia.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "messagemedia",
                                  images.messagemedia,
                                  "Message Media"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.messagemedia &&
                      modules.messagemedia.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("messagemedia", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["messagemedia"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "messagemedia",
                              true,
                              "Message Media"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen(
                            "messagemedia",
                            true,
                            "Message Media"
                          );
                        }}
                      >
                        {btnLoader["messagemedia"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.driva && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img
                        alt=""
                        src={images.driva}
                        style={{ maxHeight: "60px", maxWidth: "137px" }}
                      />
                    </div>
                    <div className="integration-title">
                      <a href="#">Driva</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.driva) ||
                      !modules.driva.active ? (
                      <>
                        {modules.driva && modules.driva.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.driva.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.driva.requestedDate
                                      ? moment
                                        .unix(
                                          modules.driva.requestedDate.seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen("driva", images.driva, "Driva");
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules && modules.driva && modules.driva.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("driva", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["driva"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("driva", true, "Driva");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("driva", true, "Driva");
                        }}
                      >
                        {btnLoader["driva"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.digitallicence_nsw && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.digitallicence_nsw} />
                    </div>
                    <div className="integration-title">
                      <a href="#">NSW Digital Driver Licence</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.digitallicence_nsw) ||
                      !modules.digitallicence_nsw.active ? (
                      <>
                        {modules.digitallicence_nsw &&
                          modules.digitallicence_nsw.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.digitallicence_nsw.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.digitallicence_nsw.requestedDate
                                      ? moment
                                        .unix(
                                          modules.digitallicence_nsw
                                            .requestedDate.seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "digitallicence_nsw",
                                  images.digitallicence_nsw,
                                  "NSW Digital Driver Licence"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.digitallicence_nsw &&
                      modules.digitallicence_nsw.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("digitallicence_nsw", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["digitallicence_nsw"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "digitallicence_nsw",
                              true,
                              "NSW Digital Driver Licence"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen(
                            "digitallicence_nsw",
                            true,
                            "NSW Digital Driver Licence"
                          );
                        }}
                      >
                        {btnLoader["digitallicence_nsw"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.autogate && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.autogate} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Auto Gate</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.autogate) ||
                      !modules.autogate.active ? (
                      <>
                        {modules.autogate && modules.autogate.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.autogate.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.autogate.requestedDate
                                      ? moment
                                        .unix(
                                          modules.autogate.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "autogate",
                                  images.autogate,
                                  "Auto Gate"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.autogate &&
                      modules.autogate.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("autogate", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["autogate"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "autogate",
                              true,
                              "Auto Gate"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("autogate", true, "Auto Gate");
                        }}
                      >
                        {btnLoader["autogate"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.revolution && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.tune} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Revolution</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.revolution) ||
                      !modules.revolution.active ? (
                      <>
                        {modules.revolution && modules.revolution.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.revolution.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.revolution.requestedDate
                                      ? moment
                                        .unix(
                                          modules.revolution.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "revolution",
                                  images.tune,
                                  "Revolution"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.revolution &&
                      modules.revolution.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("revolution", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["revolution"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "revolution",
                              true,
                              "Revolution"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("revolution", true, "Revolution");
                        }}
                      >
                        {btnLoader["revolution"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.smshub && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.smshub} />
                    </div>
                    <div className="integration-title">
                      <a href="#">SMS Hub</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.smshub) ||
                      !modules.smshub.active ? (
                      <>
                        {modules.smshub && modules.smshub.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.smshub.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.smshub.requestedDate
                                      ? moment
                                        .unix(
                                          modules.smshub.requestedDate.seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "smshub",
                                  images.smshub,
                                  "SMS Hub"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules && modules.smshub && modules.smshub.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("smshub", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["smshub"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("smshub", true, "SMS Hub");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("smshub", true, "SMS Hub");
                        }}
                      >
                        {btnLoader["smshub"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.cmdotcom && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.cmdotcom} />
                    </div>
                    <div className="integration-title">
                      <a href="#">CM.COM</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.cmdotcom) ||
                      !modules.cmdotcom.active ? (
                      <>
                        {modules.cmdotcom && modules.cmdotcom.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.cmdotcom.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.cmdotcom.requestedDate
                                      ? moment
                                        .unix(
                                          modules.cmdotcom.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "cmdotcom",
                                  images.cmdotcom,
                                  "CM.COM"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.cmdotcom &&
                      modules.cmdotcom.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("cmdotcom", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["cmdotcom"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("cmdotcom", true, "SMS Hub");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("cmdotcom", true, "SMS Hub");
                        }}
                      >
                        {btnLoader["cmdotcom"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.podium && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.podium} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Podium</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.podium) ||
                      !modules.podium.active ? (
                      <>
                        {modules.podium && modules.podium.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.podium.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.podium.requestedDate
                                      ? moment
                                        .unix(
                                          modules.podium.requestedDate.seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "podium",
                                  images.podium,
                                  "Podium"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules && modules.podium && modules.podium.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            handlePodiumDisable();
                            enableAddons("podium", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["podium"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("podium", true, "Podium");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          // handleOptionsOpen('podium', true, 'Podium')
                          handlePodiumAuthStart();
                        }}
                      >
                        {btnLoader["podium"] || podiumEnableLoader ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {/* ///// */}
              {show.autotrader && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.autotrader} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Auto Trader</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.autotrader) ||
                      !modules.autotrader.active ? (
                      <>
                        {modules.autotrader &&
                          modules.autotrader.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.autotrader.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.autotrader.requestedDate
                                      ? moment
                                        .unix(
                                          modules.autotrader.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "autotrader",
                                  images.autotrader,
                                  "Auto Trader"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.autotrader &&
                      modules.autotrader.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("autotrader", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["autotrader"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "autotrader",
                              true,
                              "Auto Trader"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("autotrader", true, "Auto Trader");
                        }}
                      >
                        {btnLoader["autotrader"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.dealersolutions && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.dealersolutions} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Dealer solutions</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.dealersolutions) ||
                      !modules.dealersolutions.active ? (
                      <>
                        {modules.dealersolutions &&
                          modules.dealersolutions.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.dealersolutions.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.dealersolutions.requestedDate
                                      ? moment
                                        .unix(
                                          modules.dealersolutions
                                            .requestedDate.seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "dealersolutions",
                                  images.dealersolutions,
                                  "Dealer solutions"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.dealersolutions &&
                      modules.dealersolutions.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("dealersolutions", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["dealersolutions"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "dealersolutions",
                              true,
                              "Dealer solutions"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen(
                            "dealersolutions",
                            true,
                            "Dealer solutions"
                          );
                        }}
                      >
                        {btnLoader["dealersolutions"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.drivechat && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.drivechat} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Drive Chat</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.drivechat) ||
                      !modules.drivechat.active ? (
                      <>
                        {modules.drivechat && modules.drivechat.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.drivechat.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.drivechat.requestedDate
                                      ? moment
                                        .unix(
                                          modules.drivechat.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "drivechat",
                                  images.drivechat,
                                  "Drive Chat"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.drivechat &&
                      modules.drivechat.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("drivechat", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["drivechat"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "drivechat",
                              true,
                              "Drive Chat"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("drivechat", true, "Drive Chat");
                        }}
                      >
                        {btnLoader["drivechat"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.carsguide && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.carsguide} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Cars Guide</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.carsguide) ||
                      !modules.carsguide.active ? (
                      <>
                        {modules.carsguide && modules.carsguide.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.carsguide.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.carsguide.requestedDate
                                      ? moment
                                        .unix(
                                          modules.carsguide.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "carsguide",
                                  images.carsguide,
                                  "Cars Guide"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.carsguide &&
                      modules.carsguide.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("carsguide", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["carsguide"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "carsguide",
                              true,
                              "Drive Chat"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("carsguide", true, "Drive Chat");
                        }}
                      >
                        {btnLoader["carsguide"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.erapower && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.erapower} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Era Power</a>
                    </div>
                    <p className="integration-content">
                      Automotive CRM from Pentana Solutions.
                    </p>
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.erapower) ||
                      !modules.erapower.active ? (
                      <>
                        {modules.erapower && modules.erapower.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.erapower.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.erapower.requestedDate
                                      ? moment
                                        .unix(
                                          modules.erapower.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "erapower",
                                  images.erapower,
                                  "Era Power"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.erapower &&
                      modules.erapower.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("erapower", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["erapower"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "erapower",
                              true,
                              "Era Power"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("erapower", true, "Era Power");
                        }}
                      >
                        {btnLoader["erapower"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.saleslogs && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.saleslogs} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Sales Logs</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.saleslogs) ||
                      !modules.saleslogs.active ? (
                      <>
                        {modules.saleslogs && modules.saleslogs.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.saleslogs.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.saleslogs.requestedDate
                                      ? moment
                                        .unix(
                                          modules.saleslogs.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "saleslogs",
                                  images.saleslogs,
                                  "Sales Logs"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.saleslogs &&
                      modules.saleslogs.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("saleslogs", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["saleslogs"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "saleslogs",
                              true,
                              "Sales Logs"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("saleslogs", true, "Sales Logs");
                        }}
                      >
                        {btnLoader["saleslogs"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {/* {show.ppsr && (
                                    <div className="col-md-4 margin-btm-30">
                                        <div className="integration-box">
                                            <div className="integration-icon"><img alt="" src={images.ppsr} /></div>
                                            <div className="integration-title"><a href="#">PPSR</a></div>
                                             {
                                                _.isEmpty(modules) || _.isEmpty(modules.ppsr) || !modules.ppsr.active ? (
                                                    <>
                                                        {
                                                            modules.ppsr && modules.ppsr.requestedBy
                                                                ?
                                                                <>
                                                                    <OverlayTrigger
                                                                        placement='bottom'
                                                                        overlay={
                                                                            <Tooltip>
                                                                                <><span>Requested by:</span> {CommonHelper.getUserNamebyId(props.clientUsers, modules.ppsr.requestedBy)}</>
                                                                                <br></br>
                                                                                <><span>Requested on:</span> {modules.ppsr.requestedDate ? moment.unix(modules.ppsr.requestedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</>
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <button type="button" className="btn btn-orange" >Requested </button>
                                                                    </OverlayTrigger>
                                                                </>
                                                                :
                                                                <>
                                                                    <button type="button" className="btn btn-primary"
                                                                        onClick={(e) => { e.preventDefault(); handleFormOpen('ppsr', images.ppsr, 'PPSR') }}
                                                                    >Contact Us</button>
                                                                </>
                                                        }

                                                    </>) :
                                                    modules && modules.ppsr && modules.ppsr.enabled ? (
                                                        <div className="d-inline-block">
                                                            <button type="button" className="btn btn-green" onClick={() => {
                                                                enableAddons('ppsr', false)
                                                            }}> <i className="ico icon-check"></i>
                                                                {
                                                                    btnLoader['ppsr'] ?
                                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                        : (<></>)
                                                                }
                                                                Enabled </button>
                                                            <button type="button" className="btn btn-default ml-2 custom-settings-ico" onClick={() => { handleEditOptionsOpen('ppsr', true, 'PPSR') }} ><i className="ico icon-settings"></i></button>
                                                        </div>
                                                    ) : (<button type="button" className="btn btn-primary" onClick={() => {
                                                        handleOptionsOpen('ppsr', true, 'PPSR')
                                                    }}>
                                                        {
                                                            btnLoader['ppsr'] ?
                                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                : (<></>)
                                                        }
                                                        Enable </button>)
                                            }
                                        </div>
                                    </div>
                                )} */}
              {show.ringcentral && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.ringcentral} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Ring Central</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.ringcentral) ||
                      !modules.ringcentral.active ? (
                      <>
                        {modules.ringcentral &&
                          modules.ringcentral.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.ringcentral.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.ringcentral.requestedDate
                                      ? moment
                                        .unix(
                                          modules.ringcentral.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "ringcentral",
                                  images.ringcentral,
                                  "Ring Central"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.ringcentral &&
                      modules.ringcentral.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("ringcentral", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["ringcentral"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "ringcentral",
                              true,
                              "Ring Central"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("ringcentral", true, "Ringcentral");
                          //handleRingCentralAuthStart()
                        }}
                      >
                        {btnLoader["ringcentral"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.aircall && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.aircall} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Aircall</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.aircall) ||
                      !modules.aircall.active ? (
                      <>
                        {modules.aircall && modules.aircall.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.aircall.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.aircall.requestedDate
                                      ? moment
                                        .unix(
                                          modules.aircall.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "aircall",
                                  images.aircall,
                                  "Aircall"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.aircall &&
                      modules.aircall.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("aircall", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["aircall"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("aircall", true, "Aircall");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          //handleOptionsOpen('aircall', true, 'Aircall')
                          handleAircallAuthStart();
                        }}
                      >
                        {btnLoader["aircall"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.justcall && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.justcall} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Justcall</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.justcall) ||
                      !modules.justcall.active ? (
                      <>
                        {modules.justcall && modules.justcall.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.justcall.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.justcall.requestedDate
                                      ? moment
                                        .unix(
                                          modules.justcall.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "justcall",
                                  images.justcall,
                                  "Justcall"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.justcall &&
                      modules.justcall.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("justcall", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["justcall"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("justcall", true, "Justcall");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          //handleOptionsOpen('justcall', true, 'Justcall')
                          handleEditOptionsOpen("justcall", true, "Justcall");
                        }}
                      >
                        {btnLoader["justcall"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.tss && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.tss} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Total Selling Solutions</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.tss) ||
                      !modules.tss.active ? (
                      <>
                        {modules.tss && modules.tss.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.tss.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.tss.requestedDate
                                      ? moment
                                        .unix(
                                          modules.tss.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "tss",
                                  images.tss,
                                  "TSS"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.tss &&
                      modules.tss.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("tss", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["tss"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("tss", true, "TSS");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          //handleOptionsOpen('tss', true, 'tss')
                          handleEditOptionsOpen("tss", true, "TSS");
                        }}
                      >
                        {btnLoader["tss"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.impel && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.impel} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Impel AI</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.impel) ||
                      !modules.impel.active ? (
                      <>
                        {modules.impel && modules.impel.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.impel.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.impel.requestedDate
                                      ? moment
                                        .unix(
                                          modules.impel.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "impel",
                                  images.impel,
                                  "Impel AI"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.impel &&
                      modules.impel.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("impel", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["impel"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("impel", true, "Impel AI");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          //handleOptionsOpen('impel', true, 'Impel AI')
                          handleEditOptionsOpen("impel", true, "Impel AI");
                        }}
                      >
                        {btnLoader["impel"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.mailchimp && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.mailchimp} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Mailchimp</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.mailchimp) ||
                      !modules.mailchimp.active ? (
                      <>
                        {modules.mailchimp && modules.mailchimp.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.mailchimp.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.mailchimp.requestedDate
                                      ? moment
                                        .unix(
                                          modules.mailchimp.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "mailchimp",
                                  images.mailchimp,
                                  "Mailchimp"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.mailchimp &&
                      modules.mailchimp.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("mailchimp", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["mailchimp"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "mailchimp",
                              true,
                              "Mailchimp"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("mailchimp", true, "Mailchimp");
                        }}
                      >
                        {btnLoader["mailchimp"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.mailup && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.mailup} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Mailup</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.mailup) ||
                      !modules.mailup.active ? (
                      <>
                        {modules.mailup && modules.mailup.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.mailup.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.mailup.requestedDate
                                      ? moment
                                        .unix(
                                          modules.mailup.requestedDate.seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "mailup",
                                  images.mailup,
                                  "Mailup"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules && modules.mailup && modules.mailup.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("mailup", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["mailup"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("mailup", true, "Mailup");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("mailup", true, "Mailup");
                        }}
                      >
                        {btnLoader["mailup"] || mailupLoader ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.mailjet && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.mailjet} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Mailjet</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.mailjet) ||
                      !modules.mailjet.active ? (
                      <>
                        {modules.mailjet && modules.mailjet.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.mailjet.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.mailjet.requestedDate
                                      ? moment
                                        .unix(
                                          modules.mailjet.requestedDate.seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "mailjet",
                                  images.mailjet,
                                  "Mailup"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules && modules.mailjet && modules.mailjet.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("mailjet", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["mailjet"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("mailjet", true, "Mailjet");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("mailjet", true, "Mailjet");
                        }}
                      >
                        {btnLoader["mailjet"] || mailupLoader ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.vision6 && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.vision6} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Vision6</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.vision6) ||
                      !modules.vision6.active ? (
                      <>
                        {modules.vision6 && modules.vision6.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.vision6.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.vision6.requestedDate
                                      ? moment
                                        .unix(
                                          modules.vision6.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "vision6",
                                  images.vision6,
                                  "Vision6"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.vision6 &&
                      modules.vision6.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("vision6", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["vision6"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("vision6", true, "Vision6");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("vision6", true, "Vision6");
                        }}
                      >
                        {btnLoader["vision6"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.evalexpert && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.evalexpert} />
                    </div>
                    <div className="integration-title">
                      <a href="#">EvalExpert</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.evalexpert) ||
                      !modules.evalexpert.active ? (
                      <>
                        {modules.evalexpert &&
                          modules.evalexpert.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.evalexpert.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.evalexpert.requestedDate
                                      ? moment
                                        .unix(
                                          modules.evalexpert.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "evalexpert",
                                  images.evalexpert,
                                  "EvalExpert"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.evalexpert &&
                      modules.evalexpert.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("evalexpert", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["evalexpert"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "evalexpert",
                              true,
                              "EvalExpert"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("evalexpert", true, "EvalExpert");
                        }}
                      >
                        {btnLoader["evalexpert"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.nylas && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.nylas} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Nylas</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.nylas) ||
                      !modules.nylas.active ? (
                      <>
                        {modules.nylas && modules.nylas.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.nylas.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.nylas.requestedDate
                                      ? moment
                                        .unix(
                                          modules.nylas.requestedDate.seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen("nylas", images.nylas, "Nylas");
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules && modules.nylas && modules.nylas.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            Swal.fire({
                              title: "Are you sure?",
                              text: "Do you want to disable addon? Disabling this will disable all active users' email accounts which are using this integration.",
                              icon: "info",
                              showCancelButton: true,
                              confirmButtonText: CommonHelper.showLocale(
                                props,
                                "Yes"
                              ),
                              cancelButtonText: CommonHelper.showLocale(
                                props,
                                "No"
                              ),
                            }).then((result) => {
                              if (result.value) {
                                if (
                                  !_.isEmpty(selectedLevel) &&
                                  selectedLevel.level === "individual" &&
                                  selectedLevel.levelID
                                ) {
                                  disableAllNylasEmail(selectedLevel.levelID);
                                }
                                enableAddons("nylas", false);
                              }
                            });
                            // handleDisableOpen('nylas', true, 'Nylas')
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["nylas"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            if (
                              selectedLevel &&
                              selectedLevel.level === "individual" &&
                              selectedLevel.levelID
                            ) {
                              handleDisableOpen("nylas", true, "Nylas");
                            }
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("nylas", true, "Nylas");
                        }}
                      >
                        {btnLoader["nylas"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.pandadoc && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.pandadoc} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Panda Doc</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.pandadoc) ||
                      !modules.pandadoc.active ? (
                      <>
                        {modules.pandadoc && modules.pandadoc.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.pandadoc.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.pandadoc.requestedDate
                                      ? moment
                                        .unix(
                                          modules.pandadoc.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "pandadoc",
                                  images.pandadoc,
                                  "Panda Doc"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.pandadoc &&
                      modules.pandadoc.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("pandadoc", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["pandadoc"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("pandadoc", true, "Panda Doc");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          //handleOptionsOpen('pandadoc', true, 'pandadoc')
                          handleEditOptionsOpen("pandadoc", true, "Panda Doc");
                        }}
                      >
                        {btnLoader["pandadoc"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.adtorqueedge && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.adtorqueedge} />
                    </div>
                    <div className="integration-title">
                      <a href="#">AdTorque Edge</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.adtorqueedge) ||
                      !modules.adtorqueedge.active ? (
                      <>
                        {modules.adtorqueedge &&
                          modules.adtorqueedge.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.adtorqueedge.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.adtorqueedge.requestedDate
                                      ? moment
                                        .unix(
                                          modules.adtorqueedge.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "adtorqueedge",
                                  images.adtorqueedge,
                                  "AdTorque Edge"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.adtorqueedge &&
                      modules.adtorqueedge.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("adtorqueedge", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["adtorqueedge"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "adtorqueedge",
                              true,
                              "AdTorque Edge"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen(
                            "adtorqueedge",
                            true,
                            "AdTorque Edge"
                          );
                        }}
                      >
                        {btnLoader["adtorqueedge"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.adtorqueedgeloan && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.adtorqueedge} />
                    </div>
                    <div className="integration-title">
                      <a href="#">AdTorque Edge Loan</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.adtorqueedgeloan) ||
                      !modules.adtorqueedgeloan.active ? (
                      <>
                        {modules.adtorqueedgeloan &&
                          modules.adtorqueedgeloan.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.adtorqueedgeloan.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.adtorqueedgeloan.requestedDate
                                      ? moment
                                        .unix(
                                          modules.adtorqueedgeloan
                                            .requestedDate.seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "adtorqueedgeloan",
                                  images.adtorqueedgeloan,
                                  "AdTorque Edge"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.adtorqueedgeloan &&
                      modules.adtorqueedgeloan.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("adtorqueedgeloan", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["adtorqueedgeloan"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        {/* <button type="button" className="btn btn-default ml-2 custom-settings-ico" onClick={() => { handleEditOptionsOpen('adtorqueedgeloan', true, 'AdTorque Edge Loan') }} ><i className="ico icon-settings"></i></button> */}
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen(
                            "adtorqueedgeloan",
                            true,
                            "AdTorque Edge"
                          );
                        }}
                      >
                        {btnLoader["adtorqueedgeloan"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.appraisal_solutions && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.appraisal_solutions} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Appraisal Solutions</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.appraisal_solutions) ||
                      !modules.appraisal_solutions.active ? (
                      <>
                        {modules.appraisal_solutions &&
                          modules.appraisal_solutions.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.appraisal_solutions.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.appraisal_solutions.requestedDate
                                      ? moment
                                        .unix(
                                          modules.appraisal_solutions
                                            .requestedDate.seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "appraisal_solutions",
                                  images.appraisal_solutions,
                                  "Appraisal Solutions"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.appraisal_solutions &&
                      modules.appraisal_solutions.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("appraisal_solutions", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["appraisal_solutions"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "appraisal_solutions",
                              true,
                              "Appraisal Solutions"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen(
                            "appraisal_solutions",
                            true,
                            "Appraisal Solutions"
                          );
                        }}
                      >
                        {btnLoader["appraisal_solutions"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.autoit && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.autoit} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Auto-IT</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.autoit) ||
                      !modules.autoit.active ? (
                      <>
                        {modules.autoit && modules.autoit.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.autoit.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.autoit.requestedDate
                                      ? moment
                                        .unix(
                                          modules.autoit.requestedDate.seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "autoit",
                                  images.autoit,
                                  "Auto-IT"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules && modules.autoit && modules.autoit.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("autoit", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["autoit"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("autoit", true, "Auto-IT");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("autoit", true, "Auto-IT");
                        }}
                      >
                        {btnLoader["autoit"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.pmds && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.pmds} />
                    </div>
                    <div className="integration-title">
                      <a href="#">PMDS</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.pmds) ||
                      !modules.pmds.active ? (
                      <>
                        {modules.pmds && modules.pmds.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.pmds.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.pmds.requestedDate
                                      ? moment
                                        .unix(
                                          modules.pmds.requestedDate.seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen("pmds", images.pmds, "PMDS");
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules && modules.pmds && modules.pmds.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("pmds", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["pmds"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("pmds", true, "PMDS");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("pmds", true, "PMDS");
                        }}
                      >
                        {btnLoader["pmds"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.bentley_cab && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.bentley_cab} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Bentley Cab</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.bentley_cab) ||
                      !modules.bentley_cab.active ? (
                      <>
                        {modules.bentley_cab &&
                          modules.bentley_cab.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.bentley_cab.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.bentley_cab.requestedDate
                                      ? moment
                                        .unix(
                                          modules.bentley_cab.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "bentley_cab",
                                  images.bentley_cab,
                                  "Bentley Cab"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.bentley_cab &&
                      modules.bentley_cab.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("bentley_cab", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["bentley_cab"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "bentley_cab",
                              true,
                              "Bentley Cab"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("bentley_cab", true, "Bentley Cab");
                        }}
                      >
                        {btnLoader["bentley_cab"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.keyloop && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.keyloop} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Keyloop</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.keyloop) ||
                      !modules.keyloop.active ? (
                      <>
                        {modules.keyloop && modules.keyloop.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.keyloop.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.keyloop.requestedDate
                                      ? moment
                                        .unix(
                                          modules.keyloop.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "keyloop",
                                  images.keyloop,
                                  "Keyloop"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.keyloop &&
                      modules.keyloop.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("keyloop", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["keyloop"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("keyloop", true, "Keyloop");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("keyloop", true, "Keyloop");
                        }}
                      >
                        {btnLoader["keyloop"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.conversica && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.conversica} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Conversica</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.conversica) ||
                      !modules.conversica.active ? (
                      <>
                        {modules.conversica &&
                          modules.conversica.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.conversica.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.conversica.requestedDate
                                      ? moment
                                        .unix(
                                          modules.conversica.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "conversica",
                                  images.conversica,
                                  "Conversica"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.conversica &&
                      modules.conversica.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("conversica", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["conversica"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "conversica",
                              true,
                              "Conversica"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("conversica", true, "Conversica");
                        }}
                      >
                        {btnLoader["conversica"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.creativo && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.creativo} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Creativo</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.creativo) ||
                      !modules.creativo.active ? (
                      <>
                        {modules.creativo && modules.creativo.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.creativo.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.creativo.requestedDate
                                      ? moment
                                        .unix(
                                          modules.creativo.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "creativo",
                                  images.creativo,
                                  "Creativo"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.creativo &&
                      modules.creativo.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("creativo", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["creativo"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("creativo", true, "Creativo");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("creativo", true, "Creativo");
                        }}
                      >
                        {btnLoader["creativo"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.dubizzle && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.dubizzle} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Dubizzle</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.dubizzle) ||
                      !modules.dubizzle.active ? (
                      <>
                        {modules.dubizzle && modules.dubizzle.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.dubizzle.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.dubizzle.requestedDate
                                      ? moment
                                        .unix(
                                          modules.dubizzle.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "dubizzle",
                                  images.dubizzle,
                                  "Dubizzle"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.dubizzle &&
                      modules.dubizzle.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("dubizzle", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["dubizzle"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("dubizzle", true, "Dubizzle");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("dubizzle", true, "Dubizzle");
                        }}
                      >
                        {btnLoader["dubizzle"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.facebook && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.facebook} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Facebook</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.facebook) ||
                      !modules.facebook.active ? (
                      <>
                        {modules.facebook && modules.facebook.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.facebook.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.facebook.requestedDate
                                      ? moment
                                        .unix(
                                          modules.facebook.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "facebook",
                                  images.facebook,
                                  "Facebook"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.facebook &&
                      modules.facebook.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("facebook", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["facebook"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("facebook", true, "Facebook");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("facebook", true, "Facebook");
                        }}
                      >
                        {btnLoader["facebook"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.autograb && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.autograb} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Autograb</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.autograb) ||
                      !modules.autograb.active ? (
                      <>
                        {modules.autograb && modules.autograb.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.autograb.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.autograb.requestedDate
                                      ? moment
                                        .unix(
                                          modules.autograb.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "autograb",
                                  images.autograb,
                                  "Auto Grab"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.autograb &&
                      modules.autograb.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("autograb", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["autograb"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "autograb",
                              true,
                              "Auto Grab"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("autograb", true, "Auto Grab");
                        }}
                      >
                        {btnLoader["autograb"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}

              {show.fca && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.fca_digital} />
                    </div>
                    <div className="integration-title">
                      <a href="#">FCA Digital</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.fca) ||
                      !modules.fca.active ? (
                      <>
                        {modules.fca &&
                          modules.fca.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.fca.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.fca.requestedDate
                                      ? moment
                                        .unix(
                                          modules.fca.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "fca",
                                  images.fca,
                                  "FCA Digital"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.fca &&
                      modules.fca.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("fca", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["fca"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "fca",
                              true,
                              "FCA Digital"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("fca", true, "FCA Digital");
                        }}
                      >
                        {btnLoader["fca"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.gumtree && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.gumtree} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Gumtree</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.gumtree) ||
                      !modules.gumtree.active ? (
                      <>
                        {modules.gumtree && modules.gumtree.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.gumtree.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.gumtree.requestedDate
                                      ? moment
                                        .unix(
                                          modules.gumtree.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "gumtree",
                                  images.gumtree,
                                  "Gumtree"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.gumtree &&
                      modules.gumtree.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("gumtree", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["gumtree"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("gumtree", true, "Gumtree");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("gumtree", true, "Gumtree");
                        }}
                      >
                        {btnLoader["gumtree"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.heroleads && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.heroleads} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Heroleads</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.heroleads) ||
                      !modules.heroleads.active ? (
                      <>
                        {modules.heroleads && modules.heroleads.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.heroleads.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.heroleads.requestedDate
                                      ? moment
                                        .unix(
                                          modules.heroleads.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "heroleads",
                                  images.heroleads,
                                  "Heroleads"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.heroleads &&
                      modules.heroleads.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("heroleads", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["heroleads"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "heroleads",
                              true,
                              "Heroleads"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("heroleads", true, "Heroleads");
                        }}
                      >
                        {btnLoader["heroleads"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.c2native && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.c2native} />
                    </div>
                    <div className="integration-title">
                      <a href="#">C2 Native</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.c2native) ||
                      !modules.c2native.active ? (
                      <>
                        {modules.c2native && modules.c2native.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.c2native.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.c2native.requestedDate
                                      ? moment
                                        .unix(
                                          modules.c2native.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "c2native",
                                  images.c2native,
                                  "C2 Native"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.c2native &&
                      modules.c2native.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("c2native", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["c2native"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "c2native",
                              true,
                              "C2 Native"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("c2native", true, "C2 Native");
                        }}
                      >
                        {btnLoader["c2native"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.mattaki && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.mattaki} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Mattaki</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.mattaki) ||
                      !modules.mattaki.active ? (
                      <>
                        {modules.mattaki && modules.mattaki.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.mattaki.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.mattaki.requestedDate
                                      ? moment
                                        .unix(
                                          modules.mattaki.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "mattaki",
                                  images.mattaki,
                                  "Mattaki"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.mattaki &&
                      modules.mattaki.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("mattaki", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["mattaki"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("mattaki", true, "Mattaki");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("mattaki", true, "Mattaki");
                        }}
                      >
                        {btnLoader["mattaki"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.motorweb && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.motorweb} />
                    </div>
                    <div className="integration-title">
                      <a href="#">MotorWeb</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.motorweb) ||
                      !modules.motorweb.active ? (
                      <>
                        {modules.motorweb && modules.motorweb.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.motorweb.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.motorweb.requestedDate
                                      ? moment
                                        .unix(
                                          modules.motorweb.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "motorweb",
                                  images.motorweb,
                                  "MotorWeb"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.motorweb &&
                      modules.motorweb.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("motorweb", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["motorweb"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("motorweb", true, "MotorWeb");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("motorweb", true, "MotorWeb");
                        }}
                      >
                        {btnLoader["motorweb"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.cap && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.porsche_cap} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Porsche Cap</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.cap) ||
                      !modules.cap.active ? (
                      <>
                        {modules.cap &&
                          modules.cap.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.cap.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.cap.requestedDate
                                      ? moment
                                        .unix(
                                          modules.cap.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "cap",
                                  images.cap,
                                  "Porsche Cap"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.cap &&
                      modules.cap.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("cap", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["cap"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "cap",
                              true,
                              "Porsche Cap"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("cap", true, "Porsche Cap");
                        }}
                      >
                        {btnLoader["cap"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {/* {show.red_book && (
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.red_book} /></div>
                                    <div className="integration-title"><a href="#">Red Book</a></div>
                                    {
                                        _.isEmpty(modules) || _.isEmpty(modules.red_book) || !modules.red_book.active ? (
                                            <>
                                                {
                                                    modules.red_book && modules.red_book.requestedBy
                                                        ?
                                                        <>
                                                            <OverlayTrigger
                                                                placement='bottom'
                                                                overlay={
                                                                    <Tooltip>
                                                                        <><span>Requested by:</span> {CommonHelper.getUserNamebyId(props.clientUsers, modules.red_book.requestedBy)}</>
                                                                        <br></br>
                                                                        <><span>Requested on:</span> {modules.red_book.requestedDate ? moment.unix(modules.red_book.requestedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</>
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button type="button" className="btn btn-orange" >Requested </button>
                                                            </OverlayTrigger>
                                                        </>
                                                        :
                                                        <>
                                                            <button type="button" className="btn btn-primary"
                                                                onClick={(e) => { e.preventDefault(); handleFormOpen('red_book', images.red_book, 'Red Book') }}
                                                            >Contact Us</button>
                                                        </>
                                                }

                                            </>) :
                                            modules && modules.red_book && modules.red_book.enabled ? (
                                                <div className="d-inline-block">
                                                    <button type="button" className="btn btn-green" onClick={() => { enableAddons('red_book', false) }}> <i className="ico icon-check"></i>
                                                        {
                                                            btnLoader['red_book'] ?
                                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                : (<></>)
                                                        }
                                                    Enabled
                                                </button>
                                                    <button type="button" className="btn btn-default ml-2 custom-settings-ico" onClick={() => { handleEditOptionsOpen('red_book', true, 'Red Book') }} ><i className="ico icon-settings"></i></button>
                                                </div>
                                            )
                                                : (<button type="button" className="btn btn-primary" onClick={() => { handleOptionsOpen('red_book', true, 'Red Book') }}>
                                                    {
                                                        btnLoader['red_book'] ?
                                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                            : (<></>)
                                                    }
                                            Enable </button>)
                                    }
                                </div>
                            </div>
                        )} */}
              {show.roi && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.roi} />
                    </div>
                    <div className="integration-title">
                      <a href="#">ROI</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.roi) ||
                      !modules.roi.active ? (
                      <>
                        {modules.roi && modules.roi.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.roi.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.roi.requestedDate
                                      ? moment
                                        .unix(
                                          modules.roi.requestedDate.seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen("roi", images.roi, "ROI");
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules && modules.roi && modules.roi.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("roi", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["roi"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("roi", true, "ROI");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("roi", true, "ROI");
                        }}
                      >
                        {btnLoader["roi"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.salesforce && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.salesforce} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Salesforce</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.salesforce) ||
                      !modules.salesforce.active ? (
                      <>
                        {modules.salesforce &&
                          modules.salesforce.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.salesforce.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.salesforce.requestedDate
                                      ? moment
                                        .unix(
                                          modules.salesforce.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "salesforce",
                                  images.salesforce,
                                  "Salesforce"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.salesforce &&
                      modules.salesforce.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("salesforce", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["salesforce"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        {modules.salesforce.settings ? (
                          <button
                            type="button"
                            className="btn btn-default ml-2 custom-settings-ico"
                            onClick={() => {
                              handleEditOptionsOpen(
                                "salesforce",
                                true,
                                "Salesforce"
                              );
                            }}
                          >
                            <i className="ico icon-settings"></i>
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("salesforce", true, "Salesforce");
                        }}
                      >
                        {btnLoader["salesforce"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.shiftdigital && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.shiftdigital} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Shift Digital</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.shiftdigital) ||
                      !modules.shiftdigital.active ? (
                      <>
                        {modules.shiftdigital &&
                          modules.shiftdigital.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.shiftdigital.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.shiftdigital.requestedDate
                                      ? moment
                                        .unix(
                                          modules.shiftdigital.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "shiftdigital",
                                  images.shiftdigital,
                                  "Shift Digital"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.shiftdigital &&
                      modules.shiftdigital.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("shiftdigital", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["shiftdigital"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "shiftdigital",
                              true,
                              "Shift Digital"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen(
                            "shiftdigital",
                            true,
                            "Shift Digital"
                          );
                        }}
                      >
                        {btnLoader["shiftdigital"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.sms_broadcast && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.sms_broadcast} />
                    </div>
                    <div className="integration-title">
                      <a href="#">SMS Broadcast</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.sms_broadcast) ||
                      !modules.sms_broadcast.active ? (
                      <>
                        {modules.sms_broadcast &&
                          modules.sms_broadcast.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.sms_broadcast.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.sms_broadcast.requestedDate
                                      ? moment
                                        .unix(
                                          modules.sms_broadcast.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "sms_broadcast",
                                  images.sms_broadcast,
                                  "SMS Broadcast"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.sms_broadcast &&
                      modules.sms_broadcast.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("sms_broadcast", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["sms_broadcast"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "sms_broadcast",
                              true,
                              "SMS Broadcast"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen(
                            "sms_broadcast",
                            true,
                            "SMS Broadcast"
                          );
                        }}
                      >
                        {btnLoader["sms_broadcast"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.wildjar && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.wildjar} />
                    </div>
                    <div className="integration-title">
                      <a href="#">WildJar</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.wildjar) ||
                      !modules.wildjar.active ? (
                      <>
                        {modules.wildjar && modules.wildjar.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.wildjar.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.wildjar.requestedDate
                                      ? moment
                                        .unix(
                                          modules.wildjar.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "wildjar",
                                  images.wildjar,
                                  "WildJar"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.wildjar &&
                      modules.wildjar.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("wildjar", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["wildjar"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("wildjar", true, "WildJar");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("wildjar", true, "WildJar");
                        }}
                      >
                        {btnLoader["wildjar"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.dubaicars && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.dubicars} />
                    </div>
                    <div className="integration-title">
                      <a href="#">DubiCars</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.dubaicars) ||
                      !modules.dubaicars.active ? (
                      <>
                        {modules.dubaicars && modules.dubaicars.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.dubaicars.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.dubaicars.requestedDate
                                      ? moment
                                        .unix(
                                          modules.dubaicars.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "dubaicars",
                                  images.dubicars,
                                  "DubiCars"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.dubaicars &&
                      modules.dubaicars.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("dubaicars", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["dubaicars"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "dubaicars",
                              true,
                              "DubiCars"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("dubaicars", true, "DubiCars");
                        }}
                      >
                        {btnLoader["dubaicars"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.aapwebsite && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.integrations} />
                    </div>
                    <div className="integration-title">
                      <a href="#">AAP Website</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.aapwebsite) ||
                      !modules.aapwebsite.active ? (
                      <>
                        {modules.aapwebsite &&
                          modules.aapwebsite.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.aapwebsite.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.aapwebsite.requestedDate
                                      ? moment
                                        .unix(
                                          modules.aapwebsite.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "aapwebsite",
                                  images.integrations,
                                  "AAP Website"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.aapwebsite &&
                      modules.aapwebsite.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("aapwebsite", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["aapwebsite"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "aapwebsite",
                              true,
                              "AAP Website"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("aapwebsite", true, "AAP Website");
                        }}
                      >
                        {btnLoader["aapwebsite"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.projectcoavme && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.integrations} />
                    </div>
                    <div className="integration-title">
                      <a href="#">ProjectCO AVME</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.projectcoavme) ||
                      !modules.projectcoavme.active ? (
                      <>
                        {modules.projectcoavme &&
                          modules.projectcoavme.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.projectcoavme.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.projectcoavme.requestedDate
                                      ? moment
                                        .unix(
                                          modules.projectcoavme.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "projectcoavme",
                                  images.integrations,
                                  "ProjectCO AVME"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.projectcoavme &&
                      modules.projectcoavme.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("projectcoavme", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["projectcoavme"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "projectcoavme",
                              true,
                              "ProjectCO AVME"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen(
                            "projectcoavme",
                            true,
                            "ProjectCO AVME"
                          );
                        }}
                      >
                        {btnLoader["projectcoavme"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.nexa && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.integrations} />
                    </div>
                    <div className="integration-title">
                      <a href="#">NEXA</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.nexa) ||
                      !modules.nexa.active ? (
                      <>
                        {modules.nexa && modules.nexa.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.nexa.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.nexa.requestedDate
                                      ? moment
                                        .unix(
                                          modules.nexa.requestedDate.seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "nexa",
                                  images.integrations,
                                  "NEXA"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules && modules.nexa && modules.nexa.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("nexa", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["nexa"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("nexa", true, "NEXA");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("nexa", true, "NEXA");
                        }}
                      >
                        {btnLoader["nexa"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.gubagoo && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.gubagoo} />
                    </div>
                    <div className="integration-title">
                      <a href="#">Gubagoo</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.gubagoo) ||
                      !modules.gubagoo.active ? (
                      <>
                        {modules.gubagoo && modules.gubagoo.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.gubagoo.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.gubagoo.requestedDate
                                      ? moment
                                        .unix(
                                          modules.gubagoo.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "gubagoo",
                                  images.gubagoo,
                                  "Gubagoo"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.gubagoo &&
                      modules.gubagoo.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("gubagoo", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["gubagoo"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("gubagoo", true, "Gubagoo");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("gubagoo", true, "Gubagoo");
                        }}
                      >
                        {btnLoader["gubagoo"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.autoforce && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.autoforce} />
                    </div>
                    <div className="integration-title">
                      <a href="#">AutoForce</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.autoforce) ||
                      !modules.autoforce.active ? (
                      <>
                        {modules.autoforce && modules.autoforce.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.autoforce.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.autoforce.requestedDate
                                      ? moment
                                        .unix(
                                          modules.autoforce.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "autoforce",
                                  images.autoforce,
                                  "AutoForce"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.autoforce &&
                      modules.autoforce.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("autoforce", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["autoforce"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen(
                              "autoforce",
                              true,
                              "AutoForce"
                            );
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("autoforce", true, "AutoForce");
                        }}
                      >
                        {btnLoader["autoforce"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.amsme && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.amsme} />
                    </div>
                    <div className="integration-title">
                      <a href="#">AMS ME</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.amsme) ||
                      !modules.amsme.active ? (
                      <>
                        {modules.amsme && modules.amsme.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.amsme.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.amsme.requestedDate
                                      ? moment
                                        .unix(
                                          modules.amsme.requestedDate.seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen("amsme", images.amsme, "AMS ME");
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules && modules.amsme && modules.amsme.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("amsme", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["amsme"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("amsme", true, "amsme");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("amsme", true, "AMS ME");
                        }}
                      >
                        {btnLoader["amsme"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.gforces && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.gforces} />
                    </div>
                    <div className="integration-title">
                      <a href="#">GForces</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.gforces) ||
                      !modules.gforces.active ? (
                      <>
                        {modules.gforces && modules.gforces.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.gforces.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.gforces.requestedDate
                                      ? moment
                                        .unix(
                                          modules.gforces.requestedDate
                                            .seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen(
                                  "gforces",
                                  images.gforces,
                                  "GForces"
                                );
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules &&
                      modules.gforces &&
                      modules.gforces.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("gforces", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["gforces"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("gforces", true, "GForces");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("gforces", true, "GForces");
                        }}
                      >
                        {btnLoader["gforces"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {show.d365 && (
                <div className="col-md-4 margin-btm-30">
                  <div className="integration-box">
                    <div className="integration-icon">
                      <img alt="" src={images.d365} />
                    </div>
                    <div className="integration-title">
                      <a href="#">McLaren Stock d365</a>
                    </div>
                    {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                    {_.isEmpty(modules) ||
                      _.isEmpty(modules.d365) ||
                      !modules.d365.active ? (
                      <>
                        {modules.d365 && modules.d365.requestedBy ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  <>
                                    <span>Requested by:</span>{" "}
                                    {CommonHelper.getUserNamebyId(
                                      props.clientUsers,
                                      modules.d365.requestedBy
                                    )}
                                  </>
                                  <br></br>
                                  <>
                                    <span>Requested on:</span>{" "}
                                    {modules.d365.requestedDate
                                      ? moment
                                        .unix(
                                          modules.d365.requestedDate.seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                      : "--"}
                                  </>
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-orange">
                                Requested{" "}
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFormOpen("d365", images.d365, "D365");
                              }}
                            >
                              Contact Us
                            </button>
                          </>
                        )}
                      </>
                    ) : modules && modules.d365 && modules.d365.enabled ? (
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            enableAddons("d365", false);
                          }}
                        >
                          {" "}
                          <i className="ico icon-check"></i>
                          {btnLoader["d365"] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enabled{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleEditOptionsOpen("d365", true, "D365");
                          }}
                        >
                          <i className="ico icon-settings"></i>
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleOptionsOpen("d365", true, "D365");
                        }}
                      >
                        {btnLoader["d365"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enable{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {
                allintegrations.filter(a => show[a.value] === true).map((rec, index) => {
                  return <div key={index} className="col-md-4 margin-btm-30">
                    <div className="integration-box">
                      <div className="integration-icon">
                        <img alt="" src={images[rec.icon]} />
                      </div>
                      <div className="integration-title">
                        <a href="#">{rec.name}</a>
                      </div>
                      {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                      {_.isEmpty(modules) ||
                        _.isEmpty(modules[rec.value]) ||
                        !modules?.[rec.value]?.active ? (
                        <>
                          {modules[rec.value] && modules[rec.value].requestedBy ? (
                            <>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>
                                    <>
                                      <span>Requested by:</span>{" "}
                                      {CommonHelper.getUserNamebyId(
                                        props.clientUsers,
                                        modules[rec.value].requestedBy
                                      )}
                                    </>
                                    <br></br>
                                    <>
                                      <span>Requested on:</span>{" "}
                                      {modules[rec.value].requestedDate
                                        ? moment
                                          .unix(
                                            modules[rec.value].requestedDate.seconds
                                          )
                                          .format("DD MMM YYYY h:mm A")
                                        : "--"}
                                    </>
                                  </Tooltip>
                                }
                              >
                                <button type="button" className="btn btn-orange">
                                  Requested{" "}
                                </button>
                              </OverlayTrigger>
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleFormOpen(rec.value, images[rec.icon], rec.name);
                                }}
                              >
                                Contact Us
                              </button>
                            </>
                          )}
                        </>
                      ) : modules && modules[rec.value] && modules[rec.value].enabled ? (
                        <div className="d-inline-block">
                          <button
                            type="button"
                            className="btn btn-green"
                            onClick={() => {
                              enableAddons(rec.value, false);
                            }}
                          >
                            {" "}
                            <i className="ico icon-check"></i>
                            {btnLoader[rec.value] ? (
                              <span
                                className="spinner-border spinner-button mr-1"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              <></>
                            )}
                            Enabled{" "}
                          </button>
                          {
                            rec?.fields?.length ? (
                              <button
                                type="button"
                                className="btn btn-default ml-2 custom-settings-ico"
                                onClick={() => {
                                  handleDynOptionsOpen(rec, true, modules?.[rec.value]?.settings);
                                }}
                              >
                                <i className="ico icon-settings"></i>
                              </button>
                            ) : (<></>)
                          }

                        </div>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            if (rec?.fields?.length)
                              handleDynOptionsOpen(rec, true, modules?.[rec.value]?.settings);
                            else
                              enableAddons(rec.value, true);
                          }}
                        >
                          {btnLoader[rec.value] ? (
                            <span
                              className="spinner-border spinner-button mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <></>
                          )}
                          Enable{" "}
                        </button>
                      )}
                    </div>
                  </div>
                })
              }
              {/* Generic Apis */}
              {
                props.dealersettings.superAdmin === true || emailIntgSettings ? (
                  <div className="col-md-4 margin-btm-30">
                    <div className="integration-box">
                      <div className="integration-icon">
                        <img alt="" src={images.emailIntg} />
                      </div>
                      <div className="integration-title">
                        <a href="#">Email Integrations</a>
                      </div>
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            setEmailIntgShow({
                              show: true,
                              title: 'Email Integrations'
                            })
                          }}
                        >
                          &nbsp;Setting&nbsp;<i className="ico icon-settings"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (<></>)
              }
              {
                props.dealersettings.superAdmin === true ? (<>
                  <div className="col-md-4 margin-btm-30">
                    <div className="integration-box">
                      <div className="integration-icon">
                        <img alt="" src={images.genericApi} />
                      </div>
                      <div className="integration-title">
                        <a href="#">Generic API's</a>
                      </div>
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            handleGenericEditOptionsOpen(
                              "genericApis",
                              true,
                              "Generic Apis",
                              integrationKey
                            );
                          }}
                        >
                          &nbsp;Setting&nbsp;<i className="ico icon-settings"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 margin-btm-30">
                    <div className="integration-box">
                      <div className="integration-icon">
                        <img alt="" src={images.googlesheet} />
                      </div>
                      <div className="integration-title">
                        <a href="#">Google Sheet Integrations</a>
                      </div>
                      <div className="d-inline-block">
                        <button
                          type="button"
                          className="btn btn-default ml-2 custom-settings-ico"
                          onClick={() => {
                            setGoogleSheetIntgShow({
                              show: true,
                              title: 'Google Sheet Integrations'
                            })
                          }}
                        >
                          &nbsp;Setting&nbsp;<i className="ico icon-settings"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </>) : (<></>)
              }
            </div>
          </div>
        </div>
      ) : (
        // <div className="common-table" style={{ height: windowSize.windowHeight + 'px' }}>
        <div style={{ height: "45vh" }}>
          <div className="spinner-loader h-100">
            <div
              className="d-flex h-100 justify-content-center align-items-center text-primary"
              style={{ flexDirection: "column" }}
            >
              <div className="no-data-img">
                {" "}
                <img src={_images.nodata} width="60" height="60" alt="" />
              </div>
              <div className="no-data-txt mt-2">
                <p>{`Please select ${selectedLevel
                  ? selectedLevel.level === "individual"
                    ? "client"
                    : selectedLevel.level
                  : ""
                  }`}</p>
              </div>
            </div>
          </div>
        </div>
        // </div>
      )}

      <PopUpModal show={modalRequestShow}>
        <RequestForm
          show={modalRequestShow}
          handleClose={handleFormClose}
          title={
            reqForm && reqForm.title
              ? "Request - " + reqForm.title
              : "Contact Us"
          }
          requests={reqForm}
          clients={clients}
          selectedClient={
            selectedLevel &&
              selectedLevel.levelID &&
              selectedLevel.level === "individual"
              ? selectedLevel.levelID
              : ""
          }
          dealersettings={props.dealersettings}
          clientUsers={props.clientUsers}
          groupUsers={props.groupUsers}
        ></RequestForm>
      </PopUpModal>
      <PopUpModal show={modalOptionsShow.show}>
        <Options
          show={modalOptionsShow.show}
          handleClose={handleOptionsClose}
          title={modalOptionsShow.title}
          isEdit={modalOptionsShow.isEdit}
          setOptions={setOptions}
          integrationKey={integrationKey}
          options={options}
          drivaTC={drivaTC}
          moduleToChange={moduleToChange}
          selectedLevel={selectedLevel}
          dealersettings={props.dealersettings}
          enableAddons={enableAddons}
          authenticateSalesForce={authenticateSalesForce}
          authenticateMailup={authenticateMailup}
          currentSettings={currentSettings}
          levelName={levelName}
          {...props}
        ></Options>
      </PopUpModal>
      <PopUpModal show={modalEmailIntgShow.show}>
        <EmailIntegration
          {...props}
          {...modalEmailIntgShow}
          selectedLevel={selectedLevel}
          integrations={integrations}
          handleClose={() => {
            setEmailIntgShow({ show: false })
          }}
          enableAddons={enableAddons}
          currentSettings={currentSettings}
          levelName={levelName}
        ></EmailIntegration>
      </PopUpModal>
      <PopUpModal show={googleSheetIntgShow.show}>
        <GoogleSheetIntegration
          {...props}
          {...googleSheetIntgShow}
          selectedLevel={selectedLevel}
          integrations={integrations}
          handleClose={() => {
            setGoogleSheetIntgShow({ show: false })
          }}
          enableAddons={enableAddons}
          currentSettings={currentSettings}
          levelName={levelName}
        ></GoogleSheetIntegration>
      </PopUpModal>
      <PopUpModal show={modalGenericIntgShow.show}>
        <GenericIntegration
          {...props}
          {...modalGenericIntgShow}
          selectedLevel={selectedLevel}
          integrations={integrations}
          handleClose={() => {
            setGenericIntgShow({ show: false })
          }}
          enableAddons={enableAddons}
          currentSettings={currentSettings}
          levelName={levelName}
        ></GenericIntegration>
      </PopUpModal>
      <PopUpModal show={modalDisableShow.show}>
        <DisableModal
          show={modalDisableShow.show}
          handleClose={handleDisableClose}
          title={modalDisableShow.title}
          moduleToChange={moduleToChange}
          selectedClient={
            selectedLevel &&
              selectedLevel.levelID &&
              selectedLevel.level === "individual"
              ? selectedLevel.levelID
              : ""
          }
          dealersettings={props.dealersettings}
          clientUsers={props.clientUsers}
          disableAllNylasEmail={disableAllNylasEmail}
          nylasDisableLoader={nylasDisableLoader}
          enableAddons={enableAddons}
          data={
            !_.isEmpty(modules) && modules.nylas && modules.nylas.enabled
              ? modules.nylas
              : {}
          }
        ></DisableModal>
      </PopUpModal>
      <PopUpModal show={showUpdateGenericApi}>
        <UpdateGenericApiModal
          show={showUpdateGenericApi}
          dealersettings={props.dealersettings}
          handleClose={handleModalClose}
          selectedLevel={selectedLevel}
        ></UpdateGenericApiModal>
      </PopUpModal>
      <PopUpModal show={modalDynOptionsShow.show}>
        <DynOptions
          {...props}
          {...modalDynOptionsShow}
          handleClose={handleOptionsClose}
          moduleToChange={moduleToChange}
          selectedLevel={selectedLevel}
          enableAddons={enableAddons}
        ></DynOptions>
      </PopUpModal>
    </>
  );
};

export default IntegrationAddOns;
