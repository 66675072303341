/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            filter: {
                iSearch: '',
            }
        };
    }

    static applyFilter = (listings, filter) => {
        const { iSearch } = filter
        let result = listings

        if (iSearch) {
            result = result.filter(item =>
                item.name.toLowerCase().startsWith(iSearch) ||
                (item.clientName ? item.clientName.toLowerCase().includes(iSearch) : false) ||
                (item.role ? item.role.toLowerCase().includes(iSearch) : false))
        }
        return result
    }

    componentDidMount() {
        const { clientUsers } = this.props;
        console.log('componentDidMount', clientUsers)
        if (clientUsers) {
            this.setState({
                users: clientUsers.filter(e => e.active)
            });
        }
    }

    handleChange = (prop, value) => {
        let state = this.state.filter;
        state[prop] = value;
        this.setState({ filter: state });
    }

    render() {
        const { iSearch } = this.state.filter
        const { users, filter } = this.state
        const filteredList = Users.applyFilter(users, filter)
        const { show, showQueue, dealersettings, groupUsers } = this.props;
        const divStyle = {
            height: '450px',
            overflow: 'auto'
        };
        //const stockLocations = dealersettings?.client?.clientSettings?.stockLocations;
        //console.log('stockLocations', filteredList, groupUsers, stockLocations)
        return (
            <>
                <Modal
                    id="userList"
                    show={show}
                    onHide={this.props.handleUserClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-terms">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" ><Translate text={this.props.title} /></h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <>
                            <div className={`vehicle-popover-head pr-0`}>
                                <input
                                    //className="form-control"
                                    type="text"
                                    id="isearch"
                                    aria-label="Search"
                                    placeholder="search..."
                                    value={iSearch}
                                    autoComplete="off"
                                    onChange={event =>
                                        this.handleChange('iSearch', event.target.value)
                                    }
                                />
                            </div>

                            <div style={divStyle}>
                                {

                                    (!_.isEmpty(filteredList) && filteredList.length) > 0
                                        ?
                                        <>
                                            {_.uniqBy(filteredList, 'id').map(_user =>
                                                <div key={_user.id} className="frontdesk-rearrange-box frontdesk-flex-queue" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.handleUserClose(_user.id, _user)

                                                }}>
                                                    <div className="queue-drag">
                                                        {
                                                            (_user.queueNum && showQueue)
                                                                ?
                                                                <div className="list-grid-count float-right"> <span className="badge badge-pill badge-grid-queue ml-1">{_user.queueNum}</span> </div>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                    <div className={`frontdesk-rearrange-avatar`}>
                                                        {/* <div className="frontdesk-rearrange-count"> <span className="badge badge-pill badge-frontdesk-queue ml-1">{index + 1}</span> </div> */}
                                                        <img src={CommonHelper.showUserAvatar(_user.profileImage, _user.name)} alt="" className="img-object-fit" /> </div>
                                                    <div className="frontdesk-rearrange-detail">
                                                        <h3>{_user.name}</h3>
                                                        <h5>
                                                            {_user.role ? _user.role : '     '}
                                                            {/* {(_user.clientID && (!_.isEmpty(stockLocations) || _user.clientID !== dealersettings?.client?.id || localStorage.defaultModule === 'oem')) &&
                                                                (
                                                                    <div className="customer-avatar-titlesub blue-color"><i className="ico icon-group"></i><strong>{CommonHelper.getOtherDealerName(dealersettings, _user.clientID)}</strong></div>
                                                                )} */}
                                                            {/* {_user.departmentName ? _user.departmentName : _user.department} */}
                                                        </h5>
                                                    </div>

                                                </div>
                                            )}</>
                                        :
                                        <>
                                            <div className="spinner-loader h-100 p-5">
                                                <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                    <div className="no-cafe-img">
                                                        <i className="ico icon-Contacts"></i> </div>
                                                    <div className="no-data-txt mt-2"> <Translate text={'noUserFound'} /></div>
                                                </div>

                                            </div>
                                        </>
                                }

                            </div>

                        </>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

}

export default Users;