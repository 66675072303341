/** LIBRARIES */
import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';
/** COMPONENTS */
import Translate from '../../constants/translate';
import {
    PopUpModal, InputCheckBox, InputMultipleText,
    ReactSelect, InputText, ReactMultiSelect,
    AntDateRangePicker, AntMonthPicker
} from '../../components';
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';

import SaveFilter from '../filters/saveFilter'

import { activityLogOptions, objModules } from './viewModel'
import { userLevelOptions } from '../eventsPro/viewModel';

//#region  ASSIGN VARIABLES
let campaigns = [];
let origins = [];
let enquiryTypes = [];
let serviceTypes = [];
let leadSources = [];
let enquiryOptionsStatic = [];
let financeStatuses = [];
let afterMarketStatuses = [];
let nationalities = [];
let labels = [];

let genders = [];
let sale_type = [];
let vehicleYears = [];
let groupClients = [];
let enquiryOptionsDF = [];
// const labels = [
//     { active: true, value: 'hot', label: <div className={`badge badge-pill badge-hot`}><Translate text={'hot'} upperCase={true} /></div> },
//     { active: true, value: 'warm', label: <div className={`badge badge-pill badge-warm`}><Translate text={'warm'} upperCase={true} /></div> },
//     { active: true, value: 'cold', label: <div className={`badge badge-pill badge-cold`}><Translate text={'cold'} upperCase={true} /></div> }
// ]
/*let enquirySalesStatus = [
    { active: true, value: 'open', label: <div className={`badge badge-pill badge-status-open`}>OPEN</div> },
    { active: true, value: 'won', label: <div className={`badge badge-pill badge-status-won`}>WON</div> },
    { active: true, value: 'delivered', label: <div className={`badge badge-pill badge-status-delivered`}>DELIVERED</div> },
    { active: true, value: 'lost', label: <div className={`badge badge-pill badge-status-lost`}>LOST</div> },
    { active: true, value: 'pendingLost', label: <div className={`badge badge-pill badge-status-pendinglost`}>PENDING LOST APPROVAL</div> },
    { active: true, value: 'prospectLost', label: <div className={`badge badge-pill badge-status-lost`}>PROSPECT LOST</div> }
]*/

let enquiryAfterSalesStatus = [
    { active: true, value: 'open', label: <div className={`badge badge-pill badge-status-open`}><Translate text={'open'} upperCase={true} /></div> },
    { active: true, value: 'lost', label: <div className={`badge badge-pill badge-status-lost`}><Translate text={'lost'} upperCase={true} /></div> },
    { active: true, value: 'closeWon', label: <div className={`badge badge-pill badge-status-won`}><Translate text={'closeWon'} upperCase={true} /></div> }
]

const contact_type = [
    { active: true, value: 'Current Customer', label: <div className={`badge badge-pill badge-input-white`}><Translate text={'Current Customer'} /></div> },
    { active: true, value: 'Potential Customer', label: <div className={`badge badge-pill badge-input-white`}><Translate text={'Potential Customer'} /></div> }
]
// const genders = [
//     { active: true, value: 'Male', label: 'Male' },
//     { active: true, value: 'Female', label: 'Female' },
//     { active: true, value: 'Other', label: 'Other' },
// ]
const vehicle_search_type = [
    { active: true, value: 'requirement', label: <Translate text={'Requirement Model'} /> },
    { active: true, value: 'testdrive', label: <Translate text={'Test Drive Model'} /> }
]

const dateOptions = [
    { active: true, value: 'today', label: <Translate text={'today'} /> },
    { active: true, value: 'yesterday', label: <Translate text={'Yesterday'} /> },
    { active: true, value: 'thisWeek', label: <Translate text={'This Week'} /> },
    { active: true, value: 'lastWeek', label: <Translate text={'Last Week'} /> },
    { active: true, value: 'thisMonth', label: <Translate text={'This Month'} /> },
    { active: true, value: 'lastMonth', label: <Translate text={'Last Month'} /> },
    { active: true, value: 'custom', label: <Translate text={'Custom'} /> }
]

const dateTypes = [
    { active: true, value: 'all', label: <Translate text={'all'} /> },
    { active: true, value: 'stageDate', label: <Translate text={'stageDate'} /> },
    { active: true, value: 'addedDate', label: <Translate text={'addedDate'} /> },
    { active: true, value: 'modifiedDate', label: <Translate text={'modifiedDate'} /> },
    { active: true, value: 'convertedDate', label: <Translate text={'convertedDate'} /> },
    { active: true, value: 'wonDate', label: <Translate text={'wonDate'} /> },
    { active: true, value: 'lostDate', label: <Translate text={'lostDate'} /> },
    { active: true, value: 'deliveredDate', label: <Translate text={'deliveredDate'} /> },
    { active: true, value: 'deliveryDate', label: <Translate text={'deliveryDate'} /> },
    { active: true, value: 'verifiedDate', label: <Translate text={'verifiedDate'} /> },
    { active: true, value: 'confirmedDate', label: <Translate text={'confirmedDate'} /> },
    { active: true, value: 'testdrive', label: <Translate text={'Test Drive Date'} /> },
    { active: true, value: 'tradein', label: <Translate text={'Trade-In Date'} /> },
    { active: true, value: 'walkinDate', label: <Translate text={'walkinDate'} /> },
    { active: true, value: 'warrantyStartDate', label: <Translate text={'warrantyStartDate'} /> },
    { active: true, value: 'warrantyExpiry', label: <Translate text={'warrantyExpiry'} /> },
]

const objDateField = {
    name: null,
    type: null,
    range: null
}

const rottenOptions = [
    { active: true, value: '=', label: <Translate text={'Equal To'} /> },
    { active: true, value: '>', label: <Translate text={'Greater Than'} /> },
    { active: true, value: '<', label: <Translate text={'Less Than'} /> },
]

const __financestatus = [
    { active: true, value: 'InProgress', label: <div className={`badge badge-pill badge-status-inprogress`}><Translate text={'InProgress'} upperCase={true} /></div> },
    { active: true, value: 'Pending', label: <div className={`badge badge-pill badge-status-pending`}><Translate text={'Pending'} upperCase={true} /></div> },
    { active: true, value: 'Won', label: <div className={`badge badge-pill badge-status-won`}><Translate text={'won'} upperCase={true} /></div> },
    { active: true, value: 'Lost', label: <div className={`badge badge-pill badge-status-lost`}><Translate text={'lost'} upperCase={true} /></div> }
]

const activityTaskOptions = [
    { active: true, value: 'overdue', label: <Translate text={'overdue'} /> },
    { active: true, value: 'today', label: <Translate text={'today'} /> },
    { active: true, value: 'upcoming', label: <Translate text={'upcoming'} /> },
    { active: true, value: 'completed', label: <Translate text={'completed'} /> },
    { active: true, value: 'contacted', label: <Translate text={'contacted'} /> },
    { active: true, value: 'notContacted', label: <Translate text={'notContacted'} /> },
    { active: true, value: 'noactivity', label: <Translate text={'noactivity'} /> },
]

const yesNoOptions = [
    { active: true, value: 'Yes', label: 'Yes' },
    { active: true, value: 'No', label: 'No' }
]

const contact_phone = [
    { active: true, value: 'contactswithPhone', label: <Translate text={'contactswithPhone'} /> },
    { active: true, value: 'contactswithoutPhone', label: <Translate text={'contactswithoutPhone'} /> }
]

const contact_email = [
    { active: true, value: 'contactswithEmail', label: <Translate text={'contactswithEmail'} /> },
    { active: true, value: 'contactswithoutEmail', label: <Translate text={'contactswithoutEmail'} /> }
]

const boolTypes = ['isFav', 'isPendingLostApproval', 'leadswithoutContact', 'leadswithoutReq', 'isContact', 'isVehicle', 'isTestDrive', 'isTradeIn', 'isFinance', 'isVideo', 'isVIP', 'isFinanceRequired']
//#endregion

const PipelineAdvanceFilters = (props) => {
    const [filterFields, setFilterFields] = useState({})
    const [clientUsers, setClientUsers] = useState([])
    const [financeUsers, setFinanceUsers] = useState([])
    const [afterMarketUsers, setAfterMarketUsers] = useState([])
    const [inboundUsers, setInboundUsers] = useState([])
    const [otherUsers, setOtherUsers] = useState([])
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [types, setTypes] = useState([])
    const [modelGroups, setModelGroups] = useState([])

    const [dealers, setDealers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [regions, setRegions] = useState([]);
    const [subregions, setSubRegions] = useState([]);

    const [lostReasons, setLostReasons] = useState([])
    const [lostSubReasons, setLostSubReasons] = useState([])
    const [showSaveFilter, setShowSaveFilter] = useState(false)
    const [activityTypes, setActivityTypes] = useState(activityLogOptions);
    const [inboundSources, setInboundSources] = useState([])
    const [inboundSubTypes, setInboundSubTypes] = useState([])
    const [fusionEvents, setFusionEvents] = useState([])

    const { columnFields } = props;

    const [enquiryAllStatus, setAllStatus] = useState([]);
    const [stages, setStages] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])

    let _enquiryStatus = localStorage.defaultModule === 'afterSales' ? enquiryAfterSalesStatus : enquiryAllStatus;
    const accessToFinanceTab = (props.dealersettings?.rolePermissions?.permissions?.accessToFinanceManager ? true : false);
    const accessToAfterMarketTab = (props.dealersettings?.rolePermissions?.permissions?.accessToAfterMarketTab ? true : false);

    const modules = (!_.isEmpty(props.dealersettings?.client?.settings) &&
        !_.isEmpty(props.dealersettings.client.settings.pipelines.filter(e => e.default)[0])) ?
        props.dealersettings.client.settings.pipelines.filter(e => e.default)[0].modules :
        objModules;

    const financeEnabled = ((!_.isEmpty(props?.dealersettings?.client?.moduleSettings?.finance) &&
        props.dealersettings.client.moduleSettings.finance.enabled) ? true : false);

    const drivaEnabled = ((!_.isEmpty(props.dealersettings?.client?.integrations) &&
        props.dealersettings.client.integrations.filter(e => e.type === "driva")[0] &&
        props.dealersettings.client.integrations.filter(e => e.type === "driva")[0].active === true &&
        props.dealersettings.client.integrations.filter(e => e.type === "driva")[0].enabled === true
    ) ? true : false);

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    let _userLevelOptions = userLevelOptions.filter(e => e.value !== 'oem' && e.index >= _.find(userLevelOptions, { value: props.dealersettings.level })?.index);
    let _userLevel = _userLevelOptions.map(r => { return r.value })
    let _userLevelName = _userLevelOptions.map(r => { return r.label === 'Individual' ? 'Client' : r.label }).join('/')
    //console.log('_userLevel',_userLevelName, _userLevel)

    useEffect(() => {
        if (!props.dealersettings?.client?.moduleSettings?.fusionevents?.enabled)
            return;

        const fetchData = async () => {
            const { dealersettings } = props;
            let _searchObject = {
                type: "getFusionEvents",
                params: JSON.stringify({ clientID: dealersettings?.client?.id })
            }
            const getFusionEvents = window.firebase.functions().httpsCallable('generic-getData');
            let _resp = await getFusionEvents(_searchObject);
            let _data = _resp?.data?.data;
            let _fusionevents = [];
            _data && _data.forEach(doc => {
                _fusionevents.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            setFusionEvents(_fusionevents);
            //console.log('getFusionEvents', _fusionevents);

        }

        if (props.dealersettings?.client?.moduleSettings?.fusionevents?.enabled)
            fetchData();
    }, [])

    useEffect(() => {
        const { pipelineFilter } = props;
        if (!_.isEmpty(pipelineFilter))
            setFilterFields(pipelineFilter.value);
        else
            setFilterFields({});

    }, [props.pipelineFilter])

    useEffect(() => {
        //console.log('props.pipeline', props.pipeline)
        if (_.isEmpty(props.pipeline))
            return;

        let _stages = [];
        let defaultstages = props.pipeline.filter(e => (props.pipelineView && props.pipelineView === 'INBOUND') ? e.value === 'LeadsBucket' : (e.value !== 'LeadsBucket' && e.default === true))[0].stages;
        defaultstages.filter(item => item.value !== 'Converted').forEach((doc) => {
            _stages.push({
                value: doc.value,
                label: doc.name
            });
        });

        setStages(_stages);

    }, [props.pipeline])

    useEffect(() => {
        const { dealersettings, pipelineFilter } = props;

        if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            const accessToSelectAllKPIs = true;//dealersettings?.rolePermissions?.permissions?.accessToSelectAllKPIs;
            let excludeOrigins = Object.assign([], dealersettings?.rolePermissions?.excludeOrigins)
            const setttings = dealersettings.client.settings;
            const brands = dealersettings.client.brands;
            const _makes = [];
            const _lostReasons = [];
            const _inboundSources = [];
            const _stages = [];

            let _fields = !_.isEmpty(pipelineFilter) ? pipelineFilter.value : {};

            let defaultMakes = props?.pipeline?.filter(e => (props.pipelineView && props.pipelineView === 'INBOUND') ? e.value === 'LeadsBucket' : (e.value !== 'LeadsBucket' && e.default === true))[0]?.makes;


            enquiryOptionsDF = [];
            if (setttings.enquiryOptionsDF)
                enquiryOptionsDF = setttings.enquiryOptionsDF;

            // const _pipelines = [];
            // setttings.pipelines.forEach((doc) => {
            //     _pipelines.push({
            //         value: doc.value,
            //         label: doc.name
            //     });
            // });

            vehicleYears = [];
            for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
                vehicleYears.push({
                    value: i.toString(),
                    label: i.toString(),
                    active: true,
                });
            }

            origins = [];
            setttings.origins && setttings.origins.forEach(doc => {
                origins.push({
                    value: doc.value,
                    active: (!_.isEmpty(excludeOrigins) && excludeOrigins.includes(doc.value)) ? false : _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    isDisabled: (!_.isEmpty(excludeOrigins) && excludeOrigins.includes(doc.value)) ? true : accessToSelectAllKPIs ? false : Boolean(doc.autoSelect)
                });
            });

            enquiryTypes = [];
            setttings.enquiryTypes && setttings.enquiryTypes.forEach(doc => {
                enquiryTypes.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            labels = [];
            setttings.enquiryLabels && setttings.enquiryLabels.forEach(doc => {
                labels.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: <div className={`badge badge-pill`} style={{
                        color: `${doc.color ? doc.color : '#f47815 '}`,
                        backgroundColor: `${doc.color ? doc.color + '1f' : '#f478151f'}`,
                        boxShadow: `inset 0 0 0 1px ${doc.color ? doc.color : '#000'},0 1px 2px rgba(38,41,44,.08)`
                    }}>{doc.name.toUpperCase()}</div>,
                });
            });

            serviceTypes = [];
            setttings.serviceTypes && setttings.serviceTypes.forEach(doc => {
                serviceTypes.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            leadSources = [];
            setttings.leadSource && setttings.leadSource.forEach(doc => {
                leadSources.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            nationalities = [];
            dealersettings.nationalities && dealersettings.nationalities.forEach((doc) => {
                nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            enquiryOptionsStatic = [];
            setttings.enquiryOptionsStatic && setttings.enquiryOptionsStatic.forEach(doc => {
                enquiryOptionsStatic.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    name: doc.name,
                    label: doc.name,
                });
            });

            genders = [];
            setttings.genders && setttings.genders.forEach(doc => {
                genders.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            sale_type = [];
            setttings.salesType && setttings.salesType.forEach(doc => {
                sale_type.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            financeStatuses = [];
            setttings.financeStatus && setttings.financeStatus.forEach(doc => {
                financeStatuses.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
                });
            });

            afterMarketStatuses = [];
            setttings.afterMarketStatus && setttings.afterMarketStatus.forEach(doc => {
                afterMarketStatuses.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
                });
            });

            brands.filter((doc) => !_.isEmpty(defaultMakes) ? defaultMakes.includes(doc.value) : true).forEach((doc) => {
                _makes.push({
                    value: doc.value,
                    label: doc.name,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    models: doc.models
                });
            });

            if ((!_.isEmpty(_fields) && _fields.hasOwnProperty('make') && _fields.make)) {
                const models = [];
                const types = [];
                const modelGroups = [];

                let _models = !_.isEmpty(_makes.filter(m => m.value === _fields.make)[0]) ? _makes.filter(m => m.value === _fields.make)[0].models : [];

                _models && _models.filter((model, index) => {
                    if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                        types.push({
                            value: model.type,
                            label: model.type,
                            active: _.isBoolean(model.active) ? model.active : true,
                        });
                    }

                    if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                        modelGroups.push({
                            value: model.group,
                            active: _.isBoolean(model.active) ? model.active : true,
                            label: model.group
                        });
                    }
                })

                _models && _models.forEach((data, index) => {
                    models.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: CommonHelper.displayModelName(data),
                    });
                });
                setTypes(_.orderBy(types, ['label'], ['asc']));
                setModelGroups(_.orderBy(modelGroups, ['label'], ['asc']));
                setModels(_.orderBy(models, ['label'], ['asc']));
            }

            let defaultstages = setttings?.pipelines?.filter(e => (props.pipelineView && props.pipelineView === 'INBOUND') ? e.value === 'LeadsBucket' : (e.value !== 'LeadsBucket' && e.default === true))?.[0]?.stages;
            if (props.pipeline)
                defaultstages = props?.pipeline?.filter(e => (props.pipelineView && props.pipelineView === 'INBOUND') ? e.value === 'LeadsBucket' : (e.value !== 'LeadsBucket' && e.default === true))?.[0]?.stages;

            if (defaultstages) {
                defaultstages.filter(item => item.value !== 'Converted').forEach((doc) => {
                    _stages.push({
                        value: doc.value,
                        label: doc.name
                    });
                });
            }
            // if (!_.isEmpty(_fields) && _fields.hasOwnProperty('pipeline') &&
            //     _fields['pipeline'] &&
            //     !_fields['pipeline'].includes(',')) {
            //     let defaultstages = setttings.pipelines.filter(e => e.value === _fields['pipeline'])[0].stages;
            //     defaultstages.filter(item => item.value !== 'Converted').forEach((doc) => {
            //         _stages.push({
            //             value: doc.value,
            //             label: doc.name
            //         });
            //     });
            // }

            setttings.lostReasons && setttings.lostReasons.forEach(doc => {
                _lostReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });
            });

            dealersettings.client.integrations && dealersettings.client.integrations.filter(e => e.inbound === true && e.enabled === true && e.active === true && !Boolean(e.appraisal)).forEach(doc => {
                _inboundSources.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });
            });

            if (!_.isEmpty(_fields) && _fields.hasOwnProperty('lostReason') && _fields.lostReason &&
                !_.isEmpty(setttings.lostReasons.filter(e => e.value === _fields.lostReason)[0])) {
                const _lostSubReasons = [];
                let _subList = !_.isEmpty(setttings.lostReasons.filter(e => e.value === _fields.lostReason)[0].subList) ?
                    setttings.lostReasons.filter(e => e.value === _fields.lostReason)[0].subList : null;

                !_.isEmpty(_subList) && _subList.forEach(doc => {
                    _lostSubReasons.push({
                        value: doc.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
                setLostSubReasons(_lostSubReasons);
            }

            if (!_.isEmpty(_fields) && _fields.hasOwnProperty('inboundSource') && _fields.inboundSource &&
                !_.isEmpty(_inboundSources.filter(e => e.value === _fields.inboundSource)[0])) {
                const _inboundSubTypes = [];
                let _subList = !_.isEmpty(_inboundSources.filter(e => e.value === _fields.inboundSource)[0].subList) ?
                    _inboundSources.filter(e => e.value === _fields.inboundSource)[0].subList : null;

                !_.isEmpty(_subList) && _subList.forEach(doc => {
                    _inboundSubTypes.push({
                        value: doc.Name,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.Name
                    });
                });
                setInboundSubTypes(_inboundSubTypes);
            }

            const viewOtherEnquiry = (dealersettings?.rolePermissions?.permissions?.viewOtherEnquiry ? true : false);

            const _clientUsers = [];
            if (props.clientUsers) {
                let ___clientUsers = props.clientUsers;
                if (!viewOtherEnquiry)
                    ___clientUsers = ___clientUsers.filter(m => m.id === localStorage.uid);

                ___clientUsers.forEach((doc) => {
                    _clientUsers.push({
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        searchlabel: doc.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(doc.profileImage, doc.name)} alt="" width="50" className="img-object-fit" />
                            {doc.name}
                        </div>
                    });
                });
            }

            const _otherUsers = [];
            if (!_.isEmpty(props.groupUsers)) {
                props.groupUsers.forEach((doc) => {
                    if (!_.find(_clientUsers, { value: doc.id })) {
                        _otherUsers.push({
                            value: doc.id,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            searchlabel: doc.name,
                            label: <div className="drop-image">
                                <img src={CommonHelper.showUserAvatar(doc.profileImage, doc.name)} alt="" width="50" className="img-object-fit" />
                                {doc.name}
                            </div>
                        });
                    }
                });
            }

            let options = [];
            let _financeUsers = [];
            let _amUsers = [];
            if (!_.isEmpty(dealersettings.client.roles)) {
                let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.pipeline && e.pipeline.some(m => m === 'LeadsBucket')), function (obj) {
                    return obj.documentID;
                })];

                let roleFMIds = [..._.map(dealersettings.client.roles.filter(e => e.permissions && e.permissions.accessToBusinessManager === true), function (obj) {
                    return obj.documentID;
                })];

                let roleAMMIds = [..._.map(dealersettings.client.roles.filter(e => e.permissions && e.permissions.accessToAfterMarketBM === true), function (obj) {
                    return obj.documentID;
                })];

                let _selectedUsers = [];
                if (!_.isEmpty(roleIds))
                    _selectedUsers = _.filter(props.clientUsers, (v) => _.includes(roleIds, v.roleID));

                let _selectedFMUsers = [];
                if (!_.isEmpty(roleFMIds))
                    _selectedFMUsers = _.filter(props.clientUsers, (v) => _.includes(roleFMIds, v.roleID));

                let _selectedAMMUsers = [];
                if (!_.isEmpty(roleAMMIds))
                    _selectedAMMUsers = _.filter(props.clientUsers, (v) => _.includes(roleAMMIds, v.roleID));

                _selectedFMUsers && _selectedFMUsers.map(rec => {
                    return _financeUsers.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })

                _selectedAMMUsers && _selectedAMMUsers.map(rec => {
                    return _amUsers.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })

                _selectedUsers && _selectedUsers.map(rec => {
                    return options.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })
            }


            let _dealers = [];
            if (dealersettings.group && dealersettings.group.clients)
                _dealers = Object.keys(dealersettings.group.clients).map(cid => {
                    return {
                        //...dealersettings.group.clients[cid],
                        value: cid,
                        label: dealersettings.group.clients[cid].name,
                        group: dealersettings.group.clients[cid].group,
                        region: dealersettings.group.clients[cid].region,
                        subregion: dealersettings.group.clients[cid].subregion
                    }
                })

            let _subregions = [];
            dealersettings.subregions && dealersettings.subregions.forEach((doc) => {
                if (_dealers && _dealers.some(client => client.subregion === doc.id)) {
                    _subregions.push({
                        ...doc,
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                }
            });

            let _regions = [];
            dealersettings.regions && dealersettings.regions.forEach((doc) => {
                if (_dealers && _dealers.some(client => client.region === doc.id)) {
                    _regions.push({
                        ...doc,
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                }
            });

            let _groups = [];
            dealersettings.groups && dealersettings.groups.forEach((doc) => {
                if (_dealers && _dealers.some(client => client.group === doc.id)) {
                    _groups.push({
                        ...doc,
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                }
            });



            const _activityTypes = [];
            setttings.activityTypes && setttings.activityTypes.filter(a => a.active === true).forEach(doc => {
                _activityTypes.push(
                    {
                        label: (<><i className={`ico icon-${doc.icon} mr-1`}></i>{doc.name}</>),
                        name: doc.name,
                        value: doc.value,
                        active: true,
                    });
            });

            if (!_.isEmpty(_activityTypes))
                setActivityTypes(_activityTypes);

            let _enquiryAllStatus = [];
            setttings.enquiryStatus && setttings.enquiryStatus.forEach((doc) => {
                _enquiryAllStatus.push({
                    ...doc,
                    label: <div className={`badge badge-pill badge-status-${doc.value === 'open' ? 'open' : 'empty'}`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name.toUpperCase()}</div>,
                });
            });
            setAllStatus(_enquiryAllStatus);


            dateTypes.forEach((obj) => {
                if (obj.value === 'wonDate') {
                    obj.label = CommonHelper.getNameByValue(_enquiryAllStatus, 'won', 'Won') + ' Date';
                }
                else if (obj.value === 'deliveredDate') {
                    obj.label = CommonHelper.getNameByValue(_enquiryAllStatus, 'delivered', 'Delivered') + ' Date';
                }
                else if (obj.value === 'lostDate') {
                    obj.label = CommonHelper.getNameByValue(_enquiryAllStatus, 'lost', 'Lost') + ' Date';
                }
            });

            dealersettings.countries && dealersettings.countries.forEach((doc) => {
                countries.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });
            if (!_.isEmpty(_fields) && _fields.hasOwnProperty('country') && _fields.country && !_.isEmpty(dealersettings.states)) {
                dealersettings.states.filter(e => e.country_code === _fields.country).forEach((data) => {
                    states.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: data.name
                    });
                });
                setStates(states);
            }

            setGroups(_groups);
            setRegions(_regions);
            setSubRegions(_subregions);
            setDealers(_dealers);
            setClientUsers(_.uniqBy(_clientUsers, 'value'));
            setOtherUsers(_.uniqBy(_otherUsers, 'value'));
            setFinanceUsers(_.uniqBy(_financeUsers, 'value'));
            setAfterMarketUsers(_.uniqBy(_amUsers, 'value'))
            setInboundUsers(_.uniqBy(options, 'value'))
            setMakes(_makes);
            setLostReasons(_lostReasons);
            setInboundSources(_inboundSources);
            //setPipelines(_pipelines);
            setStages(_stages);
            setCountries(countries);
        }

    }, [props.dealersettings, props.clientUsers])

    //#region CHANGE EVENTS

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, filterFields);
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            setFilterFields(fields);
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str;
            setFilterFields(fields);
        }
    }

    const handleMultiSelectChange = (selectedOptions, data) => {
        let state = Object.assign({}, filterFields);
        let value = '';
        if (selectedOptions?.length > 0 && selectedOptions.some(item => item.value === 'all')) {
            if (data.name === 'clientID')
                value = loadDealers().filter(e => e.value !== 'all').map(rec => { return rec.value }).join(',')
            else if (data.name === 'group')
                value = loadGroups().filter(e => e.value !== 'all').map(rec => { return rec.value }).join(',')
            else if (data.name === 'subregion')
                value = loadSubRegions().filter(e => e.value !== 'all').map(rec => { return rec.value }).join(',')
            else if (data.name === 'region')
                value = loadRegions().filter(e => e.value !== 'all').map(rec => { return rec.value }).join(',')
        }
        else {
            !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                value = (value ? value + ',' : '') + (data.value)
            })
        }
        // if (!_.isEmpty(selectedOptions)) {
        //     selectedOptions.map((data) => {
        //         value = (value ? value + ',' : '') + (data.value)
        //     })
        // }

        if (data.name === 'region') {
            delete state['subregion']
            delete state['group']
            state['clientID'] = ''
        }
        if (data.name === 'subregion') {
            delete state['group']
            state['clientID'] = ''
        }
        if (data.name === 'group') {
            state['clientID'] = ''
        }

        if (data.name === 'pipeline') {
            const { dealersettings } = props;
            let _pipeline = value;
            const _stages = [];
            if (dealersettings && dealersettings.client && dealersettings.client.settings && _pipeline && !_pipeline.includes(',')) {
                const setttings = dealersettings.client.settings;
                let defaultstages = setttings.pipelines.filter(v => v.value === _pipeline)[0].stages;
                defaultstages.filter(item => item.value !== 'Converted').map((doc) => {
                    _stages.push({
                        value: doc.value,
                        label: doc.name
                    });
                });
            }
            setStages(_stages);
            state[data.name] = _pipeline;
            if (state.hasOwnProperty('stage'))
                state['stage'] = null;
        }
        else {
            state[data.name] = value;
        }



        setFilterFields(state);
    }

    const handleSelectChange = (e, data) => {
        let state = Object.assign({}, filterFields);

        if (data.name === 'make' && e) {
            const models = [];
            const types = [];
            const modelGroups = [];
            let _models = !_.isEmpty(makes.filter(m => m.value === e.value)[0]) ? makes.filter(m => m.value === e.value)[0].models : [];

            _models && _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true
                    });
                }

                if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                    modelGroups.push({
                        value: model.group,
                        active: _.isBoolean(model.active) ? model.active : true,
                        label: model.group
                    });
                }
            })

            _models && _models.forEach((data, index) => {
                models.push({
                    value: data.value,
                    active: _.isBoolean(data.active) ? data.active : true,
                    label: CommonHelper.displayModelName(data),
                });
            });

            setTypes(_.orderBy(types, ['label'], ['asc']));
            setModelGroups(_.orderBy(modelGroups, ['label'], ['asc']));
            setModels(_.orderBy(models, ['label'], ['asc']));

            state[data.name] = e.value;

            if (state.hasOwnProperty('model')) state['model'] = null;
            if (state.hasOwnProperty('vehicleType')) state['vehicleType'] = null;
            if (state.hasOwnProperty('vehicleGroup')) state['vehicleGroup'] = null;
            //if (state.hasOwnProperty('vehicleSearchOn')) state['vehicleSearchOn'] = null;

            setFilterFields(state)
        }
        else if (data.name === 'lostReason' && e) {
            const lostSubReasons = [];
            if (!_.isEmpty(lostReasons.filter(m => m.value === e.value)[0].subList)) {
                lostReasons.filter(m => m.value === e.value)[0].subList.forEach(doc => {
                    lostSubReasons.push({
                        value: doc.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
            }
            setLostSubReasons(lostSubReasons);
            state[data.name] = e.value;
            if (state.hasOwnProperty('lostSubReason'))
                state['lostSubReason'] = null;
            setFilterFields(state)
        }
        else if (data.name === 'inboundSource' && e) {
            const _inboundSubTypes = [];
            if (!_.isEmpty(inboundSources.filter(m => m.value === e.value)[0].subList)) {
                inboundSources.filter(m => m.value === e.value)[0].subList.forEach(doc => {
                    _inboundSubTypes.push({
                        value: doc.Name,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.Name
                    });
                });
            }
            setInboundSubTypes(_inboundSubTypes);
            state[data.name] = e.value;
            if (state.hasOwnProperty('inboundSubType'))
                state['inboundSubType'] = null;
            setFilterFields(state)
        }
        else if (data.name === 'pipeline' && e) {
            const { dealersettings } = props;
            let _pipeline = e.value;
            if (dealersettings && dealersettings.client && dealersettings.client.settings) {
                const setttings = dealersettings.client.settings;
                let defaultstages = setttings.pipelines.filter(v => v.value === _pipeline)[0].stages;
                const _stages = [];
                defaultstages.forEach((doc) => {
                    _stages.push({
                        value: doc.value,
                        label: doc.name
                    });
                });

                state[data.name] = _pipeline;
                state['stage'] = null;
                setFilterFields(state)
                setStages(_stages);
            }
        }
        else {
            if (data.name === 'country') {
                if (e)
                    handleCountryChange(e.value);
                else
                    handleCountryChange('');

                if (state.hasOwnProperty('state'))
                    state['state'] = '';
            }

            if (data.name === 'yearFrom' && !state['yearTo'] && e?.value)
                state['yearTo'] = e.value;
            if (data.name === 'yearTo' && !state['yearFrom'] && e?.value)
                state['yearFrom'] = e.value;

            if (e)
                state[data.name] = e.value;
            else
                state[data.name] = null;
            setFilterFields(state)
        }



    }

    const handleOnChangeMultipleText = (val, name) => {
        setFilterFields({
            ...filterFields,
            [name]: !_.isEmpty(val) ? val.join(',') : ''
        })
    }

    const removeMultipleText = (index, name) => {
        let arr = filterFields[name] ? filterFields[name].split(',') : []
        arr.splice(index, 1)
        setFilterFields({
            ...filterFields,
            [name]: arr.join(',')
        })
    }

    const handleCountryChange = cCode => {
        const { dealersettings } = props;
        const states = [];
        if (cCode && !_.isEmpty(dealersettings)) {
            dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === cCode).forEach((doc) => {
                    states.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
        }
        setStates(states)
    }

    const handleGroupChange = (e, data) => {

        let state = Object.assign({}, filterFields);
        const models = [];
        const types = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.group === e.value && (state.vehicleType ? m.type === state.vehicleType : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.vehicleType ? m.type === state.vehicleType : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }

            _models && _models.filter(m => state.vehicleGroup ? m.group === state.vehicleGroup : true).forEach((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.filter(m => state.vehicleGroup ? m.group === state.vehicleGroup : true).findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })

        }

        if (state.hasOwnProperty('model')) state['model'] = null;
        setModels(_.orderBy(models, ['label'], ['asc']));
        setTypes(_.orderBy(types, ['label'], ['asc']));
        setFilterFields(state)

    }

    const handleTypeChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.type === e.value && (state.vehicleGroup ? m.group === state.vehicleGroup : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.vehicleGroup ? m.group === state.vehicleGroup : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
        }

        if (state.hasOwnProperty('model')) state['model'] = null;
        setModels(_.orderBy(models, ['label'], ['asc']));
        setFilterFields(state)
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let state = Object.assign({}, filterFields);
        if (checked) {

            if (boolTypes.includes(name)) {
                state[name] = false;
                //state['model'] = null;
            }
            else if (name === 'lostReason') {
                state[name] = null;
                //state['lostSubReason'] = null;
            }
            else if (name === 'owner' || name === 'addedBy' || name === 'ownerInbound') {
                let userID = props.dealersettings ? props.dealersettings.id : null;
                state[name] = userID;
            }
            else if (name === 'pipeline') {
                state[name] = null;
                state['stage'] = null;
                setStages([]);
            }
            else
                state[name] = null;

        }
        else {
            delete state[name];

            if (name === 'make') {
                delete state['model'];
                delete state['vehicleGroup'];
                delete state['vehicleType'];
                delete state['vehicleSearchOn'];
                setTypes([]);
                setModelGroups([]);
                setModels([]);
            }

            if (name === 'lostReason') {
                delete state['lostSubReason'];
            }

            if (name === 'inboundSource') {
                delete state['inboundSubType'];
            }

            if (name === 'clientID') {
                delete state['region'];
                delete state['subregion'];
                delete state['group'];
            }

            if (name === 'pipeline')
                delete state['stage'];
        }

        setFilterFields(state);

    }


    const handlePIDateSave = (val, name) => {
        let state = Object.assign({}, filterFields);
        if (val) val = moment(val).format('MMM YYYY');
        state[name] = val;
        setFilterFields(state);
    };

    const handleSwitchChange = (e, name) => {
        const { checked } = e.target;
        let state = Object.assign({}, filterFields);
        state[name] = checked;
        setFilterFields(state)
    }

    const onNumberChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  
        let state = Object.assign({}, filterFields);
        state[name] = e.value;
        setFilterFields(state)
    }

    //#endregion

    //#region EVENTS

    const handleClear = () => {
        if (!_.isEmpty(props.pipelineFilter)) {
            props.handleClearFilter();
        }
        else {
            setFilterFields({});
        }
    }

    const handleApply = () => {
        Object.keys(filterFields).forEach(key => (filterFields[key] === undefined || (_.isObject(filterFields[key]) && _.isEmpty(filterFields[key]))) ? delete filterFields[key] : {});
        if (!_.isEmpty(filterFields)) {
            if (!_.isEmpty(props.pipelineFilter) && !_.isEmpty(props.pipelineFilter.value) && JSON.stringify(props.pipelineFilter.value) === JSON.stringify(filterFields)) {
                props.handleApplyFilter(props.pipelineFilter);
            }
            else {
                let _filter = Object.assign({}, { name: 'Adv Filter', value: filterFields, type: 'filter' })
                props.handleApplyFilter(_filter);
            }
            props.handleFilterClose();
        }
        else {
            props.handleClearFilter();
            props.handleFilterClose();
        }
    }

    const handleSave = () => {
        Object.keys(filterFields).forEach(key => (filterFields[key] === undefined || (_.isObject(filterFields[key]) && _.isEmpty(filterFields[key]))) ? delete filterFields[key] : {});
        if (!_.isEmpty(filterFields))
            setShowSaveFilter(true);
    }

    const handleClose = (applyFilter) => {
        if (!_.isEmpty(applyFilter)) {
            props.handleApplyFilter(applyFilter);
            props.handleFilterClose();
        }
        setShowSaveFilter(false);

    }
    //#endregion

    //#region DATE RANGE FILTERS
    const handleDateTypeChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.type = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.type = e.value;
            }
            if (e.value !== 'custom')
                objData.range = null;

            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateNameChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.name = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.name = e.value;
            }
            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateRangeChange = (val, name) => {
        let state = Object.assign({}, filterFields);
        state[name].range = val;
        setFilterFields(state);

    };

    //#endregion

    //#region DYNAMIC CHANGE EVENTS
    const handleDynamicCheckChange = (e, _type) => {
        const { name, checked } = e.target;
        let dynamicFields = Object.assign([], filterFields?.dynamicFields);
        if (checked) {
            dynamicFields.push({
                'name': name, 'type': _type
            })
        }
        else {
            dynamicFields = dynamicFields.filter(e => e.name !== name)
        }
        setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });
    }

    const handleDynamicOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;

        let dynamicFields = Object.assign([], filterFields?.dynamicFields);
        dynamicFields.filter(function (obj) {
            if (obj.name === name) {
                obj.value = value ? value.trim() : null;
            }
        });
        setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });
    }

    const onDynamicCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}
        let dynamicFields = Object.assign([], filterFields?.dynamicFields);
        dynamicFields.filter(function (obj) {
            if (obj.name === name) {
                obj.value = e ? e.floatValue : null;
            }
        });

        setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });
    }

    const handleDynamicMultiSelectChange = (selectedOptions, data) => {
        let value = '';
        if (!_.isEmpty(selectedOptions)) {
            selectedOptions.map((data) => {
                value = (value ? value + ',' : '') + (data.value)
            })
        }

        let dynamicFields = Object.assign([], filterFields?.dynamicFields);
        dynamicFields.filter(function (obj) {
            if (obj.name === data.name) {
                obj.value = value;
            }
        });
        setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });
    }

    const handleDynamicSwitchOption = (e, data) => {

        let dynamicFields = Object.assign([], filterFields?.dynamicFields);
        dynamicFields.filter(function (obj) {
            if (obj.name === data.name) {
                obj.value = e ? (e.value === 'Yes' ? true : false) : null;
            }
        });
        setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });
    }

    const handleDynamicDateTypeChange = (e, data) => {

        let dynamicFields = Object.assign([], filterFields?.dynamicFields);
        if (e) {
            dynamicFields.filter(function (obj) {
                if (obj.name === data.name) {
                    obj.dateType = e.value;

                    if (e.value === 'custom') {
                        obj.range = null;
                        obj.startDate = null;
                        obj.endDate = null;
                    }
                    else {
                        let objData = CommonHelper.handleDynamicDateRangeFilter(obj, props.dealersettings)
                        for (let [key, value] of Object.entries(objData)) {
                            obj[key] = value;
                        }
                    }

                }
            });
            setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });
        }
    }


    const handleDynamicDateRangeChange = (val, name) => {
        let dynamicFields = Object.assign([], filterFields?.dynamicFields);

        dynamicFields.filter(function (obj) {
            if (obj.name === name) {

                if (obj.dateType === 'custom' && val) {
                    obj.range = val;
                    let objData = CommonHelper.handleDynamicDateRangeFilter(obj, props.dealersettings)
                    for (let [key, value] of Object.entries(objData)) {
                        obj[key] = value;
                    }
                }
                else {
                    obj.range = null;
                    obj.startDate = null;
                    obj.endDate = null;
                }
            }
        });
        setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });


    };
    //#endregion

    //#region groups
    const loadDealers = () => {
        let _dealers = [];
        if (!_.isEmpty(filterFields?.group)) {
            let _sub = Object.assign([], filterFields.group.split(','))
            _dealers = dealers.filter(e => _sub.includes(e.group))
        }
        else if (!_.isEmpty(filterFields?.subregion)) {
            let _sub = Object.assign([], filterFields.subregion.split(','))
            _dealers = dealers.filter(e => _sub.includes(e.subregion))
        }
        else if (!_.isEmpty(filterFields?.region)) {
            let _regs = Object.assign([], filterFields.region.split(','))
            _dealers = dealers.filter(e => _regs.includes(e.region))
        }
        else
            _dealers = dealers

        //console.log('loadDealers', _dealers, dealers, filterFields)
        if (_dealers.length > 1) {
            _dealers = [{ label: 'All', value: 'all' }, ..._dealers];
        }
        return _dealers;
    }

    const loadGroups = () => {
        let _groups = [];
        if (!_.isEmpty(filterFields?.subregion)) {
            let _regs = Object.assign([], filterFields.subregion.split(','))
            _groups = groups.filter(doc => dealers.some(client => client.group === doc.value && _regs.includes(client.subregion)))
        }
        else if (!_.isEmpty(filterFields?.region)) {
            let _regs = Object.assign([], filterFields.region.split(','))
            _groups = groups.filter(doc => dealers.some(client => client.group === doc.value && _regs.includes(client.region)))
        }
        else
            _groups = groups
        //console.log('loadGroups', _groups, groups, filterFields)
        if (_groups.length > 1) {
            _groups = [{ label: 'All', value: 'all' }, ..._groups];
        }
        return _groups;
    }

    const loadSubRegions = () => {
        let _subregions = [];
        if (!_.isEmpty(filterFields?.region)) {
            let _regs = Object.assign([], filterFields.region.split(','))
            _subregions = subregions.filter(doc => dealers.some(client => client.subregion === doc.value && _regs.includes(client.region)))
        }
        else _subregions = subregions
        //console.log('loadSubRegions', _subregions, subregions, filterFields)
        if (_subregions.length > 1) {
            _subregions = [{ label: 'All', value: 'all' }, ..._subregions];
        }
        return _subregions;
    }

    const loadRegions = () => {
        let _regions = regions;

        if (_regions.length > 1) {
            _regions = [{ label: 'All', value: 'all' }, ..._regions];
        }
        return _regions;
    }
    //#endregion

    return (
        <>

            <div className="quick-filter-body">
                <div className="advanced-filter-subheader">   <Translate text={'enquiries'} /> </div>

                <div className="advanced-filter-list">
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('keywords') >= 0) && (<>
                            {/* KEYWORDS */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'keywords'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'keywords'}><Translate text={'keywords'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder={'search..'}
                                                    className={`form-control`}
                                                    name="keywords"
                                                    onChange={handleOnChange}
                                                    value={filterFields.hasOwnProperty('keywords') ? filterFields.keywords : ''}
                                                />
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('date') >= 0) && (<>
                            {/* DATE RANGE */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'date'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('date')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'date'}><Translate text={'date'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('date') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={dateTypes}
                                                    id={"date-name"}
                                                    name={"date"}
                                                    placeholder={'select date type'}
                                                    onChange={handleDateNameChange}
                                                    value={
                                                        (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.name))
                                                            ? filterFields.date.name :
                                                            null
                                                    }
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={dateOptions}
                                                    id={"date-type"}
                                                    name={"date"}
                                                    placeholder={'select date option'}
                                                    onChange={handleDateTypeChange}
                                                    value={
                                                        (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.type))
                                                            ? filterFields.date.type :
                                                            null
                                                    }
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                        {
                                            (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.type) && filterFields.date.type === 'custom') && (
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <AntDateRangePicker
                                                            value={
                                                                (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.range))
                                                                    ? filterFields.date.range :
                                                                    null
                                                            }
                                                            id={"date-range"}
                                                            name={'date'}
                                                            format='DD/MM/YYYY'
                                                            onChange={(e) => { handleDateRangeChange(e, 'date') }}
                                                            placeholder='DD/MM/YYYY'
                                                        />
                                                    </div>
                                                </div>)
                                        }
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('clientID') >= 0) && (<>
                            {/* clientID */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'clientID'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('clientID')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'clientID'}> {_userLevelName}
                                        {/* <Translate text={'Dealers'} /> */}
                                    </label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('clientID')
                                    ?
                                    <div className="advanced-filter-box form-style">
                                        {
                                            _userLevel.includes('region') && (
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <ReactMultiSelect
                                                            options={loadRegions()}
                                                            name={"region"}
                                                            placeholder={'select regions'}
                                                            onChange={handleMultiSelectChange}
                                                            value={filterFields.hasOwnProperty('region') && filterFields.region ? filterFields.region.split(',') : []}
                                                            classNamePrefix={`basic-select`}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                        >
                                                        </ReactMultiSelect>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            _userLevel.includes('subregion') && (
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <ReactMultiSelect
                                                            options={loadSubRegions()}
                                                            name={"subregion"}
                                                            placeholder={'select sub regions'}
                                                            onChange={handleMultiSelectChange}
                                                            value={filterFields.hasOwnProperty('subregion') && filterFields.subregion ? filterFields.subregion.split(',') : []}
                                                            classNamePrefix={`basic-select`}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                        >
                                                        </ReactMultiSelect>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            _userLevel.includes('group') && (
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <ReactMultiSelect
                                                            options={loadGroups()}
                                                            name={"group"}
                                                            placeholder={'select groups'}
                                                            onChange={handleMultiSelectChange}
                                                            value={filterFields.hasOwnProperty('group') && filterFields.group ? filterFields.group.split(',') : []}
                                                            classNamePrefix={`basic-select`}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                        >
                                                        </ReactMultiSelect>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            _userLevel.includes('individual') && (
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <ReactMultiSelect
                                                            options={loadDealers()}
                                                            name={"clientID"}
                                                            placeholder={'select client'}
                                                            onChange={handleMultiSelectChange}
                                                            value={filterFields.hasOwnProperty('clientID') && filterFields.clientID ? filterFields.clientID.split(',') : []}
                                                            classNamePrefix={`basic-select`}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                        //menuPlacement={'top'}
                                                        >
                                                        </ReactMultiSelect>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    :
                                    <></>
                            }
                            {/* {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('clientID') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactMultiSelect
                                                    options={dealers}
                                                    name={"clientID"}
                                                    placeholder={'select dealer'}
                                                    onChange={handleMultiSelectChange}
                                                    value={filterFields.hasOwnProperty('clientID') && !_.isEmpty(filterFields.clientID) ? filterFields.clientID.split(',') : []}
                                                    classNamePrefix={`basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                        </div>
                                    </div>)
                            } */}
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('owner') >= 0) && (<>
                            {/* OWNER */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'owner'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('owner')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'owner'}><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'owner')} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('owner') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactMultiSelect
                                                    options={clientUsers}
                                                    name={"owner"}
                                                    placeholder={'select ownership'}
                                                    onChange={handleMultiSelectChange}
                                                    value={filterFields.hasOwnProperty('owner') ? filterFields.owner.split(',') : []}
                                                    classNamePrefix={`basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('ownerInbound') >= 0) && (<>
                            {/* owner Inbound */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'ownerInbound'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('ownerInbound')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'ownerInbound'}><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'ownerInbound')} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('ownerInbound') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactMultiSelect
                                                    options={inboundUsers}
                                                    name={"ownerInbound"}
                                                    placeholder={'select inbound ownership'}
                                                    onChange={handleMultiSelectChange}
                                                    value={filterFields.hasOwnProperty('ownerInbound') ? filterFields.ownerInbound.split(',') : []}
                                                    classNamePrefix={`basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(otherUsers) && !_.isEmpty(columnFields) && columnFields.indexOf('ownerOtherDealer') >= 0) && (<>
                            {/* owner Inbound */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'ownerOtherDealer'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('ownerOtherDealer')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'ownerOtherDealer'}><Translate text={'ownerOtherDealer'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('ownerOtherDealer') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactMultiSelect
                                                    options={otherUsers}
                                                    name={"ownerOtherDealer"}
                                                    placeholder={'select ownership'}
                                                    onChange={handleMultiSelectChange}
                                                    value={filterFields.hasOwnProperty('ownerOtherDealer') && !_.isEmpty(filterFields.ownerOtherDealer) ? filterFields.ownerOtherDealer.split(',') : []}
                                                    classNamePrefix={`basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('addedBy') >= 0) && (<>
                            {/* addedBy */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'addedBy'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('addedBy')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'addedBy'}><Translate text={'leadCreatedBy'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('addedBy') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactMultiSelect
                                                    options={clientUsers}
                                                    name={"addedBy"}
                                                    placeholder={'select lead created by'}
                                                    onChange={handleMultiSelectChange}
                                                    value={filterFields.hasOwnProperty('addedBy') ? filterFields.addedBy.split(',') : []}
                                                    classNamePrefix={`basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }


                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('stage') >= 0) && (<>
                            {/* stage */}
                            <div className="check-list">
                                <div className="checkbox icheck-success">
                                    <InputCheckBox
                                        className="switch"
                                        name={'stage'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('stage')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'stage'}>{'Stages'}</label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('stage') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={stages}
                                                    name={"stage"}
                                                    placeholder={'select astage'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('stage') ? filterFields.stage : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('status') >= 0) && (<>
                            {/* STATUS */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'status'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('status')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'status'}><Translate text={'status'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('status') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={_enquiryStatus}
                                                    name={"status"}
                                                    placeholder={'select status'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('status') ? filterFields.status : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isFav') >= 0) && (<>
                            {/* isFav */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'isFav'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isFav')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'isFav'}><Translate text={'Favourite Enquiries'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isFav') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <div className="text-left" >
                                                    <Translate text={'Favourite Enquiries'} />
                                                    <span className="switch switch-sm float-right">
                                                        <InputCheckBox
                                                            className={`switch`}
                                                            name="_isFav"
                                                            checked={filterFields.hasOwnProperty('isFav') ? filterFields.isFav : false}
                                                            onChange={(e) => { handleSwitchChange(e, 'isFav') }} />
                                                        <label htmlFor="_isFav"></label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('rottenType') >= 0) && (<>
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'rottenType'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('rottenType')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'rottenType'}><Translate text={'Unattended Leads'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('rottenType')
                                    ?
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={rottenOptions}
                                                    name={"rottenType"}
                                                    placeholder={'select options'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('rottenType') ? filterFields.rottenType : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                        {
                                            (filterFields.hasOwnProperty('rottenType') && filterFields.rottenType) && (
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <NumberFormat
                                                            thousandSeparator={false}
                                                            placeholder={'enter unattended days'}
                                                            className={`form-control`}
                                                            onValueChange={onNumberChange.bind(this, 'rottenDays')}
                                                            value={filterFields.hasOwnProperty('rottenDays') ? filterFields.rottenDays : null}
                                                        />
                                                    </div>
                                                </div>)
                                        }
                                    </div>
                                    :
                                    <></>
                            }
                        </>)
                    }
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('inboundSource') >= 0) && (<>
                            {/* ENQQUIRY TYPE */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'inboundSource'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('inboundSource')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'inboundSource'}><Translate text={'Inbound Source'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('inboundSource') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={inboundSources}
                                                    name={"inboundSource"}
                                                    placeholder={'select inbound source'}
                                                    // onChange={handleMultiSelectChange}
                                                    // value={filterFields.hasOwnProperty('inboundSource') && !_.isEmpty(filterFields.inboundSource) ? filterFields.inboundSource.split(',') : []}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('inboundSource') ? filterFields.inboundSource : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                // isMulti={true}
                                                // isSearchable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                        {
                                            !_.isEmpty(inboundSubTypes) && (
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <ReactSelect
                                                            options={inboundSubTypes}
                                                            name={"inboundSubType"}
                                                            placeholder={'select inbound Sub Type'}
                                                            onChange={handleSelectChange}
                                                            value={filterFields.hasOwnProperty('inboundSubType') ? filterFields.inboundSubType : null}
                                                            classNamePrefix={`cursor-pointer basic-select`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </div>)
                                        }

                                    </div>)
                            }
                        </>)
                    }
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('enquiryType') >= 0) && (<>
                            {/* ENQQUIRY TYPE */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'enquiryType'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('enquiryType')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'enquiryType'}><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'enquiryType')} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('enquiryType') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={enquiryTypes}
                                                    name={"enquiryType"}
                                                    placeholder={'select enquiry type'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('enquiryType') ? filterFields.enquiryType : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('serviceType') >= 0) && (<>
                            {/* ENQQUIRY TYPE */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'serviceType'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('serviceType')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'serviceType'}><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'serviceType')} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('serviceType') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={serviceTypes}
                                                    name={"serviceType"}
                                                    placeholder={'select enquiry type'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('serviceType') ? filterFields.serviceType : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('leadSource') >= 0) && (<>
                            {/* ENQQUIRY TYPE */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'leadSource'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('leadSource')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'leadSource'}><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'leadSource')} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('leadSource') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={leadSources}
                                                    name={"leadSource"}
                                                    placeholder={'select lead source'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('leadSource') ? filterFields.leadSource : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('origin') >= 0) && (<>
                            {/* ORIGIN */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'origin'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('origin')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'origin'}><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'origin')} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('origin') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={origins}
                                                    name={"origin"}
                                                    placeholder={'select origin'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('origin') ? filterFields.origin : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('campaign') >= 0) && (<>
                            {/* CAMPAIGN */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'campaign'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('campaign')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'campaign'}><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'campaign')} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('campaign') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={campaigns}
                                                    name={"campaign"}
                                                    placeholder={'select campaign'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('campaign') ? filterFields.campaign : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('label') >= 0) && (<>
                            {/* LABEL */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'label'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('label')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'label'}><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'label')} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('label') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={labels}
                                                    name={"label"}
                                                    placeholder={'select label'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('label') ? filterFields.label : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('purchaseIntention') >= 0) && (<>
                            {/* purchase Intention */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'purchaseIntention'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('purchaseIntention')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'purchaseIntention'}><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'purchaseIntention')} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('purchaseIntention') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <AntMonthPicker
                                                    value={
                                                        (filterFields.hasOwnProperty('purchaseIntention') && filterFields.purchaseIntention)
                                                            ? moment('01 ' + filterFields.purchaseIntention)._d
                                                            : null
                                                    }
                                                    name={'purchaseIntention'}
                                                    onChange={(e) => { handlePIDateSave(e, 'purchaseIntention') }}
                                                    placeholder='select purchase intention'
                                                    className={`form-control cursor-pointer`}
                                                    picker={'month'}
                                                    format={'MMM YYYY'}
                                                />
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }



                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('lostReason') >= 0) && (<>
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'lostReason'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('lostReason')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'lostReason'}><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'lostReason')} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('lostReason') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={lostReasons}
                                                    name={"lostReason"}
                                                    placeholder={'select lost reason'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('lostReason') ? filterFields.lostReason : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                        {
                                            !_.isEmpty(lostSubReasons) && (
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <ReactSelect
                                                            options={lostSubReasons}
                                                            name={"lostSubReason"}
                                                            placeholder={'select lost sub reason'}
                                                            onChange={handleSelectChange}
                                                            value={filterFields.hasOwnProperty('lostSubReason') ? filterFields.lostSubReason : null}
                                                            classNamePrefix={`cursor-pointer basic-select`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </div>)
                                        }
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isPendingLostApproval') >= 0) && (<>
                            {/* isPendingLostApproval */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'isPendingLostApproval'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isPendingLostApproval')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'isPendingLostApproval'}><Translate text={'Pending Approval'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isPendingLostApproval') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <div className="text-left" >
                                                    <Translate text={'Lost Pending Approval'} />
                                                    <span className="switch switch-sm float-right">
                                                        <InputCheckBox
                                                            className={`switch`}
                                                            name="_isPendingLostApproval"
                                                            checked={filterFields.hasOwnProperty('isPendingLostApproval') ? filterFields.isPendingLostApproval : false}
                                                            onChange={(e) => { handleSwitchChange(e, 'isPendingLostApproval') }} />
                                                        <label htmlFor="_isPendingLostApproval"></label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('leadswithoutContact') >= 0) && (<>
                            {/* leadswithoutContact */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'leadswithoutContact'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('leadswithoutContact')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'leadswithoutContact'}><Translate text={'leadswithoutContact'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('leadswithoutContact') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <div className="text-left" >
                                                    <Translate text={'leadswithoutContact'} />
                                                    <span className="switch switch-sm float-right">
                                                        <InputCheckBox
                                                            className={`switch`}
                                                            name="_leadswithoutContact"
                                                            checked={filterFields.hasOwnProperty('leadswithoutContact') ? filterFields.leadswithoutContact : false}
                                                            onChange={(e) => { handleSwitchChange(e, 'leadswithoutContact') }} />
                                                        <label htmlFor="_leadswithoutContact"></label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('leadswithoutReq') >= 0) && (<>
                            {/* leadswithoutReq */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'leadswithoutReq'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('leadswithoutReq')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'leadswithoutReq'}><Translate text={'leadswithoutReq'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('leadswithoutReq') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <div className="text-left" >
                                                    <Translate text={'leadswithoutReq'} />
                                                    <span className="switch switch-sm float-right">
                                                        <InputCheckBox
                                                            className={`switch`}
                                                            name="_leadswithoutReq"
                                                            checked={filterFields.hasOwnProperty('leadswithoutReq') ? filterFields.leadswithoutReq : false}
                                                            onChange={(e) => { handleSwitchChange(e, 'leadswithoutReq') }} />
                                                        <label htmlFor="_leadswithoutReq"></label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isContact') >= 0) && (<>
                            {/* isContact */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'isContact'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isContact')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'isContact'}><Translate text={'Contact Done'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isContact') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <div className="text-left" >
                                                    <Translate text={'Contact Done'} />
                                                    <span className="switch switch-sm float-right">
                                                        <InputCheckBox
                                                            className={`switch`}
                                                            name="_isContact"
                                                            checked={filterFields.hasOwnProperty('isContact') ? filterFields.isContact : false}
                                                            onChange={(e) => { handleSwitchChange(e, 'isContact') }} />
                                                        <label htmlFor="_isContact"></label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isVehicle') >= 0 && !_.isEmpty(modules) && modules.requirement) && (<>
                            {/* isVehicle */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'isVehicle'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isVehicle')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'isVehicle'}><Translate text={'Requirement Done'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isVehicle') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <div className="text-left" >
                                                    <Translate text={'Requirement Done'} />
                                                    <span className="switch switch-sm float-right">
                                                        <InputCheckBox
                                                            className={`switch`}
                                                            name="_isVehicle"
                                                            checked={filterFields.hasOwnProperty('isVehicle') ? filterFields.isVehicle : false}
                                                            onChange={(e) => { handleSwitchChange(e, 'isVehicle') }} />
                                                        <label htmlFor="_isVehicle"></label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isTestDrive') >= 0 && !_.isEmpty(modules) && modules.testDrive) && (<>
                            {/* isTestDrive */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'isTestDrive'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isTestDrive')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'isTestDrive'}><Translate text={'TestDrive Done'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isTestDrive') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <div className="text-left" >
                                                    <Translate text={'TestDrive Done'} />
                                                    <span className="switch switch-sm float-right">
                                                        <InputCheckBox
                                                            className={`switch`}
                                                            name="_isTestDrive"
                                                            checked={filterFields.hasOwnProperty('isTestDrive') ? filterFields.isTestDrive : false}
                                                            onChange={(e) => { handleSwitchChange(e, 'isTestDrive') }} />
                                                        <label htmlFor="_isTestDrive"></label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isTradeIn') >= 0 && !_.isEmpty(modules) && modules.tradeIn) && (<>
                            {/* isTradeIn */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'isTradeIn'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isTradeIn')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'isTradeIn'}><Translate text={'Trade-In Done'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isTradeIn') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <div className="text-left" >
                                                    <Translate text={'Trade-In Done'} />
                                                    <span className="switch switch-sm float-right">
                                                        <InputCheckBox
                                                            className={`switch`}
                                                            name="_isTradeIn"
                                                            checked={filterFields.hasOwnProperty('isTradeIn') ? filterFields.isTradeIn : false}
                                                            onChange={(e) => { handleSwitchChange(e, 'isTradeIn') }} />
                                                        <label htmlFor="_isTradeIn"></label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isFinance') >= 0 && !_.isEmpty(modules) && modules.finance) && (<>
                            {/* isFinance */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'isFinance'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isFinance')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'isFinance'}><Translate text={'Finance Done'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isFinance') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <div className="text-left" >
                                                    <Translate text={'Finance Done'} />
                                                    <span className="switch switch-sm float-right">
                                                        <InputCheckBox
                                                            className={`switch`}
                                                            name="_isFinance"
                                                            checked={filterFields.hasOwnProperty('isFinance') ? filterFields.isFinance : false}
                                                            onChange={(e) => { handleSwitchChange(e, 'isFinance') }} />
                                                        <label htmlFor="_isFinance"></label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isVideo') >= 0 && !_.isEmpty(modules) && modules.amsVideo) && (<>
                            {/* isVideo */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'isVideo'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isVideo')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'isVideo'}><Translate text={'AMS Video Done'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isVideo') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <div className="text-left" >
                                                    <Translate text={'AMS Video Done'} />
                                                    <span className="switch switch-sm float-right">
                                                        <InputCheckBox
                                                            className={`switch`}
                                                            name="_isVideo"
                                                            checked={filterFields.hasOwnProperty('isVideo') ? filterFields.isVideo : false}
                                                            onChange={(e) => { handleSwitchChange(e, 'isVideo') }} />
                                                        <label htmlFor="_isVideo"></label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('fusionevents') >= 0) && (<>

                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'fusionevents'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('fusionevents')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'fusionevents'}><Translate text={'fusionevents'} /></label>
                                </div>
                            </div>

                            {(() => {
                                return (
                                    <>
                                        {
                                            !_.isEmpty(filterFields) && filterFields.hasOwnProperty('fusionevents') && (
                                                <div className="advanced-filter-box form-style">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <ReactMultiSelect
                                                                options={fusionEvents}
                                                                name={"fusionevents"}
                                                                placeholder={'select fusion events'}
                                                                onChange={handleMultiSelectChange}
                                                                value={filterFields.hasOwnProperty('fusionevents') && !_.isEmpty(filterFields.fusionevents) ? filterFields.fusionevents.split(',') : []}
                                                                classNamePrefix={`cursor-pointer basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div>
                                                    </div>
                                                </div>)
                                        }
                                    </>
                                )

                            })()}

                        </>)
                    }

                    {
                        enquiryOptionsDF && enquiryOptionsDF.filter(m => m.active === true).map((info, index) => {
                            return <Fragment key={index}>

                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={info.value}
                                            checked={(!_.isEmpty(filterFields) && filterFields.dynamicFields && _.find(filterFields.dynamicFields, { name: info.value })) ? true : false}
                                            onChange={(e) => { handleDynamicCheckChange(e, info.type) }} />
                                        <label htmlFor={info.value}><Translate text={info.name} /></label>
                                    </div>
                                </div>
                                {
                                    (!_.isEmpty(filterFields) && filterFields.dynamicFields && _.find(filterFields.dynamicFields, { name: info.value })) && (
                                        <>
                                            <div className="advanced-filter-box form-style">


                                                {(() => {
                                                    let dynamicfilterFields = _.find(filterFields.dynamicFields, { name: info.value })

                                                    if (info.type === 'toggle' || info.type === 'switch') {
                                                        return (
                                                            <>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <ReactSelect
                                                                            options={yesNoOptions}
                                                                            id={index + '_' + info.value}
                                                                            name={info.value}
                                                                            placeholder={info.name}
                                                                            onChange={handleDynamicSwitchOption}
                                                                            value={_.isBoolean(dynamicfilterFields?.value) ? (dynamicfilterFields.value ? 'Yes' : 'No') : null}
                                                                            classNamePrefix={`cursor-pointer basic-select`}
                                                                            removeClearable={true}
                                                                        >
                                                                        </ReactSelect>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    else if (info.type === 'text') {
                                                        return (
                                                            <>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <InputText
                                                                            name={info.value}
                                                                            onChange={handleDynamicOnChange}
                                                                            value={dynamicfilterFields?.value ? dynamicfilterFields.value : ''}
                                                                            autoComplete="off"
                                                                            placeholder={info.name}
                                                                            className={`form-control`}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    else if (info.type === 'number') {
                                                        return (
                                                            <>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <NumberFormat
                                                                            id={info.value}
                                                                            name={info.value}
                                                                            allowNegative={false}
                                                                            thousandSeparator={false}
                                                                            placeholder={info.name}
                                                                            className={`form-control `}
                                                                            onValueChange={onDynamicCurrencyChange.bind(this, info.value)}
                                                                            value={dynamicfilterFields?.value ? dynamicfilterFields?.value : ''}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    else if (info.type === 'price') {
                                                        return (
                                                            <>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <NumberFormat
                                                                            id={info.value}
                                                                            name={info.value}
                                                                            allowNegative={true}
                                                                            decimalScale={2}
                                                                            thousandSeparator={true}
                                                                            prefix={currencySymbol}
                                                                            placeholder={currencySymbol}
                                                                            className={`form-control `}
                                                                            onValueChange={onDynamicCurrencyChange.bind(this, info.value)}
                                                                            value={dynamicfilterFields?.value ? dynamicfilterFields?.value : null}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>

                                                        )
                                                    }
                                                    else if (info.type === 'date') {
                                                        return (
                                                            <>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <ReactSelect
                                                                            options={dateOptions}
                                                                            id={'_' + info.value}
                                                                            name={info.value}
                                                                            placeholder={'select date option'}
                                                                            onChange={handleDynamicDateTypeChange}
                                                                            value={
                                                                                (!_.isEmpty(dynamicfilterFields) && !_.isEmpty(dynamicfilterFields.dateType))
                                                                                    ? dynamicfilterFields.dateType :
                                                                                    null
                                                                            }
                                                                            classNamePrefix={`cursor-pointer basic-select`}
                                                                            removeClearable={true}
                                                                        >
                                                                        </ReactSelect>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    (!_.isEmpty(dynamicfilterFields) && !_.isEmpty(dynamicfilterFields.dateType) && dynamicfilterFields.dateType === 'custom') && (
                                                                        <div className="form-row">
                                                                            <div className="form-group col-md-12">
                                                                                <AntDateRangePicker
                                                                                    value={
                                                                                        !_.isEmpty(dynamicfilterFields?.range)
                                                                                            ? dynamicfilterFields?.range :
                                                                                            null
                                                                                    }
                                                                                    id={index + '_' + info.value}
                                                                                    name={index + '_' + info.value}
                                                                                    format='DD/MM/YYYY'
                                                                                    onChange={(e) => { handleDynamicDateRangeChange(e, info.value) }}
                                                                                    placeholder='DD/MM/YYYY'
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                    else if (info.type === 'multiselect' || info.type === 'select') {
                                                        return (
                                                            <>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <ReactMultiSelect
                                                                            options={
                                                                                !_.isEmpty(info.subList) ?
                                                                                    _.map(info.subList, function (e) { return { label: e, value: e } })
                                                                                    : []
                                                                            }
                                                                            name={info.value}
                                                                            placeholder={info.name}
                                                                            onChange={handleDynamicMultiSelectChange}
                                                                            value={dynamicfilterFields?.value ? dynamicfilterFields?.value.split(',') : []}
                                                                            classNamePrefix={`basic-select`}
                                                                            isMulti={true}
                                                                            isSearchable={true}
                                                                        >
                                                                        </ReactMultiSelect>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                })()}

                                            </div>
                                        </>)
                                }
                            </Fragment>
                        })
                    }
                    <div className="advanced-filter-subheader"><Translate text={'activities'} /></div>
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('activityType') >= 0) && (<>
                            {/* activityType */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'activityType'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('activityType')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'activityType'}><Translate text={'activityType'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('activityType') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactMultiSelect
                                                    options={activityTypes}
                                                    name={"activityType"}
                                                    placeholder={'select activity type'}
                                                    onChange={handleMultiSelectChange}
                                                    value={filterFields.hasOwnProperty('activityType') && !_.isEmpty(filterFields.activityType) ? filterFields.activityType.split(',') : []}
                                                    classNamePrefix={`basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('activityOption') >= 0) && (<>
                            {/* activityOption */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'activityOption'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('activityOption')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'activityOption'}><Translate text={'activityOption'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('activityOption') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={activityTaskOptions}
                                                    name={"activityOption"}
                                                    placeholder={'select activity Option'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('activityOption') ? filterFields.activityOption : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }


                    <div className="advanced-filter-subheader"><Translate text={'contact'} /></div>
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('contactType') >= 0) && (<>
                            {/* contactType */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'contactType'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactType')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'contactType'}><Translate text={'preferredContactType'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactType') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={contact_type}
                                                    name={"contactType"}
                                                    placeholder={'select contact Type'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('contactType') ? filterFields.contactType : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isVIP') >= 0) && (<>
                            {/* isVIP */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'isVIP'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isVIP')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'isVIP'}><Translate text={'vip'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isVIP') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <div className="text-left" >
                                                    <Translate text={'vip'} />
                                                    <span className="switch switch-sm float-right">
                                                        <InputCheckBox
                                                            className={`switch`}
                                                            name="_isVIP"
                                                            checked={filterFields.hasOwnProperty('isVIP') ? filterFields.isVIP : false}
                                                            onChange={(e) => { handleSwitchChange(e, 'isVIP') }} />
                                                        <label htmlFor="_isVIP"></label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('contactsPhone') >= 0) && (<>
                            {/* contactsPhone */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'contactsPhone'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactsPhone')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'contactsPhone'}><Translate text={'contactsPhone'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactsPhone') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={contact_phone}
                                                    name={"contactsPhone"}
                                                    placeholder={'select contact phone'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('contactsPhone') ? filterFields.contactsPhone : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('contactsEmail') >= 0) && (<>
                            {/* contactsEmail */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'contactsEmail'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactsEmail')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'contactsEmail'}><Translate text={'contactsEmail'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactsEmail') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={contact_email}
                                                    name={"contactsEmail"}
                                                    placeholder={'select contact email'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('contactsEmail') ? filterFields.contactsEmail : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('gender') >= 0) && (<>
                            {/* GENDER */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'gender'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('gender')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'gender'}><Translate text={'gender'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('gender') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={genders}
                                                    name={"gender"}
                                                    placeholder={'select gender'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('gender') ? filterFields.gender : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('contactcampaign') >= 0) && (<>
                            {/* contactcampaign */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'contactcampaign'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactcampaign')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'contactcampaign'}><Translate text={'campaign'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactcampaign') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={campaigns}
                                                    name={"contactcampaign"}
                                                    placeholder={'select campaign'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('contactcampaign') ? filterFields.contactcampaign : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('contactorigin') >= 0) && (<>
                            {/* contactorigin */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'contactorigin'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactorigin')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'contactorigin'}><Translate text={'origin'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactorigin') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={origins}
                                                    name={"contactorigin"}
                                                    placeholder={'select origin'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('contactorigin') ? filterFields.contactorigin : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('nationality') >= 0) && (<>
                            {/* nationality */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'nationality'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('nationality')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'nationality'}><Translate text={'nationality'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('nationality') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={nationalities}
                                                    name={"nationality"}
                                                    placeholder={'select nationality'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('nationality') ? filterFields.nationality : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('country') >= 0) && (<>
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'country'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('country')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'country'}><Translate text={'country&state'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('country') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={countries}
                                                    name={"country"}
                                                    placeholder={'select country'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('country') ? filterFields.country : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={states}
                                                    name={"state"}
                                                    placeholder={'select state'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('state') ? filterFields.state : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('areaCode') >= 0) && (<>
                            {/* areaCode */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'areaCode'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('areaCode')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'areaCode'}><Translate text={'areaCode'} /></label>
                                </div>
                            </div>
                            {(() => {
                                return (
                                    <>
                                        {
                                            !_.isEmpty(filterFields) && filterFields.hasOwnProperty('areaCode') && (
                                                <div className="advanced-filter-box form-style">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <InputMultipleText
                                                                placeholder={`area code`}
                                                                className={`form-control react-multi-tag`}
                                                                name="areaCode"
                                                                onChange={(m) => { handleOnChangeMultipleText(m, 'areaCode') }}
                                                                value={filterFields.hasOwnProperty('areaCode') && filterFields.areaCode ? filterFields.areaCode.split(',') : []}
                                                                validateEmail={false}
                                                                getLabel={(_data, index) => {
                                                                    return <div data-tag key={index}>
                                                                        {_data}
                                                                        <span data-tag-handle onClick={() => removeMultipleText(index, 'areaCode')}>
                                                                            ×
                                                                        </span>
                                                                    </div>
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>)
                                        }
                                    </>
                                )

                            })()}

                        </>)
                    }

                    <div className="advanced-filter-subheader"><Translate text={'requirement'} /></div>

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('make') >= 0) && (<>
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'make'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('make')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'make'}><Translate text={'make&model'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('make') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={makes}
                                                    name={"make"}
                                                    placeholder={'select make'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('make') ? filterFields.make : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={modelGroups}
                                                    name={"vehicleGroup"}
                                                    placeholder={'select group'}
                                                    onChange={handleGroupChange}
                                                    value={filterFields.hasOwnProperty('vehicleGroup') ? filterFields.vehicleGroup : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                //removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={types}
                                                    name={"vehicleType"}
                                                    placeholder={'select type'}
                                                    onChange={handleTypeChange}
                                                    value={filterFields.hasOwnProperty('vehicleType') ? filterFields.vehicleType : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                //removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactMultiSelect
                                                    options={models}
                                                    name={"model"}
                                                    placeholder={'select model'}
                                                    onChange={handleMultiSelectChange}
                                                    value={filterFields.hasOwnProperty('model') && filterFields.model ? filterFields.model.split(',') : []}
                                                    classNamePrefix={`basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                    menuPlacement={'top'}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <ReactSelect
                                                    options={vehicleYears}
                                                    name={"yearFrom"}
                                                    placeholder={'select year from'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('yearFrom') ? filterFields.yearFrom : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                //removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <ReactSelect
                                                    options={vehicleYears}
                                                    name={"yearTo"}
                                                    placeholder={'select year to'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('yearTo') ? filterFields.yearTo : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                //removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={vehicle_search_type}
                                                    name={"vehicleSearchOn"}
                                                    placeholder={'search vehicle based on'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('vehicleSearchOn') ? filterFields.vehicleSearchOn : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                //removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>

                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('saleType') >= 0) && (<>
                            {/* saleType */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'saleType'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('saleType')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'saleType'}><Translate text={'saleType'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('saleType') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactMultiSelect
                                                    options={sale_type}
                                                    name={"saleType"}
                                                    placeholder={'select sale type'}
                                                    onChange={handleMultiSelectChange}
                                                    value={filterFields.hasOwnProperty('saleType') && filterFields.saleType ? filterFields.saleType.split(',') : []}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    <div className="advanced-filter-subheader"><Translate text={'finance'} /></div>
                    {
                        (accessToFinanceTab && !_.isEmpty(financeUsers) && !_.isEmpty(columnFields) && columnFields.indexOf('financeBM') >= 0) && (<>
                            {/* financeBM */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'financeBM'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('financeBM')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'financeBM'}><Translate text={'financeBM'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('financeBM') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactMultiSelect
                                                    options={financeUsers}
                                                    name={"financeBM"}
                                                    placeholder={'select business manager'}
                                                    onChange={handleMultiSelectChange}
                                                    value={filterFields.hasOwnProperty('financeBM') && !_.isEmpty(filterFields.financeBM) ? filterFields.financeBM.split(',') : []}
                                                    classNamePrefix={`basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isFinanceRequired') >= 0) && (<>
                            {/* isFinanceRequired */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'isFinanceRequired'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isFinanceRequired')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'isFinanceRequired'}><Translate text={'isFinanceRequired'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isFinanceRequired') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <div className="text-left" >
                                                    <Translate text={'isFinanceRequired'} />
                                                    <span className="switch switch-sm float-right">
                                                        <InputCheckBox
                                                            className={`switch`}
                                                            name="_isFinanceRequired"
                                                            checked={filterFields.hasOwnProperty('isFinanceRequired') ? filterFields.isFinanceRequired : false}
                                                            onChange={(e) => { handleSwitchChange(e, 'isFinanceRequired') }} />
                                                        <label htmlFor="_isFinanceRequired"></label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('financeStatus') >= 0) && (<>
                            {/* financeStatus */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'financeStatus'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('financeStatus')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'financeStatus'}><Translate text={'financeStatus'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('financeStatus') && (<>
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={(financeEnabled || drivaEnabled) ? __financestatus : financeStatuses}
                                                    name={"financeStatus"}
                                                    placeholder={'select status'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('financeStatus') ? filterFields.financeStatus : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                    menuPlacement={'top'}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div></>)
                            }
                        </>)
                    }

                    {accessToAfterMarketTab && (<div className="advanced-filter-subheader"><Translate text={'afterMarket'} /></div>)}
                    {
                        (accessToAfterMarketTab && !_.isEmpty(afterMarketUsers) && !_.isEmpty(columnFields) && columnFields.indexOf('afterMarketBM') >= 0) && (<>
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'afterMarketBM'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('afterMarketBM')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'afterMarketBM'}><Translate text={'afterMarketBM'} /></label>
                                </div>
                            </div>
                            {(() => {
                                return (
                                    <>
                                        {!_.isEmpty(filterFields) && filterFields.hasOwnProperty('afterMarketBM') && (<div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={afterMarketUsers}
                                                        name={"afterMarketBM"}
                                                        placeholder={'select aftermarket manager'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('afterMarketBM') && !_.isEmpty(filterFields.afterMarketBM) ? filterFields.afterMarketBM.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>)}
                                    </>
                                )
                            })()}

                        </>)
                    }
                    {
                        (accessToAfterMarketTab && !_.isEmpty(columnFields) && columnFields.indexOf('afterMarketStatus') >= 0) && (<>
                            {/* afterMarketStatus */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'afterMarketStatus'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('afterMarketStatus')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'afterMarketStatus'}><Translate text={'afterMarketStatus'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('afterMarketStatus') && (<>
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <ReactSelect
                                                    options={afterMarketStatuses}
                                                    name={"afterMarketStatus"}
                                                    placeholder={'select status'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('afterMarketStatus') ? filterFields.afterMarketStatus : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                    menuPlacement={'top'}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div></>)
                            }
                        </>)
                    }
                </div>
            </div>

            {
                !_.isEmpty(filterFields)
                    ?
                    <>
                        <div className="quick-filter-footer">
                            {props.noSaveAccess ? <></> : <><button type="button" className="btn btn-primary float-left" onClick={(e) => { e.preventDefault(); handleSave(); }}> <Translate text={'saveAsFilter'} /></button></>}
                            <button type="button" className={`btn btn-default float-left ${props.noSaveAccess ? '' : 'ml-2'}`} onClick={(e) => { e.preventDefault(); handleClear(); }}><Translate text={'clear'} /></button>
                            <button type="button" className="btn btn-primary float-right" onClick={(e) => { e.preventDefault(); handleApply(); }}><Translate text={'apply'} /></button>
                        </div>
                    </>
                    :
                    <>
                    </>
            }


            <PopUpModal show={showSaveFilter}>
                <SaveFilter
                    show={showSaveFilter}
                    handleClose={handleClose}
                    filterFields={filterFields}
                    filtersList={props.customFilters}
                    activeFilter={props.pipelineFilter}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    module={(props.pipelineView && props.pipelineView === 'INBOUND') ? 'inbound' : 'enquiry'}
                >
                </SaveFilter>
            </PopUpModal>



        </>
    )
}

export default PipelineAdvanceFilters;