import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import Swal from 'sweetalert2'
import Translate from '../constants/translate';
import toast from 'toasted-notes';
import { PDFDocument } from 'pdf-lib';
import { Tooltip } from 'antd';
import NumberFormat from 'react-number-format';
import { default as _images } from '../images';
import { defaultFinanceTerms, enquiryStatus, enqUnActionStatus, eventAllocationStatus, stockStatus, testDriveStatus, tipOfferStatus, tipStatus } from './enum'
import { objEnquiry, objNewSubEnquiryVM } from '../views/pipeline/viewModel'
import parser from "cron-parser";
import CommonHelper from './common';
import { firestoreDB, storageBucket } from './helper';


const __financestatus = [
	{ active: true, value: 'InProgress', label: <div className={`badge badge-pill badge-status-inprogress`}><Translate text={'InProgress'} upperCase={true} /></div> },
	{ active: true, value: 'Pending', label: <div className={`badge badge-pill badge-status-pending`}><Translate text={'Pending'} upperCase={true} /></div> },
	{ active: true, value: 'Won', label: <div className={`badge badge-pill badge-status-won`}><Translate text={'won'} upperCase={true} /></div> },
	{ active: true, value: 'Lost', label: <div className={`badge badge-pill badge-status-lost`}><Translate text={'lost'} upperCase={true} /></div> }
]

class EnquiryHelper {

	handleMoveStageForStatusChange = (_status, state, enquiry, statusSettings) => {
		const { stageHistory, statusHistory } = enquiry;

		let _make = state?.requirement?.stock?.make ? state?.requirement?.stock?.make : state?.requirement?.make;
		let _saleType = state?.requirement?.saleType;
		let _statusArray = !_.isEmpty(statusSettings) ? statusSettings.filter(e => e.status === _status) : []
		let data = null;

		if (!_.isEmpty(_statusArray)) {
			if (!_.isEmpty(_make) && !_.isEmpty(_saleType) && _statusArray.filter(e => !_.isEmpty(e.salesType) && !_.isEmpty(e.make) && e.salesType.includes(_saleType) && e.make.includes(_make))[0])
				data = _statusArray.filter(e => !_.isEmpty(e.salesType) && !_.isEmpty(e.make) && e.salesType.includes(_saleType) && e.make.includes(_make))[0]
			else if (!_.isEmpty(_make) && _statusArray.filter(e => _.isEmpty(e.salesType) && !_.isEmpty(e.make) && e.make.includes(_make))[0])
				data = _statusArray.filter(e => _.isEmpty(e.salesType) && !_.isEmpty(e.make) && e.make.includes(_make))[0]
			else if (!_.isEmpty(_saleType) && _statusArray.filter(e => !_.isEmpty(e.salesType) && _.isEmpty(e.make) && e.salesType.includes(_saleType))[0])
				data = _statusArray.filter(e => !_.isEmpty(e.salesType) && _.isEmpty(e.make) && e.salesType.includes(_saleType))[0];
			else if (_statusArray.filter(e => _.isEmpty(e._make) && _.isEmpty(e.salesType))[0])
				data = _statusArray.filter(e => _.isEmpty(e._make) && _.isEmpty(e.salesType))[0];
		}

		if (!_.isEmpty(data) && data.pipeline && data.stage) {
			let _stageHistory = Object.assign([], stageHistory);
			let _stageData = CommonHelper.handleStageHistory(stageHistory, data.pipeline, data.stage);
			if (!_.isEmpty(_stageData)) {
				_stageHistory.push(_stageData);
				state.stageHistory = Object.assign([], _stageHistory);
				state.pipeline = data.pipeline;
				state.stage = data.stage;
				state.stageDate = window.firebase.firestore.Timestamp.now();
			}
		}

		state.statusHistory = window.firebase.firestore.FieldValue.arrayUnion(
			{
				date: window.firebase.firestore.Timestamp.now(),
				status: _status,
				user: localStorage.uid
			}
		)
		return state;

	}

	handleCreateSubLeads = async (dealersettings, fields, requirements, soldIDs, checkPrimary, _status, nxtStatus, statusSettings, linkCount) => {
		let objData = Object.assign({}, objNewSubEnquiryVM);
		for (let [key, value] of Object.entries(fields)) {
			if (objData.hasOwnProperty(key)) objData[key] = value;
		}

		// let _dynamicStatus = Object.assign({}, objData.dynamicStatus);

		// if (_status) {
		// 	objData.wonDate = window.firebase.firestore.Timestamp.now();
		// 	objData.status = _status;
		// 	objData = this.handleMoveStageForStatusChange(_status, objData, fields, statusSettings);
		// 	_dynamicStatus[_status] = window.firebase.firestore.Timestamp.now();
		// }

		// if (nxtStatus) {
		// 	objData.status = nxtStatus;
		// 	objData = this.handleMoveStageForStatusChange(nxtStatus, objData, fields, statusSettings);
		// 	_dynamicStatus[nxtStatus] = window.firebase.firestore.Timestamp.now();
		// }

		// objData['dynamicStatus'] = _dynamicStatus;
		objData.modifiedBy = localStorage.uid;
		objData.modifiedDate = window.firebase.firestore.Timestamp.now();
		objData.modifiedFrom = 'web';
		if (!objData.linkID) objData.linkID = objData.displayID;
		if (!objData.linkEnquiryID) objData.linkEnquiryID = objData.documentID;

		const { requirement } = fields;
		let primaryReqID = (!_.isEmpty(requirement)) ? requirement.documentID : '';
		let primaryID = primaryReqID;

		//IF NO PRIMARY VEHICLE SELECTED, MARK FIRST ONE AS PRIMARY
		if (soldIDs.indexOf(primaryReqID) < 0) {
			primaryID = soldIDs[0];
		}

		const batch = firestoreDB(dealersettings).firestore().batch();
		let _selectedIds = {};
		let i = linkCount > 0 ? linkCount : 0;
		soldIDs.forEach((requirementID, index) => {

			let isPrimary = checkPrimary ? (primaryID && primaryID === requirementID ? true : false) : false;
			let _enquiry = Object.assign({}, objData);
			let _requirement = Object.assign({}, requirements.filter(e => e.documentID === requirementID)[0]);

			if (!isPrimary) {
				let _count = (i) + 1;
				_enquiry.documentID = firestoreDB(dealersettings).firestore().collection('enquiries').doc().id;
				_enquiry.displayID = objData.linkID + '-' + (_count > 9 ? _count : `0${_count}`);
				_requirement.enquiryID = _enquiry.documentID;
				_enquiry.saleslogID = null;
				if (!_.isEmpty(_enquiry.tags)) {
					_enquiry.tags = Object.assign([], _enquiry.tags).filter(e => e.type !== "saleslogs")
				}
				i++;
			}

			_requirement.isSold = true;
			_requirement.modifiedBy = localStorage.uid;
			_requirement.modifiedDate = window.firebase.firestore.Timestamp.now();
			_requirement.modifiedFrom = 'web';

			//handleChangeStockStatus
			if (!_.isEmpty(_requirement.stock) && _requirement.stock.documentID) {
				let objStockData = {
					status: stockStatus.SOLD,
					soldDate: _enquiry.modifiedDate,
					enquiryID: _enquiry.documentID,
					statusModifiedBy: _enquiry.modifiedBy,
					statusModifiedFrom: 'web',
					modifiedBy: _enquiry.modifiedBy,
					modifiedDate: _enquiry.modifiedDate,
					modifiedFrom: 'web'
				}

				_requirement.stock = { ..._requirement.stock, ...objStockData }
				batch.set(window.firebase.firestore().doc(`stock/${_requirement.stock.documentID}`), objStockData, { merge: true });
			}

			//handleReqMarkAsPrimary
			_enquiry.requirement = _requirement;

			let _dynamicStatus = Object.assign({}, _enquiry.dynamicStatus);
			if (_status) {
				_enquiry.wonDate = window.firebase.firestore.Timestamp.now();
				_enquiry.status = _status;
				_enquiry = this.handleMoveStageForStatusChange(_status, _enquiry, fields, statusSettings);
				_dynamicStatus[_status] = window.firebase.firestore.Timestamp.now();
			}
			else {
				_enquiry = this.handleMoveStageForStatusChange(_enquiry.status, _enquiry, fields, statusSettings);
			}

			if (nxtStatus) {
				_enquiry.status = nxtStatus;
				_enquiry = this.handleMoveStageForStatusChange(nxtStatus, _enquiry, fields, statusSettings);
				_dynamicStatus[nxtStatus] = window.firebase.firestore.Timestamp.now();
			}
			_enquiry['dynamicStatus'] = _dynamicStatus;

			//Remove empty value from object
			for (var propName in _enquiry) {
				if (_enquiry[propName] === null || _enquiry[propName] === undefined || _enquiry[propName] === '' || (_.isObject(_enquiry[propName]) && _.isEmpty(_enquiry[propName]))) {
					delete _enquiry[propName];
				}
			}
			_selectedIds[_enquiry.documentID] = _enquiry;
			batch.set(firestoreDB(dealersettings).firestore().doc(`enquiries/${_enquiry.documentID}`), _enquiry, { merge: true });

			batch.set(firestoreDB(dealersettings).firestore().doc(`enquiries/${_enquiry.documentID}/requirements/${requirementID}`), _requirement, { merge: true });

			if (!isPrimary) {
				batch.delete(firestoreDB(dealersettings).firestore().doc(`enquiries/${objData.documentID}/requirements/${_requirement.documentID}`))
				CommonHelper.saveChangeLog(objData.clientID, objData.groupID, objData.documentID, `Sub lead created ${_enquiry.displayID}`, 'enquiry', 'updated', batch);
				CommonHelper.saveChangeLog(_enquiry.clientID, _enquiry.groupID, _enquiry.documentID, `Lead created from ${_enquiry.linkID}`, 'enquiry', 'updated', batch);
			}

			//console.log(_selectedIds)

		});

		batch.commit()
			.then(() => {
				for (let [key, value] of Object.entries(_selectedIds)) {
					let state = Object.assign({}, value);
					//let isPrimary = (primaryID && primaryID === requirementID ? true : false);
					CommonHelper.savePrimaryFieldsToEnquiry(state, key, true, dealersettings);
				}

				toast.notify('Enquiry updated successfully.', { duration: 2000 });

				if (objData.contactID) {
					const updateRepeatCustomer = window.firebase.functions().httpsCallable(`contact-updateRepeatCustomer`);
					updateRepeatCustomer({ 'contactID': objData.contactID, 'projectId': objData?.projectId ? objData.projectId : null })
				}


			})
			.catch((error) => {
				console.error(error);

				toast.notify('Something went wrong', {
					duration: 2000
				})
			});


	}

	generateSubLeadsData = async (dealersettings, fields, requirements, soldIDs, checkPrimary, _status, nxtStatus, statusSettings, linkCount) => {
		let objData = Object.assign({}, objNewSubEnquiryVM);
		const { requirement } = fields;
		for (let [key, value] of Object.entries(fields)) {
			if (objData.hasOwnProperty(key)) objData[key] = value;
		}

		objData.modifiedBy = localStorage.uid;
		objData.modifiedDate = window.firebase.firestore.Timestamp.now();
		objData.modifiedFrom = 'web';
		if (!objData.linkID) objData.linkID = objData.displayID;
		if (!objData.linkEnquiryID) objData.linkEnquiryID = objData.documentID;

		let primaryReqID = (!_.isEmpty(requirement)) ? requirement.documentID : '';
		let primaryID = primaryReqID;

		//IF NO PRIMARY VEHICLE SELECTED, MARK FIRST ONE AS PRIMARY
		if (soldIDs.indexOf(primaryReqID) < 0) {
			primaryID = soldIDs[0];
		}

		// let _dynamicStatus = Object.assign({}, objData.dynamicStatus);

		// if (_status) {
		// 	objData.wonDate = window.firebase.firestore.Timestamp.now();
		// 	objData.status = _status;
		// 	objData = this.handleMoveStageForStatusChange(_status, objData, fields, statusSettings);
		// 	_dynamicStatus[_status] = window.firebase.firestore.Timestamp.now();
		// }

		// if (nxtStatus) {
		// 	objData.status = nxtStatus;
		// 	objData = this.handleMoveStageForStatusChange(nxtStatus, objData, fields, statusSettings);
		// 	_dynamicStatus[nxtStatus] = window.firebase.firestore.Timestamp.now();
		// }

		// objData.dynamicStatus = _dynamicStatus;

		let i = linkCount > 0 ? linkCount : 0;
		let _enquiries = [];
		soldIDs.forEach((requirementID, index) => {

			let isPrimary = checkPrimary ? (primaryID && primaryID === requirementID ? true : false) : false;
			let _enquiry = Object.assign({}, objData);
			let _requirement = Object.assign({}, requirements.filter(e => e.documentID === requirementID)[0]);

			if (!isPrimary) {
				let _count = (i) + 1;
				_requirement.enquiryID = _enquiry.documentID;

				_enquiry.documentID = firestoreDB(dealersettings).firestore().collection('enquiries').doc().id;
				_enquiry.displayID = objData.linkID + '-' + (_count > 9 ? _count : `0${_count}`);
				_enquiry.isPrimary = false;

				//_enquiry.purchaseIntention = '';
				_enquiry.depositAmount = 0;
				_enquiry.depositReciptNumber = '';
				_enquiry.grossAmount = 0;
				_enquiry.deliveryDate = null;
				_enquiry.dynamicFields = {};
				_enquiry.chassisNo = '';
				_enquiry.regNo = '';
				_enquiry.stockNo = '';
				_enquiry.warrantyStartDate = null;
				_enquiry.warrantyExpiry = null;

				_enquiry.saleslogID = null;
				if (!_.isEmpty(_enquiry.tags)) {
					_enquiry.tags = Object.assign([], _enquiry.tags).filter(e => e.type !== "saleslogs")
				}

				i++;
			}
			else {
				_enquiry.isPrimary = true;
			}

			//handleReqMarkAsPrimary
			_enquiry.requirement = _requirement;

			let _dynamicStatus = Object.assign({}, _enquiry.dynamicStatus);
			if (_status) {
				_enquiry.wonDate = window.firebase.firestore.Timestamp.now();
				_enquiry.status = _status;
				_enquiry = this.handleMoveStageForStatusChange(_status, _enquiry, fields, statusSettings);
				_dynamicStatus[_status] = window.firebase.firestore.Timestamp.now();
			}
			else {
				_enquiry = this.handleMoveStageForStatusChange(_enquiry.status, _enquiry, fields, statusSettings);
			}

			if (nxtStatus) {
				_enquiry.status = nxtStatus;
				_enquiry = this.handleMoveStageForStatusChange(nxtStatus, _enquiry, fields, statusSettings);
				_dynamicStatus[nxtStatus] = window.firebase.firestore.Timestamp.now();
			}
			_enquiry['dynamicStatus'] = _dynamicStatus;

			//Remove empty value from object
			for (var propName in _enquiry) {
				if (_enquiry[propName] === null || _enquiry[propName] === undefined || _enquiry[propName] === '' || (_.isObject(_enquiry[propName]) && _.isEmpty(_enquiry[propName]))) {
					delete _enquiry[propName];
				}
			}
			_enquiries.push(_enquiry)
		});
		//console.log('_enquiries', _enquiries)
		return _enquiries;

	}

	handleCreateSubEnquiriesOnWon = async (dealersettings, enquiries, objData) => {


		const batch = firestoreDB(dealersettings).firestore().batch();
		const batchPrimary = window.firebase.firestore().batch();
		let _selectedIds = {};
		enquiries.forEach((enq) => {

			let _enquiry = Object.assign({}, enq);

			let isPrimary = _enquiry.isPrimary ? true : (enq.documentID === objData.documentID ? true : false);

			let _requirement = Object.assign({}, _enquiry.requirement);

			_enquiry.modifiedBy = localStorage.uid;
			_enquiry.modifiedDate = window.firebase.firestore.Timestamp.now();
			_enquiry.modifiedFrom = 'web';

			_requirement.isSold = true;
			_requirement.modifiedBy = localStorage.uid;
			_requirement.modifiedDate = window.firebase.firestore.Timestamp.now();
			_requirement.modifiedFrom = 'web';

			//handleChangeStockStatus
			if (!_.isEmpty(_requirement.stock) && _requirement.stock.documentID) {
				let objStockData = {
					status: stockStatus.SOLD,
					soldDate: _enquiry.modifiedDate,
					enquiryID: _enquiry.documentID,
					statusModifiedBy: _enquiry.modifiedBy,
					statusModifiedFrom: 'web',
					modifiedBy: _enquiry.modifiedBy,
					modifiedDate: _enquiry.modifiedDate,
					modifiedFrom: 'web'
				}

				_requirement.stock = { ..._requirement.stock, ...objStockData }
				batch.set(window.firebase.firestore().doc(`stock/${_requirement.stock.documentID}`), objStockData, { merge: true });
			}

			//handleReqMarkAsPrimary
			_enquiry.requirement = _requirement;

			if (_enquiry.hasOwnProperty('isPrimary')) delete _enquiry['isPrimary'];

			//Remove empty value from object
			for (var propName in _enquiry) {
				if (_enquiry[propName] === null || _enquiry[propName] === undefined || _enquiry[propName] === '' || _enquiry[propName] === [] || (_.isObject(_enquiry[propName]) && _.isEmpty(_enquiry[propName]))) {
					delete _enquiry[propName];
				}
			}

			_selectedIds[_enquiry.documentID] = _enquiry;
			batch.set(firestoreDB(dealersettings).firestore().doc(`enquiries/${_enquiry.documentID}`), _enquiry, { merge: true });

			batch.set(firestoreDB(dealersettings).firestore().doc(`enquiries/${_enquiry.documentID}/requirements/${_requirement.documentID}`), _requirement, { merge: true });

			if (!isPrimary) {
				batch.delete(firestoreDB(dealersettings).firestore().doc(`enquiries/${objData.documentID}/requirements/${_requirement.documentID}`))
				CommonHelper.saveChangeLog(objData.clientID, objData.groupID, objData.documentID, `Sub lead created ${_enquiry.displayID}`, 'enquiry', 'updated', batchPrimary);
				CommonHelper.saveChangeLog(_enquiry.clientID, _enquiry.groupID, _enquiry.documentID, `Lead created from ${_enquiry.linkID}`, 'enquiry', 'updated', batchPrimary);
			}

			//console.log(_selectedIds)

		});

		batch.commit()
			.then(() => {
				for (let [key, value] of Object.entries(_selectedIds)) {
					let state = Object.assign({}, value);
					//let isPrimary = (primaryID && primaryID === requirementID ? true : false);
					CommonHelper.savePrimaryFieldsToEnquiry(state, key, true, dealersettings);
				}
				batchPrimary.commit();
				toast.notify('Enquiry updated successfully.', { duration: 2000 });

				if (objData.contactID) {
					const updateRepeatCustomer = window.firebase.functions().httpsCallable(`contact-updateRepeatCustomer`);
					updateRepeatCustomer({ 'contactID': objData.contactID, 'projectId': objData?.projectId ? objData.projectId : null })
				}


			})
			.catch((error) => {
				console.error(error);

				toast.notify('Something went wrong', {
					duration: 2000
				})
			});


	}

	getMandatoryErrors = (enquiries, mandatoryFields, enquiryOptionsDF) => {
		let errors = {};

		enquiries && enquiries.forEach(_enq => {

			let enquiry = Object.assign({}, objEnquiry);
			for (let [key, value] of Object.entries(_enq)) {
				enquiry[key] = value;
			}

			let errorKey = [];
			mandatoryFields && mandatoryFields.forEach((key) => {
				if ((_.isObject(enquiry[key]) && _.isEmpty(enquiry[key])) || (!_.isObject(enquiry[key]) && !_.isBoolean(enquiry[key]) && !enquiry[key])) {

					if (enquiry.hasOwnProperty(key) ||
						((!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.value === key && m.active === true)[0])) &&
							(_.isEmpty(enquiry.dynamicFields) ||
								(!_.isEmpty(enquiry.dynamicFields) && _.isEmpty(enquiry.dynamicFields[key]) && !_.isBoolean(enquiry.dynamicFields[key]) && !_.isNumber(enquiry.dynamicFields[key]))
							))
					) {
						//formIsValid = false;
						errorKey.push(key);
					}

				}
			});

			if (!_.isEmpty(errorKey)) {
				errors[enquiry.documentID] = errorKey;
			}

		});

		return errors;
	}

	updateEventsAttendee = (_attendeeID, _enquiryID, _dealersettings, batch) => {
		if (_attendeeID) {
			let newEventVM = {};
			newEventVM.allocationStatus = eventAllocationStatus.ACCEPTED;
			newEventVM.actionedOn = window.firebase.firestore.Timestamp.now();
			newEventVM.enquiryID = _enquiryID;
			Object.keys(newEventVM).forEach(key => newEventVM[key] === undefined ? delete newEventVM[key] : {});

			if (batch)
				batch.set(firestoreDB(_dealersettings, true).firestore().doc(`eventsProAttendees/${_attendeeID}`), newEventVM, { merge: true });
			else
				firestoreDB(_dealersettings, true).firestore().doc(`eventsProAttendees/${_attendeeID}`).set(newEventVM, { merge: true })

		}

	}

	bindDefaultStatus = (_defaultStatus, _currentStatus) => {
		let _excludedStatus = [enquiryStatus.WON, enquiryStatus.DELIEVERD, enquiryStatus.LOST, enquiryStatus.PROSPECTLOST, enquiryStatus.PENDINGLOST]
		return _defaultStatus ? _defaultStatus : (_currentStatus && !_excludedStatus.includes(_currentStatus) ? _currentStatus : enquiryStatus.OPEN);
	}

	//region tradeinpro status reopen
	reopenAllCompletedTradeinPro = async (dealersettings, enquiryID) => {
		const response = await firestoreDB(dealersettings).firestore().collection('tradeins')
			.where('enquiryID', '==', enquiryID)
			.where('status', 'in', [tipStatus.WON, tipStatus.LOST])
			.where('isDeleted', '==', false)
			.get();

		const tradeIns = [];
		if (response.docs.length > 0) {
			response.docs.forEach((doc) => {
				let objData = Object.assign({}, doc.data());
				objData.documentID = doc.id;
				if (!_.isEmpty(objData.tradeinProID))
					tradeIns.push(objData);
			});
		}

		if (_.isEmpty(tradeIns)) return;

		toast.notify(
			<div style={{ fontSize: `14px` }}>
				<span
					className="spinner-border spinner-button  mr-2 spinner-border text-primary"
					role="status"
					aria-hidden="true"
				></span>
				{'Re-opening appraisal(s)...'}
			</div>,
			{
				position: "top",
				duration: null,
			}
		);

		const batch = firestoreDB(dealersettings).firestore().batch();

		for (const _tradein of tradeIns) {
			let tradeinproid = _tradein?.tradeinProID ? _tradein.tradeinProID : (_tradein?.tradeinPro?.documentID ? _tradein.tradeinPro.documentID : null)
			let tradeinid = _tradein.documentID
			let val = tipStatus.COMPLETED;
			if (tradeinproid && tradeinid) {
				let _tradePro = null
				let snap = await firestoreDB(dealersettings).firestore().doc(`tradeinPro/${tradeinproid}`).get();
				if (snap.exists) {
					_tradePro = snap.data();
				}

				let _tradeproOffers = [];
				let snap1 = await firestoreDB(dealersettings).firestore().collection(`tradeinPro/${tradeinproid}/offers`).get();
				snap1.docs && snap1.docs.forEach(_offer => {
					if (_offer.exists)
						_tradeproOffers.push(_offer.data())
				})

				let objData = {};
				objData.wonDate = null;
				objData.lostDate = null;
				objData.lostReason = null;
				objData.lostSubReason = null;
				objData.status = val;
				objData.modifiedBy = localStorage.uid;
				objData.modifiedDate = window.firebase.firestore.Timestamp.now();
				objData.modifiedFrom = 'web';
				objData = this.handleMoveStageForStatusChangeTIP(val, objData, _tradePro, dealersettings);

				if (!_.isEmpty(_tradePro.tradein)) objData.tradein = { ..._tradePro.tradein, ['status']: val }

				batch.set(firestoreDB(dealersettings).firestore().doc(`tradeinPro/${tradeinproid}`), objData, { merge: true });
				//console.log(_tradePro, _tradeproOffers, objData)
				let objTradeInData = {};
				objTradeInData.status = val;
				objTradeInData.modifiedBy = localStorage.uid;
				objTradeInData.modifiedDate = window.firebase.firestore.Timestamp.now();
				objTradeInData.modifiedFrom = 'web';

				if (tradeinid)
					batch.set(firestoreDB(dealersettings).firestore().doc(`tradeins/${tradeinid}`), objTradeInData, { merge: true });

				!_.isEmpty(_tradeproOffers) && _tradeproOffers.forEach(newVM => {
					newVM.modifiedBy = localStorage.uid;
					newVM.modifiedDate = window.firebase.firestore.Timestamp.now();
					newVM.modifiedFrom = 'web';
					if (newVM.evaluationPrice) newVM.status = tipOfferStatus.COMPLETED; else newVM.status = tipOfferStatus.PENDING;

					if (newVM.tradeinProID && newVM.documentID)
						batch.set(firestoreDB(dealersettings).firestore().doc(`tradeinPro/${tradeinproid}/offers/${newVM.documentID}`), newVM, { merge: true });
				})

				let _objLogData = {
					tradeinProID: tradeinproid,
					offerID: _tradePro.offer?.documentID ? _tradePro?.offer?.documentID : null,
					projectId: _tradePro?.projectId ? _tradePro?.projectId : null,
					message: 'The appraisal has been reopened from the enquiry.'
				}
				CommonHelper.saveTradeInProLog(dealersettings, _objLogData, batch);
			}
		}

		await batch.commit().then(snapshot => {
			toast.closeAll();
			toast.notify('Appraisal(s) re-opened.', { duration: 2000 });

		}).catch((error) => {
			toast.closeAll();
			console.error("Error updating document: ", error);
		});
	}

	handleMoveStageForStatusChangeTIP = (_status, state, fields, dealersettings) => {
		let setttings = dealersettings.client?.tradeinPro;
		const { stageHistory } = fields;

		let _selPipeline = fields.pipeline && !_.isEmpty(setttings) && setttings.allPipelines.filter(e => e.value === fields.pipeline)[0];
		let statusSettings = _selPipeline && _selPipeline.statusSettings;

		let data = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === _status)[0];
		if (!_.isEmpty(data) && data.pipeline && data.stage) {
			let _stageHistory = Object.assign([], stageHistory);
			let _stageData = CommonHelper.handleStageHistory(stageHistory, data.pipeline, data.stage);
			if (!_.isEmpty(_stageData)) {
				_stageHistory.push(_stageData);
				state.stageHistory = Object.assign([], _stageHistory);
				state.pipeline = data.pipeline;
				state.stage = data.stage;
				state.stageDate = window.firebase.firestore.Timestamp.now();
			}

			return state;
		}
		else
			return state;
	}

	isFileCategoryExist = async (_fileType, _enquiryID, dealersettings) => {
		if (_fileType && _fileType.split('_')?.length > 1) {
			const enqDoc = await firestoreDB(dealersettings).firestore().collection(`enquiries/${_enquiryID}/files`)
				.where('category', '==', _fileType.split('_')[2])
				.where('isDeleted', '==', false)
				.limit(1).get();
			if (enqDoc.size > 0)
				return true;
			else
				return false
		}
		return true;
	}

	isActivityExist = async (_logType, _enquiryID, dealersettings) => {
		if (_logType && _logType.split('_')?.length > 1) {
			const enqDoc = await firestoreDB(dealersettings).firestore().collection(`activities`)
				.where('enquiryID', '==', _enquiryID)
				.where('type', '==', _logType.split('_')[0])
				.where('subType', '==', _logType.split('_')[1])
				.where('isDeleted', '==', false)
				.limit(1).get();
			if (enqDoc.size > 0)
				return true;
			else
				return false
		}
		return true;
	}


	getIntakeURL = async (tradein, dealersettings) => {
		try {

			if (tradein?.tradeinProID) {
				let pdfUrl = null;
				const querySnapshot = await firestoreDB(dealersettings).firestore().collection(`tradeinPro/${tradein?.tradeinProID}/files`)
					.where('origin', '==', 'intake')
					.where('isDeleted', '==', false)
					.get()

				if (querySnapshot.size > 0) {
					let _list = [];
					querySnapshot.docs.forEach(rec => {
						_list.push({
							...rec.data(),
							documentID: rec.id
						})
					})
					pdfUrl = _.orderBy(_list.filter(a => !_.isEmpty(a.url)), ['addedDate'], ['desc'])[0]?.url;
				}
				if (pdfUrl) return { success: true, data: pdfUrl, type: 'file' }

				let pdf2Url = null;
				let _clientID = (tradein?.clientID ? tradein?.clientID : dealersettings.client.id)
				if (tradein?.chassisNo) {
					const querySnapshot = await firestoreDB(dealersettings).firestore().collection(`ppsr`)
						.where('clientID', '==', _clientID)
						.where('vinNo', '==', tradein?.chassisNo)
						.where('success', '==', true)
						.get()

					if (querySnapshot.size > 0) {
						let _list = [];
						querySnapshot.docs.forEach(rec => {
							_list.push({
								...rec.data(),
								documentID: rec.id
							})
						})
						pdf2Url = _.orderBy(_list.filter(a => !_.isEmpty(a.certURL)), ['logDate'], ['desc'])[0]?.certURL;
					}
					//console.log('pdf2Url', pdf2Url);
				}


				if (pdf2Url) {
					//ppsr has pdf certificate url, open with pdf
					let apiURL = (window.location.hostname === "ams-pro-live.web.app" || window.location.hostname === "app.fusionamspro.com") ? "https://app.fusionamspro.com" : "https://qa.fusionamspro.com";
					let _url = `${apiURL}/tradeinpro/intake/${tradein?.tradeinProID}`;

					var objData = Object.assign({}, {
						"clientID": _clientID,
						"url": _url,
						"filePrefix": 'intake',
						"timeout": 120
					});
					// const A4_WIDTH = 595.28;
					// const A4_HEIGHT = 841.89;
					//console.log('showIntakeAgreement', objData);
					const generatePDF = window.firebase.functions().httpsCallable('generic-generatePDF');
					let response = await generatePDF(objData)
					//console.log('generatePDF', response);
					if (response.data.success && !_.isEmpty(response.data?.data)) {
						const pdf1Url = response.data?.data;
						// Fetch existing PDF
						const existingPdfBytes_A = await fetch(pdf1Url).then(res => res.arrayBuffer());
						const existingPdfBytes_B = await fetch(pdf2Url).then(res => res.arrayBuffer());

						// Load both PDFs
						const pdfDoc = await PDFDocument.create();
						const [existingPdfDoc_A, existingPdfDoc_B] = await Promise.all([
							PDFDocument.load(existingPdfBytes_A),
							PDFDocument.load(existingPdfBytes_B),
						]);

						// Copy pages from both PDFs
						const existingPdfPages_A = await pdfDoc.copyPages(existingPdfDoc_A, existingPdfDoc_A.getPageIndices());
						const existingPdfPages_B = await pdfDoc.copyPages(existingPdfDoc_B, existingPdfDoc_B.getPageIndices());

						// Add pages to the new PDF       
						existingPdfPages_A.forEach((page) => { pdfDoc.addPage(page); });
						existingPdfPages_B.forEach((page) => { pdfDoc.addPage(page); });

						// Save the new PDF
						const mergedPdfBytes = await pdfDoc.save();
						const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
						blob.name = `Intake_${tradein.make || ''}_${tradein.model || ''}_${tradein.year || tradein.regNo || tradein.exteriorColor || ''}_${moment().format('hmmssSS')}.pdf`
						const url = URL.createObjectURL(blob);
						console.log('blob', blob);
						if (url && tradein?.tradeinProID) {
							this.addPDFFile(tradein, mergedPdfBytes, blob, dealersettings)
						}
						return { success: true, data: url, type: 'pdf' }
						//window.open(url, '_blank');
					}
					else {
						const url = `/tradeinpro/intake/${tradein?.tradeinProID}?isView=y`;
						return { success: true, data: url, type: 'url' }
						//window.open(url, '_blank');
					}
				}
				else {
					return { success: false, type: 'ppsr' }
					// //ppsr has no pdf certificate url the open with htnl 
					// const pdf1Url = `/tradeinpro/intake/${tradein?.tradeinProID}?isView=y`;
					// return pdf1Url;
					// //window.open(pdf1Url, '_blank');

				}
			}
			else { return { success: false, type: 'noid' } }

		} catch (error) {
			console.log('showIntakeAgreement', error);
			return { success: false, type: 'error', error: error }
		}
	}

	addPDFFile = async (tradein, url, blob, dealersettings) => {
		try {
			const objData = Object.assign({});

			let uid = moment()._d.getTime().toString();
			let clientID = tradein.clientID ? tradein.clientID : (dealersettings ? dealersettings.client.id : '');
			let enquiryID = tradein.enquiryID ? tradein.enquiryID : (tradein.enquiry ? tradein.enquiry?.documentID : '');
			let contactID = tradein.contactID ? tradein.contactID : (tradein.contact ? tradein.contact?.documentID : '');

			let tradeinProID = tradein.tradeinProID ? tradein.tradeinProID : ''
			let logNotes = 'File added';

			if (tradeinProID && clientID) {

				var _fileCollection = `tradeinPro/${tradeinProID}`

				const res = await storageBucket(dealersettings).ref(`${clientID}/${_fileCollection}/${uid}/${blob.name}`).put(blob);
				if (res.state === 'success') {
					const dataURL = await storageBucket(dealersettings).ref(`${clientID}/${_fileCollection}/${uid}`)
						.child(blob.name)
						.getDownloadURL();
					console.log('addPDFFile', dataURL);
					if (!dataURL) return
					objData.addedBy = dealersettings ? dealersettings.id : '';
					objData.addedDate = window.firebase.firestore.Timestamp.now();
					objData.addedFrom = 'web';
					objData.modifiedBy = dealersettings ? dealersettings.id : '';
					objData.modifiedDate = window.firebase.firestore.Timestamp.now();
					objData.modifiedFrom = 'web';
					objData.isDeleted = false;
					objData.name = blob.name;
					objData.size = blob.size;

					objData.category = 'others';
					objData.origin = 'intake'
					objData.url = dataURL;

					objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
					objData.documentID = firestoreDB(dealersettings).firestore().collection('tradeinPro').doc().id

					if (objData.name) logNotes = 'File: ' + objData.name + ' added';
					if (enquiryID) objData.enquiryID = enquiryID
					if (tradeinProID) objData.tradeinProID = tradeinProID
					if (contactID) objData.contactID = contactID


					if (tradein.module) objData.module = tradein.module;

					//Remove empty value from object
					Object.keys(objData).forEach(key => (objData[key] === undefined || objData[key] === null) ? delete objData[key] : {});

					firestoreDB(dealersettings).firestore().collection(`${_fileCollection}/files`).doc(objData.documentID).set(objData, { merge: true })

					if (objData.enquiryID)
						firestoreDB(dealersettings).firestore().collection(`enquiries/${objData.enquiryID}/files`).doc(objData.documentID).set(objData, { merge: true })

					let _objLogData = {
						notes: logNotes,
						type: 'files',
					}
					if (enquiryID) _objLogData.enquiryID = enquiryID;
					if (tradeinProID) _objLogData.tradeinProID = tradeinProID;
					if (contactID) _objLogData.contactID = contactID;
					_objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
					CommonHelper.saveFileChangeLog(_objLogData, 'created');

					firestoreDB(dealersettings).firestore().doc(`${_fileCollection}`).set({ isFiles: true }, { merge: true })

				}
			}
		} catch (error) {
			console.log('addPDFFile', error);
			return { success: false, type: 'error', error: error }
		}
	};

	linkEnqruiy = async (data, props) => {
		try {
			var id = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails').doc().id;
			const linkref = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails').doc(id);

			let model = {
				documentID: id,
				clientID: props.dealersettings.client.documentID,
				thread: data.thread,
				token: props.dealersettings.nylasAccessToken,
				linkedBy: localStorage.uid,
				linkedDate: window.firebase.firestore.Timestamp.now(),
				type: CommonHelper.getActivityType('log', props.originType),
				projectId: props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null
			};

			if (!_.isEmpty(props.enquiry)) {
				model.enquiryID = props.enquiry.documentID;
				model.enquiry = props.enquiry;
				if (!_.isEmpty(props.enquiry.contact)) {
					model.contactID = props.enquiry.contact.documentID;
					model.contact = props.enquiry.contact;
				}
			}
			else if (!_.isEmpty(props.tradeinPro)) {
				model.tradeinProID = props.tradeinPro.documentID;
				model.tradeinPro = props.tradeinPro;
				if (!_.isEmpty(props.tradeinPro.contact)) {
					model.contactID = props.tradeinPro.contact.documentID;
					model.contact = props.tradeinPro.contact;
				}
			}
			else if (!_.isEmpty(props.eventsPro)) {
				model.eventsProID = props.eventsPro.documentID;
				model.eventsPro = props.eventsPro;
				if (!_.isEmpty(props.eventsPro.contact)) {
					model.contactID = props.eventsPro.contact.documentID;
					model.contact = props.eventsPro.contact;
				}
			}
			else if (!_.isEmpty(props.service)) {
				model.serviceID = props.service.documentID;
				model.service = props.service;
				if (!_.isEmpty(props.service.contact)) {
					model.contactID = props.service.contact.documentID;
					model.contact = props.service.contact;
				}
			}
			else if (!_.isEmpty(props.contact)) {
				model.contactID = props.contact.documentID;
				model.contact = props.contact;
			}
			if (props?.enquiryID) model.enquiryID = props.enquiryID;
			if (props?.tradeinProID) model.tradeinProID = props.tradeinProID;
			if (props?.eventsProID) model.eventsProID = props.eventsProID;
			if (props?.serviceID) model.serviceID = props.serviceID;
			if (props?.fleetProID) model.fleetProID = props.fleetProID;
			if (props?.contactID) model.contactID = props.contactID;

			Object.keys(model).forEach(key => (model[key] === undefined || _.isEmpty(model[key])) ? delete model[key] : {});

			linkref.set(model, { merge: true });

			if (!_.isEmpty(model.enquiryID)) {
				firestoreDB(props.dealersettings).firestore().doc(`enquiries/${model.enquiryID}`)
					.set({
						modifiedBy: localStorage.uid,
						modifiedFrom: 'web',
						unactionedNotify: enqUnActionStatus.COMPLETED,
						modifiedDate: window.firebase.firestore.Timestamp.now(),
					}, { merge: true })
			}

			if (!_.isEmpty(data.data)) {
				let messageRef = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails').doc(id).collection('messages').doc(data.data.id)
				messageRef.set({
					...data.data,
					projectId: props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null
				})
			}

			return true
		} catch (error) {
			console.log('linkEnqruiy', error);
			return false;
		}
	}

	createTestdrivesFromEventsPro = async (_enquiry, _testDrives, dealersettings, batch) => {
		_testDrives && _testDrives.forEach(async (testdrive) => {
			if ((testdrive.make && testdrive.model) || testdrive.stock) {

				const _objTestdrive = Object.assign({});
				_objTestdrive.addedBy = 'System';
				_objTestdrive.addedDate = testdrive.startDate ? testdrive.startDate : window.firebase.firestore.Timestamp.now();
				_objTestdrive.addedFrom = 'web';
				_objTestdrive.modifiedBy = 'System';
				_objTestdrive.modifiedDate = testdrive.startDate ? testdrive.startDate : window.firebase.firestore.Timestamp.now();
				_objTestdrive.startDate = testdrive.startDate ? testdrive.startDate : window.firebase.firestore.Timestamp.now();
				_objTestdrive.endDate = testdrive.endDate ? testdrive.endDate : window.firebase.firestore.Timestamp.now();
				_objTestdrive.modifiedFrom = 'web';
				_objTestdrive.isDeleted = false;
				_objTestdrive.status = testDriveStatus.COMPLETE;
				_objTestdrive.documentID = window.firebase.firestore().collection('testdrives').doc().id;
				_objTestdrive.clientID = _enquiry?.clientID ? _enquiry.clientID : (dealersettings ? dealersettings.client.id : '');
				_objTestdrive.projectId = _enquiry?.projectId ? _enquiry.projectId : (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null);
				if (!_.isEmpty(_enquiry)) {
					_objTestdrive.enquiry = _enquiry;
					_objTestdrive.enquiryID = _enquiry.documentID;
					_objTestdrive.contact = _enquiry?.contact || null;
					_objTestdrive.contactID = _enquiry?.contact?.documentID || null;
					_objTestdrive.tags = _enquiry.tags || {}
				}
				_objTestdrive.stock = {
					make: testdrive?.stock?.make || testdrive.make || null,
					model: testdrive?.stock?.model || testdrive.model || null,
					extColor: testdrive?.stock?.extColor || testdrive.extColor || null,
					regNo: testdrive?.stock?.regNo || testdrive.regNo || null,
					vinNo: testdrive?.stock?.vinNo || testdrive.vinNo || null,
					year: testdrive?.stock?.year || testdrive.year || null,
					type: testdrive.VehicleModelType || null
				}

				_objTestdrive.terms = testdrive.terms || null;

				if (!_.isEmpty(batch)) {
					batch.set(firestoreDB(dealersettings).firestore().doc(`testdrives/${_objTestdrive.documentID}`), _objTestdrive, { merge: true });
				}
				else {
					firestoreDB(dealersettings).firestore().doc(`testdrives/${_objTestdrive.documentID}`).set(_objTestdrive, { merge: true })
				}

			}
		});
	}

	showEnquiryStaticField = (key, value, dealersettings, allUsers) => {

		let _settings = dealersettings.client && dealersettings.client.settings;
		const currencySymbol = !_.isEmpty(dealersettings?.client?.currency) ? dealersettings.client.currency.symbol + " " : "$ ";

		if (key === 'origin') {
			return CommonHelper.getNameByValue(_settings?.origins, value, '--')
		} else if (key === 'enquiryType') {
			return CommonHelper.getNameByValue(_settings?.enquiryTypes, value, '--')
		} else if (key === 'financeProvider') {
			return CommonHelper.getNameByValue(_settings?.financeProviders, value, '--')
		} else if (key === 'financeTerms') {
			return CommonHelper.getLabelByValue(defaultFinanceTerms, value, '--')
		} else if (key === 'serviceType') {
			return CommonHelper.getNameByValue(_settings?.serviceTypes, value, '--')
		} else if (key === 'leadSource') {
			return CommonHelper.getNameByValue(_settings?.leadSource, value, '--')
		} else if (key === "campaign") {
			return CommonHelper.getNameByValue(_settings?.campaigns, value, '--')
		} else if (key === "label") {
			return CommonHelper.bindEnquiryLabel(_settings?.enquiryLabels, value, '--')
		} else if (key === "clientID") {
			return CommonHelper.getOtherDealerName(dealersettings, value, '--')
		} else if (key === "pipeline") {
			return CommonHelper.getLabelByValue(_.map(dealersettings?.allPipelines, function (e) { return { label: e.name, value: e.value, data: e } }), value, '--')
		} else if (key === "stage") {
			const _stages = [];
			dealersettings.allPipelines && dealersettings.allPipelines.forEach((doc) => {
				!_.isEmpty(doc.stages) && doc.stages.forEach(docsub => {
					_stages.push({
						value: docsub.value,
						label: docsub.name
					});
				});
			});
			return CommonHelper.getLabelByValue(_stages, value, '--')
		} else if (key === "status") {
			return CommonHelper.getLabelByValue(_.map(_settings?.enquiryStatus, function (e) {
				return {
					...e,
					label: <div className={`badge badge-pill badge-status-${e.value === "open" ? "open" : '--'}`}
						style={{ background: e.color ? e.color : "#6b717f" }}>
						{e.name.toUpperCase()}
					</div>
				}
			}), value, '--')
		} else if (key === "financeStatus") {
			let financeStatuses = [];
			_settings.financeStatus && _settings.financeStatus.forEach(doc => {
				financeStatuses.push({
					value: doc.value,
					active: _.isBoolean(doc.active) ? doc.active : true,
					label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
				});
			});

			return CommonHelper.getLabelByValue(financeStatuses ? [...financeStatuses, ...__financestatus] : __financestatus, value, '--')
		} else if (key === "afterMarketStatus") {
			return CommonHelper.getLabelByValue(_.map(_settings?.afterMarketStatus, function (e) {
				return {
					...e,
					label: <div className={`badge badge-pill badge-status-empty`} style={{ background: e.color ? e.color : "#6b717f" }}>{e.name.toUpperCase()}</div>
				}
			}), value, '--')
		} else if (
			key === "owner" || key === "confirmedBy" || key === "verifiedBy" || key === "financeBM" ||
			key === "afterMarketBM" || key === "ownerInbound" || key === "convertedBy" ||
			key === "convertedByGroup" || key === "convertedByOEM" || key === "convertedByRegion" || key === "oemConvertedBy"
		) {
			return CommonHelper.getUserNamebyId(allUsers, value, '--')
		} else if (key === "purchaseIntention") {
			return value ? moment(value).format("MMM YYYY") : '--'
		} else if (key === "warrantyStartDate" || key === "warrantyExpiry") {
			return value ? moment(value).format("DD MMM YYYY") : '--'
		} else if (
			key === "financeStartDate" || key === "deliveryDate" || key === "stageDate" || key === "wonDate" ||
			key === "lostDate" || key === "deliveredDate" || key === "confirmedDate" || key === "verifiedDate" ||
			key === "receivedDate" || key === "convertedDate" || key === "convertedDateGroup" || key === "convertedDateOEM" ||
			key === "convertedDateRegion" || key === "oemConvertedDate"
		) {
			if (_.isEmpty(value) || value?.seconds > 0) {
				return value ? moment.unix(value.seconds).format("DD MMM YYYY h:mm A") : '--'
			}
		} else if (key === "lostReason") {
			return CommonHelper.getNameByValue(_settings?.lostReasons, value, '--')
		} else if (key === "lostSubReason") {
			let _lostSubReasons = [];
			_settings.lostReasons && _settings.lostReasons.forEach(doc => {
				!_.isEmpty(doc.subList) && doc.subList.forEach(docsub => {
					_lostSubReasons.push({
						value: docsub.value,
						label: docsub.name
					});
				});
			});

			return CommonHelper.getLabelByValue(_lostSubReasons, value, '--')
		} else if (key === "transferHistory") {
			return CommonHelper.getLatestTransferHistoryHTML(value, dealersettings, false, null, '--')
		}
		else if (key === "financeAmount" || key === 'depositAmount' || key === 'grossAmount') {
			return value ? <NumberFormat value={value} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /> : <>{'--'}</>
		}
		else if (key === "financeRate") {
			return value ? <NumberFormat value={value} displayType={'text'} decimalScale={2} thousandSeparator={true} suffix={'%'} /> : <>{'--'}</>
		} else if (key === "isFinanceRequired" || key === "isFinanceRequestSent") {
			return value === true ? "Yes" : "No"
		} else { return (value && _.isString(value) ? value : '--') }

	}

	mapScoreTemplate = (template, contact, dealersettings) => {
		let content = template;
		let client = dealersettings ? dealersettings.client : '';
		if (content && contact) {

			content = content.replace(/@DATE/g, moment().format('DD MMM YYYY'));

			if (contact && (contact.firstName || contact.lastName || contact.middleName))
				content = content.replace(/@FULL_NAME/g, CommonHelper.displayContactName([], contact));

			if (contact && contact.dob) content = content.replace(/@CUSTOMER_DOB/g, moment(contact.dob).format('DD MMM YYYY')); else content = content.replace(/@CUSTOMER_DOB/g, '<dob>');
			if (contact && contact.address) content = content.replace(/@CUSTOMER_ADDRESS/g, contact.address); else content = content.replace(/@CUSTOMER_ADDRESS/g, '<address>');
			if (contact && contact.documentID) content = content.replace(/@UNSUBSCRIBE_URL/g, `${window.location.origin}/contact/unsubscribe/${contact.documentID}`); else content = content.replace(/@UNSUBSCRIBE_URL/g, '#');

			if (client && client.name) content = content.replace(/@DEALER_NAME/g, client.name); else content = content.replace(/@DEALER_NAME/g, '<dealer name>');
			if (client && client.facebookURL) content = content.replace(/@FACEBOOK_URL/g, client.facebookURL); else content = content.replace(/@FACEBOOK_URL/g, '');
			if (client && client.xURL) content = content.replace(/@X_URL/g, client.xURL); else content = content.replace(/@X_URL/g, '');
			if (client && client.instagramURL) content = content.replace(/@INSTAGRAM_URL/g, client.instagramURL); else content = content.replace(/@INSTAGRAM_URL/g, '');
			if (client && client.youtubeURL) content = content.replace(/@YOUTUBE_URL/g, client.youtubeURL); else content = content.replace(/@YOUTUBE_URL/g, '');
			if (dealersettings && dealersettings.name) content = content.replace(/@USER_NAME/g, dealersettings.name); else content = content.replace(/@USER_NAME/g, '<salesperson name>');
			if (dealersettings && dealersettings.designation) content = content.replace(/@USER_DESIGNATION/g, dealersettings.designation); else content = content.replace(/@USER_DESIGNATION/g, '<designation>');
		}
		return content;
	}

	allOpenPipelines = (_pipelines, dealersettings) => {
		const enquiryAllStatus = Object.assign([], dealersettings?.client?.settings?.enquiryStatus);
		let _openDynStatus = enquiryAllStatus.filter(e => e.dynamic === true && !e.afterSold).map(r => { return r.value })
		let openStatus = !_.isEmpty(_openDynStatus) ? _.uniq(_.union([enquiryStatus.OPEN], _openDynStatus)) : [enquiryStatus.OPEN]
		return !_.isEmpty(_pipelines) ? _pipelines.filter(e => (_.isEmpty(e.status) || (!_.isEmpty(e.status) && e.status.some(s => openStatus.includes(s))))) : [];


	}
	//#endregion

	mapStartDate = (minutes, dealersettings, slotDate) => {
		var now = slotDate ? slotDate : window.firebase.firestore.Timestamp.now();
		if (dealersettings?.client?.clientSettings?.scheduleActWithInWHrs && dealersettings?.client?.workingHours) {
			// Check if the current date is within working hours
			let _isWithinWorkingHours = this.isWithinWorkingHours(moment.unix(now.seconds), moment.unix(now.seconds).add(minutes, 'minutes'), dealersettings?.client?.workingHours)

			// If the current date is not within working hours, adjust to start working time
			let _response = _isWithinWorkingHours ? now : window.firebase.firestore.Timestamp.fromDate(moment(`${moment().format('YYYY-MM-DD')} ${dealersettings?.client?.workingHours.split(';')[0]}`)._d)
			//console.log('mapNewStartDate', _isWithinWorkingHours, moment.unix(_response.seconds)._d, moment(`${moment().format('YYYY-MM-DD')} ${dealersettings?.client?.workingHours.split(';')[0]}`)._d)
			return _response;
		}
		return now;

	}

	mapEndDate = (startDate, endDate, dealersettings) => {
		if (dealersettings?.client?.clientSettings?.scheduleActWithInWHrs && dealersettings?.client?.workingHours) {
			// Check if the start and end dates fall within the working hours
			let _isWithinWorkingHours = this.isWithinWorkingHours(moment.unix(startDate.seconds), moment.unix(endDate.seconds), dealersettings?.client?.workingHours)

			// If the dates are not within the working hours, adjust the end date
			let _response = _isWithinWorkingHours ? endDate : window.firebase.firestore.Timestamp.fromDate(moment(`${moment.unix(endDate.seconds).format('YYYY-MM-DD')} ${dealersettings?.client?.workingHours.split(';')[1]}`)._d)
			//console.log('mapEndDate', _isWithinWorkingHours, moment.unix(_response.seconds)._d, moment(`${moment().format('YYYY-MM-DD')} ${dealersettings?.client?.workingHours.split(';')[1]}`)._d)
			return _response;
		}
		return endDate;
	}

	isWithinWorkingHours = (startDate, endDate, workingHours) => {
		// Define start and end time for working hours
		const startOfWorkingHours = moment(`${moment(startDate).format('YYYY-MM-DD')} ${workingHours.split(';')[0]}`)
		const endOfWorkingHours = moment(`${moment(startDate).format('YYYY-MM-DD')} ${workingHours.split(';')[1]}`)

		// Check if both start and end times fall within the working hours
		const isStartValid = startDate.isBetween(startOfWorkingHours, endOfWorkingHours, null, '[]');
		const isEndValid = endDate.isBetween(startOfWorkingHours, endOfWorkingHours, null, '[]');

		return isStartValid && isEndValid;
	};

	updateActivities = async (enquiryID, dealersettings) => {
		const markActDoneWhenLeadOnLost = dealersettings?.client?.clientSettings?.markActDoneWhenLeadOnLost ? true : false;
		if (!_.isEmpty(enquiryID) && markActDoneWhenLeadOnLost) {
			try {
				const batch = firestoreDB(dealersettings).firestore().batch();
				await firestoreDB(dealersettings).firestore().collection('activities')
					.where('enquiryID', '==', enquiryID)
					.where('isDone', '==', false)
					.where('isDeleted', '==', false)
					.get().then(response => {
						response.docs.forEach((doc) => {
							batch.update(doc.ref, {
								isDone: true,
								modifiedBy: localStorage.uid,
								modifiedDate: window.firebase.firestore.Timestamp.now(),
								modifiedFrom: 'web'
							});
						});
					});
				batch.commit();
			} catch (error) {
				console.log('updateActivities', error);

			}
		}

	}

}

export default new EnquiryHelper;