
/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import _ from 'lodash';
//import moment from 'moment';
import moment from 'moment-timezone'
import Swal from 'sweetalert2';
import 'rc-time-picker/assets/index.css';
//import Swal from 'sweetalert2'
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { PopUpModal, InputText, } from '../../../components';
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import SearchContainer from '../../contacts/searchContainer';
import OwnedEnquiryInfo from '../../contacts/owned/ownedEnquiryInfo';
import AddContact from '../../contacts/add';
import AddTestDrive from '../../testdrive/add';
import TestDriveInfo from '../../testdrive/testDriveInfo';
import InboundTestdrives from './inbound'
import InboundTestdriveInfo from '../inboundInfo'
/** VIEW-MODELS */
import { enqUnActionStatus, enquiryStatus } from '../../../services/enum'
import { objContact, objOwnedEnquiryVehicle } from "../../contacts/viewModel";
import { objActivitylog, objEnquiry } from "../../pipeline/viewModel";
import { objTestDrive } from '../../testdrive/viewModel';
import { stockBasicVM } from '../../stock/viewModel';
import { firestoreDB } from "../../../services/helper";

let titles = [];

export const originFrom = {
    CONTACT: 'newContact',
    ENQUIRY: 'newEnquiry',
    TESTDRIVE: 'newTestDrive'

}
export default class NewTestDrive extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.mandatoryFieldsForTestDrive = [];
        this.unsubscribeInboundTD = null;
        this.state = {
            fields: Object.assign({}),
            selectedContact: Object.assign({}),
            selectedEnquiry: Object.assign({}),
            selectedInbound: Object.assign({}),
            newEnquiry: Object.assign({}),
            allClientUsers: Object.assign([]),
            testdrives: Object.assign([]),
            enquiries: Object.assign([]),
            oldFields: Object.assign({}),
            inboundTestdrives: this.props.tdInboundList || Object.assign([]),
            searchText: '',
            showContacts: false,
            showInbounds: false,
            contactModal:
            {
                show: false,
                showSearch: false,
                title: '',
                clsActive: '',
                mandatoryFields: null,
                originPath: null,
                engageNewContact: null
            },
            testDriveModal:
            {
                show: false,
                testDriveID: '',
                title: '',
                clsActive: '',
                enquiry: null,
                originPath: null,
            }
        }

    }

    //#region PAGE LOAD EVENTS

    loadNewTestDrive = async (props) => {
        const { docID, show, dealersettings, clientUsers, d365testdriveEnabled } = props;

        const fields = Object.assign({});

        if (show === true) {

            if (dealersettings && dealersettings.client && dealersettings.client.settings) {
                const setttings = dealersettings.client.settings;

                if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.testdriveContact))
                    this.mandatoryFieldsForTestDrive = setttings.mandatoryFields.testdriveContact.split(',');

            }

            if (clientUsers) {
                const _clientUsers = [];
                clientUsers.filter(e => !e.isDeleted).forEach((rec) => {
                    _clientUsers.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    });
                });
                this.setState({
                    allClientUsers: _clientUsers
                });
            }
            if (this.state.inboundTestdrives.length > 0) {
                this.handleInboundShow();
            } else if (d365testdriveEnabled) {
                this.unsubscribeInboundTD = firestoreDB(dealersettings).firestore().collection(`inboundTestdrive`)
                    .where('clientID', '==', dealersettings.client.id)
                    .where('status', '==', 0)
                    .where('isDeleted', '==', false)
                    .onSnapshot(this.onInboundsCollectionUpdate);
            }
        }

    }

    onInboundsCollectionUpdate = (querySnapshot) => {
        let list = [];
        querySnapshot.forEach((_data) => {
            const _inbound = Object.assign({}, _data.data());
            _inbound.documentID = _data.id;
            list.push(_inbound);
        });
        list = _.orderBy(list, ['addedDate'], ['desc'])
        this.setState({ inboundTestdrives: list });
    }

    componentDidMount() {
        this.loadNewTestDrive(this.props);
    }

    componentWillUnmount() {
        this.unsubscribeEnquiriesListTD && this.unsubscribeEnquiriesListTD();
        this.unsubscribeTestDrivesList && this.unsubscribeTestDrivesList();
        this.unsubscribeInboundTD && this.unsubscribeInboundTD();
    }
    //#endregion

    //#region INBOUND CHANGES
    handleInboundShow = () => {
        this.setState({ showInbounds: true });
    }

    handleInboundClose = (_inbound) => {
        if (!_.isEmpty(_inbound)) {

            this.unsubscribeEnquiriesListTD && this.unsubscribeEnquiriesListTD();
            this.unsubscribeTestDrivesList && this.unsubscribeTestDrivesList();
            this.setState(
                {
                    showContacts: false,
                    selectedContact: {},
                    selectedEnquiry: {},
                    selectedInbound: _inbound,
                    newEnquiry: {},
                    testdrives: [],
                    enquiries: []
                }, () => { this.handleContactOpen('addContact', [], originFrom.CONTACT, _inbound.contact); }
            );


        }
        this.setState({ showInbounds: false });
    }

    mergeInboundData = (_enquiry, isNewEnquiry) => {
        const { selectedInbound } = this.state;
        if (!_.isEmpty(selectedInbound)) {
            if (selectedInbound.enquiryType && isNewEnquiry) _enquiry.enquiryType = selectedInbound.enquiryType;

            if (isNewEnquiry) {
                _enquiry.isConverted = true;
                _enquiry.unactionedNotify = enqUnActionStatus.PENDING;
                _enquiry.convertedBy = localStorage.uid;
                _enquiry.convertedDate = window.firebase.firestore.Timestamp.now();
            }

            _enquiry.tags = window.firebase.firestore.FieldValue.arrayUnion(
                {
                    type: (selectedInbound.systemType || ''),
                    refID: (selectedInbound.refID || ''),
                    subType: (selectedInbound.subType || '')
                }
            )
        }

        return _enquiry;
    }
    //#endregion

    //#region CONTACT CHANGES

    handleContactOpen = (title, _manFields, _originPath, _engage) => {
        const { dealersettings, d365testdriveEnabled } = this.props;
        if (!_.isEmpty(_engage) && d365testdriveEnabled) {
            _engage = Object.assign({}, _engage);
            const d365testdrive = Object.assign({}, !_.isEmpty(dealersettings?.client?.integrations) ?
                dealersettings.client.integrations.filter(e => e.type === "d365testdrive")[0] : {});

            if (!_.isEmpty(d365testdrive) && d365testdrive?.settings?.customerConsent)
                _engage.terms = {
                    addedBy: localStorage.uid,
                    addedDate: window.firebase.firestore.Timestamp.now(),
                    verbalConsent: false,
                    signature: '',
                    termsAndConditions: !_.isEmpty(dealersettings?.client?.digitalConsent) ? dealersettings.client.digitalConsent : (!_.isEmpty(dealersettings?.client?.customerConsent) ? dealersettings.client.customerConsent : ''),
                    consentType: 'Digital'
                };
        }
        setTimeout(() => {
            this.setState({
                contactModal: {
                    show: true,
                    showSearch: false,
                    title: title,
                    clsActive: 'overlay-modal active',
                    mandatoryFields: !_.isEmpty(_manFields) ? _manFields : [],
                    originPath: _originPath ? _originPath : null,
                    engageNewContact: _engage
                },
            });
        }, 500);

    }

    handleContactClose = async (ID, objData) => {
        const { originPath } = this.state.contactModal;
        //   console.log('handleContactClose', originPath, ID, objData);
        const { dealersettings, groupUsers, clientUsers } = this.props;
        const accessToCreateLeadByContactOwner = (dealersettings?.rolePermissions?.permissions?.accessToCreateLeadByContactOwner) ? true : false;
        let _set_state = {
            contactModal:
            {
                show: false,
                showSearch: false,
                title: '',
                clsActive: '',
                mandatoryFields: null,
                originPath: null,
                engageNewContact: null

            },
            searchText: ''
        }
        if (ID && !_.isEmpty(objData)) {

            if (accessToCreateLeadByContactOwner && objData.owner !== localStorage.uid) {
                let _txt = CommonHelper.showLocale(this.props, `Cannot select another owners contact`)
                if (objData.owner) _txt = `${CommonHelper.showLocale(this.props, 'The contact owner is')} ${CommonHelper.getUserNamebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, objData.owner)}, ${CommonHelper.showLocale(this.props, "Cannot select another owners contact")}`
                Swal.fire(CommonHelper.showLocale(this.props, 'Access denied.'), _txt, 'info');
                return false;
            }

            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }


            this.setState({ selectedContact: objData });
            const enqDoc = await firestoreDB(dealersettings).firestore().collection('enquiries')
                .where('contact.documentID', '==', ID)
                .where('status', '==', enquiryStatus.OPEN)
                .where('isDeleted', '==', false).limit(1).get();
            if (enqDoc.size > 0) {
                this.setState(
                    {
                        selectedEnquiry: {},
                        newEnquiry: {},
                        testdrives: [],
                        enquiries: []
                    }
                );

                this.unsubscribeEnquiriesListTD && this.unsubscribeEnquiriesListTD();
                this.unsubscribeTestDrivesList && this.unsubscribeTestDrivesList();

                this.unsubscribeEnquiriesListTD = firestoreDB(this.props.dealersettings).firestore().collection('enquiries')
                    .where('contact.documentID', '==', ID)
                    .where('status', '==', enquiryStatus.OPEN)
                    .where('isDeleted', '==', false)
                    .onSnapshot(this.onEnquiryCollectionUpdate);
            }
            else {
                this.isValidForTestDrive(originPath);
            }


        }
        else if (ID) {

            const refData = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails').doc(ID);
            refData.get().then(async (doc) => {
                if (doc.exists) {

                    const objData = Object.assign({}, doc.data());

                    if (accessToCreateLeadByContactOwner && objData.owner !== localStorage.uid) {
                        let _txt = CommonHelper.showLocale(this.props, `Cannot select another owners contact`)
                        if (objData.owner) _txt = `${CommonHelper.showLocale(this.props, 'The contact owner is')} ${CommonHelper.getUserNamebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, objData.owner)}, ${CommonHelper.showLocale(this.props, "Cannot select another owners contact")}`
                        Swal.fire(CommonHelper.showLocale(this.props, 'Access denied.'), _txt, 'info');
                        return false;
                    }

                    //Remove empty value from object
                    for (var propName in objData) {
                        if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                            delete objData[propName];
                        }
                    }
                    const customer = Object.assign({}, objContact);
                    for (let [key, value] of Object.entries(objData)) {
                        customer[key] = value;
                    }

                    customer.documentID = doc.id;

                    this.setState({ selectedContact: customer });

                    const enqDoc = await firestoreDB(dealersettings).firestore().collection('enquiries')
                        .where('contact.documentID', '==', ID)
                        .where('status', '==', enquiryStatus.OPEN)
                        .where('isDeleted', '==', false).limit(1).get();
                    if (enqDoc.size > 0) {
                        this.setState(
                            {
                                selectedEnquiry: {},
                                newEnquiry: {},
                                testdrives: [],
                                enquiries: []
                            }
                        );

                        this.unsubscribeEnquiriesListTD && this.unsubscribeEnquiriesListTD();
                        this.unsubscribeTestDrivesList && this.unsubscribeTestDrivesList();

                        this.unsubscribeEnquiriesListTD = firestoreDB(this.props.dealersettings).firestore().collection('enquiries')
                            .where('contact.documentID', '==', ID)
                            .where('status', '==', enquiryStatus.OPEN)
                            .where('isDeleted', '==', false)
                            .onSnapshot(this.onEnquiryCollectionUpdate);
                    }
                    else {
                        this.isValidForTestDrive(originPath);
                    }

                } else {
                    console.error("No such document!");
                }
            });
        } else if (!_.isEmpty(this.state.selectedInbound)) {
            _set_state.selectedInbound = {}
        }

        this.setState(_set_state);

    }

    handleRemoveCustomer = (_engage) => {

        this.unsubscribeEnquiriesListTD && this.unsubscribeEnquiriesListTD();
        this.unsubscribeTestDrivesList && this.unsubscribeTestDrivesList();
        this.setState(
            {
                showContacts: false,
                selectedContact: {},
                selectedEnquiry: {},
                selectedInbound: {},
                newEnquiry: {},
                testdrives: [],
                enquiries: []
            }
        );
    }
    //#endregion

    //#region ENQUIRY CHANGES
    handleOnEnquirySelect = (ID, objData) => {
        if (ID && !_.isEmpty(objData)) {

            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }
            const fields = Object.assign({}, this.state.fields);
            fields.enquiry = Object.assign({}, objData);
            fields.enquiryID = ID;
            this.setState(
                {
                    fields: fields,
                    selectedEnquiry: fields.enquiry,
                    newEnquiry: {},
                    testdrives: []
                }
            );

            this.unsubscribeTestDrivesList = firestoreDB(this.props.dealersettings).firestore().collection('testdrives')
                .where('enquiryID', '==', ID)
                .where('isDeleted', '==', false)
                .onSnapshot(this.onTestDrivesCollectionUpdate);

        }
    }

    onTestDrivesCollectionUpdate = (querySnapshot) => {
        let testdrives = [];
        querySnapshot.forEach((_testdrive) => {
            const testDrive = Object.assign({}, objTestDrive);
            const objTestDriveData = Object.assign({}, _testdrive.data());
            for (let [key, value] of Object.entries(objTestDriveData)) {
                testDrive[key] = value;
            }
            testDrive.documentID = _testdrive.id;

            if (testDrive.stockID && !testDrive.stock) {
                const refTDStockData = window.firebase.firestore().collection('stock').doc(testDrive.stockID);
                testdrives.push(
                    refTDStockData.get().then((_testStock) => {
                        if (_testStock.exists) {
                            const _stock = Object.assign({}, stockBasicVM);
                            const objTDStockData = Object.assign({}, _testStock.data());
                            for (let [key, value] of Object.entries(objTDStockData)) {
                                if (_stock.hasOwnProperty(key)) _stock[key] = value;
                            }
                            _stock.documentID = testDrive.stockID;
                            testDrive.stock = _stock;
                            return testDrive
                        }
                        else {
                            console.error("No such document!");
                        }
                    })
                )
            }
            else {
                testdrives.push(testDrive);
            }
        });

        Promise.all(testdrives).then(_testdrives => {

            if (!_.isEmpty(_testdrives)) {
                _testdrives = _testdrives.sort(function (x, y) {
                    return (x.addedDate.seconds > y.addedDate.seconds ? -1 : 1);
                })
            }

            this.setState({ testdrives: _testdrives })
        })
    }

    onEnquiryCollectionUpdate = querySnapshot => {
        const ownedEnquiries = [];

        querySnapshot.forEach((_enquiry) => {
            const enquiry = Object.assign({}, objEnquiry);
            const ownedEnquiry = Object.assign({}, objOwnedEnquiryVehicle);
            const objEnquiryData = Object.assign({}, _enquiry.data());
            for (let [key, value] of Object.entries(objEnquiryData)) {
                enquiry[key] = value;
            }
            enquiry.documentID = _enquiry.id;
            let dealersettings = this.props.dealersettings;
            if (enquiry.clientID && (enquiry.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
                ownedEnquiry.dealerName = CommonHelper.getOtherDealerName(dealersettings, enquiry.clientID)
            }

            ownedEnquiry.enquiryID = enquiry.documentID;
            ownedEnquiry.displayID = enquiry.displayID ? enquiry.displayID : '';
            ownedEnquiry.status = enquiry.status;
            ownedEnquiry.owner = enquiry.owner;
            ownedEnquiry.enquiry = enquiry;
            ownedEnquiry.addedDate = enquiry.addedDate;
            if (!_.isEmpty(enquiry.requirement) && (!_.isEmpty(enquiry.requirement.make) || !_.isEmpty(enquiry.requirement.stock))) {

                if (!_.isEmpty(enquiry.requirement.stock)) {
                    ownedEnquiry.make = enquiry.requirement.stock.make ? enquiry.requirement.stock.make : '';
                    ownedEnquiry.model = enquiry.requirement.stock.model ? enquiry.requirement.stock.model : '';
                    ownedEnquiry.type = enquiry.requirement.stock.type ? enquiry.requirement.stock.type : '';
                    ownedEnquiry.year = enquiry.requirement.stock.year ? enquiry.requirement.stock.year : '';
                    ownedEnquiry.regNo = enquiry.requirement.stock.regNo ? enquiry.requirement.stock.regNo : '';
                    ownedEnquiry.vinNo = enquiry.requirement.stock.vinNo ? enquiry.requirement.stock.vinNo : '';
                    ownedEnquiry.extColor = enquiry.requirement.stock.extColor ? enquiry.requirement.stock.extColor : '';
                    ownedEnquiry.saleType = enquiry.requirement.saleType ? CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                        enquiry.requirement.saleType, '') : '';
                }
                else {
                    ownedEnquiry.make = enquiry.requirement.make ? enquiry.requirement.make : '';
                    ownedEnquiry.model = enquiry.requirement.model ? enquiry.requirement.model : '';
                    ownedEnquiry.type = enquiry.requirement.type ? enquiry.requirement.type : '';
                    ownedEnquiry.year = enquiry.requirement.year ? enquiry.requirement.year : '';
                    ownedEnquiry.regNo = enquiry.requirement.regNo ? enquiry.requirement.regNo : '';
                    ownedEnquiry.vinNo = enquiry.requirement.vinNo ? enquiry.requirement.vinNo : '';
                    ownedEnquiry.extColor = enquiry.requirement.extColor ? enquiry.requirement.extColor : '';
                    ownedEnquiry.saleType = enquiry.requirement.saleType ? CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                        enquiry.requirement.saleType, '') : '';
                }
            }
            ownedEnquiries.push(ownedEnquiry)

        });

        this.setState({
            enquiries: _.orderBy(ownedEnquiries, ["addedDate"], ["desc"])
        });
    }

    //#endregion

    //#region SAVE & SUBMIT 

    loadNewEnquiry = () => {
        const { selectedContact, selectedInbound } = this.state;
        const { dealersettings } = this.props;
        if (dealersettings &&
            dealersettings.client &&
            dealersettings.client.settings) {

            const setttings = dealersettings.client.settings;


            let state = Object.assign({}, objEnquiry);

            if (_.isEmpty(state.addedBy)) {
                state.addedBy = dealersettings ? dealersettings.id : '';
                state.addedDate = window.firebase.firestore.Timestamp.now();
                state.owner = dealersettings ? dealersettings.id : '';
            }
            state.modifiedBy = dealersettings ? dealersettings.id : '';
            state.modifiedDate = window.firebase.firestore.Timestamp.now();
            state = CommonHelper.setClientOrGroupOrRegionOrOemID(state, dealersettings, null, null, true)
            // state.clientID = dealersettings ? dealersettings.client.id : '';
            // state.groupID = (dealersettings && dealersettings.client.group ? dealersettings.client.group : null);
            // state.regionID = (dealersettings && dealersettings.client.region ? dealersettings.client.region : null);
            // state.oemID = (dealersettings ? dealersettings.client.settingsID : null);

            let _pipeline = Object.assign({}, setttings.pipelines.filter(e => e.value !== 'LeadsBucket')[0])
            if (setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaultPipeline)) {
                _pipeline = setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaultPipeline);
            }
            let defaultstages = _pipeline.stages;
            let defaultstatus = _pipeline.status;
            let stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')

            state.pipeline = _pipeline.value;
            state.stage = stages[0];
            state.status = !_.isEmpty(defaultstatus) ? defaultstatus[0] : 'open';
            state.statusHistory = Object.assign([], [{ date: window.firebase.firestore.Timestamp.now(), status: state.status, user: localStorage.uid }]);
            state.stageDate = window.firebase.firestore.Timestamp.now();
            state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
            state.isDeleted = false;
            state.isNewEnquiry = true;
            state.isTestDrive = true;

            if (selectedInbound?.enquiryType) state.enquiryType = selectedInbound.enquiryType;

            if (!_.isEmpty(selectedContact)) {
                const contact = Object.assign({}, objContact);
                const objcontactData = Object.assign({}, selectedContact);
                for (let [key, value] of Object.entries(objcontactData)) {
                    contact[key] = value;
                }
                state.contact = contact;
                state.isContact = true;
                state.contactID = contact.documentID;
                state.isNewEnquiry = false;
            }

            if (_.isEmpty(state.requirement) && _.isArray(dealersettings.accessSalesType) && dealersettings.accessSalesType.length > 0) {
                state.requirement = {
                    saleType: dealersettings.accessSalesType[0]
                }
            }

            const objData = Object.assign({}, state);
            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }

            objData.documentID = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc().id;
            this.setState({ newEnquiry: objData });
            return objData;
        }
        return null;
    }

    //#endregion

    //#region test

    isValidForTestDrive = (_originPath) => {
        const { selectedContact } = this.state;
        if (!_.isEmpty(selectedContact)) {
            const ObjCustomer = Object.assign({}, selectedContact);
            if (!_.isEmpty(this.mandatoryFieldsForTestDrive)) {
                var msg = '';
                this.mandatoryFieldsForTestDrive.some((el, index) => {
                    if (el.trim() === 'licenseFrontURL') {
                        if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
                            msg = 'Please upload license front image';
                            return true;
                        }
                    } else if (el.trim() === 'licenseBackURL') {
                        if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
                            msg = 'Please upload license back image';
                            return true;
                        }
                    } else if (el.trim() === 'phoneORemail') {
                        if (!ObjCustomer['phone'] && !ObjCustomer['email']) {
                            msg = 'Please enter Phone or Email';
                            return true;
                        }
                    } else if (el.trim() === 'phoneORemailORlicenseNo') {
                        if (!ObjCustomer['phone'] && !ObjCustomer['email'] && !ObjCustomer['licenseNo']) {
                            msg = 'Please enter Phone or Email or License No.';
                            return true;
                        }
                    } else if (!ObjCustomer[el] && !_.isBoolean(ObjCustomer[el])) {
                        msg = `${CommonHelper.showLocale(this.props, 'Please enter')} ${CommonHelper.showLocale(this.props, el)}`;
                        return true;
                    }
                });

                if (msg) {
                    Swal.fire({
                        title: CommonHelper.showLocale(this.props, msg),
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                        cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                    }).then(result => {
                        if (result.value) {
                            this.handleContactOpen('editContact', this.mandatoryFieldsForTestDrive, _originPath);
                        }
                    });

                    return false;
                } else {
                    this.handleOpenTestDriveModal(_originPath);
                }
            } else {
                this.handleOpenTestDriveModal(_originPath);
            }
            return true;
        } else {
            this.handleContactOpen('addContact', this.mandatoryFieldsForTestDrive, _originPath);
            return false;
        }
    };

    handleOpenTestDriveModal = (_originPath) => {
        const { selectedContact, selectedEnquiry, newEnquiry } = this.state;
        if (!_.isEmpty(selectedEnquiry) && _originPath === originFrom.TESTDRIVE) {
            this.handleAddTestDrive(selectedEnquiry, _originPath, 1);
        }
        else if (!_.isEmpty(newEnquiry)) {
            this.handleAddTestDrive(newEnquiry, _originPath, 2);
        }
        else {
            let _newEnq = this.loadNewEnquiry();
            if (!_.isEmpty(_newEnq))
                this.handleAddTestDrive(_newEnq, (_originPath === originFrom.CONTACT ? originFrom.ENQUIRY : _originPath), 3);
        }
    }

    handleAddTestDrive = (_enquiry, _originPath, flow) => {

        //   console.log('handleAddTestDrive', _originPath, flow, _enquiry);
        const { selectedContact, selectedEnquiry, newEnquiry } = this.state;
        if (selectedContact['licenseExpiry'] && (moment()._d > moment(selectedContact['licenseExpiry'])._d)) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'License is expired.'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Update Now'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
                if (result.value) {
                    this.handleContactOpen('editContact', this.mandatoryFieldsForTestDrive, _originPath);
                }
            });

            return false;
        }
        else {
            this.setState({
                testDriveModal: {
                    show: true,
                    testDriveID: '',
                    title: 'testDrive',
                    clsActive: 'overlay-modal active',
                    enquiry: _enquiry,
                    originPath: _originPath,
                },
            });
        }

    }

    handleTestDriveClose = async (testDriveID) => {
        const { originPath } = this.state.testDriveModal;
        const { newEnquiry, selectedEnquiry, selectedInbound, testDriveModal } = this.state;
        console.log('handleTestDriveClose', originPath, testDriveID, newEnquiry)
        if (testDriveID) {

            if (originPath && !_.isEmpty(newEnquiry) && (originPath === originFrom.CONTACT || originPath === originFrom.ENQUIRY)) {
                let _objEnquiry = Object.assign({}, newEnquiry);
                //  console.log('newEnquiry', _objEnquiry)
                _objEnquiry = this.mergeInboundData(_objEnquiry, true)

                const refReqData = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(_objEnquiry.documentID);
                refReqData.set(_objEnquiry, { merge: true }).then(async (docRef) => {
                    console.log('refReqData', _objEnquiry)
                    if (!testDriveModal.testDriveID) { // for new testdrive
                        await this.updateInBound(_objEnquiry.documentID, testDriveID);

                        //SAVE NOTES 
                        this.saveLog(selectedInbound, _objEnquiry.documentID, _objEnquiry);
                    }

                    if (_objEnquiry.contactID) {
                        const updateRepeatCustomer = window.firebase.functions().httpsCallable(`contact-updateRepeatCustomer`);
                        updateRepeatCustomer({ 'contactID': _objEnquiry.contactID, 'projectId': newEnquiry?.projectId ? newEnquiry.projectId : null })
                    }

                    if (originPath === originFrom.CONTACT) {
                        this.props.history.push("/enquiry/details/" + _objEnquiry.documentID);
                        this.props.handleClose();
                    }

                    if (originPath === originFrom.ENQUIRY) {
                        this.setState(
                            {
                                //selectedEnquiry: _objEnquiry,
                                newEnquiry: {},
                                testdrives: []
                            }
                        );
                        this.props.handleClose(_objEnquiry.documentID, true);
                        //27-05-22 sameeer
                        // this.unsubscribeTestDrivesList = window.firebase.firestore().collection('testdrives')
                        //     .where('enquiryID', '==', _objEnquiry.documentID)
                        //     .where('isDeleted', '==', false)
                        //     .onSnapshot(this.onTestDrivesCollectionUpdate);
                    }

                }).catch((error) => {
                    console.error("Error adding new test drive: ", error);
                    Swal.fire(CommonHelper.showLocale(this.props, 'Something went wrong'), '', 'error');
                    const documentID = window.firebase.firestore().collection('errorLogs').doc().id;
                    const ref = window.firebase.firestore().collection('errorLogs').doc(documentID);
                    ref.set({
                        addedDate: window.firebase.firestore.Timestamp.now(),
                        addedBy: localStorage.uid,
                        clientID: this.props.dealersettings.client.id,
                        oemID: this.props.dealersettings.client.settingsID,
                        error: error.message ? error.message : '',
                        description: 'Add New Test Drive:' + (error.message ? error.message : ''),
                        currentPath: window.location.pathname,
                    }).then(() => {
                        console.log('Error has been uploaded')
                    }).catch((error) => {
                        console.error("Error adding log: ", error);
                    });
                    // firestoreDB(this.props.dealersettings).firestore().doc(`testdrives/${testDriveID}`).delete().then(() => {
                    // }).catch((error) => {
                    //     console.error("Error removing Filter: ", error);
                    // });
                });
            }
            else if (!_.isEmpty(selectedEnquiry)) {
                let _objEnquiry = this.mergeInboundData({})
                if (!selectedEnquiry.isTestDrive) _objEnquiry.isTestDrive = true;

                if (!_.isEmpty(_objEnquiry)) {
                    firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(selectedEnquiry.documentID).set(_objEnquiry, { merge: true })
                }
                if (!testDriveModal.testDriveID) { // for new testdrive
                    await this.updateInBound(selectedEnquiry.documentID, testDriveID);
                    //SAVE NOTES 
                    this.saveLog(selectedInbound, selectedEnquiry.documentID, selectedEnquiry);
                }
                this.props.handleClose(selectedEnquiry.documentID, false);
            }

        }

        this.setState({
            testDriveModal:
            {
                show: false,
                testDriveID: '',
                title: '',
                clsActive: '',
                enquiry: null,
                originPath: null,
            },
            newEnquiry: {},
        });
    }

    handleEditTestDrive = (testDriveID, status) => {
        this.setState({
            testDriveModal:
            {
                show: true,
                testDriveID: testDriveID,
                title: 'testDrive',
                clsActive: 'overlay-modal active'
            }
        });
    };

    updateInBound = async (enquiryID, testdriveID) => {
        try {
            const { selectedInbound } = this.state;
            if (selectedInbound?.documentID) {
                const { dealersettings } = this.props;
                const userID = dealersettings ? dealersettings.id : '';

                const batch = firestoreDB(dealersettings).firestore().batch();
                let _objReq = {
                    status: 1,
                    enquiryID: (enquiryID || null),
                    testdriveID: (testdriveID || null),
                    oemStatus: null,
                    modifiedBy: userID,
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedFrom: 'web'
                }

                _objReq.convertedBy = localStorage.uid;
                _objReq.convertedDate = window.firebase.firestore.Timestamp.now();
                batch.set(firestoreDB(dealersettings).firestore().doc(`inboundTestdrive/${selectedInbound?.documentID}`), _objReq, { merge: true });

                batch.commit().catch((error) => {
                    console.error("Error updating document: ", error);
                });
            }


        } catch (error) {
            console.error(error, 'updateInBound');
        }
    }

    getTDnotes = (_requirement) => {
        let notes = '';
        if (!_.isEmpty(_requirement)) {
            const _viewableKeys = Object.keys(_requirement)
            const order = ["make", "model", "startDate", "endDate", "notes"]
            _viewableKeys
                .sort(function (x, y) {
                    return (order.indexOf(x) < order.indexOf(y) ? -1 : 1);
                })

            _viewableKeys.forEach((key) => {
                if (_.isString(_requirement[key]) || _.isNumber(_requirement[key]))
                    notes = notes.concat([key] + ': ' + _requirement[key] + '\n')
                else if (!_.isEmpty(_requirement[key]) && (_requirement[key]?.seconds))
                    notes = notes.concat([key] + ': ' + moment.unix(_requirement[key].seconds).format('YYYY-MM-DD HH:mm') + '\n')
            });
            // for (let [key, value] of Object.entries(_requirement)) {
            //     if (!_.isEmpty(_requirement[key]) && (_.isString(value) || _.isNumber(value)))
            //         notes = notes.concat([key] + ': ' + value + '\n')
            //     else if (!_.isEmpty(_requirement[key]) && (value?.seconds))
            //         notes = notes.concat([key] + ': ' + moment.unix(value.seconds).format('YYYY-MM-DD HH:mm') + '\n')
            // }
        }

        return notes;
    }

    saveLog = (viewmodel, enquiryID, __enquiryData) => {
        if (!_.isEmpty(viewmodel.testdrive)) {
            const { dealersettings } = this.props;
            const batch = firestoreDB(dealersettings).firestore().batch();

            let newlogVM = Object.assign({}, objActivitylog);
            newlogVM.type = 'log';
            newlogVM.subType = 'note';
            newlogVM.isDone = true;
            newlogVM.addedByName = 'System';
            newlogVM.addedBy = 'System';
            newlogVM.modifiedBy = 'System';
            newlogVM.addedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
            newlogVM.modifiedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
            newlogVM.documentID = firestoreDB(dealersettings).firestore().collection('activities').doc().id;
            newlogVM.startDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
            newlogVM.notes = this.getTDnotes(viewmodel.testdrive);
            newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];

            newlogVM.enquiryID = enquiryID;
            newlogVM['enquiry'] = CommonHelper.getMinifiedData(__enquiryData, 'enquiry');
            newlogVM['contact'] = CommonHelper.getMinifiedData(__enquiryData?.contact, 'contact');
            newlogVM.contactID = newlogVM?.contact?.documentID ? newlogVM.contact.documentID : null;
            newlogVM = CommonHelper.setLevelIDs(newlogVM, dealersettings, null, (!_.isEmpty(__enquiryData) ? __enquiryData.clientID : null));
            Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
            newlogVM.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

            batch.set(firestoreDB(dealersettings).firestore().doc(`activities/${newlogVM.documentID}`), newlogVM, { merge: true });
            batch.commit().catch((error) => {
                console.error("Error updating document: ", error);
            });
        }

    }
    //#endregion


    handleSearchText = (e) => {
        this.setState({ searchText: e.target.value }, () => {
            let val = this.state.searchText.trim();
            if (val.length > 2 && !this.state.showContacts) {
                this.setState({ showContacts: true })
            } else if (val.length < 3) {
                this.setState({ showContacts: false })
            }
        });
    }

    render() {
        const { show, dealersettings, clientUsers, title, isFromLayout, groupUsers, d365testdriveEnabled } = this.props;
        const { searchText, showContacts, selectedEnquiry, selectedContact, selectedInbound,
            showInbounds,
            contactModal, testDriveModal, inboundTestdrives } = this.state;
        let addNewLeadTxt = dealersettings?.isPorsche === true ? 'addInboundEnquriy' : 'addNewLead'
        return (
            <>
                <Modal
                    id="add-calllogs"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName={`modal-dialog ${searchText ? 'modal-add-testdrive-fix' : 'modal-dialog-scrollable'} modal-dialog-centered modal-add-testdrive-new`}>

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" >
                                <Translate text={'addTestDrive'} />

                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className={`container-fluid`}>

                            <div className="row h-100">
                                {
                                    !_.isEmpty(selectedInbound) && (<InboundTestdriveInfo inbound={selectedInbound} dealersettings={dealersettings} />)
                                }
                                <div className=" pop-layout form-style">
                                    <div className="testdrive-link-left">
                                        <div className="testdrive-link-title">
                                            <div className="float-left">
                                                <div className="testdrive-link-name"> <Translate text={'contact'} /></div>
                                            </div>
                                            <div className="float-right">
                                                {
                                                    _.isEmpty(selectedContact) && (<button type="button" className="btn btn-common float-right"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.isValidForTestDrive(originFrom.CONTACT);
                                                        }}>
                                                        <i className="ico icon-add mr-2"></i>
                                                        <Translate text={'Add New Contact'} />
                                                    </button>)
                                                }
                                                {
                                                    _.isEmpty(selectedInbound) && d365testdriveEnabled && (

                                                        <button type="button" className="btn btn-common float-right mr-2"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.handleInboundShow();
                                                            }}>
                                                            <i className="ico icon-activity-testdrive mr-2"></i>
                                                            <Translate text={'Test Drive Request'} />
                                                            <span className="badge badge-pill badge-counter green-fill ml-1">
                                                                {inboundTestdrives.length}
                                                            </span>
                                                        </button>)
                                                }
                                            </div>

                                        </div>
                                        <div className="testdrive-link-body">

                                            {

                                                (!_.isEmpty(selectedContact))
                                                    ?
                                                    <>
                                                        <div className="form-group">
                                                            <div className="form-readonly-wrap">
                                                                <div className="input-readonly">
                                                                    <div className="input-readonly-close">
                                                                        <a href="#" onClick={(e) => { e.preventDefault(); this.handleRemoveCustomer(); }}><i className="ico icon-remove"></i> </a>
                                                                    </div>
                                                                    <div className="input-readonly-inner">
                                                                        <div className="calllogs-head">
                                                                            {CommonHelper.displayContactIcon(selectedContact, 'mr-1')}
                                                                            {CommonHelper.displayContact(titles, selectedContact)}

                                                                        </div>
                                                                        {
                                                                            (selectedContact.businessContact && !_.isEmpty(selectedContact.company))
                                                                                ?
                                                                                <div className="calllogs-head">
                                                                                    <i className={`ico icon-Contacts mr-1`}></i>
                                                                                    {CommonHelper.getFullName([], selectedContact, '')}
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {
                                                                            selectedContact.displayID ?
                                                                                (<div className="vehicle-item-info"><i className={`ico icon-enquiry mr-1`}></i>{selectedContact.displayID}</div>) :
                                                                                (<></>)
                                                                        }

                                                                        {
                                                                            (selectedContact.phone || selectedContact.email)
                                                                                ?
                                                                                <div className="calllogs-contact-sub">
                                                                                    <ul className="calllogs-contact-details">
                                                                                        {selectedContact.phone ? <li> <i className="ico icon-call"></i>{CommonHelper.phoneFormat(selectedContact.phoneCode, selectedContact.phone, dealersettings)}</li> : <></>}
                                                                                        {selectedContact.email ? <li> <i className="ico icon-email"></i>{selectedContact.email}</li> : <></>}
                                                                                    </ul>
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {
                                                                            selectedContact.clientID && (selectedContact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group ? (
                                                                                <div className="blue-color">{CommonHelper.getOtherDealerName(dealersettings, selectedContact.clientID)}</div>
                                                                            ) : (<></>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="form-group position-relative">
                                                            <label><Translate text={'Select Existing Contact'} /></label>
                                                            <div className="calllogs-search ">
                                                                <InputText
                                                                    value={searchText}
                                                                    onChange={this.handleSearchText}
                                                                    autoComplete="off"
                                                                    placeholder={'search contact'}
                                                                    className={`mb-2`}
                                                                />

                                                                <SearchContainer
                                                                    history={this.props.history}
                                                                    showSearchPanel={showContacts}
                                                                    dealersettings={dealersettings}
                                                                    clientUsers={clientUsers}
                                                                    groupUsers={groupUsers}
                                                                    searchText={searchText ? searchText : ''}
                                                                    handleClose={this.handleContactClose}//{() => this.setState({ showContacts: false, searchText: '' })}
                                                                    handleSearchClose={() => this.setState({ showContacts: false })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="no-data-testdrivepop h-100 justify-content-center align-items-center mt-5">
                                                            <div className="no-data-testdrivepop-icon"><img src={_images.nodata} alt="" width="50" /></div>
                                                            <div className="no-data-testdrivepop-wrap">
                                                                <div className="no-data-testdrivepop-head"><Translate text={'No contacts or enquiries selected'} /></div>
                                                                <div className="no-data-testdrivepop-content"><Translate text={'Please select an existing contact or create a new contact'} /></div>
                                                            </div>
                                                        </div>
                                                    </>
                                            }

                                            {
                                                !_.isEmpty(this.state.enquiries)
                                                    ?
                                                    <>
                                                        <div className="testdrive-link-subhead">
                                                            <div className="float-left">
                                                                <h3><Translate text={'Existing Enquiries'} /></h3>
                                                            </div>
                                                            <div className="float-right">
                                                                <button type="button" className="btn btn-common float-right"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.isValidForTestDrive(originFrom.ENQUIRY);
                                                                    }}>
                                                                    <i className="ico icon-add mr-2"></i>
                                                                    <Translate text={addNewLeadTxt} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {this.state.enquiries.map((_owned, index) => (
                                                            <div key={index}
                                                                className={`testdrive-link-vehicle-box mb-2 ${!_.isEmpty(selectedEnquiry) && selectedEnquiry.documentID === _owned.enquiryID ? 'active-inq' : ''}`}
                                                            >
                                                                <OwnedEnquiryInfo
                                                                    key={index}
                                                                    requirement={_owned}
                                                                    clientUsers={clientUsers}
                                                                    groupUsers={groupUsers}
                                                                    dealersettings={dealersettings}
                                                                    sidepanelOpen={this.handleOnEnquirySelect}
                                                                    history={this.props.history}
                                                                    noItemClass={true}
                                                                    hideMore={true}
                                                                    handleClose={this.props.handleClose}
                                                                >
                                                                </OwnedEnquiryInfo>
                                                            </div>
                                                        ))
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            !_.isEmpty(selectedContact)
                                                                ?
                                                                <>
                                                                    <div className="testdrive-link-subhead">
                                                                        <div className="float-left">
                                                                            <h3><Translate text={'Existing Enquiries'} /></h3>
                                                                        </div>
                                                                        <div className="float-right">
                                                                            <button type="button" className="btn btn-common float-right"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.isValidForTestDrive(originFrom.ENQUIRY);
                                                                                }}>
                                                                                <i className="ico icon-add mr-2"></i>
                                                                                <Translate text={addNewLeadTxt} />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="no-data-testdrivepop h-100 justify-content-center align-items-center mt-5">
                                                                        <div className="no-data-testdrivepop-icon"><img src={_images.nodata} alt="" width="50" /></div>
                                                                        <div className="no-data-testdrivepop-wrap">
                                                                            <div className="no-data-testdrivepop-head"><Translate text={'No enquiry found'} /></div>
                                                                            {/* <div className="no-data-testdrivepop-content">Please select a existing contact or create new contact</div> */}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                </>
                                                        }
                                                    </>
                                            }

                                        </div>
                                    </div>
                                    <div className="testdrive-link-right">
                                        <div className="testdrive-link-title">
                                            <div className="float-left">
                                                <div className="testdrive-link-name">{'Test Drive Info'} </div>
                                            </div>
                                            {
                                                !_.isEmpty(selectedEnquiry)
                                                    ?
                                                    <div className="float-right">
                                                        <button type="button" className="btn btn-primary float-right"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.isValidForTestDrive(originFrom.TESTDRIVE);
                                                            }}>
                                                            <i className="ico icon-add mr-2"></i>
                                                            <Translate text={'Add New Test Drive'} />
                                                        </button>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <div className="testdrive-link-body">
                                            {
                                                !_.isEmpty(this.state.testdrives)
                                                    ?
                                                    <>
                                                        {
                                                            this.state.testdrives.map((_testdrive, index) => (

                                                                <TestDriveInfo
                                                                    key={index}
                                                                    testdrive={_testdrive}
                                                                    stockData={_testdrive.stock}
                                                                    handleEditTestDrive={this.handleEditTestDrive}
                                                                    dealersettings={dealersettings}
                                                                >
                                                                </TestDriveInfo>

                                                            ))
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <div className="testdrive-link-no-datawrap">
                                                            <div className="no-data-testdrivepop h-100 justify-content-center align-items-center no-testdrive-topfix">
                                                                <div className="no-data-testdrivepop-icon"><i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-activity-testdrive')}></i></div>
                                                                <div className="no-data-testdrivepop-wrap">
                                                                    <div className="no-data-testdrivepop-head"><Translate text={'No test drive found'} /></div>
                                                                    {/* <div className="no-data-testdrivepop-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit</div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <PopUpModal show={contactModal.show}>
                    <AddContact
                        docID={!_.isEmpty(selectedContact) ? selectedContact.documentID : null}
                        show={contactModal.show}
                        clsActive={contactModal.clsActive}
                        handleClose={this.handleContactClose}
                        title={contactModal.title}
                        showSearch={contactModal.showSearch}
                        mandatoryFields={contactModal.mandatoryFields}
                        engageNewContact={contactModal?.engageNewContact}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        isFromLayout={isFromLayout}
                        isNewEnquiry={true}
                    >
                    </AddContact>
                </PopUpModal>

                <PopUpModal show={testDriveModal.show}>
                    <AddTestDrive
                        docID={testDriveModal.testDriveID}
                        show={testDriveModal.show}
                        clsActive={testDriveModal.clsActive}
                        handleClose={this.handleTestDriveClose}
                        title={testDriveModal.title}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        enquiryID={testDriveModal.enquiry ? testDriveModal.enquiry.documentID : null}
                        enquiry={testDriveModal.enquiry}
                        contact={selectedContact}
                        isFromLayout={isFromLayout}
                        prefillData={testDriveModal.testDriveID ? null : selectedInbound?.testdrive}
                        selectedInbound={testDriveModal.testDriveID ? null : selectedInbound}
                    //walkins={!_.isEmpty(walkins) ? walkins : []}
                    >
                    </AddTestDrive>
                </PopUpModal>

                <PopUpModal show={showInbounds}>
                    {showInbounds && (<InboundTestdrives
                        show={showInbounds}
                        clsActive={showInbounds ? 'overlay-modal active' : ''}
                        handleClose={this.handleInboundClose}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    >
                    </InboundTestdrives>)}
                </PopUpModal>
            </>
        );
    }
}

