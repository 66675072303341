export const fleetFieldsVM = [
    {
        name: 'Make',
        value: 'make'
    },
    {
        name: 'Model',
        value: 'model'
    },
    {
        name: 'Model Description',
        value: 'modelDescription'
    },
    {
        name: 'Body Type',
        value: 'type'
    },
    {
        name: 'Group',
        value: 'group'
    },
    {
        name: 'Year',
        value: 'year'
    },
    {
        name: 'VIN',
        value: 'vinNo'
    },
    {
        name: 'Reg No',
        value: 'regNo'
    },
    {
        name: 'Ext. Color',
        value: 'extColor'
    },
    {
        name: 'Int. Color',
        value: 'intColor'
    },
    {
        name: 'Mileage',
        value: 'mileage'
    },
    {
        name: 'Fleet Owner',
        value: 'fleetOwner'
    },
    {
        name: 'Status',
        value: 'status'
    },
    {
        name: 'De-Fleet Date',
        value: 'defleetDate'
    },
    {
        name: 'Country',
        value: 'country'
    },
    {
        name: 'Region',
        value: 'regionID'
    },
    {
        name: 'Subregion',
        value: 'subregionID'
    },
    {
        name: 'Group',
        value: 'groupID'
    }
]

export const retailerEventVM = [
    { name: 'Client', value: 'clientID' },
    { name: 'Type Of Activity', value: 'typeOfActivity' },
    { name: 'Event Name', value: 'eventName' },
    { name: 'Start Date', value: 'startDate' },
    { name: 'End Date', value: 'endDate' },
    { name: 'Location', value: 'location' },
    { name: 'Name', value: 'name' },
    { name: 'Email', value: 'email' },
    { name: 'Phone', value: 'phone' },
    { name: 'Address', value: 'address' },
    { name: 'Company Name', value: 'companyName' },
    { name: 'Purpose', value: 'purpose' },
    //{ name: 'Method Of Transport', value: 'methodOfTransport' },
    //{ name: 'Transport Company', value: 'transportCompany' },
    //{ name: 'Departure Date', value: 'departureDate' },
    //{ name: 'Expected Arrival Date', value: 'expectedArrivalDate' },
    //{ name: 'Expected Return Date', value: 'expectedReturnDate' },
    { name: 'Inspection', value: 'inspection' },
    //{ name: 'Odometer', value: 'odometer' },
    { name: 'Pre Odometer', value: 'preOdometer' },
    { name: 'Pre Inspection', value: 'preInspection' },
    { name: 'Pre Signature', value: 'preSignature' },
    { name: 'pre Notes', value: 'preNotes' },
    { name: 'Terms', value: 'terms' },
    { name: 'Notes', value: 'notes' },
    { name: 'MAL Technical Support Required', value: 'malTechnicalSupportReq' },
    { name: 'Post Odometer', value: 'postOdometer' },
    { name: 'Post Signature', value: 'postSignature' },
    { name: 'Post Inspection', value: 'postInspection' },
    { name: 'Post Notes', value: 'postNotes' },
]

export const extIntEventVM = [
    { name: 'Event Name', value: 'eventName' },
    { name: 'Start Date', value: 'startDate' },
    { name: 'End Date', value: 'endDate' },
    { name: 'Location', value: 'location' },
    { name: 'Event Type', value: 'eventType' },
    { name: 'Name', value: 'name' },
    { name: 'Phone', value: 'phone' },
    { name: 'Email', value: 'email' },
    { name: 'Address', value: 'address' },
    { name: 'Company Name', value: 'companyName' },
    { name: 'Inspection', value: 'inspection' },
    { name: 'Pre Odometer', value: 'preOdometer' },
    { name: 'Pre Inspection', value: 'preInspection' },
    { name: 'Pre Signature', value: 'preSignature' },
    { name: 'pre Notes', value: 'preNotes' },
    { name: 'Terms', value: 'terms' },
    { name: 'Notes', value: 'notes' },
    //{ name: 'Odometer', value: 'odometer' },
    { name: 'Post Odometer', value: 'postOdometer' },
    { name: 'Post Signature', value: 'postSignature' },
    { name: 'Post Inspection', value: 'postInspection' },
    { name: 'Post Notes', value: 'postNotes' }
]

export const loanVehicleVM = [
    { name: 'Start Date', value: 'startDate' },
    { name: 'Expected Return Date', value: 'expectedReturnDate' },
    { name: 'End Date', value: 'endDate' },
    { name: 'Name', value: 'name' },
    { name: 'Phone', value: 'phone' },
    { name: 'Email', value: 'email' },
    { name: 'Address', value: 'address' },
    { name: 'Company Name', value: 'companyName' },
    //{ name: 'Odometer', value: 'odometer' },
    { name: 'Inspection', value: 'inspection' },
    { name: 'Terms', value: 'terms' },
    { name: 'Notes', value: 'notes' },
    { name: 'Post Odometer', value: 'postOdometer' },
    { name: 'Post Inspection', value: 'postInspection' },
    { name: 'Post Signature', value: 'postSignature' },
    { name: 'Post Notes', value: 'postNotes' }
]

export const allModules = [
    "fleetPro",
    "status",
    "retailerEvent",
    "extIntEvent",
    "loanVehicle"
]

export const fleetModules = [
    "fleetPro",
    "status",
    "retailerEvent",
    "extIntEvent",
    "loanVehicle"
]

export const levelOptions = [
    { active: true, value: 'oem', label: 'OEM', index: 1 },
    { active: true, value: 'region', label: 'Region', index: 2 },
    { active: true, value: 'group', label: 'Group', index: 3 },
    { active: true, value: 'individual', label: 'Individual', index: 4 }
]


export const fleetStatus = [
    { active: true, value: 'allocated', name: 'Allocated' },
    { active: true, value: 'fok', name: 'FOK' },
    { active: true, value: 'onfleeting', name: 'On-Fleeting' },
    { active: true, value: 'operational', name: 'Operational' }
]

export const mandSettingsVM = {
    fleetPro: null,
    retailerEvent: null,
    extIntEvent: null,
    loanVehicle: null,
    status: [],
}


export const moduleNameValue = [
    {
        name: 'Fleet',
        value: 'fleetPro'
    },
    {
        name: 'Retailer Event',
        value: 'retailerEvent'
    },
    {
        name: 'Ext. Int. Event',
        value: 'extIntEvent'
    },
    {
        name: 'Loan Vehicle',
        value: 'loanVehicle'
    }
]