/** LIBRARIES */
import React, { Component, Fragment } from 'react';
import { Accordion, Tabs, Tab } from 'react-bootstrap';
import { OverlayTrigger, Tooltip, Card, Dropdown } from 'react-bootstrap';
import moment from 'moment';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2';
import _ from 'lodash';
import NumberFormat from 'react-number-format';

import { collection, doc } from 'rxfire/firestore';
import { combineLatest } from 'rxjs';

import {
    PopUpModal, ImageCanvas,
    AfterSignature, Terms, PopoverPanel,
    LoaderOverlay, DropDownMenu, ContentOverlay
} from '../../../components';

import { CustomMenu, CustomToggle } from '../../../components/customdropdown';
import Translate from '../../../constants/translate';
import images, { default as _images } from '../../../images';
import PageNotFound from '../../../components/pagenotFound';

import AddNotes from '../../fleet/add/notes'
//import ContactInfo from '../../contacts/info'
//import Files from '../../files';
//import AddFile from '../../files/add';
import CommonHelper from '../../../services/common';

import AddFleet from '../add'
import AssignFleet from '../assign/'


import { fleetSortOptions, objFleetPro, fleetAssignStatusLevel, objFleetProAssign } from '../viewModel';

import { defaultfleetAssignTypes, eventStatus, fleetAssignStatus, fleetAssignTypes, fleetProCategory, fleetProStatus, userLevel } from '../../../services/enum';

import htmlToText from 'html-to-text';
import VehicleBookings from '../calndar';

//#region  ASSIGN VARIABLES
let fleetOptionsDF = [];
let fleetProAllStatus = [];
let _enquiryAllStatus = [];
const fieldsData = {
    contact: '',
    fleetPro: '',
    company: ''
}

let enquirySortOptions = [
    "origin", "lostReason", "lostSubReason"
];
//#endregion

export default class FleetProInfo extends Component {
    // _isMounted = false;
    constructor(props) {
        super(props);
        this.ref = window.firebase.firestore().collection('fleetPro');
        this.unsubscribeFleetPro = null;
        this.unsubscribeAssigns = null;
        this.unsubscribeLogs = null;
        this.mandatoryFields = [];
        this.state = {
            loading: false,
            activeLeftKey: '0',
            activeCntKey: '0',
            activeVehKey: '1',
            activeTipKey: '2',
            activeRightKey: '00',
            fields: Object.assign({}, objFleetPro),
            fleetAssigns: Object.assign([]),
            fleetProLogs: Object.assign([]),
            fleetProID: null,
            _clientID: null,
            errors: {},
            allUsers: [],
            eventsModal:
            {
                show: false,
                title: '',
                clsActive: '',
                docID: null,
                mandatoryFields: null
            },
            showReAuthentication: false,
            pagenotFound: false,
            dealersettings: props.dealersettings,
            settingLoading: true,
            readMore: false,
            notesPopOver: { showpopover: false, targetpopover: null, notes: '' },
            showInspection: {
                show: false,
                marks: null
            },
            showTerms: {
                show: false,
                terms: null
            },
            fleetModal: { show: false, docID: null },
            fleetAssignModal: { show: false, docID: null },
            bookingsModal: { show: false, fleetProID: null },
            showSignature: { show: false, id: null, sign: null, isReadOnly: false },
            loghasMore: [],
            logPagingLoader: false,
            logPageLimit: 20,

        }
    }

    //#region FUNCTIONS

    //#region LOAD PAGE

    async loadFleetPro() {
        document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
        const { fleetproid, clientUsers, groupUsers } = this.props;
        if (fleetproid && this.props.dealersettings) {
            // if (dealersettings.client.settings.pipelines.length === 0) {
            //     Swal.fire('Access denied. Please contact administrator.', '', 'info')
            //     this.props.history.push('/eventspro/list');
            //     return
            // }

            const refData = await window.firebase.firestore().collection('fleetPro').doc(fleetproid).get();
            var _stage = '';

            if (refData.exists) {
                const _objTIP = Object.assign({}, refData.data());

                const _fleetPro = Object.assign({}, objFleetPro);
                for (let [key, value] of Object.entries(_objTIP)) {
                    _fleetPro[key] = value;
                }
                _fleetPro.documentID = refData.id;

                let dealersettings = this.props.dealersettings;

                const _client = dealersettings.client;
                const _permissions = dealersettings?.rolePermissions?.fleetProPermissions;
                const accessToFleetHistory = ((!_.isEmpty(_permissions) && _permissions.accessToFleetHistory) ? true : false);
                // //console.log('E              X             I                S                  T             S', _isEmptyPipeline)
                if (!accessToFleetHistory) {
                    Swal.fire(CommonHelper.showLocale(this.props, 'Access denied.You dont have history access. Please contact administrator.'), '', 'info')
                    this.props.history.push('/fleetpro/list');
                    return
                }

                // //add eventspro into routes
                this.props.handleRouteChange({
                    name: _fleetPro.make ? _fleetPro.make + ' ' + _fleetPro.model : '',
                    id: refData.id,
                    displayID: _fleetPro.displayID ? _fleetPro.displayID : refData.id,
                    status: CommonHelper.getFleetStatus(_fleetPro),
                    active: true,
                    clientid: _fleetPro.clientID,
                    isNew: null,
                    params: '',
                    module: 'fleetPro'
                }, 'fleetPro');

                if (!_.isEmpty(_client) && !_.isEmpty(_client.fleetPro)) {
                    const setttings = _client.fleetPro;

                    if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.fleetPro))
                        this.mandatoryFields = setttings.mandatoryFields.fleetPro.split(',');

                    fleetOptionsDF = [];
                    if (setttings.fleetOptionsDF)
                        fleetOptionsDF = setttings.fleetOptionsDF;

                    fleetProAllStatus = [];
                    dealersettings?.client?.fleetPro?.fleetProStatus && dealersettings.client.fleetPro.fleetProStatus.forEach((doc) => {
                        fleetProAllStatus.push({
                            ...doc,
                            label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name.toUpperCase()}</div>,
                        });
                    });

                }

                // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
                let options = [];
                const roleFleetProIds = [..._.map(dealersettings.client?.roles?.filter(e => e.modules?.fleetPro === true), function (obj) {
                    return obj.documentID;
                })];
                const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers
                _.uniqBy(allUsers, 'id').filter(a => Boolean(a.superAdmin) === false && (!_.isEmpty(roleFleetProIds) ? _.includes(roleFleetProIds, a.roleID) : false)).forEach(rec => {
                    options.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>,
                        data: rec,
                        name: rec.name
                    })
                })

                this.setState({
                    fleetProID: refData.id,
                    _clientID: _fleetPro.clientID ? _fleetPro.clientID : dealersettings.client.id,
                    fields: _fleetPro,
                    allUsers: _.uniqBy(options, 'value'),
                    dealersettings: dealersettings,
                }, () => {
                    this.unsubscribeFleetPro = this.ref
                        .doc(fleetproid)
                        .onSnapshot(this.onTIPCollectionUpdate);

                    this.unsubscribeAssigns = window.firebase.firestore().collection('fleetProAssign')
                        .where('fleetProID', '==', refData.id)
                        .where('isDeleted', '==', false)
                        // .orderBy('modifiedDate', 'desc')
                        .onSnapshot(this.onAssignsCollectionUpdate);

                    this.loadPagingLogs();
                });
            }
            else {
                this.setState({
                    pagenotFound: true,
                    fleetProID: null,
                })
            }
        }
    }


    onAssignsCollectionUpdate = querySnapshot => {
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
        let fleetAssigns = [];
        const { dealersettings } = this.props
        querySnapshot.forEach(_tradein => {
            const _fleetAssign = Object.assign({}, objFleetProAssign);
            for (let [key, value] of Object.entries(_tradein.data())) {
                _fleetAssign[key] = value;
            }

            _fleetAssign.documentID = _tradein.id;
            _fleetAssign.addedDate = _fleetAssign.addedDate ? _fleetAssign.addedDate : window.firebase.firestore.Timestamp.now();

            let _clientIDs = CommonHelper.getAllFleetProDealers(dealersettings, true)
            if ((dealersettings.level === userLevel.INDIVIDUAL && _fleetAssign?.clientID && _clientIDs.includes(_fleetAssign?.clientID) && _fleetAssign?.assignType === fleetAssignTypes.RetailerEvent))
                fleetAssigns.push(_fleetAssign);
            else if (dealersettings.level !== userLevel.INDIVIDUAL)
                fleetAssigns.push(_fleetAssign);
        });

        if (!_.isEmpty(fleetAssigns)) {
            fleetAssigns = fleetAssigns.sort(function (x, y) {
                return (x.addedDate.seconds > y.addedDate.seconds ? -1 : 1);
            })
        }



        this.setState({
            fleetAssigns: fleetAssigns
        });
    }

    loadPagingLogs() {
        let lastRecord = this.state.loghasMore;
        const { dealersettings } = this.props
        let _clientIDs = CommonHelper.getAllFleetProDealers(dealersettings, true)
        var enquiryQuery = window.firebase.firestore().collection('fleetProLogs')
            .where('fleetProID', '==', this.props.fleetproid)
            .where('isDeleted', '==', false);

        if (dealersettings.level === userLevel.INDIVIDUAL)
            enquiryQuery = enquiryQuery.where('clientID', 'in', [_clientIDs])

        if (lastRecord.length > 0) {
            enquiryQuery = enquiryQuery
                .startAfter(lastRecord[0].lastDoc)
                .limit(this.state.logPageLimit)
        }
        else {
            enquiryQuery = enquiryQuery
                .limit(this.state.logPageLimit)
        }
        this.unsubscribeLogs = enquiryQuery
            .onSnapshot(this.onLogsCollectionUpdate);
    }

    onLogsCollectionUpdate = querySnapshot => {
        const fleetProLogs = [];
        if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === this.state.logPageLimit) {
            this.setState({
                loghasMore: [{
                    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1]
                }]
            })
        }
        else {
            this.setState({
                loghasMore: []
            })
        }
        querySnapshot.forEach(_enquiry => {
            const enquiry = Object.assign({}, _enquiry.data());
            enquiry.documentID = _enquiry.id;
            fleetProLogs.push(enquiry);
        });

        this.setState({
            fleetProLogs: _.uniqBy(_.orderBy([
                ...this.state.fleetProLogs,
                ...fleetProLogs
            ], ["logDate"], ["desc"]), 'documentID'),
            logPagingLoader: false
        });
    };
    // loadTemplates = () => {
    //     const { dealersettings } = this.props
    //     const { clientIDs, clientID, level, levelID } = this.state.fields;
    //     let templatesSnap = '';
    //     let triggerSnap = '';
    //     let _clientIDs = CommonHelper.getAllEventClientsByLevel(dealersettings, clientIDs, level, levelID)
    //     var templatePromise = [];
    //     if (_clientIDs?.length > 0) {
    //         for (let k = 0; k < Math.ceil(_clientIDs.length / 1); k++) {
    //             templatePromise.push(collection(window.firebase.firestore().collection('eventsProTemplates')
    //                 .where('isDeleted', '==', false)
    //                 .where('clientIDs', 'array-contains-any', CommonHelper.arrPaginate(_clientIDs, 1, k + 1))
    //             ))

    //         }
    //     }
    //     templatePromise.push(collection(window.firebase.firestore().collection('eventsProTriggers')
    //         .where('fleetProID', '==', this.state.fields.documentID)));

    //     templatePromise.push(doc(window.firebase.firestore().doc(`fleetPro/${this.state.fields.documentID}/settings/${this.state.fields.documentID}`)));
    //     templatePromise.push(collection(window.firebase.firestore().collection(`eventsProTPSource`)
    //         .where('active', '==', true)
    //         .where('isDeleted', '==', false)
    //         .where('eventsProIDs', 'array-contains', this.state.fields.documentID)
    //     ));
    //     window.refCollectionFleetssData = combineLatest(
    //         templatePromise
    //     ).subscribe(async allDocs => {
    //         var _templates = [];
    //         for (let i = 0; i < allDocs.length - 3; i++) {
    //             _templates.push(...allDocs[i].map(r => { return { ...r.data(), documentID: r.id } }));

    //         }
    //         this.setState({
    //             eventTriggers: allDocs[allDocs.length - 3].map(r => { return { ...r.data(), documentID: r.id } }),
    //             eventTemplates: _.uniqBy(_templates, 'documentID'),
    //             eventSettings: {
    //                 ...allDocs[allDocs.length - 2].data(),
    //                 fleetProID: allDocs[allDocs.length - 2].id,
    //                 source: allDocs[allDocs.length - 1].map(r => { return { ...r.data(), documentID: r.id, value: r.id } })
    //             },
    //             settingLoading: false
    //         });
    //     });
    // }




    onTIPCollectionUpdate = (docSnapshot) => {
        document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');

        if (!docSnapshot.exists) {
            return;
        }

        const _fleetPro = Object.assign({}, objFleetPro);
        const _objTIP = Object.assign({}, docSnapshot.data());
        for (let [key, value] of Object.entries(_objTIP)) {
            _fleetPro[key] = value;
        }
        _fleetPro.documentID = docSnapshot.id;

        // const { status } = _fleetPro;
        // if (_fleetPro.status !== this.state.fields.status) {
        //     this.props.updatenavbar({
        //         id: this.state.fleetProID,
        //         displayID: _fleetPro.displayID,
        //         status: status,
        //         name: _fleetPro.title,
        //         module: 'fleetPro'
        //     }, 'fleetPro');
        // }

        this.setState({
            fields: _fleetPro
        });
    };


    handleSpaceScrollLock = (e) => {
        if (e.keyCode == 32 && e.target == document.body) {
            e.preventDefault();
        }
    }

    componentDidMount() {
        localStorage.removeItem("objFleetPro");
        this._isMounted = true;
        if (this._isMounted) {
            this.loadFleetPro();
            setTimeout(() => this.scrollIntoComments(), 4000);
        }

        window.addEventListener('keydown', (e) => { this.handleSpaceScrollLock(e) });

    }

    componentDidUpdate() {
        let classList = document.getElementsByTagName('body')[0].classList.value;
        if (classList.indexOf('enquiry-bg') >= 0 && classList.indexOf('inner-bg') >= 0) {
            //console.log('componentDidUpdate-classList', classList.indexOf('enquiry-bg'), classList.indexOf('inner-bg'));
            document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        }
        if (this.state.loading) {
            this.unsubscribeFleetPro && this.unsubscribeFleetPro();
            this.unsubscribeAssigns && this.unsubscribeAssigns();
            //this[`unsubscribeAssigns-${this.props.fleetproid}`] && this[`unsubscribeAssigns-${this.props.fleetproid}`]();
            this.unsubscribeLogs && this.unsubscribeLogs();
            window.refCollectionFleetssData?.unsubscribe && window.refCollectionFleetssData.unsubscribe()
            this.loadFleetPro();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.fleetproid !== state.fleetProID && state.fleetProID) {
            return {
                loading: true,
                settingLoading: true,
                fleetProID: null,
                fleetAssigns: Object.assign([]),
                fleetProLogs: Object.assign([]),
                loghasMore: Object.assign([]),
            };
        } else {
            return {
                loading: false,
            };
        }
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
        window.removeEventListener('keydown', (e) => { this.handleSpaceScrollLock(e) });
        this.unsubscribeFleetPro && this.unsubscribeFleetPro();
        this.unsubscribeAssigns && this.unsubscribeAssigns();
        this.unsubscribeLogs && this.unsubscribeLogs();
        window.refCollectionFleetssData?.unsubscribe && window.refCollectionFleetssData.unsubscribe()
        if (window.location.pathname.indexOf('eventspro/details') < 0) {
            this.props.resetnavbar();
        }
        this._isMounted = false;
    }

    scrollIntoComments = () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let __comments = params.get('comments');
        let __assignID = params.get('aid');

        if (__comments && document.getElementById('enquiry-item-' + __comments)) {
            document.getElementById('enquiry-item-' + __comments).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }
        if (__assignID) this.setState({ fleetAssignModal: { show: true, docID: __assignID }, activeRightKey: __assignID })

    }

    //#endregion    

    //#region Vehicle CHANGES


    handleEventOpen = (id, _manFields) => {
        this.setState({
            eventsModal: {
                show: true,
                title: 'event',
                clsActive: 'overlay-modal active',
                docID: id,
                //mandatoryFields: !_.isEmpty(_manFields) ? _manFields : [],
            },
        });
    }

    handleEventClose = (tradeinID, tradein, isLink) => {
        this.setState({
            eventsModal: {
                show: false,
                title: 'event',
                clsActive: 'overlay-modal active',
                mandatoryFields: null,
                docID: null
            },
        });
    }

    //#endregion

    //#region CHANGE EVENT TIP

    handleOnChange = (e) => {
        const { name, value } = e.target;
        this.saveFleetProField(name, value);
    }

    handleOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = _.trim(value.toUpperCase());
        else
            fields[name] = null;
        this.setState({ fields: fields }, () => { this.errorChange(name); });
    }

    handleOnDateChange = (value, name) => {
        let _selectedDate = null;
        if (value) _selectedDate = window.firebase.firestore.Timestamp.fromDate(moment(moment(value).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);
        this.saveFleetProField(name, _selectedDate);

        setTimeout(() => this.errorChange(name), 500);
    };

    handleTimeChange = (date, name) => {

        this.saveFleetProField(name, window.firebase.firestore.Timestamp.fromDate(moment(date)._d));
        setTimeout(() => this.errorChange(name), 500);
    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        if (!_.isEmpty(errors)) {
            if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
                if (key === 'status') {
                    errors[key] = errorClass;
                }
                else
                    errors[key] = errorClass;
            }
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    };

    handleSelectSave = (e, data) => {
        this.saveFleetProField(data.name, (e ? e.value : null));
        setTimeout(() => this.errorChange(data.name), 500);
    };

    handleDeliveryDateChange = (val, name) => {
        if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);

        this.saveFleetProField(name, val);
        setTimeout(() => this.errorChange(name), 500);
    };

    handleDateChange = (val, name) => {
        if (val) val = moment(val).format('YYYY-MM-DD');

        this.saveFleetProField(name, val);
        setTimeout(() => this.errorChange(name), 500);
    };

    handlePIDateSave = (val, name) => {
        if (val) val = moment(val).format('MMM YYYY');

        this.saveFleetProField(name, val);
        setTimeout(() => this.errorChange(name), 500);
    };

    isPIValid = (date) => {
        let _date = moment(date, 'MMM YYYY');
        if (moment().isBefore(_date) || moment().isSame(_date, 'month')) return '';
        else return 'form-alert ';
    };

    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}

        let state = Object.assign({}, this.state.fields);
        state[name] = e.floatValue;
        this.setState({ fields: state }, () => { this.errorChange(name) });
    }

    handleLeftActiveKey = (index) => {
        this.setState({ activeLeftKey: index });
    }
    handleCntActiveKey = (index) => {
        this.setState({ activeCntKey: index });
    }
    handleVehActiveKey = (index) => {
        this.setState({ activeVehKey: index });
    }
    handleTipActiveKey = (index) => {
        this.setState({ activeTipKey: index });
    }
    handleRightActiveKey = (index) => {
        this.setState({ activeRightKey: index });
    }


    //#endregion

    //#region  SAVE TIP INFO


    cancelEventsProField = (name, val) => {
        let state = Object.assign({}, this.state.fields);
        state[name] = val;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    saveFleetProField = (name, val, noToast) => {

        let objData = {};
        let state = Object.assign({}, this.state.fields);
        state[name] = val;
        objData[name] = val;
        objData.modifiedBy = state.modifiedBy;
        objData.modifiedDate = state.modifiedDate;
        objData.modifiedFrom = 'web';

        this.setState({ fields: state });

        const updateRef = window.firebase.firestore().collection('fleetPro').doc(this.state.fleetProID);
        updateRef.set(objData, { merge: true }).then((docRef) => {

            if (!noToast)
                toast.notify('Fleet updated successfully.', { duration: 2000 });

            if (name === 'status') {
                this.props.updatenavbar({
                    id: this.state.fleetProID,
                    status: CommonHelper.getFleetStatus(state.status),
                    name: state.make ? state.make + ' ' + state.model : '',
                });
            }

        }).catch(error => {
            console.error('Error updating enquiries: ', error);
        });

    };

    handleDynamicStatus = (_status) => {
        let _selectedStatus = CommonHelper.getNameByValue(fleetProAllStatus, _status)
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: `${CommonHelper.showLocale(this.props, 'You want to change fleet status to')} ${_selectedStatus.toUpperCase()}`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then(result => {
            if (result.value) {
                this.saveFleetProField('status', _status);
            }
        });
    }

    //#endregion


    render() {

        //console.log('R E - R E N D E R - E N Q U I R Y - E D I T ');
        let { documentID, clientID, displayID, clientName,
            status, fleetOwner, isDeleted,
        } = this.state.fields;

        const { activeCntKey, activeVehKey, allUsers, activeRightKey, fleetAssigns, showSignature, fleetProLogs,
            fleetAssignModal, fleetModal, notesPopOver, fields, showTerms, showInspection, bookingsModal } = this.state;

        let _fleetStatus = CommonHelper.getFleetStatus(fields);

        const { groupUsers, clientUsers, dealersettings } = this.props;
        let _clientIDs = CommonHelper.getAllFleetProDealers(dealersettings, true)
        const _allUser = !_.isEmpty(groupUsers) ? groupUsers : clientUsers
        const currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

        const _settings = dealersettings?.client?.fleetPro;

        const _permissions = dealersettings?.rolePermissions?.fleetProPermissions;

        const readOnlyFleetVehicle = ((!_.isEmpty(_permissions) && _permissions.readOnlyFleetVehicle) ? true : false);
        //const accessToAddVehicle = ((!_.isEmpty(_permissions) && _permissions.accessToAddVehicle && !readOnlyFleetVehicle) ? true : false);
        const accessToEditVehicle = ((!_.isEmpty(_permissions) && _permissions.accessToEditVehicle && !readOnlyFleetVehicle) ? true : false);
        //const accessToDeleteVehicle = ((!_.isEmpty(_permissions) && _permissions.accessToDeleteVehicle && !readOnlyFleetVehicle) ? true : false);
        const accessToVehicleBooking = ((!_.isEmpty(_permissions) && _permissions.accessToVehicleBooking && !readOnlyFleetVehicle) ? true : false);
        const accessToVehicleScheduler = ((!_.isEmpty(_permissions) && _permissions.accessToVehicleScheduler && !readOnlyFleetVehicle) ? true : false);
        const accessToUpdateFleetStatus = ((!_.isEmpty(_permissions) && _permissions.accessToUpdateFleetStatus && !readOnlyFleetVehicle) ? true : false);
        //const accessToScheduleTransit = ((!_.isEmpty(_permissions) && _permissions.accessToScheduleTransit && !readOnlyFleetVehicle) ? true : false);
        const accessToAssign = ((!_.isEmpty(_permissions) && _permissions.accessToAssign && !readOnlyFleetVehicle) ? true : false);
        const accessToRequest = ((!_.isEmpty(_permissions) && _permissions.accessToRequest && !readOnlyFleetVehicle) ? true : false);
        // const accessToApprove = ((!_.isEmpty(_permissions) && _permissions.accessToApprove && !readOnlyFleetVehicle) ? true : false);
        // const accessToReturn = ((!_.isEmpty(_permissions) && _permissions.accessToReturn && !readOnlyFleetVehicle) ? true : false);
        // const accessToReceive = ((!_.isEmpty(_permissions) && _permissions.accessToReceive && !readOnlyFleetVehicle) ? true : false);
        // const accessToMarketingFleet = ((!_.isEmpty(_permissions) && _permissions.accessToMarketingFleet) ? true : false);
        // const accessToPRFleet = ((!_.isEmpty(_permissions) && _permissions.accessToPRFleet) ? true : false);
        // const accessToMSOAssest = ((!_.isEmpty(_permissions) && _permissions.accessToMSOAssest) ? true : false);
        // const accessToFleetHistory = ((!_.isEmpty(_permissions) && _permissions.accessToFleetHistory) ? true : false);
        // const accessToViewAllocatedFleet = ((!_.isEmpty(_permissions) && _permissions.accessToViewAllocatedFleet) ? true : false);
        // const accessToViewFOKFleet = ((!_.isEmpty(_permissions) && _permissions.accessToViewFOKFleet) ? true : false);
        // const accessToOnFleetingVehicle = ((!_.isEmpty(_permissions) && _permissions.accessToOnFleetingVehicle) ? true : false);
        // const accessToOperationalVehicle = ((!_.isEmpty(_permissions) && _permissions.accessToOperationalVehicle) ? true : false);
        // const accessToDeFleetVehicles = ((!_.isEmpty(_permissions) && _permissions.accessToDeFleetVehicles) ? true : false);

        const canModifyFleet = false;//(isDeleted) ? false : true;


        let dynamicDetailList = !_.isEmpty(_settings?.sortFleetOptions) ? _settings.sortFleetOptions : (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.fleetProOptions))
            ? dealersettings.client.fleetProOptions :
            Object.assign([], _.uniq(_.union(fleetSortOptions, Object.assign([], (!_.isEmpty(fleetOptionsDF) && !_.isEmpty(fleetOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(fleetOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));


        let _objCurrentStatus = _fleetStatus && fleetProAllStatus.filter(e => e.value === _fleetStatus)[0];
        let _dynamicStatusMenu = CommonHelper.bindFleetStatusMenu(fleetProAllStatus, _fleetStatus, _permissions, accessToUpdateFleetStatus);
        // let _allClientIDs = CommonHelper.getAllEventClientsByLevel(dealersettings, clientIDs, fields.level, fields.levelID)
        //console.log('_allClientIDs', fleetOptionsDF, dynamicDetailList)

        const tradeinIdentifiers = [
            // "regionID",
            // "subregionID",
            // "groupID",
            // "clientID",
            "make",
            "model",
            "year",
            "status",
            "fleetOwner",
            "category"
        ];

        return this.state.fleetProID ? (
            <>

                <div className="middle-wrapper">


                    <div className="fleetpro-detailswrap">

                        <div className="fleetpro-detail-head">
                            <div className="float-left">
                                <div className="contactdetails-userthumb-img ">
                                    {
                                        (!_.isEmpty(fields?.images))
                                            ?
                                            <>
                                                {
                                                    fields.images.map((data, index) => (
                                                        <a key={index} data-fancybox={`fleet_pro_detail_img_slider`} className={index === 0 ? '' : 'hide'} href={data} onClick={(e) => { e.preventDefault(); }}>
                                                            {index === 0 ?
                                                                <>
                                                                    <div className="zoom-ico"><i className="ico icon-zoom"></i></div>
                                                                    <img src={data} width="70" height="70" alt="" className="img-object-fit" />
                                                                </>
                                                                : <></>}

                                                        </a>
                                                    ))
                                                }
                                            </>
                                            :
                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                <img src={CommonHelper.showBrandLogo(dealersettings, fields?.make)} className="img-object-fit-contain" width="70" height="70" alt="" />
                                            </a>
                                    }

                                </div>


                            </div>

                            <div className="float-left">

                                <div className="enquiry-title" onClick={(e) => { e.preventDefault(); console.log('dealersettings,', dealersettings) }}> {fields.make} {fields.model} </div>
                                <div className="enquiry-subtitle">{fields.extColor ? `${fields.extColor},` : ''} {fields.year ? fields.year : ''}
                                    {
                                        fields?.category && (<div className="badge badge-pill badge-white ml-1">{CommonHelper.getNameByValue(dealersettings.client?.fleetPro?.category, fields?.category, '')}</div>)
                                    }
                                </div>

                            </div>


                            <div className="float-right">
                                {
                                    (_fleetStatus && _objCurrentStatus)
                                        ?
                                        <div className='current-status mt-3'>
                                            <Translate text={'currentStatus'} />
                                            <div className={`badge badge-pill badge-empty ml-2`}
                                                style={{ background: (_objCurrentStatus && _objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}>
                                                {
                                                    !_.isEmpty(_dynamicStatusMenu)
                                                        ?
                                                        <DropDownMenu
                                                            text={_objCurrentStatus ? _objCurrentStatus.name : _fleetStatus}
                                                            keyVal="165898"
                                                            tIndex="-13"
                                                            menu={_dynamicStatusMenu}
                                                            handleChange={this.handleDynamicStatus}
                                                        >
                                                        </DropDownMenu>
                                                        :
                                                        <Translate
                                                            text={_objCurrentStatus ? _objCurrentStatus.name : _fleetStatus}
                                                            upperCase={true}
                                                        />
                                                }
                                            </div>
                                        </div>
                                        :
                                        <></>

                                }


                                <div className="status-btn-wrap">
                                    <a href="#" className="btn btn-common button-grey" onClick={(e) => {
                                        e.preventDefault();
                                        const SONO = CommonHelper.getFleetProDynamicField(fields.dynamicFields, 'sono', dealersettings?.client?.fleetPro?.fleetOptionsDF)
                                        this.setState({
                                            bookingsModal: {
                                                show: true,
                                                title: `Booking Calendar`,
                                                subTitle: ` - ${fields?.make || ''} ${fields?.model || ''} ${SONO ? '(SONO: ' + SONO + ')' : ''}`,
                                                fleetProID: fields.documentID,
                                                accessToVehicleScheduler,
                                                accessToVehicleBooking
                                            }
                                        })
                                    }}> <i className="ico icon-Calendar mr-2"></i> Schedule </a>
                                    {
                                        accessToAssign && dealersettings.level !== userLevel.INDIVIDUAL && _fleetStatus && [fleetProStatus.OPERATIONAL].includes(_fleetStatus) && (<a href="#" className="btn btn-common button-blue" onClick={(e) => {
                                            e.preventDefault(); this.setState({
                                                fleetAssignModal: {
                                                    show: true,
                                                    docID: null
                                                }
                                            })
                                        }}> <i className="ico icon-close mr-2"></i> Assign </a>)
                                    }

                                    {
                                        accessToRequest && dealersettings.level === userLevel.INDIVIDUAL && [fleetProCategory.MARKETING].includes(fields?.category) && [fleetProStatus.OPERATIONAL].includes(_fleetStatus) && (<a href="#" className="btn btn-common button-blue" onClick={(e) => {
                                            e.preventDefault(); this.setState({
                                                fleetAssignModal: {
                                                    show: true,
                                                    docID: null
                                                }
                                            })
                                        }}> <i className="ico icon-close mr-2"></i> Request </a>)
                                    }
                                </div>

                                {/* <div className="enquiry-view-more dropdown">
                                    <button type="button" className="common-button dropdown-toggle btn btn-primary"><i className="ico icon-more"></i></button>
                                </div> */}
                            </div>

                        </div>


                        <div className="enquiry-detail-bottom">
                            <div className="float-right">
                                <div className="filter-item-btn ml-2"> <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                    <div className="filter-item-users">
                                        <img src={CommonHelper.getUserImagebyId(_allUser, fleetOwner)} alt="" width="300" height="300" />
                                    </div>
                                    <span>  {CommonHelper.getUserNamebyId(_allUser, fleetOwner)}</span>
                                </a> </div>
                            </div>
                        </div>
                    </div>

                    <div className="enquirydetail-grid flex-column mt-4">
                        {/** LEFT TAB */}
                        <aside className="small-column mr-3">
                            <div className="enquiry-sidebar">


                                <div id="accordion-left">

                                    <Accordion defaultActiveKey="0" >
                                        {/** Fleet INFO */}

                                        <Card>
                                            <Accordion.Toggle
                                                as={Card.Header}
                                                eventKey="0"
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    if (activeCntKey === '0')
                                                        this.handleCntActiveKey(null);
                                                    else
                                                        this.handleCntActiveKey('0');
                                                }}>
                                                <div className="mb-0">

                                                    <div className="cardlink">
                                                        <a onClick={(e) => { e.preventDefault(); }} className={`btn btn-link ${activeCntKey === '0' ? '' : 'collapsed'}`}>
                                                            <i className="ico icon-Stock"></i> <Translate text={'Vehicle Info'} /> <i className="arrow-collapse"></i>
                                                        </a></div>
                                                </div>
                                            </Accordion.Toggle>


                                            {accessToEditVehicle && (<div className='edit-button right-icon'>
                                                <a
                                                    href='#'
                                                    className='mini-button'
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            fleetModal: {
                                                                show: true,
                                                                docID: this.state.fleetProID
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <i className='ico icon-edit'></i>
                                                </a>
                                            </div>)}


                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                    <div className="info-table">
                                                        <table className="table-fill">
                                                            <tbody>
                                                                {
                                                                    dynamicDetailList.filter(e => !tradeinIdentifiers.includes(e)).map((rec, index) => {
                                                                        return <tr key={index}>
                                                                            {(() => {
                                                                                if (rec === 'regDate' || rec === 'complianceDate') {
                                                                                    return <>
                                                                                        <td className="text-left label-text"><Translate text={rec} /></td>
                                                                                        <td className="text-left">
                                                                                            {fields[rec] ? moment(fields[rec], 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}
                                                                                        </td>
                                                                                    </>
                                                                                }
                                                                                else if (rec === 'defleetDate') {
                                                                                    return <>
                                                                                        <td className="text-left label-text"><Translate text={rec} /></td>
                                                                                        <td className="text-left">
                                                                                            {fields[rec] ? moment.unix(fields[rec].seconds).format('DD/MM/YYYY') : '--'}
                                                                                        </td>
                                                                                    </>
                                                                                }
                                                                                else if (rec === 'massageVentilated') {
                                                                                    return <>
                                                                                        <td className="text-left label-text"><Translate text={rec} /></td>
                                                                                        <td className="text-left">
                                                                                            {fields[rec] ? 'YES' : 'NO'}
                                                                                        </td>

                                                                                    </>
                                                                                }
                                                                                else if (rec === 'reconditioningCost') {
                                                                                    return <>
                                                                                        <td className="text-left label-text"><Translate text={rec} /></td>
                                                                                        <td className="text-left">
                                                                                            {fields[rec] ? <NumberFormat style={{ fontSize: 12 }} value={fields[rec]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /> : '--'}
                                                                                        </td>

                                                                                    </>
                                                                                }
                                                                                else if (rec === 'country') {
                                                                                    return <>
                                                                                        <td className="text-left label-text"><Translate text={rec} /></td>
                                                                                        <td className="text-left">
                                                                                            {fields[rec] ? CommonHelper.getNameByCode(dealersettings.countries, fields[rec], '') : '--'}
                                                                                        </td>

                                                                                    </>
                                                                                }
                                                                                else if (rec === 'regionID') {
                                                                                    return <>
                                                                                        <td className="text-left label-text"><Translate text={rec} /></td>
                                                                                        <td className="text-left">
                                                                                            {fields[rec] ? CommonHelper.getNameByCode(dealersettings.regions, fields[rec], '') : '--'}
                                                                                        </td>

                                                                                    </>
                                                                                }
                                                                                else if (rec === 'subregionID') {
                                                                                    return <>
                                                                                        <td className="text-left label-text"><Translate text={rec} /></td>
                                                                                        <td className="text-left">
                                                                                            {fields[rec] ? CommonHelper.getNameByCode(dealersettings.subregions, fields[rec], '') : '--'}
                                                                                        </td>

                                                                                    </>
                                                                                }
                                                                                else if (rec === 'groupID') {
                                                                                    return <>
                                                                                        <td className="text-left label-text"><Translate text={rec} /></td>
                                                                                        <td className="text-left">
                                                                                            {fields[rec] ? CommonHelper.getNameByCode(dealersettings.groups, fields[rec], '') : '--'}
                                                                                        </td>

                                                                                    </>
                                                                                }
                                                                                else if (rec === 'clientID') {
                                                                                    return <>
                                                                                        <td className="text-left label-text"><Translate text={rec} /></td>
                                                                                        <td className="text-left">
                                                                                            {fields[rec] ? CommonHelper.getOtherDealerName(dealersettings, fields[rec], '') : '--'}
                                                                                        </td>

                                                                                    </>
                                                                                }
                                                                                else if (objFleetPro.hasOwnProperty(rec)) {
                                                                                    return <>
                                                                                        <td className="text-left label-text"><Translate text={rec} /></td>
                                                                                        <td className="text-left">
                                                                                            {fields[rec] ? fields[rec] : '--'}
                                                                                        </td>

                                                                                    </>
                                                                                }
                                                                                else if (!_.isEmpty(fleetOptionsDF) && !_.isEmpty(fleetOptionsDF.filter(m => m.active === true && m.value === rec)[0])) {
                                                                                    let info = fleetOptionsDF.filter(m => m.active === true && m.value === rec)[0];
                                                                                    if (info.type === 'price')
                                                                                        return <>
                                                                                            <td className="text-left label-text">{info.name}</td>
                                                                                            <td className="text-left">
                                                                                                {fields?.dynamicFields && fields?.dynamicFields[rec] ? <NumberFormat style={{ fontSize: 12 }} value={fields?.dynamicFields[rec]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={info.type === 'price' ? currencySymbol : ''} /> : '--'}
                                                                                            </td>
                                                                                        </>
                                                                                    else if (info.type === 'toggle')
                                                                                        return <>
                                                                                            <td className="text-left label-text">{info.name}</td>
                                                                                            <td className="text-left">
                                                                                                {fields?.dynamicFields && fields?.dynamicFields[rec] ? 'YES' : 'NO'}
                                                                                            </td>
                                                                                        </>
                                                                                    else if (info.type === 'date')
                                                                                        return <>
                                                                                            <td className="text-left label-text">{info.name}</td>
                                                                                            <td className="text-left">
                                                                                                {fields?.dynamicFields && fields.dynamicFields[rec] ? moment.unix(fields.dynamicFields[rec].seconds).format('DD/MM/YYYY') : ''}
                                                                                            </td>
                                                                                        </>

                                                                                    else if (info.type === 'multiselect')
                                                                                        return <>
                                                                                            <td className="text-left label-text">{info.name}</td>
                                                                                            <td className="text-left">
                                                                                                {fields?.dynamicFields && fields.dynamicFields[rec] ? fields.dynamicFields[rec].join(', ') : ''}
                                                                                            </td>
                                                                                        </>
                                                                                    else
                                                                                        return <>
                                                                                            <td className="text-left label-text">{info.name}</td>
                                                                                            <td className="text-left">
                                                                                                {fields?.dynamicFields && fields?.dynamicFields[rec] ? fields?.dynamicFields[rec] : '--'}
                                                                                            </td>

                                                                                        </>
                                                                                }
                                                                            })()}
                                                                        </tr >
                                                                    })
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>

                                    <Accordion defaultActiveKey="1" >


                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey="1" onClick={(e) => {
                                                e.preventDefault()

                                                if (activeVehKey === '1')
                                                    this.handleVehActiveKey(null);
                                                else
                                                    this.handleVehActiveKey('1');
                                            }}
                                            >
                                                <div className="mb-0">

                                                    <div className="cardlink">
                                                        <a onClick={(e) => { e.preventDefault(); }} className={`btn btn-link ${activeVehKey === '1' ? '' : 'collapsed'}`}>
                                                            <i className="ico icon-service-log"></i><Translate text={'Vehicle Log'} />

                                                            <i className="arrow-collapse"></i>
                                                        </a></div>
                                                </div>
                                            </Accordion.Toggle>

                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>
                                                    <div className="info-table">
                                                        {
                                                            !_.isEmpty(fleetProLogs)
                                                                ?
                                                                <>
                                                                    <table className="table-fleetpro-fill">
                                                                        <tbody>
                                                                            {
                                                                                fleetProLogs.map((log, index) => {
                                                                                    return <tr key={index} style={{ backgroundColor: `${log.color}20` }}>
                                                                                        <td className="text-left label-text">{moment.unix(log.logDate.seconds).format("DD-MM-YYYY")}
                                                                                            <div className="fleetpro-log-time"> {moment.unix(log.logDate.seconds).format("h:mm A")}</div>
                                                                                        </td>
                                                                                        <td className="text-left">{log.description || '--'}

                                                                                            <div className="fleetpro-log-user">
                                                                                                <div className="fleetpro-log-user">
                                                                                                    <img src={CommonHelper.getUserImagebyId(_allUser, log.logBy)} alt="" width="50" height="50" className="img-object-fit" />

                                                                                                    {CommonHelper.getUserNamebyId(_allUser, log.logBy)}
                                                                                                </div>
                                                                                            </div>

                                                                                        </td>
                                                                                    </tr>
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                    {
                                                                        this.state.logPagingLoader ? (<div className="pull-left mt-2 w-100"><ContentOverlay active={true} /></div>) :
                                                                            this.state.loghasMore.length > 0 ?
                                                                                (<div className="load-more-button"> <a href="#" onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.setState({ logPagingLoader: true });
                                                                                    this.loadPagingLogs();
                                                                                }}> <Translate text={'Load More'} /><i className="fa fa-angle-down" aria-hidden="true"></i> </a></div>)
                                                                                : (<></>)
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                </>
                                                        }

                                                    </div>

                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>





                                </div>
                            </div>
                        </aside>

                        {/** RIGHT TAB */}
                        <div className="mid-column pb-5">

                            <div className="fleetpro-section-boxloop">
                                <div className="fleetpro-section-boxloop-header ">
                                    <div className="float-left">
                                        <h3 className="fleetpro-section-page-title">  Vehicle History </h3>
                                    </div>

                                </div>
                                <div className="fleetpro-section-page-content pt-0 form-style">

                                    {
                                        (!_.isEmpty(fleetAssigns))
                                            ?
                                            <>
                                                <div className="fleetpro-list-accordion">
                                                    <Accordion className="quickview-related" defaultActiveKey="00">
                                                        {
                                                            _.orderBy(fleetAssigns, ["addedDate"], ["desc"]).map((_assign, index) => {

                                                                return <Card key={index} className={[`0${index}`, _assign.documentID].includes(activeRightKey) ? 'active' : ''}>
                                                                    <>
                                                                        {(() => {

                                                                            let _allInspection = _.union(_assign.inspection, _assign.preInspection);
                                                                            return <>
                                                                                <Accordion.Toggle as={Card.Header} eventKey={`0${index}`} onClick={(e) => {
                                                                                    e.preventDefault()

                                                                                    if ([`0${index}`, _assign.documentID].includes(activeRightKey))
                                                                                        this.handleRightActiveKey(null);
                                                                                    else
                                                                                        this.handleRightActiveKey(`0${index}`);
                                                                                }}>
                                                                                    <div className="mb-0" id={`recomm_${_assign.documentID}`}>

                                                                                        <div className="fleetpro-view-button">
                                                                                            <div className="fleetpro-white-btn">
                                                                                                <button className="btn btn-sm btn-primary"
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        e.stopPropagation();
                                                                                                        this.setState({
                                                                                                            fleetAssignModal: {
                                                                                                                show: true,
                                                                                                                docID: _assign.documentID
                                                                                                            }
                                                                                                        })
                                                                                                    }} ><i className="ico icon-expand"></i>View </button>
                                                                                            </div>

                                                                                            {/* <div className="fleetpro-white-btn">
                                                                                                <button className="btn btn-sm btn-secondary"
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        e.stopPropagation();
                                                                                                        window.open(`/fleetpro/confirmation/${_assign.documentID}`, '_blank')

                                                                                                    }} ><i className="ico icon-expand"></i>Report </button>
                                                                                            </div> */}

                                                                                        </div>

                                                                                        <div className="cardlink">
                                                                                            <a
                                                                                                onClick={(e) => { e.preventDefault(); }}
                                                                                                className={`btn btn-link ${[`0${index}`, _assign.documentID].includes(activeRightKey) ? '' : 'collapsed'}`}
                                                                                            >
                                                                                                <div className="recom-list-head">

                                                                                                    {
                                                                                                        _assign?.assignType === fleetAssignTypes.RetailerEvent && _assign?.clientID ?
                                                                                                            <><i className="ico icon-dealers"></i>{CommonHelper.getOtherDealerName(dealersettings, _assign.clientID)}</>
                                                                                                            :
                                                                                                            <>
                                                                                                                {
                                                                                                                    _assign?.assignType ?
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                _assign?.assignType === fleetAssignTypes.LoanVehicle
                                                                                                                                    ?
                                                                                                                                    <i className="ico icon-module-loan"></i>
                                                                                                                                    :
                                                                                                                                    <>
                                                                                                                                        {
                                                                                                                                            _assign?.assignType === fleetAssignTypes.ExternalInternalEvent
                                                                                                                                                ?
                                                                                                                                                <i className="ico icon-events-module-fill"></i>
                                                                                                                                                :
                                                                                                                                                <i className="ico icon-dealers"></i>
                                                                                                                                        }
                                                                                                                                    </>
                                                                                                                            }

                                                                                                                            {CommonHelper.getNameByValue(defaultfleetAssignTypes, _assign.assignType)}</>
                                                                                                                        :
                                                                                                                        <>
                                                                                                                        </>
                                                                                                                }
                                                                                                            </>
                                                                                                    }

                                                                                                    {_assign.status ?
                                                                                                        <div className="float-right kitchen-modal-status ml-2" onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            e.stopPropagation();
                                                                                                            window.open(`/fleetpro/confirmation/${_assign.documentID}`, '_blank')

                                                                                                        }}>
                                                                                                            <div className={`badge badge-pill badge-status-${_assign.status.trim().toLowerCase()} `}>
                                                                                                                {_assign.status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
                                                                                                            </div></div>
                                                                                                        : <></>}
                                                                                                    {_assign.startDate ? <div className="fleetpro-white-badge">
                                                                                                        <div className="badge badge-pill badge-fleetpro-white ml-1">
                                                                                                            <i className="ico icon-date"></i>{CommonHelper.showCombineDate(_assign.startDate, ([fleetAssignTypes.LoanVehicle].includes(_assign.assignType) && _assign.status !== fleetAssignStatus.RELEASED ? _assign.expectedReturnDate : _assign.endDate), _assign.timezone)}
                                                                                                        </div>
                                                                                                    </div> : <></>}

                                                                                                    {/* {_assign.eventType && _assign?.assignType === fleetAssignTypes.ExternalInternalEvent ? <div className="fleetpro-white-badge">
                                                                                                        <div title='Event Type' className="badge badge-pill badge-fleetpro-white ml-1">
                                                                                                            {_assign.eventType}
                                                                                                        </div>
                                                                                                    </div> : <></>} */}

                                                                                                </div>
                                                                                                <i className="arrow-collapse"></i>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </Accordion.Toggle>

                                                                                <Accordion.Collapse eventKey={`0${index}`}>
                                                                                    <Card.Body>

                                                                                        <div className="fleetpro-accordion-innerhead" >
                                                                                            <h3>
                                                                                                {_assign.eventName ? <> <i className="ico icon-events-event mr-2"></i>{_assign.eventName}</> : <></>}
                                                                                                {_assign.eventType && _assign?.assignType === fleetAssignTypes.ExternalInternalEvent && (<div title='Event Type' className="fleetpro-eventtype-tag">
                                                                                                    <div className="badge badge-pill badge-input-white mr-1">
                                                                                                        {_assign.eventType}
                                                                                                    </div>
                                                                                                </div>)}
                                                                                            </h3>

                                                                                            <div className="events-info-card-sub">
                                                                                                {_assign.startDate ? <>
                                                                                                    <i className="ico icon-date mr-2"></i>{CommonHelper.showCombineDate(_assign.startDate, ([fleetAssignTypes.LoanVehicle].includes(_assign.assignType) && _assign.status !== fleetAssignStatus.RELEASED ? _assign.expectedReturnDate : _assign.endDate), _assign.timezone)}
                                                                                                    <i className="ico icon-time ml-2 mr-2"></i> {CommonHelper.showCombineTime(_assign.startDate, ([fleetAssignTypes.LoanVehicle].includes(_assign.assignType) && _assign.status !== fleetAssignStatus.RELEASED ? _assign.expectedReturnDate : _assign.endDate), _assign.timezone, null)}</> : <></>}

                                                                                                {_assign.purpose && (<> <i className="ico icon-files-document ml-2 mr-2" title='Purpose'></i> {_assign.purpose}</>)}
                                                                                                {_assign.odometer ? (<> <i className="ico icon-odometer-ico ml-2 mr-2" title="Odometer"></i> <NumberFormat style={{ fontSize: 12 }} value={_assign.odometer} displayType={'text'} thousandSeparator={true} suffix={' km'} /></>) : (<></>)}
                                                                                                {_assign.location && (<> <i className="ico icon-location ml-2 mr-2" title='Location'></i> {_assign.location}</>)}

                                                                                            </div>


                                                                                            <div className="fleetpro-accordion-approve-bg">
                                                                                                <div className="fleetpro-accordion-approve">

                                                                                                    {_assign.requestedBy && (<span>
                                                                                                        Requested By: <b>{CommonHelper.getUserNamebyId(_allUser, _assign.requestedBy)}</b>
                                                                                                        &nbsp;on&nbsp; <b>{_assign.requestedDate ? moment.unix(_assign.requestedDate.seconds).format('DD/MM/YYYY') : '--'}</b>
                                                                                                    </span>)}

                                                                                                    {_assign.assignBy && (<span>
                                                                                                        Assign By: <b>{CommonHelper.getUserNamebyId(_allUser, _assign.assignBy)}</b>
                                                                                                        &nbsp;on&nbsp; <b>{_assign.assignDate ? moment.unix(_assign.assignDate.seconds).format('DD/MM/YYYY') : '--'}</b>
                                                                                                    </span>)}

                                                                                                    {_assign.approvedBy && (<span>
                                                                                                        Approved By: <b>{CommonHelper.getUserNamebyId(_allUser, _assign.approvedBy)}</b>
                                                                                                        &nbsp;on&nbsp; <b>{_assign.approvedDate ? moment.unix(_assign.approvedDate.seconds).format('DD/MM/YYYY') : '--'}</b>
                                                                                                    </span>)}

                                                                                                    {_assign.status === fleetAssignStatus.DECLINED && (<span>
                                                                                                        Declined By: <b>{CommonHelper.getUserNamebyId(_allUser, _assign.modifiedBy)}</b>
                                                                                                        &nbsp;on&nbsp; <b>{_assign.modifiedDate ? moment.unix(_assign.modifiedDate.seconds).format('DD/MM/YYYY') : '--'}</b>
                                                                                                    </span>)}

                                                                                                    {_assign.status === fleetAssignStatus.CANCELLED && (<span>
                                                                                                        Cancelled By: <b>{CommonHelper.getUserNamebyId(_allUser, _assign.modifiedBy)}</b>
                                                                                                        &nbsp;on&nbsp; <b>{_assign.modifiedDate ? moment.unix(_assign.modifiedDate.seconds).format('DD/MM/YYYY') : '--'}</b>
                                                                                                    </span>)}
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>

                                                                                        {(_assign.name || _assign.phone || _assign.email) && (<div className="fleetpro-accordion-responsibleinfo">

                                                                                            <div className="fleetpro-responsibleinfo-head">Responsible Person</div>
                                                                                            <div className="fleetpro-responsibleinfo-list">
                                                                                                <ul>
                                                                                                    {
                                                                                                        _assign.name && (<li> <i className="ico icon-Contacts"></i> {_assign.name}</li>)
                                                                                                    }
                                                                                                    {
                                                                                                        _assign.phone && (<li> <i className="ico icon-call"></i> {_assign.phone}</li>)
                                                                                                    }
                                                                                                    {
                                                                                                        _assign.email && (<li> <i className="ico icon-email"></i> {_assign.email}</li>)
                                                                                                    }
                                                                                                    {
                                                                                                        _assign.companyName && (<li> <i className="ico icon-company"></i> {_assign.companyName}</li>)
                                                                                                    }
                                                                                                    {
                                                                                                        _assign.address && (<li> <i className="ico icon-location"></i> {_assign.address}</li>)
                                                                                                    }
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>)
                                                                                        }

                                                                                        {([fleetAssignTypes.LoanVehicle].includes(_assign?.assignType) && (_assign?.recipientName || _assign?.recipientPhone || _assign?.recipientEmail || _assign?.recipientLicenseNo || _assign?.recipientLicenseExpiry)) && (<div className="fleetpro-accordion-responsibleinfo">

                                                                                            <div className="fleetpro-responsibleinfo-head"><Translate text={'Recipient Details'} /></div>
                                                                                            <div className="fleetpro-responsibleinfo-list">
                                                                                                <ul>
                                                                                                    {
                                                                                                        _assign?.recipientName && (<li> <i className="ico icon-Contacts"></i> {_assign.recipientName}</li>)
                                                                                                    }
                                                                                                    {
                                                                                                        _assign?.recipientPhone && (<li> <i className="ico icon-call"></i> {_assign.recipientPhone}</li>)
                                                                                                    }
                                                                                                    {
                                                                                                        _assign?.recipientEmail && (<li> <i className="ico icon-email"></i> {_assign.recipientEmail}</li>)
                                                                                                    }
                                                                                                    {
                                                                                                        _assign?.recipientAddress && (<li> <i className="ico icon-location"></i> {_assign.recipientAddress}</li>)
                                                                                                    }
                                                                                                    {
                                                                                                        _assign?.recipientLicenseNo && (<li> <i className="ico icon-billing"></i> {_assign.recipientLicenseNo}</li>)
                                                                                                    }
                                                                                                    {
                                                                                                        _assign?.recipientLicenseExpiry && (<li> <i className="ico icon-dynamic-datefield"></i> {_assign.recipientLicenseExpiry}</li>)
                                                                                                    }
                                                                                                </ul>
                                                                                                <div className="fleetpro-responsibleinfo-licencewrap">
                                                                                                    <div className="fleetpro-responsibleinfo-licencelabel">Licence</div>
                                                                                                    <div className='license-box'>
                                                                                                        {_assign?.recipientLicenseFront ? (
                                                                                                            <> <a
                                                                                                                data-fancybox={`${_assign.documentID}-license-assign-box`}
                                                                                                                href={_assign.recipientLicenseFront}
                                                                                                                onClick={e => { e.preventDefault(); }}
                                                                                                            >
                                                                                                                <img src={_assign.recipientLicenseFront} alt='' className="img-object-fit" />{' '}
                                                                                                            </a>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <a
                                                                                                                href='#'
                                                                                                                onClick={e => { e.preventDefault(); }}
                                                                                                            >
                                                                                                                <img src={_images.nolicense} alt='' />{' '}
                                                                                                            </a>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className='license-box'>
                                                                                                        {_assign?.recipientLicenseBack ? (
                                                                                                            <>
                                                                                                                <a
                                                                                                                    data-fancybox={`${_assign.documentID}license-assign-box`}
                                                                                                                    href={_assign.recipientLicenseBack}
                                                                                                                    onClick={e => { e.preventDefault(); }}
                                                                                                                >
                                                                                                                    <img src={_assign.recipientLicenseBack} alt='' className="img-object-fit" />{' '}
                                                                                                                </a>
                                                                                                            </>

                                                                                                        ) : (
                                                                                                            <a
                                                                                                                href='#'
                                                                                                                onClick={e => { e.preventDefault(); }}
                                                                                                            >
                                                                                                                <img src={_images.nolicense} alt='' />{' '}
                                                                                                            </a>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>)
                                                                                        }

                                                                                        <div className="fleetpro-transit-wrap">
                                                                                            {/* <div className="fleetpro-transit-head">Transit Details</div> */}
                                                                                            <div className="fleetpro-transit-info">
                                                                                                <div className="fleetpro-transit-info-left ">
                                                                                                    <table>
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td className="label-fleetpro" >MAL Technical Support Required?</td>
                                                                                                                <td><span className="fleetpro-info">{_assign?.malTechnicalSupportReq ? 'Yes' : 'No'}</span></td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                                {
                                                                                                    _assign.notes && (<div className="fleetpro-transit-info-right">
                                                                                                        <table>
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td className="label-fleetpro" >Notes</td>
                                                                                                                    <td><span className="fleetpro-info">{_assign.notes ? _assign.notes : '--'}</span></td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>)
                                                                                                }

                                                                                            </div>
                                                                                        </div>
                                                                                        {/* { // 17-09-24
                                                                                            [fleetAssignTypes.RetailerEvent].includes(_assign?.assignType) && (<div className="fleetpro-transit-wrap">

                                                                                                <div className="fleetpro-transit-head">Transit Details</div>
                                                                                                <div className="fleetpro-transit-info">
                                                                                                    <div className="fleetpro-transit-info-left ">
                                                                                                        <table>
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td className="label-fleetpro">Method of Transport</td>
                                                                                                                    <td><span className="fleetpro-info">{CommonHelper.getNameByValue(dealersettings.client?.fleetPro?.methodOfTransport, _assign?.methodOfTransport, '--')}</span></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td className="label-fleetpro" >Transport Company</td>
                                                                                                                    <td><span className="fleetpro-info">{CommonHelper.getNameByValue(dealersettings.client?.fleetPro?.transportCompany, _assign?.transportCompany, '--')}</span></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td className="label-fleetpro" >MAL Technical Support Required?</td>
                                                                                                                    <td><span className="fleetpro-info">{_assign?.malTechnicalSupportReq ? 'Yes' : 'No'}</span></td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>

                                                                                                    <div className="fleetpro-transit-info-right">
                                                                                                        <table>
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td className="label-fleetpro">Departure</td>
                                                                                                                    <td><span className="fleetpro-info">{_assign.departureDate ? moment.unix(_assign.departureDate.seconds).format('DD/MM/YYYY') : '--'}</span></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td className="label-fleetpro">Expected Arrival</td>
                                                                                                                    <td><span className="fleetpro-info">{_assign.expectedArrivalDate ? moment.unix(_assign.expectedArrivalDate.seconds).format('DD/MM/YYYY') : '--'}</span></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td className="label-fleetpro" >Expected Return</td>
                                                                                                                    <td><span className="fleetpro-info">{_assign.expectedReturnDate ? moment.unix(_assign.expectedReturnDate.seconds).format('DD/MM/YYYY') : '--'}</span></td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>)} */}


                                                                                        <div className="fleetpro-accordion-innersection">
                                                                                            <div className="fleetpro-vehicle-info-outer">
                                                                                                <div className="fleetpro-info-left">
                                                                                                    <span className="heading mt-0">Received Info</span>
                                                                                                    <div className="fleetpro-info-table">
                                                                                                        <table>
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td>Received Odometer</td>
                                                                                                                    <td><span className="fleetpro-info">{_assign.preOdometer ? <NumberFormat style={{ fontSize: 12 }} value={_assign.preOdometer} displayType={'text'} thousandSeparator={true} suffix={' km'} /> : <span>{'--'}</span>}</span></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Received Date</td>
                                                                                                                    <td><span className="fleetpro-info">{_assign.receivedDate ? moment.unix(_assign.receivedDate.seconds).format('DD/MM/YYYY') : '--'}</span></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Received By</td>
                                                                                                                    <td><span className="fleetpro-info">{CommonHelper.getUserNamebyId(_allUser, _assign.receivedBy, (_assign.receivedByName || '--'))}</span></td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>

                                                                                                    <div className="fleetpro-history-img-wrap">
                                                                                                        {
                                                                                                            !_.isEmpty(_allInspection) && !_.isEmpty(_allInspection.filter(a => !_.isEmpty(a.images))[0])
                                                                                                                ?
                                                                                                                _allInspection.map((mark, index) => {
                                                                                                                    return <div key={index}>
                                                                                                                        {!_.isEmpty(mark.images) && mark.images.map((data, _index) => (
                                                                                                                            <a key={_index}
                                                                                                                                data-fancybox={`testdrive-before-${_assign.documentID}`}
                                                                                                                                className="fleetpro-history-item-image" href={data} onClick={(e) => { e.preventDefault(); }}>
                                                                                                                                <img id={`testdrive-before-${index}-${_index}-${_assign.documentID}`} src={data} className="img-object-fit" width="70" height="70" />
                                                                                                                            </a>
                                                                                                                        ))}
                                                                                                                    </div>
                                                                                                                })
                                                                                                                :
                                                                                                                <>
                                                                                                                    <div className="fleetpro-history-item-image">
                                                                                                                        <img id={`testdrive-before-${_assign.documentID}`} src={_images[CommonHelper.getNoVehicleIcons(dealersettings?.client?.category, 'novehicle')]} width="78" />
                                                                                                                    </div>
                                                                                                                </>
                                                                                                        }
                                                                                                    </div>

                                                                                                    <div className="fleetpro-action-btn-wrap">

                                                                                                        <button className="btn btn-sm btn-default  ml-1" onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            this.setState({ showInspection: { show: true, marks: _allInspection } })
                                                                                                        }}>
                                                                                                            {!_.isEmpty(_allInspection) && (<div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div>)}
                                                                                                            <i className="ico icon-inspection mr-1"></i> <Translate text={'inspection'} />
                                                                                                        </button>
                                                                                                        <button className="btn btn-sm btn-default  ml-1" onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            this.setState({ showTerms: { show: true, marks: _assign.terms } })
                                                                                                        }}>
                                                                                                            {!_.isEmpty(_assign.terms) && (<div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div>)}
                                                                                                            <i className="ico icon-terms mr-1"></i> <Translate text={'termsAndConditions'} />
                                                                                                        </button>
                                                                                                        <button className="btn btn-sm btn-default  ml-1" onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            this.setState({ showSignature: { show: true, id: 'preSignature', sign: _assign.preSignature, isReadOnly: true } })
                                                                                                        }}>
                                                                                                            {!_.isEmpty(_assign.preSignature) && (<div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div>)}
                                                                                                            <i className="ico icon-sign mr-1"></i> <Translate text={'signature'} />
                                                                                                        </button>
                                                                                                        <button className="btn btn-sm btn-default  ml-1" onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            this.setState({ notesPopOver: { showpopover: true, targetpopover: e.target, notes: _assign.preNotes } })
                                                                                                        }}>
                                                                                                            {(_assign.preNotes) && (<div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div>)}
                                                                                                            <i className="ico icon-service-notes mr-1"></i> Notes
                                                                                                        </button>

                                                                                                    </div>




                                                                                                </div>
                                                                                                <div className="fleetpro-info-right">
                                                                                                    <span className="heading mt-0">Released Info</span>
                                                                                                    <div className="fleetpro-info-table">
                                                                                                        <table>
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td>Released Odometer</td>
                                                                                                                    <td><span className="fleetpro-info">{_assign.postOdometer ? <NumberFormat style={{ fontSize: 12 }} value={_assign.postOdometer} displayType={'text'} thousandSeparator={true} suffix={' km'} /> : <span>{'--'}</span>}</span></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Released Date</td>
                                                                                                                    <td><span className="fleetpro-info">{_assign.releasedDate ? moment.unix(_assign.releasedDate.seconds).format('DD/MM/YYYY') : '--'}</span></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Released By</td>
                                                                                                                    <td><span className="fleetpro-info">{CommonHelper.getUserNamebyId(_allUser, _assign.releasedBy, (_assign.releasedByName || '--'))}</span></td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                    <div className="fleetpro-history-img-wrap">

                                                                                                        {
                                                                                                            !_.isEmpty(_assign.postInspection) && !_.isEmpty(_assign.postInspection.filter(a => !_.isEmpty(a.images))[0])
                                                                                                                ?
                                                                                                                _assign.postInspection.map((mark, index) => {
                                                                                                                    return <div key={index}>
                                                                                                                        {!_.isEmpty(mark.images) && mark.images.map((data, _index) => (
                                                                                                                            <a key={_index}
                                                                                                                                data-fancybox={`testdrive-after-${_assign.documentID}`}
                                                                                                                                className="fleetpro-history-item-image" href={data} onClick={(e) => { e.preventDefault(); }}>
                                                                                                                                <img id={`testdrive-after-${index}-${_index}-${_assign.documentID}`} src={data} className="img-object-fit" width="70" height="70" />
                                                                                                                            </a>
                                                                                                                        ))}
                                                                                                                    </div>
                                                                                                                })
                                                                                                                :
                                                                                                                <>
                                                                                                                    <div className="fleetpro-history-item-image">
                                                                                                                        <img id={`testdrive-after-${_assign.documentID}`} src={_images[CommonHelper.getNoVehicleIcons(dealersettings?.client?.category, 'novehicle')]} width="78" />
                                                                                                                    </div>
                                                                                                                </>
                                                                                                        }




                                                                                                    </div>

                                                                                                    <div className="fleetpro-action-btn-wrap">

                                                                                                        <button className="btn btn-sm btn-default  ml-1" onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            this.setState({ showInspection: { show: true, marks: _assign.postInspection } })
                                                                                                        }}>
                                                                                                            {!_.isEmpty(_assign.postInspection) && (<div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div>)}
                                                                                                            <i className="ico icon-inspection mr-1"></i> <Translate text={'inspection'} />
                                                                                                        </button>
                                                                                                        <button className="btn btn-sm btn-default  ml-1" onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            this.setState({ showSignature: { show: true, id: 'postSignature', sign: _assign.postSignature, isReadOnly: true } })
                                                                                                        }}>
                                                                                                            {!_.isEmpty(_assign.postSignature) && (<div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div>)}
                                                                                                            <i className="ico icon-sign mr-1"></i> <Translate text={'signature'} />
                                                                                                        </button>
                                                                                                        <button className="btn btn-sm btn-default  ml-1" onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            this.setState({ notesPopOver: { showpopover: true, targetpopover: e.target, notes: _assign.postNotes } })
                                                                                                        }}>
                                                                                                            {!_.isEmpty(_assign.postNotes) && (<div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div>)}
                                                                                                            <i className="ico icon-service-notes mr-1"></i> Notes
                                                                                                        </button>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>


                                                                                        </div>

                                                                                    </Card.Body>
                                                                                </Accordion.Collapse>
                                                                            </>
                                                                        })()}
                                                                    </>


                                                                </Card>
                                                            })
                                                        }
                                                    </Accordion>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="container d-flex h-100">
                                                    <div className="row justify-content-center align-self-center w-100">
                                                        <div className="text-center p-4">
                                                            <span><img src={_images.nodata} alt="" width="50" /></span>
                                                            <p>No History Available</p>

                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="spinner-loader">
                                                    <div className="no-cafe-flex justify-content-center align-items-center ">
                                                        <div className="no-cafe-img">
                                                            <i className="ico icon-service-recommendation"></i> </div>
                                                    </div>
                                                </div> */}
                                            </>
                                    }

                                </div>
                            </div>







                        </div>
                    </div>



                </div>
                <PopUpModal show={showInspection.show}>
                    <ImageCanvas
                        show={showInspection.show}
                        clsActive='overlay-modal active'
                        imagePath='fleetProAssign'
                        marks={showInspection.marks}
                        handleClose={() => {
                            this.setState({
                                showInspection: {
                                    show: false,
                                    marks: null
                                }
                            })
                        }}
                        isReadOnly={true}
                        dealersettings={dealersettings}
                        showCost={false}
                    ></ImageCanvas>
                </PopUpModal>

                <PopUpModal show={showTerms.show}>
                    <Terms
                        show={showTerms.show}
                        clsActive='overlay-modal active'
                        imagePath='fleetProAssign'
                        terms={showTerms.terms}
                        //termsandCondition={CommonHelper.getTermsByField(dealersettings?.client, 'fleetTC', contact?.language)}
                        title={'termsAndConditions'}
                        isDisable={true}
                        handleClose={() => {
                            this.setState({
                                showTerms: {
                                    show: false,
                                    terms: null
                                }
                            })
                        }}
                        dealersettings={dealersettings}

                    ></Terms>
                </PopUpModal>

                <PopoverPanel
                    showpopover={notesPopOver.showpopover}
                    targetpopover={notesPopOver.targetpopover}
                    title="Notes"
                    position="top"
                    id="notes-fleet-assign"
                    className="popover-pipeline-switch"
                    closepopover={() => {
                        this.setState({
                            notesPopOver: { showpopover: false, targetpopover: '', notes: '' }
                        });
                    }}>
                    <AddNotes
                        notes={notesPopOver.notes}
                        handleClose={() => {
                            this.setState({
                                notesPopOver: { showpopover: false, targetpopover: '', notes: '' }
                            });
                        }}
                        isDisable={true}
                    />
                </PopoverPanel>

                <PopUpModal show={fleetModal.show}>
                    <AddFleet
                        {...this.props}
                        docID={fleetModal.docID}
                        show={fleetModal.show}
                        clsActive='overlay-modal active'
                        handleClose={() => {
                            this.setState({
                                fleetModal: {
                                    show: false,
                                    docID: null
                                }
                            })
                        }}
                        title={fleetModal.docID ? 'fleet' : 'addFleet'}
                    >
                    </AddFleet>
                </PopUpModal>
                <PopUpModal show={fleetAssignModal.show}>
                    <AssignFleet
                        {...this.props}
                        docID={fleetAssignModal.docID}
                        fleetProID={this.state.fleetProID}
                        fleetPro={this.state.fields}
                        show={fleetAssignModal.show}
                        clsActive='overlay-modal active'
                        handleClose={() => {
                            this.setState({
                                fleetAssignModal: {
                                    show: false,
                                    docID: null
                                }
                            })
                        }}
                        title={fleetAssignModal.docID ? 'fleet' : 'assignFleet'}
                    >
                    </AssignFleet>
                </PopUpModal>
                <PopUpModal show={bookingsModal.show}>
                    <VehicleBookings
                        {...this.props}
                        {...bookingsModal}
                        handleClose={() => {
                            this.setState({
                                bookingsModal: {
                                    show: false,
                                    docID: null
                                }
                            })
                        }}
                    />
                </PopUpModal>

                <PopUpModal show={showSignature.show}>
                    <AfterSignature
                        show={showSignature.show}
                        signatureURL={showSignature.sign}
                        fieldID={showSignature.id}
                        isDisable={true}
                        imagePath='fleetProAssign'
                        clsActive='overlay-modal active'
                        handleClose={() => { this.setState({ showSignature: { show: false, id: null, sign: null, isReadOnly: false } }) }}
                        dealersettings={dealersettings}
                    ></AfterSignature>
                </PopUpModal>

            </>
        ) : this.state.pagenotFound ? (
            <PageNotFound />
        ) : (
            <LoaderOverlay text='Fetching fleet details...' active={true} />
        );
    }
}


