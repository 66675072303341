import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import toast from 'toasted-notes'
import moment from 'moment'
import { ContentOverlay, TableView, PopUpModal, ReactSelect, ReactMultiSelect } from '../../../../components'
import { usersVM, userlistFields, levelSubOptions } from './viewModel'
import { default as _images } from "../../../../images";
import AddUser from './addUser'
import { ClientContext } from '../clientContext'
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';
import MergeModal from './mergeModal';
import Swal from 'sweetalert2';
import common from '../../../../services/common';
import { firestoreDB } from '../../../../services/helper';
import ExportUser from './exportUser';

const UserList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 315) })
    const [userList, setUserList] = useState([])
    const [checkDataLoad, setDataload] = useState(true)
    const [searchText, setSearchText] = useState('')
    const [loader, setLoader] = useState(true)
    const [isPaging, setPaging] = useState(false)
    const [hasMore, setHasMoreData] = useState([])
    const [showuserModel, setShowUserModel] = useState(false)
    const [popHeader, setPopHeader] = useState('')
    const [selectedUser, setSelecteduser] = useState(null)
    const [clientOptions, setClientOptions] = useState([]);
    const [selectedClient, setSelectedClient] = useState('')
    const [showtargetModel, setShowTargetModel] = useState(false)
    const [showMergeModal, setShowMergeModal] = useState({
        show: false,
        id: null,
    })
    const [showExportUser, setShowExportUser] = useState({ show: false })
    const [filter, setFilter] = useState(true)
    const [objFilter, setobjFilter] = useState({})
    const { clients } = useContext(ClientContext);
    let userlevelindex = _.find(levelSubOptions, { value: props.dealersettings.level }) ? _.find(levelSubOptions, { value: props.dealersettings.level }).index : 1;
    const _permissions = props?.dealersettings?.rolePermissions?.permissions;
    const accessToExportUser = ((!_.isEmpty(_permissions) && _permissions.accessToExportUser) ? true : false);
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
                windowHeight: (window.innerHeight - 315)
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubAdminUserList && window.unSubAdminUserList();
        }
    }, [])

    useEffect(() => {
        if (!_.isEmpty(clients)) {
            let _clients = [];
            _.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(_client => {
                _clients.push({ label: _client.name, value: _client.documentID, data: _client, active: _.isBoolean(_client.active) ? _client.active : true })
            });
            setClientOptions(_clients)
            // if (_.isEmpty(selectedClient)) {
            //     setSelectedClient(_.find(clients, { 'documentID': props.dealersettings.client.id }))
            // }
        }
    }, [clients])

    useEffect(() => {
        //if (!_.isEmpty(selectedClient)) {
        window.unSubAdminUserList = window.firebase.firestore().collection('users')
            .where('settingsID', '==', (localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.settingsID))
            .onSnapshot(querySnapshot => {
                let _users = [];
                querySnapshot.docs.forEach(doc => {
                    const _userdoc = {
                        ...doc.data(),
                        documentID: doc.id
                    };
                    let listlevelindex = _.find(levelSubOptions, { value: _userdoc.level }) ? _.find(levelSubOptions, { value: _userdoc.level }).index : 1;
                    if (userlevelindex <= listlevelindex && _userdoc.arrayClientID && _userdoc.arrayClientID.length > 0 && common.arrayContainsArray(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), _userdoc.arrayClientID)) {
                        _users.push(convertVM(_userdoc))
                    }
                    else if (_.isEmpty(_userdoc.arrayClientID) && !_.isEmpty(_userdoc.settingsID)) {
                        _users.push(convertVM(_userdoc))
                    }

                })
                // console.log('these are users', _users)
                setUserList(_users.filter(a => !Boolean(a.superAdmin)))
                setLoader(false)
            });
        //}
    }, [])

    const convertVM = (doc) => {
        const objuserData = Object.assign({}, doc);
        const listVM = Object.assign({}, usersVM);
        for (let [key, value] of Object.entries(objuserData)) {
            if (listVM.hasOwnProperty(key))
                listVM[key] = value;
        }

        if (doc.isDisabled !== true && doc.isDisabled !== false) {
            listVM.isDisabled = !false
        } else {
            listVM.isDisabled = !doc.isDisabled
        }
        listVM.disabledUser = doc.isDisabled;
        listVM.userName = <><div className="table-list-users"><img src={CommonHelper.showUserAvatar(doc.profileImage, listVM.name)} alt="" className="img-object-fit" /></div> {listVM.name}</>
        listVM.roleName = <>{doc.role}<br /><span className="user-role-span ml-0">{_.find(levelSubOptions, { value: doc.level }) ? _.find(levelSubOptions, { value: doc.level }).label : doc.level}</span></>;
        listVM.lastlogin = doc.lastLoginTime ? moment.unix(doc.lastLoginTime.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.lastloginSec = doc.lastLoginTime ? doc.lastLoginTime.seconds : 0;
        listVM.status = doc.tokkens && doc.tokkens.length > 0 && !Boolean(doc.isDisabled) && doc.lastLoginTime ?
            (<div className="badge badge-pill badge-online ml-1"> <Translate text={'Online'} /></div>)
            : (<div className="badge badge-pill badge-offline ml-1"> <Translate text={'Offline'} /></div>);
        listVM.statusValue = doc.tokkens && doc.tokkens.length > 0 ? 'online' : 'offline';
        listVM.superAdmin = doc.superAdmin;
        if (_.isEmpty(doc.arrayRegionID) && !_.isEmpty(doc.regionID)) {
            listVM.arrayRegionID = [doc.regionID]
        }
        if (_.isEmpty(doc.arrayGroupID) && !_.isEmpty(doc.groupID)) {
            listVM.arrayGroupID = [doc.groupID]
        }

        if (!_.isEmpty(doc.dmsKeys) && _.isArray(doc.dmsKeys)) {
            listVM.dmsTags = (<div className="Vehicle-availability text-capitalize mt-1">                {
                doc.dmsKeys.map((rec, index) => {
                    return <div title={rec.value || ''} key={index}><span>{rec.type}</span></div>
                })
            }
            </div>);
        }
        return listVM
    }

    const hadleEditUser = (id) => {
        const editUser = userList.find(item => item.documentID === id);
        const userVM = Object.assign({}, usersVM);
        for (let [key, value] of Object.entries(editUser)) {
            if (userVM[key] !== undefined)
                userVM[key] = value;
        }
        setSelecteduser(userVM)
        setPopHeader('Edit User')
        setShowUserModel(true)
    }

    const hadleDeleteUser = (id, data) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, `Do you want to delete this user?`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(async (result) => {
            if (result.value) {
                try {
                    const deleteUser = window.firebase.functions().httpsCallable('amsadmin-deleteUser');
                    const resp = await deleteUser({
                        "uid": id,
                    })
                    if (resp && resp.data && resp.data.success) {
                        try {
                            const logRef = window.firebase.firestore().collection('auditLogs')
                            const auditLog = {
                                clientID: props.dealersettings.client.id ? props.dealersettings.client.id : null,
                                documentID: logRef.doc().id,
                                groupID: props.dealersettings.groupID ? props.dealersettings.groupID : null,
                                imageURL: null,
                                logBy: localStorage.uid,
                                logDate: window.firebase.firestore.Timestamp.now(),
                                notes: `${data?.name || ''} User deleted`,
                                oemID: props.dealersettings.oemID ? props.dealersettings.oemID : null,
                                platform: 'web',
                                recordID: localStorage.browserKey ? localStorage.browserKey : null,
                                regionID: props.dealersettings.regionID ? props.dealersettings.regionID : null,
                                subtype: 'enable',
                                type: 'authentication',
                            }
                            logRef.doc(auditLog.documentID).set(auditLog, { merge: true })
                        } catch (err) { }
                        window.firebase.firestore().doc(`users/${id}`).delete()
                            .then(snap => {
                                toast.notify('User deleted successfully', {
                                    duration: 2000
                                })
                            })
                    } else {
                        Swal.fire(resp.data.message, '', 'error')
                        return;
                    }
                } catch (err) {
                    console.log(err)
                    Swal.fire('Something went wrong.', '', 'error')
                    return;
                }
            }
        })
    }

    const handleActionClick = (id, data) => {
        return (
            <div className="action-fix">
                {
                    data.disabledUser === true ? (
                        <div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                            hadleDeleteUser(id, data)
                        }}>
                            <i className="ico icon-delete"></i>
                        </div>
                    ) : (
                        <div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                            hadleEditUser(id)
                        }}>
                            <i className="ico icon-edit"></i>
                        </div>
                    )
                }
            </div>
        )
    }

    const handleUserdetailsClick = (e, id) => {
        e.preventDefault();
        hadleEditUser(id)
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }


    const handleModelClose = () => {
        setSelecteduser(null)
        setShowUserModel(false)
    }
    const handleMergeModalClose = () => {
        setShowMergeModal({
            ...showMergeModal,
            show: false,
            id: null,
        })
        setShowExportUser({
            show: false
        })
    }
    const handleTargetModelClose = () => {
        setSelecteduser(null)
        setShowTargetModel(false)
    }
    const handleAddUser = () => {
        setSelecteduser(null)
        setPopHeader('Add User')
        setShowUserModel(true)
    }

    const hadlesetuserTarget = (user) => {
        setSelecteduser(user)
        setPopHeader('User Targets')
        setShowTargetModel(true)
    }

    const handleSwitchChange = async (id, checked, data) => {
        if (_.isEmpty(data))
            return

        let _checked = !checked;
        // console.log(id, _checked, data)

        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, `Do you want to ${_checked ? 'disable' : 'enable'} this user?`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(async (result) => {
            if (result.value) {
                if (!_checked) {
                    // enabling user
                    const enableUser = window.firebase.functions().httpsCallable('amsadmin-enableUser');
                    const resp = await enableUser({
                        "uid": id,
                    })

                    // console.log('response of function call', resp)
                    if (resp && resp.data && resp.data.success) {
                        try {
                            const logRef = window.firebase.firestore().collection('auditLogs')
                            const auditLog = {
                                clientID: props.dealersettings.client.id ? props.dealersettings.client.id : null,
                                documentID: logRef.doc().id,
                                groupID: props.dealersettings.groupID ? props.dealersettings.groupID : null,
                                imageURL: null,
                                logBy: localStorage.uid,
                                logDate: window.firebase.firestore.Timestamp.now(),
                                notes: `${data?.name || ''} User enabled`,
                                oemID: props.dealersettings.oemID ? props.dealersettings.oemID : null,
                                platform: 'web',
                                recordID: localStorage.browserKey ? localStorage.browserKey : null,
                                regionID: props.dealersettings.regionID ? props.dealersettings.regionID : null,
                                subtype: 'enable',
                                type: 'authentication',
                            }
                            logRef.doc(auditLog.documentID).set(auditLog, { merge: true })
                        } catch (err) { }
                        toast.notify('User enabled successfully', {
                            duration: 2000
                        })
                    } else {
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    }
                } else if (_checked) {
                    // disabling user
                    let isOwnerExist;

                    const activitiesSnap = await firestoreDB(props.dealersettings).firestore().collection('activities').where('owner', '==', id).where('isDeleted', '==', false).limit(1).get()
                    const contactsSnap = await window.firebase.firestore().collection('contactDetails').where('owner', '==', id).where('isDeleted', '==', false).limit(1).get()
                    const enquiriesSnap = await firestoreDB(props.dealersettings).firestore().collection('enquiries').where('owner', '==', id).where('isDeleted', '==', false).limit(1).get()

                    const eventsProSnap = await firestoreDB(props.dealersettings).firestore().collection('eventsPro').where('owner', '==', id).where('isDeleted', '==', false).limit(1).get()
                    const serviceJobsSnap = await firestoreDB(props.dealersettings).firestore().collection('serviceJobs').where('owner', '==', id).where('isDeleted', '==', false).limit(1).get()
                    const tradeinProSnap = await firestoreDB(props.dealersettings).firestore().collection('tradeinPro').where('owner', '==', id).where('isDeleted', '==', false).limit(1).get()

                    if (activitiesSnap.size > 0) {
                        isOwnerExist = true
                        //console.log('activitiesSnap.size', activitiesSnap.size)
                    }
                    if (contactsSnap.size > 0) {
                        isOwnerExist = true
                        //console.log('contactsSnap.size', contactsSnap.size)
                    }
                    if (enquiriesSnap.size > 0) {
                        isOwnerExist = true
                        //console.log('enquiriesSnap.size', enquiriesSnap.size)
                    }
                    if (eventsProSnap.size > 0) {
                        isOwnerExist = true
                        //console.log('eventsProSnap.size', eventsProSnap.size)
                    }
                    if (serviceJobsSnap.size > 0) {
                        isOwnerExist = true
                        //console.log('serviceJobsSnap.size', serviceJobsSnap.size)
                    }
                    if (tradeinProSnap.size > 0) {
                        isOwnerExist = true
                        //console.log('tradeinProSnap.size', tradeinProSnap.size)
                    }

                    if (isOwnerExist === true) {
                        // console.log('this user is owner of some contacts, enquiries or activities')
                        setShowMergeModal({
                            ...showMergeModal,
                            show: true,
                            id,
                            name: data.name
                        })
                    } else {
                        // console.log('this user is not owner of some contacts, enquiries or activities')
                        // disable user right away without showing modal for transfer of data
                        await disableUser(id, true, data)
                    }
                }
            }
        })
    }

    const disableUser = async (id, isDisabled, data) => {
        try {
            const userRef = window.firebase.firestore().collection('users').doc(id)
            await userRef.update({
                isDisabled: isDisabled,
                tokkens: [],
                isWholesaler: false,
                modifiedBy: localStorage.uid,
                modifiedDate: window.firebase.firestore.Timestamp.now()
            })
            try {
                window.firebase.firestore().collection(`users/${id}/devices`)
                    .where('isLogin', '==', true)
                    .get()
                    .then(snap => {
                        if (snap.docs.length > 0) {
                            snap.docs.forEach(doc => {
                                window.firebase.firestore().doc(`users/${id}/devices/${doc.id}`).set({
                                    isLogin: false,
                                    token: null
                                }, { merge: true })
                            })
                        }
                    });
                window.firebase.firestore().doc(`amsmeUsers/${id}`)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            window.firebase.firestore().doc(`amsmeUsers/${id}`).delete();
                        }
                    })
            } catch (err) { }
            try {
                const logRef = window.firebase.firestore().collection('auditLogs')
                const auditLog = {
                    clientID: props.dealersettings.client.id ? props.dealersettings.client.id : null,
                    documentID: logRef.doc().id,
                    groupID: props.dealersettings.groupID ? props.dealersettings.groupID : null,
                    imageURL: null,
                    logBy: localStorage.uid,
                    logDate: window.firebase.firestore.Timestamp.now(),
                    notes: `${data?.name || ''} User disabled`,
                    oemID: props.dealersettings.oemID ? props.dealersettings.oemID : null,
                    platform: 'web',
                    recordID: localStorage.browserKey ? localStorage.browserKey : null,
                    regionID: props.dealersettings.regionID ? props.dealersettings.regionID : null,
                    subtype: 'disable',
                    type: 'authentication',
                }
                logRef.doc(auditLog.documentID).set(auditLog, { merge: true })
            } catch (err) { }
            toast.notify('User disabled successfully', {
                duration: 2000
            })
        } catch (error) {
            toast.notify('Something went wrong', {
                duration: 2000
            })
            console.log(error)
        }
    }

    const handleToggleFilter = (state) => {
        setFilter(state)
    }


    const getRegions = () => {
        let regionsToReturn;
        let filteredRegions;

        filteredRegions = props.dealersettings.regions.filter(region => {
            if (props.dealersettings.clients.some(client => client.region === region.code)) {
                return true
            }
            return false;
        })

        regionsToReturn = filteredRegions.map(e => {
            return { label: e.name, value: e.code };
        })

        return regionsToReturn
    }

    const getSubregions = () => {
        let regionsToReturn;
        let filteredSubregions;

        filteredSubregions = props.dealersettings.subregions.filter(subregion => {
            if (props.dealersettings.clients.some(client => (client.subregion === subregion.code) &&
                (!_.isEmpty(objFilter['region']) ? objFilter['region'].includes(client.region) : true))) {
                return true
            }
            return false;
        })

        regionsToReturn = filteredSubregions.map(e => {
            return { label: e.name, value: e.code };
        })

        return regionsToReturn
    }

    const setMultibjFilter = (selectedOptions, name) => {
        let value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        let _state = objFilter;
        if (name === 'region') {
            _state = {
                ..._state,
                [name]: value,
                ['subregion']: [],
                ['dealer']: [],
            };
        } else if (name === 'subregion') {
            _state = {
                ..._state,
                [name]: value,
                ['dealer']: [],
            };
        } else {
            _state = {
                ..._state,
                [name]: value,
            };
        }
        _state.clients = props?.dealersettings?.clients ? props.dealersettings.clients.filter(client =>
            (_state?.region?.length > 0 ? _state.region.includes(client.region) : true) &&
            (_state?.subregion?.length > 0 ? _state.subregion.includes(client.subregion) : true) &&
            (_state?.dealer?.length > 0 ? _state.dealer.includes(client.documentID) : true)
        ).map(r => { return r.id }) : []
        setobjFilter(_state);
    }

    const getDealers = () => {
        let clientsToReturn = props.dealersettings.clients;

        if (!_.isEmpty(objFilter['region'])) {
            clientsToReturn = clientsToReturn.filter(client =>
                objFilter['region'].includes(client.region)
            );
        }

        if (!_.isEmpty(objFilter['subregion'])) {
            clientsToReturn = clientsToReturn.filter(client =>
                objFilter['subregion'].includes(client.subregion)
            );
        }

        clientsToReturn = clientsToReturn.map(client => {
            return {
                value: client.documentID,
                doc: client,
                label: client.name,
                active: _.isBoolean(client.active) ? client.active : true,
            };
        })

        return clientsToReturn
    }

    return (
        <>
            <div className="user-management">
                <div className="settings-head form-style">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="switch-tab ml-2">
                                    <ul>
                                        <li onClick={() => handleToggleFilter(true)}><a href="#" className={filter ? 'active' : ''}><Translate text={'Activated'} /></a></li>
                                        <li onClick={() => handleToggleFilter(false)}><a href="#" className={!filter ? 'active' : ''}><Translate text={'Deactivated'} /></a></li>
                                    </ul>
                                </div>
                                <div className="form-group col-md-2 pr-0">
                                    <ReactMultiSelect
                                        options={getRegions()}
                                        name={'region'}
                                        placeholder={`select region`}
                                        onChange={(e) => {
                                            setMultibjFilter(e, 'region')
                                        }}
                                        value={objFilter['region']}
                                        classNamePrefix={`basic-select`}
                                        isMulti={true}
                                        isSearchable={true}
                                    >
                                    </ReactMultiSelect>
                                </div>
                                <div className="form-group col-md-2 pr-0">
                                    <ReactMultiSelect
                                        options={getSubregions()}
                                        name={'subregion'}
                                        placeholder={`select subregion`}
                                        onChange={(e) => {
                                            setMultibjFilter(e, 'subregion')
                                        }}
                                        value={objFilter['subregion']}
                                        classNamePrefix={`basic-select`}
                                        isMulti={true}
                                        isSearchable={true}
                                    >
                                    </ReactMultiSelect>
                                </div>
                                <div className="col-md-2 pr-0">
                                    <ReactMultiSelect
                                        options={getDealers()}
                                        name={'dealer'}
                                        placeholder={`select dealer`}
                                        onChange={(e) => {
                                            setMultibjFilter(e, 'dealer')
                                        }}
                                        value={objFilter['dealer']}
                                        classNamePrefix={`basic-select`}
                                        isMulti={true}
                                        isSearchable={true}
                                    >
                                    </ReactMultiSelect>
                                </div>
                                <div className="filter-search search-icon col-md-3">
                                    <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                </div>
                            </div></div>
                        <div className="col-md-3">
                            {
                                accessToExportUser ? (
                                    <div className="float-right">
                                        <button type="button" className="btn btn-primary float-right ml-2" onClick={() => { setShowExportUser({ show: true, title: 'Active Users Download' }) }}><i className="ico icon-excel-download mr-2 mt-1"></i> <Translate text={'Active Users'} /></button>
                                    </div>
                                ) : (<></>)
                            }
                            <div className="float-right">
                                <button type="button" className="btn btn-primary float-right" onClick={() => { handleAddUser(); }}><i className="ico icon-add mr-2"></i> <Translate text={'Add User'} /></button>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        userList.length > 0 ? (
                            <div className="common-table">
                                <TableView
                                    datalist={_.orderBy(userList
                                        .filter(user => {
                                            let val;
                                            if (filter) { // show enabled
                                                if (user.isDisabled) {
                                                    val = true
                                                } else {
                                                    val = false
                                                }
                                            } else { // show disabled
                                                if (user.isDisabled) {
                                                    val = false
                                                } else {
                                                    val = true
                                                }
                                            }
                                            return val
                                        })
                                        .filter(({ name, phone, email, arrayClientID }) =>
                                            (!_.isEmpty(searchText) ? (
                                                (!_.isEmpty(name) ? name.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                                (!_.isEmpty(phone) ? phone.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                                (!_.isEmpty(email) ? email.toLowerCase().includes(searchText.toLowerCase()) : false)
                                            ) : true)
                                            //&& (!_.isEmpty(selectedClient) ? arrayClientID.some(e => e === selectedClient.documentID) : true)
                                            && (objFilter?.clients?.length ? arrayClientID?.some(ai => objFilter.clients.includes(ai)) : true)
                                        ), ['addedDate.seconds'], ['desc'])}
                                    height={windowSize.windowHeight}
                                    width={windowSize.windowWidth}
                                    columns={userlistFields}
                                    dynamicFields={userlistFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                    handleActionClick={handleActionClick}
                                    handleRowSingleClick={handleUserdetailsClick}
                                    isSettings={false}
                                    hasMore={hasMore}
                                    handlePagination={handlePagination}
                                    isPaging={true}
                                    isReorderable={false}
                                    handleSwitchChange={handleSwitchChange}
                                    isSorting={true}
                                    isTranslate={true}
                                    dealersettings={props.dealersettings}
                                />
                            </div>
                        ) : (
                            <div className="common-table">
                                <div className="text-center p-5">
                                    <p><Translate text={'No users found'} /></p>
                                    <button className="btn btn-sm btn-default" onClick={() => { handleAddUser(); }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                        <Translate text={'Add User'} />
                                    </button>
                                </div>
                            </div>
                        )
                }

            </div>
            <PopUpModal show={showuserModel}>
                <AddUser
                    {...props}
                    show={showuserModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    user={selectedUser}
                />
            </PopUpModal>
            <PopUpModal show={showMergeModal.show}>
                <MergeModal
                    {...props}
                    {...showMergeModal}
                    title={'Leads Transfer'}
                    handleClose={handleMergeModalClose}
                    setUserList={setUserList}
                    userList={userList}
                    dealersettings={props.dealersettings}
                />
            </PopUpModal>
            <PopUpModal show={showExportUser.show}>
                <ExportUser
                    {...props}
                    {...showExportUser}
                    objFilter={objFilter}
                    handleClose={handleMergeModalClose}
                />
            </PopUpModal>

        </>
    );
}

export default UserList;