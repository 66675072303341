/** LIBRARIES */
import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import _ from 'lodash'
import Swal from 'sweetalert2';
import moment from 'moment';
import CommonHelper from '../../../services/common';
import SendMail from '../details/sendMail';
import InviteAmsMe from '../../contacts/inviteME';
import Translate from '../../../constants/translate';
import { enquiryStatus } from '../../../services/enum';

class QuickInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { clientID, label, requirement, engageNewContact,
            displayID, verifiedBy, verifiedDate, status,
            isFav, isContact, clientName, tags, isDeleted, transferHistory, requirementCount, soldCount
        } = this.props.enquiry;

        const { titles, dealersettings, clientsettings, canModifyEnquiry, groupUsers, clientUsers, contact, amsMeEnabled, isAmsMeContact, enquiry } = this.props;

        const isGroupDealer = localStorage.defaultModule !== 'oem' && clientID && !_.isEmpty(dealersettings) && clientID !== dealersettings.client.id ? true : false;

        let dealerName = '';
        if (clientID && (clientID !== clientsettings.client.id || localStorage.defaultModule === 'oem'))
            dealerName = CommonHelper.getOtherDealerName(dealersettings, clientID);

        //console.log('contact', contact)
        let dealerNameContact = '';
        if (contact && contact.clientID && (contact.clientID !== clientsettings.client.id || localStorage.defaultModule === 'oem'))
            dealerNameContact = CommonHelper.getOtherDealerName(dealersettings, contact.clientID);

        const _transferHistoryData = !_.isEmpty(transferHistory) ? CommonHelper.getLatestTransferHistory(transferHistory, dealersettings, true) : null;

        const _reqCount = requirementCount && requirementCount > 1 ? (requirementCount - 1) : null;
        const _wonCount = soldCount && soldCount > 1 ? (soldCount) : null;

        const aircallEnabled = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
            dealersettings.client.integrations.filter(e => e.type === "aircall")[0] &&
            dealersettings.client.integrations.filter(e => e.type === "aircall")[0].active === true &&
            dealersettings.client.integrations.filter(e => e.type === "aircall")[0].enabled === true
        ) ? true : false);

        let _displayDate = null;//_enq.addedDate
        let _displayDateTitle = null;//'created'
        let _displayColor = ''

        const enquiryAllStatus = (dealersettings?.client?.settings.enquiryStatus ? dealersettings.client.settings.enquiryStatus : [])
        const isStatusSold = CommonHelper.isEnqStatusSold(enquiryAllStatus, enquiry.status)
        if ((enquiry.status === enquiryStatus.LOST || enquiry.status === enquiryStatus.PROSPECTLOST || enquiry.status === enquiryStatus.PENDINGLOST) && !_.isEmpty(enquiry.lostDate)) {
            _displayDate = enquiry.lostDate
            _displayDateTitle = CommonHelper.getNameByValue(enquiryAllStatus, 'lost', 'Lost');
            _displayColor = CommonHelper.getFieldByValue(enquiryAllStatus, 'lost', 'color');
        }
        else if (enquiry.status === enquiryStatus.DELIEVERD && !_.isEmpty(enquiry.deliveredDate)) {
            _displayDate = enquiry.deliveredDate
            _displayDateTitle = CommonHelper.getNameByValue(enquiryAllStatus, 'delivered', 'Delivered');
            _displayColor = CommonHelper.getFieldByValue(enquiryAllStatus, 'delivered', 'color');
        }
        else if ((isStatusSold || enquiry.status === enquiryStatus.CLOSED) && !_.isEmpty(enquiry.wonDate)) {
            _displayDate = enquiry.wonDate
            _displayDateTitle = CommonHelper.getNameByValue(enquiryAllStatus, 'won', 'Won');
            _displayColor = CommonHelper.getFieldByValue(enquiryAllStatus, 'won', 'color');
        }

        let enquiryage = (enquiry.addedDate ? (moment.unix(enquiry.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? 'today' : moment.unix(enquiry.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '--');
        let displayAge = (_displayDate ? (moment.unix(_displayDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? 'today' : moment.unix(_displayDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '');

        return (
            <>
                <div className="quickview-mid-header">

                    <div className="float-left quickview-title-wrap w-100">
                        <div className="me-quickview-btn">
                            <InviteAmsMe
                                dealersettings={dealersettings}
                                clientUsers={clientUsers}
                                amsMeEnabled={amsMeEnabled}
                                isContact={isContact}
                                isAmsMeContact={isAmsMeContact}
                                contact={contact}
                            >
                            </InviteAmsMe>
                        </div>

                        {
                            isContact && !_.isEmpty(contact)
                                ?
                                <>
                                    <div className="quickview-contact">
                                        {CommonHelper.displayContactIcon(contact, 'mr-1')}
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            // const url = `/contacts/details/${contact.documentID}`;
                                            // window.open(url, '_blank');

                                            let pathname = window.location.pathname;
                                            if (pathname.indexOf('contacts/details') < 0) {
                                                this.props.history.push("/contacts/details/" + contact.documentID + '?back-url=' + pathname);
                                            }

                                        }}>
                                            {CommonHelper.displayContact(titles, contact, '--')}
                                        </a>
                                    </div>
                                    {CommonHelper.displayPreferredContact(contact)}
                                    {/* {contact.displayID ? <span className='enquiry-details-preferred'> ({contact.displayID})</span> : <></>} */}

                                    <span className="quickview-favourite-ico">
                                        {(() => {
                                            if (isFav) {
                                                return (
                                                    <a href="#"><i className="ico icon-star-active" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.updateStatus('isFav', false)
                                                    }}></i></a>
                                                )
                                            }
                                            else {
                                                return (
                                                    <a href="#"><i className="ico icon-star" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.updateStatus('isFav', true)
                                                    }}></i></a>
                                                )
                                            }
                                        })()}

                                    </span>
                                    <div className="quickview-optin">
                                        {
                                            contact.optinPhone === false
                                                ?
                                                <span><i className={`ico icon-no-call`}></i></span>
                                                :
                                                <></>
                                        }
                                        {
                                            contact.optinSMS === false
                                                ?
                                                <span><i className={`ico icon-no-sms`}></i></span>
                                                :
                                                <></>
                                        }
                                        {
                                            contact.optinEmail === false
                                                ?
                                                <span><i className={`ico icon-no-email`}></i></span>
                                                :
                                                <></>
                                        }
                                        {
                                            contact.optinPost === false
                                                ?
                                                <span><i className={`ico icon-no-post`}></i></span>
                                                :
                                                <></>
                                        }
                                        {
                                            contact.marketingOptinEmail === false
                                                ?
                                                <span><i className={`ico icon-no-email`}></i></span>
                                                :
                                                <></>
                                        }
                                        {
                                            contact.marketingOptinSMS === false
                                                ?
                                                <span><i className={`ico icon-no-sms`}></i></span>
                                                :
                                                <></>
                                        }
                                        {
                                            contact.banLoanVehicle ? <span><i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, `ico icon-ban-loan`)}></i></span> : <></>
                                        }
                                    </div>
                                    <div className="quickview-contact-list">
                                        <ul>
                                            {contact.phone ? aircallEnabled ?
                                                <li><i className="ico icon-call"></i> <a href='#' className='hyperlink-blue' onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.sidepanelClose()
                                                    this.props.dialNumber(CommonHelper.validPhone(contact.phone, contact.phoneCode), this?.props?.enquiry?.documentID, contact.documentID)
                                                }}> {CommonHelper.phoneFormat(contact.phoneCode, contact.phone, dealersettings)}</a></li> :
                                                <li><i className="ico icon-call"></i> {CommonHelper.phoneFormat(contact.phoneCode, contact.phone, dealersettings)}</li> : <></>}
                                            {contact.email ? <li><i className="ico icon-Mail"></i> <SendMail
                                                email={contact.email}
                                                enquiry={this.props.enquiry}
                                                dealersettings={dealersettings}
                                                history={this.props.history}
                                            >
                                            </SendMail></li> : <></>}
                                            {
                                                (contact.businessContact)
                                                    ?
                                                    <li>
                                                        <i className={`ico icon-Contacts ${contact.contactType && contact.contactType === 'Potential Customer' ? 'potentialcustomer-ico' : ''}`}></i>
                                                        {CommonHelper.getFullName(titles, contact, '--')}
                                                    </li>
                                                    :
                                                    <>{contact.company && contact.company.name ? <li> <i className="ico icon-company"></i> {contact.company.name}</li> : ''}</>

                                            }
                                            {dealerNameContact ? <li><span className="quickview-dealer-tag"><i className="ico icon-group"></i>{dealerNameContact}</span></li> : <></>}
                                        </ul>
                                    </div>
                                </>
                                :
                                <>
                                    {
                                        !_.isEmpty(engageNewContact)
                                            ?
                                            <>
                                                <div className="quickview-contact text-red-indicator">
                                                    {CommonHelper.displayContactIcon(engageNewContact, 'mr-1')}

                                                    {CommonHelper.displayContact(titles, engageNewContact, '--')}

                                                </div>
                                                {CommonHelper.displayPreferredContact(engageNewContact)}

                                                <span className="quickview-favourite-ico">
                                                    {(() => {
                                                        if (isFav) {
                                                            return (
                                                                <a href="#"><i className="ico icon-star-active" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.props.updateStatus('isFav', false)
                                                                }}></i></a>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <a href="#"><i className="ico icon-star" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.props.updateStatus('isFav', true)
                                                                }}></i></a>
                                                            )
                                                        }
                                                    })()}

                                                </span>
                                                <div className="quickview-optin">
                                                    {
                                                        engageNewContact.optinPhone === false
                                                            ?
                                                            <span><i className={`ico icon-no-call`}></i></span>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        engageNewContact.optinSMS === false
                                                            ?
                                                            <span><i className={`ico icon-no-sms`}></i></span>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        engageNewContact.optinEmail === false
                                                            ?
                                                            <span><i className={`ico icon-no-email`}></i></span>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        engageNewContact.optinPost === false
                                                            ?
                                                            <span><i className={`ico icon-no-post`}></i></span>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        engageNewContact.marketingOptinEmail === false
                                                            ?
                                                            <span><i className={`ico icon-no-email`}></i></span>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        engageNewContact.marketingOptinSMS === false
                                                            ?
                                                            <span><i className={`ico icon-no-sms`}></i></span>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        engageNewContact.banLoanVehicle ? <span><i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, `ico icon-ban-loan`)}></i></span> : <></>
                                                    }
                                                </div>
                                                <div className="quickview-contact-list">
                                                    <ul>
                                                        {engageNewContact.phone ? <li><i className="ico icon-call"></i> {CommonHelper.phoneFormat(engageNewContact.phoneCode, engageNewContact.phone, dealersettings)}</li> : <></>}
                                                        {engageNewContact.email ? <li><i className="ico icon-Mail"></i> <SendMail
                                                            email={engageNewContact.email}
                                                            enquiry={this.props.enquiry}
                                                            dealersettings={dealersettings}
                                                            history={this.props.history}
                                                        >
                                                        </SendMail></li> : <></>}


                                                    </ul>
                                                </div>
                                            </>
                                            :
                                            <>--</>
                                    }

                                </>
                        }


                        <div className="quickview-subtitle">
                            {(() => {
                                if (!_.isEmpty(requirement) && (!_.isEmpty(requirement.stock) || !_.isEmpty(requirement.make))) {
                                    if (!_.isEmpty(requirement.stock) && !_.isEmpty(requirement.stock.make)) {
                                        return (
                                            <span>
                                                <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-Stock')}></i>
                                                {(requirement.stock.make ? requirement.stock.make : '') + ' ' + (requirement.stock.model ? requirement.stock.model : '')}
                                                {requirement.saleType ? (<div className="badge badge-pill badge-white badge-mini ml-1">
                                                    {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                        requirement.saleType, '')}
                                                </div>) : ''}


                                                {
                                                    _reqCount
                                                        ?
                                                        <OverlayTrigger
                                                            placement='bottom'
                                                            overlay={
                                                                <Tooltip><Translate text={'Requirements'} /></Tooltip>
                                                            }
                                                        >
                                                            <div className="badge badge-pill badge-blue badge-mini ml-1">
                                                                +{_reqCount} <Translate text={'more'} lowerCase={true} />
                                                            </div>
                                                        </OverlayTrigger>

                                                        :
                                                        <></>
                                                }

                                                {
                                                    _wonCount
                                                        ?
                                                        <OverlayTrigger
                                                            placement='bottom'
                                                            overlay={
                                                                <Tooltip><Translate text={'Won Requirements'} /></Tooltip>
                                                            }
                                                        >
                                                            <div className="badge badge-pill badge-available badge-mini ml-1">
                                                                {_wonCount}  <Translate text={'won'} upperCase={true} />
                                                            </div>

                                                        </OverlayTrigger>

                                                        :
                                                        <></>
                                                }

                                            </span>
                                        )
                                    }
                                    else {
                                        return (
                                            <span>
                                                <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-Stock')}></i>
                                                {(requirement.make ? requirement.make : '') + ' ' + (requirement.model ? requirement.model : '')}
                                                {requirement.saleType ? (<div className="badge badge-pill badge-white badge-mini ml-1">
                                                    {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                        requirement.saleType, '')}
                                                </div>) : ''}

                                                {
                                                    _reqCount
                                                        ?
                                                        <OverlayTrigger
                                                            placement='bottom'
                                                            overlay={
                                                                <Tooltip><Translate text={'Requirements'} /></Tooltip>
                                                            }
                                                        >
                                                            <div className="badge badge-pill badge-blue badge-mini ml-1">
                                                                +{_reqCount} <Translate text={'more'} lowerCase={true} />
                                                            </div>
                                                        </OverlayTrigger>

                                                        :
                                                        <></>
                                                }

                                                {
                                                    _wonCount
                                                        ?
                                                        <OverlayTrigger
                                                            placement='bottom'
                                                            overlay={
                                                                <Tooltip><Translate text={'Won Requirements'} /></Tooltip>
                                                            }
                                                        >
                                                            <div className="badge badge-pill badge-available badge-mini ml-1">
                                                                {_wonCount}  <Translate text={'won'} upperCase={true} />
                                                            </div>

                                                        </OverlayTrigger>

                                                        :
                                                        <></>
                                                }

                                            </span>
                                        )
                                    }


                                }
                            })()}



                        </div>

                        {/* <div className="quickview-module-list">
                            <ul>
                                <li className={isContact ? 'active' : 'inactive'}> <i className="ico icon-check"> </i> Contact </li>
                                <li className={isVehicle ? 'active' : 'inactive'}> <i className="ico icon-check"> </i> Vehicle </li>
                                <li className={isTestDrive ? 'active' : 'inactive'}> <i className="ico icon-check"> </i> Test Drive </li>
                                <li className={isTradeIn ? 'active' : 'inactive'}> <i className="ico icon-check"> </i> Trade In </li>
                                <li className={isCafe ? 'active' : 'inactive'}> <i className="ico icon-check"> </i> Cafe </li>
                            </ul>
                        </div> */}

                        <div className="quickview-subtitle">
                            {displayID ? <div className="quickview-id"><i className="ico icon-enquiry"></i> {displayID}</div> : <></>}
                            {
                                enquiryage
                                    ?
                                    <> <div className="quickview-id" title="Enquiry Age">

                                        <i className="ico icon-timer mb-1" ></i>{enquiryage}
                                    </div>
                                    </>
                                    :
                                    <> </>
                            }

                            {
                                displayAge && _displayDateTitle
                                    ?
                                    <>
                                        <div className="quickview-id" title={`${_displayDateTitle} Age`}>

                                            <i className="ico icon-timer mb-1" style={_displayColor ? { color: _displayColor } : {}}></i>
                                            <span style={_displayColor ? { color: _displayColor } : {}}>{displayAge}</span></div>
                                    </>
                                    :
                                    <> </>
                            }



                            {!_.isEmpty(_transferHistoryData) ?
                                <div className="oem-tag-id"><i className="ico icon-location"></i>
                                    {_transferHistoryData.name}
                                    <span className="user-role-span ml-1">{_transferHistoryData.value}</span>
                                </div> :
                                <>
                                    {dealerName ? <div className="quickview-id blue-color ml-2"><i className="ico icon-group"></i>{dealerName}</div> : <>
                                        {clientName ? (
                                            <div className="oem-tag-id"><i className="ico icon-location"></i>
                                                {clientName}
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </>}
                                </>
                            }

                            {
                                tags && tags.length > 0 ? (<div className="quickview-id">
                                    {
                                        tags.map((r, i) => {
                                            return r.subType ? (
                                                <span key={i} className="lead-badge-border">
                                                    <div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize" title={r.refID || ''}>{CommonHelper.getNameByValue(this.props.dealersettings.integrations, r.type, r.type)}</div>
                                                    <div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{r.subType}</div>
                                                </span>
                                            ) : r.type === 'cap' ? (<div key={i} className="badge badge-pill badge-white ml-1 badge-mini text-capitalize" title='C@P'>C@P-{r.refID || CommonHelper.getNameByValue(this.props.dealersettings.integrations, r.type, r.type)}</div>)
                                                : (<div key={i} className="badge badge-pill badge-white ml-1 badge-mini text-capitalize" title={r.refID || ''}>{CommonHelper.getNameByValue(this.props.dealersettings.integrations, r.type, r.type)}</div>)
                                        })
                                    }
                                </div>) : (<></>)
                            }

                            {
                                (label)
                                    ?
                                    <>
                                        <div className="quickview-status">
                                            {
                                                CommonHelper.bindEnquiryLabel(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.enquiryLabels, label)
                                            }
                                            {/* <div className={`badge badge-pill badge-${label.toLowerCase()}`}>{label}</div> */}
                                        </div>
                                    </>
                                    :
                                    <></>
                            }

                            {
                                verifiedBy && !_.isEmpty(verifiedDate) ? (
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                            <Tooltip>
                                                <><span><Translate text={'verifiedBy'} />:</span> {CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), verifiedBy)}</>
                                                <br></br>
                                                <><span><Translate text={'verifiedDate'} />:</span> {verifiedDate ? moment.unix(verifiedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</>
                                            </Tooltip>
                                        }
                                    >
                                        <div className="verified-status">
                                            <div className="badge badge-pill badge-verified">
                                                <i className="ico icon-check"> </i> <Translate text={'Verified'} />
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                ) : (
                                    <></>
                                )
                            }

                            {
                                !canModifyEnquiry ? (

                                    <div className="verified-status">
                                        <div className="badge badge-pill badge-hot">
                                            <i className="ico icon-lock mr-1"> </i> <Translate text={'readonly'} upperCase={true} />
                                        </div>
                                    </div>

                                ) : (
                                    <></>
                                )
                            }
                            {
                                isDeleted ? (

                                    <div className="verified-status">
                                        <div className="badge badge-pill badge-hot">
                                            <i className="ico icon-delete mr-1"> </i> <Translate text={'deleted'} upperCase={true} />
                                        </div>
                                    </div>

                                ) : (
                                    <></>
                                )
                            }

                            {
                                contact?.gdprDeleted ? (

                                    <div className="verified-status">
                                        <div className="badge badge-pill badge-warm">
                                            <i className="ico icon-delete mr-1"> </i> <Translate text={'gdprDeleted'} upperCase={true} />
                                        </div>
                                    </div>

                                ) : (
                                    <></>
                                )
                            }

                            {
                                this.props.enquiry['mergeID'] ? (
                                    <div className="verified-status" onClick={e => {
                                        e.preventDefault();
                                        Swal.fire({
                                            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                                            text: CommonHelper.showLocale(this.props, `You want to view merged enquiry`),
                                            icon: 'info',
                                            showCancelButton: true,
                                            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                                            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                                        }).then(result => {
                                            if (result.value) {
                                                this.props.history.push('/enquiry/details/' + this.state.fields['mergeID']);
                                            }
                                        });

                                    }}>
                                        <div className="badge badge-pill badge-merged">
                                            <i className="ico icon-merge mr-1"> </i> <Translate text={'merged'} upperCase={true} />
                                        </div>
                                    </div>


                                ) : (
                                    <></>
                                )
                            }


                        </div>

                    </div>

                </div>
            </>


        );
    }
}

export default QuickInfo;