import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../../constants/translate';

const InfoModal = ({ show, handleClose, title, type, counts, clientsToRender, totalOEMCounts }) => {
	const [totalMonth, settotalMonth] = useState(0)
	const [totalToday, settotalToday] = useState(0)
	const [totalOverdueActivity, settotalOverdueActivity] = useState(0)

	useEffect(() => {
		if (!clientsToRender) {
			return
		}
		let day = 0
		let month = 0
		let overdue = 0
		clientsToRender.length > 0 && clientsToRender.forEach(client => {
			if (type === 'overdueActivity') {
				overdue = overdue + (totalOEMCounts.filter(count => count.clientID === client.documentID)[0].overdueActivity ?
					totalOEMCounts.filter(count => count.clientID === client.documentID)[0].overdueActivity : 0)
			} else {
				month = month + (totalOEMCounts.filter(count => count.clientID === client.documentID)[0][`month${type}`] ?
					totalOEMCounts.filter(count => count.clientID === client.documentID)[0][`month${type}`] : 0)
				day = day + (totalOEMCounts.filter(count => count.clientID === client.documentID)[0][`today${type}`] ?
					totalOEMCounts.filter(count => count.clientID === client.documentID)[0][`today${type}`] : 0)
				overdue = totalOEMCounts.filter(count => count.clientID === client.documentID)[0].overdueActivity ?
					totalOEMCounts.filter(count => count.clientID === client.documentID)[0].overdueActivity : 0
			}
			// console.log(`name, day, month, overdue === ${client.name}, ${day}, ${month}, ${overdue} `)
		})
		settotalToday(day)
		settotalMonth(month)
		settotalOverdueActivity(overdue)
	}, [clientsToRender, totalOEMCounts, type])
	return (
		<Modal
			id='add-finance'
			show={show}
			onHide={handleClose}
			backdrop='static'
			backdropClassName={show ? 'overlay-modal active' : ''}
			enforceFocus={false}
			dialogClassName='modal-dialog modal-dialog-scrollable modal-dialog-centered modal-view-oem'
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className='modal-title'>
						<Translate text={title ? title : 'Open Leads'} />
					</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='container-fluid'>
					<div className='row'>
						<div className='oem-countlist tbl-scroll'>
							<table width="100%" cellSpacing="0" cellPadding="0" border="0" className="table table-bordered">
								<tbody>
									{type === 'overdueActivity' ? (
										<tr>
											<th scope="col" className="head-light" width="70%">Dealership</th>
											<th scope="col" className="head-light  text-center" width="15%">Counts</th>
										</tr>
									) : (
										<tr>
											<th scope="col" className="head-light" width="70%">Dealership</th>
											<th scope="col" className="head-light text-center" width="15%">Today</th>
											<th scope="col" className="head-light  text-center" width="15%">MTD</th>
										</tr>
									)}
									{clientsToRender.length > 0 && clientsToRender.map(client => (
										<tr key={client.documentID}>
											<td >{client.name}</td>
											{type === 'overdueActivity' ? (
												<td className="text-center ">{totalOEMCounts.filter(count => count.clientID === client.documentID)[0].overdueActivity ? totalOEMCounts.filter(count => count.clientID === client.documentID)[0].overdueActivity : 0}</td>
											) : (
												<>
													<td className="text-center ">{totalOEMCounts.filter(count => count.clientID === client.documentID)[0][`today${type}`] ? totalOEMCounts.filter(count => count.clientID === client.documentID)[0][`today${type}`] : 0}</td>
													<td className="text-center">{totalOEMCounts.filter(count => count.clientID === client.documentID)[0][`month${type}`] ? totalOEMCounts.filter(count => count.clientID === client.documentID)[0][`month${type}`] : 0}</td>
												</>
											)}
										</tr>
									))}
									{type === 'overdueActivity' ? (
										<tr>
											<td className="dealer-totalcount" >Dealer Totals</td>
											<td className="dealer-totalcount text-center" >{totalOverdueActivity}</td>
										</tr>
									) : (
										<tr>
											<td className="dealer-totalcount" >Dealer Totals</td>
											<td className="dealer-totalcount text-center" >{counts[`today${type}`]}</td>
											<td className="dealer-totalcount text-center" >{counts[`month${type}`]}</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default InfoModal;
