
/** LIBRARIES */
import React, { useState, useEffect, useContext, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash'
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
/** COMPONENTS */
import {
	ReactTimePicker,
	ReactSelect,
	AntDatePicker,
	InputCheckBox
} from '../../../components';
import CommonHelper from '../../../services/common';
import SimpleMentionEditor from '../../../components/mentionEditor/mentionEditor';
import { MainContext } from '../../layout/provider';
import Translate from '../../../constants/translate';
import { default as _images } from "../../../images";
import { callTypes } from '../../../services/enum'
import { Dropdown, Popover } from 'react-bootstrap';
import { CustomMenu, CustomToggle } from '../../../components/customdropdown';
import Swal from 'sweetalert2';
import { storageBucket } from '../../../services/helper';

const EditLogs = ({
	handlechangelog,
	handlesavelog,
	handlecancellog,
	customerlog,
	textareaRef,
	handleDateChangelog,
	loading,
	completedTab,
	// dealersettings,
	messageMediaEnabled,
	sendLoading,
	contact,
	enquiry,
	tradeinPro,
	service,
	// clientUsers,
	shouldFocusOnMount,
	smsHubEnabled,
	podiumEnabled,
	handleRadioChange,
	originType,
	cmdotcomEnabled,
	ringcentralEnabled,
	justcallEnabled,
	eventsPro,
	eventsProAttendee,
	handleFileChange
}) => {
	const { clientUsers, dealersettings, smsTemplates, groupUsers } = useContext(MainContext);
	const [templates, setTemplates] = useState(smsTemplates ? smsTemplates : []);
	const [templateSearchText, setTemplateSearchText] = useState('');
	const [showPop, setShowPop] = useState(false);
	const [isNoteEmpty, setIsNoteEmpty] = useState(false);
	const [firstChangeDone, setFirstChangeDone] = useState(false);
	const [secondChangeDone, setSecondChangeDone] = useState(false);
	const [arrcallstatus, setCallStatus] = useState([]);
	const [mentions, setMentions] = useState([]);
	const [teamMentions, setTeamMentions] = useState(null);
	const [smsTemplateData, setSmsTemplateData] = useState({
		active: false,
		textHTML: ``,
	});
	const node = useRef();

	const [mandatoryCallLogFields, setMandatoryFields] = useState([]);
	const [errors, setErrors] = useState({});
	const [imageLoader, setImageLoader] = useState(false);
	const [mms, setMMS] = useState()
	const [sourceNumbers, setSourceNumbers] = useState([])

	const justcallSettings = ((!_.isEmpty(dealersettings) &&
		!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
		dealersettings.client.integrations.filter(e => e.type === "justcall")[0] &&
		dealersettings.client.integrations.filter(e => e.type === "justcall")[0].active === true &&
		dealersettings.client.integrations.filter(e => e.type === "justcall")[0].enabled === true
	) ? dealersettings.client.integrations.filter(e => e.type === "justcall")[0].settings : null);

	let mentionsData = clientUsers.filter(a => a.id !== localStorage.uid && a.active === true).map(clientUser => {
		return {
			name: `@${clientUser.name}`,
			id: clientUser.id,
			avatar: CommonHelper.showUserAvatar(clientUser.profileImage, clientUser.name)
		};
	});
	dealersettings.client.teams.forEach(team => {
		mentionsData.push({
			name: `@${team.name}`,
			id: team.user_ids.join(','),
			avatar: _images.group
		})
	})

	useEffect(() => {
		if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
			const setttings = dealersettings.client.settings;

			let options = [];
			setttings.callStatus && setttings.callStatus.forEach((doc) => {
				options.push({
					value: doc.value,
					active: _.isBoolean(doc.active) ? doc.active : true,
					label: doc.name
				});
			});
			setCallStatus(options);

			if (setttings?.mandatoryFields?.callLog) {
				setMandatoryFields(setttings.mandatoryFields.callLog.split(','));
			}
		}
		// options.push({ label: 'Connected', value: 'Connected' });
		// options.push({ label: 'Not Connected', value: 'Not Connected' });
		// options.push({ label: 'Busy', value: 'Busy' });
		// options.push({ label: 'Wrong Number', value: 'Wrong Number' });

	}, [dealersettings]);

	useEffect(() => {
		if (!justcallEnabled || _.isEmpty(justcallSettings))
			return

		const fnsourceNumbers = window.firebase.functions().httpsCallable('justcall-getSourceNumber');
		fnsourceNumbers({
			...justcallSettings,
			name: dealersettings.client.name,
			email: dealersettings.email,
			userID: localStorage.uid
		}).then(async (resp) => {
			if (resp?.data?.data?.length) {
				setSourceNumbers(resp.data.data.filter(a => a.phone).map(a => {
					return {
						label: <>+{a.phone.replace(/\D/g, '')}<span className="user-role-span">{a.custom_name}</span></>,
						searchlabel: `${a.custom_name} ${a.phone}`,
						value: `+${a.phone.replace(/\D/g, '')}`
					}
				}))
			}
		})

	}, [justcallSettings])

	// useEffect(() => {
	// 	if (textareaRef && textareaRef.current) {
	// 		textareaRef.current.focus();
	// 	}
	// }, [customerlog.subType]);

	const handleDateChange = (val, name) => {
		if (val) {
			//console.log(moment.unix(customerlog.addedDate.seconds).format('HH:mm'))
			val = moment(moment(val).format('YYYY-MM-DD') + ' ' + moment.unix(customerlog.addedDate.seconds).format('HH:mm'))._d;
			handleDateChangelog(val, name, customerlog);
		}
	};
	const handleTimeChange = (val, name) => {
		if (val) {
			handleDateChangelog(val, name, customerlog, completedTab);
		}
	};
	const dateFormat = val => {
		return CommonHelper.getdateFormat(val);
	};

	const handleCalldurationChange = val => {
		var secs = Number(val.format('mm')) * 60 + Number(val.format('ss'));
		handleDateChangelog(secs, 'callDuration', customerlog, completedTab);
	};

	const handleReactSelectChange = (e, data) => {
		handleDateChangelog(
			e && e.value ? e.value : '',
			data.name,
			customerlog,
			completedTab,
		);
	};

	const onMentionChange = notesText => {
		//	console.log('onMentionChange, ', notesText)

		// if (notesText)
		// notesText = notesText.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });

		const e = {
			target: {
				name: 'notes',
				value: notesText,
			},
		};

		// console.log(`notes ======`, notesText)	
		// console.log(`customerlog notes ======`, customerlog.notes)


		if (notesText === '' || notesText.length === 0) {
			if (firstChangeDone) {
				if (secondChangeDone) {
					setIsNoteEmpty(true)
				}
			}
		}

		if (isNoteEmpty && notesText.length > 0) {
			setIsNoteEmpty(false)
		}

		handlechangelog(e, customerlog, completedTab);
		if (firstChangeDone) {
			setSecondChangeDone(true)
		}

		setFirstChangeDone(true)

	};

	const filterTemplates = (template) => {
		if (!templateSearchText) {
			return true
		} else if (template.title.toLowerCase().includes(templateSearchText.toLowerCase())) {
			return true
		}

		return false
	}

	const handleInsertTemplate = emailTemplate => {
		let template = emailTemplate.bodyHTML ? emailTemplate.bodyHTML : ''

		if (!_.isEmpty(emailTemplate.templateJSON) && !_.isEmpty(JSON.parse(emailTemplate.templateJSON))) {
			template = JSON.parse(emailTemplate.templateJSON);
			let text = '';
			if (!_.isEmpty(template.blocks)) {
				template.blocks.forEach(ele => {
					text += ele.text + '\n';
				});
			}
			template = text
		}

		let contactToPass = !_.isEmpty(contact) ? contact : (!_.isEmpty(enquiry) && !_.isEmpty(enquiry.contact)) ? enquiry.contact : (!_.isEmpty(tradeinPro) && !_.isEmpty(tradeinPro.contact)) ? tradeinPro.contact : (!_.isEmpty(service) && !_.isEmpty(service.contact)) ? service.contact : null
		let enqToPass = !_.isEmpty(enquiry) ? enquiry : null
		let tradeToPass = !_.isEmpty(tradeinPro) ? tradeinPro : null
		let eventsToPass = !_.isEmpty(eventsPro) ? eventsPro : null
		let serviceToPass = !_.isEmpty(service) ? service : null
		template = CommonHelper.mapTemplate(
			template,
			contactToPass,
			enqToPass,
			dealersettings,
			(dealersettings.group && dealersettings.group.enableSharedContact === true) ? groupUsers : clientUsers,
			tradeToPass,
			serviceToPass,
			null, null,
			eventsToPass
		)

		setSmsTemplateData({
			active: true,
			textHTML: template,
		})
		return
	};

	const clearSmsTemplateData = () => {
		setSmsTemplateData({
			active: false,
			textHTML: ``,
		})
	}
	const onMentionSelect = (option, ownerID) => {
		// console.log('select', option);
		const { name, id } = option;
		let idToPass = id
		// return if user is mentioning himself
		if (idToPass === localStorage.uid) {
			return;
		}

		if (id.includes(',')) {
			let idToPass = id
			if (idToPass.includes(localStorage.uid)) {
				idToPass = idToPass.replace(`${localStorage.uid},`, '')
				idToPass = idToPass.replace(`${localStorage.uid}`, '')
			}
			if (ownerID && idToPass.includes(ownerID)) {
				idToPass = idToPass.replace(`${ownerID},`, '')
				idToPass = idToPass.replace(`${ownerID}`, '')
			}
			setTeamMentions(idToPass)
		}

		// Check to avoid adding duplicate entries in mention
		const check = mentions.filter(mention => mention.id === idToPass);

		if (check.length === 0) {
			setMentions([...mentions, { name, id: idToPass }]);
		}
	};

	const clearTeamMentions = () => {
		setTeamMentions(null)
	}

	const handleSubmit = (e, isSend) => {
		e.preventDefault();
		let errors = {};
		let formIsValid = true;
		let fields = Object.assign({}, customerlog);
		let errorClass = 'input_error';

		for (let [key] of Object.entries(fields)) {
			if (customerlog.subType === 'call' && mandatoryCallLogFields && mandatoryCallLogFields.indexOf(key) >= 0 && (!fields[key] || fields[key] === 0)) {
				formIsValid = false;
				errors[key] = errorClass;
			}
		}

		if (isNoteEmpty && _.isEmpty(customerlog.notes) && _.isEmpty(customerlog.files)) {
			formIsValid = false;
			setIsNoteEmpty(true)
		}

		setErrors(errors);
		let _mentions = [];
		if (mentions?.length && customerlog?.notes) {
			_mentions = mentions.filter(a => customerlog.notes.includes(a.name));
		}

		if (formIsValid === true) {
			handlesavelog(e, customerlog, completedTab, _mentions, isSend, contact, enquiry, tradeinPro, service, eventsPro, eventsProAttendee);
		}

	}

	const onSelectFile = (e, type, id, title) => {

		if (e.target.files && e.target.files.length > 0) {
			if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif|pdf)$/i)) {
				Swal.fire(CommonHelper.showLocale({ dealersettings }, 'File format not supported. Please select image file.'), '', 'info');
				return;
			}
			var img = e.target.files[0].size;
			var imgsize = img / 1024 / 1024;
			if (imgsize > 0.5) {
				Swal.fire(CommonHelper.showLocale({ dealersettings }, 'File size exceeded. Maximum Size is 500KB '), '', 'info');
				return;
			}

			let reader = new FileReader();
			let file = e.target.files[0];

			reader.onloadend = () => {
				saveImageToStorage(
					file
				);

			}
			reader.readAsDataURL(file)
		}
	}

	const saveImageToStorage = (file) => {
		setImageLoader(true)
		const ext = file.name.split('.').pop();
		const fileName = moment()._d.getTime().toString() + '.' + ext;
		const uid = enquiry?.contact ? enquiry.contact.documentID : contact ? contact.documentID : enquiry ? enquiry.documentID : moment()._d.getTime().toString();
		var storageRef = storageBucket(dealersettings).ref(`${dealersettings.client.id}/messagemedia/${uid}/${fileName}`).put(file);
		storageRef.on('state_changed', (snapshot) => {
			var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;

		},
			(error) => {
				//console.log(error);
			},
			() => {
				storageBucket(dealersettings).ref(`${dealersettings.client.id}/messagemedia/${uid}`)
					.child(fileName)
					.getDownloadURL()
					.then(dataURL => {
						setImageLoader(false)
						setMMS({
							name: file.name,
							url: dataURL,
							size: file.size
						});
						handleFileChange(dataURL, file.name, customerlog)
					})
			})
	};

	return _.isEmpty(customerlog) ? (<></>) : (
		<>
			<div className='editor-textarea-wrap form-style'>
				{customerlog.subType === 'message' ? (
					<div className='form-row'>
						{
							justcallEnabled ? (
								<div className='form-group col-md-6'>
									<label ><Translate text={'Source Number'} /></label>
									<div className='inner-addon'>
										<ReactSelect
											options={sourceNumbers}
											placeholder='select phone number'
											name={'sourceNumber'}
											onChange={handleReactSelectChange}
											value={customerlog.sourceNumber}
											classNamePrefix={`${errors["sourceNumber"]} basic-select`}
										/>
									</div>
								</div>
							) : (<></>)
						}
						<div className={`form-group col-md-${justcallEnabled ? '3' : '6'}`}>
							<label ><Translate text={'date'} /></label>
							<div className='inner-addon'>
								<AntDatePicker
									value={moment
										.unix(customerlog.addedDate.seconds)
										.format('YYYY-MM-DD')}
									name={'addedDate'}
									onChange={e => {
										handleDateChange(e, 'addedDate');
									}}
									format='DD/MM/YYYY'
									placeholder='DD/MM/YYYY'
									className={`form-control`}
									disabledDate={(current) => { return current && current > moment().endOf('day') }}
								/>
							</div>
						</div>
						<div className={`form-group col-md-${justcallEnabled ? '3' : '6'}`}>
							<label ><Translate text={'time'} /></label>
							<div className='inner-addon right-addon'>
								<i className='bg-icons ico icon-time'></i>
								<ReactTimePicker
									value={moment.unix(customerlog.addedDate.seconds)._d}
									name={'addedDate'}
									onChange={handleTimeChange}
									timeIntervals={15}
									placeholder='h:mm aa'
									className={`form-control`}
									minTime={
										moment.unix(
											CommonHelper.getBaseDate(customerlog.addedDate).seconds,
										)._d
									}
									maxTime={
										moment().format('YYYY-MM-DD') !==
											moment
												.unix(customerlog.addedDate.seconds)
												.format('YYYY-MM-DD')
											? moment.unix(
												CommonHelper.getEndDate(customerlog.addedDate)
													.seconds,
											)._d
											: moment()._d
									}
								/>
							</div>
						</div>
					</div>
				) : customerlog.subType === 'call' ? (
					<div className='form-row'>
						<div className='form-group col-md-2'>
							<label ><Translate text={'date'} /></label>
							<div className='inner-addon'>
								{/* <i className="bg-icons ico icon-date"></i> */}
								{/* <ReactDatePicker
                                            value={moment.unix(customerlog.addedDate.seconds).format('YYYY-MM-DD')}
                                            name={'addedDate'}
                                            onChange={handleDateChange}
                                            format={dateFormat}
                                            placeholder='DD/MM/YYYY'
                                            className={`form-control`}
                                            maxDate={moment()._d}
                                            readOnly={true}
                                        /> */}
								<AntDatePicker
									value={moment
										.unix(customerlog.addedDate.seconds)
										.format('YYYY-MM-DD')}
									name={'addedDate'}
									onChange={e => {
										handleDateChange(e, 'addedDate');
									}}
									format='DD/MM/YYYY'
									placeholder='DD/MM/YYYY'
									className={`form-control`}
									disabledDate={(current) => { return current && current > moment().endOf('day') }}
								/>
							</div>
						</div>
						<div className='form-group col-md-2'>
							<label ><Translate text={'time'} /></label>
							<div className='inner-addon right-addon'>
								<i className='bg-icons ico icon-time'></i>
								<ReactTimePicker
									value={moment.unix(customerlog.addedDate.seconds)._d}
									name={'addedDate'}
									onChange={handleTimeChange}
									timeIntervals={15}
									placeholder='h:mm aa'
									className={`form-control`}
									minTime={
										moment.unix(
											CommonHelper.getBaseDate(customerlog.addedDate).seconds,
										)._d
									}
									maxTime={
										moment().format('YYYY-MM-DD') !==
											moment
												.unix(customerlog.addedDate.seconds)
												.format('YYYY-MM-DD')
											? moment.unix(
												CommonHelper.getEndDate(customerlog.addedDate)
													.seconds,
											)._d
											: moment()._d
									}
								/>
							</div>
						</div>
						<div className='form-group col-md-2'>
							<label ><Translate text={'Call Duration'} /></label>
							<div className='inner-addon right-addon'>
								<TimePicker
									value={CommonHelper.convertminstoDate(
										customerlog.callDuration,
									)}
									showHour={false}
									onChange={handleCalldurationChange}
									name={'callDuration'}
									allowEmpty={false}
									className={`input-time-picker ${errors["callDuration"] ? 'input_line_error' : ''}`}
									popupClassName='pop-time-picker'
								/>
							</div>
						</div>
						<div className='form-group col-md-3'>
							<label ><Translate text={'Call Type'} /></label>
							<div className='inner-addon right-addon'>
								<ReactSelect
									options={callTypes}
									name={'callType'}
									onChange={handleReactSelectChange}
									value={customerlog.callType}
									classNamePrefix={`${errors["callType"]} basic-select`}
								/>
							</div>
						</div>
						<div className='form-group col-md-3'>
							<label ><Translate text={'Call Status'} /></label>
							<div className='inner-addon right-addon'>
								<ReactSelect
									options={arrcallstatus}
									name={'callStatus'}
									onChange={handleReactSelectChange}
									value={customerlog.callStatus}
									classNamePrefix={`${errors["callStatus"]} basic-select`}
								/>
							</div>
						</div>
					</div>
				) : (
					<></>
				)}
				<div className='form-group mt-2'>
					{customerlog.subType !== 'note' ? (
						<label ><Translate text={customerlog.subType === 'message' ? 'Message' : 'notes'} /></label>
					) : (
						<></>
					)}

					{/* <textarea
						ref={textareaRef}
						id={`notes-tab-${customerlog.documentID}`}
						className='notes'
						name='notes'
						value={customerlog.notes}
						rows='3'
						placeholder='write something...'
						onChange={e => {
							handlechangelog(e, customerlog, completedTab);
						}}
						onFocus={e => {
							var temp_value = e.target.value;
							e.target.value = '';
							e.target.value = temp_value;
						}}
					></textarea> */}
					<SimpleMentionEditor
						smsTemplateData={smsTemplateData}
						clearSmsTemplateData={clearSmsTemplateData}
						mentionsData={mentionsData}
						onMentionChange={onMentionChange}
						onMentionSelect={onMentionSelect}
						notes={customerlog.notes}
						focusOnMount={true}
						isNoteEmpty={isNoteEmpty}
						shouldFocusOnMount={shouldFocusOnMount ? shouldFocusOnMount : false}
						teamMentions={teamMentions}
						clearTeamMentions={clearTeamMentions}
						clientUsers={clientUsers}
					// ownerID={customerlog.owner ? customerlog.owner : ''}
					/>
				</div>
				<div className='editmode-footer mt-0'>
					{
						((contact?.phone || enquiry?.contact?.phone || tradeinPro?.contact?.phone || service?.contact?.phone) && customerlog.type === CommonHelper.getActivityType('log', originType) && customerlog.subType === 'message' && (messageMediaEnabled || smsHubEnabled || podiumEnabled || cmdotcomEnabled || ringcentralEnabled || justcallEnabled))
							?
							<>
								<Dropdown
									className='template-sms-btn ml-2 float-left dropdown-toggle'
									alignRight
									ref={node}
									show={showPop}
								>
									<Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components' onClick={() => {
										setShowPop(!showPop)
									}}>
										<i className='ico icon-template'></i> <span><Translate text={'Templates'} /></span>
									</Dropdown.Toggle>
									<Dropdown.Menu
										as={CustomMenu}
										className='rm-pointers dropdown-menu-lg dropdown-menu-right template-add-dropdown'
										ChildClass='pipeline-dropdown'
										xplacement='top-end'
									>
										<Popover
											id='popover-basic-mail'
											className='popover popover-mail-templates popover-x in kv-popover-active show left left-bottom'
											style={{
												top: '-317px',
												left: '42px',
												display: 'block',
												zIndex: '1029',
												padding: '0px',
											}}

										>
											<Popover.Title>
												<h3 className='popover-header popover-title'>
													<span className='close' data-dismiss='popover-x' onClick={() => setShowPop(false)}>
														×
													</span>
													<Translate text={'Templates'} />{' '}
												</h3>
												<div className='email-list-search'>
													<div className='input-group template-search'>
														<input type='text' className='form-control' placeholder='Search' value={templateSearchText} onChange={e => setTemplateSearchText(e.target.value)} style={{ width: '100%' }} />
														<div className='input-group-append input-search-clear'>
															{templateSearchText.length > 0 && (
																<a href='#' className='input-search-clear-icon' onClick={() => setTemplateSearchText('')}>
																	<i className='ico icon-remove'></i>
																</a>
															)}
														</div>
													</div>
												</div>
											</Popover.Title>
											<Popover.Content>
												<div className='popover-body popover-content p-0'>
													<div className='mailtemplate-column-nav'>
														<ul>
															{templates && templates.length > 0 ? _.orderBy(templates, ['title'], ['asc']).filter(template => filterTemplates(template)).map((template, index) => (
																<li onClick={(e) => { e.preventDefault(); handleInsertTemplate(template); setShowPop(false); }} key={index}>
																	<a href='#' key={index}>{template.title}</a>
																</li>
															)) : (
																<>
																	<div className='no-data-flex h-100 justify-content-center align-items-center '>
																		<div className='no-data-img'>
																			{' '}
																			<img src={_images.nodata} width='60' height='60' alt='' />
																		</div>
																		<div className='no-data-txt mt-2'>
																			{' '}
																			<Translate
																				text={'No templates found'}
																			/>
																		</div>
																	</div>
																</>
															)}
														</ul>
													</div>
												</div>
											</Popover.Content>
										</Popover>

									</Dropdown.Menu>
								</Dropdown>
								{
									(messageMediaEnabled || justcallEnabled) ? mms ? (
										<div className="mms-attach-list ml-2">
											<div className="mms-attach-remove" onClick={(e) => {
												e.preventDefault();
												setMMS(null);
												handleFileChange(null, null, customerlog)
											}}> <i className="ico icon-remove"> </i></div>
											<a id="" href="#"><div className="mms-attach-text">{CommonHelper.showLimitedChar(mms.name, 18)}
											</div>
												<div className="mms-attach-size">	{' (' +
													CommonHelper.showFileSize(mms.size) +
													')'}
												</div>
											</a>
										</div>

									) : (
										<div
											className='settings-profile-upload'
											style={{ display: 'block', marginBottom: '0px' }}
										>
											<label
												htmlFor='logoURL'
												className={`btn btn-default float-left ml-2 font-600 email-attachments-btn`}
											>
												{
													imageLoader ? (
														<span
															className='spinner-border spinner-button mr-1'
															role='status'
															aria-hidden='true'
														></span>
													) : (<i className='ico icon-attachment'></i>)
												}
												Attach
												<input
													className='fileInput'
													type='file'
													name='testee'
													id='logoURL'
													accept='*'
													style={{ display: 'none' }}
													onChange={e => onSelectFile(e)}
													disabled={imageLoader ? true : false}
												/>
											</label>
										</div>
									) : (<></>)
								}
								{(contact?.phone || enquiry?.contact?.phone || tradeinPro?.contact?.phone || service?.contact?.phone) && (
									<button
										type='button'
										className='btn btn-green float-right ml-2'
										onClick={e => {
											handleSubmit(e, true);
										}}
										disabled={imageLoader}
									>
										{sendLoading ? (
											<span
												className='spinner-border spinner-button mr-1'
												role='status'
												aria-hidden='true'
											></span>
										) : (
											<></>
										)}
										<Translate text={'sendMessage'} />
									</button>
								)}
							</>
							:
							<></>
					}
					{
						customerlog.type === CommonHelper.getActivityType('log', originType) && customerlog.subType === 'note' && !_.isEmpty(enquiry) && localStorage.defaultModule === 'oem' ? (
							<div className="radio icheck-success float-left">
								<InputCheckBox
									id={`INTERNAL_NOTE_${customerlog.documentID}`}
									className="switch"
									name={'oemNote'}
									checked={Boolean(customerlog.oemNote)}
									onChange={(e) => { handleRadioChange(e, customerlog) }} />
								<label htmlFor={`INTERNAL_NOTE_${customerlog.documentID}`}><Translate text={'internalNote'} /></label>
							</div>
						) : (<></>)
					}
					<button
						type='button'
						className='btn btn-primary float-right ml-2'
						onClick={e => {

							handleSubmit(e, false);
						}}
					>
						{loading ? (
							<span
								className='spinner-border spinner-button mr-1'
								role='status'
								aria-hidden='true'
							></span>
						) : (
							<></>
						)}
						<Translate text={'save'} />
					</button>
					<button
						type='button'
						className='btn btn-default float-right'
						onClick={e => {
							handlecancellog(e, customerlog, completedTab);
						}}
					>
						<Translate text={'cancel'} />
					</button>
				</div>
			</div>
		</>
	);
};

export default EditLogs;
