import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash'
import toast from 'toasted-notes'
import { Dropdown } from 'react-bootstrap'
import { CustomToggle, CustomAdvFilters } from '../../components/customdropdown';
import { DefaultFilters, CustomFilters } from '../common'
import CalendarAdvanceFilters from './advanceFilters'
import Translate from '../../constants/translate';

const _defaultFilters = [
    { name: 'All Activities ', value: {}, type: 'default' },
    { name: 'Completed Activities', value: { 'isDone': true }, type: 'default' },
    { name: 'In-Completed Activities', value: { 'isDone': false }, type: 'default' }
]

const objActivityFilter = [
    "keywords", "date", "owner", "teams", "make", "subType", "isDone", "activityOutcome", "clientID", "excludeTrigger"
]

const Filters = (props) => {
    const [userID, setUserID] = useState('')
    const [show, setShow] = useState(false)
    const [searchField, setSearchField] = useState('')
    const [customFilters, setCustomFilters] = useState([])
    const [defaultFilters, setDefaultFilters] = useState(_defaultFilters)
    const [columnFields, setColumnFields] = useState(objActivityFilter)
    const [activeTab, setActiveTab] = useState('All')

    const { activityFilter } = props;

    const node = useRef();

    useEffect(() => {
        setCustomFilters(props.customFilters)
        setUserID(props.dealersettings.id)
    }, [props]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if ((node && node.current && node.current.contains(e.target)) ||
            (!_.isEmpty(e.target.classList) && e.target.classList.value.indexOf('ant-picker-') >= 0) ||
            (!_.isEmpty(e.target.classList) && e.target.classList.value.indexOf('swal2-') >= 0) ||
            (!_.isEmpty(e.target.classList) && e.target.classList.value.indexOf('filter-dont-close') >= 0) ||
            e.target.tagName === 'path' || e.target.tagName === 'svg' || e.target.classList.contains('basic-select__indicator')
        ) {
            return;
        }
        setShow(false);
    };

    const handleSearchField = (e) => {
        setSearchField(e.target.value);
        if (e.target.value) {
            setCustomFilters([...props.customFilters.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase()))])
            setDefaultFilters([..._defaultFilters.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase()))])
            setColumnFields([...objActivityFilter.filter(item => item.toLowerCase().includes(e.target.value.toLowerCase()))])
        }
        else {
            setCustomFilters(props.customFilters)
            setDefaultFilters(_defaultFilters)
            setColumnFields(objActivityFilter)
        }

    }

    const handleClearSearchField = (e) => {
        setSearchField('');
        setCustomFilters(props.customFilters)
        setDefaultFilters(_defaultFilters)
        setColumnFields(objActivityFilter)
    }



    const handleSelectFilter = (_filter) => {
        setShow(false);
        props.handleApplyFilter(_filter);
    }

    const displayFilterBadge = () => {
        if (!_.isEmpty(activityFilter) && !_.isEmpty(activityFilter.value)) {

            return <span className="badge badge-pill badge-advanced-filter">{Object.keys(activityFilter.value).length}</span>
        }
        else {
            return <></>
        }
    }

    const handleFilterClose = () => {
        setShow(false);
    }

    return (


        <Dropdown ref={node} alignRight show={show}>
            <div className="advanced-filter-btn mr-2 float-right">
                <Dropdown.Toggle as={CustomToggle} className="dropdown" onClick={() => {
                    setShow(!show)
                }}>
                    {displayFilterBadge()}
                    <i className="ico icon-filter"></i>
                    <span>
                        {
                            !_.isEmpty(activityFilter)
                                ?
                                <>
                                    {
                                        activityFilter.name && (activityFilter.type === 'default' || activityFilter.type === 'custom')
                                            ?
                                            activityFilter.name
                                            :
                                            <Translate text={'Filter'} />
                                    }
                                </>
                                :
                                <><Translate text={'All Activities'} /></>
                        }

                    </span>
                </Dropdown.Toggle>
                {
                    !_.isEmpty(activityFilter)
                        ?
                        <>
                            <a className="clear-btn" onClick={(e) => { e.preventDefault(); props.handleClearFilter(); }}> <i className="ico icon-remove"></i> </a>
                        </>
                        :
                        <></>
                }
            </div>
            <Dropdown.Menu as={CustomAdvFilters}
                id="table-dynamic-cols-settings-pipeline"
                className="dropdown-menu dropdown-menu-left"
                ChildClass="quick-filter-listing"
                xplacement="bottom-end"
                title={'Filters'}
                handleTextchange={handleSearchField}
                handleTextClear={handleClearSearchField}
                searchField={searchField}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                noSaveAccess={props.noSaveAccess}
                dealersettings={props.dealersettings}
            >
                {
                    props.noSaveAccess
                        ?
                        <>
                            <CalendarAdvanceFilters
                                columnFields={columnFields}
                                dealersettings={props.dealersettings}
                                clientUsers={props.clientUsers}
                                groupUsers={props.groupUsers}
                                activityFilter={activityFilter}
                                customFilters={customFilters}
                                handleClearFilter={props.handleClearFilter}
                                handleApplyFilter={props.handleApplyFilter}
                                handleFilterClose={handleFilterClose}
                                showDateFilter={props.showDateFilter}
                                noSaveAccess={props.noSaveAccess}
                            />
                        </>
                        :
                        <>
                            {(() => {
                                if (activeTab === 'All') {
                                    return (
                                        <div className="quick-filter-body">
                                            <ul className={'quick-filter-listing'}>
                                                {
                                                    defaultFilters && defaultFilters.length > 0
                                                        ?
                                                        <>
                                                            <ol className="quick-filter-subheader"><Translate text={'system'} /></ol>
                                                            {
                                                                defaultFilters.map((_filter, index) => {
                                                                    return (
                                                                        <DefaultFilters
                                                                            key={index}
                                                                            _filter={_filter}
                                                                            localFilter={activityFilter}
                                                                            handleSelectFilter={handleSelectFilter}
                                                                        ></DefaultFilters>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }

                                                {
                                                    customFilters && customFilters.length > 0
                                                        ?
                                                        <>
                                                            <ol className="quick-filter-subheader"><Translate text={'customFilters'} /></ol>
                                                            {
                                                                customFilters.map((_filter, index) => {
                                                                    return (
                                                                        <CustomFilters
                                                                            key={index}
                                                                            userID={userID}
                                                                            _filter={_filter}
                                                                            localFilter={activityFilter}
                                                                            handleSelectFilter={handleSelectFilter}
                                                                            dealersettings={props.dealersettings}
                                                                            clientUsers={props.clientUsers}
                                                                            customFilters={customFilters}
                                                                            module={'activities'}
                                                                        ></CustomFilters>
                                                                    )
                                                                })
                                                            }

                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                            </ul>
                                        </div>
                                    )
                                } else if (activeTab === 'Custom') {
                                    return (
                                        <div className="quick-filter-body">
                                            <ul className={'quick-filter-listing'}>
                                                {
                                                    customFilters && customFilters.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                customFilters.map((_filter, index) => {
                                                                    return (
                                                                        <CustomFilters
                                                                            key={index}
                                                                            userID={userID}
                                                                            _filter={_filter}
                                                                            localFilter={activityFilter}
                                                                            handleSelectFilter={handleSelectFilter}
                                                                            dealersettings={props.dealersettings}
                                                                            clientUsers={props.clientUsers}
                                                                            customFilters={customFilters}
                                                                            module={'activities'}
                                                                        ></CustomFilters>
                                                                    )
                                                                })
                                                            }

                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                            </ul>
                                        </div>
                                    )
                                } else if (activeTab === 'Favorites') {
                                    return (
                                        <div className="quick-filter-body">
                                            <ul className={'quick-filter-listing'}>
                                                {
                                                    customFilters && customFilters.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                customFilters.filter(e => !_.isEmpty(e.isFav) && e.isFav.indexOf(userID) >= 0).map((_filter, index) => {
                                                                    return (
                                                                        <CustomFilters
                                                                            key={index}
                                                                            userID={userID}
                                                                            _filter={_filter}
                                                                            localFilter={activityFilter}
                                                                            handleSelectFilter={handleSelectFilter}
                                                                            dealersettings={props.dealersettings}
                                                                            clientUsers={props.clientUsers}
                                                                            customFilters={customFilters}
                                                                            module={'activities'}
                                                                        ></CustomFilters>
                                                                    )
                                                                })
                                                            }

                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                            </ul>
                                        </div>
                                    )
                                } else if (activeTab === 'Filters') {
                                    return (
                                        <>
                                            <CalendarAdvanceFilters
                                                columnFields={columnFields}
                                                dealersettings={props.dealersettings}
                                                clientUsers={props.clientUsers}
                                                groupUsers={props.groupUsers}
                                                activityFilter={activityFilter}
                                                customFilters={customFilters}
                                                handleClearFilter={props.handleClearFilter}
                                                handleApplyFilter={props.handleApplyFilter}
                                                handleFilterClose={handleFilterClose}
                                                showDateFilter={props.showDateFilter}
                                            />
                                        </>
                                    )
                                }
                            })()}
                        </>
                }
            </Dropdown.Menu>
        </Dropdown>





    )
}

export default Filters