import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import moment from 'moment'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { Tabs, Tab } from 'react-bootstrap'
import { ContentOverlay, ImageCropHook, ReactSelect } from '../../../../components'
import PrivacyTerms from "./privacyTerms";
import AppSettings from "./appSettings";
import Translate from '../../../../constants/translate';
import { storageBucket, validateEmail } from "../../../../services/helper";
import EmailSettings from './emailSettings'
import CommonHelper from '../../../../services/common';
import images from '../../../../images';

const DealerSettings = (props) => {
    const getQueryparam = () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let key = params.get('key');
        if (!key) {
            key = "settings"
        }
        return key
    }
    const [dealer, setDealer] = useState(null);
    const [loader, setLoader] = useState(true)
    const [btnLoader, setBtnLoader] = useState(false)
    const [errorFields, setErrorFields] = useState({});
    const [currencies, setCurrencies] = useState([])
    const [oldDealerdata, setOldDealer] = useState(null);
    const [imageLoader, setImageLoader] = useState(false)
    const [cropShow, setCropShow] = useState({ show: false, clsActive: '', src: null, id: '', type: '', ext: '', title: '' })
    const [activeTab, setActiveTab] = useState(`${getQueryparam()}`);
    const [selectedLevel, setSelectedLevel] = useState({ level: props.dealersettings.level });
    const [regiongroupOptions, setRegionGroupOptions] = useState([])
    const [termsloader, setTermsLoader] = useState(false)
    const defaultSettings = {
        timezone: null,
        customerConsent: null,
        currentSettings: {
            leadVerification: null,
            isNextActivityMandatoryNE: null,
            isScheduleNextActivity: null,
            enableTwoWaySMS: null
        }
    }

    const fleetPro = props?.dealersettings?.client?.moduleSettings?.fleetPro?.enabled && props?.dealersettings?.client?.moduleSettings?.fleetPro?.active;
    const serviceFleetPro = props?.dealersettings?.client?.moduleSettings?.serviceFleetPro?.enabled && props?.dealersettings?.client?.moduleSettings?.serviceFleetPro?.active;

    const levelOptions = [
        { value: "oem", label: "OEM", index: 1 },
        { value: "region", label: "Region", index: 2 },
        { value: "subregion", label: "Subregion", index: 3 },
        { value: "group", label: "Group", index: 4 }
    ];

    useEffect(() => {
        if (_.isEmpty(props.dealersettings))
            return

        let refoemSettings = ''
        if (props.dealersettings.level === 'oem' && props.dealersettings.oemID)
            refoemSettings = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}`);
        else if (props.dealersettings.level === 'region' && props.dealersettings.regionID)
            refoemSettings = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/regions/${props.dealersettings.regionID}`);
        else if (props.dealersettings.level === 'group' && props.dealersettings.groupID)
            refoemSettings = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/groups/${props.dealersettings.groupID}`);
        else
            refoemSettings = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.id}`);

        refoemSettings.get().then(doc => {
            if (doc.exists) {
                setDealer(doc.data())
                setOldDealer(doc.data())
            }
            else {
                setDealer(defaultSettings)
                setOldDealer(defaultSettings)
            }
            setLoader(false)
        })
    }, [])

    useEffect(() => {
        if (!termsloader) {
            return;
        }

        let refoemSettings = ''
        if (selectedLevel.level === 'oem' && props.dealersettings.oemID)
            refoemSettings = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}`);
        else
            refoemSettings = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/${selectedLevel.level}s/${selectedLevel.levelID}`);

        refoemSettings.get().then(doc => {
            if (doc.exists) {
                setDealer(doc.data())
                setOldDealer(doc.data())
            }
            else {
                setDealer(defaultSettings)
                setOldDealer(defaultSettings)
            }
            setTermsLoader(false)
        })

    }, [termsloader])

    useEffect(() => {
        let allClients = [];
        if (props?.dealersettings?.clients)
            allClients = props.dealersettings.clients;

        if (selectedLevel.level !== 'oem') {
            setRegionGroupOptions(props?.dealersettings?.[`${selectedLevel.level}s`]?.filter(a => allClients.some(b => b[selectedLevel.level] === a.id))?.map(r => {
                return {
                    label: r.name,
                    value: r.id
                }
            }) || [])
        }
    }, [selectedLevel.level])

    useEffect(() => {
        getCurrencies();
    }, [])

    async function getCurrencies() {
        var baseURL = props.dealersettings.baseURL;
        if (process.env.NODE_ENV === 'development')
            baseURL = 'http://localhost:3000/';
        const response = await fetch(`${baseURL}/lookups/currencies.json`);
        const respJson = await response.json();
        setCurrencies(respJson);
    }

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (name === 'email' || name === 'website') {
            setDealer({
                ...dealer,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setDealer({
                    ...dealer,
                    [name]: newarray1.join(' ')
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setDealer({
                    ...dealer,
                    [name]: str
                });
            }
        }
    }

    const handleNumberChange = e => {
        var regexp = /^[0-9+ \b]+$/;
        let number = e.target.value;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setDealer({
                ...dealer,
                [e.target.name]: e.target.value
            });
        }
    };

    const handleReactSelectChange = (e, data) => {
        if (data.name === 'currency') {
            setDealer({
                ...dealer,
                [data.name]: e ? { code: e.data.code, symbol: e.data.symbol } : ''
            });
        }
        else {
            setDealer({
                ...dealer,
                [data.name]: e ? e.value : '',
                ['countryCode']: (data.name === 'country' && e) ? e.value : dealer.countryCode
            });
        }
    }

    const onEditorStateChange = (obj) => {
        setDealer({
            ...dealer,
            [obj.name]: obj.value
        });
    }

    const handleMailgunOnChange = e => {
        setDealer({
            ...dealer,
            ['clientSettings']: dealer.clientSettings ? {
                ...dealer.clientSettings,
                'mailgunconfig': {
                    ...dealer.clientSettings.mailgunconfig,
                    [e.target.name]: e.target.value
                }
            } : {
                'mailgunconfig': {
                    [e.target.name]: e.target.value
                }
            }
        });
    };

    const saveDealer = (smtpVerified, awsVerified, resetSMTP, resetAWSSES) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        setBtnLoader(true)
        dealer.modifiedDate = window.firebase.firestore.Timestamp.now();
        dealer.modifiedBy = localStorage.uid;
        if (!_.isEmpty(oldDealerdata.clientSettings) && (oldDealerdata.clientSettings.smtpFrom !== dealer.clientSettings.smtpFrom || oldDealerdata.clientSettings.smtpUri !== dealer.clientSettings.smtpUri)) {
            dealer.clientSettings.smtpVerified = false
        }
        if (!_.isEmpty(oldDealerdata.clientSettings) && !_.isEmpty(oldDealerdata.clientSettings.awsses) && (oldDealerdata.clientSettings.awsses.email !== dealer.clientSettings.awsses.email)) {
            dealer.clientSettings.awsses.pending = false
            dealer.clientSettings.awsses.verified = false
        }

        if (smtpVerified) {
            dealer.clientSettings.smtpVerified = true
        }

        if (awsVerified) {
            dealer.clientSettings.awsses.pending = true
        }

        if (resetSMTP) {
            dealer.clientSettings.smtpVerified = false
            dealer.clientSettings.smtpFrom = ''
            dealer.clientSettings.smtpUri = ''
        }

        if (resetAWSSES) {
            dealer.clientSettings.awsses = {}
        }
        let refoemSettings = ''
        if (!_.isEmpty(selectedLevel?.levelID) && selectedLevel.level !== 'oem') {
            refoemSettings = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/${selectedLevel.level}s/${selectedLevel.levelID}`);
        } else {
            if (props.dealersettings.level === 'oem' && props.dealersettings.oemID)
                refoemSettings = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}`);
            else if (props.dealersettings.level === 'region' && props.dealersettings.regionID)
                refoemSettings = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/regions/${props.dealersettings.regionID}`);
            else if (props.dealersettings.level === 'group' && props.dealersettings.groupID)
                refoemSettings = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/groups/${props.dealersettings.groupID}`);
            else
                refoemSettings = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.id}`);
        }

        refoemSettings.set(dealer, { merge: true })
            .then(snapshot => {
                setBtnLoader(false)
                toast.notify('Settings updated successfully', {
                    duration: 2000
                })

                if (!_.isEmpty(props.dealersettings.client)) {
                    window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: 'Client Settings has been updated',
                        }, { merge: true })
                }
                if (localStorage.defaultModule === 'oem' && props.dealersettings.oemID) {
                    window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: 'Client Settings has been updated',
                        }, { merge: true })
                }
            })
            .catch(error => {
                setBtnLoader(false)
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    const handleOnChangeMultipleText = (val, name) => {
        setDealer({
            ...dealer,
            ['clientSettings']: {
                ...dealer.clientSettings,
                [name]: !_.isEmpty(val) ? val.join(',') : ''
            }
        })
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleCheckChange = (e) => {
        setDealer({
            ...dealer,
            ['clientSettings']: {
                ...dealer.clientSettings,
                [e.target.name]: e.target.checked
            }
        })
    }

    const handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: ''
        });
    }

    const handleCropSelect = (_cropShow) => {

        const { src, id, type, ext } = _cropShow;
        setDealer({
            ...dealer,
            [id]: src
        });
        saveImageToStorage(src, id, type, ext);

        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: ''
        })
    }

    const onSelectFile = (e, type, id, title) => {

        if (e.target.files && e.target.files.length > 0) {
            if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
                Swal.fire(CommonHelper.showLocale(props, 'File format not supported. Please select image file.'), '', 'info');
                return;
            }
            var img = e.target.files[0].size;
            var imgsize = img / 1024 / 1024;
            if (imgsize > 10) {
                Swal.fire(CommonHelper.showLocale(props, 'Maximum file size exceeded.'), '', 'info');
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {
                setCropShow({
                    show: true,
                    clsActive: 'overlay-modal active',
                    src: reader.result,
                    id: id,
                    type: type,
                    ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                    title: title
                })

            }
            reader.readAsDataURL(file)
        }
    }

    const saveImageToStorage = (imageBase64, id, type, ext) => {

        var fileName = 'logo.' + ext;

        var storageRef = storageBucket(props.dealersettings).ref(`${dealer.documentID}/${fileName}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;
            if (percentage >= 0 && percentage < 99) {
                setImageLoader(true)
            }

        },
            (error) => {
                //console.log(error);
            },
            () => {
                storageBucket(props.dealersettings).ref(`${dealer.documentID}/`).child(fileName).getDownloadURL()
                    .then(dataURL => {
                        setDealer({
                            ...dealer,
                            [id]: dataURL
                        });
                        setImageLoader(false)
                    })
            })
    };

    const bindAddress = (place) => {
        var objDealer = dealer;
        let componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            sublocality_level_1: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'short_name',
            //administrative_area_level_1: 'long_name',
            //country: 'long_name',
            postal_code: 'short_name'
        };
        if (place.formatted_address !== null)
            objDealer = {
                ...objDealer,
                ['address']: place.formatted_address
            };

        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (componentForm[addressType]) {
                var val = place.address_components[i][componentForm[addressType]];
                if (addressType === "administrative_area_level_1")
                    objDealer = {
                        ...objDealer,
                        ['state']: val
                    };

                if (addressType === "country")
                    objDealer = {
                        ...objDealer,
                        ['country']: val,
                        ['countryCode']: val
                    };
            }
        }
        setDealer(objDealer);
    };

    const handleSMTPOnChange = (e) => {
        setDealer({
            ...dealer,
            ['clientSettings']: dealer.clientSettings ? {
                ...dealer.clientSettings,
                [e.target.name]: e.target.value
            } : {
                [e.target.name]: e.target.value
            }
        });
    }

    const handleAWSSESOnChange = e => {
        setDealer({
            ...dealer,
            ['clientSettings']: dealer.clientSettings ? {
                ...dealer.clientSettings,
                'awsses': {
                    ...dealer.clientSettings.awsses,
                    [e.target.name]: e.target.value
                }
            } : {
                'awsses': {
                    [e.target.name]: e.target.value
                }
            }
        });
    };

    return (
        <>
            <h2>{`${props.dealersettings.level === 'oem' ? 'OEM' : props.dealersettings.level === 'region' ? 'Region' : props.dealersettings.level === 'group' ? 'Group' : 'Dealer'} Settings`}</h2>
            <div className="divider-line"></div>
            {
                Boolean(props.dealersettings.superAdmin) ? (
                    <div className='settings-head form-style mt-3'>
                        <div className='row'>
                            <div className='col-md-9'>
                                <div className="row">
                                    <div className="col-md-3 pr-0">
                                        <ReactSelect
                                            options={levelOptions}
                                            name={"level"}
                                            placeholder={"select level"}
                                            onChange={(e, data) => {
                                                if (e?.value === 'oem') {
                                                    setTermsLoader(true);
                                                }
                                                setSelectedLevel(e ? {
                                                    level: e.value,
                                                } : null);
                                            }}
                                            value={selectedLevel ? selectedLevel.level : ""}
                                            classNamePrefix={`cursor-pointer basic-select`}
                                            removeClearable={true}
                                        ></ReactSelect>
                                    </div>
                                    {!_.isEmpty(selectedLevel) && selectedLevel.level !== "oem" ? (
                                        <div className="col-md-3 pr-0">
                                            <ReactSelect
                                                options={regiongroupOptions}
                                                name={"levelID"}
                                                placeholder={selectedLevel.level}
                                                onChange={(e, data) => {
                                                    if (e) {
                                                        setTermsLoader(true);
                                                        setSelectedLevel({
                                                            ...selectedLevel,
                                                            levelID: e.value,
                                                        });
                                                    } else setSelectedLevel(null);
                                                }}
                                                value={selectedLevel ? selectedLevel.levelID : ""}
                                                classNamePrefix={`basic-select`}
                                                removeClearable={true}
                                            ></ReactSelect>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (<></>)
            }
            <div className="settings-tab-wrap tabs">
                <div className="tabs-wraper">
                    {
                        (loader || termsloader || _.isEmpty(dealer)) ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                            (selectedLevel.level === "oem" || !_.isEmpty(selectedLevel.levelID)) ? (<>
                                <nav>
                                    <Tabs defaultActiveKey={`${getQueryparam()}`} transition={false} className="nav-fill" onSelect={(key) => {
                                        setActiveTab(key)
                                    }}>
                                        <Tab eventKey="settings" title="Settings">
                                            <AppSettings
                                                dealer={dealer}
                                                currencies={currencies}
                                                countries={props.dealersettings.countries}
                                                states={props.dealersettings.states}
                                                handleReactSelectChange={handleReactSelectChange}
                                                handleNumberChange={handleNumberChange}
                                                handleOnChange={handleOnChange}
                                                handleOnChangeMultipleText={handleOnChangeMultipleText}
                                                handleCheckChange={handleCheckChange}
                                                deleteLogo={() => {
                                                    setDealer({
                                                        ...dealer,
                                                        'logoURL': ''
                                                    })
                                                }}
                                                imageLoader={imageLoader}
                                                onSelectFile={onSelectFile}
                                                bindAddress={bindAddress}
                                            />
                                        </Tab>
                                        <Tab eventKey="privacyTerms" title="Privacy Terms">
                                            <div className='settings-head form-style mb-0'>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <button type="button" className="btn btn-primary float-right" onClick={() => saveDealer()}>
                                                            {
                                                                btnLoader ?
                                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                    : (<></>)
                                                            }
                                                            <Translate text={'save'} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <PrivacyTerms
                                                dealer={dealer}
                                                fleetPro={fleetPro}
                                                serviceFleetPro={serviceFleetPro}
                                                onEditorStateChange={onEditorStateChange}
                                            />
                                        </Tab>
                                        <Tab eventKey="email" title="Email SMTP">
                                            <EmailSettings
                                                dealer={dealer}
                                                dealersettings={props.dealersettings}
                                                setDealer={(dealer => {
                                                    setDealer(dealer)
                                                })}
                                                errorFields={errorFields}
                                                handleSMTPOnChange={handleSMTPOnChange}
                                                handleAWSSESOnChange={handleAWSSESOnChange}
                                                saveDealer={saveDealer}
                                                validateEmail={validateEmail}
                                                handleMailgunOnChange={handleMailgunOnChange}
                                            />
                                        </Tab>
                                    </Tabs>
                                </nav>
                                {(activeTab === 'email' || activeTab === 'privacyTerms') ? (<></>) : <div className="settings-footer mt-3">
                                    {/* <button type="button" className="btn btn-default float-left" onClick={sendPushNotification}>
                                        {
                                            notifyloader ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        <Translate text={'Push notification'}/>
                                    </button> */}
                                    <button type="button" className={`btn btn-primary float-right ${imageLoader === true ? 'btn-disable' : ''}`} onClick={() => saveDealer()}>
                                        {
                                            btnLoader ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        <Translate text={'save'} />
                                    </button>
                                </div>}
                            </>) : (
                                <div style={{ height: "45vh" }}>
                                    <div className="spinner-loader h-100">
                                        <div
                                            className="d-flex h-100 justify-content-center align-items-center text-primary"
                                            style={{ flexDirection: "column" }}
                                        >
                                            <div className="no-data-img">
                                                {" "}
                                                <img src={images.nodata} width="60" height="60" alt="" />
                                            </div>
                                            <div className="no-data-txt mt-2">
                                                <p>{`Please select ${selectedLevel
                                                    ? selectedLevel.level === "individual"
                                                        ? "client"
                                                        : selectedLevel.level
                                                    : ""
                                                    }`}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </div>
            </div >
            <ImageCropHook
                cropShow={cropShow}
                handleClose={handleCropClose}
                handleSelect={handleCropSelect}
            ></ImageCropHook>
        </>
    )
};
export default DealerSettings;