/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import CommonHelper from '../../../services/common';
import moment from 'moment';
import { AntDatePicker, InputRadio, InputText, InputTextArea, PopUpModal, ReactSelect, ReactTimePicker } from '../../../components';
import "../../../styles/attendeeConfirmation.scss";
import _ from 'lodash'
import AddGuest from './addGuest';
import images from '../../../images';
import Swal from 'sweetalert2';
import uuid from 'react-uuid';

const AttendeeConfirmation = props => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const _eid = params.get('eid');
    const _sts = params.get('sts');
    const [firestoreDB, setFirestoreDB] = useState()
    const [submitLoader, setSubmitLoader] = useState(false);
    const [projectId, setProjectId] = useState()
    const [event, setEvent] = useState()
    const [contact, setContact] = useState()
    const [settings, setSettings] = useState()
    const [client, setClient] = useState()
    const [loader, setLoader] = useState(true);
    const [guestModel, setGuestModel] = useState({ show: false });
    const [contactStatus, setContactStatus] = useState();
    const [clientSettings, setClientSettings] = useState()
    const [notes, setNotes] = useState()
    // const [phoneCodes, setPhoneCodes] = useState([]);
    const [dealers, setDealers] = useState([])
    const [errorFields, setErrorFields] = useState({});
    const [selectedClient, setSelectedClient] = useState()


    useEffect(() => {
        if (props.contactid) {
            window.firebase.firestore().doc(`eventsProAttendees/${props.contactid}`).get()
                .then(doc => {
                    if (doc.exists) {
                        setFirestoreDB(window.firebase)
                    }
                    else {
                        setFirestoreDB(window.firebase2)
                        setProjectId(window.secondaryProjectId);
                    }
                })
        }
        else {
            setFirestoreDB(window.firebase)
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return

        let _promise = [];
        _promise.push(firestoreDB.firestore().collection(`eventsPro`).doc(_eid).get());
        _promise.push(firestoreDB.firestore().collection(`eventsPro`).doc(_eid).collection('settings').doc(_eid).get());
        _promise.push(firestoreDB.firestore().collection(`eventsProAttendees`).doc(props.contactid).get());

        Promise.all(_promise)
            .then(async docs => {
                if (docs[0]?.exists) {
                    setEvent(docs[0].data())
                    if (docs[0].data().clientID) {
                        const clientDoc = await window.firebase.firestore().collection(`clients`).doc(docs[0].data().clientID).get();
                        if (clientDoc.exists) {
                            setClient(clientDoc.data());
                        }
                        const settingsDoc = await window.firebase.firestore().doc(`clients/${docs[0].data().clientID}/currentSettings/${docs[0].data().clientID}/eventsPro/${docs[0].data().clientID}`).get();
                        if (settingsDoc.exists) {
                            setClientSettings(settingsDoc.data());
                        }
                    }
                    else if (docs[0].data().oemID) {
                        const clientDoc = await window.firebase.firestore().collection(`clientSettings`).doc(docs[0].data().oemID).get();
                        if (clientDoc.exists) {
                            setClient({
                                ...clientDoc.data(),
                                settingsID: docs[0].data().oemID
                            });
                        }
                        const settingsDoc = await window.firebase.firestore().doc(`clientSettings/${docs[0].data().oemID}/eventsPro/${docs[0].data().oemID}`).get();
                        if (settingsDoc.exists) {
                            setClientSettings(settingsDoc.data());
                        }
                    }
                    // const responseCountries = await fetch(`${window.location.origin}/lookups/countries.json`);
                    // const countriesjson = await responseCountries.json();
                    // let _phoneCodes = [];
                    // countriesjson.forEach((doc) => {
                    //     if (doc.phone_code) {
                    //         _phoneCodes.push({
                    //             country_code: doc.code,
                    //             value: doc.phone_code,
                    //             active: _.isBoolean(doc.active) ? doc.active : true,
                    //             label: doc.name + ' (' + (doc.phone_code ? doc.phone_code : '--') + ')'
                    //         });
                    //     }
                    // });
                    // setPhoneCodes(_phoneCodes);
                }
                if (docs[1]?.exists) {
                    setSettings(docs[1].data())
                }
                if (docs[2]?.exists) {
                    setContact({
                        ...docs[2].data(),
                        preferredRetailer: null
                    })
                    setContactStatus(docs[2].data().isSubmitted);
                    if (docs[2].data().notes && _.find(docs[2].data().notes, { addedBy: 'Attendee' })) {
                        setNotes(_.find(docs[2].data().notes, { addedBy: 'Attendee' }).notes)
                    }
                }
                setLoader(false)
            })

    }, [firestoreDB]);


    useEffect(() => {
        if (_.isEmpty(event))
            return
        let clientPromise = [];
        if (event?.clientIDs?.length) {
            for (let k = 0; k < Math.ceil(event.clientIDs.length / 10); k++) {
                clientPromise.push(window.firebase.firestore().collection(`clients`).where('documentID', 'in', CommonHelper.arrPaginate(event.clientIDs, 10, k + 1)).get())
            }
        } else if (event?.level !== 'individual' && event?.levelID?.length) {
            for (let k = 0; k < Math.ceil(event.levelID.length / 10); k++) {
                clientPromise.push(window.firebase.firestore().collection(`clients`).where(event?.level, 'in', CommonHelper.arrPaginate(event.levelID, 10, k + 1)).get())
            }
        }
        if (clientPromise.length) {
            Promise.all(clientPromise)
                .then(alldocs => {
                    let _dealers = [];
                    alldocs.forEach(docs => {
                        docs.forEach(doc => {
                            _dealers.push({
                                ...doc.data(),
                                label: doc.data().name,
                                value: doc.id,
                                documentID: doc.id
                            })
                        })
                    })
                    setDealers(_.orderBy(_dealers, 'label', 'asc'))
                })
        }
    }, [event])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (name === 'email') {
            setContact({
                ...contact,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setContact({
                    ...contact,
                    [name]: newarray1.join(' ')
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setContact({
                    ...contact,
                    [name]: str
                });
            }
        }
    }


    const handleReactSelectChange = (e, data) => {
        if (data.name === 'preferredRetailer') {
            setContact({
                ...contact,
                [data.name]: e ? e.value : null,
            })
            setSelectedClient(e || null)
        } else {
            setContact({
                ...contact,
                [data.name]: e ? e.value : null
            })
        }

    }

    const handleNotesChange = (e) => {
        e.preventDefault()
        const { value } = e.target;
        setNotes(value)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        let categories = clientSettings?.categories;
        let category = {};
        if (categories?.length > 0) {
            categories.map(rec => { return category = { ...category, [rec.value]: false } });
        }
        else {
            category = {
                invitation: false
            }
        }
        const _reqObject = {
            attendeeID: props.contactid,
            attendee: {
                ...CommonHelper.removeEmptyOrNull(_.pick(contact, ['firstName', 'middleName', 'lastName', 'phone', 'email', 'preferredRetailer', 'preferredDateTime'])),
                preferredDate: contact?.preferredDateTime?.seconds ? moment.unix(contact.preferredDateTime.seconds).format(`${settings?.dateFormat || 'DD/MM/YYYY'} ${settings?.timeFormat || 'hh:mm A'}`) : null,
                isSubmitted: true
            },
            eventsProID: _eid,
            projectId: projectId,
            category,
            createInbound: true,
            eventsPro: CommonHelper.removeEmptyOrNull(_.pick(event, ['description', 'documentID', 'eventProID', 'location', 'owner', 'startDate', 'endDate', 'status', 'title'])),
            inboundClientID: selectedClient?.documentID || null,
            inboundProjectId: selectedClient?.projectId || null
        }
        if (!_.isEmpty(notes)) {
            _reqObject.notes = notes
        }
        if (!_.isEmpty(contact.phone)) {
            if (contact.phone.length < 10) {
                formIsValid = false;
                errors['phone'] = errorClass;
            }
        }
        if (_.isEmpty(contact.preferredRetailer)) {
            formIsValid = false;
            errors['preferredRetailer'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setSubmitLoader(true)
        try {
            if (client?.settingsID && selectedClient?.documentID) {
                window.firebase.firestore().doc(`clientSettings/${client.settingsID}/clients/${selectedClient?.documentID}`).get()
                    .then((doc) => {
                        if (!_.find(doc?.data()?.leadSource, { value: _eid })) {
                            window.firebase.firestore().doc(`clientSettings/${client.settingsID}/clients/${selectedClient?.documentID}`).update({
                                leadSource: window.firebase.firestore.FieldValue.arrayUnion({
                                    active: true,
                                    level: "individual",
                                    name: event.title,
                                    value: _eid
                                })
                            })
                        }
                    })
            }
        } catch (err) { }


        const genericSaveData = window.firebase.functions().httpsCallable('eventsPro-registerAttendee');
        genericSaveData(_reqObject).then((response) => {
            //console.log(response);
            if (response.data.success) {
                setSubmitLoader(false)
                setContactStatus(true)
                Swal.fire('Thank you for registering your interest.', '', 'success')
            }
            else {
                setSubmitLoader(false)
                Swal.fire('Something went wrong.', '', 'error')
            }
        });
    }

    const handleDateChange = (val, name) => {
        if (val) {
            const time = contact?.preferredDateTime ? moment.unix(contact?.preferredDateTime.seconds).format('HH:mm') : moment().format('HH:mm');
            setContact({
                ...contact,
                [name]: val ? window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + time).toDate()) : null
            });
        }
    };

    const handleTimeChange = (val, name) => {
        setContact({
            ...contact,
            [name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d),
        });
    };


    const handleNumberChange = e => {
        var regexp = /^[0-9+ \b]+$/;
        let number = e.target.value;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setContact({
                ...contact,
                [e.target.name]: e.target.value
            });
        }
    };

    return loader ? (
        <>
            <div className='loader-center-screen'>
                <div className='spinner-loader h-100 '>
                    <div className='d-flex h-100 justify-content-center align-items-center'>
                        <div role='status' className='spinner-border'></div>
                        <p className='text-muted mt-3'></p>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <main role="main">
            <div className="eventslanding-header">
                <div className="container">
                    <div className="eventslanding-logo-wrap"> <span className="eventslanding-logo-img mr-3">
                        {
                            client?.logoURL
                                ?
                                <img src={client.logoURL} height="60" />
                                :
                                <div className='eventslanding-info-card-title'>{event.title}</div>
                        }
                    </span>
                        <div className="eventslanding-logo-title"> </div>
                    </div>
                </div>
            </div>

            <div className="eventslanding-banner">
                {
                    event.imageURL ? (
                        <img src={event.imageURL} />
                    ) : (<></>)
                }

            </div>


            <div className="container">

                <div className="eventslanding-wrap-bg ">
                    <div className={`eventslanding-wrap ${contactStatus ? 'form-disable' : ''}`}>




                        <div className="eventslanding-question-loop">


                            <div className="eventslanding-info-card">

                                <div className="eventslanding-info-card-title">Contact Form</div>

                                {/* <div className="eventslanding-sub-info ">
                                    <div className="float-left mt-1">
                                        <ul className="eventslanding-sub-details">

                                            <li> <i className="ico icon-location"></i> {event.location} </li>
                                            <li> <i className="ico icon-date"></i>{CommonHelper.showCombineDate(event.startDate, event.endDate)}</li>
                                            <li><i className="ico icon-time"></i>{CommonHelper.showCombineTime(event.startDate, event.endDate)}</li>

                                        </ul>
                                    </div>

                                </div> */}

                            </div>
                            <div className="eventslanding-description">
                                {
                                    event.description ? (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: event.description,
                                            }}
                                        ></div>
                                    ) : (<></>)
                                }
                            </div>

                            <div className="eventslanding-subheader-info"><i className="ico icon-Contacts"></i> Contact Details</div>


                            <div className="eventslanding-field-wraper form-style ">

                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label >First Name</label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder={'first name'}
                                            className={`form-control ${errorFields["firstName"]} `}
                                            name="firstName"
                                            onChange={handleOnChange}
                                            value={contact.firstName}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Middle Name</label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder={'middle name'}
                                            className={`form-control ${errorFields["middleName"]} `}
                                            name="middleName"
                                            onChange={handleOnChange}
                                            value={contact.middleName}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Last Name</label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder={'last name'}
                                            className={`form-control ${errorFields["lastName"]} `}
                                            name="lastName"
                                            onChange={handleOnChange}
                                            value={contact.lastName}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Phone</label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder={'phone'}
                                            className={`form-control ${errorFields["phone"]}`}
                                            name="phone"
                                            onChange={handleNumberChange}
                                            value={contact.phone}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Email</label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder={'email'}
                                            className={`form-control ${errorFields["email"]} `}
                                            name="email"
                                            onChange={handleOnChange}
                                            value={contact.email}
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label >Preferred Date</label>
                                        <AntDatePicker
                                            value={contact.preferredDateTime ? moment.unix(contact.preferredDateTime.seconds).format('YYYY-MM-DD') : null}
                                            name={'preferredDateTime'}
                                            onChange={e => {
                                                handleDateChange(e, 'preferredDateTime');
                                            }}
                                            format='DD/MM/YYYY'
                                            placeholder='DD/MM/YYYY'
                                            className={`form-control`}
                                            disabledDate={(current) => { return current && current < moment().startOf('day') }}
                                        />
                                    </div>
                                    <div className="form-group col-md-1">
                                        <label >Time</label>
                                        <ReactTimePicker
                                            value={contact.preferredDateTime ? moment.unix(contact.preferredDateTime.seconds)._d : null}
                                            name={'preferredDateTime'}
                                            onChange={handleTimeChange}
                                            timeIntervals={15}
                                            placeholder='h:mm aa'
                                            className={`form-control`}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Preferred Retailer</label>
                                        <ReactSelect
                                            options={dealers.map(rec => {
                                                return {
                                                    ...rec,
                                                    value: rec.label
                                                }
                                            })}
                                            name={"preferredRetailer"}
                                            placeholder={'select preferred retailer'}
                                            onChange={handleReactSelectChange}
                                            value={contact.preferredRetailer}
                                            classNamePrefix={`${errorFields["preferredRetailer"]} basic-select`}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-12 pr-0 pl-0">
                                <label >Comments</label>
                                <InputTextArea
                                    className={`form-control notes`}
                                    name="notes"
                                    onChange={handleNotesChange}
                                    value={notes}
                                    maxLength="280"
                                    rows="3">
                                </InputTextArea>
                            </div>
                            {
                                !contactStatus ? (
                                    <div className="eventslanding-footer">

                                        <button type="button" className={`btn btn-primary float-right ${submitLoader ? 'btn-disable' : ''}`} onClick={(e) => handleSubmit(e)}>
                                            {
                                                submitLoader ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Submit your Request</button>
                                    </div>
                                ) : (
                                    <div className="alert alert-success mt-2" role="alert">
                                        Thank you for registering your interest.
                                    </div>
                                )
                            }


                            <div className="eventslanding-footer-bottom">
                                <div className="eventslanding-footer-bottom-sub">
                                    <div className="eventslanding-cpy-wrap"> <img src={images.safeTag} alt="" width="120" /></div>
                                    <div className="eventslanding-cpy-wrap"> Contact us if you are interested in joining one of our events or would like more information at {client?.email}, or phone {client?.phone}</div>
                                    <div className="clear"></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </main>
    );
};

export default AttendeeConfirmation;
