import React, { useContext, useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import moment from 'moment';
import { Tabs, Tab } from 'react-bootstrap';
import RosterList from './list'
import Translate from '../../../../constants/translate';
import { UpgradePlan } from '../../../../components'
import { TemplateContext } from '../templateContext'
import { clientLookups, levelOptions, rosterLookupVM } from './viewModel';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators'
import { doc } from 'rxfire/firestore';
import toast from 'toasted-notes'
import Swal from 'sweetalert2';
import cronstrue from 'cronstrue';
import { MainContext } from '../../../layout/provider';
import CommonHelper from '../../../../services/common';

const RosterSettings = (props) => {
	const { clients } = useContext(TemplateContext);
	const [loader, setLoader] = useState(true);
	const [clientOptions, setClientOptions] = useState([]);
	const [state, setState] = useState({});
	const [activeTab, setActiveTab] = useState(`rosterHolidays`);
	const { updateDealerSettings } = useContext(MainContext);

	const liteVersion = ((!_.isEmpty(props.dealersettings) &&
		!_.isEmpty(props.dealersettings.client) &&
		!_.isEmpty(props.dealersettings.client.clientSettings) &&
		props.dealersettings.client.clientSettings.liteVersion) ? true : false);

	useEffect(() => {
		if (!_.isEmpty(clients)) {
			let _clients = [];
			_.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(_client => {
				_clients.push({ label: _client.name, value: _client.documentID, data: _client, active: _.isBoolean(_client.active) ? _client.active : true, })
			});
			setClientOptions(_clients)
		}
	}, [clients])

	useEffect(() => {
		if (_.isEmpty(clientOptions))
			return

		let clientPromise = []
		clientOptions.forEach(rec => {
			let snapDataRef = window.firebase.firestore().doc(`clients/${rec.value}/roster/${rec.value}`)

			clientPromise.push(doc(snapDataRef));
		})
		var alllookupsettings = combineLatest(clientPromise)
			.pipe(
				map((lookups) => {
					let _allClients = [];
					lookups && lookups.forEach(client => {
						if (client.exists)
							_allClients.push({ ...client.data(), id: client.id, documentID: client.id, name: _.find(clientOptions, { value: client.id }) ? _.find(clientOptions, { value: client.id }).label : '' })
					});
					return _allClients;
				}))
			.subscribe(allDocs => {
				var lookups = [];
				allDocs.forEach(rec => { _.isArray(rec) ? rec.forEach(e => lookups.push(e)) : lookups.push(rec) })
				let settingsDoc = {};
				lookups.forEach(rec => {
					clientLookups.filter(e => e.module === 'Roster').forEach((key) => {
						if (rec[key.value]) {
							rec[key.value].forEach((_lookups, index) => {
								if (settingsDoc[key.value]) {
									settingsDoc[key.value] = [
										...settingsDoc[key.value],
										{
											..._lookups,
											'strName': key.value === 'rosterCategories' ? (<div className="drop-image"><span className="d-inline-flex badge badge-pill roster-dropdown-badge-counter ml-1" style={{ background: _lookups.color }}>{_lookups.value}</span>{_lookups.name}</div>)
												: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: _lookups.color }}></div>{_lookups.name}</div>),
											'strCron': !_.isEmpty(_lookups.cron) && _lookups.recurrence ? `${cronstrue.toString(_lookups.cron).replace('Every minute,', '')}` : _lookups.dateFrom === _lookups.dateTo ? moment(_lookups.dateFrom).format('DD/MM/YYYY') : `${moment(_lookups.dateFrom).format('DD/MM/YYYY')} - ${moment(_lookups.dateTo).format('DD/MM/YYYY')}`,
											'strValue': _lookups.name,
											'levelID': rec.id,
											'levelName': rec.name,
											'levelValue': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).label : _lookups.level,
											'documentID': _lookups.value,
											'disableEdit': (props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: _lookups.level })) ? false :
												(_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: _lookups.level }).index) ? true : false,
											'disableDelete': (props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: _lookups.level })) ? false :
												(_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: _lookups.level }).index) ? true : false
										}
									]
								}
								else {
									settingsDoc[key.value] = [{
										..._lookups,
										'strName': key.value === 'rosterCategories' ? (<div className="drop-image"><span className="d-inline-flex badge badge-pill roster-dropdown-badge-counter ml-1" style={{ background: _lookups.color }}>{_lookups.value}</span>{_lookups.name}</div>)
											: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: _lookups.color }}></div>{_lookups.name}</div>),
										'strCron': !_.isEmpty(_lookups.cron) && _lookups.recurrence ? `${cronstrue.toString(_lookups.cron).replace('Every minute,', '')}` : _lookups.dateFrom === _lookups.dateTo ? moment(_lookups.dateFrom).format('DD/MM/YYYY') : `${moment(_lookups.dateFrom).format('DD/MM/YYYY')} - ${moment(_lookups.dateTo).format('DD/MM/YYYY')}`,
										'strValue': _lookups.name,
										'levelID': rec.id,
										'levelName': rec.name,
										'levelValue': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).label : _lookups.level,
										'documentID': _lookups.value,
										'disableEdit': (props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: _lookups.level })) ? false :
											(_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: _lookups.level }).index) ? true : false,
										'disableDelete': (props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: _lookups.level })) ? false :
											(_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: _lookups.level }).index) ? true : false
									}]
								}
							})
						}
						else if (_.isEmpty(settingsDoc[key.value])) {
							settingsDoc[key.value] = [];
						}
					})
				})
				if (lookups.length === 0) {
					clientLookups.filter(e => e.module === 'Roster').forEach((key) => {
						settingsDoc[key.value] = [];
					})
				}
				setState(settingsDoc)
				let _obj = {};
				Object.keys(settingsDoc).forEach(r => {
					_obj = { ..._obj, [r]: settingsDoc[r].filter(a => a.levelID === props.dealersettings.client.id) }
				})
				updateDealerSettings('rosterSettings', _obj)
				setLoader(false)
			});
		return () => {
			alllookupsettings && alllookupsettings.unsubscribe()
		}
	}, [clientOptions])

	const lookupList = useMemo(() => state[activeTab] ? _.chain(state[activeTab])
		.groupBy("value")
		.map((value, key) => ({
			...value[0],
			levelName: value.map(r => { return r.levelName }).join(','),
			levelID: value.map(r => { return r.levelID }),
			levelsDOM: (<div className="Vehicle-availability">
				{
					value.map((r, i) => { return <div key={i}><span>{r.levelName}</span></div> })
				}
			</div>),
		}))
		.value() : [], [activeTab, state])

	const handleSwitchChange = (id, checked, data) => {
		if (_.isEmpty(data))
			return
		const batch = window.firebase.firestore().batch();

		if (_.isArray(data.levelID)) {
			data.levelID.forEach(rec => {
				const lookupObj = {
					[activeTab]: [..._.map(state[activeTab].filter(item => item.level === data.level && item.levelID === rec), function (obj) {
						return obj.value === data.value ? { ..._.pick({ ...data, 'active': checked }, _.keys(rosterLookupVM)) } : { ..._.pick(obj, _.keys(rosterLookupVM)) };
					})]
				}
				batch.set(window.firebase.firestore().doc(`clients/${rec}/roster/${rec}`),
					{
						...lookupObj,
						modifiedDate: window.firebase.firestore.Timestamp.now(),
						modifiedBy: localStorage.uid
					}, { merge: true });
				batch.set(window.firebase.firestore().doc(`clients/${rec}`),
					{
						settingsUpdate: window.firebase.firestore.Timestamp.now(),
						settingsUpdatePriority: 'normal',
						settingsUpdateBy: props?.dealersettings?.name || null,
						settingsUpdateText: 'Roster Settings has been updated',
					}, { merge: true })
			})
		}
		else {
			const lookupObj = {
				[activeTab]: [..._.map(state[activeTab].filter(item => item.level === data.level && item.levelID === data.levelID), function (obj) {
					return obj.value === data.value ? { ..._.pick({ ...data, 'active': checked }, _.keys(rosterLookupVM)) } : { ..._.pick(obj, _.keys(rosterLookupVM)) };
				})]
			}
			batch.set(window.firebase.firestore().doc(`clients/${data.levelID}/roster/${data.levelID}`),
				{
					...lookupObj,
					modifiedBy: localStorage.uid,
					modifiedDate: window.firebase.firestore.Timestamp.now(),
				}, { merge: true });
			batch.set(window.firebase.firestore().doc(`clients/${data.levelID}`),
				{
					settingsUpdate: window.firebase.firestore.Timestamp.now(),
					settingsUpdatePriority: 'normal',
					settingsUpdateBy: props?.dealersettings?.name || null,
					settingsUpdateText: 'Roster Settings has been updated',
				}, { merge: true })
		}

		batch.commit()
			.then(snapshot => {
				toast.notify(`Settings updated successfully`, {
					duration: 2000
				})
			})
			.catch((error) => {
				console.log(error);
				Swal.fire('Something went wrong', '', 'error')
			});

	}

	const hadleDeleteLookup = (data) => {
		Swal.fire({
			title: CommonHelper.showLocale(props, 'Are you sure?'),
			text: CommonHelper.showLocale(props, 'Do you want to delete'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
		}).then(async (result) => {
			if (result.value) {

				const batch = window.firebase.firestore().batch();
				if (_.isArray(data.levelID)) {
					data.levelID.forEach(rec => {
						const lookupObj = {
							[activeTab]: [..._.map(state[activeTab].filter(item => item.value !== data.value && item.levelID === rec), function (obj) {
								return { ..._.pick(obj, _.keys(rosterLookupVM)) };
							})]
						}
						batch.set(window.firebase.firestore().doc(`clients/${rec}/roster/${rec}`),
							{
								...lookupObj,
								modifiedDate: window.firebase.firestore.Timestamp.now(),
								modifiedBy: localStorage.uid
							}, { merge: true });
					})
				}
				else {
					const lookupObj = {
						[activeTab]: [..._.map(state[activeTab].filter(item => item.value !== data.value && item.levelID === data.levelID), function (obj) {
							return { ..._.pick(obj, _.keys(rosterLookupVM)) };
						})]
					}
					batch.set(window.firebase.firestore().doc(`clients/${data.levelID}/roster/${data.levelID}`),
						{
							...lookupObj,
							modifiedDate: window.firebase.firestore.Timestamp.now(),
							modifiedBy: localStorage.uid
						}, { merge: true });
				}

				batch.commit()
					.then(snapshot => {
						toast.notify('Settings deleted successfully', {
							duration: 2000
						})
					})
					.catch((error) => {
						console.log(error);
						Swal.fire('Something went wrong', '', 'error')
					});

			}
		})
	}

	return (
		<>
			{
				liteVersion ? (<UpgradePlan />) : (<></>)
			}
			<h2><Translate text={'Roster Settings'} /></h2>
			<div className={`settings-tab-wrap tabs ${liteVersion ? 'form-lite' : ''}`} style={liteVersion ? { height: `${window.innerHeight - 70}px` } : {}}>
				<div className='tabs-wraper'>
					<nav>
						<Tabs
							defaultActiveKey='rosterHolidays'
							className='nav-fill'
							mountOnEnter={true}
							onSelect={(key) => {
								//console.log('onSelect', key)
								setActiveTab(key)
							}}
						>
							<Tab eventKey='rosterHolidays' title={CommonHelper.showLocale(props, 'Holidays')}>
								<RosterList
									{...props}
									loader={loader}
									clientOptions={clientOptions}
									lookupList={lookupList}
									handleSwitchChange={handleSwitchChange}
									handleDeleteItem={hadleDeleteLookup}
									allLookups={state}
									activeTab={activeTab}
									title={'Holiday'}
								/>
							</Tab>
							<Tab eventKey='rosterCategories' title={CommonHelper.showLocale(props, 'Category')}>
								<RosterList
									{...props}
									loader={loader}
									clientOptions={clientOptions}
									lookupList={lookupList}
									handleSwitchChange={handleSwitchChange}
									handleDeleteItem={hadleDeleteLookup}
									allLookups={state}
									activeTab={activeTab}
									title={'Category'}
								/>
							</Tab>
						</Tabs>
					</nav>
				</div>
			</div>
		</>
	)
}

export default RosterSettings