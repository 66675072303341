import React, { Fragment, useEffect, useState } from 'react'
import _ from 'lodash'
import { AntDatePicker, InputText, PopUpModal, ReactTimePicker, ReactSelect, Checkbox, ReactMultiSelect } from "../../../components";
import Translate from '../../../constants/translate';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import uuid from 'react-uuid';
import Swal from 'sweetalert2';
import CommonHelper from '../../../services/common';
import moment from 'moment';
import { eventFieldTypes } from '../../settings/eventsProworkflow/lookups/viewModel';
import AddDynamicOptions from './settingsAttendeeOptions';
import AddSlot from './addSlot';

const eoiMandatoryFields = [
    { name: "Title", value: "title" },
    { name: "First Name", value: "firstName" },
    { name: "Middle Name", value: "middleName" },
    { name: "Last Name", value: "lastName" },
    { name: "Phone", value: "phone" },
    { name: "Email", value: "email" },
    { name: "Phone or Email", value: "phoneOremail" },
    { name: "Address", value: "address" },
    { name: "Area Code", value: "areaCode" },
    { name: "State", value: "state" },
    { name: "Country", value: "country" },
    { name: "Model of Interest", value: "moi", removeMand: true },
    { name: "Make", value: "make" },
    { name: "Model", value: "model" },
    { name: "Year", value: "year" },
    { name: "Chassis/VIN", value: "vinNo" },
    //{ name: "Dietary Preference", value: "dietaryPreference" },
    { name: "Preferred Retailer", value: "preferredRetailer" },
    { name: "Opt in Email", value: "optinEmail" },
    { name: "Opt in Phone", value: "optinPhone" },
    { name: "Opt in SMS", value: "optinSMS" },
    { name: "Opt in Post", value: "optinPost" },
    { name: "Comments", value: "comments", removeMand: true },
]

const regMandatoryFields = [
    { name: "Title", value: "title" },
    { name: "First Name", value: "firstName" },
    { name: "Middle Name", value: "middleName" },
    { name: "Last Name", value: "lastName" },
    { name: "Phone", value: "phone" },
    { name: "Email", value: "email" },
    { name: "Phone or Email", value: "phoneOremail" },
    { name: "Address", value: "address" },
    { name: "Area Code", value: "areaCode" },
    { name: "State", value: "state" },
    { name: "Country", value: "country" },
    { name: "Model of Interest", value: "moi", removeMand: true },
    { name: "Make", value: "make" },
    { name: "Model", value: "model" },
    { name: "Year", value: "year" },
    { name: "Chassis/VIN", value: "vinNo" },
    { name: "Event Slot", value: "eventSlot" },
    //{ name: "Dietary Preference", value: "dietaryPreference" },
    { name: "Preferred Retailer", value: "preferredRetailer" },
    { name: "Opt in Email", value: "optinEmail" },
    { name: "Opt in Phone", value: "optinPhone" },
    { name: "Opt in SMS", value: "optinSMS" },
    { name: "Opt in Post", value: "optinPost" },
    { name: "Comments", value: "comments", removeMand: true },
]

const dateFormats = [
    { value: "MM/DD/YYYY", dateFormatPhone: "MM/dd/yyyy" },
    { value: "DD/MM/YYYY", dateFormatPhone: "dd/MM/yyyy" },
    { value: "YYYY-MM-DD", dateFormatPhone: "yyyy-MM-dd" },
    { value: "DD-MMM-YY", dateFormatPhone: "dd-MMM-yy" },
    { value: "DD-MMM-YYYY", dateFormatPhone: "dd-MMM-yyyy" },
    { value: "MM/DD/YY", dateFormatPhone: "MM/dd/yy" },
    { value: "YY/MM/DD", dateFormatPhone: "yy/MM/dd" }
]

const timeFormats = [
    { value: "hh:mm a", timeFormatPhone: "hh:mm a" },
    { value: "HH:mm", timeFormatPhone: "HH:mm" }
]

const SettingsTab = (props) => {
    const [settings, setSettings] = useState({ name: '', value: uuid() })
    const [editSettings, setEditSettings] = useState()
    const [textEditor, setTexteditor] = useState({})
    const [errorFields, setErrorFields] = useState({});
    const [showDynamicOptionModel, setShowDynamicOptionModel] = useState({ show: false })
    const [makes, setMakes] = useState([])
    const [years, setYears] = useState([])
    const [slotModel, setslotModel] = useState({ show: false })
    const timezone = props?.event?.timezone || moment.tz.guess();

    useEffect(() => {
        const { dealersettings } = props;

        const brands = dealersettings?.client?.brands || [];
        const _years = [];
        const _makes = [];


        for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
            _years.push({
                value: i.toString(),
                label: i.toString(),
                active: true,
            });
        }

        brands.forEach((doc) => {
            _makes.push({
                value: doc.value,
                label: doc.name,
                models: doc.models,
                active: _.isBoolean(doc.active) ? doc.active : true,
            });
        });

        setMakes(_makes);
        setYears(_years);
    }, [])

    const handleAdd = (newSettings, obj) => {
        let append = false;
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (props.activeTab === 'slots') {
            if (_.isEmpty(obj['startTime'])) {
                formIsValid = false;
                if (newSettings)
                    errors['startTime'] = errorClass;
                else
                    errors['editstartTime'] = errorClass;
            }
            if (_.isEmpty(obj['endTime'])) {
                formIsValid = false;
                if (newSettings)
                    errors['endTime'] = errorClass;
                else
                    errors['editendTime'] = errorClass;
            }
            if (_.isEmpty(obj['date'])) {
                formIsValid = false;
                if (newSettings)
                    errors['date'] = errorClass;
                else
                    errors['editdate'] = errorClass;
            }
        }
        else if (props.activeTab === 'testdriveModels') {
            if (_.isEmpty(obj['make'])) {
                formIsValid = false;
                if (newSettings)
                    errors['make'] = errorClass;
                else
                    errors['editmake'] = errorClass;
            }
            if (_.isEmpty(obj['model'])) {
                formIsValid = false;
                if (newSettings)
                    errors['model'] = errorClass;
                else
                    errors['editmodel'] = errorClass;
            }
        }
        else {
            if (_.isEmpty(obj['name'])) {
                formIsValid = false;
                if (newSettings)
                    errors['name'] = errorClass;
                else
                    errors['editname'] = errorClass;
            }
        }
        setErrorFields(errors)
        if (newSettings && props.settings?.some(item => item.name === obj.name && item.value === obj.value)) {
            Swal.fire('Settings with same options already exists', '', 'error')
            formIsValid = false;
        }
        if (obj.available && obj.total && obj.available > obj.total) {
            Swal.fire('Available slot should not be greater then total.', '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        if (props.activeTab === 'slots') {
            let _eventDate = obj.date?.seconds ? moment.unix(obj.date.seconds).format('YYYY-MM-DD') : moment(obj.date).format('YYYY-MM-DD');
            if (newSettings && obj.interval > 0) {
                let arrObj = [];
                for (var m = moment.unix(obj.startTime.seconds); m.isBefore(moment.unix(obj.endTime.seconds)); m.add(obj.interval, 'minutes')) {
                    let name = '';
                    let _startTime = moment(m).format('hh:mm A');
                    let _endTime = moment(m).add(obj.interval, 'minutes').format('hh:mm A')
                    name = obj.date?.seconds ? moment.unix(obj.date.seconds).format('DD/MM/YYYY') : moment(obj.date).format('DD/MM/YYYY')
                    name = (name ? name + ' ' : '') + _startTime
                    name = (name ? name + ' - ' : '') + _endTime
                    arrObj.push({
                        ...obj,
                        value: uuid(),
                        name,
                        startTime: window.firebase.firestore.Timestamp.fromDate(moment(`${_eventDate} ${_startTime}`).toDate()),
                        endTime: window.firebase.firestore.Timestamp.fromDate(moment(`${_eventDate} ${_endTime}`).toDate())
                    })
                }
                obj = arrObj
                append = true;
            } else {
                obj.name = '';
                let _startTime = moment.unix(obj.startTime.seconds).format('hh:mm A');
                let _endTime = moment.unix(obj.endTime.seconds).format('hh:mm A')
                obj.name = obj.date?.seconds ? moment.unix(obj.date.seconds).format('DD/MM/YYYY') : moment(obj.date).format('DD/MM/YYYY')
                obj.name = (obj.name ? obj.name + ' ' : '') + _startTime
                obj.name = (obj.name ? obj.name + ' - ' : '') + _endTime
                obj.startTime = window.firebase.firestore.Timestamp.fromDate(moment(`${_eventDate} ${_startTime}`).toDate())
                obj.endTime = window.firebase.firestore.Timestamp.fromDate(moment(`${_eventDate} ${_endTime}`).toDate())
            }
        }
        props.updateSettings(obj, newSettings, append);
        if (newSettings)
            setSettings({ name: '', value: uuid() })
        else
            handleCancelSettings();

    }

    const onDragEnd = (result) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        const subList = props.settings
        const [removed] = subList.splice(result.source.index, 1);
        subList.splice(result.destination.index, 0, removed);
        props.updateSettings(subList);
    }

    const handleDeleteSettings = (obj) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                props.updateSettings(props.settings.filter(rec => obj.value !== rec.value));
            }
        })
    }

    const handleEditSettings = (rec) => {
        setEditSettings({
            ...rec,
            date: rec.date?.seconds ? moment.unix(rec.date.seconds).format('YYYY-MM-DD') : rec.date
        })
        setTexteditor({
            [rec.value]: true
        })
    }

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setSettings({
                ...settings,
                [name]: newarray1.join(' ')
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setSettings({
                ...settings,
                [name]: str
            });
        }
        setErrorFields({
            ...errorFields,
            ['edit' + name]: ''
        });
    }

    const handleNumberChange = e => {
        const { name, value } = e.target;
        var regexp = /^[0-9 \b]+$/;
        let number = _.trim(value);

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            if (name === 'total') {
                setSettings({
                    ...settings,
                    [name]: Number(_.trim(value)),
                    ['available']: Number(_.trim(value))
                });
            } else {
                setSettings({
                    ...settings,
                    [name]: Number(_.trim(value))
                });
            }

            setErrorFields({
                ...errorFields,
                [name]: ''
            });
        }
    };


    const handleEditNumberChange = e => {
        const { name, value } = e.target;
        var regexp = /^[0-9 \b]+$/;
        let number = _.trim(value);

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setEditSettings({
                ...editSettings,
                [name]: Number(_.trim(value))
            });

            setErrorFields({
                ...errorFields,
                ['edit' + name]: ''
            });
        }
    };

    const handleOnEditChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setEditSettings({
                ...editSettings,
                [name]: newarray1.join(' ')
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setEditSettings({
                ...editSettings,
                [name]: str
            });
        }
        setErrorFields({
            ...errorFields,
            ['edit' + name]: ''
        });
    }

    const handleCancelSettings = () => {
        setEditSettings(null)
        setTexteditor({})
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging && ("#e6eaf2"),
        ...draggableStyle,
    })

    const handleDateChange = (val, name) => {
        setSettings({
            ...settings,
            [name]: val ? window.firebase.firestore.Timestamp.fromDate(moment(val)._d) : null
        });
    };

    const handleReactSelectChange = (e, data) => {
        if (data.name === 'make') {
            setSettings({
                ...settings,
                [data.name]: e ? e.value : null,
                ['model']: null
            });
        } else {
            setSettings({
                ...settings,
                [data.name]: e ? e.value : null,
            });
        }

    }

    const handleEditReactSelectChange = (e, data) => {
        if (data.name === 'make') {
            setEditSettings({
                ...editSettings,
                [data.name]: e ? e.value : null,
                ['model']: null
            });
        } else {
            setEditSettings({
                ...editSettings,
                [data.name]: e ? e.value : null,
            });
        }

    }

    const handleOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        setSettings({
            ...settings,
            [name]: value ? _.trim(value.toUpperCase()) : null
        });
    }

    const handleEditCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        setEditSettings({
            ...editSettings,
            [name]: value ? _.trim(value.toUpperCase()) : null
        });
    }

    const handleTimeChange = (date, name) => {
        let val = moment(moment().format('YYYY-MM-DD') + ' ' + moment(date).format('HH:mm'))._d;
        setSettings({
            ...settings,
            [name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d)
        });

    }

    const handleEditDateChange = (val, name) => {

        setEditSettings({
            ...editSettings,
            [name]: val ? window.firebase.firestore.Timestamp.fromDate(moment(val)._d) : null
        });

    };

    const handleEditTimeChange = (date, name) => {
        let val = moment(moment().format('YYYY-MM-DD') + ' ' + moment(date).format('HH:mm'))._d;
        setEditSettings({
            ...editSettings,
            [name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d)
        });

    }

    return (<>
        <DragDropContext onDragEnd={onDragEnd}>
            <div className="common-table form-style w-100">
                {
                    props.activeTab === 'attendeesDF' && !_.isEmpty(props.settings) ? (
                        <div className="float-right mb-2">
                            <button type="button" className="btn btn-primary" onClick={() => { setShowDynamicOptionModel({ show: true, title: 'Add Attendee Field' }) }}>
                                <Translate text={'add'} />
                            </button>
                        </div>
                    ) : (<>  </>)
                }
                {
                    props.activeTab === 'slots' && !_.isEmpty(props.settings) ? (
                        <div className="float-right">
                            <button type="button" className="btn btn-primary" onClick={() => { setslotModel({ show: true, title: 'Add Slot' }) }}>
                                <Translate text={'add'} />
                            </button>
                        </div>
                    ) : (<>  </>)
                }
                {
                    props.activeTab === 'attendeesDF' && _.isEmpty(props.settings) ? (<>
                        <div className="common-table">
                            <div className="text-center p-5">
                                <p>{`No data found.`}</p>
                                <button className="btn btn-sm btn-default" onClick={() => { setShowDynamicOptionModel({ show: true, title: 'Add Attendee Field' }) }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                    {`Add Attendee Field`}
                                </button>
                            </div>
                        </div>
                    </>) : props.activeTab === 'slots' && _.isEmpty(props.settings) ? (<>
                        <div className="common-table">
                            <div className="text-center p-5">
                                <p>{`No data found.`}</p>
                                <button className="btn btn-sm btn-default" onClick={() => { setslotModel({ show: true, title: 'Add Slot' }) }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                    {`Add Slot`}
                                </button>
                            </div>
                        </div>
                    </>) : props.activeTab === 'mandatoryFields' ? (
                        <>
                            {
                                props.event.enableEOI === true ? (
                                    <>
                                        <div className="event-mandatory-wrapper">
                                            <h2>Expression of Interest</h2>
                                            <div className="event-mandatory-innerlist">
                                                {
                                                    eoiMandatoryFields.filter(a => !Boolean(a.removeMand)).map((rec, index) => {
                                                        return <div key={index} className='float-left mr-3 mb-2'>
                                                            <label className='checkbox-custom-green'>
                                                                {rec.name}
                                                                <input type="checkbox"
                                                                    id={`eoi-checkbox-${rec.value}`}
                                                                    name={rec.value}
                                                                    checked={props?.settings?.eoi?.split(',')?.includes(rec.value)}
                                                                    value={rec.value}
                                                                    onChange={(e) => {
                                                                        props.updateMadatoryFields(e, 'eoi');
                                                                    }}
                                                                />
                                                                <span className="checkmark-custom-green"></span>
                                                            </label>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="event-mandatory-wrapper mt-4">
                                            <h2>Registration</h2>
                                            <div className="event-mandatory-innerlist">
                                                {
                                                    regMandatoryFields.filter(a => !Boolean(a.removeMand)).map((rec, index) => {
                                                        return <div key={index} className='float-left mr-3 mb-2'>
                                                            <label className='checkbox-custom-green'>
                                                                {rec.name}
                                                                <input type="checkbox"
                                                                    id={`registration-checkbox-${rec.value}`}
                                                                    name={rec.value}
                                                                    checked={props?.settings?.registration?.split(',')?.includes(rec.value)}
                                                                    value={rec.value}
                                                                    onChange={(e) => {
                                                                        props.updateMadatoryFields(e, 'registration');
                                                                    }}
                                                                />
                                                                <span className="checkmark-custom-green"></span>
                                                            </label>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="event-mandatory-wrapper mt-4">
                                            <h2>Hide Form Fields</h2>
                                            <div className="form-style w-100">
                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <p className="mb-2 w-100">Expression of Interest</p>
                                                        <ReactMultiSelect
                                                            options={eoiMandatoryFields.map(rec => {
                                                                return {
                                                                    ...rec,
                                                                    label: rec.name
                                                                }
                                                            })}
                                                            name={"eoi"}
                                                            placeholder={'select fields'}
                                                            onChange={(options) => {
                                                                const value = [];
                                                                !_.isEmpty(options) && options.map((data) => {
                                                                    value.push(data.value)
                                                                })
                                                                props.updateMadatoryFields(value, 'eoi', 'hiddenFields');
                                                            }}
                                                            value={props?.allSettings?.hiddenFields?.eoi || []}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                        >
                                                        </ReactMultiSelect>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="mb-2 w-100">Registration</p>
                                                        <ReactMultiSelect
                                                            options={regMandatoryFields.map(rec => {
                                                                return {
                                                                    ...rec,
                                                                    label: rec.name
                                                                }
                                                            })}
                                                            name={"registration"}
                                                            placeholder={'select fields'}
                                                            onChange={(options) => {
                                                                const value = [];
                                                                !_.isEmpty(options) && options.map((data) => {
                                                                    value.push(data.value)
                                                                })
                                                                props.updateMadatoryFields(value, 'registration', 'hiddenFields');
                                                            }}
                                                            value={props?.allSettings?.hiddenFields?.registration || []}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                        >
                                                        </ReactMultiSelect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (<></>)
                            }
                            <div className="event-mandatory-wrapper mt-4">
                                <h2>Settings</h2>
                                <div className="form-style w-100">

                                    <div className="form-row">
                                        <div className={`${props.event.enableEOI === true ? 'col-md-3' : 'col-md-6'}`}>
                                            <p className="mb-2 w-100">Number of guest per registration</p>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                placeholder={'guest'}
                                                pattern="[0-9]*"
                                                className={`form-control`}
                                                name="guest"
                                                onChange={(e) => props.updateMadatoryFields(e, 'guest')}
                                                value={props?.allSettings?.guest || ''}
                                            />
                                        </div>
                                        {
                                            props.event.enableEOI === true ? (
                                                <div className={`col-md-3`}>
                                                    <p className="mb-2 w-100">Number of guest per EOI</p>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder={'guest eoi'}
                                                        pattern="[0-9]*"
                                                        className={`form-control`}
                                                        name="guestEOI"
                                                        onChange={(e) => props.updateMadatoryFields(e, 'guestEOI')}
                                                        value={props?.allSettings?.guestEOI || ''}
                                                    />
                                                </div>
                                            ) : (<></>)
                                        }
                                        <div className="col-md-3">
                                            <p className="mb-2 w-100">Date Format</p>
                                            <ReactSelect
                                                options={dateFormats.map(rec => {
                                                    return {
                                                        label: moment().format(rec.value),
                                                        value: rec.value,
                                                        dateFormatPhone: rec.dateFormatPhone
                                                    }
                                                })}
                                                name={"dateFormat"}
                                                placeholder={'select date format'}
                                                onChange={(e) => {
                                                    props.updateMadatoryFields(e, 'dateFormat');
                                                }}
                                                value={props?.allSettings?.dateFormat || ''}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        <div className="col-md-3">
                                            <p className="mb-2 w-100">Time Format</p>
                                            <ReactSelect
                                                options={timeFormats.map(rec => {
                                                    return {
                                                        label: moment().format(rec.value),
                                                        value: rec.value,
                                                        timeFormatPhone: rec.timeFormatPhone
                                                    }
                                                })}
                                                name={"timeFormat"}
                                                placeholder={'select date format'}
                                                onChange={(e) => {
                                                    props.updateMadatoryFields(e, 'timeFormat');
                                                }}
                                                value={props?.allSettings?.timeFormat || ''}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                    </div>
                                    <div className="form-row mt-2">
                                        <div className={`${props.event.enableEOI === true ? 'col-md-3' : 'col-md-6'}`}>
                                            <p className="mb-2 w-100">Registration Makes Selection</p>
                                            <ReactMultiSelect
                                                options={makes}
                                                name={"makes"}
                                                placeholder={'select make'}
                                                onChange={(options) => {
                                                    const value = [];
                                                    !_.isEmpty(options) && options.map((data) => {
                                                        value.push(data.value)
                                                    })
                                                    props.updateMadatoryFields(value, 'makes');
                                                }}
                                                value={props?.allSettings?.makes || []}
                                                isMulti={true}
                                                isSearchable={true}
                                            >
                                            </ReactMultiSelect>
                                        </div>
                                        {
                                            props.event.enableEOI === true ? (
                                                <div className={`col-md-3`}>
                                                    <p className="mb-2 w-100">EOI Makes Selection</p>
                                                    <ReactMultiSelect
                                                        options={makes}
                                                        name={"makesEOI"}
                                                        placeholder={'select make'}
                                                        onChange={(options) => {
                                                            const value = [];
                                                            !_.isEmpty(options) && options.map((data) => {
                                                                value.push(data.value)
                                                            })
                                                            props.updateMadatoryFields(value, 'makesEOI');
                                                        }}
                                                        value={props?.allSettings?.makesEOI || []}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            ) : (<></>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : props.activeTab === 'slots' ? (
                        <>
                            <div className="custom-slot-listwrap">
                                {
                                    props?.settings?.length ?
                                        _.orderBy(_.chain(props.settings)
                                            .groupBy('date')
                                            .map((value, key) => ({
                                                ['date']: moment(key),
                                                ['slots']: value.map((r, i) => {
                                                    return {
                                                        ...r,
                                                        sortTime: r?.startTime?.seconds ? moment.unix(r.startTime.seconds).tz(timezone).format('HH:mm') : `${i}`
                                                    }
                                                })
                                            }))
                                            .value(), 'date', 'asc').map((doc, index) => {
                                                return <div key={index} className="event-slot-list-container">
                                                    <h3>{doc.date.format(`${props?.allSettings?.dateFormat || 'DD/MM/YYYY'}`)}</h3>
                                                    <div className="custom-slot-list">
                                                        {
                                                            _.orderBy(doc.slots, 'sortTime', 'asc').map((rec, i) => {
                                                                return <div key={i} className="event-slote-result-box ">
                                                                    <a href='#' onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setslotModel({
                                                                            show: true,
                                                                            slot: rec,
                                                                            title: 'Edit Slot'
                                                                        })
                                                                    }}>
                                                                        <span>
                                                                            {moment.unix(rec?.startTime?.seconds).tz(timezone).format(`${props?.allSettings?.timeFormat || 'hh:mm A'}`)} - {moment.unix(rec?.endTime?.seconds).tz(timezone).format(`${props?.allSettings?.timeFormat || 'hh:mm A'}`)}
                                                                        </span>
                                                                        {
                                                                            rec?.slotName ? (
                                                                                <span className='dynamic-slot-value-span'>{rec.slotName}</span>
                                                                            ) : (<></>)
                                                                        }
                                                                        {
                                                                            rec.total > 0 ? (
                                                                                <div className={`slote-result-box-counterwrap ${rec.booked === rec.total ? 'slot-full' : ''}`}>
                                                                                    <div className={`slote-result-box-counter`}>{rec.booked ? rec.booked : 0} / {rec.total}</div>
                                                                                </div>
                                                                            ) : (<></>)
                                                                        }
                                                                    </a>
                                                                    <a href="#" className="edit red-fix" onClick={(e) => {
                                                                        handleDeleteSettings(rec)
                                                                    }}><i className="ico icon-delete"></i></a>
                                                                </div>
                                                            })
                                                        }

                                                    </div>
                                                </div>
                                            })
                                        : (<></>)
                                }
                            </div>
                        </>
                    ) : (
                        <table className="table table-bordered">
                            <thead>
                                {
                                    props.activeTab === 'attendeesDF' ? (
                                        <tr>
                                            <th scope="col" className="head-light" width="45%"><Translate text={'Name'} /></th>
                                            <th scope="col" className="head-light" width="45%"><Translate text={'Field Type'} /></th>
                                            <th scope="col" className="head-light border-right-0" width="10%"><Translate text={'action'} /></th>
                                        </tr>
                                    ) : props.activeTab === 'testdriveModels' ? (
                                        <tr>
                                            <th scope="col" className="head-light" width="15%"><Translate text={'Make'} /></th>
                                            <th scope="col" className="head-light" width="15%"><Translate text={'Model'} /></th>
                                            <th scope="col" className="head-light" width="15%"><Translate text={'Year'} /></th>
                                            <th scope="col" className="head-light" width="15%"><Translate text={'Reg. No'} /></th>
                                            <th scope="col" className="head-light" width="15%"><Translate text={'Vin No'} /></th>
                                            <th scope="col" className="head-light" width="15%"><Translate text={'Colour'} /></th>
                                            <th scope="col" className="head-light border-right-0" width="10%"><Translate text={'action'} /></th>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <th scope="col" className="head-light" width="90%"><Translate text={'name'} /></th>
                                            <th scope="col" className="head-light border-right-0" width="10%"><Translate text={'action'} /></th>
                                        </tr>
                                    )
                                }
                                {
                                    props.activeTab === 'attendeesDF' || props.activeTab === 'slots' ? (<></>) : (
                                        <tr>
                                            {
                                                props.activeTab === 'testdriveModels' ? (
                                                    <>
                                                        <td width="15%">
                                                            <ReactSelect
                                                                options={makes}

                                                                name={"make"}
                                                                placeholder={'select make'}
                                                                onChange={handleReactSelectChange}
                                                                value={settings.make}
                                                                classNamePrefix={`${errorFields["make"]} basic-select`}
                                                                removeClearable={true}
                                                            >
                                                            </ReactSelect>
                                                        </td>
                                                        <td width="15%" >
                                                            <ReactSelect
                                                                options={_.find(makes, { value: settings?.make })?.models?.map(doc => {
                                                                    return {
                                                                        value: doc.value,
                                                                        label: doc.name
                                                                    }
                                                                }) || []}

                                                                name={"model"}
                                                                placeholder={'select model'}
                                                                onChange={handleReactSelectChange}
                                                                value={settings.model}
                                                                classNamePrefix={`${errorFields["model"]} basic-select`}
                                                                removeClearable={true}
                                                            >
                                                            </ReactSelect>
                                                        </td>
                                                        <td width="15%" >
                                                            <ReactSelect
                                                                options={years}

                                                                name={"year"}
                                                                placeholder={'select year'}
                                                                onChange={handleReactSelectChange}
                                                                value={settings.year}
                                                                classNamePrefix={`${errorFields["year"]} basic-select`}
                                                                removeClearable={true}
                                                            >
                                                            </ReactSelect>
                                                        </td>
                                                        <td width="15%" >
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'reg no'}
                                                                className={`form-control ${errorFields["regNo"]}`}
                                                                name="regNo"
                                                                onChange={handleOnCapChange}
                                                                value={settings.regNo}
                                                            />
                                                        </td>
                                                        <td width="15%" >
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'vin no'}
                                                                className={`form-control ${errorFields["vinNo"]}`}
                                                                name="vinNo"
                                                                onChange={handleOnCapChange}
                                                                value={settings.vinNo}
                                                            />
                                                        </td>
                                                        <td width="15%" >
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'colour'}
                                                                className={`form-control ${errorFields["extColor"]}`}
                                                                name="extColor"
                                                                onChange={handleOnChange}
                                                                value={settings.extColor}
                                                            />
                                                        </td>
                                                    </>
                                                ) : (
                                                    <td width="90%">

                                                        <div className="form-group mb-0">
                                                            <InputText
                                                                id={`target-name`}
                                                                autoComplete="off"
                                                                placeholder={'name'}
                                                                className={`form-control ${errorFields["name"]}`}
                                                                name="name"
                                                                onChange={handleOnChange}
                                                                value={settings.name}
                                                            />
                                                        </div>

                                                    </td>
                                                )
                                            }

                                            <td width="10%" className="border-right-0">
                                                <div className="button-height-fix">
                                                    <button type="button" className="btn btn-primary" onClick={() => { handleAdd(true, settings) }}>
                                                        <Translate text={'add'} />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            </thead>
                            <Droppable droppableId="droppableDealerTarget">
                                {(provided, snapshot) => (
                                    <tbody
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}>
                                        {
                                            _.isArray(props?.settings) ?
                                                props.settings.map((rec, index) => {
                                                    return <Draggable key={rec.value} draggableId={rec.value} index={index}>
                                                        {(provided1, snapshot1) => (<tr
                                                            ref={provided1.innerRef}
                                                            {...provided1.draggableProps}
                                                            {...provided1.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot1.isDragging,
                                                                provided1.draggableProps.style
                                                            )}
                                                            onDoubleClick={(e) => {
                                                                e.preventDefault();
                                                                handleEditSettings(rec)
                                                            }}>
                                                            {
                                                                props.activeTab === 'attendeesDF' ? (<>
                                                                    <td width="40%">{rec.name}</td>
                                                                    <td width="40%">{_.find(eventFieldTypes, { value: rec.type }) ? _.find(eventFieldTypes, { value: rec.type }).label : rec.value}</td>
                                                                    <td width="20%" className="border-right-0">
                                                                        <div style={{ width: '90px', float: 'left' }}>
                                                                            <div className="action-fix">
                                                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setShowDynamicOptionModel({ show: true, title: 'Add Attendee Field', lookup: rec }) }}> <i className="ico icon-edit"></i></a></div>
                                                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteSettings(rec) }}> <i className="ico icon-delete"></i></a></div>

                                                                            </div>
                                                                            <div className="table-edit float-right"> <i className="ico icon-drag"></i></div>
                                                                        </div>
                                                                    </td>
                                                                </>) : props.activeTab === 'testdriveModels' ? (
                                                                    <>
                                                                        <td>
                                                                            {textEditor[rec.value] ? (
                                                                                <>
                                                                                    <ReactSelect
                                                                                        options={makes}

                                                                                        name={"make"}
                                                                                        placeholder={'select make'}
                                                                                        onChange={handleEditReactSelectChange}
                                                                                        value={editSettings.make}
                                                                                        classNamePrefix={`${errorFields["editmake"]} basic-select`}
                                                                                        removeClearable={true}
                                                                                    >
                                                                                    </ReactSelect>
                                                                                </>
                                                                            ) : (
                                                                                <>{rec.make}</>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {textEditor[rec.value] ? (
                                                                                <>
                                                                                    <ReactSelect
                                                                                        options={_.find(makes, { value: editSettings?.make })?.models?.map(doc => {
                                                                                            return {
                                                                                                value: doc.value,
                                                                                                label: doc.name
                                                                                            }
                                                                                        }) || []}

                                                                                        name={"model"}
                                                                                        placeholder={'select model'}
                                                                                        onChange={handleEditReactSelectChange}
                                                                                        value={editSettings.model}
                                                                                        classNamePrefix={`${errorFields["editmodel"]} basic-select`}
                                                                                        removeClearable={true}
                                                                                    >
                                                                                    </ReactSelect>
                                                                                </>
                                                                            ) : (
                                                                                <>{rec.model}</>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {textEditor[rec.value] ? (
                                                                                <>
                                                                                    <ReactSelect
                                                                                        options={years}
                                                                                        name={"year"}
                                                                                        placeholder={'select year'}
                                                                                        onChange={handleEditReactSelectChange}
                                                                                        value={editSettings.year}
                                                                                        classNamePrefix={`${errorFields["edityear"]} basic-select`}
                                                                                        removeClearable={true}
                                                                                    >
                                                                                    </ReactSelect>
                                                                                </>
                                                                            ) : (
                                                                                <>{rec.year}</>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {textEditor[rec.value] ? (
                                                                                <>
                                                                                    <InputText
                                                                                        autoComplete="off"
                                                                                        placeholder={'reg no'}
                                                                                        className={`form-control ${errorFields["editregNo"]}`}
                                                                                        name="regNo"
                                                                                        onChange={handleEditCapChange}
                                                                                        value={editSettings.regNo}
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                <>{rec.regNo}</>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {textEditor[rec.value] ? (
                                                                                <>
                                                                                    <InputText
                                                                                        autoComplete="off"
                                                                                        placeholder={'vin no'}
                                                                                        className={`form-control ${errorFields["editvinNo"]}`}
                                                                                        name="vinNo"
                                                                                        onChange={handleEditCapChange}
                                                                                        value={editSettings.vinNo}
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                <>{rec.vinNo}</>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {textEditor[rec.value] ? (
                                                                                <>
                                                                                    <InputText
                                                                                        autoComplete="off"
                                                                                        placeholder={'colour'}
                                                                                        className={`form-control ${errorFields["editextColor"]}`}
                                                                                        name="extColor"
                                                                                        onChange={handleEditCapChange}
                                                                                        value={editSettings.extColor}
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                <>{rec.extColor}</>
                                                                            )}
                                                                        </td>
                                                                        <td width="10%" className="border-right-0">
                                                                            <div style={{ width: '90px', float: 'left' }}>
                                                                                <div className="action-fix">
                                                                                    {
                                                                                        textEditor[rec.value] ?
                                                                                            (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleAdd(false, editSettings) }}> <i className="ico icon-check"></i></a></div>
                                                                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleCancelSettings(rec) }}> <i className="ico icon-remove"></i></a></div>
                                                                                            </>) :
                                                                                            (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditSettings(rec) }}> <i className="ico icon-edit"></i></a></div>
                                                                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteSettings(rec) }}> <i className="ico icon-delete"></i></a></div>
                                                                                            </>)
                                                                                    }
                                                                                </div>
                                                                                <div className="table-edit float-right"> <i className="ico icon-drag"></i></div>
                                                                            </div>
                                                                        </td>
                                                                    </>
                                                                ) : (<>
                                                                    <td width="90%">
                                                                        {
                                                                            textEditor[rec.value] ? (<>
                                                                                <InputText
                                                                                    id={`target-edit-name`}
                                                                                    autoComplete="off"
                                                                                    placeholder={'name'}
                                                                                    className={`form-control ${errorFields["editname"]}`}
                                                                                    name="name"
                                                                                    onChange={handleOnEditChange}
                                                                                    value={editSettings.name}
                                                                                />
                                                                            </>) : (<>{rec.name}</>)
                                                                        }
                                                                    </td>
                                                                    <td width="10%" className="border-right-0">
                                                                        <div style={{ width: '90px', float: 'left' }}>
                                                                            <div className="action-fix">
                                                                                {
                                                                                    textEditor[rec.value] ?
                                                                                        (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleAdd(false, editSettings) }}> <i className="ico icon-check"></i></a></div>
                                                                                            <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleCancelSettings(rec) }}> <i className="ico icon-remove"></i></a></div>
                                                                                        </>) :
                                                                                        (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditSettings(rec) }}> <i className="ico icon-edit"></i></a></div>
                                                                                            <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteSettings(rec) }}> <i className="ico icon-delete"></i></a></div>
                                                                                        </>)
                                                                                }
                                                                            </div>
                                                                            <div className="table-edit float-right"> <i className="ico icon-drag"></i></div>
                                                                        </div>
                                                                    </td>
                                                                </>)
                                                            }

                                                            {provided1.placeholder}
                                                        </tr>
                                                        )}
                                                    </Draggable>
                                                }) : (<></>)
                                        }
                                        {provided.placeholder}
                                    </tbody>
                                )}
                            </Droppable>
                        </table>
                    )
                }

            </div >
        </DragDropContext >
        <PopUpModal show={showDynamicOptionModel.show}>
            <AddDynamicOptions
                {...props}
                {...showDynamicOptionModel}
                handleClose={(obj, isnew) => {
                    if (!_.isEmpty(obj)) {
                        handleAdd(isnew, obj)
                    }
                    setShowDynamicOptionModel({ show: false })
                }}
            />
        </PopUpModal>
        <PopUpModal show={slotModel.show}>
            <AddSlot
                {...props}
                {...slotModel}
                timezone={timezone}
                handleClose={(obj, isnew) => {
                    if (!_.isEmpty(obj)) {
                        handleAdd(isnew, obj)
                    }
                    setslotModel({ show: false })
                }}
            />
        </PopUpModal>
    </>);
}

export default SettingsTab;