import React, { useState, useEffect } from 'react'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import _ from 'lodash'
import Swal from 'sweetalert2'
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { ReactSelect, PopUpModal, ContentOverlay, ReactTimePicker, AntDatePicker } from '../../components';
import ContactInfo from '../contacts/contactInfo'
import CommonHelper from '../../services/common';
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
import { objActivitylog, activityLogOptions } from '../pipeline/viewModel'
import { objContact } from '../contacts/viewModel'
import { contactResponseCode, tipStatus } from '../../services/enum';
import { objTradeIP, objBasicTradeInVM } from '../tradeinplus/viewModel'
import MentionEditor from '../../components/mentionEditor/mentionEditor';
import AddNotes from '../service/details/addNotes';
import { _returnMandatoryFields } from '../fleet/viewModel';
import { companyBasicVM } from '../company/viewModel'
import AddContact from '../contacts/add';
import { firestoreDB } from '../../services/helper';
import Requirements from '../requirement/list';

const InBoundLeadConvert = (props) => {

    const [fields, setFields] = useState({ clientID: '', owner: localStorage.uid, comments: '', activityDate: null, activityType: '' });
    const [groups, setGroups] = useState([]);
    const [tradeinPro, setTradeinPro] = useState({});
    const [contact, setContact] = useState({});
    const [tradein, setTradein] = useState({});

    const [errors, setErrors] = useState({});
    const [allUsers, setAllusers] = useState([]);
    const [defaulStages, setDefaulStages] = useState([]);
    const [pipelines, setPipelines] = useState([]);
    const [activeStageIndex, setActiveStageIndex] = useState(0);

    const [showContactInfo, setshowContactInfo] = useState(false)
    const [existingContactInfo, setexistingContactInfo] = useState({})

    const [isLoading, setLoading] = useState(false);
    const [isContactMerged, setContactMerged] = useState(false);

    const [titles, setTitles] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    //const [makes, setMakes] = useState([])
    const [logNotes, setNotes] = useState([]);
    const [deleteLoader, setDeleteloader] = useState(false);
    const [teamMentions, setTeamMentions] = useState(null);
    const [mentions, setMentions] = useState([]);

    const [notesHistory, setNotesHistory] = useState([])
    const [showNotes, setShowNotes] = useState(false);
    const [notesHistoryTIP, setnotesHistoryTIP] = useState([])
    const [notesLoader, setNotesLoader] = useState(true);
    const [showContactModal, setshowContactModal] = useState(false)
    const [activityTypes, setActivityTypes] = useState(activityLogOptions);
    const [rosterMsg, setRosterMsg] = useState(null)
    const history = useHistory();
    useEffect(() => {
        if (_.isEmpty(props.docID))
            return;
        const { docID } = props;
        if (docID) {
            loadTIP();
        }

        return () => {
            window.unsubscribeInboundEnq && window.unsubscribeInboundEnq();
        };
    }, [props.docID]);

    // useEffect(() => {
    //     if (_.isEmpty(props.docID))
    //         return;


    //     const notesHistoryInboundCollection = firestoreDB(props.dealersettings).firestore().collection(`tradeinProInbound/${props.docID}/notesHistory`)
    //         .onSnapshot(onCollectionHistoryUpdate);

    //     return () => {
    //         notesHistoryInboundCollection && notesHistoryInboundCollection();
    //     }
    // }, [props.docID]);

    const deleteInboundLead = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.tradeinProPermissions) &&
        props.dealersettings.rolePermissions.tradeinProPermissions.deleteInboundLead) ? true : false);

    const mentionsData = props.clientUsers.map(clientUser => {
        return {
            name: `@${clientUser.name}`,
            id: clientUser.id,
            avatar: CommonHelper.showUserAvatar(clientUser.profileImage, clientUser.name)
        };
    });
    props.dealersettings.client.teams.forEach(team => {
        mentionsData.push({
            name: `@${team.name}`,
            id: team.user_ids.join(','),
            avatar: _images.group
        })
    })

    //#region CHANGE EVENT Notes  
    const handleNotesOpen = () => {
        setShowNotes(true);
    }

    const handleNotesClose = (noteTxt, _mentions) => {

        if (noteTxt) {
            const { docID } = props;

            let convertedIDs = []
            if (!_.isEmpty(_mentions)) {
                let rawIDs = _mentions.map(mentionObject => mentionObject.id)
                rawIDs.forEach(id => {
                    if (id.includes(',')) {
                        let allIDs = id.split(',')
                        allIDs.forEach(a => {
                            convertedIDs.push(a)
                        })
                    } else {
                        convertedIDs.push(id)
                    }
                })
            }

            let _objNote =
            {
                documentID: firestoreDB(props.dealersettings).firestore().collection('tradeinProInbound').doc().id,
                notes: noteTxt,
                mentions: Object.assign([], convertedIDs),
                addedBy: localStorage.uid,
                addedByName: props.dealersettings.name,
                addedDate: window.firebase.firestore.Timestamp.now(),
                addedFrom: 'web',
                modifiedBy: localStorage.uid,
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web',
                projectId: props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null
            }

            const updateRef = firestoreDB(props.dealersettings).firestore().doc(`tradeinProInbound/${docID}/notesHistory/${_objNote.documentID}`);
            updateRef.set(_objNote, { merge: true }).then((docRef) => {

                toast.notify('notes added successfully', {
                    duration: 2000
                })

            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });

        }

        setShowNotes(false);
    }

    const onCollectionHistoryUpdate = querySnapshot => {
        let _logData = [];
        querySnapshot.forEach(_log => {
            let objData = Object.assign({}, _log.data());
            objData.documentID = _log.id;
            _logData.push(objData);
        });

        if (!_.isEmpty(_logData)) {
            setNotesHistory(_.orderBy(_logData, ["modifiedDate"], ["desc"]))
        }
        // else if (activityLog && activityLog.notes && activityLog.notes.trim()) {
        // 	saveLogNote();
        // }

    };
    //#endregion 



    //#region  EVENT CHANGES
    const handleDealerChange = async (e, data) => {
        const { dealersettings, groupUsers, clientUsers, pipeline } = props;
        //const _clientSettings = await window.firebase.firestore().doc(`clients/${e.value}/currentSettings/${e.value}`).get().then(doc => { if (doc.exists) return doc.data(); else return null; });

        //setClientSettings(Object.assign({}, _clientSettings));
        let objFilds = {
            ...fields,
            'clientID': e.value
        }

        // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
        if (!_.isEmpty(dealersettings.client.roles)) {
            let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.tradeinProPipeline && e.tradeinProPipeline.some(m => m === objFilds.pipeline)), function (obj) {
                return obj.documentID;
            })];
            let _selectedUsers = [];
            let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
            if (!_.isEmpty(roleIds))
                _selectedUsers = _.filter(allDealerUsers.filter(v => v.clientID === e.value), (v) => _.includes(roleIds, v.roleID));

            let options = [];
            _selectedUsers && _selectedUsers.map(rec => {
                return options.push({
                    value: rec.id,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    searchlabel: rec.name,
                    label: <div className="drop-image">
                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                        {rec.name}
                    </div>
                })
            })
            setAllusers(options)
            let _currentUser = fields.owner && options.filter(v => v.value === fields.owner)[0];
            if (!_currentUser) {
                objFilds.owner = '';
            }
        }
        else {
            setAllusers([])
            objFilds.owner = '';
        }
        // let options = [];
        // let allDealerUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
        // _.filter(allDealerUsers.filter(v => v.clientID === e.value)).map(rec => {
        //     return options.push({
        //         value: rec.id,
        //         active: _.isBoolean(rec.active) ? rec.active : true,
        //         searchlabel: rec.name,
        //         label: <div className="drop-image">
        //             <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
        //             {rec.name}
        //         </div>
        //     })
        // })
        // setAllusers(options)

        setFields(objFilds);
        checkUserRoster(objFilds.owner, objFilds.activityDate);
    }

    const handlePipelineChange = (e, data) => {
        const { dealersettings, groupUsers, clientUsers } = props;

        let objFilds = {
            ...fields,
            'pipeline': e.value
        }

        // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
        if (!_.isEmpty(dealersettings.client.roles)) {
            let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.tradeinProPipeline && e.tradeinProPipeline.some(m => m === objFilds.pipeline)), function (obj) {
                return obj.documentID;
            })];
            let _selectedUsers = [];
            let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
            if (!_.isEmpty(roleIds))
                _selectedUsers = _.filter(allDealerUsers.filter(v => v.clientID === objFilds.clientID), (v) => _.includes(roleIds, v.roleID));

            let options = [];
            _selectedUsers && _selectedUsers.map(rec => {
                return options.push({
                    value: rec.id,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    searchlabel: rec.name,
                    label: <div className="drop-image">
                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                        {rec.name}
                    </div>
                })
            })
            setAllusers(options)
            let _currentUser = fields.owner && options.filter(v => v.value === fields.owner)[0];
            if (!_currentUser) {
                objFilds.owner = '';
            }
        }
        else {
            setAllusers([])
            objFilds.owner = '';
        }

        if (!_.isEmpty(dealersettings?.client?.tradeinPro)) {

            const setttings = Object.assign({}, dealersettings?.client?.tradeinPro);
            let _stages = null;

            let _pipeline = Object.assign({}, (setttings.allPipelines.filter(v => v.value === e.value)[0]));
            let defaultstages = _pipeline?.stages;
            _stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');

            objFilds.stage = _stages[0];

            setDefaulStages(defaultstages)
            setActiveStageIndex(_stages.indexOf(_stages[0]))
            setFields(objFilds);
            checkUserRoster(objFilds.owner, objFilds.activityDate);
        }
    }

    const handleStageChange = (e) => {
        e.preventDefault();
        const { id, name } = e.target;
        const { dealersettings } = props;
        let statusSettings = fields.pipeline && dealersettings?.client?.tradeinPro?.allPipelines?.filter(e => e.value === fields.pipeline)[0]?.statusSettings;
        let _statusMndry = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.pipeline === fields.pipeline && e.stage === name)[0];

        if (_statusMndry && _statusMndry.status !== tipStatus.NEW) {
            let __stage = defaulStages.find(x => x.value === name);
            const allStatus = (dealersettings && dealersettings.client && dealersettings.client.tradeinPro ? dealersettings.client.tradeinPro.tradeinProStatus : [])
            let _status = CommonHelper.getNameByValue(allStatus, _statusMndry.status)
            Swal.fire(`${CommonHelper.showLocale(props, 'Tradein status will be')} ${_status.toUpperCase()} ${CommonHelper.showLocale(props, 'for selecting stage')}: ${__stage ? __stage.name : ''}`, '', 'info')
            return;
        }

        setFields({ ...fields, ['stage']: name });
        setActiveStageIndex(id)
        setErrors('stage');
    }

    const handleReactSelectChange = (e, data) => {
        if (e) {
            setFields({
                ...fields,
                [data.name]: e.value
            });

            if (data.name === 'owner')
                checkUserRoster(e.value, fields.activityDate);
        }
    }

    const checkUserRoster = (userID, _date) => {
        if (userID) {
            const { dealersettings, groupUsers, clientUsers } = props;
            CommonHelper.checkUserRoster(dealersettings,
                {
                    owner: userID,
                    startDate: _date ? _date : window.firebase.firestore.Timestamp.now()
                }, (!_.isEmpty(groupUsers) ? groupUsers : clientUsers),
                (_date ? true : false))
                .then(msg => {
                    setRosterMsg(msg);
                })
        }
    }
    //#endregion

    //#region SUBMIT CHANGES

    const createGenericLead = async (viewmodel) => {
        const _objTIPro = Object.assign({}, objTradeIP);
        try {
            const _objRequirement = Object.assign({}, objBasicTradeInVM);

            if (!_.isEmpty(viewmodel.contact)) {
                _objTIPro.contact = viewmodel.contact;
                _objTIPro.isContact = true;
            }

            let _requiremntData = viewmodel.tradein ? viewmodel.tradein : null;
            if (!_.isEmpty(_requiremntData)) {

                // if (_requiremntData.stockNo) {
                //     let stock = {};
                //     const checkStock = await window.firebase.firestore().collection('stock').where('clientID', '==', props.dealersettings.client.id)
                //         .where('stockNo', '==', _requiremntData.stockNo)
                //         .where('isDeleted', '==', false).limit(1).get()

                //     if (checkStock.size === 1) {
                //         checkStock.forEach(doc => {
                //             stock = doc.data();
                //         });
                //     }

                //     if (!_.isEmpty(stock)) {
                //         _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                //         _objRequirement.stock = stock;
                //         _objRequirement.saleType = stock.saleType ? stock.saleType : '';
                //         _objTIPro.isVehicle = true;
                //         _objTIPro.tradein = _objRequirement;
                //     }
                // }

                if ((_requiremntData.make || _requiremntData.regNo) && !_objTIPro.isTradein) {
                    let _make = dealersettings.client.brands && dealersettings.client.brands.filter(m => m.value && m.value.toLowerCase() === _requiremntData.make.toLowerCase())[0];
                    if (!_.isEmpty(_make)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;

                        for (let [key, value] of Object.entries(_requiremntData)) {
                            if (value)
                                _objRequirement[key] = value;
                        }

                        _objRequirement.make = _make.value ? _make.value : '';
                        _objRequirement.model = _requiremntData.model ? _requiremntData.model : '';
                        _objRequirement.type = _requiremntData.type ? _requiremntData.type : '';
                        _objRequirement.group = _requiremntData.group ? _requiremntData.group : '';
                        _objRequirement.saleType = _requiremntData.saleType ? _requiremntData.saleType : '';
                        _objRequirement.mileage = _requiremntData.mileage ? _requiremntData.mileage.toString() : '';
                        _objRequirement.offerPrice = _requiremntData.price ? parseInt(_requiremntData.price) : null;
                        _objRequirement.stock = null;

                        _objRequirement.stockNo = _requiremntData.stockNo ? _requiremntData.stockNo : '';
                        _objRequirement.regNo = _requiremntData.regNo ? _requiremntData.regNo : '';
                        _objRequirement.vinNo = _requiremntData.vinNo ? _requiremntData.vinNo : '';
                        _objTIPro.isTradein = true;

                        let _model = null;
                        if (_objRequirement.make && _objRequirement.make.toLowerCase() === 'audi')
                            _model = _objRequirement.model && _make.models && _make.models.filter(m => m.value && m.value.toLowerCase().includes(_objRequirement.model.toLowerCase()))[0];
                        else
                            _model = _objRequirement.model && _make.models && _make.models.filter(m => m.value && m.value.toLowerCase() === _objRequirement.model.toLowerCase())[0];

                        if (!_.isEmpty(_model)) {
                            _objRequirement.model = _model.value ? _model.value : '';
                            _objRequirement.type = _model.type ? _model.type : '';
                            _objRequirement.group = _model.group ? _model.group : '';
                        }

                        //Remove empty value from object
                        for (var propName in _objRequirement) {
                            if (_objRequirement[propName] === null || _objRequirement[propName] === undefined || _objRequirement[propName] === '' || _objRequirement[propName] === Object.assign([]) || (_.isObject(_objRequirement[propName]) && _.isEmpty(_objRequirement[propName]))) {
                                delete _objRequirement[propName];
                            }
                        }

                        _objTIPro.tradein = _objRequirement;
                    }
                    else {
                        _objTIPro.isTradein = true;

                        //Remove empty value from object
                        for (var propName in _requiremntData) {
                            if (_requiremntData[propName] === null || _requiremntData[propName] === undefined || _requiremntData[propName] === '' || _objRequirement[propName] === Object.assign([]) || (_.isObject(_requiremntData[propName]) && _.isEmpty(_requiremntData[propName]))) {
                                delete _requiremntData[propName];
                            }
                        }
                        _objRequirement.mileage = _requiremntData.mileage ? _requiremntData.mileage.toString() : '';
                        _objRequirement.offerPrice = _requiremntData.price ? parseInt(_requiremntData.price) : null;
                        _objTIPro.tradein = _requiremntData;
                    }
                }
            }

            let notes = [];
            if (!_.isEmpty(viewmodel.notes)) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'tradeinProlog';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedBy = localStorage.uid;
                newlogVM.modifiedBy = localStorage.uid;
                newlogVM.addedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = viewmodel.notes;
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                notes.push(newlogVM)
            }

            setNotes(notes)
            _objTIPro.receivedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
            return _objTIPro;
        } catch (error) {
            errorLog(error, 'createGenericLead');
            return _objTIPro;
        }

    }

    const getTIPLead = async (viewmodel) => {

        let _objTIPro = Object.assign({}, objTradeIP)
        try {
            const refData = await firestoreDB(props.dealersettings).firestore().collection('tradeinPro').doc(viewmodel.tradeinProID).get();
            if (refData.exists) {
                _objTIPro = Object.assign({}, refData.data());
                _objTIPro.documentID = refData.id;
                _objTIPro.receivedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                return _objTIPro;
            }
        } catch (error) {
            errorLog(error, 'getTIPLead');
            return _objTIPro;
        }

    }

    const loadTIP = async () => {
        try {
            const { docID, dealersettings, groupUsers, clientUsers } = props;
            if (docID && dealersettings) {

                const refData = await firestoreDB(props.dealersettings).firestore().collection('tradeinProInbound').doc(docID).get();
                const _pipelines = [];
                let _stages = null;

                if (refData.exists) {
                    let _objLead = Object.assign({}, refData.data());
                    _objLead.documentID = refData.id;

                    let response;
                    if (!_.isEmpty(_objLead.tradeinProID) && _objLead.status === 0) {
                        response = await getTIPLead(_objLead);

                        // const _logData = [];

                        // const notes1 = await firestoreDB(props.dealersettings).firestore().collection('activities')
                        //     .where('tradeinProID', '==', _objLead.tradeinProID)
                        //     .where('type', '==', 'tradeinProlog')
                        //     .where('subType', '==', 'note')
                        //     .where('isDeleted', '==', false)
                        //     .get();

                        // const notes2 = await firestoreDB(props.dealersettings).firestore().collection('oemNotes')
                        //     .where('tradeinProID', '==', _objLead.tradeinProID)
                        //     .where('isDeleted', '==', false)
                        //     .get();

                        // if (notes1.docs.length > 0) {
                        //     notes1.docs.forEach((_log) => {
                        //         let objData = Object.assign({}, _log.data());
                        //         objData.documentID = _log.id;
                        //         _logData.push(objData);
                        //     });
                        // }

                        // if (notes2.docs.length > 0) {
                        //     notes2.docs.forEach((_log) => {
                        //         let objData = Object.assign({}, _log.data());
                        //         objData.documentID = _log.id;
                        //         _logData.push(objData);
                        //     });
                        // }

                        // if (!_.isEmpty(_logData)) {
                        //     setnotesHistoryTIP(_.orderBy(_logData, ["modifiedDate"], ["desc"]))
                        // }


                        // setNotesLoader(false)

                    }
                    else {
                        setNotesLoader(false)
                        response = await createGenericLead(_objLead);
                    }

                    if (response) {
                        setTradeinPro(response);

                        if (response.isContact && !_.isEmpty(response.contact)) {
                            //SaMeeR OEM
                            //if (_.isEmpty(response.contact['documentID']))
                            let _contact = response.contact
                            _contact['documentID'] = firestoreDB(props.dealersettings).firestore().collection('contactDetails').doc().id;
                            _contact['isDeleted'] = false;

                            //save license image
                            if (_objLead.request?.LicenseImageURL && _objLead.request.LicenseImageURL.includes('http'))
                                _contact.licenseFrontURL = _objLead.request.LicenseImageURL;

                            if (_objLead.request?.LicenseImageBackURL && _objLead.request.LicenseImageBackURL.includes('http'))
                                _contact.licenseBackURL = _objLead.request.LicenseImageBackURL;

                            if (_objLead.request?.ImageURL && _objLead.request.ImageURL.includes('http'))
                                _contact.userImageURL = _objLead.request.ImageURL;

                            if (_objLead.request?.LicenseExpiry)
                                _contact.licenseExpiry = moment(_objLead.request.LicenseExpiry).format('YYYY-MM-DD');

                            if (_objLead.request?.LicenseNo)
                                _contact.licenseNo = _objLead.request.LicenseNo;

                            //end
                            response.contact = _contact;
                            setContact(response.contact);
                        }

                        if (response.isTradein && !_.isEmpty(response.tradein)) {
                            setTradein(response.tradein);
                        }
                    }
                    //console.log(props.dealersettings.integrations, _objLead.systemType, _objLead.subType)
                    _objLead.tags = [CommonHelper.getNameByValue(props.dealersettings.integrations, _objLead.systemType, _objLead.systemType), _objLead.subType];

                    if (dealersettings && dealersettings.client && dealersettings.client.settings) {

                        let _groups = CommonHelper.tradeinProClients(dealersettings);

                        const brands = dealersettings.client.brands;

                        const setttings = props.dealersettings.client.settings;
                        let defaultCountry = dealersettings?.client?.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU';

                        const _makes = [];
                        brands.forEach((doc) => {
                            _makes.push({
                                value: doc.value,
                                label: doc.name,
                                models: doc.models
                            });
                        });

                        let _titles = [];
                        setttings.titles && setttings.titles.forEach((doc) => {
                            _titles.push({
                                value: doc.value,
                                label: doc.name
                            });
                        });

                        const _countries = [];
                        dealersettings.countries && dealersettings.countries.forEach((doc) => {
                            _countries.push({
                                value: doc.code,
                                active: _.isBoolean(doc.active) ? doc.active : true,
                                label: doc.name
                            });
                        });

                        const _states = [];
                        defaultCountry && dealersettings.states && dealersettings.states.filter(e => e.country_code === (defaultCountry)).forEach((doc) => {
                            _states.push({
                                value: doc.code,
                                active: _.isBoolean(doc.active) ? doc.active : true,
                                label: doc.name,
                                name: doc.country_code
                            });
                        });

                        const _activityTypes = [];
                        setttings.activityTypes && setttings.activityTypes.filter(a => a.active === true).forEach(doc => {
                            _activityTypes.push(
                                {
                                    label: (<><i className={`ico icon-${doc.icon} mr-1`}></i>{doc.name}</>),
                                    name: doc.name,
                                    value: doc.value,
                                    active: true,
                                });
                        });

                        if (!_.isEmpty(_activityTypes))
                            setActivityTypes(_activityTypes);

                        let _pipeline = Object.assign({}, (dealersettings?.client?.tradeinPro?.allPipelines ? dealersettings?.client?.tradeinPro?.allPipelines[0] : null));
                        if (_pipeline) {
                            let defaultstages = _pipeline.stages && _pipeline.stages;
                            _stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');

                            dealersettings?.client?.tradeinPro && dealersettings.client.tradeinPro.allPipelines.forEach((doc) => {
                                _pipelines.push({
                                    value: doc.value,
                                    active: _.isBoolean(doc.active) ? doc.active : true,
                                    label: doc.name
                                });
                            });

                            setDefaulStages(defaultstages)
                            setPipelines(_pipelines)
                            setActiveStageIndex(_stages.indexOf(_stages[0]))
                        }
                        else {

                            setDefaulStages([])
                            setPipelines([])
                            setActiveStageIndex(0)
                        }


                        setGroups(_groups)
                        setTitles(_titles);
                        setCountries(_countries);
                        setStates(_states);
                        setErrors({})
                    }

                    var _owner = null;
                    if (!_.isEmpty(_objLead) && _objLead.userEmail) {
                        _owner = props.clientUsers && props.clientUsers.filter(m => m.email === _objLead.userEmail)[0] ? props.clientUsers.filter(m => m.email === _objLead.userEmail)[0].id : null;
                    }

                    _objLead = {
                        ..._objLead,
                        //'oldStage': _objLead.stage,
                        'owner': _owner ? _owner : localStorage.uid,
                        'activityType': '',
                        'pipeline': _pipelines.length > 0 ? _pipelines[0].value : null,
                        'stage': _stages[0]
                    }

                    if (localStorage.defaultModule !== 'oem') {
                        _objLead.clientID = dealersettings.client.id;
                        _objLead.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
                    }

                    // // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
                    // let options = [];
                    // let allDealerUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
                    // _.filter(allDealerUsers.filter(v => v.clientID === _objLead.clientID)).map(rec => {
                    //     return options.push({
                    //         value: rec.id,
                    //         active: _.isBoolean(rec.active) ? rec.active : true,
                    //         searchlabel: rec.name,
                    //         label: <div className="drop-image">
                    //             <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                    //             {rec.name}
                    //         </div>
                    //     })
                    // })
                    // setAllusers(options)

                    // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
                    let options = [];
                    if (!_.isEmpty(dealersettings.client.roles) && _objLead.clientID) {
                        let roleIds = [..._.map(dealersettings.client.roles.filter(e => _objLead.pipeline && e.tradeinProPipeline && e.tradeinProPipeline.some(m => m === _objLead.pipeline)), function (obj) {
                            return obj.documentID;
                        })];
                        let _selectedUsers = [];
                        let allDealerUsers = (localStorage.defaultModule !== 'oem' && !_.isEmpty(groupUsers)) ? groupUsers : clientUsers;
                        if (!_.isEmpty(roleIds))
                            _selectedUsers = _.filter(allDealerUsers.filter(v => v.clientID === _objLead.clientID), (v) => _.includes(roleIds, v.roleID));


                        _selectedUsers && _selectedUsers.map(rec => {
                            return options.push({
                                value: rec.id,
                                active: _.isBoolean(rec.active) ? rec.active : true,
                                searchlabel: rec.name,
                                label: <div className="drop-image">
                                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                    {rec.name}
                                </div>
                            })
                        })
                        setAllusers(options)
                    }
                    else {
                        let allDealerUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
                        _.filter(allDealerUsers.filter(v => v.clientID === _objLead.clientID)).map(rec => {
                            return options.push({
                                value: rec.id,
                                active: _.isBoolean(rec.active) ? rec.active : true,
                                searchlabel: rec.name,
                                label: <div className="drop-image">
                                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                    {rec.name}
                                </div>
                            })
                        })
                        setAllusers(options)
                    }

                    let _currentUser = _objLead.owner ? options.filter(v => v.value === _objLead.owner)[0] : null;
                    if (_.isEmpty(_currentUser)) {
                        _objLead.owner = '';
                    }

                    setFields(_objLead);
                }
            }
        } catch (error) {
            errorLog(error, 'loadTIP');
        }
    }

    const handleDateChange = (val, name) => {
        if (val) {
            val = moment(moment(val).format('YYYY-MM-DD') + ' ' + (fields.activityDate ? moment.unix(fields.activityDate.seconds).format('HH:mm') : moment().format('HH:mm')))._d;

            setFields({
                ...fields,
                [name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d)
            });

            checkUserRoster(fields.owner, window.firebase.firestore.Timestamp.fromDate(moment(val)._d));
        }
    };
    const handleStartTimeChange = (val, name) => {
        //console.log(val)
        setFields({
            ...fields,
            [name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d)
        });

        checkUserRoster(fields.owner, window.firebase.firestore.Timestamp.fromDate(moment(val)._d));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const { docID } = props;
        let errors = {};
        let formIsValid = true;
        let objfields = Object.assign({}, fields);

        let errorClass = 'input_error';

        for (let [key] of Object.entries(objfields)) {
            if ((!fields[key] && key === 'owner') || (key === 'activityType' && !fields[key] && fields['activityDate'])) {
                formIsValid = false;
                errors[key] = errorClass;
            }
        }
        //console.log('handleSubmit', errors, objfields)
        setErrors(errors);

        if (formIsValid === true) {
            const inboundResult = await firestoreDB(props.dealersettings).firestore().doc(`tradeinProInbound/${docID}`).get()
            const inboundData = inboundResult.data();
            //    console.log('handleSubmit-check already exist', inboundData)
            if (!_.isEmpty(inboundData) && !_.isEmpty(inboundData.tradeinProID) && inboundData.status !== 0) {
                formIsValid = false;
                Swal.fire({
                    title: CommonHelper.showLocale(props, `Lead already allocated`),
                    showCancelButton: true,
                    confirmButtonClass: 'viewButton',
                    confirmButtonColor: '#459E20',
                    cancelButtonColor: '#17232D',
                    confirmButtonText: CommonHelper.showLocale(props, 'viewAppraisal'),
                    cancelButtonText: CommonHelper.showLocale(props, 'Okay'),
                    reverseButtons: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then((result) => {
                    if (result.value) {
                        props.handleModalClose();
                        if (props.setNotifyClose)
                            props.setNotifyClose(true);

                        history.push("/tradeinpro/details/" + inboundData.tradeinProID);
                    }
                });
                return
            }
        }

        if (formIsValid === true) {
            if (!_.isEmpty(tradeinPro.contact)) {
                handleSaveContact();
            }
            else {
                handleSaveTradein();
            }

        }
        else {
            setLoading(false);
            return formIsValid;
        }


    };

    const handleSaveTradein = async () => {

        const { dealersettings } = props;
        const _autograbIntegrations = Object.assign({}, dealersettings?.client?.integrations.filter(e => e.type === "autograb")[0]);
        const autoGrabEnabled = _autograbIntegrations?.active && _autograbIntegrations?.enabled ? true : ((!_.isEmpty(dealersettings?.client?.moduleSettings?.autograb) &&
            dealersettings.client.moduleSettings.autograb.enabled &&
            dealersettings.client.moduleSettings.autograb.vehicleDetail) ? true : false);

        let _tradeIN = Object.assign({}, (!_.isEmpty(tradein) ? tradein : tradeinPro.tradein));
        //console.log('handleSaveTradein', _tradeIN, contact?.state)

        if (autoGrabEnabled && ((_tradeIN.regNo && contact?.state) || _tradeIN.vinNo)) {

            var objData = Object.assign({}, { "clientID": fields?.clientID ? fields.clientID : dealersettings?.client?.id, upstreamData: true });

            if (_tradeIN.vinNo) {
                objData.vinNo = _tradeIN.vinNo;
            }
            else {
                objData.regNo = _tradeIN.regNo;
                objData.state = contact.state;
            }
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === Object.assign([]) || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }

            //console.log('handleAutoGrabSearch', objData);
            const tradeinplussendEmail = window.firebase.functions().httpsCallable('autograb-searchVehicle');
            tradeinplussendEmail(objData).then((response) => {
                //console.log('autograb-searchVehicle', response, response.data?.success);
                if (response.data.success && !_.isEmpty(response.data?.data?.vehicle)) {
                    bindAutoGrabTradeIn(response.data?.data)
                }
                else {
                    handleSaveTIP();
                }
            });

        }
        else {
            handleSaveTIP();
        }
    }

    const bindAutoGrabTradeIn = (_data) => {
        let _tradeIN = Object.assign({}, (!_.isEmpty(tradein) ? tradein : tradeinPro.tradein));
        let state = Object.assign({}, _tradeIN);
        state = CommonHelper.bindAutoGrabDetail(state, _data);

        setTradein(state)
        setTimeout(() => handleSaveTIP(state), 500);

    }

    const handleSaveTIP = async (_tradeIN) => {

        try {
            setLoading(true);
            const { dealersettings, stage } = props;
            if (dealersettings &&
                dealersettings.client &&
                dealersettings.client.settings) {
                let state = Object.assign({}, tradeinPro);


                state.addedBy = dealersettings ? dealersettings.id : '';
                if (fields.tradeinProID)
                    state.addedDate = window.firebase.firestore.Timestamp.now()
                else
                    state.addedDate = fields.leadCreated ? fields.leadCreated : (fields.addedDate ? fields.addedDate : window.firebase.firestore.Timestamp.now());

                state.addedFrom = 'web';

                state.modifiedBy = dealersettings ? dealersettings.id : '';
                state.modifiedDate = window.firebase.firestore.Timestamp.now();
                state.createdDate = window.firebase.firestore.Timestamp.now();
                state.modifiedFrom = 'web';

                state.documentID = firestoreDB(dealersettings).firestore().collection('tradeinPro').doc().id;
                state.createdDate = window.firebase.firestore.Timestamp.now();
                state.isDeleted = false;
                state.isNewTradein = true;

                const setttings = dealersettings.client.tradeinPro;
                let _selPipeline = Object.assign({}, setttings.pipelines.filter(i => i.default === true)[0]);
                if (_.isEmpty(_selPipeline))
                    _selPipeline = Object.assign({}, setttings.pipelines[0]);

                let defaultstages = _selPipeline.stages;
                let stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')

                state.pipeline = fields.pipeline ? fields.pipeline : _selPipeline.value;
                state.stage = fields.stage ? fields.stage : stages[0];
                state.status = tipStatus.NEW;
                state.stageDate = window.firebase.firestore.Timestamp.now();
                state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
                state.tags = [
                    {
                        type: (fields.systemType ? fields.systemType : ''),
                        refID: (fields.refID ? fields.refID : ''),
                        subType: (fields.subType ? fields.subType : '')
                    }
                ]

                if (fields.owner) state.owner = fields.owner;
                if (fields.campaign) state.campaign = fields.campaign;
                if (fields.origin) state.origin = fields.origin;
                if (fields.enquiryType) state.enquiryType = fields.enquiryType;
                if (fields.leadSource) state.leadSource = fields.leadSource;
                state.inboundLeadID = fields.documentID || null

                state.clientID = fields.clientID ? fields.clientID : (dealersettings ? dealersettings.client.id : '');
                state.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

                state.isConverted = true;
                state.convertedBy = localStorage.uid;
                state.convertedDate = window.firebase.firestore.Timestamp.now();

                if (state.isContact && !_.isEmpty(contact)) {
                    let objContactData = Object.assign({}, contact);
                    //Remove empty value from object
                    for (var propName in objContactData) {
                        if (objContactData[propName] === null || objContactData[propName] === undefined || objContactData[propName] === '' || objContactData[propName] === Object.assign([]) || (_.isObject(objContactData[propName]) && _.isEmpty(objContactData[propName]))) {
                            delete objContactData[propName];
                        }
                    }
                    objContactData = bindCompany(objContactData);
                    state.contact = objContactData;
                    state.contactID = objContactData.documentID;
                    state.isNewTradein = false;
                }

                if (state.isTradein && (!_.isEmpty(tradein) || !_.isEmpty(_tradeIN))) {
                    let objTradeinData = Object.assign({}, !_.isEmpty(_tradeIN) ? _tradeIN : tradein);
                    objTradeinData.documentID = firestoreDB(dealersettings).firestore().collection('tradeins').doc().id;
                    objTradeinData.tradeinProID = state.documentID;
                    objTradeinData.status = tipStatus.NEW;
                    objTradeinData.addedBy = localStorage.uid;
                    objTradeinData.addedDate = window.firebase.firestore.Timestamp.now();
                    objTradeinData.addedFrom = 'web';
                    objTradeinData.modifiedBy = localStorage.uid;
                    objTradeinData.modifiedDate = window.firebase.firestore.Timestamp.now();
                    objTradeinData.modifiedFrom = 'web';
                    objTradeinData.isDeleted = false;
                    objTradeinData.clientID = state.clientID ? state.clientID : null;
                    objTradeinData.projectId = state.projectId ? state.projectId : null;
                    objTradeinData.mileage = objTradeinData.mileage ? objTradeinData.mileage.toString() : '';
                    //Remove empty value from object
                    for (var propName in objTradeinData) {
                        if (objTradeinData[propName] === null || objTradeinData[propName] === undefined || objTradeinData[propName] === '' || objTradeinData[propName] === Object.assign([]) || (_.isObject(objTradeinData[propName]) && _.isEmpty(objTradeinData[propName]))) {
                            delete objTradeinData[propName];
                        }
                    }

                    state.tradein = objTradeinData;
                    state.tradeinID = objTradeinData.documentID;
                }

                const objData = Object.assign({}, state);
                //Remove empty value from object
                for (var propName in objData) {
                    if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === Object.assign([]) || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                        delete objData[propName];
                    }
                }
                //    console.log('handleSaveTIP', objData)
                const batch = firestoreDB(dealersettings).firestore().batch();
                batch.set(firestoreDB(dealersettings).firestore().doc(`tradeinPro/${objData.documentID}`), objData, { merge: true });
                let documentID = objData.documentID;

                //create trade-in
                if (objData.isTradein && !_.isEmpty(documentID) && !_.isEmpty(objData.tradein) && !_.isEmpty(objData.tradeinID))
                    batch.set(firestoreDB(dealersettings).firestore().doc(`tradeins/${objData.tradeinID}`), objData.tradein, { merge: true });

                //updateInBound
                if (!_.isEmpty(documentID) && !_.isEmpty(fields.documentID)) {
                    let _objReq = {
                        status: 1,
                        tradeinProID: documentID,
                        modifiedBy: localStorage.uid,
                        modifiedDate: window.firebase.firestore.Timestamp.now(),
                        modifiedFrom: 'web'
                    }

                    batch.set(firestoreDB(dealersettings).firestore().doc(`tradeinProInbound/${fields.documentID}`), _objReq, { merge: true });
                }

                updateConvertedData(batch, documentID, objData);

                CommonHelper.saveChangeLog(objData.clientID, objData.groupID, objData.documentID, 'Lead converted', 'tradeinPro', 'updated');

                batch.commit()
                    .then(() => {
                        setLoading(false);
                        toast.notify('Allocated sucessfully', { duration: 2000 });
                        if (props.setNotifyClose)
                            props.setNotifyClose(true);

                        props.handleModalClose();
                    })
                    .catch((error) => {
                        setLoading(false);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    });

            }
        } catch (error) {
            errorLog(error, 'handleSaveTIP');
        }
    }

    const updateConvertedData = async (batch, tradeinProID, __tiProData) => {
        try {

            logNotes && logNotes.forEach(newlogVM => {
                newlogVM.tradeinProID = tradeinProID;
                newlogVM.tradeinPro = Object.assign({}, __tiProData);
                newlogVM.contact = __tiProData && __tiProData.contact ? Object.assign({}, __tiProData.contact) : null;
                newlogVM.contactID = contact ? contact.documentID : null;
                newlogVM.clientID = __tiProData.clientID ? __tiProData.clientID : null;
                Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
                newlogVM.projectId = __tiProData.projectId ? __tiProData.projectId : null;

                batch.set(firestoreDB(props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`), newlogVM, { merge: true });
            })

            // notesHistory && notesHistory.forEach(newlogVM => {
            //     newlogVM.type = 'tradeinProlog';
            //     newlogVM.subType = 'note';
            //     newlogVM.isDone = true;
            //     newlogVM.isDeleted = false;
            //     newlogVM.startDate = newlogVM.addedDate ? newlogVM.addedDate : window.firebase.firestore.Timestamp.now();
            //     newlogVM.tradeinProID = tradeinProID;
            //     newlogVM.tradeinPro = Object.assign({}, __tiProData);
            //     newlogVM.contact = __tiProData && __tiProData.contact ? Object.assign({}, __tiProData.contact) : null;
            //     newlogVM.contactID = contact ? contact.documentID : null;
            //     newlogVM.clientID = __tiProData.clientID ? __tiProData.clientID : null;
            //     newlogVM.projectId = __tiProData.projectId ? __tiProData.projectId : null;
            //     batch.set(firestoreDB(props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`), newlogVM, { merge: true });
            // })

            if (!_.isEmpty(fields.comments)) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'tradeinProlog';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedBy = localStorage.uid;
                newlogVM.modifiedBy = localStorage.uid;
                newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
                newlogVM.tradeinProID = tradeinProID;
                newlogVM.contactID = (contact && contact.documentID) ? contact.documentID : null;
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = window.firebase.firestore.Timestamp.now();
                newlogVM.notes = fields.comments;
                newlogVM.clientID = __tiProData.clientID ? __tiProData.clientID : null;
                Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
                newlogVM.projectId = __tiProData.projectId ? __tiProData.projectId : null;

                if (!_.isEmpty(mentions)) {
                    if (mentions.length > 0) {
                        let convertedIDs = []
                        let rawIDs = mentions.map(mentionObject => mentionObject.id)
                        rawIDs.forEach(id => {
                            if (id.includes(',')) {
                                let allIDs = id.split(',')
                                allIDs.forEach(a => {
                                    convertedIDs.push(a)
                                })
                            } else {
                                convertedIDs.push(id)
                            }
                        })

                        if (newlogVM.mentions && newlogVM.mentions.length > 0) {
                            newlogVM.mentions = _.uniq([...newlogVM.mentions, ...convertedIDs])
                        } else {
                            newlogVM.mentions = _.uniq(convertedIDs)
                        }
                    }
                }

                batch.set(firestoreDB(dealersettings).firestore().doc(`activities/${newlogVM.documentID}`), newlogVM, { merge: true });
            }

            if (!_.isEmpty(fields.activityDate)) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'tradeinProactivity';
                newlogVM.subType = fields.activityType ? fields.activityType : 'appointment';
                newlogVM.title = fields.activityType ? CommonHelper.getNameByValue(activityTypes, fields.activityType) : 'Appointment';
                newlogVM.owner = fields.owner;
                newlogVM.isDone = true;
                newlogVM.addedBy = localStorage.uid;
                newlogVM.modifiedBy = localStorage.uid;
                newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
                newlogVM.tradeinProID = tradeinProID;
                newlogVM.tradeinPro = Object.assign({}, __tiProData);
                newlogVM.contact = __tiProData && __tiProData.contact ? Object.assign({}, __tiProData.contact) : null;
                newlogVM.contactID = contact ? contact.documentID : null;
                newlogVM.clientID = __tiProData.clientID ? __tiProData.clientID : null;
                newlogVM.projectId = __tiProData.projectId ? __tiProData.projectId : null;
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = fields.activityDate;
                newlogVM.endDate = window.firebase.firestore.Timestamp.fromDate(moment.unix(fields.activityDate.seconds).add('hour', 1).toDate());
                newlogVM.addedByName = CommonHelper.getUserNamebyId(props.clientUsers, localStorage.uid);
                newlogVM.notes = fields.comments;

                batch.set(firestoreDB(dealersettings).firestore().doc(`activities/${newlogVM.documentID}`), newlogVM, { merge: true });

            }

        } catch (error) {
            errorLog(error, 'updateConvertedData');
        }

    }

    const bindCompany = (objData) => {

        if (!_.isEmpty(objData) && !_.isEmpty(objData.company)) {
            let _company = Object.assign({});
            if (_.isString(objData.company)) {
                _company.name = objData.company;
                _company.documentID = firestoreDB(props.dealersettings).firestore().collection('companies').doc().id;
            }
            else if (_.isObject(objData.company) && _.isEmpty(objData.company.documentID)) {
                _company = Object.assign({}, companyBasicVM);
                for (let [key, value] of Object.entries(objData.company)) {
                    if (_company.hasOwnProperty(key) && value)
                        _company[key] = value;
                }
                _company.documentID = firestoreDB(props.dealersettings).firestore().collection('companies').doc().id;
            }

            if (!_.isEmpty(_company)) {
                objData.company = _company;
                objData.isCompany = true;
            }
            else
                objData.company = null;

        }

        return objData;

    }

    const deleteInbound = () => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'You want to delete?'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(result => {
            if (result.value) {
                setDeleteloader(true);
                let objFilds = {
                    'modifiedDate': window.firebase.firestore.Timestamp.now(),
                    'modifiedBy': localStorage.uid,
                    'isDeleted': true,
                    'status': 2
                }
                firestoreDB(props.dealersettings).firestore().doc(`tradeinProInbound/${props.docID}`)
                    .set(objFilds, { merge: true })
                    .then(snapshot => {
                        setDeleteloader(false);

                        props.handleModalClose();
                        if (props.setNotifyClose)
                            props.setNotifyClose(true);

                        toast.notify('Lead deleted successfully', {
                            duration: 2000,
                        });
                    })
                    .catch(error => {
                        errorLog(error, 'deleteInbound');
                        setDeleteloader(false);
                        console.error(error);
                        toast.notify('Something went wrong', {
                            duration: 2000,
                        });
                    });
            }
        });
    };


    //#endregion

    //#region  CONTACT CHANGES

    const handleContactClose = (ID, _contact) => {
        if (!_.isEmpty(_contact)) {
            Object.keys(_contact).forEach(key => ([undefined, null, ''].includes(_contact[key]) || (_.isObject(_contact[key]) && _.isEmpty(_contact[key]))) ? delete _contact[key] : {});
            if (!_.isEmpty(_contact)) setContact(_contact)
        }

        setshowContactModal(false)
    }

    const handleSaveContact = () => {
        try {

            setLoading(true);
            let objData = Object.assign({}, contact);
            let _param = {};
            let dealersettings = props.dealersettings;
            if (_.isEmpty(objData.addedBy)) {
                objData.addedBy = props.dealersettings ? props.dealersettings.id : '';
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
            }
            objData.modifiedBy = props.dealersettings ? props.dealersettings.id : '';
            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.isDeleted = false;
            objData.modifiedFrom = 'web';

            if (fields.campaign && !objData.campaign) objData.campaign = fields.campaign;
            if (fields.origin && !objData.origin) objData.origin = fields.origin;
            let defaultCountry = props.dealersettings?.client?.countryCode ? props.dealersettings.client.countryCode.toUpperCase() : 'AU';
            //Remove empty value from object
            for (var propName in objData) {

                if (defaultCountry === 'AU' && (propName === 'phone' || propName === 'secondaryPhone')) {
                    objData[propName] = CommonHelper.validPhoneZeroPrefix(objData[propName], objData[`${propName}Code`])
                }

                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === Object.assign([]) || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }
            if (_.isEmpty(objData.country)) objData.country = defaultCountry;
            //save license image
            // if (fields.request && fields.request.LicenseImageURL && fields.request.LicenseImageURL.includes('http')) {
            //     objData.licenseFrontURL = fields.request.LicenseImageURL;
            // }
            // if (fields.request && fields.request.LicenseImageBackURL && fields.request.LicenseImageBackURL.includes('http')) {
            //     objData.licenseBackURL = fields.request.LicenseImageBackURL;
            // }
            // if (fields.request && fields.request.ImageURL && fields.request.ImageURL.includes('http')) {
            //     objData.userImageURL = fields.request.ImageURL;
            // }
            // if (fields.request && fields.request.LicenseExpiry) {
            //     objData.licenseExpiry = moment(fields.request.LicenseExpiry).format('YYYY-MM-DD');
            // }
            // if (fields.request && fields.request.LicenseNo) {
            //     objData.licenseNo = fields.request.LicenseNo;
            // }
            //end

            if (!_.isEmpty(tradeinPro.convertedLeadRefID) &&
                _.isEmpty(contact.mergeID) &&
                !_.isEmpty(objData.clientID) &&
                objData.clientID !== dealersettings.client.id) {
                objData = copyExistingContact(objData);
            }

            if (!objData.documentID)
                objData.documentID = firestoreDB(props.dealersettings).firestore().collection('contactDetails').doc().id;

            objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(objData, props.dealersettings, objData.clientID, true);



            // if (!objData.clientID)
            //     objData.clientID = fields.clientID ? fields.clientID : (props.dealersettings ? props.dealersettings.client.id : '');

            // if (!objData.groupID)
            //     objData.groupID = (props.dealersettings && props.dealersettings.client.group ? props.dealersettings.client.group : null);

            // if (!objData.regionID)
            //     objData.regionID = (props.dealersettings && props.dealersettings.client.region ? props.dealersettings.client.region : null);

            // if (!objData.oemID)
            //     objData.oemID = (props.dealersettings ? props.dealersettings.client.settingsID : null);

            if (objData.documentID)
                _param['contactID'] = objData.documentID;

            // if (objData.groupID) {
            //     _param['groupID'] = objData.groupID;
            // }

            const _systemType = Object.assign({}, !_.isEmpty(dealersettings?.client?.integrations) ?
                dealersettings.client.integrations.filter(e => e.type === fields.systemType)[0] : {});

            if (!_.isEmpty(_systemType) && _systemType?.settings?.customerConsent)
                objData.terms = {
                    addedBy: localStorage.uid,
                    addedDate: window.firebase.firestore.Timestamp.now(),
                    verbalConsent: false,
                    signature: '',
                    termsAndConditions: !_.isEmpty(dealersettings?.client?.digitalConsent) ? dealersettings.client.digitalConsent : (!_.isEmpty(dealersettings?.client?.customerConsent) ? dealersettings.client.customerConsent : ''),
                    consentType: 'Digital'
                };


            const _pmds = Object.assign({}, !_.isEmpty(dealersettings) &&
                dealersettings.client &&
                dealersettings.client.integrations &&
                dealersettings.client.integrations.filter(e => e.type === "pmds")[0]);
            if (!_.isEmpty(_pmds) && _pmds.active && _pmds.enabled)
                _param['pmds'] = _pmds;

            if (!_.isEmpty(objData.displayID) || (!_.isEmpty(objData.tags) && objData.tags.some(e => e.type === 'pmds')))
                _param['pmds'] = null;

            const _keyloop = Object.assign({}, !_.isEmpty(dealersettings) &&
                dealersettings.client &&
                dealersettings.client.integrations &&
                dealersettings.client.integrations.filter(e => e.type === "keyloop")[0]);
            _param['keyloop'] = !_.isEmpty(_keyloop) && _keyloop.active && _keyloop.enabled ? true : false;

            if (!_.isEmpty(objData.displayID) || (!_.isEmpty(objData.tags) && objData.tags.some(e => e.type === 'keyloop')))
                _param['keyloop'] = null;

            //_param['testpmds'] = true;

            if (objData.mergeID) {
                _param['mergeID'] = objData.mergeID;
                //_param['pmds'] = null;
                delete objData['mergeID'];
            }

            _param = CommonHelper.setClientOrGroupOrRegionOrOemID(_param, props.dealersettings);
            _param['isShared'] = ((!_.isEmpty(props.dealersettings) &&
                !_.isEmpty(props.dealersettings.group) &&
                props.dealersettings.group.enableSharedContact) ? true : false);
            setContact(objData);
            //console.log('contact-saveContact', objData, _param)
            const addContact = window.firebase.functions().httpsCallable('contact-saveContact');
            addContact({
                "model": objData,
                "param": _param
            }).then((res) => {

                let response = res.data;
                if (response.success === false) {
                    //console.log(response);
                    if (response.code === contactResponseCode.PhoneAlreadyExists ||
                        response.code === contactResponseCode.EmailAlreadyExists ||
                        response.code === contactResponseCode.LicenseAlreadyExists ||
                        response.code === contactResponseCode.PMDSAlreadyExists ||
                        response.code === contactResponseCode.KeyloopAlreadyExists) {
                        setLoading(false);
                        handleExistingContact(response);
                    }
                    else {
                        toast.notify(response.message, {
                            duration: 2000
                        })
                    }
                    return false;
                }
                else {
                    handleSaveTradein();
                    return true;
                }
            });
        } catch (error) {
            errorLog(error, 'handleSaveContact');
        }
    }

    const handleExistingContact = (response) => {

        let _entity;
        let _msg;
        let _title;
        let _fullName = response.data ? CommonHelper.displayContactName([], response.data) : '';

        if (response.code === contactResponseCode.PhoneAlreadyExists) {
            _entity = "mobile no.";
            _title = "Mobile No. already exist";
        } else if (response.code === contactResponseCode.EmailAlreadyExists) {
            _entity = "email address";
            _title = "Email already exist";
        } else if (response.code === contactResponseCode.LicenseAlreadyExists) {
            _entity = "license no.";
            _title = "License No. already exist";
        }

        _msg = _entity ? `A contact ${_fullName} already has this ${_entity}.` : '';
        if (response.code === contactResponseCode.PMDSAlreadyExists || response.code === contactResponseCode.KeyloopAlreadyExists)
            _title = response.message;


        Swal.fire({
            title: CommonHelper.showLocale(props, (_title ? _title : '')),
            html: _msg ? _msg : '',
            showCancelButton: true,
            confirmButtonClass: 'viewButton',
            confirmButtonColor: '#459E20',
            cancelButtonColor: '#17232D',
            confirmButtonText: CommonHelper.showLocale(props, 'View Contact'),
            cancelButtonText: CommonHelper.showLocale(props, 'Okay'),
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                handleXContactShow(response.data)
            }
        });
    }

    const handleXContactShow = (data) => {
        try {

            if (!_.isEmpty(data)) {
                data = convertContactVM(data);
                setshowContactInfo(true);
                setexistingContactInfo(data);
            }
        } catch (error) {
            errorLog(error, 'handleXContactShow');
        }
    }

    const handleXContactClose = (type, objData) => {
        try {

            if (type && !_.isEmpty(objData)) {
                if (type === 'fillMissing') {
                    fillMissingContact(objData);
                }
                else if (type === 'replaceAll' || type === 'merge') {
                    setContactMerged(true);
                    replaceContact(objData)
                }
            }
            setshowContactInfo(false);
            setexistingContactInfo({})
        } catch (error) {
            errorLog(error, 'handleXContactClose');
        }
    }

    const convertContactVM = (doc) => {
        doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

        const objcontactData = Object.assign({}, doc);
        const contact = Object.assign({}, objContact);
        for (let [key, value] of Object.entries(objcontactData)) {
            if (contact.hasOwnProperty(key))
                contact[key] = value;
        }
        return contact
    }
    const fillMissingContact = (objData) => {
        const _fields = Object.assign({}, contact);

        for (let [key, value] of Object.entries(objData)) {
            if (_fields[key] === null || _fields[key] === undefined || _fields[key] === false || _fields[key] === '' || _fields[key] === Object.assign([]) || (_.isObject(_fields[key]) && _.isEmpty(_fields[key]))) {
                _fields[key] = value;
            }
        }
        _fields.documentID = objData.documentID ? objData.documentID : '';
        _fields.owner = objData.owner ? objData.owner : '';
        _fields.addedBy = objData.addedBy ? objData.addedBy : '';
        _fields.addedDate = objData.addedDate ? objData.addedDate : null;
        if (_.isEmpty(_fields.owner)) _fields.owner = localStorage.uid;
        if (_.isEmpty(_fields.country) && !_.isEmpty(_fields.state)) _fields.country = 'AU';
        _fields.isDeleted = false;
        setContact(_fields);
    }
    const replaceContact = (objData) => {
        const _fields = Object.assign({}, objContact);
        for (let [key, value] of Object.entries(objData)) {
            _fields[key] = value;
        }
        _fields.isDeleted = false;
        if (_.isEmpty(_fields.owner)) _fields.owner = localStorage.uid;
        if (_.isEmpty(_fields.country) && !_.isEmpty(_fields.state)) _fields.country = 'AU';
        setContact(_fields);
    }

    const copyExistingContact = (objData) => {
        const _fields = Object.assign({}, objData);

        _fields.documentID = firestoreDB(props.dealersettings).firestore().collection('contactDetails').doc().id;
        _fields.displayID = null;
        _fields.refContactID = objData.documentID;
        _fields.addedBy = props.dealersettings ? props.dealersettings.id : '';
        _fields.addedDate = window.firebase.firestore.Timestamp.now();
        _fields.owner = props.dealersettings ? props.dealersettings.id : '';
        _fields.isDeleted = false;

        // if (objData.clientID !== props.dealersettings.id) {

        // }
        return _fields
    }

    const errorLog = (error, origin) => {
        const documentID = window.firebase.firestore().collection('errorLogs').doc().id;
        const ref = window.firebase.firestore().collection('errorLogs').doc(documentID);
        ref.set({
            addedDate: window.firebase.firestore.Timestamp.now(),
            addedBy: localStorage.uid,
            clientID: props.dealersettings.client.id,
            oemID: props.dealersettings.client.settingsID,
            error: error.message ? error.message : '',
            description: (origin ? origin + ':- ' : '') + (error.message ? error.message : ''),
            currentPath: window.location.pathname,
        }).then(() => {
            console.log('Error has been uploaded')
        }).catch((error) => {
            console.error("Error adding log: ", error);
        });
    }

    //#endregion

    const { dealersettings } = props;

    const _settings = dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings;

    //console.log('C O N T A C T', fields.isDeleted);

    const onMentionChange = notesText => {
        if (notesText)
            notesText = notesText.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });

        if (notesText.length > 0) {
            setErrors({ ...errors, 'notes': '' });
        }
        setFields({
            ...fields,
            comments: notesText
        });

        // handleChangelog(e, true);
    };

    const onMentionSelect = (option, ownerID) => {
        // console.log('select', option);
        const { name, id } = option;
        let idToPass = id
        // return if user is mentioning himself
        if (idToPass === localStorage.uid) {
            return;
        }

        if (id.includes(',')) {
            if (idToPass.includes(localStorage.uid)) {
                idToPass = idToPass.replace(`${localStorage.uid},`, '')
                idToPass = idToPass.replace(`${localStorage.uid}`, '')
            }
            if (ownerID && idToPass.includes(ownerID)) {
                idToPass = idToPass.replace(`${ownerID},`, '')
                idToPass = idToPass.replace(`${ownerID}`, '')
            }
            setTeamMentions(idToPass)
        }

        // Check to avoid adding duplicate entries in mention
        const check = mentions.filter(mention => mention.id === idToPass);

        if (check.length === 0) {
            setMentions([...mentions, { name, id: idToPass }]);
        }
    };

    const clearTeamMentions = () => {
        setTeamMentions(null)
    }


    const _transferHistoryData = fields && !_.isEmpty(fields.transferHistory) ? CommonHelper.getLatestTransferHistory(fields.transferHistory, dealersettings, true, 'InboundLead') : null;

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleModalClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-dialog-scrollable modal-integration-lead"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={'Trade-In Lead Details'} />

                        <div className="lead-source-badge">
                            {!_.isEmpty(_transferHistoryData) ?
                                <div className="oem-tag-id w-100 mr-2">
                                    <i className="ico icon-location"></i>{_transferHistoryData.name}
                                    <span className="user-role-span ml-1">{_transferHistoryData.value}</span>
                                </div> : <></>}
                            {
                                fields.tags && fields.tags.length > 0 ? (<>

                                    {
                                        fields.tags.filter(m => !_.isEmpty(m)).map((r, i) => {
                                            return <div key={i} className="badge badge-pill badge-white ml-1 text-capitalize">{r}</div>
                                        })
                                    }

                                </>) : (<></>)
                            }
                            {fields && fields.isDeleted ?
                                <><div className={`badge badge-pill badge-lost ml-2`}><Translate text={'deleted'} upperCase={true} /></div></>
                                :
                                <></>}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    {
                        !_.isEmpty(fields)
                            ?
                            <>
                                <div className={`container-fluid ${(fields.isDeleted) ? 'form-disable' : ''}`}>
                                    <div className="row">
                                        <div className="integration-lead-body">
                                            <div className="integration-lead-contact-details">
                                                <h3> <Translate text={'contactDetails'} /></h3>
                                                <div className="integration-lead-box">
                                                    <div className="integration-lead-contact-head">
                                                        {(() => {
                                                            return <>
                                                                {
                                                                    (localStorage.defaultModule !== 'oem' && fields.status !== 1 && !fields.isDeleted) && (<div className="loaninfo-edit-button">
                                                                        <a href="#" className="mini-button" title={'Edit Contact Info'} onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setshowContactModal(true);
                                                                        }}>
                                                                            <i className="ico icon-edit"></i>
                                                                        </a>
                                                                    </div>)
                                                                }
                                                            </>
                                                        })()}
                                                        <div className="integration-lead-contact-title"> {CommonHelper.displayFullContact(titles, contact, '--')}</div>
                                                        {contact && contact.preferredName ? <span className="integration-lead-contact-preferred">({contact.preferredName})</span> : <></>}
                                                        <div className="integration-lead-contact-sub">
                                                            <ul className="integration-lead-ul">
                                                                <li> <i className="ico icon-call"></i> {contact && contact.phone ? (CommonHelper.phoneFormat(contact.phoneCode, contact.phone, dealersettings)) : '--'}</li>
                                                                <li> <i className="ico icon-email"></i> {contact && contact.email ? (contact.email) : '--'}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="integration-lead-contact-table">
                                                        <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                            <tbody>
                                                                <tr className="tr-brk">
                                                                    <td width="25%"><span className="table-label"><Translate text={'licenseNo'} /></span> {contact && contact.licenseNo ? (contact.licenseNo) : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'licenseExpiry'} /> </span> {contact && contact.licenseExpiry ? (contact.licenseExpiry) : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'gender'} /></span> {contact && contact.gender ? (contact.gender) : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'companyName'} /></span> {contact && contact.company ? (_.isObject(contact.company) ? contact.company.name : contact.company) : '--'}</td>
                                                                </tr>
                                                                <tr className="tr-brk">

                                                                    <td colSpan="2"><span className="table-label"><Translate text={'address'} /></span> {contact && contact.address ? (contact.address) : '--'} </td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'country'} /></span> {contact && contact.country ? CommonHelper.getLabelByValue(countries, contact.country) : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'state'} /> </span> {contact && contact.state ? CommonHelper.getLabelByValue(states, contact.state) : '--'}</td>
                                                                </tr>
                                                                {/* <tr className="tr-brk">
                                                                    <td width="25%"><span className="table-label"><Translate text={'areaCode'} /></span> {contact && contact.areaCode ? (contact.areaCode) : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'nationality'} /></span> {contact && contact.nationality ? (contact.nationality) : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'dob'} /></span>{contact && contact.dob ? (contact.dob) : '--'}</td>                                                                    
                                                                    <td width="25%"><span className="table-label"><Translate text={'optinPhone'} /></span> {contact && contact.optinPhone ? (contact.optinPhone) : '--'}</td>
                                                                </tr>
                                                                <tr className="tr-brk">
                                                                    <td width="25%"><span className="table-label"><Translate text={'optinEmail'} /></span>{contact && contact.optinEmail ? (contact.optinEmail) : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'optinPost'} /> </span> {contact && contact.optinPost ? (contact.optinPost) : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'optinSMS'} /></span> {contact && contact.optinSMS ? (contact.optinSMS) : '--'}</td>
                                                                    <td width="25%">&nbsp;</td>
                                                                </tr> */}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <h3> <Translate text={'vehicleDetails'} /></h3>
                                                <Requirements
                                                    requirement={fields.tradein || {}}
                                                    //enquiryID={activity.enquiryID}
                                                    handleEdit={() => { }}
                                                    setPrimary={() => { }}
                                                    decrementQuantity={() => { }}
                                                    incrementQuantity={() => { }}
                                                    isPrimary={false}
                                                    isDeivered={true}
                                                    hideMore={true}
                                                    dealersettings={props.dealersettings}
                                                    clientUsers={props.clientUsers}
                                                    groupUsers={props.groupUsers}
                                                >
                                                </Requirements>
                                                {/* <div className="integration-lead-box">
                                                    <div className="integration-lead-vehicle-head">
                                                        <div className="flex-vehicle">
                                                            <div className="vehicle-item-image"> <a href="#"> <img src={CommonHelper.showBrandLogo(dealersettings, fields?.tradein?.make)} className="img-object-fit-contain" alt="" width="70" height="70" /> </a> </div>
                                                            <div className="vehicle-data">
                                                                <div className="vehicle-item-title">
                                                                    {fields.tradein && fields.tradein.make ? (fields.tradein.make) : ''} &nbsp;
                                                                    {fields.tradein && fields.tradein.model ? (fields.tradein.model) : ''}
                                                                </div>
                                                                <div className="vehicle-item-info">
                                                                    {fields.tradein && fields.tradein.extColor ? `${fields.tradein.extColor}, ` : ''}
                                                                    {fields.tradein && fields.tradein.year ? (fields.tradein.year) : ''}
                                                                </div>
                                                                {fields?.tradein?.regNo ? <><div className="vehicle-item-info">
                                                                    <Translate text={'reg'} toUpperCase={true} />#: &nbsp; {fields.tradein.regNo}
                                                                </div></> : <></>}
                                                                {
                                                                    fields.tradein && fields.tradein.saleType ?
                                                                        <div className="vehicle-item-status">

                                                                            <div className="badge badge-pill badge-white ml-1">
                                                                                {CommonHelper.getNameByValue(_settings && _settings.salesType,
                                                                                    fields.tradein.saleType, '')}
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div> */}

                                                <h3> <Translate text={'Other Information'} /></h3>

                                                <div className="integration-lead-box">
                                                    <div className="integration-lead-contact-table">
                                                        <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                            <tbody>
                                                                {/* <tr className="tr-brk">
                                                                    <td width="25%"><span className="table-label"><Translate text={'origin'} /> </span>{fields.origin ? CommonHelper.getNameByValue(_settings && _settings.origins, fields.origin, '--') : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'campaign'} /></span> {fields.campaign ? CommonHelper.getNameByValue(_settings && _settings.campaigns, fields.campaign, '--') : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'leadSource'} /> </span>{fields.leadSource ? CommonHelper.getNameByValue(_settings && _settings.leadSource, fields.leadSource, '--') : '--'}</td>
                                                                    <td width="25%">
                                                                        <span className="table-label"><Translate text={'Received Date & Time'} /></span>
                                                                        {fields.addedDate ? moment.unix(fields.addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}
                                                                    </td>
                                                                </tr> */}
                                                                <tr className="tr-brk">
                                                                    <td width="25%">
                                                                        <span className="table-label"><Translate text={'Received Date & Time'} /></span>
                                                                        {fields.addedDate ? moment.unix(fields.addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}
                                                                    </td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'Appointment Date & Time'} /></span> {fields.appointmentOn ? (fields.appointmentOn) : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'origin'} /> </span>{fields.origin ? CommonHelper.getNameByValue(dealersettings.client.tradeinPro.origins, fields.origin, '--') : '--'}</td>
                                                                    {/* <td width="25%"><span className="table-label"><Translate text={'Ref. No.'} /></span> {fields.displayID ? (fields.displayID) : '--'}</td> */}
                                                                    <td width="25%"><span className="table-label"><Translate text={'Notes'} /> </span>
                                                                        <span className="text-area-space">{fields.notes ? (
                                                                            <div dangerouslySetInnerHTML={{
                                                                                __html: CommonHelper.stringtoURL(fields.notes),
                                                                            }}
                                                                            ></div>
                                                                        ) : '--'}</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>


                                                {
                                                    fields.status !== 1
                                                        ?
                                                        <>
                                                            <div className="lead-stage-layout form-style">

                                                                <div className="form-row ">
                                                                    <div className="form-group col-md-12 mt-2">
                                                                        <label > <Translate text={'dealerships'} /></label>
                                                                        <ReactSelect
                                                                            options={groups}
                                                                            name={"clientID"}
                                                                            placeholder={'select dealer'}
                                                                            onChange={handleDealerChange}
                                                                            value={fields.clientID}
                                                                            classNamePrefix={`${errors["clientID"]} basic-select`}
                                                                            removeClearable={true}
                                                                        >
                                                                        </ReactSelect>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row ">
                                                                    <div className="form-group col-md-12 mt-2">
                                                                        <label > <Translate text={'pipeline'} /></label>
                                                                        <ReactSelect
                                                                            options={pipelines}
                                                                            name={"pipeline"}
                                                                            placeholder={'select pipeline'}
                                                                            onChange={handlePipelineChange}
                                                                            value={fields.pipeline}
                                                                            classNamePrefix={`${errors["pipeline"]} basic-select`}
                                                                            removeClearable={true}
                                                                        >
                                                                        </ReactSelect>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <label ><Translate text={'Pipeline Stage'} /> </label>
                                                                        <div className={`${errors["stage"] ? 'custom_error' : ''}`}>
                                                                            <ul className={`pipeline-step `}>
                                                                                {
                                                                                    defaulStages.length > 0 && defaulStages.map((_stage, index) => {
                                                                                        return (
                                                                                            <OverlayTrigger
                                                                                                key={index}
                                                                                                placement='bottom'
                                                                                                overlay={
                                                                                                    <Tooltip>{_stage.name}</Tooltip>
                                                                                                }
                                                                                            >
                                                                                                <li key={index} className={activeStageIndex >= index ? 'active' : ''}>
                                                                                                    <a href="#" id={index} name={_stage.value} onClick={(e) => handleStageChange(e)}>
                                                                                                        {/* {_stage.name} */}
                                                                                                        {<>&nbsp;</>}
                                                                                                    </a>
                                                                                                </li>
                                                                                            </OverlayTrigger>

                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label ><Translate text={'owner'} /></label>
                                                                    <ReactSelect
                                                                        options={allUsers}
                                                                        name={"owner"}
                                                                        onChange={handleReactSelectChange}
                                                                        value={fields.owner}
                                                                        classNamePrefix={`${errors["owner"]} basic-select`}
                                                                        removeClearable={true}
                                                                    >
                                                                    </ReactSelect>
                                                                </div>
                                                                <div className="scheduled-activities-title"> <Translate text={'activity'} /></div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-3">
                                                                        <label > <Translate text={'activityType'} /></label>
                                                                        <ReactSelect
                                                                            options={activityTypes}
                                                                            name={"activityType"}
                                                                            placeholder={'select activity type'}
                                                                            onChange={handleReactSelectChange}
                                                                            value={fields.activityType}
                                                                            classNamePrefix={`${errors["activityType"]} basic-select`}
                                                                            removeClearable={true}
                                                                            isSearchable={false}
                                                                        >
                                                                        </ReactSelect>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label ><Translate text={'activityDate'} /></label>
                                                                        <AntDatePicker
                                                                            value={fields.activityDate ? moment
                                                                                .unix(fields.activityDate.seconds)
                                                                                .format('YYYY-MM-DD') : null}
                                                                            name={'activityDate'}
                                                                            onChange={e => {
                                                                                handleDateChange(e, 'activityDate');
                                                                            }}
                                                                            format='DD/MM/YYYY'
                                                                            placeholder='DD/MM/YYYY'
                                                                            className={`form-control`}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label ><Translate text={'Time'} /></label>
                                                                        <div className='inner-addon right-addon'>
                                                                            <i className='bg-icons ico icon-time'></i>
                                                                            <ReactTimePicker
                                                                                value={fields.activityDate ? moment.unix(fields.activityDate.seconds)._d : null}
                                                                                name={'activityDate'}
                                                                                onChange={handleStartTimeChange}
                                                                                timeIntervals={15}
                                                                                placeholder='h:mm aa'
                                                                                className={`form-control`}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <h3> <Translate text={'Notes'} /></h3>
                                                            <MentionEditor
                                                                mentionsData={mentionsData}
                                                                onMentionChange={onMentionChange}
                                                                onMentionSelect={onMentionSelect}
                                                                notes={fields.comments ? fields.comments : ''}
                                                                focusOnMount={false}
                                                                isNoteEmpty={false}
                                                                teamMentions={teamMentions}
                                                                clearTeamMentions={clearTeamMentions}
                                                                clientUsers={props.clientUsers}
                                                                ownerID={fields.owner ? fields.owner : ''}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                (!_.isEmpty(fields) && fields.modifiedBy && fields.modifiedDate) ? (
                                                                    <div className="lead-stage-layout form-style vehicle-status-alert alert-user mb-1 mt-2">
                                                                        <b className="alert-link"><Translate text={'allocated'} /> :</b>
                                                                        {moment.unix(fields.modifiedDate.seconds).format('DD/MM/YYYY h:mm A')} - {CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), fields.modifiedBy)}

                                                                    </div>
                                                                ) : (<></>)
                                                            }

                                                        </>
                                                }

                                                {/* {
                                                    (localStorage.defaultModule === 'oem' && dealersettings.level === 'oem')
                                                        ?
                                                        <>

                                                            <div className="linked-activities-wrap mt-3">
                                                                <div className="linked-activities-title p-0 pb-2"> <Translate text={'Notes History'} />
                                                                    <div className=" float-right linked-activities-add">
                                                                        <button type="button"
                                                                            className="btn btn-primary float-right"
                                                                            onClick={(e) => { e.preventDefault(); handleNotesOpen(); }}
                                                                        >
                                                                            <i className="ico icon-add"></i> <Translate text={'addNote'} />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    notesLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (<div className="enquiry-flow mt-0 mb-3">
                                                                        {
                                                                            !_.isEmpty(notesHistory) || !_.isEmpty(notesHistoryTIP)
                                                                                ?
                                                                                <div className="enquiry-flow-wrap mt-0 mb-4 border-bottom pb-3">
                                                                                    <div className="flow-timelinebar"></div>

                                                                                    {
                                                                                        notesHistory.map((obj, index) => {
                                                                                            return (
                                                                                                <div key={index} className="enquiry-flow-item note">
                                                                                                    <div className="flow-item  note  ">
                                                                                                        <div className="flow-item-content">
                                                                                                            <div className="flow-text-wrap">
                                                                                                                <div className="flow-text text-area-space note">{obj.notes}
                                                                                                                    <div className="flow-notes-sub mt-1">
                                                                                                                        <span>{obj.addedDate ? moment.unix(obj.addedDate.seconds).format('DD/MM/YYYY h:mm A') : ''}</span>
                                                                                                                        {
                                                                                                                            obj.addedByName
                                                                                                                                ?
                                                                                                                                <>{' - '}{obj.addedByName}</>
                                                                                                                                :
                                                                                                                                <>
                                                                                                                                    {
                                                                                                                                        obj.addedBy
                                                                                                                                            ?
                                                                                                                                            <>{' - '}{CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), obj.addedBy)}</>
                                                                                                                                            :
                                                                                                                                            <></>
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                        }

                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <span className="flow-icon"> <i className="ico icon-note"></i> </span>
                                                                                                </div>
                                                                                            );
                                                                                        })
                                                                                    }

                                                                                    {
                                                                                        notesHistoryTIP.map((obj, index) => {
                                                                                            return (
                                                                                                <div key={index} className="enquiry-flow-item note">
                                                                                                    <div className="flow-item  note  ">
                                                                                                        <div className="flow-item-content">
                                                                                                            <div className="flow-text-wrap">
                                                                                                                <div className="flow-text text-area-space note">{obj.notes}
                                                                                                                    <div className="flow-notes-sub mt-1">
                                                                                                                        <span>{obj.addedDate ? moment.unix(obj.addedDate.seconds).format('DD/MM/YYYY h:mm A') : ''}</span>
                                                                                                                        {
                                                                                                                            obj.addedByName
                                                                                                                                ?
                                                                                                                                <>{' - '}{obj.addedByName}</>
                                                                                                                                :
                                                                                                                                <>
                                                                                                                                    {
                                                                                                                                        obj.addedBy
                                                                                                                                            ?
                                                                                                                                            <>{' - '}{CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), obj.addedBy)}</>
                                                                                                                                            :
                                                                                                                                            <></>
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                        }

                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <span className="flow-icon"> <i className="ico icon-note"></i> </span>
                                                                                                </div>
                                                                                            );
                                                                                        })
                                                                                    }

                                                                                </div>
                                                                                :
                                                                                <>
                                                                                    <div className="text-center p-5">
                                                                                        <p><Translate text={'no notes created'} /> </p>
                                                                                    </div>
                                                                                </>
                                                                        }

                                                                    </div>)
                                                                }
                                                            </div>

                                                        </>
                                                        :
                                                        <>

                                                        </>
                                                } */}

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="mt-5"><ContentOverlay active={true} /></div>
                            </>
                    }

                </Modal.Body>

                <>
                    {
                        fields.status !== 1 && !fields.isDeleted
                            ?
                            <>
                                <Modal.Footer className="modal-footer">

                                    {
                                        !_.isEmpty(rosterMsg) ? (
                                            <div className="activity-pop-info alert-danger mb-3">
                                                <i className="fa fa-exclamation-circle mr-2" aria-hidden="true"></i>{rosterMsg}
                                            </div>
                                        ) : (<></>)
                                    }

                                    {
                                        (localStorage.defaultModule !== 'oem' || (localStorage.defaultModule === 'oem' && !_.isEmpty(fields) && fields.status === 0 && _.isEmpty(fields.clientID)))
                                            ?
                                            <button
                                                type="button"
                                                className={`btn btn-primary float-right ml-2 ${isLoading ? 'form-disable' : ''}`}
                                                onClick={(e) => handleSubmit(e)}
                                            >
                                                {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                <Translate text={'accept'} />
                                            </button>
                                            :
                                            <></>
                                    }


                                    {
                                        (deleteInboundLead || props.dealersettings.superAdmin === true)
                                            ? (
                                                <button
                                                    type='button'
                                                    className='btn btn-red float-left ml-2'
                                                    onClick={() => {
                                                        deleteInbound();
                                                    }}
                                                >
                                                    {deleteLoader ? (
                                                        <span
                                                            className='spinner-border spinner-button mr-1'
                                                            role='status'
                                                            aria-hidden='true'
                                                        ></span>
                                                    ) : (
                                                        <>
                                                            <i className='ico icon-delete marker-delete'></i>
                                                        </>
                                                    )}
                                                    <Translate text={'delete'} />
                                                </button>
                                            ) : (
                                                <></>
                                            )}
                                </Modal.Footer>
                            </>
                            :
                            <>
                                {
                                    fields && fields.tradeinProID && !fields.isDeleted
                                        ?
                                        <>
                                            <Modal.Footer className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary float-right ml-2"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (props.setNotifyClose)
                                                            props.setNotifyClose(true);
                                                        props.setNotifyClose(true)
                                                        props.handleModalClose();
                                                        history.push("/tradeinpro/details/" + fields.tradeinProID);
                                                    }}
                                                >
                                                    {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                    <Translate text={'viewAppraisal'} />
                                                </button>
                                            </Modal.Footer>
                                        </>
                                        :
                                        <></>
                                }

                            </>
                    }
                </>


            </Modal>

            <PopUpModal show={showContactModal}>
                <AddContact
                    docID={null}
                    show={showContactModal}
                    handleClose={handleContactClose}
                    showSearch={false}
                    engageNewContact={contact ? contact : {}}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    isNewEnquiry={true}
                    skipMandatory={true}
                    skipSave={true}
                >
                </AddContact>
            </PopUpModal>
            <PopUpModal show={showContactInfo}>
                <ContactInfo
                    show={showContactInfo}
                    existingContact={existingContactInfo}
                    newContact={contact ? contact : {}}
                    isNewContact={isContactMerged ? false : true}
                    handleClose={handleXContactClose}
                    title={'contactDetails'}
                    dealersettings={props.dealersettings}
                    clientUsers={!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers}
                >
                </ContactInfo>
            </PopUpModal>

            {/* <PopUpModal show={showOEMLeadConvert}>
                <OEMLeadAllocation
                    show={showOEMLeadConvert}
                    handleModalClose={handlOEMLeadConvertClose}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    tradeinProID={fields.tradeinProID}
                    enquiry={fields}
                    returnEnq={true}
                />
            </PopUpModal> */}


            {/* <PopUpModal show={showNotes}>
                <AddNotes
                    show={showNotes}
                    handleClose={handleNotesClose}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                />
            </PopUpModal> */}


        </>
    )
}

export default InBoundLeadConvert;