import React, { useEffect, useState, useContext, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import Translate from '../../constants/translate'
import Swal from 'sweetalert2'
import CommonHelper from '../../services/common'


const PandaDocEditor = (props) => {
    const [loader, setLoader] = useState(false)

    const editorBlock = {
        fieldPlacementOnly: false, // Enable Blocks
        documentId: props.document.id, // Replace with your document ID
        token: props.member_token, // Replace with your bearer token
        workspaceId: props.workspace_id, // Replace with your workspace ID
        organizationId: props.organization_id, // Replace with your organization ID
    }



    const handleSubmit = () => {
        Swal.fire({
            title: CommonHelper.showLocale({ dealersettings: props.dealersettings }, 'Are you sure?'),
            text: CommonHelper.showLocale({ dealersettings: props.dealersettings }, 'Do you want to send signature request?'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale({ dealersettings: props.dealersettings }, 'Yes'),
            cancelButtonText: CommonHelper.showLocale({ dealersettings: props.dealersettings }, 'No')
        }).then(async (result) => {
            if (result?.value) {
                setLoader(true);
                const sendDocument = window.firebase.functions().httpsCallable('pandadoc-sendDocument');
                sendDocument({
                    "api_key": props.apikey,
                    "id": props.document.id,
                    "payload": {
                        "message": props.payload.message,
                        "subject": props.payload.subject,
                        "forwarding_settings": {
                            "forwarding_allowed": false,
                            "forwarding_with_reassigning_allowed": false
                        }
                    }
                }).then(async (resp) => {
                    //console.log(data)
                    if (resp.data.success === true) {
                        props.handleClose();
                    }
                    else {
                        Swal.fire(resp.data.message || 'Something went wrong', '', 'error')
                    }
                });
            }
        })
    }

    const handleClose = () => {
        Swal.fire({
            title: CommonHelper.showLocale({ dealersettings: props.dealersettings }, 'Are you sure?'),
            text: CommonHelper.showLocale({ dealersettings: props.dealersettings }, 'Do you want to close?'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale({ dealersettings: props.dealersettings }, 'Yes'),
            cancelButtonText: CommonHelper.showLocale({ dealersettings: props.dealersettings }, 'No')
        }).then((result) => {
            if (result?.value) {
                props.handleClose();
            }
        });
    }

    useEffect(() => {
        const scripttag = document.createElement("script");
        scripttag.src = "https://static.prod.pandadoc-static.com/prod/es/iframe-editor-api.js";
        scripttag.async = true;

        document.head.appendChild(scripttag);

        const initializeEditor = () => {
            if (window?.PD?.Editor) {
                const editor = new window.PD.Editor(`pd-editor-container-${props.iframeID}`, editorBlock);
                return editor;
            }
        };

        scripttag.onload = () => {
            window.onPandaDocEditorIframeAPIReady = initializeEditor();
        }

        return () => {
            document.head.removeChild(scripttag);
        }


    }, [])

    return <Modal
        show={props.show}
        backdrop="static"
        backdropClassName={'overlay-modal active'}
        dialogClassName="modal-dialog fullscreenmodal-lg add-triggers-fullscreen">
        <div className="modal-content modal-content-fullscreen">
            <Modal.Header className="modal-header-fullscreen">
                <div className="modal-title">  <Translate text={props.title} /></div>
                <div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom'>
                    <button
                        type='button'
                        className={`btn btn-primary float-left ml-2 mr-2 email-send-btn `}
                        onClick={handleSubmit}
                    >
                        {loader ? (
                            <span
                                className='spinner-border spinner-button mr-1'
                                role='status'
                                aria-hidden='true'
                            ></span>
                        ) : (
                            <></>
                        )}
                        <Translate text={'send'} />
                    </button>
                    <button type="button" className="close close-fullscreen float-right" onClick={() => { handleClose() }}>×</button>
                </div>
            </Modal.Header>
            <Modal.Body className="modal-body-fullscreen">
                <div className="popup-wrap-fullscreen pd-editor-container">
                    <main role="main" className="container" id={`pd-editor-container-${props.iframeID}`} style={{ height: '100vh' }}>
                        <div className="spinner-loader h-100">
                            <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                                <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                            </div>
                        </div>
                    </main>
                </div>
            </Modal.Body>
        </div>
    </Modal>
}

export default PandaDocEditor