import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import uuid from 'react-uuid'
import { InputText, ReactSelect, InputCheckBox, ReactMultiSelect } from '../../../components'
import Translate from '../../../constants/translate';
import { lookupSublistVM } from '../../settings/serviceworkflow/lookups/viewModel'
import NumberFormat from 'react-number-format';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CommonHelper from '../../../services/common';

const AddServicePackage = (props) => {
    const [lookup, setLookup] = useState(props.lookup)
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)
    const [subList, setSubList] = useState(!_.isEmpty(props.lookup) && !_.isEmpty(props.lookup.subList) ? props.lookup.subList : []);
    const [addSubList, setAddSubList] = useState({ name: '', value: '' })
    const [editSubList, setEditSubList] = useState()
    const [textEditor, setTexteditor] = useState({})

    const newLookup = !_.isEmpty(props.lookup) ? false : true



    const disableEdit = false
    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    useEffect(() => {
        if (_.isEmpty(props.lookup)) {
            const newlookupVM = Object.assign({}, lookupSublistVM);
            setLookup(newlookupVM)
            setAddSubList([])
        }
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (name === 'value') {
            setLookup({
                ...lookup,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setLookup({
                    ...lookup,
                    [name]: newarray1.join(' '),
                    ['value']: newLookup ? uuid() : lookup.value
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setLookup({
                    ...lookup,
                    [name]: str,
                    ['value']: newLookup ? uuid() : lookup.value
                });
            }
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const onCurrencyChange = (e, name) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  

        setLookup({
            ...lookup,
            [name]: e.floatValue
        });

        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const handleOnSubChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (name === 'value') {
            setAddSubList({
                ...addSubList,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setAddSubList({
                    ...addSubList,
                    [name]: newarray1.join(' '),
                    ['value']: _.isEmpty(textEditor) ? uuid() : addSubList.value
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setAddSubList({
                    ...addSubList,
                    [name]: str,
                    ['value']: _.isEmpty(textEditor) ? uuid() : addSubList.value
                });
            }
        }
        setErrorFields({
            ...errorFields,
            ['sublist' + name]: ''
        });
    }

    const handleOnEditSubChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (name === 'value') {
            setEditSubList({
                ...editSubList,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setEditSubList({
                    ...editSubList,
                    [name]: newarray1.join(' ')
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setEditSubList({
                    ...editSubList,
                    [name]: str
                });
            }
        }
        setErrorFields({
            ...errorFields,
            ['editsublist' + name]: ''
        });
    }

    const saveLookup = (e) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};

        let lookupObjData = { ...lookup }


        if (_.isEmpty(lookupObjData['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }

        if (!_.isNumber(lookupObjData['price'])) {
            formIsValid = false;
            errors['price'] = errorClass;
        }
        if (!lookupObjData['description']) {
            formIsValid = false;
            errors['description'] = errorClass;
        }

        // if (!_.isEmpty(lookupObjData.level) && lookupObjData.level !== 'oem' && _.isEmpty(lookupObjData['levelID'])) {
        //     formIsValid = false;
        //     errors['levelID'] = errorClass;
        // }
        setErrorFields(errors)

        if (!formIsValid) {
            return;
        }
        setLoader(true)

        //Remove empty value from lookupObjData object
        for (var propName in lookupObjData) {
            if (lookupObjData[propName] === null || lookupObjData[propName] === undefined || lookupObjData[propName] === '' || lookupObjData[propName] === [] || (_.isObject(lookupObjData[propName]) && _.isEmpty(lookupObjData[propName]))) {
                delete lookupObjData[propName];
            }
        }

        props.handleClose(lookupObjData, newLookup)

    }

    const handleSublistSave = (rec, newlist) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(rec['name'])) {
            formIsValid = false;
            if (newlist)
                errors['sublistname'] = errorClass;
            else
                errors['editsublistname'] = errorClass;
        }
        if (_.isEmpty(rec['value'])) {
            formIsValid = false;
            if (newlist)
                errors['sublistvalue'] = errorClass;
            else
                errors['editsublistvalue'] = errorClass;
        }
        setErrorFields(errors)
        if (subList.filter(item => item.value !== rec.value).some(item => item.name === rec.name)) {
            Swal.fire(CommonHelper.showLocale(props, 'Package with same name already exists'), '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        let addList = newlist ? [...subList, rec] : [
            ..._.map(subList, function (obj) {
                return obj.value === rec.value ? { ...rec } : { ...obj };
            })];
        setSubList(addList)
        setLookup({
            ...lookup,
            ['subList']: addList
        });
        setAddSubList({ name: '', value: '' })
        setEditSubList(null)
        setTexteditor({})
    }

    const handleCancelSubList = () => {
        setEditSubList(null)
        setTexteditor({})
    }

    const handleEditSubList = (rec) => {
        setEditSubList(rec)
        setTexteditor({
            [rec.value]: true
        })
    }
    const handleDeleteSubList = (rec) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                let deleteList = [...subList.filter(item => item.value !== rec.value)];
                setSubList(deleteList)
                setLookup({
                    ...lookup,
                    ['subList']: deleteList
                });
            }
        })
    }

    const onDragEnd = (result) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        const [removed] = subList.splice(result.source.index, 1);
        subList.splice(result.destination.index, 0, removed);
        setSubList(subList)
        setLookup({
            ...lookup,
            ['subList']: subList
        });
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging && ("#e6eaf2"),
        ...draggableStyle,
    })

    return _.isEmpty(lookup) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-lost-reasons"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title> <Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">

                                <div className="form-group col-md-12">
                                    <label > <Translate text={'name'} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'name'}
                                        className={`form-control ${errorFields["name"]}`}
                                        name="name"
                                        onChange={handleOnChange}
                                        value={lookup.name}
                                        disabled={disableEdit}
                                        maxLength={500}
                                    />
                                </div>


                                <div className="form-group col-md-12">
                                    <label> <Translate text={'price'} /></label>
                                    <NumberFormat
                                        id={'price'}
                                        decimalScale={2}
                                        allowNegative={false}
                                        thousandSeparator={true}
                                        prefix={currencySymbol}
                                        placeholder={currencySymbol}
                                        className={`form-control ${errorFields["price"]}`}
                                        onValueChange={(e) => {
                                            onCurrencyChange(e, 'price')
                                        }}
                                        value={lookup.price}
                                    />
                                </div>


                                <div className="form-group col-md-12">
                                    <label > <Translate text={'Estimated Time'} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'estimated time ( 1 - 2 hours )'}
                                        className={`form-control ${errorFields["description"]}`}
                                        name="description"
                                        onChange={handleOnChange}
                                        value={lookup.description}
                                        maxLength={500}
                                    // disabled={disableEdit}
                                    />
                                </div>

                            </div>

                            <div className="settings-sub-divider">
                                <h3> <Translate text={'Service Package Includes'} /></h3>
                            </div>
                            <DragDropContext onDragEnd={onDragEnd}>

                                <div className="common-table">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="head-light" width="45%"> <Translate text={'name'} /></th>
                                                {/* <th scope="col" className="head-light" width="45%">Value</th> */}
                                                <th scope="col" className="head-light border-right-0" width="10%"> <Translate text={'Actions'} /></th>
                                            </tr>
                                            {
                                                disableEdit ? (<></>) : (
                                                    <tr>
                                                        <td><div className="table-input">
                                                            <InputText
                                                                id="sublistname"
                                                                autoComplete="off"
                                                                placeholder={'name'}
                                                                className={`form-control ${errorFields["sublistname"]}`}
                                                                name="name"
                                                                onChange={handleOnSubChange}
                                                                value={addSubList.name}
                                                                disabled={disableEdit}
                                                                maxLength={500}
                                                            />
                                                        </div></td>
                                                        <td className="border-right-0">
                                                            <div className="button-height-fix">
                                                                <button type="button" className="btn btn-primary" onClick={() => { handleSublistSave(addSubList, true) }}> <Translate text={'add'} /></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </thead>
                                        <Droppable droppableId="droppableLookupExt">
                                            {(provided, snapshot) => (
                                                <tbody
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {
                                                        subList.length > 0 ?
                                                            subList.map((rec, index) => {
                                                                return <Draggable key={rec.value} draggableId={rec.value} index={index}>
                                                                    {(provided1, snapshot1) => (
                                                                        <tr
                                                                            ref={provided1.innerRef}
                                                                            {...provided1.draggableProps}
                                                                            {...provided1.dragHandleProps}
                                                                            style={getItemStyle(
                                                                                snapshot1.isDragging,
                                                                                provided1.draggableProps.style
                                                                            )}
                                                                            onDoubleClick={(e) => { e.preventDefault(); handleEditSubList(rec) }}>
                                                                            <td style={{ width: '335px' }}>
                                                                                {
                                                                                    textEditor[rec.value] ? (<><InputText
                                                                                        id="editsublistname"
                                                                                        autoComplete="off"
                                                                                        placeholder={'name'}
                                                                                        className={`form-control ${errorFields["editsublistname"]}`}
                                                                                        name="name"
                                                                                        onChange={handleOnEditSubChange}
                                                                                        value={editSubList.name}
                                                                                        maxLength={500}
                                                                                    /></>) : (<>{rec.name}</>)
                                                                                }
                                                                            </td>
                                                                            {/* <td>{
                                                                textEditor ? (<><InputText
                                                                id="editsublistvalue"
                                                                    autoComplete="off"
                                                                    placeholder={'value'}
                                                                    className={`form-control ${errorFields["editsublistvalue"]}`}
                                                                    name="value"
                                                                    onChange={handleOnEditSubChange}
                                                                    value={editSubList.value}
                                                                    disabled={true}
                                                                /></>) : (<>{rec.value}</>)
                                                            }</td> */}
                                                                            <td className="border-right-0">
                                                                                <div className="action-fix">
                                                                                    {
                                                                                        textEditor[rec.value] ?
                                                                                            (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleSublistSave(editSubList, false) }}> <i className="ico icon-check"></i></a></div>
                                                                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleCancelSubList(rec) }}> <i className="ico icon-remove"></i></a></div>
                                                                                            </>) :
                                                                                            (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditSubList(rec) }}> <i className="ico icon-edit"></i></a></div>
                                                                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteSubList(rec) }}> <i className="ico icon-delete"></i></a></div>
                                                                                            </>)
                                                                                    }

                                                                                </div>
                                                                                <div className="table-edit float-right"> <i className="ico icon-drag"></i></div>
                                                                            </td>
                                                                            {provided1.placeholder}
                                                                        </tr>
                                                                    )}
                                                                </Draggable>
                                                            })
                                                            : (<></>)
                                                    }
                                                    {provided.placeholder}
                                                </tbody>
                                            )}
                                        </Droppable>
                                    </table>
                                </div>

                            </DragDropContext>

                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">

                <button type="button" disabled={!_.isEmpty(textEditor) || !_.isEmpty(addSubList.name) ? true : false} className={`btn btn-primary float-right ml-2 ${!_.isEmpty(textEditor) || !_.isEmpty(addSubList.name) ? 'btn-disable' : ''}`}
                    onClick={(e) => saveLookup(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal >
    );
}

export default AddServicePackage;