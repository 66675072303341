/** LIBRARIES */
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap'
import _ from 'lodash';
/** PROVIDERS */
import { PipelineProvider, PipelineConsumer } from './PipelineProvider';
import { LayoutConsumer } from '../../layout/provider';
import { RouteConsumer } from '../../layout/RouteProvider';
import { StageConsumer } from '../provider';
/** COMPONENTS */
import { ContentOverlay, DropDownMenu } from '../../../components'
import { CustomMenu, CustomToggle } from '../../../components/customdropdown';
import Translate from '../../../constants/translate';
//import { default as _images } from "../../../images";
import QuickInfo from './quickInfo'
import QuickTab from './quickTab'
import ExpandView from './expandView'
import ChatSidePanel from '../../chat/chatsidepanel';
import CommonHelper from '../../../services/common';
import { enquiryStatus } from '../../../services/enum';

class QuickView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSharePanel: {
                chatClsActive: '',
                chatClsName: 'sidebar',
            }
        }
    }

    handleSharePanelOpen = (e) => {
        e.preventDefault();
        localStorage.removeItem('showChat');
        this.setState({
            showSharePanel: {
                chatClsActive: 'active',
                chatClsName: 'sidebar',
            },
        });
    };

    handleSharePanelClose = () => {
        //console.log('handleSharePanelClose')
        this.setState({
            showSharePanel: {
                chatClsActive: '',
                chatClsName: 'sidebar',
            },
        });
    };

    render() {
        //console.log('RE RENDER QUICK VIEW')
        const { isReadOnlyView, isHideExpandView, isFromLayout, parentClose } = this.props;
        return (
            <LayoutConsumer>
                {({ dealersettings, clientUsers, groupUsers, clientModule, handleClientChange, handleModuleChange, dialNumber, updateDealerSettings }) => (
                    <StageConsumer>
                        {({ updateEnquiry, handleOEMLeadConvertShow }) => (
                            <RouteConsumer>
                                {({ handleRouteChange, updatenavbar, handleRouteRemove }) => (
                                    <PipelineProvider
                                        enquiryid={this.props.enquiryid}
                                        updateEnquiry={updateEnquiry}
                                        updateListEnquiry={this.props.updateListEnquiry}
                                        dealersettings={dealersettings}
                                        clientUsers={clientUsers}
                                        groupUsers={groupUsers}
                                        updatenavbar={updatenavbar}
                                        isFilterApplied={this.props.isFilterApplied}
                                        handleRouteChange={handleRouteChange}
                                        history={this.props.history}
                                        sidepanelClose={this.props.sidepanelClose}
                                        handleRouteRemove={handleRouteRemove}
                                        reloadData={this.props.reloadData}
                                        isReadOnlyView={isReadOnlyView}
                                        updateDealerSettings={updateDealerSettings}
                                    >
                                        <PipelineConsumer>
                                            {({ enquiry, contact, isEmptyPipeline, updateStatus, updateEnquiryInfo, leadOwner,
                                                loading, handleDeleteEnquiry, canModifyEnquiry, isStatusSold, reopenEnquiry, clientsettings,
                                                titles, handleLostApprovalOpen, handleVerifyEnqiry, lostApproval, verifyEnquiry,
                                                leadVerifyEnabled, lostApprovalEnabled, handleNewLeadOpen, createLeadOnLost,
                                                handleOpenMerge, handleLeadConvertShow, isValidForStageUpdate, amsMeEnabled,
                                                enableProspectLost, isStatusOpen, handleEnquiryProspectLost, liteVersion,
                                                isAmsMeContact, handleDynamicStatus, objCurrentStatus, dynaicStatusMenu }) => loading || _.isEmpty(enquiry?.displayID) ? (<ContentOverlay active={true} />) : (
                                                    <>

                                                        <div className="quickview-header">
                                                            <div className="float-right">
                                                                {
                                                                    (enquiry.status)
                                                                        ?
                                                                        <div className="current-status-quickview mt-1 mr-2">
                                                                            {
                                                                                objCurrentStatus
                                                                                    ?
                                                                                    <>
                                                                                        <Translate text={'currentStatus'} />
                                                                                        <div className={`badge badge-pill badge-${enquiry.status === 'open' ? 'open' : 'empty'} ml-2`}
                                                                                            style={{ background: (objCurrentStatus && objCurrentStatus.color ? objCurrentStatus.color : '#333') }}>
                                                                                            {
                                                                                                !_.isEmpty(dynaicStatusMenu)
                                                                                                    ?
                                                                                                    <DropDownMenu
                                                                                                        text={objCurrentStatus ? objCurrentStatus.name : enquiry.status}
                                                                                                        keyVal="100878"
                                                                                                        tIndex="-1"
                                                                                                        menu={dynaicStatusMenu}
                                                                                                        handleChange={handleDynamicStatus}
                                                                                                    >
                                                                                                    </DropDownMenu>
                                                                                                    :
                                                                                                    <Translate
                                                                                                        text={objCurrentStatus ? objCurrentStatus.name : enquiry.status.toLowerCase()}
                                                                                                        upperCase={true}
                                                                                                    />
                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <> </>
                                                                            }

                                                                            {
                                                                                (enquiry.status === enquiryStatus.PENDINGLOST && enquiry.lastStatus && !lostApproval && lostApprovalEnabled)
                                                                                    ?
                                                                                    <>
                                                                                        <div
                                                                                            className={`badge badge-pill badge-pendinglost ml-2`}
                                                                                        >
                                                                                            <Translate
                                                                                                text={'pending approval'}
                                                                                                upperCase={true}
                                                                                            />
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                    </>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <>
                                                                            {(enquiry.pipeline && enquiry.pipeline !== 'LeadsBucket') && (<div className='current-status-quickview mt-1 mr-2'>
                                                                                <div className={`badge badge-pill badge-empty`} style={{ color: '#333' }}>
                                                                                    <DropDownMenu
                                                                                        text={'Select status'}
                                                                                        keyVal="10045341"
                                                                                        tIndex="-1"
                                                                                        menu={dynaicStatusMenu}
                                                                                        handleChange={handleDynamicStatus}
                                                                                    >
                                                                                    </DropDownMenu>
                                                                                </div>
                                                                            </div>)}
                                                                        </>

                                                                }
                                                                {
                                                                    canModifyEnquiry
                                                                        ?
                                                                        <>
                                                                            {
                                                                                enquiry.pipeline === 'LeadsBucket' ? (<>
                                                                                    <div className="quickview-btn-wrap">
                                                                                        {
                                                                                            enableProspectLost && !enquiry.isNewEnquiry
                                                                                                ?
                                                                                                <>
                                                                                                    {(() => {
                                                                                                        if (_.isEmpty(enquiry.status) || isStatusOpen || enquiry.status === 'reopen') {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <a href="#" className="btn btn-common button-red" onClick={(e) => {
                                                                                                                        e.preventDefault();
                                                                                                                        handleEnquiryProspectLost();
                                                                                                                    }}>  <Translate text={'prospectLost'} upperCase={true} /></a>
                                                                                                                </>
                                                                                                            )
                                                                                                        }
                                                                                                        else if ((enquiry.status === 'prospectLost')) {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <a href="#" className="btn btn-common button-reopen" onClick={(e) => {
                                                                                                                        e.preventDefault();
                                                                                                                        updateEnquiryInfo('status', 'open')
                                                                                                                    }}> <Translate text={'reopen'} upperCase={true} /></a>
                                                                                                                </>
                                                                                                            )
                                                                                                        }
                                                                                                    })()}

                                                                                                </>
                                                                                                :
                                                                                                <></>
                                                                                        }

                                                                                        {
                                                                                            (enquiry.status === enquiryStatus.PENDINGLOST && enquiry.lastStatus && lostApproval && lostApprovalEnabled)
                                                                                                ?
                                                                                                <>
                                                                                                    <a href="#" className="btn btn-common button-grey " onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        handleLostApprovalOpen(e);
                                                                                                    }}>LOST APPROVAL</a>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                </>
                                                                                        }

                                                                                        {
                                                                                            (_.isEmpty(enquiry.status) || enquiry.status !== enquiryStatus.PROSPECTLOST)
                                                                                                ?
                                                                                                <>
                                                                                                    {
                                                                                                        (clientModule === 'oem' && _.isEmpty(enquiry.clientID) && (dealersettings.level === 'oem' || dealersettings.level === 'region' || dealersettings.level === 'group'))
                                                                                                            ?
                                                                                                            <>
                                                                                                                {
                                                                                                                    !_.isEmpty(enquiry.contact) && enquiry.oemStatus === 'pending'
                                                                                                                        ?
                                                                                                                        <a href="#" className="btn btn-common button-blue" onClick={(e) => {
                                                                                                                            e.preventDefault();
                                                                                                                            handleOEMLeadConvertShow(this.props.enquiryid, enquiry)
                                                                                                                        }}> <Translate text={'allocate'} upperCase={true} /> </a>
                                                                                                                        :
                                                                                                                        <></>
                                                                                                                }

                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                {
                                                                                                                    localStorage.defaultModule !== 'oem'
                                                                                                                        ?
                                                                                                                        <a href="#" className="btn btn-common button-blue" onClick={(e) => {
                                                                                                                            e.preventDefault();
                                                                                                                            handleLeadConvertShow(this.props.enquiryid, enquiry)
                                                                                                                        }}> <Translate text={'Convert'} upperCase={true} /> </a>
                                                                                                                        :
                                                                                                                        <></>
                                                                                                                }

                                                                                                            </>

                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                <></>
                                                                                        }

                                                                                    </div>
                                                                                </>)
                                                                                    : (
                                                                                        <>
                                                                                            {
                                                                                                (enquiry && enquiry.pipeline !== 'LeadsBucket' && dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.pipelines.length > 0 &&
                                                                                                    dealersettings.client.settings.pipelines.filter(item => item.value !== 'LeadsBucket').length === 0)
                                                                                                    ?
                                                                                                    <></>
                                                                                                    :
                                                                                                    <>
                                                                                                        <div className="quickview-btn-wrap">

                                                                                                            {(() => {
                                                                                                                if (isStatusSold || enquiry.status === enquiryStatus.WON || enquiry.status === enquiryStatus.CLOSED) {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                reopenEnquiry
                                                                                                                                    ?
                                                                                                                                    <>
                                                                                                                                        <a href="#" className="btn btn-common button-reopen" onClick={(e) => {
                                                                                                                                            e.preventDefault();
                                                                                                                                            updateStatus('status', 'open', true)
                                                                                                                                        }}> <Translate text={'reopen'} upperCase={true} /></a>
                                                                                                                                    </>
                                                                                                                                    :
                                                                                                                                    <></>
                                                                                                                            }
                                                                                                                        </>
                                                                                                                    )
                                                                                                                }
                                                                                                                else if ((enquiry.status === enquiryStatus.LOST || enquiry.status === enquiryStatus.PENDINGLOST || enquiry.status === enquiryStatus.DELIEVERD) && reopenEnquiry) {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <a href="#" className="btn btn-common button-reopen" onClick={(e) => {
                                                                                                                                e.preventDefault();
                                                                                                                                updateStatus('status', 'open')
                                                                                                                            }}> <Translate text={'reopen'} upperCase={true} /></a>
                                                                                                                        </>
                                                                                                                    )
                                                                                                                }
                                                                                                            })()}

                                                                                                        </div>


                                                                                                    </>
                                                                                            }
                                                                                            {
                                                                                                enquiry.isAfterSales
                                                                                                    ?
                                                                                                    <></>
                                                                                                    :
                                                                                                    <>
                                                                                                        <div className="btn-divider">
                                                                                                            {
                                                                                                                (enquiry.status === enquiryStatus.PENDINGLOST && enquiry.lastStatus && lostApproval && lostApprovalEnabled)
                                                                                                                    ?
                                                                                                                    <>
                                                                                                                        <a href="#" className="btn btn-common button-grey " onClick={(e) => {
                                                                                                                            e.preventDefault();
                                                                                                                            handleLostApprovalOpen(e);
                                                                                                                        }}>LOST APPROVAL</a>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                    </>
                                                                                                            }

                                                                                                            {
                                                                                                                (leadVerifyEnabled && verifyEnquiry && !enquiry.verifiedBy)
                                                                                                                    ?
                                                                                                                    <>
                                                                                                                        <a href="#" className="btn btn-common button-blue " onClick={(e) => {
                                                                                                                            e.preventDefault();
                                                                                                                            handleVerifyEnqiry();
                                                                                                                        }}> VERIFY</a>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                    </>
                                                                                                            }
                                                                                                            {
                                                                                                                (createLeadOnLost && enquiry.status === 'lost' && _.isEmpty(enquiry.lostRefID))
                                                                                                                    ?
                                                                                                                    <>
                                                                                                                        <a href="#" className="btn btn-common button-blue " onClick={(e) => {
                                                                                                                            e.preventDefault();
                                                                                                                            handleNewLeadOpen();
                                                                                                                        }}><i className="ico icon-enquiry mr-2" aria-hidden="true"></i> NEW LEAD</a>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                    </>
                                                                                                            }

                                                                                                        </div>
                                                                                                    </>
                                                                                            }

                                                                                        </>
                                                                                    )
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                        </>
                                                                }


                                                                <OverlayTrigger
                                                                    placement='bottom'
                                                                    overlay={
                                                                        <Tooltip>{leadOwner ? <>{CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), leadOwner)}</> : '--'}</Tooltip>
                                                                    }
                                                                >
                                                                    <div className="quick-view-avatar">
                                                                        <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                            <img src={CommonHelper.getUserImagebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, leadOwner)} alt="" className="img-object-fit" />
                                                                        </a>
                                                                    </div>
                                                                </OverlayTrigger>


                                                                {
                                                                    isHideExpandView || (enquiry && enquiry.pipeline !== 'LeadsBucket' && clientsettings && clientsettings.client && clientsettings.client.settings && clientsettings.client.settings.pipelines.length > 0 &&
                                                                        clientsettings.client.settings.pipelines.filter(item => item.value !== 'LeadsBucket').length === 0)
                                                                        ?
                                                                        <></>
                                                                        :
                                                                        <>
                                                                            <OverlayTrigger
                                                                                placement='bottom'
                                                                                overlay={
                                                                                    <Tooltip>{'Expand View'}</Tooltip>
                                                                                }
                                                                            >
                                                                                <ExpandView
                                                                                    enquiry={enquiry}
                                                                                    enquiryid={this.props.enquiryid}
                                                                                    history={this.props.history}
                                                                                    target={this.props.target}
                                                                                    isReadOnlyView={isReadOnlyView}
                                                                                    handleModuleChange={handleModuleChange}
                                                                                    handleRouteChange={handleRouteChange}
                                                                                    handleClientChange={handleClientChange}
                                                                                    dealersettings={clientsettings}
                                                                                    isFromLayout={isFromLayout}
                                                                                    layoutClose={parentClose}
                                                                                >
                                                                                </ExpandView>
                                                                            </OverlayTrigger>
                                                                        </>
                                                                }

                                                                <Dropdown className='quick-view-more'>
                                                                    <Dropdown.Toggle
                                                                        as={CustomToggle}
                                                                    //className='common-button'
                                                                    >
                                                                        <i className='ico icon-more'></i>
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu
                                                                        as={CustomMenu}
                                                                        ChildClass="more-dropdown"
                                                                        xplacement="bottom-end"
                                                                    >

                                                                        {
                                                                            enquiry.clientID
                                                                                ?
                                                                                <Dropdown.Item eventKey="4" onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    const url = `/enquiry/preview/${this.props.enquiryid}?isView=y`;
                                                                                    window.open(url, '_blank');
                                                                                }}><i className="ico icon-waiver"></i>Preview</Dropdown.Item>
                                                                                :
                                                                                <></>
                                                                        }



                                                                        <Dropdown.Item eventKey="1" onClick={(e) => { this.handleSharePanelOpen(e) }}><i className="ico icon-share"></i>  <Translate text={'Share'} /></Dropdown.Item>
                                                                        {
                                                                            (!_.isEmpty(dealersettings) &&
                                                                                !_.isEmpty(dealersettings.rolePermissions) &&
                                                                                !_.isEmpty(dealersettings.rolePermissions.permissions) &&
                                                                                dealersettings.rolePermissions.permissions.mergeContact) && canModifyEnquiry && enquiry.isContact
                                                                                ?
                                                                                <Dropdown.Item eventKey="3" onClick={(e) => { handleOpenMerge(e) }}><i className="ico icon-merge"></i>  Merge</Dropdown.Item>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {
                                                                            (!_.isEmpty(dealersettings.rolePermissions) &&
                                                                                !_.isEmpty(dealersettings.rolePermissions.permissions) &&
                                                                                dealersettings.rolePermissions.permissions.deleteEnquiry) && canModifyEnquiry
                                                                                ?
                                                                                <Dropdown.Item eventKey="2" onClick={(e) => { handleDeleteEnquiry(e) }}><i className="ico icon-delete"></i>  <Translate text={'delete'} /></Dropdown.Item>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </Dropdown.Menu>
                                                                </Dropdown>

                                                            </div>

                                                            <QuickInfo
                                                                enquiry={enquiry}
                                                                contact={contact}
                                                                updateStatus={updateStatus}
                                                                history={this.props.history}
                                                                titles={titles}
                                                                clientUsers={clientUsers}
                                                                groupUsers={groupUsers}
                                                                dealersettings={clientsettings}
                                                                clientsettings={dealersettings}
                                                                canModifyEnquiry={canModifyEnquiry}
                                                                clientModule={clientModule}
                                                                amsMeEnabled={amsMeEnabled}
                                                                isAmsMeContact={isAmsMeContact}
                                                                dialNumber={dialNumber}
                                                                sidepanelClose={this.props.sidepanelClose}
                                                            >

                                                            </QuickInfo>
                                                        </div>
                                                        <QuickTab
                                                            enquiry={enquiry}
                                                            contact={contact}
                                                            isEmptyPipeline={isEmptyPipeline}
                                                            history={this.props.history}
                                                            clientModule={clientModule}
                                                            isValidForActivity={isValidForStageUpdate}
                                                            updateEnquiry={updateEnquiry}
                                                            updateListEnquiry={this.props.updateListEnquiry}
                                                            isFilterApplied={this.props.isFilterApplied}
                                                            dialNumber={dialNumber}
                                                            sidepanelClose={this.props.sidepanelClose}
                                                        ></QuickTab>


                                                        <ChatSidePanel
                                                            sidepanelClose={this.handleSharePanelClose}
                                                            clsActive={this.state.showSharePanel.chatClsActive}
                                                            clsName={this.state.showSharePanel.chatClsName}
                                                            title='selectUser'
                                                            sharePanel={true}
                                                            sendAs='enquiry'
                                                            enquiry={enquiry}
                                                        />
                                                    </>
                                                )}
                                        </PipelineConsumer>
                                    </PipelineProvider>
                                )}
                            </RouteConsumer>
                        )}
                    </StageConsumer>
                )}
            </LayoutConsumer>
        );
    }
}

export default withRouter(QuickView);