import React from 'react'
export const templateVM = {
    documentID: null,
    clientID: null,
    clientIDs: null,
    projectId: null,
    title: null,
    bodyHTML: null,
    addedBy: '',
    modifiedBy: '',
    addedDate: null,
    modifiedDate: null,
    modifiedFrom: 'web',
    addedFrom: 'web',
    isDeleted: false,
    visibility: null,
    category: null,
    templateJSON: '',
    level: '',
    levelID: null,
};


export const allTemplatesFields = [
    {
        name: 'Title',
        value: 'title',
        flex: 1,
        default: true
    },
    {
        name: 'Category',
        value: 'strCategoty',
        flex: 1,
        default: true
    },
    {
        name: 'Email',
        value: 'emailContent',
        flex: 2,
        default: true
    },
    {
        name: 'Created On',
        value: 'createdOn',
        subText: 'createdBy',
        flex: 1,
        default: true
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        subText: 'updatedBy',
        flex: 1,
        default: true
    },
    {
        name: 'Level',
        value: 'levelName',
        width: 80,
        flex: 0,
        default: true
    },
    {
        name: 'Region/Subregion/Group/Client',
        value: 'dealersDOM',
        flex: 2,
        default: true
    },
    {
        name: 'Visibility',
        value: 'visibilityDOM',
        width: 80,
        flex: 0,
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 200,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const mentions = [{
    name: 'First Name',
    value: '@FIRST_NAME'
},
{
    name: 'Last Name',
    value: '@LAST_NAME'
},
{
    name: 'Full Name',
    value: '@FULL_NAME'
},
{
    name: 'Customer Email',
    value: '@CUSTOMER_EMAIL'
},
{
    name: 'Customer Phone',
    value: '@CUSTOMER_PHONE'
},
{
    name: 'Dealer Name',
    value: '@DEALER_NAME'
},
{
    name: 'Dealer Phone',
    value: '@DEALER_PHONE'
},
{
    name: 'Dealer Email',
    value: '@DEALER_EMAIL'
},
{
    name: 'Dealer Address',
    value: '@DEALER_ADDRESS'
},
{
    name: 'Facebook URL',
    value: '@FACEBOOK_URL'
},
{
    name: 'X URL',
    value: '@X_URL'
},
{
    name: 'Instagram URL',
    value: '@INSTAGRAM_URL'
},
{
    name: 'YouTube URL',
    value: '@YOUTUBE_URL'
},
{
    name: 'Unsubscribe URL',
    value: '@UNSUBSCRIBE_URL'
},
{
    name: 'Event Start Date',
    value: '@EVENT_START_DATE'
},
{
    name: 'Event Start Time',
    value: '@EVENT_START_TIME'
},
{
    name: 'Event End Date',
    value: '@EVENT_END_DATE'
},
{
    name: 'Event End Time',
    value: '@EVENT_END_TIME'
},
{
    name: 'Event Title',
    value: '@EVENT_TITLE'
},
{
    name: 'Event Location',
    value: '@EVENT_LOCATION'
},
{
    name: 'Event Slot',
    value: '@EVENT_SLOT'
},
{
    name: 'Dietary',
    value: '@DIETARY'
}]

export const specialLinks = [{
	type: 'Confirmation',
	label: 'Link.Confirmation',
	link: '@LINK_CONFIRMATION'
},{
	type: 'Accepted',
	label: 'Link.Accepted',
	link: '@LINK_ACCEPTED'
},
{
	type: 'Tentative',
	label: 'Link.Tentative',
	link: '@LINK_TENTATIVE'
},
{
	type: 'Declined',
	label: 'Link.Declined',
	link: '@LINK_DECLINED'
},
{
	type: 'Contact Form',
	label: 'Link.ContactForm',
	link: '@LINK_CONTACT_FORM'
},
{
	type: 'Facebook URL',
	label: 'Link.Facebook',
	link: '@FACEBOOK_URL'
},
{
	type: 'X URL',
	label: 'Link.X',
	link: '@X_URL'
},
{
	type: 'Instagram URL',
	label: 'Link.Instagram',
	link: '@INSTAGRAM_URL'
},
{
	type: 'Youtube URL',
	label: 'Link.Youtube',
	link: '@YOUTUBE_URL'
},
{
    type: 'Unsubscribe URL',
    label: 'Link.Unsubscribe',
    link: '@UNSUBSCRIBE_URL'
}];

export const visibilities = [
    { active: true, value: 'private', label: <div className="badge badge-pill badge-private">{'Private'}</div> },
    { active: true, value: 'shared', label: <div className="badge badge-pill badge-shared">{'Shared'}</div> }
]

export const levelOptions = [
    { value: 'oem', label: 'OEM', index: 1 },
    { value: 'region', label: 'Region', index: 2 },
    { value: 'subregion', label: 'Sub Region', index: 3 },
    { value: 'group', label: 'Group', index: 4 },
    { value: 'individual', label: 'Individual', index: 5 }
]