import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import uuid from 'react-uuid'
import NumberFormat from 'react-number-format';
import { InputText } from '../../../../components'
import Translate from '../../../../constants/translate';
import { pipelineVM } from './viewModel'

const AddStage = (props) => {
    const [stage, setStage] = useState(props.stage);
    const [errorFields, setErrorFields] = useState({});
    const newStage = !_.isEmpty(props.stage) ? false : true
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (_.isEmpty(props.stage)) {
            setStage({ name: '', value: '', rottenDays: 0 })
        }
    }, [])


    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setStage({
                ...stage,
                [name]: newarray1.join(' '),
                ['value']: newStage ? uuid() : stage.value
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setStage({
                ...stage,
                [name]: str,
                ['value']: newStage ? uuid() : stage.value
            });
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const handleNumberChange = (e, name) => {
        setStage({
            ...stage,
            [name]: e.floatValue > 0 ? e.floatValue : 0
        });
    };

    const handleNotesChanges = (e) => {
        e.preventDefault();

        let { name, value } = e.target;
        if (value)
            value = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });

        setStage({
            ...stage,
            [name]: value
        });
    }

    const saveStage = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(stage['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        setErrorFields(errors)
        if (newStage && props.pipeline.stages.some(item => item.name.toLowerCase() === stage.name.toLowerCase())) {
            Swal.fire('Stage with same name already exists', '', 'error')
            formIsValid = false;
        }

        if (!formIsValid) {
            return;
        }

        setLoader(true)
        const batch = window.firebase.firestore().batch();
        if (props.pipeline.level === 'oem') {
            const pipelineObj = {
                'pipelines': [..._.map(props.allPipelines.filter(item => item.level === props.pipeline.level), function (obj) {
                    return obj.value === props.pipeline.value ? {
                        ..._.pick(props.pipeline, _.keys(pipelineVM)),
                        stages: newStage ? [...props.pipeline.stages, stage] : [..._.map(props.pipeline.stages, function (obj) {
                            return obj.value === stage.value ? { ...stage } : { ...obj };
                        })],
                    } : { ..._.pick(obj, _.keys(pipelineVM)) };
                })]
            }
            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}`),
                pipelineObj, { merge: true });
        }
        else if (_.isArray(props.pipeline.levelID)) {
            props.pipeline.levelID.forEach(rec => {
                const pipelineObj = {
                    'pipelines': [..._.map(props.allPipelines.filter(item => item.level === props.pipeline.level && item.levelID === rec), function (obj) {
                        return obj.value === props.pipeline.value ? {
                            ..._.pick(props.pipeline, _.keys(pipelineVM)),
                            stages: newStage ? [...props.pipeline.stages, stage] : [..._.map(props.pipeline.stages, function (obj) {
                                return obj.value === stage.value ? { ...stage } : { ...obj };
                            })],
                        } : { ..._.pick(obj, _.keys(pipelineVM)) };
                    })]
                }
                batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${props.pipeline.level === 'group' ? 'groups' : props.pipeline.level === 'region' ? 'regions' : 'clients'}/${rec}`),
                    pipelineObj, { merge: true });
            })
        }
        else {
            const pipelineObj = {
                'pipelines': [..._.map(props.allPipelines.filter(item => item.level === props.pipeline.level && item.levelID === props.pipeline.levelID), function (obj) {
                    return obj.value === props.pipeline.value ? {
                        ..._.pick(props.pipeline, _.keys(pipelineVM)),
                        stages: newStage ? [...props.pipeline.stages, stage] : [..._.map(props.pipeline.stages, function (obj) {
                            return obj.value === stage.value ? { ...stage } : { ...obj };
                        })],
                    } : { ..._.pick(obj, _.keys(pipelineVM)) };
                })]
            }
            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${props.pipeline.level === 'group' ? 'groups' : props.pipeline.level === 'region' ? 'regions' : 'clients'}/${props.pipeline.levelID}`),
                pipelineObj, { merge: true });
        }


        batch.commit()
            .then(snapshot => {
                setLoader(false)
                //update settings date and priority
                if (!_.isEmpty(props.selectedClient)) {
                    window.firebase.firestore().doc(`clients/${props.selectedClient.documentID}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: 'Pipeline Settings has been updated',
                        }, { merge: true })
                }
                if (localStorage.defaultModule === 'oem' && props.dealersettings.oemID) {
                    window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: 'Pipeline Settings has been updated',
                        }, { merge: true })
                }
                //end
                toast.notify('Stage ' + (newStage ? 'added' : 'updated') + ' successfully', {
                    duration: 2000
                })
                props.handleClose(props.pipeline)
            })
            .catch(error => {
                setLoader(false)
                console.log(error);
                Swal.fire('Something went wrong', '', 'error')
            });
        // console.log([..._.map(props.allPipelines.filter(item => item.levelID === props.pipeline.levelID), function (obj) {
        //     return obj.value === props.pipeline.value ? {
        //         ..._.pick(props.pipeline, _.keys(pipelineVM)),
        //         stages: newStage ? [...props.pipeline.stages, stage] : [..._.map(props.pipeline.stages, function (obj) {
        //             return obj.value === stage.value ? { ...stage } : { ...obj };
        //         })]
        //     } : { ..._.pick(obj, _.keys(pipelineVM)) };
        // })])

        // props.handleClose({
        //     ...props.pipeline,
        //     stages: newStage ? [...props.pipeline.stages, stage] : [..._.map(props.pipeline.stages, function (obj) {
        //         return obj.value === stage.value ? { ...stage } : { ...obj };
        //     })]
        // })
    }

    return _.isEmpty(stage) ? (<></>) : (<Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-small-popup"
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>  <Translate text={props.title} /></Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
            <div className="container-fluid">
                <div className="row">
                    <div className="form-style w-100 pipeline-form-style">
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label >  <Translate text={'Stage Name'} /> </label>
                                <InputText
                                    autoComplete="off"
                                    placeholder={'name'}
                                    className={`form-control ${errorFields["name"]}`}
                                    name="name"
                                    onChange={handleOnChange}
                                    value={stage.name}
                                />
                            </div>
                            <div className="form-group col-md-12">
                                <label >  <Translate text={'Unattended Leads'} /> </label>
                                <NumberFormat
                                    thousandSeparator={false}
                                    placeholder={'unattended days'}
                                    className={`form-control ${errorFields["rottenDays"]}`}
                                    onValueChange={(e) => { handleNumberChange(e, 'rottenDays') }}
                                    value={stage.rottenDays > 0 ? stage.rottenDays : ''}
                                />
                            </div>
                            <div className="form-group col-md-12">
                                <label >  <Translate text={'Info'} /> </label>
                                <textarea
                                    className={'form-control form-style-notes'}
                                    name={'info'}
                                    onChange={(e) => { handleNotesChanges(e) }}
                                    value={stage.info || ''}
                                    placeholder="info"
                                    cols="80"
                                    rows="3"
                                >
                                </textarea>
                            </div>
                            {
                                props.dealersettings.superAdmin === true ? (
                                    <div className="form-group col-md-12">
                                        <label > <Translate text={'Key'} /></label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder={'key'}
                                            className={`form-control ${errorFields["key"]}`}
                                            name="key"
                                            onChange={(e) => {
                                                setStage({
                                                    ...stage,
                                                    [e.target.name]: e.target.value
                                                })
                                            }}
                                            value={stage.key}
                                            maxLength={150}
                                        />
                                    </div>
                                ) : (<></>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveStage(e)}>
                {
                    loader ?
                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                        : (<></>)
                }
                <Translate text={'save'} />
            </button>
            <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} >  <Translate text={'cancel'} /></button>
        </Modal.Footer>
    </Modal>
    );
}

export default AddStage;