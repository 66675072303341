import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Translate from "../../../../constants/translate";
import images from "../../../../images";
import { InputMultipleText, ReactMultiSelect, ReactSelect } from "../../../../components";
import _ from "lodash";
import { dynSelectOptions } from "../../../../admin/settings/integrations/addOns/viewModel";

const DynOptions = (props) => {
  const [state, setState] = useState(props.intgSettings || {})
  const [errorFields, setErrorFields] = useState({});
  const [btnLoader, setLoader] = useState(false)
  const [selectOptions, setSelectOptions] = useState({ ...dynSelectOptions })

  useEffect(() => {
    if (_.isEmpty(props.intgSettings)) {
      return
    }
    if (
      !(
        !_.isEmpty(props.selectedLevel) &&
        (props.selectedLevel.level === "oem" || !_.isEmpty(props.selectedLevel.levelID))
      )
    ) {
      return;
    }

    let integrationRef;
    if (props.selectedLevel.level === "oem") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID)
        .collection("integrations")
        .where('type', '==', props.intg.value);
    } else if (props.selectedLevel.level === "region") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID)
        .collection("regions")
        .doc(props.selectedLevel.levelID)
        .collection("integrations")
        .where('type', '==', props.intg.value);
    } else if (props.selectedLevel.level === "group") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID)
        .collection("groups")
        .doc(props.selectedLevel.levelID)
        .collection("integrations")
        .where('type', '==', props.intg.value);
    } else if (props.selectedLevel.level === "individual") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clients`)
        .doc(props.selectedLevel.levelID)
        .collection("integrations")
        .where('type', '==', props.intg.value);
    }

    integrationRef.get()
      .then((querySnapshot) => {
        let _integrations = [];
        querySnapshot.docs.forEach((rec) => {
          let data = rec.data();
          _integrations.push({
            documentID: rec.id,
            ...data,
          });
        });
        if (_.find(_integrations, { type: props.intg.value })?.settings) {
          setState(_.find(_integrations, { type: props.intg.value })?.settings)
        }
      })

  }, [])

  useEffect(() => {
    if (props?.dealersettings?.client?.settings) {
      let allSettings = {}
      Object.keys(props.dealersettings.client.settings).forEach(rec => {
        if (_.isArray(props.dealersettings.client.settings[rec]) && props.dealersettings.client.settings[rec].some(a => a.value)) {
          allSettings = {
            ...allSettings,
            [rec]: props.dealersettings.client.settings[rec].map(r => { return { label: r.name, value: r.value } })
          }
        }
      })
      setSelectOptions({
        ...dynSelectOptions,
        ...allSettings
      })
    }
  }, [])

  const handleOnChangeMultipleText = (val, name) => {
    setState({
      ...state,
      [name]: !_.isEmpty(val) ? val : [],
    });
    setErrorFields({
      ...errorFields,
      [name]: null
    });
  };

  const removeEmail = (index, name) => {
    let arr = state[name] ? state[name] : [];
    arr.splice(index, 1);
    setState({
      ...state,
      [name]: arr,
    });
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
    setErrorFields({
      ...errorFields,
      [e.target.name]: null
    });
  }

  const handleReactSelectChange = (e, data) => {
    setState({
      ...state,
      [data.name]: e?.value || ''
    });

    setErrorFields({
      ...errorFields,
      [`${data.name}`]: ''
    });
  }

  const handleReactMultiSelectChange = (selectedOptions, name) => {
    const value = [];

    !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
      value.push(data.value)
    })

    setState({
      ...state,
      [name]: value
    });

    setErrorFields({
      ...errorFields,
      [`${name}`]: ''
    });
  }

  const handleSave = () => {
    let formIsValid = true;
    let errorClass = "input_error";
    let errors = {};
    props.intg.fields.filter(a => a.mandatory === true).forEach((field) => {
      if (_.isEmpty(state[field.value])) {
        formIsValid = false;
        errors[`${field.value}`] = errorClass;
      }
    });
    setErrorFields(errors);
    if (!formIsValid) {
      return;
    }
    props.handleClose();
    props.enableAddons(
      props.moduleToChange.module,
      props.moduleToChange.checked,
      state,
    );
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? "overlay-modal active" : ""}
        enforceFocus={false}
        dialogClassName={`modal-dialog modal-dialog-centered modal-configure-integration`}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title">
              <Translate text={`${props.title} Integration`} />
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="form-style w-100">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <div className="configure-integration-logo">
                      <img src={images[props.intg.icon]} alt={props.intg.name} />
                    </div>
                  </div>
                </div>
                <div className="form-row ">
                  {
                    props.intg.website ? (
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          paddingBottom: "15px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        If you do not have these credentials, you can get them
                        from{" "}
                        <a
                          href={props.intg.website}
                          target="_blank"
                          style={{ color: "#2b57e2", fontWeight: "600" }}
                        >
                          here
                        </a>
                        .
                      </p>
                    ) : (<></>)
                  }
                  {
                    props.intg.fields.map((field, index) => {
                      return <div key={index} className={`form-group ${field.class}`}>
                        <label>
                          <Translate text={field.label} />{" "}
                        </label>
                        {
                          field.type === 'multitext' ? (
                            <InputMultipleText
                              placeholder={field.placeholder || field.value}
                              className={`form-control react-multi-tag ${errorFields[field.value]}`}
                              name={field.value}
                              onChange={(vals) => {
                                handleOnChangeMultipleText(vals, field.value);
                              }}
                              value={state[field.value] || []}
                              validateEmail={Boolean(field.validateEmail)}
                              getLabel={(email, index) => {
                                return (
                                  <div data-tag key={index}>
                                    {email}
                                    <span
                                      data-tag-handle
                                      onClick={() => removeEmail(index, field.value)}
                                    >
                                      ×
                                    </span>
                                  </div>
                                );
                              }}
                            />
                          ) : field.type === 'text' ? (
                            <input
                              type="text"
                              className={`form-control ${errorFields[field.value]}`}
                              value={state[field.value] || ""}
                              placeholder={`enter ${field.placeholder || field.value}`}
                              onChange={handleChange}
                              name={field.value}
                            />
                          ) : field.type === 'select' ? (
                            <ReactSelect
                              options={selectOptions?.[field.options] || []}
                              name={field.value}
                              placeholder={`select ${field.placeholder || field.value}`}
                              onChange={handleReactSelectChange}
                              value={state[field.value]}
                              classNamePrefix={`${errorFields[field.value]} cursor-pointer basic-select`}
                              removeClearable={false}
                            >
                            </ReactSelect>
                          ) : field.type === 'multiselect' ? (
                            <ReactMultiSelect
                              options={selectOptions?.[field.options] || []}
                              name={field.value}
                              placeholder={`select ${field.placeholder || field.value}`}
                              onChange={(option) => handleReactMultiSelectChange(option, field.value)}
                              value={state[field.value] || []}
                              classNamePrefix={`${errorFields[field.value]} cursor-pointer basic-select`}
                              removeClearable={false}
                              isMulti={true}
                              isSearchable={true}
                            >
                            </ReactMultiSelect>
                          ) : (<></>)
                        }
                      </div>
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer >
          <button
            type="button"
            className="btn btn-primary float-right ml-2 "
            aria-label="Save"
            onClick={() => handleSave()}>
            {
              btnLoader ?
                (<span className="spinner-border spinner-button mr-1" role="status" aria- hidden="true"></span>)
                : (<></>)
            }
            <Translate text={"Save"} />
          </button>
          <button
            type="button"
            className="btn btn-default float-left"
            onClick={() => props.handleClose(null)}
          >
            <Translate text={"close"} />
          </button>
        </Modal.Footer >
      </Modal >
    </>
  );
};

export default DynOptions;
