/** LIBRARIES */
import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { Tab, Nav } from 'react-bootstrap'
import Translate from '../../constants/translate';
import SalesTarget from './salesTarget'
import DealerTarget from './dealerTarget'
import SalesProgress from './salesProgress'
import { PopUpModal, ContentOverlay } from '../../components'
import AddReport from './addReport'
import DynamicReport from './dynamicReport'
import InboundLeadProgress from './inboundleadProgress'
import LeadProgressKPI from './leadProgressKpi'
import AddDynamicReport from './addDynReport'
import ViewDynamicReport from './viewDynReport'
import CustomerLogReport from "./customerLogReport";
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import StaticReport from './staticReport';
import SalesProgressbyQuote from './salesProgressbyQuote'
import AddEmailSettings from './addEmailSettings'
import uuid from 'react-uuid'
import moment from 'moment'
import MarketingROI from './marketingROI';
import PerformanceWithTrend from './performanceWithTrend';


const Reports = (props) => {
    const [showModel, setShowmodel] = useState(false)
    const [showDynModel, setShowDynmodel] = useState(false)
    const [dynamicReports, setReports] = useState([])
    const [staticReports, setStaticReports] = useState([])
    const [loader, setLoader] = useState(true)
    const [key, setKey] = useState()
    const [showSMTPModel, setShowSMTPmodel] = useState(false)
    const [nylasCode, setNylasCode] = useState()


    const _pipelines = (props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.settings) ?
        props.dealersettings.client.settings.pipelines : [];

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let code = params.get('code');
        if (_.isEmpty(code))
            return

        setNylasCode(code)
        setShowSMTPmodel(true);
    }, [])

    useEffect(() => {
        function handleResize() {
            document.documentElement.style.setProperty('--reportulheight', (window.innerHeight - 130) + 'px')
            document.documentElement.style.setProperty('--reportpanelheight', (window.innerHeight - 50) + 'px')
            document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 130) + 'px')
        }
        document.documentElement.style.setProperty('--reportulheight', (window.innerHeight - 130) + 'px')
        document.documentElement.style.setProperty('--reportpanelheight', (window.innerHeight - 50) + 'px')
        document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 130) + 'px')

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.dynReportsCollection && window.dynReportsCollection.unsubscribe()
        }
    }, [])

    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let key = params.get('key');
        if (!key) {
            key = props.dealersettings.isPorsche === true ? "performancewithtrend" : "salesprogress"
        }
        if (props?.dealersettings?.rolePermissions?.permissions?.accessToReportsOnly === true) {
            setKey(null)
        }
        else {
            setKey(key)
        }
    }, [])

    useEffect(() => {
        if (!props.dealersettings)
            return;

        let refprivateReportsCollection = '';
        let refsharedReportsCollection = '';
        if (props.dealersettings.roleName === 'Administrator') {
            refprivateReportsCollection = window.firebase.firestore().collection('reports')
                .where('clientID', '==', props.dealersettings.client.id);
        }
        else if (props?.dealersettings?.rolePermissions?.permissions?.accessToReportsOnly === true) {
            refprivateReportsCollection = window.firebase.firestore().collection('reports')
                .where('visibility', '==', 'sharedView')
                .where('ownerIDs', 'array-contains', localStorage.uid)
                .where('clientID', '==', props.dealersettings.client.id);
        }
        else {
            refprivateReportsCollection = window.firebase.firestore().collection('reports')
                .where('owner', '==', localStorage.uid)
                .where('clientID', '==', props.dealersettings.client.id);


            refsharedReportsCollection = window.firebase.firestore().collection('reports')
                .where('visibility', 'in', ['shared', 'sharedView'])
                .where('clientID', '==', props.dealersettings.client.id);
        }


        const refstaticReportsCollection = window.firebase.firestore().collection('schedular')
            .where('static', '==', true)
            .where('clientID', '==', props.dealersettings.client.id)
            .limit(1);

        window.dynReportsCollection = combineLatest(
            refprivateReportsCollection ? collection(refprivateReportsCollection) : of([]),
            refsharedReportsCollection ? collection(refsharedReportsCollection) : of([]),
            collection(refstaticReportsCollection),
        ).pipe(
            map(([privateReport, sharedReport, staticReport]) => {
                return [
                    _.map(privateReport, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    _.map(sharedReport, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    _.map(staticReport, function (obj) { return { ...obj.data(), 'documentID': obj.id } })
                ]
            })).subscribe(querySnapshot => {
                let reports = [];
                for (let i = 0; i < 2; i++) {
                    querySnapshot[i].forEach(el => {
                        if (!reports.some(e => e.documentID === el.documentID) && !Boolean(el.static) && !Boolean(el.marketing)) {
                            const _defaultfilters = [{
                                id: uuid(),
                                name: 'date',
                                type: el.columnType === 'stock' ? 'overall' : 'thisMonth',
                                value: {
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                }

                            }];
                            if (el.make) {
                                _defaultfilters.push({
                                    id: uuid(),
                                    name: 'makeModels',
                                    value: {
                                        make: el.make[0]
                                    }
                                })
                            }
                            if (el.saleType) {
                                _defaultfilters.push({
                                    id: uuid(),
                                    name: 'salesType',
                                    value: {
                                        salesType: el.saleType
                                    }
                                })
                            }
                            if (el.leadType) {
                                _defaultfilters.push({
                                    id: uuid(),
                                    name: 'leadType',
                                    value: {
                                        leadType: el.leadType
                                    }
                                })
                            }
                            reports.push({
                                ...el,
                                pipeline: _.isArray(el.pipeline) ? el.pipeline : el.pipeline !== 'all' ? [el.pipeline] : [],
                                columns: _.isArray(el.columns) ? el.columns : [el.columns],
                                objFilters: _.isEmpty(el.objFilters) ? _defaultfilters : el.objFilters
                            })
                        }
                    });
                }
                setReports(_.orderBy(reports, ['addedDate'], ['asc']))
                if (props?.dealersettings?.rolePermissions?.permissions?.accessToReportsOnly === true && reports.length > 0) {
                    setKey(_.orderBy(reports, ['addedDate'], ['asc'])[0].documentID)
                }
                setStaticReports(querySnapshot[2])
                setLoader(false)
            })

    }, [props.dealersettings])



    const handleModelClose = (rec, isNew) => {
        setShowmodel(false)
        if (isNew) {
            setKey(rec.documentID)
        }
    }

    const handleModelDynClose = (rec, isNew) => {
        setShowSMTPmodel(false)
        setShowDynmodel(false)
        if (isNew) {
            setKey(rec.documentID)
        }
    }

    return (
        <>
            <div className="middle-wrapper">
                <div className="oem-panel">
                    <Tab.Container activeKey={key} onSelect={(k) => setKey(k)} mountOnEnter={true}>
                        <div id="report-wrapper">
                            <div id="sidebar-wrapper">
                                <div className="report-sidebar">
                                    <div className="custom-menu">
                                        <a href="#" className="btn-primary sidebar-toggle" onClick={(e) => {
                                            e.preventDefault()
                                            if (document.getElementById('report-wrapper')) {
                                                document.getElementById('report-wrapper').classList.toggle("toggled")
                                            }
                                        }}><i className="ico icon-menu"></i></a>
                                    </div>
                                    <div className="report-add-wrap">
                                        <h4 >Reports </h4>
                                        {
                                            props?.dealersettings?.rolePermissions?.permissions?.accessToReportsOnly === true ? (
                                                <></>
                                            ) : (
                                                <>
                                                    <div className="report-email-settings"><a href="#" className="mini-button" onClick={() => {
                                                        setShowSMTPmodel(true);
                                                    }}> <i className="ico icon-settings"></i></a></div>
                                                    <div className="report-add-button"><a href="#" className="mini-button" onClick={() => {
                                                        setShowDynmodel(true)
                                                        //setShowmodel(true)
                                                    }}> <i className="ico icon-add"></i></a></div>
                                                </>
                                            )
                                        }
                                    </div>
                                    <Nav as="ul" bsPrefix="ul-nav report-ul-scroll">
                                        {
                                            props?.dealersettings?.rolePermissions?.permissions?.accessToReportsOnly === true ? (
                                                <></>
                                            ) : (<>
                                                {
                                                    props.dealersettings.isPorsche === true ? (
                                                        <Nav.Item as="li" bsPrefix="li-nav">
                                                            <Nav.Link eventKey="performancewithtrend" bsPrefix="li-a-nav"> <Translate text={'Campaign Dashboard'} /></Nav.Link>
                                                        </Nav.Item>
                                                    ) : (<></>)
                                                }
                                                <Nav.Item as="li" bsPrefix="li-nav">
                                                    <Nav.Link eventKey="salesprogress" bsPrefix="li-a-nav"> <Translate text={'Sales Progress'} /></Nav.Link>
                                                </Nav.Item>
                                                {
                                                    _pipelines.length > 0 && _pipelines.some(item => item.value === 'LeadsBucket') ? (<Nav.Item as="li" bsPrefix="li-nav">
                                                        <Nav.Link eventKey="inboundleadprogress" bsPrefix="li-a-nav"> <Translate text={'Inbound Lead Progress'} /></Nav.Link>
                                                    </Nav.Item>) : (<></>)
                                                }
                                                <Nav.Item as="li" bsPrefix="li-nav">
                                                    <Nav.Link eventKey="leadprogresskpi" bsPrefix="li-a-nav"> <Translate text={'Lead KPIs'} /></Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item as="li" bsPrefix="li-nav">
                                                    <Nav.Link eventKey="customerLogs" bsPrefix="li-a-nav"> <Translate text={'Customer Log Report'} /></Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item as="li" bsPrefix="li-nav">
                                                    <Nav.Link eventKey="salestarget" bsPrefix="li-a-nav"> <Translate text={'Sales Target'} /></Nav.Link>
                                                </Nav.Item>
                                            </>)
                                        }
                                        {
                                            (!_.isEmpty(props.dealersettings.rolePermissions) && !_.isEmpty(props.dealersettings.rolePermissions.permissions) && props.dealersettings.rolePermissions.permissions.accessDealertarget) ? (
                                                <Nav.Item as="li" bsPrefix="li-nav">
                                                    <Nav.Link eventKey="dealertarget" bsPrefix="li-a-nav"> <Translate text={'Dealer Target'} /></Nav.Link>
                                                </Nav.Item>
                                            ) : (<></>)
                                        }
                                        {
                                            staticReports.length > 0 && (!_.isEmpty(props.dealersettings.rolePermissions) && !_.isEmpty(props.dealersettings.rolePermissions.permissions) && props.dealersettings.rolePermissions.permissions.accessStaticReport) ? (<Nav.Item as="li" bsPrefix="li-nav">
                                                <Nav.Link eventKey="staticReport" bsPrefix="li-a-nav"> <Translate text={'Static Reports'} /></Nav.Link>
                                            </Nav.Item>) : (<></>)
                                        }
                                        {
                                            loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
                                                dynamicReports.map((rec, index) => {
                                                    return <Nav.Item key={index} as="li" bsPrefix="li-nav">

                                                        <Nav.Link eventKey={rec.documentID} bsPrefix="li-a-nav">
                                                            {
                                                                rec.schedule ? (
                                                                    <div title='Schedule' className="report-schedule-mark"><i className="ico icon-followup-calendar"> </i></div>
                                                                ) : (<></>)
                                                            }
                                                            {rec.name}</Nav.Link>
                                                    </Nav.Item>
                                                })
                                            )
                                        }

                                    </Nav>
                                </div>
                            </div>
                            <div id="page-content-wrapper">
                                <div className="report-content-area">
                                    <div className="report-content-sub">
                                        {
                                            props.dealersettings && props.dealersettings.client && props.dealersettings.client.requirementType === 'automotiveServices' ? (
                                                <Tab.Pane eventKey="salesprogress">
                                                    <SalesProgressbyQuote {...props} />
                                                </Tab.Pane>
                                            ) : (
                                                <Tab.Pane eventKey="salesprogress">
                                                    <SalesProgress {...props} />
                                                </Tab.Pane>
                                            )
                                        }
                                        {
                                            _pipelines.length > 0 && _pipelines.some(item => item.value === 'LeadsBucket') ? (
                                                <Tab.Pane eventKey="inboundleadprogress">
                                                    <InboundLeadProgress {...props} />
                                                </Tab.Pane>
                                            ) : (<></>)
                                        }
                                        <Tab.Pane eventKey="leadprogresskpi">
                                            <LeadProgressKPI {...props} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="performancewithtrend">
                                            <PerformanceWithTrend {...props} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="marketingroi">
                                            <MarketingROI {...props} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="customerLogs">
                                            <CustomerLogReport {...props} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="salestarget">
                                            <SalesTarget {...props} />
                                        </Tab.Pane>
                                        {
                                            (!_.isEmpty(props.dealersettings.rolePermissions) && !_.isEmpty(props.dealersettings.rolePermissions.permissions) && props.dealersettings.rolePermissions.permissions.accessDealertarget) ? (
                                                <Tab.Pane eventKey="dealertarget">
                                                    <DealerTarget {...props} />
                                                </Tab.Pane>
                                            ) : (<></>)
                                        }
                                        {
                                            staticReports.length > 0 && (!_.isEmpty(props.dealersettings.rolePermissions) && !_.isEmpty(props.dealersettings.rolePermissions.permissions) && props.dealersettings.rolePermissions.permissions.accessStaticReport) ? (
                                                <Tab.Pane eventKey="staticReport">
                                                    <StaticReport {...props} />
                                                </Tab.Pane>
                                            ) : (<></>)
                                        }
                                        {
                                            loader ? (<></>) : (
                                                dynamicReports.map((rec, index) => {
                                                    return !_.isEmpty(rec.reportType) ? (<Tab.Pane key={index} eventKey={rec.documentID}>
                                                        <ViewDynamicReport {...props} report={rec} activeKey={key} setKey={(k) => setKey(k)} />
                                                    </Tab.Pane>) : (<Tab.Pane key={index} eventKey={rec.documentID}>
                                                        <DynamicReport {...props} report={rec} setKey={(k) => setKey(k)} />
                                                    </Tab.Pane>)
                                                })
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab.Container>
                </div>
            </div>
            <PopUpModal show={showModel}>
                <AddReport
                    {...props}
                    show={showModel}
                    title={'Add Report'}
                    handleClose={handleModelClose}
                />
            </PopUpModal>
            <PopUpModal show={showDynModel}>
                <AddDynamicReport
                    {...props}
                    show={showDynModel}
                    title={'Add Report'}
                    handleClose={handleModelDynClose}
                />
            </PopUpModal>
            <PopUpModal show={showSMTPModel}>
                <AddEmailSettings
                    {...props}
                    show={showSMTPModel}
                    title={'Email Settings'}
                    handleClose={handleModelDynClose}
                    nylasCode={nylasCode}
                />
            </PopUpModal>


        </>);
}

export default Reports;