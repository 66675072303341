/** LIBRARIES */
import * as React from 'react'
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import moment from 'moment';
import _ from 'lodash'
import Swal from 'sweetalert2';
/** COMPONENTS */
import CommonHelper from '../../services/common'
import { PopUpModal } from '../../components';
import LeadConvert from './leadConvert'
import OEMLeadAllocation from './oemLeadAllocation'
//import OEMLeadTransfer from './oemLeadTransfer'
import InBoundLeadConvert from './inBoundLeadConvert'
import { objActivitylog, objModules, objOEMInboundVM, objEnquiry } from './viewModel'
import EnquiryFields from './enquiryFields'
/** VIEW-MODELS */
import { enquiryStatus, enqUnActionStatus, inboundLeadStatus } from '../../services/enum';
import { firestoreDB } from '../../services/helper';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import enquiryHelper from '../../services/enquiryHelper';

const StageContext = React.createContext()


const fieldsData = {
    contact: '',
    businessContact: '',
    requirement: '',
    enquiry: '',
    company: ''
}


let enquiryOptions = [];
let enquiryOptionsDF = [];

export const StageConsumer = StageContext.Consumer

export class StageProvider extends React.Component {
    constructor(props) {
        super(props);
        this.mandatoryFields = [];
        this.state = {
            filter: {},
            enquiries: {},
            pipeline: [],
            isLoading: false,
            columns: [],
            clientid: '',
            hasMore: [],
            pageNum: 0,
            pageLimit: 10,
            stageLoading: '',
            showNotifier: false,
            notifierMsg: '',
            notifierInfo: '',
            sectionloading: false,
            checkLoad: {},
            showLeadConvert: false,
            showOEMLeadConvert: false,
            showOEMLeadConvertPrev: false,
            convertLeadId: '',
            convertLeadStage: '',
            convertLeadLoader: false,
            isFilterApplied: false,
            stageLoader: [],
            stageCounts: {},
            enquiryModal: {
                show: false,
                enquiry: {},
                errors: null,
                origin: {}
            },
            inboundModal: {
                show: false,
                docID: '',
                stage: null
            },
            selectedEnquiry: {},
            searchText: (localStorage.enquirySearchText ? localStorage.enquirySearchText : ''),
            inBoundSearchText: (localStorage.inBoundSearchText ? localStorage.inBoundSearchText : ''),
            hasMoreInbound: [],
        };

        //this.listenScrollEvent = this.listenScrollEvent.bind(this);
    }

    componentDidMount() {
        if (this.state.isLoading) {
            if (localStorage.pipelineFilter && this.state.isFilterApplied === false)
                this.setState({ isFilterApplied: true }, () => { this.LoadEnquiry() })
            else
                this.LoadEnquiry()

            const { dealersettings } = this.props;
            if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
                const setttings = dealersettings.client.settings;
                if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.enquiry))
                    this.mandatoryFields = setttings.mandatoryFields.enquiry.split(',');

                enquiryOptions = [];
                setttings.enquiryOptions && setttings.enquiryOptions.forEach(doc => {
                    enquiryOptions.push({
                        value: doc.value,
                        label: doc.name,
                    });
                });

                enquiryOptionsDF = [];
                if (setttings.enquiryOptionsDF)
                    enquiryOptionsDF = setttings.enquiryOptionsDF;
            }
            this.refreshStageCount()
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.isLoading) {
            this.LoadEnquiry()
        }
        if (JSON.stringify(prevState.columns) !== JSON.stringify(this.state.columns)) {
            this.refreshStageCount()
        }
    }
    componentWillUnmount() {
        !_.isEmpty(this.state.pipeline) && this.state.pipeline.forEach(p => {
            p.stages.forEach(s => {
                window['unsub' + p.value + s.value] && window['unsub' + p.value + s.value]();
            })
        })
        //window.unsubpipelinestages && window.unsubpipelinestages.unsubscribe();
        window.unSubPipelineCount && window.unSubPipelineCount();
    }

    refreshStageCount = () => {
        if (window.location.pathname !== '/pipeline' && window.location.pathname !== '/inbound')
            return

        const { pipeline } = this.state;
        const { dealersettings, pipelineView } = this.props;
        let _selectedPipeline = pipeline.find(item => (pipelineView && pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true))
        var _dpl = _selectedPipeline ? _selectedPipeline.value : '';

        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && _dpl) {
            try {

                let _searchObject = {
                    "pipeline": _dpl,
                    "owner": localStorage.uid,
                    "settingsID": dealersettings.oemID ? dealersettings.oemID : null
                }

                _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings, pipelineView, null, true);

                setTimeout(() => {
                    const stageCountsBQ = window.firebase.functions().httpsCallable('enquiries-stageCountsBQ');
                    stageCountsBQ(_searchObject).then((response) => {
                        //console.log('stageCountsBQ', _dpl, response);
                    });
                }, 3000)
            }
            catch (error) {
                console.error(error)
            }
        }
    }

    isValidFilter = () => {
        const { pipelineView } = this.props;
        if (localStorage.pipelineFilter && !pipelineView) {
            let _pipelineFilter = JSON.parse(localStorage.pipelineFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);

            // if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            // if (Object.keys(_filter).length === 1 && (_filter.hasOwnProperty('keywords') || (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty('keywords'))
                return false;

            return true;
        }
        else if (localStorage.inBoundFilter && (pipelineView && pipelineView === 'INBOUND')) {
            let _inBoundFilter = JSON.parse(localStorage.inBoundFilter);
            let _filter = Object.assign({}, _inBoundFilter.value);

            // if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            // if (Object.keys(_filter).length === 1 && (_filter.hasOwnProperty('keywords') || (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty('keywords'))
                return false;

            return true;
        }
        else {
            return false;
        }
    }

    LoadEnquiry = async (_isPaging) => {
        try {

            if (window.location.pathname !== '/pipeline' && window.location.pathname !== '/inbound' && window.location.pathname !== '/aftersales')
                return

            const { columns, clientid, pipeline, pageLimit, hasMore, pageNum, isFilterApplied, searchText, inBoundSearchText } = this.state;
            const { dealersettings, pipelineView } = this.props;

            let _selectedPipeline = pipeline.find(item => (pipelineView && pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true))
            var _dpl = _selectedPipeline ? _selectedPipeline.value : '';

            const viewOtherEnquiry = (pipelineView === 'INBOUND') ? true : ((!_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.rolePermissions) &&
                !_.isEmpty(dealersettings.rolePermissions.permissions) &&
                dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);
            let _pageNum = pageNum;

            let excludeOrigins = Object.assign([], dealersettings?.rolePermissions?.excludeOrigins)

            //console.log('LoadEnquiry', _pageNum, isFilterApplied, hasMore);


            //const promises = []
            let enquiries = this.state.enquiries
            if (_.isEmpty(this.state.enquiries)
                //|| JSON.stringify(columns) !== JSON.stringify(Object.keys(this.state.enquiries.columns))
                || JSON.stringify(_.map(this.state.enquiries.columns, function (item) { if (item.pipeline === pipeline.find(item => (pipelineView && pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true)).value) return item.id; }).filter(item => !_.isEmpty(item)).join(",").split(',')) !== JSON.stringify(columns)
                //|| this.state.stageLoading === ''
                || (this.isValidFilter() && _pageNum === 0)
            ) {
                //console.log('EMPTY ENQUIRIES', isFilterApplied, _pageNum, Object.keys(this.state.enquiries).length, JSON.stringify(_.map(this.state.enquiries.columns, function (item) { if (item.pipeline === pipeline.find(item => (item.value !== 'LeadsBucket' && item.default === true)).value) return item.id; }).filter(item => !_.isEmpty(item)).join(",").split(',')) !== JSON.stringify(columns))
                enquiries = {}
                _pageNum = 0
                this.setState({ enquiries: enquiries, pageNum: 0 })
            }

            //get total pipeline count by stages
            if ((!searchText && !localStorage.pipelineFilter && !pipelineView) || (!inBoundSearchText && !localStorage.inBoundFilter && pipelineView && pipelineView === 'INBOUND'))
                this.getPipelineCount(viewOtherEnquiry, _dpl)

            //let _hasMore = false;
            let i = 0;
            let pipelinePromise = []
            columns.forEach((_rec, indez) => {

                let lastRecord = this.state.hasMore.filter(e => e.Stage === _dpl + _rec);
                if (JSON.stringify(_.map(this.state.enquiries.columns, function (item) { if (item.pipeline === pipeline.find(item => (pipelineView && pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true)).value) return item.id; }).filter(item => !_.isEmpty(item)).join(",").split(',')) !== JSON.stringify(columns)) {
                    lastRecord = []
                }

                if (this.isValidFilter()) {
                    let _pipelineFilter = {};

                    if (localStorage.pipelineFilter && !pipelineView)
                        _pipelineFilter = JSON.parse(localStorage.pipelineFilter);

                    if (localStorage.inBoundFilter && (pipelineView && pipelineView === 'INBOUND'))
                        _pipelineFilter = JSON.parse(localStorage.inBoundFilter);

                    let _filter = Object.assign({}, _pipelineFilter.value);

                    _filter.pipeline = _dpl;

                    if (localStorage.inBoundFilter && (pipelineView && pipelineView === 'INBOUND')) {
                        _filter.inbound = true;

                        if (localStorage.defaultModule === 'oem')
                            _filter.inboundType = (_dpl === 'LeadsBucket' && _rec === 'Converted') ? 'allocated' : 'unallocated';

                    }


                    if (_.isEmpty(_filter.stage)) {
                        _filter.stage = _rec;
                    }
                    else if (!_filter.stage.includes(_rec)) {
                        _filter.stage = `${_rec + indez}`
                    }

                    // if (lastRecord.length > 0 && _hasMore === false)
                    //     _hasMore = true;

                    if (_pageNum === 0 || lastRecord.length > 0) {
                        if ((_pageNum === 0 || lastRecord.length > 0) && i === 0) {
                            i++;
                            _pageNum = (pageNum + 1);
                            this.setState({ pageNum: _pageNum })
                        }
                    }

                    if (!viewOtherEnquiry && localStorage.defaultModule !== 'oem')
                        _filter.owner = dealersettings.id;

                    /* DATE RANGE FILTER */
                    if (!_.isEmpty(_filter.date)) {
                        _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
                        delete _filter['date'];
                    }

                    /* MAKE & MODEL FILTER */
                    if (!_.isEmpty(_filter.make))
                        _filter = CommonHelper.handleMakeModelFilter(_filter, dealersettings);

                    if (_filter.rottenDays &&
                        !_.isEmpty(dealersettings) &&
                        !_.isEmpty(dealersettings.client) &&
                        !_.isEmpty(dealersettings.client.settings) &&
                        !_.isEmpty(dealersettings.client.settings.pipelines)) {
                        _filter.pipelineSettings = JSON.stringify(dealersettings.client.settings.pipelines)
                    }

                    if (_.isEmpty(_filter.saleType) && _.isArray(dealersettings.accessSalesType) && dealersettings.accessSalesType.length > 0 && pipelineView && pipelineView === 'INBOUND' && localStorage.defaultModule !== 'oem') {
                        _filter.saleType = dealersettings.accessSalesType.join(',');
                    }

                    if (_.isEmpty(_filter.origin) && !_.isEmpty(excludeOrigins))
                        _filter.origin = CommonHelper.getDataExcluded(dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);
                    else if (!_.isEmpty(_filter.origin) && !_.isEmpty(excludeOrigins))
                        _filter.origin = excludeOrigins.includes(_filter.origin) ? null : _filter.origin

                    _filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, dealersettings, pipelineView, null, true);
                    _filter.timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess()

                    // Region/Subregion/Group/Client filter
                    if ((!_.isEmpty(_filter?.region) || !_.isEmpty(_filter?.subregion) || !_.isEmpty(_filter?.group)) && _.isEmpty(_filter?.clientID)) {
                        _filter.clientID = dealersettings?.clients ? dealersettings.clients.filter(client =>
                            (_filter?.region?.length > 0 ? _filter.region.includes(client.region) : true) &&
                            (_filter?.subregion?.length > 0 ? _filter.subregion.includes(client.subregion) : true) &&
                            (_filter?.group?.length > 0 ? _filter.group.includes(client.group) : true)
                        ).map(r => { return r.id }).join(',') : ''
                        //console.log('_filter', dealersettings.level, _filter)
                    }

                    let _searchObject = {
                        "type": "searchEnquiries",
                        "filters": JSON.stringify(_filter),
                        "sortOrder": "stageDate desc",
                        "pageNum": pageNum,
                        "pageLimit": pageLimit
                    }

                    _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings, pipelineView, null, true);
                    //console.log('filter-searchEnquiries', _searchObject, clientID, pageNum, _filter);
                    const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
                    searchEnquiries(_searchObject).then((response) => {
                        //console.log('generic-searchData', _rec, response);
                        if (indez === 0) {
                            this.setState({
                                isLoading: false,
                            })
                        }
                        if (response.data.success && this.isValidFilter()) {
                            this.setState({
                                stageCounts: {
                                    ...this.state.stageCounts,
                                    [_rec]: {
                                        total: response.data.total
                                    }
                                }
                            })
                            this.onCollectionEnquiry(response.data, _rec);
                            //     if (_pageNum === 0)
                            //         this.setState({ isLoading: columns[columns.length - 1] === _rec ? false : true, })
                        }
                    });

                    window['unsub' + _dpl + _rec] && window['unsub' + _dpl + _rec]();
                    //window.unsubpipelinestages && window.unsubpipelinestages.unsubscribe();
                    window.unSubPipelineCount && window.unSubPipelineCount()
                }
                else {
                    var enquiryQuery = '';
                    let searchKeywords = (pipelineView && pipelineView === 'INBOUND') ? inBoundSearchText : searchText;
                    let searchOwners = [];

                    if (localStorage.pipelineFilter && !pipelineView) {
                        let _pipelineFilter = JSON.parse(localStorage.pipelineFilter);
                        let _filter = Object.assign({}, _pipelineFilter.value);

                        searchKeywords = _filter['keywords'] ? _filter['keywords'] : searchText;
                        searchOwners = _filter['owner'] ? _filter['owner'].split(",") : [];

                        this.setState({ searchText: searchKeywords });
                        localStorage.enquirySearchText = searchKeywords;
                        //console.log('search---Keywords', searchKeywords, searchOwners, searchText)
                    }
                    if (localStorage.inBoundFilter && (pipelineView && pipelineView === 'INBOUND')) {
                        let _inBoundFilter = JSON.parse(localStorage.inBoundFilter);
                        let _filter = Object.assign({}, _inBoundFilter.value);

                        searchKeywords = _filter['keywords'] ? _filter['keywords'] : inBoundSearchText;
                        searchOwners = _filter['owner'] ? _filter['owner'].split(",") : [];

                        this.setState({ inBoundSearchText: searchKeywords });
                        localStorage.inBoundSearchText = searchKeywords;
                        //console.log('search--Inbound--Keywords', searchKeywords, searchOwners, inBoundSearchText)
                    }

                    if (_dpl === 'LeadsBucket' && _rec === 'InboundLead') {
                        enquiryQuery = firestoreDB(this.props.dealersettings).firestore().collection('inboundLeads')
                            //.where('clientID', '==', clientid)
                            .where('status', '==', 0)
                            .where('isDeleted', '==', false)
                            .orderBy('addedDate', 'desc')

                        enquiryQuery = CommonHelper.whereClientOrGroupOrRegionOrOemClause(enquiryQuery, dealersettings, pipelineView, true);

                        if (searchKeywords) {
                            let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)

                            if (formattedSearchText.trim())
                                enquiryQuery = enquiryQuery.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
                        }

                        if (_.isArray(dealersettings.accessSalesType) && dealersettings.accessSalesType.length > 0) {
                            enquiryQuery = enquiryQuery.where('requirement.saleType', 'in', [...CommonHelper.arrLimit(dealersettings.accessSalesType, 8), ''])
                        }
                        else if (!_.isEmpty(excludeOrigins))
                            enquiryQuery = enquiryQuery.where('origin', 'in', CommonHelper.getDataExcluded(dealersettings.client?.settings?.origins, excludeOrigins, true))

                        if (localStorage.defaultModule === 'oem' && pipelineView && pipelineView === 'INBOUND') {
                            if (lastRecord.length > 0) {
                                enquiryQuery = enquiryQuery
                                    .startAfter(lastRecord[0].lastDoc)
                                    .limit(100)
                            }
                            else {
                                enquiryQuery = enquiryQuery
                                    .limit(100)
                            }
                        }
                        else {
                            if (lastRecord.length > 0) {
                                enquiryQuery = enquiryQuery
                                    .startAfter(lastRecord[0].lastDoc)
                                    .limit(pageLimit)
                            }
                            else {
                                enquiryQuery = enquiryQuery
                                    .limit(pageLimit)
                            }

                        }

                    }
                    else {
                        enquiryQuery = firestoreDB(dealersettings).firestore().collection('enquiries')
                            //.where('clientID', '==', clientid)
                            .where('isDeleted', '==', false)

                        if (searchKeywords) {
                            let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
                            if (formattedSearchText.trim())
                                enquiryQuery = enquiryQuery.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
                        }

                        if (_.isArray(dealersettings.accessSalesType) && dealersettings.accessSalesType.length > 0 && pipelineView && pipelineView === 'INBOUND' && localStorage.defaultModule !== 'oem') {
                            enquiryQuery = enquiryQuery.where('requirement.saleType', 'in', CommonHelper.arrLimit(dealersettings.accessSalesType, 9))
                        }
                        else if (!_.isEmpty(excludeOrigins))
                            enquiryQuery = enquiryQuery.where('origin', 'in', CommonHelper.getDataExcluded(dealersettings.client?.settings?.origins, excludeOrigins, true))

                        if (localStorage.defaultModule === 'oem' && pipelineView && pipelineView === 'INBOUND') {
                            enquiryQuery = CommonHelper.whereClientOrGroupOrRegionOrOemClause(enquiryQuery, dealersettings, pipelineView, true);

                            if (_dpl === 'LeadsBucket' && _rec === 'Converted') {
                                enquiryQuery = enquiryQuery
                                    .where('oemStatus', '==', 'converted')
                                    .orderBy('stageDate', 'desc');
                            }
                            else {
                                enquiryQuery = enquiryQuery
                                    .where('oemStatus', '==', 'pending')
                                    .where('pipeline', '==', _dpl)
                                    .where('stage', '==', _rec)
                                    .orderBy('stageDate', 'desc');
                            }

                        }
                        else {
                            if (_dpl === 'LeadsBucket' && _rec === 'Converted') {
                                enquiryQuery = CommonHelper.whereClientOrGroupOrRegionOrOemClauseForConverted(enquiryQuery, dealersettings, pipelineView);
                            }
                            else {
                                enquiryQuery = CommonHelper.whereClientOrGroupOrRegionOrOemClause(enquiryQuery, dealersettings, pipelineView, true);

                                if (!viewOtherEnquiry)
                                    enquiryQuery = enquiryQuery.where('owner', '==', localStorage.uid)
                                // else if (!_.isEmpty(searchOwners))
                                //     enquiryQuery = enquiryQuery.where('owner', 'in', searchOwners)

                                enquiryQuery = enquiryQuery
                                    .where('pipeline', '==', _dpl)
                                    .where('stage', '==', _rec)
                                    .orderBy('stageDate', 'desc');

                            }
                        }


                        if (lastRecord.length > 0) {
                            enquiryQuery = enquiryQuery
                                .startAfter(lastRecord[0].lastDoc)
                                .limit(pageLimit)
                        }
                        else {
                            enquiryQuery = enquiryQuery
                                .limit(pageLimit)

                        }
                    }
                    //pipelinePromise.push(collection(enquiryQuery))
                    window['unsub' + _dpl + _rec] = enquiryQuery.onSnapshot((query) => {
                        if (indez === 0) {
                            this.setState({
                                isLoading: false,
                            })
                        }
                        this.onCollectionUpdate(query, _dpl, _rec);
                    })
                }

            });
            // if (pipelinePromise.length > 0) {
            //     window.unsubpipelinestages = combineLatest(pipelinePromise).pipe(
            //         map((logs) => {
            //             return logs;
            //         }))
            //         .subscribe((allEnquiries) => {
            //             allEnquiries.forEach((docs, index) => {
            //                 this.onCollectionUpdate(docs, _dpl, columns[index]);
            //             })
            //             // allActivities.forEach(r => {
            //             //     r.forEach(d => {
            //             //         if (!_.isEmpty(d))
            //             //             _activities.push(d)
            //             //     })
            //             // });
            //             // if (_activities.filter(e => e.type === CommonHelper.getActivityType('log') && e.subType === 'email' && !_.isEmpty(e.thread)).length === 0) {
            //             //     setLoadingActivity(false)
            //             //     dispatch({
            //             //         type: "SUCCESS",
            //             //         data: _activities
            //             //     });
            //             // }
            //             // else {
            //             //     setTempData(_activities)
            //             // }

            //         });
            // }

            // if (_hasMore || (isFilterApplied && _pageNum === 0)) {
            //     _pageNum = (pageNum + 1);
            //     this.setState({ pageNum: _pageNum })
            // }
            //})
            if (Object.keys(enquiries).length === 0) {
                enquiries['tasks'] = {};
                let cols = {}
                let _selectedPipeline = pipeline.filter(item => (pipelineView && pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true))[0];

                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    enquiries.tasks && Object.keys(enquiries.tasks).map(function (el) {
                        if (enquiries.tasks[el].stage === col.value) {
                            leadsids.push(enquiries.tasks[el].id)
                        }
                    })
                    cols[col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids
                    }
                })
                enquiries['columns'] = cols
            }

            this.setState({
                isLoading: false,
                checkLoad: {
                    ...this.state.checkLoad,
                    [_dpl]: true
                }
            }, () => {
                if (_isPaging) {
                    document.querySelector(".pipeline-panel").scrollTo({
                        top: document.querySelector(".pipeline-panel").scrollHeight,
                        behavior: "smooth"
                    });
                }
            })
        }
        catch (error) {
            this.setState({
                isLoading: false,
                stageLoading: ''
            })
            console.error(error)
        }
    }

    getPipelineCount = (viewOtherEnquiry, pipeline) => {
        const { pipelineView, dealersettings } = this.props;
        let accessSalesType = [];
        const excludeOrigins = dealersettings?.rolePermissions?.excludeOrigins || [];
        let querySet = `clients/${this.props.dealersettings.client.id}/counts/${this.props.dealersettings.client.id}`
        if (dealersettings && localStorage.defaultModule === 'oem' && (pipelineView ? pipelineView === 'INBOUND' : true)) {
            if (dealersettings.level === 'oem' && dealersettings.oemID)
                querySet = `clientSettings/${dealersettings.oemID}/counts/${dealersettings.oemID}`
            else if (dealersettings.level === 'group' && dealersettings.groupID)
                querySet = `clientSettings/${dealersettings.oemID}/groups/${dealersettings.groupID}/counts/${dealersettings.groupID}`
            else if (dealersettings.level === 'region' && dealersettings.regionID)
                querySet = `clientSettings/${dealersettings.oemID}/regions/${dealersettings.regionID}/counts/${dealersettings.regionID}`
        }

        if (!viewOtherEnquiry && localStorage.defaultModule !== 'oem') {
            querySet = `users/${localStorage.uid}/userClients/${this.props.dealersettings.client.id}`
            // if (dealersettings && localStorage.defaultModule === 'oem' && (pipelineView ? pipelineView === 'INBOUND' : true)) {
            //     if (dealersettings.level === 'oem' && dealersettings.oemID)
            //         querySet = `users/${localStorage.uid}/counts/oem`
            //     else if (dealersettings.level === 'group' && dealersettings.groupID)
            //         querySet = `users/${localStorage.uid}/counts/group`
            //     else if (dealersettings.level === 'region' && dealersettings.regionID)
            //         querySet = `users/${localStorage.uid}/counts/region`
            // }
        }
        if (localStorage.defaultModule !== 'oem' && pipelineView === 'INBOUND') {
            accessSalesType = Object.assign([], dealersettings.accessSalesType);
            querySet = `clients/${this.props.dealersettings.client.id}/counts/${this.props.dealersettings.client.id}`;
        }
        window.unSubPipelineCount = window.firebase.firestore().doc(querySet)
            .onSnapshot(docSnapshot => {
                var stageCount = {};

                if (pipelineView !== 'INBOUND' && docSnapshot.exists && docSnapshot?.data()?.sales?.[pipeline]?.stages) {
                    let _selectedPipeline = this.state.pipeline.find(item => (pipelineView && pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true));
                    _selectedPipeline && _selectedPipeline.stages.forEach(stage => {
                        stageCount = pipeline === 'LeadsBucket' && (stage.value === 'Converted' || stage.value === 'InboundLead') ? {
                            ...stageCount, [stage.value]: { ['total']: docSnapshot.data().sales["LeadsBucket"].stages[stage.value] }
                        } : {
                            ...stageCount,
                            [stage.value]: {
                                ...this.statusObj(docSnapshot.data().sales[pipeline].stages[stage.value], excludeOrigins),
                                ['total']: this.sumObj(docSnapshot.data().sales[pipeline].stages[stage.value], null, null, excludeOrigins)
                            }
                        }
                    })
                }
                else if (docSnapshot.exists && docSnapshot.data() && docSnapshot.data().inbound && docSnapshot.data().inbound[pipeline] && docSnapshot.data().inbound[pipeline].stages) {
                    let _selectedPipeline = this.state.pipeline.find(item => (pipelineView && pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true));
                    _selectedPipeline && _selectedPipeline.stages.forEach(stage => {
                        stageCount = {
                            ...stageCount,
                            [stage.value]: {
                                ...docSnapshot.data().inbound[pipeline].stages[stage.value],
                                ['total']: this.sumObj(docSnapshot.data().inbound[pipeline].stages[stage.value], accessSalesType, stage.value, excludeOrigins)
                            }
                        }
                    })
                }
                this.setState({
                    stageCounts: stageCount
                })
            })
    }

    sumObj = (obj, arrSaleType, stage, excludeOrigins) => {
        var sum = 0;
        //if (stage === 'InboundLead' && !_.isEmpty(arrSaleType)) {
        //    arrSaleType = [...arrSaleType, 'na'];
        //}
        for (var el in obj) {
            if (_.isNumber(obj[el]) && (_.isEmpty(arrSaleType) || arrSaleType.some(a => a === el))) {
                sum += parseFloat(obj[el]);
            }
        }
        if (obj?.origins && excludeOrigins?.length) {
            for (var el in obj.origins) {
                if (JSON.parse(obj.origins[el])?.length) {
                    JSON.parse(obj.origins[el]).forEach(rec => {
                        excludeOrigins.forEach(ex => {
                            if (_.isNumber(rec[ex])) {
                                sum -= parseFloat(rec[ex]);
                            }
                        })
                    })
                }
            }
        }
        return sum;
    }

    statusObj = (obj, excludeOrigins) => {
        var _obj = obj;

        for (var el in obj) {
            if (_.isNumber(obj[el]) && obj?.origins && excludeOrigins?.length) {
                if (JSON.parse(obj.origins[el])?.length) {
                    JSON.parse(obj.origins[el]).forEach(rec => {
                        excludeOrigins.forEach(ex => {
                            if (_.isNumber(rec[ex])) {
                                _obj = {
                                    ..._obj,
                                    [el]: _obj[el] - parseFloat(rec[ex])
                                }
                            }
                        })
                    })
                }
            }
        }

        return _obj;
    }

    onCollectionUpdate = (querySnapshot, _pipeline, _stage) => {
        //console.log('onCollectionUpdate', querySnapshot, _stage)
        const { pipelineView, dealersettings } = this.props;
        let snapshotDoc;

        querySnapshot.docChanges().forEach(async (change) => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                //console.log(change)
                let _data = change.doc.data()
                let _defaultPipeline = _data.pipeline;
                let _defaultStage = _data.stage;
                if (_.isEmpty(_defaultPipeline) && (_data.isConvertedGroup || _data.isConvertedOEM || _data.isConvertedRegion)) {
                    _defaultPipeline = 'LeadsBucket';
                }
                // THAHA CODE FOR OEM
                // if (localStorage.defaultModule === 'oem' && ((dealersettings.level === 'oem' && Boolean(_data.isConvertedOEM)) ||
                //     (dealersettings.level === 'region' && Boolean(_data.isConvertedRegion)) ||
                //     (dealersettings.level === 'group' && Boolean(_data.isConvertedGroup)))) {
                //     _defaultStage = 'Converted';
                // }
                if (localStorage.defaultModule === 'oem' && (_data.clientID && _data.oemStatus === 'converted' &&
                    ((dealersettings.level === 'oem') || (dealersettings.level === 'region') || (dealersettings.level === 'group')))) {
                    _defaultStage = 'Converted';
                }


                snapshotDoc = this.convertEnquiryVM({
                    ...change.doc.data(),
                    documentID: change.doc.id,
                    pipeline: _defaultPipeline,
                    stageValue: _data.stage,
                    stage: _defaultStage
                });
                if (change.type === 'removed') {
                    const enqDoc = await firestoreDB(dealersettings).firestore().doc(`enquiries/${change.doc.id}`).get();
                    if (enqDoc.exists && localStorage.defaultModule !== 'oem' && enqDoc.data().clientID === dealersettings.client.id) {
                        snapshotDoc = this.convertEnquiryVM({ ...enqDoc.data(), documentID: enqDoc.id });
                    }
                    else if (enqDoc.exists && ((dealersettings.level === 'oem' && enqDoc.data().oemID === dealersettings.oemID) ||
                        (dealersettings.level === 'region' && enqDoc.data().regionID === dealersettings.regionID) ||
                        (dealersettings.level === 'group' && enqDoc.data().groupID === dealersettings.groupID))) {
                        snapshotDoc = this.convertEnquiryVM({
                            ...enqDoc.data(),
                            documentID: enqDoc.id,
                            pipeline: _defaultPipeline,
                            stageValue: enqDoc.data().stage,
                            stage: _defaultStage === 'Converted' ? _defaultStage : enqDoc.data().stage
                        });
                    }
                    else {
                        snapshotDoc.isDeleted = true;
                    }
                }
                //thaha - special condition to remove lead from inbound stage after convert.
                if (change.type === 'removed' && snapshotDoc.pipeline === 'LeadsBucket' && snapshotDoc.stage === 'InboundLead') {
                    const { enquiries } = this.state;
                    delete enquiries['tasks'][change.doc.id]
                    this.setState({ enquiries })
                }
                else {
                    this.updateEnquiry(snapshotDoc);
                }
            }
        })
        if (_.isEmpty(snapshotDoc)) {
            const { hasMore, pageLimit, pipeline } = this.state;
            let enquiries = this.state.enquiries
            let result = {}
            let hasMoreData = []
            let _selectedPipeline = pipeline.find(item => (pipelineView && pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true))
            let _dpl = _selectedPipeline ? _selectedPipeline.value : '';
            var _dplStage = '';
            if (querySnapshot.docs.length > 0) {
                // THAHA CODE FOR OEM
                // _dplStage = (
                //     _dpl === 'LeadsBucket' &&
                //     (
                //         (localStorage.defaultModule !== 'oem' && querySnapshot.docs[0].data().isConverted === true && !_.isEmpty(querySnapshot.docs[0].data().convertedDate)) ||
                //         (localStorage.defaultModule === 'oem' && dealersettings.level === 'group' && querySnapshot.docs[0].data().isConvertedGroup === true && !_.isEmpty(querySnapshot.docs[0].data().convertedDateGroup)) ||
                //         (localStorage.defaultModule === 'oem' && dealersettings.level === 'oem' && querySnapshot.docs[0].data().isConvertedOEM === true && !_.isEmpty(querySnapshot.docs[0].data().convertedDateOEM)) ||
                //         (localStorage.defaultModule === 'oem' && dealersettings.level === 'region' && querySnapshot.docs[0].data().isConvertedRegion === true && !_.isEmpty(querySnapshot.docs[0].data().convertedDateRegion))
                //     )
                // ) ? 'LeadsBucketConverted' : _dpl + (querySnapshot.docs[0].data().stage ? querySnapshot.docs[0].data().stage : 'InboundLead');
                if (localStorage.defaultModule === 'oem' && _pipeline && _stage)
                    _dplStage = _pipeline + _stage;
                else
                    _dplStage = (
                        _dpl === 'LeadsBucket' &&
                        (
                            (localStorage.defaultModule !== 'oem' && querySnapshot.docs[0].data().isConverted === true && !_.isEmpty(querySnapshot.docs[0].data().convertedDate)) ||
                            (localStorage.defaultModule === 'oem' && dealersettings.level === 'group' && querySnapshot.docs[0].data().oemStatus === 'converted' && !_.isEmpty(querySnapshot.docs[0].data().clientID)) ||
                            (localStorage.defaultModule === 'oem' && dealersettings.level === 'oem' && querySnapshot.docs[0].data().oemStatus === 'converted' && !_.isEmpty(querySnapshot.docs[0].data().clientID)) ||
                            (localStorage.defaultModule === 'oem' && dealersettings.level === 'region' && querySnapshot.docs[0].data().oemStatus === 'converted' && !_.isEmpty(querySnapshot.docs[0].data().clientID))
                        )
                    ) ? 'LeadsBucketConverted' : _dpl + (querySnapshot.docs[0].data().stage ? querySnapshot.docs[0].data().stage : 'InboundLead');
            }
            if (this.state.stageLoading) this.setState({ stageLoading: '' })
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                hasMoreData = [...hasMoreData.filter(e => e.Stage !== _dplStage), {
                    Stage: _dplStage,
                    docID: querySnapshot.docs[querySnapshot.docs.length - 1].data().documentID,
                    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1]
                }]
            }
            querySnapshot.forEach(doc => {
                let _data = doc.data()
                let _defaultPipeline = _data.pipeline;
                let _defaultStage = _data.stage;
                if (_.isEmpty(_defaultPipeline) && (_data.isConvertedGroup || _data.isConvertedOEM || _data.isConvertedRegion)) {
                    _defaultPipeline = 'LeadsBucket';
                }
                // THAHA CODE FOR OEM
                // if (localStorage.defaultModule === 'oem' && ((dealersettings.level === 'oem' && Boolean(_data.isConvertedOEM)) ||
                //     (dealersettings.level === 'region' && Boolean(_data.isConvertedRegion)) ||
                //     (dealersettings.level === 'group' && Boolean(_data.isConvertedGroup)))) {
                //     _defaultStage = 'Converted';
                // }

                if (localStorage.defaultModule === 'oem' && (_data.clientID && _data.oemStatus === 'converted' &&
                    ((dealersettings.level === 'oem') || (dealersettings.level === 'region') || (dealersettings.level === 'group')))) {
                    _defaultStage = 'Converted';
                }



                const _enq = {
                    ...doc.data(),
                    documentID: doc.id,
                    pipeline: _defaultPipeline,
                    stageValue: doc.data().stage,
                    stage: _defaultStage
                }
                result[doc.id] = this.convertEnquiryVM(_enq)
                if (enquiries.tasks) {
                    enquiries['tasks'][doc.id] = { ...result[doc.id] }

                } else {
                    enquiries['tasks'] = result
                }

                let cols = {}
                if (enquiries.columns) {
                    cols = enquiries.columns
                }

                let _selectedPipeline = pipeline.filter(item => (pipelineView && pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true))[0];
                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    enquiries.tasks && Object.keys(enquiries.tasks).map(function (el) {
                        // THAHA CODE FOR OEM
                        if ((localStorage.defaultModule === 'oem' && enquiries.tasks[el].oemStatus === 'converted' && enquiries.tasks[el].clientID &&
                            ((dealersettings.level === 'oem') || (dealersettings.level === 'region') || (dealersettings.level === 'group')
                            )) && enquiries.tasks[el].stage === col.value && enquiries.tasks[el].pipeline === _dpl) {
                            leadsids.push(enquiries.tasks[el].id)
                            if (col.rottenDays) {
                                var enqage = enquiries.tasks[el].modifiedDate ? moment().diff(moment.unix(enquiries.tasks[el].modifiedDate.seconds), 'days') : 0
                                enquiries.tasks[el].rottendays = enqage - col.rottenDays
                            }
                        }
                        else if (localStorage.defaultModule !== 'oem' && enquiries.tasks[el].stage === col.value && enquiries.tasks[el].pipeline === _dpl) {
                            leadsids.push(enquiries.tasks[el].id)
                            if (col.rottenDays) {
                                var enqage = enquiries.tasks[el].modifiedDate ? moment().diff(moment.unix(enquiries.tasks[el].modifiedDate.seconds), 'days') : 0
                                enquiries.tasks[el].rottendays = enqage - col.rottenDays
                            }
                        }
                        else if (( // THAHA CODE FOR OEM
                            (localStorage.defaultModule !== 'oem' && enquiries.tasks[el].isConverted === true) ||
                            (enquiries.tasks[el].oemStatus === 'converted' && enquiries.tasks[el].clientID &&
                                ((dealersettings.level === 'region') || (dealersettings.level === 'oem') || (dealersettings.level === 'group')))
                        ) && col.value === 'Converted') {
                            leadsids.push(enquiries.tasks[el].id)
                        }
                    })
                    cols[_dpl + col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids,
                        pipeline: _dpl
                    }
                })
                enquiries['columns'] = cols
            })
            if (querySnapshot.docs.length === 0) {
                let cols = {}
                if (enquiries.columns) {
                    cols = enquiries.columns
                }
                let _selectedPipeline = pipeline.filter(item => (pipelineView && pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true))[0];
                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    enquiries.tasks && Object.keys(enquiries.tasks).map(function (el) {
                        if (enquiries.tasks[el].stage === col.value) {
                            leadsids.push(enquiries.tasks[el].id)
                        }
                    })
                    cols[_dpl + col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids,
                        pipeline: _dpl
                    }
                })
                enquiries['columns'] = cols
            }

            this.setState({
                enquiries: enquiries,
                hasMore: hasMoreData[0] ? [...hasMore.filter(item => item.Stage !== _dplStage), hasMoreData[0]] :
                    (querySnapshot.docs.length > 0 ? [...hasMore.filter(item => item.Stage !== _dplStage)] : [...hasMore])

            })
        }
    }

    onCollectionEnquiry = (querySnapshot, _stage) => {
        //console.log('onCollectionEnquiry', _stage, querySnapshot)
        let snapshotDoc;
        const { pipelineView, dealersettings } = this.props;
        if (_.isEmpty(snapshotDoc)) {
            const { hasMore, pageLimit, pipeline } = this.state;
            let enquiries = this.state.enquiries
            let result = {}
            let hasMoreData = []
            let _selectedPipeline = pipeline.find(item => (pipelineView && pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true))
            let _dpl = _selectedPipeline ? _selectedPipeline.value : '';
            if (this.state.stageLoading) this.setState({ stageLoading: '' })
            if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
                hasMoreData.push({
                    Stage: _dpl + querySnapshot.data[0].stage,
                    lastDoc: querySnapshot.data[querySnapshot.data.length - 1]
                })
            }
            querySnapshot.data.forEach(doc => {

                let _defaultStage = doc.stage;

                if (localStorage.defaultModule === 'oem' && (doc.clientID && doc.oemStatus === 'converted' &&
                    ((dealersettings.level === 'oem') || (dealersettings.level === 'region') || (dealersettings.level === 'group')))) {
                    _defaultStage = 'Converted';
                }

                const _enq = {
                    ...doc,
                    stageValue: doc.stage,
                    stage: _defaultStage
                }

                result[doc.documentID] = this.convertEnquiryVM(_enq)
                if (enquiries.tasks) {
                    enquiries['tasks'][doc.documentID] = { ...result[doc.documentID] }

                } else {
                    enquiries['tasks'] = result
                }

                let cols = {}
                if (enquiries.columns) {
                    cols = enquiries.columns
                }
                let _selectedPipeline = pipeline.filter(item => (pipelineView && pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true))[0];
                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    enquiries.tasks && Object.keys(enquiries.tasks).map(function (el) {
                        if ((localStorage.defaultModule === 'oem' && enquiries.tasks[el].oemStatus === 'converted' && enquiries.tasks[el].clientID &&
                            ((dealersettings.level === 'oem') || (dealersettings.level === 'region') || (dealersettings.level === 'group')
                            )) && enquiries.tasks[el].stage === col.value && enquiries.tasks[el].pipeline === _dpl) {
                            leadsids.push(enquiries.tasks[el].id)
                            if (col.rottenDays) {
                                var enqage = enquiries.tasks[el].modifiedDate ? moment().diff(moment.unix(enquiries.tasks[el].modifiedDate.seconds), 'days') : 0
                                enquiries.tasks[el].rottendays = enqage - col.rottenDays
                            }
                        }
                        else if (localStorage.defaultModule !== 'oem' && enquiries.tasks[el].stage === col.value && enquiries.tasks[el].pipeline === _dpl) {
                            leadsids.push(enquiries.tasks[el].id)
                            if (col.rottenDays) {
                                var enqage = enquiries.tasks[el].modifiedDate ? moment().diff(moment.unix(enquiries.tasks[el].modifiedDate.seconds), 'days') : 0
                                enquiries.tasks[el].rottendays = enqage - col.rottenDays
                            }
                        }
                        else if (( // THAHA CODE FOR OEM
                            (localStorage.defaultModule !== 'oem' && enquiries.tasks[el].isConverted === true) ||
                            (enquiries.tasks[el].oemStatus === 'converted' && enquiries.tasks[el].clientID &&
                                ((dealersettings.level === 'region') || (dealersettings.level === 'oem') || (dealersettings.level === 'group')))
                        ) && col.value === 'Converted') {
                            leadsids.push(enquiries.tasks[el].id)
                        }

                    })
                    cols[_dpl + col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids,
                        pipeline: _dpl
                    }
                })
                enquiries['columns'] = cols
            })
            if (querySnapshot.data.length === 0) {
                let cols = {}
                if (enquiries.columns) {
                    cols = enquiries.columns
                }
                let _selectedPipeline = pipeline.filter(item => (pipelineView && pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true))[0];
                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    enquiries.tasks && Object.keys(enquiries.tasks).map(function (el) {
                        if (enquiries.tasks[el].stage === col.value) {
                            leadsids.push(enquiries.tasks[el].id)
                        }
                    })
                    cols[_dpl + col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids,
                        pipeline: _dpl
                    }
                })
                enquiries['columns'] = cols
            }

            this.setState({
                //isLoading: columns[columns.length - 1] === _stage ? false : true,
                enquiries: enquiries,
                hasMore: hasMoreData[0] ? [...hasMore.filter(item => item.Stage !== _dpl + querySnapshot.data[0].stage), hasMoreData[0]] : [...hasMore.filter(item => item.Stage !== _dpl + _stage)]

            })
        }
    }


    convertEnquiryVM = (_enq) => {

        _enq.stageDate = _enq.stageDate && _enq.stageDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.stageDate._seconds)._d) : _enq.stageDate;
        _enq.convertedDate = _enq.convertedDate && _enq.convertedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.convertedDate._seconds)._d) : _enq.convertedDate;
        _enq.addedDate = _enq.addedDate && _enq.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.addedDate._seconds)._d) : _enq.addedDate;
        _enq.modifiedDate = _enq.modifiedDate && _enq.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.modifiedDate._seconds)._d) : _enq.modifiedDate;
        _enq.verifiedDate = _enq.verifiedDate && _enq.verifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.verifiedDate._seconds)._d) : _enq.verifiedDate;

        _enq.lostDate = _enq.lostDate && _enq.lostDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.lostDate._seconds)._d) : _enq.lostDate;
        _enq.deliveredDate = _enq.deliveredDate && _enq.deliveredDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.deliveredDate._seconds)._d) : _enq.deliveredDate;
        _enq.wonDate = _enq.wonDate && _enq.wonDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.wonDate._seconds)._d) : _enq.wonDate;
        _enq.nextActivityDate = _enq?.nextActivity?.startDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq?.nextActivity?.startDate?._seconds)._d) : _enq?.nextActivity?.startDate;

        _enq.stageHistory = [..._.map(_enq.stageHistory, function (obj) {
            return obj.date && obj.date._seconds ? { ...obj, 'date': window.firebase.firestore.Timestamp.fromDate(moment.unix(obj.date._seconds)._d) } : obj;
        })]

        const { pipeline } = this.state;
        const { dealersettings, clientUsers, pipelineView, groupUsers } = this.props;
        _enq.saleType = _enq?.requirement?.saleType;
        let enqTileColor = null;
        if (_enq?.requirement?.saleType) {
            const settings = dealersettings.client.settings.salesType?.find(a => a.value === _enq?.requirement?.saleType)
            const make = _enq?.requirement?.stock?.make ? _enq?.requirement?.stock?.make.toLowerCase().trim() : _enq?.requirement?.make ? _enq?.requirement?.make.toLowerCase().trim() : '';

            if (settings?.colors?.find(a => a.make.toLowerCase().trim() === make)) {
                enqTileColor = settings?.colors?.find(a => a.make.toLowerCase().trim() === make).color;

            }
            else if (settings?.colors?.find(a => a.make.toLowerCase().trim() === 'others')) {
                enqTileColor = settings?.colors?.find(a => a.make.toLowerCase().trim() === 'others').color;

            }
        }

        if (_.isEmpty(enqTileColor) && _enq.origin && _.find(Object.assign([], dealersettings.client?.settings?.origins), { value: _enq.origin })?.color)
            enqTileColor = _.find(dealersettings.client.settings.origins, { value: _enq.origin })?.color

        var rottenDays = ''
        let _selectedPipeline = pipeline.filter(item => (pipelineView && pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true))[0];
        _selectedPipeline && _selectedPipeline.stages.forEach(col => {
            if (_enq.stage === col.value) {
                if (col.rottenDays) {
                    var enqage = _enq.modifiedDate ? moment().diff(moment.unix(_enq.modifiedDate.seconds), 'days') : 0
                    rottenDays = enqage - col.rottenDays + 'Days'
                }
            }
        })

        const objData = Object.assign({}, _enq);
        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === Object.assign([]) || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        let _owner = _enq.pipeline === 'LeadsBucket' && _enq.ownerInbound ? _enq.ownerInbound : _enq.owner;

        let _displayDate = null;//_enq.addedDate
        let _displayDateTitle = null;//'created'
        let _displayColor = ''

        const enquiryAllStatus = (this.props.dealersettings?.client?.settings.enquiryStatus ? this.props.dealersettings.client.settings.enquiryStatus : [])
        const isStatusSold = CommonHelper.isEnqStatusSold(enquiryAllStatus, _enq.status)
        if ((_enq.status === enquiryStatus.LOST || _enq.status === enquiryStatus.PROSPECTLOST || _enq.status === enquiryStatus.PENDINGLOST) && !_.isEmpty(_enq.lostDate)) {
            _displayDate = _enq.lostDate
            _displayDateTitle = `${CommonHelper.getNameByValue(enquiryAllStatus, 'lost', 'Lost')} ${_enq.lostDate ? `Date: ${moment.unix(_enq.lostDate.seconds).format('DD MMM YYYY h:mm A')}` : ' Age'}`;
            _displayColor = CommonHelper.getFieldByValue(enquiryAllStatus, 'lost', 'color');
        }
        else if (_enq.status === enquiryStatus.DELIEVERD && !_.isEmpty(_enq.deliveredDate)) {
            _displayDate = _enq.deliveredDate
            _displayDateTitle = `${CommonHelper.getNameByValue(enquiryAllStatus, 'delivered', 'Delivered')} ${_enq.deliveredDate ? `Date: ${moment.unix(_enq.deliveredDate.seconds).format('DD MMM YYYY h:mm A')}` : ' Age'}`;
            _displayColor = CommonHelper.getFieldByValue(enquiryAllStatus, 'delivered', 'color');
        }
        else if ((isStatusSold || _enq.status === enquiryStatus.CLOSED) && !_.isEmpty(_enq.wonDate)) {
            _displayDate = _enq.wonDate
            _displayDateTitle = `${CommonHelper.getNameByValue(enquiryAllStatus, 'won', 'Won')} ${_enq.wonDate ? `Date: ${moment.unix(_enq.wonDate.seconds).format('DD MMM YYYY h:mm A')}` : ' Age'}`;
            _displayColor = CommonHelper.getFieldByValue(enquiryAllStatus, 'won', 'color');
        }
        let counts = {
            today: 0,
            overdue: 0,
            upcoming: 0
        }
        if (_enq.nextActivityDate) {
            if (moment.unix(_enq.nextActivityDate.seconds) < moment().startOf('day')) {
                counts = {
                    ...counts,
                    overdue: 1
                }
            }
            else if (moment.unix(_enq.nextActivityDate.seconds) > moment().endOf('day')) {
                counts = {
                    ...counts,
                    upcoming: 1
                }
            }
            else {
                counts = {
                    ...counts,
                    today: 1
                }
            }
        }

        const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers

        let _objData = {
            id: _enq.documentID,
            displayID: (_enq.pipeline === 'LeadsBucket' && _enq.stage === 'InboundLead') ? '' : _enq.displayID ? _enq.displayID : 'ENQ-' + _enq.documentID,
            contactID: _enq.contact ? _enq.contact.documentID : '',
            name: _enq.contact ? CommonHelper.displayContactName([], _enq.contact) : '',
            businessContact: _enq.contact && _enq.contact.businessContact ? true : false,
            contactType: _enq.contact && _enq.contact.contactType ? _enq.contact.contactType : null,
            phone: (_enq.contact && _enq.contact.phone) ? _enq.contact.phone : '',
            phoneCode: (_enq.contact && _enq.contact.phoneCode ? (_enq.contact.phoneCode) : ''),
            email: _enq.contact ? (_enq.contact.email) : '',
            vehiclemodel: !_.isEmpty(_enq.requirement) ? (!_.isEmpty(_enq.requirement.stock) && !_.isEmpty(_enq.requirement.stock.make)) ? (!_.isEmpty(_enq.requirement.stock.make) ? (_enq.requirement.stock.make + ' ' + _enq.requirement.stock.model) : '') : (!_.isEmpty(_enq.requirement.make) ? (_enq.requirement.make + ' ' + _enq.requirement.model) : '') : '',
            stockNo: (_enq.pipeline === 'LeadsBucket' && _enq.stage === 'InboundLead') ? _enq?.requirement?.stockNo : null,
            saletype: !_.isEmpty(_enq.requirement) ? (_enq.requirement.saleType) : '',
            enqTileColor: enqTileColor,
            stage: _enq.stage,
            stageValue: _enq.stageValue ? _enq.stageValue : _enq.stage,
            status: (_enq.pipeline === 'LeadsBucket' && _enq.stage === 'InboundLead') ? (_enq.status ? inboundLeadStatus[_enq.status] : inboundLeadStatus[_enq.status]) : _enq.status,
            oemStatus: _enq.oemStatus,
            pipeline: _enq.pipeline,
            label: _enq.label,
            isFav: _enq.isFav,
            ownerId: _owner,
            financeBMId: _enq.financeBM,
            afterMarketBMId: _enq.afterMarketBM,
            origin: !_.isEmpty(this.props.dealersettings.client.settings.origins) && _.find(this.props.dealersettings.client.settings.origins, { 'value': _enq.origin }) ? _.find(this.props.dealersettings.client.settings.origins, { 'value': _enq.origin }).name : '',
            owner: CommonHelper.getUserNamebyId(allUsers, _owner),
            financeBMName: CommonHelper.getUserNamebyId(allUsers, _enq.financeBM),
            afterMarketBMName: CommonHelper.getUserNamebyId(allUsers, _enq.afterMarketBM),
            addedby: CommonHelper.getUserNamebyId(allUsers, _enq.addedBy),
            modifiedby: CommonHelper.getUserNamebyId(allUsers, _enq.modifiedBy),
            enquiryage: (_enq.addedDate ? (moment.unix(_enq.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? 'today' : moment.unix(_enq.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '--'),
            stageHistory: _enq.stageHistory,
            stageDate: _enq.stageDate ? _enq.stageDate : _enq.addedDate,
            modifiedDate: _enq.modifiedDate,
            counts: counts,
            rottenDays: rottenDays,
            isDeleted: _enq.isDeleted,
            isNewEnquiry: _enq.isNewEnquiry,
            isConverted: _enq.isConverted,
            convertedDate: (_enq.isConverted === true && !_enq.convertedDate) ? _enq.stageDate : _enq.convertedDate,
            addedDate: _enq.addedDate,
            verifiedBy: _enq.verifiedBy,
            verifiedDate: _enq.verifiedDate,
            enqFields: objData,
            isFinanceRequired: _enq.isFinanceRequired,
            financeStatus: _enq.financeStatus,
            afterMarketStatus: _enq.afterMarketStatus,
            tags: (_enq.pipeline === 'LeadsBucket' && _enq.stage === 'InboundLead') ? [{ type: _enq.systemType, subType: _enq.subType }] : _enq.tags,
            clientID: _enq.clientID ? _enq.clientID : null,
            oemID: _enq.oemID ? _enq.oemID : null,
            groupID: _enq.groupID ? _enq.groupID : null,
            regionID: _enq.regionID ? _enq.regionID : null,
            transferHistory: _enq.transferHistory,
            isConvertedGroup: _enq.isConvertedGroup,
            convertedDateGroup: (_enq.isConvertedGroup === true && !_enq.convertedDateGroup) ? _enq.stageDate : _enq.convertedDateGroup,
            isConvertedOEM: _enq.isConvertedOEM,
            convertedDateOEM: (_enq.isConvertedOEM === true && !_enq.convertedDateOEM) ? _enq.stageDate : _enq.convertedDateOEM,
            isConvertedRegion: _enq.isConvertedRegion,
            convertedDateRegion: (_enq.isConvertedRegion === true && !_enq.convertedDateRegion) ? _enq.stageDate : _enq.convertedDateRegion,
            displayColor: _displayColor,
            displayAgeTxt: CommonHelper.showLocale(this.props, _displayDateTitle),
            displayAge: (_displayDate ? (moment.unix(_displayDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? 'today' : moment.unix(_displayDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : ''),
        }

        return this.mapDynamicData(_objData, objData);
    }

    mapDynamicData = (_fields, doc) => {

        let __enquiryOptionsDF = !_.isEmpty(enquiryOptionsDF) ? enquiryOptionsDF : this.props.dealersettings.client.settings.enquiryOptionsDF
        if (!_.isEmpty(doc.dynamicFields) && __enquiryOptionsDF) {
            let _dynamicFields = doc.dynamicFields
            __enquiryOptionsDF.filter(e => e.active === true).forEach(rec => {
                if (_.isNumber(_dynamicFields[rec.value]) || _.isBoolean(_dynamicFields[rec.value]) || !_.isEmpty(_dynamicFields[rec.value])) {
                    if (rec.type === 'toggle') {
                        _fields[rec.value] = _dynamicFields[rec.value] === true ? 'Yes' : 'No';
                    }
                    else if (rec.type === 'date') {
                        _dynamicFields[rec.value] = _dynamicFields[rec.value] ? _dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_dynamicFields[rec.value]._seconds)._d) : _dynamicFields[rec.value] : ''
                        _fields[rec.value] = _dynamicFields[rec.value] ? moment.unix(_dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
                    }
                    else if (rec.type === 'multiselect') {
                        _fields[rec.value] = _dynamicFields[rec.value] ? _dynamicFields[rec.value].join(', ') : ''
                    }
                    else {
                        _fields[rec.value] = _dynamicFields[rec.value]
                    }
                }

            })
        }

        return _fields;
    }

    handleEnquiryFav = async (id, val, stage, oldstage, objEnquiry, _fieldName) => {
        //    console.log('handleEnquiryFav', id, val, stage, oldstage, objEnquiry, _fieldName)

        const { enquiries, pipeline } = this.state;
        const { pipelineView, dealersettings } = this.props;

        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

        const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);
        const isDeivered = (objEnquiry.status === enquiryStatus.DELIEVERD || objEnquiry.status === enquiryStatus.LOST || objEnquiry.status === enquiryStatus.PENDINGLOST || objEnquiry.status === enquiryStatus.PROSPECTLOST) ? true : false;
        const canModifyEnquiry = readOnlyEnquiryView ? false : (((!_.isEmpty(objEnquiry) && objEnquiry.ownerId === dealersettings.id) ||
            (!_.isEmpty(_permissions) &&
                _permissions.modifyOtherEnquiry)) ? true : false);

        const viewOtherEnquiry = (pipelineView === 'INBOUND') ? true : (!_.isEmpty(dealersettings) ? (((!_.isEmpty(objEnquiry) && objEnquiry.ownerId === dealersettings.id) ||
            (!_.isEmpty(_permissions) &&
                _permissions.viewOtherEnquiry)) ? true : false) : false);

        const modifyStageAfterDeliveredLost = ((!_.isEmpty(_permissions) && _permissions.modifyStageAfterDeliveredLost) ? true : false);
        if (readOnlyEnquiryView) {
            Swal.fire(CommonHelper.showLocale(this.props, 'Modify access is denied.'), '', 'warning')
            return
        }
        if (!viewOtherEnquiry && localStorage.defaultModule !== 'oem') {
            Swal.fire(CommonHelper.showLocale(this.props, "Modify access is denied."), '', 'warning')
            //console.log('viewOtherEnquiry=>', viewOtherEnquiry, 'isDeivered=>', isDeivered, 'modifyStageAfterDeliveredLost=>', modifyStageAfterDeliveredLost)
            return
        }
        if ((canModifyEnquiry && isDeivered && !modifyStageAfterDeliveredLost) || !canModifyEnquiry) {
            Swal.fire(CommonHelper.showLocale(this.props, 'Modify access is denied.'), '', 'warning')
            //console.log('canModifyEnquiry=>', canModifyEnquiry, 'isDeivered=>', isDeivered, 'modifyStageAfterDeliveredLost=>', modifyStageAfterDeliveredLost)
            return
        }

        let _selectedPipeline = pipeline.find(item => (pipelineView && pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true))
        let _dpl = _selectedPipeline ? _selectedPipeline.value : '';
        if (_dpl === 'LeadsBucket' && stage === 'InboundLead') {
            if (oldstage === stage) {
                firestoreDB(this.props.dealersettings).firestore().doc(`inboundLeads/${id}`).set({
                    isFav: Boolean(val)
                }, { merge: true })
                    .catch(error => {
                        console.error(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    });
            }
            return
        }

        if (oldstage === 'InboundLead' && _dpl === 'LeadsBucket' && (localStorage.defaultModule === 'oem' ? (stage !== 'Converted' && stage !== 'InboundLead') : stage !== 'InboundLead')) {
            //sameer inbound lead popup
            this.handleShowInboundLead(id, stage);
            return
        }
        if (localStorage.defaultModule === 'oem' && oldstage === 'Converted') {
            //sameer inbound lead popup
            Swal.fire(CommonHelper.showLocale(this.props, 'Modify access is denied for this lead.'), '', 'warning')
            return
        }

        if (localStorage.defaultModule === 'oem' && (dealersettings.level === 'oem' || dealersettings.level === 'region' || dealersettings.level === 'group') && _dpl === 'LeadsBucket' && stage === 'Converted') {
            let _selectedEnquiry = (!_.isEmpty(objEnquiry.enqFields) ? objEnquiry.enqFields : objEnquiry);

            if (oldstage !== 'InboundLead' && _selectedEnquiry && _selectedEnquiry.isNewEnquiry) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Convert is denied for new lead'), '', 'warning')
                return
            }

            this.setState({
                showOEMLeadConvert: true,
                convertLeadStage: stage,
                convertLeadId: (!_.isEmpty(objEnquiry.enqFields) && !_.isEmpty(objEnquiry.enqFields.enquiryID) ? objEnquiry.enqFields.enquiryID : (objEnquiry.enquiryID ? objEnquiry.enquiryID : (oldstage !== 'InboundLead' ? objEnquiry.id : null))),
                convertLeadLoader: false,
                selectedEnquiry: _selectedEnquiry
            })
            // if (dealersettings.level === 'oem' && inboundAllocation)
            //     this.setState({ showOEMLeadConvert: true, convertLeadStage: stage, convertLeadId: (!_.isEmpty(objEnquiry.enqFields) && !_.isEmpty(objEnquiry.enqFields.enquiryID) ? objEnquiry.enqFields.enquiryID : (objEnquiry.enquiryID ? objEnquiry.enquiryID : (oldstage !== 'InboundLead' ? objEnquiry.id : null))), convertLeadLoader: false, selectedEnquiry: (!_.isEmpty(objEnquiry.enqFields) ? objEnquiry.enqFields : objEnquiry) })
            // else
            //     this.setState({ showOEMLeadConvertPrev: true, convertLeadStage: stage, convertLeadId: (!_.isEmpty(objEnquiry.enqFields) && !_.isEmpty(objEnquiry.enqFields.enquiryID) ? objEnquiry.enqFields.enquiryID : (objEnquiry.enquiryID ? objEnquiry.enquiryID : (oldstage !== 'InboundLead' ? objEnquiry.id : null))), convertLeadLoader: false, selectedEnquiry: (!_.isEmpty(objEnquiry.enqFields) ? objEnquiry.enqFields : objEnquiry) })
            return
        }
        else if (_dpl === 'LeadsBucket' && stage === 'Converted') {
            this.handleLeadConvertShow(id, objEnquiry);
            return
        }

        objEnquiry.isFav = val;
        var inqUpdate = {
            isFav: Boolean(val),
            stage: stage
        };

        let _selPipeline = !_.isEmpty(dealersettings) && dealersettings.allPipelines.filter(e => e.value === objEnquiry.pipeline)[0];
        let _defaulStages = _selPipeline && _selPipeline.stages
        let statusSettings = _selPipeline && _selPipeline.statusSettings;

        let _statusMndry = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.pipeline === objEnquiry.pipeline && e.stage === stage)[0];
        if (_statusMndry && objEnquiry.status !== _statusMndry.status && _statusMndry.status === enquiryStatus.PROSPECTLOST) {
            inqUpdate.status = enquiryStatus.PROSPECTLOST;
            inqUpdate.lostDate = window.firebase.firestore.Timestamp.now();
            inqUpdate.statusHistory = window.firebase.firestore.FieldValue.arrayUnion(
                {
                    date: window.firebase.firestore.Timestamp.now(),
                    status: inqUpdate.status,
                    user: localStorage.uid
                }
            )
        }
        else if (_statusMndry && !_statusMndry.isModule && objEnquiry.status !== _statusMndry.status) {
            const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])
            let _status = CommonHelper.getNameByValue(enquiryAllStatus, _statusMndry.status)
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                text: `${CommonHelper.showLocale(this.props, 'You want to change enquiry status to')} ${_status.toUpperCase()}`,
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then(result => {
                if (result.value) {
                    this.handleOpenEnquiry(id, _statusMndry.status);
                }
            });
            return;
        }
        let _logNotes = '';
        let _state = (!_.isEmpty(objEnquiry.enqFields) ? objEnquiry.enqFields : objEnquiry)
        if (stage !== oldstage || _dpl !== objEnquiry.pipeline) {
            var _objFav = {
                id: id,
                val: val,
                stage: stage,
                oldstage: oldstage
            }

            if (_.isEmpty(_fieldName) && await this.isValidForStageUpdate(stage, _state, _objFav) === false)
                return

            inqUpdate.stageDate = window.firebase.firestore.Timestamp.now();
            inqUpdate.modifiedBy = localStorage.uid;
            inqUpdate.modifiedDate = window.firebase.firestore.Timestamp.now();
            inqUpdate.modifiedFrom = 'web';
            if (_dpl !== objEnquiry.pipeline && _.isEmpty(_fieldName)) {
                inqUpdate.isConverted = false;
                inqUpdate.convertedDate = null;
                inqUpdate.pipeline = _dpl;
            }
            let newstagehistory = {
                pipeline: _dpl,
                stage: stage,
                date: window.firebase.firestore.Timestamp.now()
            };
            let arrstagehistory = objEnquiry.stageHistory ? objEnquiry.stageHistory : []
            let oldstagehistory = _.filter(arrstagehistory, { stage: oldstage, pipeline: objEnquiry.pipeline })[0];
            if (oldstagehistory) {
                let stindex = _.findIndex(arrstagehistory, { stage: oldstage, pipeline: objEnquiry.pipeline });
                let oldsittingDays = oldstagehistory.sittingDays ? oldstagehistory.sittingDays : 0
                arrstagehistory.splice(stindex, 1,
                    {
                        ...oldstagehistory,
                        sittingDays: oldsittingDays + moment().diff(moment.unix(oldstagehistory.date.seconds), 'days')
                    });
            }
            if (arrstagehistory.some(e => e.stage === stage && e.pipeline === _dpl)) {
                newstagehistory = _.filter(arrstagehistory, { stage: stage, pipeline: _dpl })[0]
                newstagehistory.date = window.firebase.firestore.Timestamp.now()
            }
            arrstagehistory = [...arrstagehistory.filter(e => e.stage !== stage), newstagehistory]
            inqUpdate.stageHistory = arrstagehistory;
            objEnquiry.stageHistory = arrstagehistory;
            objEnquiry.rottendays = 0
            objEnquiry.stage = stage
            objEnquiry.stageDate = window.firebase.firestore.Timestamp.now();
            objEnquiry.modifiedBy = localStorage.uid;
            objEnquiry.modifiedDate = window.firebase.firestore.Timestamp.now();
            objEnquiry.modifiedFrom = 'web';
            if (_dpl !== objEnquiry.pipeline && _.isEmpty(_fieldName)) {
                objEnquiry.isConverted = false;
                objEnquiry.convertedDate = null;
                objEnquiry.pipeline = _dpl;
            }
            //SaMeeR-09-10-24 stage note mandatory changes
            if (_state.stageNotes) {
                _logNotes = _state.stageNotes;
                inqUpdate.stageNotes = null;
                objEnquiry.stageNotes = null;
            }

        }
        this.updateEnquiry(objEnquiry)
        //this.forceUpdate()
        //var self = this;
        //self.setState({sectionloading:true})
        firestoreDB(dealersettings).firestore().doc(`enquiries/${id}`).set(inqUpdate, { merge: true })
            .then(snapshot => {

                CommonHelper.saveActivityLog({ 'enquiry': _state }, _logNotes, 'log', 'note', null, dealersettings);
                //console.log('success');
                // toast.notify('Enquiry updated successfully...', {
                //     duration: 2000
                // })
                setTimeout(function () {
                    //self.setState({sectionloading:false})
                    if (document.getElementById('enquiry-' + id)) {
                        document.getElementById('enquiry-' + id).scrollIntoView(
                            {
                                behavior: "smooth",
                                block: "center"
                            });
                    }
                }, 1000)
            })
            .catch(error => {
                console.error(error);
                // setTimeout(function () {
                // self.setState({sectionloading:false})
                // },1000)
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    static getDerivedStateFromProps(props, state) {
        let pathname = window.location.pathname;
        if ((pathname.indexOf('pipeline') >= 0 ||
            pathname.indexOf('inbound') >= 0 ||
            pathname.indexOf('tradeinplus') >= 0 ||
            pathname.indexOf('aftersales') >= 0) &&
            (pathname.indexOf('ppsr/list') < 0 &&
                pathname.indexOf('tradeinpro/list') < 0 &&
                pathname.indexOf('eventspro/list') < 0 &&
                pathname.indexOf('fleetpro/list') < 0 &&
                pathname.indexOf('fleetpro/history') < 0 &&
                pathname.indexOf('eventspro/attendees') < 0 &&
                pathname.indexOf('service/list') < 0 &&
                pathname.indexOf('pipeline/oemlist') < 0 &&
                pathname.indexOf('pipeline/list') < 0 &&
                pathname.indexOf('inbound/list') < 0 &&
                pathname.indexOf('inbound/allocation') < 0 &&
                pathname.indexOf('tipinbound/list') < 0)) {
            document.getElementsByTagName('body')[0].className = '';
        }

        if (props.dealersettings.client.settings.pipelines.length === 0) {
            return {
                isLoading: false
            }
        }

        let _pipelines = props.dealersettings.client.settings.pipelines;

        if (localStorage.defaultModule === 'afterSales') {
            _pipelines = [{
                active: true,
                default: true,
                modules: {
                    cafe: true,
                    contact: true,
                    files: true,
                    requirement: true,
                    finance: false,
                    testDrive: false,
                    tradeIn: false
                },
                value: 'AfterSales',
                name: 'After Sales',
                stages: [{
                    name: 'Inbound Lead',
                    value: 'inbound-lead'
                }, {
                    name: 'New Enquiry',
                    value: 'new-enquiry'
                },
                {
                    name: 'In Progress',
                    value: 'in-progress'
                },
                {
                    name: 'Qualified',
                    value: 'qualified'
                },
                {
                    name: 'Appoitment Scheduled',
                    value: 'appoitment-scheduled'
                },
                {
                    name: 'Won',
                    value: 'won'
                },
                {
                    name: 'Lost',
                    value: 'lost'
                }]
            }];
        }

        let _selectedPipeline = _pipelines.filter(item => (props.pipelineView && props.pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true))[0];
        let nextColumns = Array.prototype.map.call((_selectedPipeline ? _selectedPipeline.stages : []), function (item) { return item.value; }).join(",").split(',')
        if ((JSON.stringify(nextColumns) !== JSON.stringify(state.columns)
            || props.dealersettings.client.id !== state.clientid)) {


            //props.dealersettings.ref = "";
            return {
                columns: Array.prototype.map.call((_selectedPipeline ? _selectedPipeline.stages : []), function (item) { return item.value; }).join(",").split(','),
                pipeline: _pipelines,
                clientid: props.dealersettings.client.id,
                isLoading: true//!Boolean(state.checkLoad[props.dealersettings.client.settings.pipelines.find(item => item.default).value])
            }
        }
        else {
            return {
                isLoading: false
            }
        }

    }

    loadMoreData = () => {
        if (this.state.hasMore.length === 0) return;

        this.setState({
            stageLoading: this.state.hasMore[0].Stage
        })
        this.LoadEnquiry(true);
    }

    // listenScrollEvent(e) {
    //     if (this.state.hasMore.length === 0) return;

    //     if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
    //         this.setState({
    //             stageLoading: this.state.hasMore[0].Stage
    //         })
    //         this.LoadEnquiry()
    //     }
    //     return;
    // }

    updateEnquiry = (objEnquiry, forceUpdate) => {

        //console.log('updateEnquiry', objEnquiry.id, objEnquiry);
        const { enquiries, pipeline } = this.state;
        const { pipelineView, dealersettings } = this.props;
        if (_.isEmpty(enquiries))
            return
        if (forceUpdate) {
            objEnquiry = this.convertEnquiryVM(objEnquiry)
        }
        objEnquiry.stageDate = objEnquiry.stageDate && objEnquiry.stageDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(objEnquiry.stageDate._seconds)._d) : (objEnquiry.stageDate ? objEnquiry.stageDate : objEnquiry.addedDate);
        objEnquiry.convertedDate = objEnquiry.convertedDate && objEnquiry.convertedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(objEnquiry.convertedDate._seconds)._d) : objEnquiry.convertedDate;
        objEnquiry.addedDate = objEnquiry.addedDate && objEnquiry.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(objEnquiry.addedDate._seconds)._d) : objEnquiry.addedDate;
        objEnquiry.modifiedDate = objEnquiry.modifiedDate && objEnquiry.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(objEnquiry.modifiedDate._seconds)._d) : objEnquiry.modifiedDate;
        objEnquiry.verifiedDate = objEnquiry.verifiedDate && objEnquiry.verifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(objEnquiry.verifiedDate._seconds)._d) : objEnquiry.verifiedDate;

        objEnquiry.stageHistory = [..._.map(objEnquiry.stageHistory, function (obj) {
            return obj.date && obj.date._seconds ? { ...obj, 'date': window.firebase.firestore.Timestamp.fromDate(moment.unix(obj.date._seconds)._d) } : obj;
        })]
        //THAHA old OEM
        //if ((objEnquiry.isConverted === true || objEnquiry.isConvertedGroup === true || objEnquiry.isConvertedOEM === true || objEnquiry.isConvertedRegion === true) && enquiries.tasks[objEnquiry.id] && enquiries.tasks[objEnquiry.id].pipeline === 'LeadsBucket') {
        if ((objEnquiry.isConverted === true) && enquiries.tasks[objEnquiry.id] && enquiries.tasks[objEnquiry.id].pipeline === 'LeadsBucket') {
            enquiries.columns['LeadsBucketConverted'].taskIds.splice(0, 0, objEnquiry.id)
        }
        // else
        // if (localStorage.defaultModule === 'oem' && (objEnquiry.clientID && objEnquiry.oemStatus === 'converted' &&
        //     ((dealersettings.level === 'oem') || (dealersettings.level === 'region') || (dealersettings.level === 'group')))) {
        //         enquiries.columns['LeadsBucketConverted'].taskIds.splice(0, 0, objEnquiry.id)
        // }
        else if (objEnquiry.isConverted === false && objEnquiry.pipeline === 'LeadsBucket' && enquiries.columns['LeadsBucketConverted']) {
            const oldsatge = enquiries.columns['LeadsBucketConverted'].taskIds
            const index = oldsatge.indexOf(objEnquiry.id)
            if (index > -1) {
                oldsatge.splice(index, 1);
            }
        }
        if (objEnquiry.isDeleted === true && enquiries.tasks[objEnquiry.id]) {
            const oldpipipeline = enquiries.columns[enquiries.tasks[objEnquiry.id].pipeline + enquiries.tasks[objEnquiry.id].stage] ? enquiries.columns[enquiries.tasks[objEnquiry.id].pipeline + enquiries.tasks[objEnquiry.id].stage].taskIds : []
            const index = oldpipipeline.indexOf(objEnquiry.id)
            oldpipipeline.splice(index, 1);

            delete enquiries['tasks'][objEnquiry.id]
        }
        else if (enquiries.tasks[objEnquiry.id] && enquiries.tasks[objEnquiry.id].pipeline === objEnquiry.pipeline) {
            if (!Object.keys(enquiries.tasks).some(enq => enq === objEnquiry.id)) {
                enquiries.columns[objEnquiry.pipeline + objEnquiry.stage].taskIds.splice(0, 0, objEnquiry.id)
            }
            else if (enquiries.tasks[objEnquiry.id].stage !== objEnquiry.stage) {
                const oldsatge = enquiries.columns[enquiries.tasks[objEnquiry.id].pipeline + enquiries.tasks[objEnquiry.id].stage] ? enquiries.columns[enquiries.tasks[objEnquiry.id].pipeline + enquiries.tasks[objEnquiry.id].stage].taskIds : []
                const newstage = enquiries.columns[objEnquiry.pipeline + objEnquiry.stage] ? enquiries.columns[objEnquiry.pipeline + objEnquiry.stage].taskIds : []
                const index = oldsatge.indexOf(objEnquiry.id)
                if (index > -1) {
                    oldsatge.splice(index, 1);
                }
                newstage.splice(0, 0, objEnquiry.id)
            }

            let rDays = 0;
            let _selectedPipeline = pipeline.filter(item => (pipelineView && pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true))[0]
            _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                if (objEnquiry.stage === col.value) {
                    if (col.rottenDays) {
                        var enqage = objEnquiry.modifiedDate ? moment().diff(moment.unix(objEnquiry.modifiedDate.seconds), 'days') : 0
                        rDays = enqage - col.rottenDays
                    }
                }
            })

            enquiries['tasks'][objEnquiry.id] = {
                ...objEnquiry,
                enquiryage: (objEnquiry.addedDate ? (moment.unix(objEnquiry.addedDate.seconds).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY') ? '0 day' : moment.unix(objEnquiry.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '--'),
                rottendays: rDays
            }
        }
        else {
            let _selectedPipeline = pipeline.filter(item => (pipelineView && pipelineView === 'INBOUND') ? item.value === 'LeadsBucket' : (item.value !== 'LeadsBucket' && item.default === true))[0];
            if (_selectedPipeline && _selectedPipeline.value === objEnquiry.pipeline && !_.isEmpty(enquiries.columns[objEnquiry.pipeline + objEnquiry.stage])) {
                const newstage = enquiries.columns[objEnquiry.pipeline + objEnquiry.stage].taskIds
                newstage.splice(0, 0, objEnquiry.id)

                enquiries['tasks'][objEnquiry.id] = {
                    ...objEnquiry,
                    enquiryage: (objEnquiry.addedDate ? (moment.unix(objEnquiry.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? '0 day' : moment.unix(objEnquiry.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '--'),
                    rottendays: 0
                }
            }
            else if (_selectedPipeline && _selectedPipeline.value === 'LeadsBucket' && !_.isEmpty(enquiries.columns['LeadsBucketConverted'])) {
                const newstage = enquiries.columns['LeadsBucketConverted'].taskIds
                newstage.splice(0, 0, objEnquiry.id)

                enquiries['tasks'][objEnquiry.id] = {
                    ...objEnquiry,
                    enquiryage: (objEnquiry.addedDate ? (moment.unix(objEnquiry.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? '0 day' : moment.unix(objEnquiry.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '--'),
                    rottendays: 0
                }
            }
            else if (enquiries.tasks[objEnquiry.id]) {
                const oldpipipeline = enquiries.columns[enquiries.tasks[objEnquiry.id].pipeline + enquiries.tasks[objEnquiry.id].stage].taskIds
                const index = oldpipipeline.indexOf(objEnquiry.id)
                oldpipipeline.splice(index, 1);

                delete enquiries['tasks'][objEnquiry.id]
            }
        }

        this.setState({ enquiries })
    }

    handleModalClose = (objFields) => {
        if (_.isEmpty(objFields)) {
            this.setState({ showLeadConvert: false, convertLeadLoader: false, selectedEnquiry: {} })
        }
        else {
            var self = this;
            self.setState({ convertLeadLoader: true })
            const { enquiries } = this.state;
            let state = enquiries['tasks'] ? Object.assign({}, enquiries['tasks'][objFields.enquiryID]) : null;
            const { dealersettings } = this.props;
            let _objData = {
                isConverted: true,
                unactionedNotify: enqUnActionStatus.PENDING,
                convertedDate: window.firebase.firestore.Timestamp.now(),
                stageDate: window.firebase.firestore.Timestamp.now(),
                convertedBy: localStorage.uid,
                modifiedBy: localStorage.uid,
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                clientID: objFields.clientID ? objFields.clientID : dealersettings.client.id,
                groupID: (dealersettings && dealersettings.client && dealersettings.client.group) ? dealersettings.client.group : null,
                owner: objFields.owner,
                pipeline: objFields.pipeline,
                stage: objFields.stage,
                status: enquiryHelper.bindDefaultStatus(objFields.status, state.status),
                stageHistory: objFields.stageHistory,
                modifiedFrom: 'web'
            }
            if (objFields.status)
                _objData.statusHistory = window.firebase.firestore.FieldValue.arrayUnion(
                    {
                        date: window.firebase.firestore.Timestamp.now(),
                        status: objFields.status,
                        user: localStorage.uid
                    }
                )

            if (objFields.pipeline === 'LeadsBucket') {
                //_objData.status = null;
                _objData.isConverted = false;
                _objData.unactionedNotify = '';
                _objData.convertedBy = null;
                _objData.convertedDate = null;
                _objData.ownerInbound = objFields.owner;
            }
            else {
                _objData.lastStatus = '';
                _objData.lostReason = '';
                _objData.lostSubReason = '';
                _objData.lostNotes = '';
                _objData.lostDate = null;
                _objData.wonDate = null;

                if (_.isEmpty(state.tags) && state.pipeline === 'LeadsBucket') {
                    _objData.tags = [{ type: 'inbound', refID: objFields.enquiryID, subType: '' }]
                }
            }

            let _contactChange = false;
            if (state.isContact && !_.isEmpty(state.contact?.documentID) && objFields.clientID && state.clientID !== objFields.clientID) {
                if (!(dealersettings?.group?.enableSharedContact && !_.isEmpty(dealersettings.group?.client_ids) && dealersettings.group?.client_ids.includes(objFields.clientID))) {
                    let _tags = Object.assign([], state.tags);
                    _tags.push({
                        type: 'Dealer Transfer',
                        refID: (state.contact.documentID || null),
                        clientID: (state.contact.clientID || null),
                        origin: 'pipeline_convert'
                    })
                    _objData.engageNewContact = Object.assign({}, {
                        ...state.contact,
                        documentID: null,
                        displayID: null,
                        clientID: null,
                        projectId: null,
                        groupID: null,
                        regionID: null,
                        oemID: null,
                    });
                    _objData.contact = {};
                    _objData.contactID = null;
                    _objData.isContact = false;
                    _objData.tags = _tags;
                    _objData.tags = _objData.tags ? _.union(_tags, _objData.tags) : _tags;
                    _contactChange = true;
                }
            }

            const enableCustShowNoShow = ((!_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.client) &&
                !_.isEmpty(dealersettings.client.clientSettings) &&
                dealersettings.client.clientSettings.enableCustShowNoShow) ? true : false);

            if (enableCustShowNoShow && objFields.activityType === 'appointment' && !_.isEmpty(objFields.activityDate)) {
                _objData.bdcStatus = 'allocated';
                _objData.appointmentDate = objFields.activityDate;
            }

            //Remove empty value from object
            Object.keys(_objData).forEach(key => _objData[key] === undefined ? delete _objData[key] : {});

            firestoreDB(dealersettings).firestore().doc(`enquiries/${objFields.enquiryID}`)
                .set(_objData, { merge: true })
                .then(snapshot => {
                    //console.log('success');

                    let _contactID = state.contactID ? state.contactID : state?.contact?.documentID
                    if (objFields.owner && state.owner !== objFields.owner && _contactID)
                        firestoreDB(dealersettings).firestore().doc(`contactDetails/${_contactID}`).set({ 'owner': objFields.owner }, { merge: true })

                    if (state && objFields.clientID && state.clientID && state.clientID !== objFields.clientID) {
                        this.updateActivity(state.owner, objFields, _contactChange);
                    }
                    else
                        self.setState({ showLeadConvert: false, convertLeadLoader: false, selectedEnquiry: {} })

                    this.saveCustomerlog(objFields, objFields.mentions);

                    if (objFields.activityDate)
                        this.createAppoitment(objFields);

                    toast.notify('Enquiry converted successfully.', {
                        duration: 2000
                    })
                    this.updateEnquiry({ ...state, ..._objData }, true);
                    this.refreshStageCount()

                })
                .catch(error => {
                    console.error(error);
                    setTimeout(function () {
                        self.setState({ convertLeadLoader: false })
                    }, 1000)
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }

    }
    handlOEMLeadConvertClose = (objFields) => {
        if (!_.isEmpty(objFields)) {

            if (this.props.sidepanelClose)
                this.props.sidepanelClose();

            // const { convertLeadId, selectedEnquiry } = this.state
            // if (this.props.handleRouteRemove && convertLeadId && selectedEnquiry) {
            //     const { clientID, isAfterSales } = this.state.selectedEnquiry;
            //     let objRecord = {
            //         id: convertLeadId,
            //         clientid: clientID
            //     }

            //     this.props.handleRouteRemove(objRecord, isAfterSales ? 'afterSales' : 'enquiry');
            // }

            this.refreshStageCount()

        }
        this.setState({ showOEMLeadConvert: false, showOEMLeadConvertPrev: false, convertLeadStage: '', convertLeadLoader: false, selectedEnquiry: {} })
    }

    // handlOEMLeadConvertCloseNotUsed = (objFields) => {
    //     if (_.isEmpty(objFields)) {
    //         this.setState({ showOEMLeadConvert: false, convertLeadLoader: false })
    //     }
    //     else {
    //         var self = this;
    //         self.setState({ convertLeadLoader: true })
    //         const { enquiries } = this.state;
    //         let state = enquiries['tasks'] ? Object.assign({}, enquiries['tasks'][objFields.enquiryID]) : null;
    //         const { dealersettings } = this.props;

    //         let transferHistory = Object.assign([], state.transferHistory);
    //         let _enquiry = Object.assign({}, state.enqFields);

    //         let objRequest = {
    //             modifiedBy: localStorage.uid,
    //             modifiedDate: window.firebase.firestore.Timestamp.now(),
    //             pipeline: '',
    //             modifiedFrom: 'web',
    //         }
    //         let _newTransferHistory = {
    //             by: localStorage.uid,
    //             date: window.firebase.firestore.Timestamp.now(),
    //         }
    //         if (dealersettings.level === 'oem') {
    //             objRequest.isConvertedOEM = true;
    //             objRequest.convertedByOEM = localStorage.uid;
    //             objRequest.convertedDateOEM = window.firebase.firestore.Timestamp.now();
    //             _newTransferHistory.fromOEM = dealersettings.oemID ? dealersettings.oemID : null;
    //         }
    //         if (dealersettings.level === 'region') {
    //             objRequest.isConvertedRegion = true;
    //             objRequest.convertedByRegion = localStorage.uid;
    //             objRequest.convertedDateRegion = window.firebase.firestore.Timestamp.now();
    //             _newTransferHistory.fromRegion = dealersettings.regionID ? dealersettings.regionID : null;
    //         }
    //         if (dealersettings.level === 'group') {
    //             objRequest.isConvertedGroup = true;
    //             objRequest.convertedByGroup = localStorage.uid;
    //             objRequest.convertedDateGroup = window.firebase.firestore.Timestamp.now();
    //             _newTransferHistory.fromGroup = dealersettings.groupID ? dealersettings.groupID : null;
    //         }
    //         if (objFields.regionID) {
    //             objRequest.regionID = objFields.regionID;
    //             _newTransferHistory.toRegion = objFields.regionID;
    //         }

    //         if (objFields.groupID) {
    //             objRequest.groupID = objFields.groupID;
    //             _newTransferHistory.toGroup = objFields.groupID;
    //         }

    //         if (objFields.clientID) {
    //             objRequest.clientID = objFields.clientID;
    //             _newTransferHistory.toClient = objFields.clientID;
    //         }
    //         transferHistory.push(_newTransferHistory);
    //         objRequest.transferHistory = transferHistory;

    //         this.saveOEMLead(objRequest, _enquiry);
    //         //     window.firebase.firestore().doc(`enquiries/${objFields.enquiryID}`)
    //         //         .set(objRequest, { merge: true })
    //         //         .then(snapshot => {
    //         //             //console.log('success');

    //         //             self.setState({ showOEMLeadConvert: false, convertLeadLoader: false });

    //         //             this.saveOEMLead(objRequest, _enquiry);

    //         //             toast.notify('Lead converted successfully...', {
    //         //                 duration: 2000
    //         //             })

    //         //         })
    //         //         .catch(error => {
    //         //             console.error(error);
    //         //             setTimeout(function () {
    //         //                 self.setState({ convertLeadLoader: false })
    //         //             }, 1000)
    //         //             toast.notify('Something went wrong', {
    //         //                 duration: 2000
    //         //             })
    //         //         });
    //     }

    // }

    saveOEMLead = (_objOEMInbound, _objEnquiry) => {
        const _newLead = Object.assign({}, _objOEMInbound);
        _objEnquiry = Object.assign({}, _objEnquiry);
        for (let [key, value] of Object.entries(_objEnquiry)) {
            if (_newLead.hasOwnProperty(key) && _.isEmpty(_newLead[key]) && !_.isEmpty(_objEnquiry[key]))
                _newLead[key] = value;
        }

        _newLead.documentID = firestoreDB(this.props.dealersettings).firestore().collection('inboundLeads').doc().id;
        _newLead.pipeline = 'LeadsBucket';
        _newLead.stage = 'InboundLead';
        _newLead.addedBy = localStorage.uid;
        _newLead.modifiedBy = localStorage.uid;
        _newLead.addedDate = window.firebase.firestore.Timestamp.now();
        _newLead.modifiedDate = window.firebase.firestore.Timestamp.now();
        _newLead.leadCreated = window.firebase.firestore.Timestamp.now();
        _newLead.leadModified = window.firebase.firestore.Timestamp.now();
        _newLead.addedFrom = 'web';
        _newLead.modifiedFrom = 'web';

        if (!_.isEmpty(_objEnquiry.requirement)) {
            let _requirement = Object.assign({}, _objEnquiry.requirement);
            let _newReq = {};
            if (!_.isEmpty(_requirement.stock)) {
                let _stockReq = Object.assign({}, _requirement.stock);
                _newReq.make = _stockReq.make ? _stockReq.make : '';
                _newReq.model = _stockReq.model ? _stockReq.model : '';
                _newReq.type = _stockReq.type ? _stockReq.type : '';
                _newReq.year = _stockReq.year ? _stockReq.year : '';
                _newReq.extColor = _stockReq.extColor ? _stockReq.extColor : '';
                _newReq.intColor = _stockReq.intColor ? _stockReq.intColor : '';
                _newReq.saleType = _stockReq.saleType ? _stockReq.saleType : '';
                _newReq.price = _stockReq.price ? _stockReq.price : null;
                _newReq.stockNo = _stockReq.stockNo ? _stockReq.stockNo : '';
            }
            else {
                _newReq.make = _requirement.make ? _requirement.make : '';
                _newReq.model = _requirement.model ? _requirement.model : '';
                _newReq.type = _requirement.type ? _requirement.type : '';
                _newReq.year = _requirement.year ? _requirement.year : '';
                _newReq.extColor = _requirement.extColor ? _requirement.extColor : '';
                _newReq.intColor = _requirement.intColor ? _requirement.intColor : '';
                _newReq.saleType = _requirement.saleType ? _requirement.saleType : '';
                _newReq.price = _requirement.price ? _requirement.price : null;
            }
            _newLead.requirement = _newReq;
        }

        if (!_.isEmpty(_objEnquiry.contact)) {
            let _newContact = {};
            _newContact.firstName = _objEnquiry.contact.firstName ? _objEnquiry.contact.firstName : '';
            _newContact.middleName = _objEnquiry.contact.middleName ? _objEnquiry.contact.middleName : '';
            _newContact.lastName = _objEnquiry.contact.lastName ? _objEnquiry.contact.lastName : '';
            _newContact.phoneCode = _objEnquiry.contact.phoneCode ? _objEnquiry.contact.phoneCode : '';
            _newContact.phone = _objEnquiry.contact.phone ? _objEnquiry.contact.phone : '';
            _newContact.email = _objEnquiry.contact.email ? _objEnquiry.contact.email : '';
            _newLead.contact = _newContact;
        }
        //console.log('saveOEMLead', _objOEMInbound, _newLead);
        // window.firebase.firestore().doc(`inboundLeads/${_newLead.documentID}`).set(_newLead, { merge: true })
        //     .catch(error => {
        //         console.error(error);
        //     });
    }

    saveCustomerlog = (objFields, mentions) => {
        const { enquiries } = this.state;
        const { dealersettings, clientUsers, groupUsers } = this.props;
        let _enquiry = enquiries.tasks && !_.isEmpty(enquiries.tasks[objFields.enquiryID]) ? enquiries.tasks[objFields.enquiryID] : null
        let newlogVM = Object.assign({}, objActivitylog);
        newlogVM.type = 'log';
        newlogVM.subType = 'note';
        newlogVM.isDone = true;
        newlogVM.owner = localStorage.uid;
        newlogVM.addedBy = localStorage.uid;
        newlogVM.modifiedBy = localStorage.uid;
        newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.modifiedFrom = 'web';
        newlogVM.enquiryID = objFields.enquiryID;
        newlogVM.contactID = !_.isEmpty(_enquiry?.contact) ? _enquiry.contact.documentID : null;
        newlogVM['enquiry'] = CommonHelper.getMinifiedData(_enquiry, 'enquiry');
        newlogVM['contact'] = CommonHelper.getMinifiedData(_enquiry?.contact, 'contact');
        //newlogVM.clientID = dealersettings.client.id;
        newlogVM.documentID = firestoreDB(dealersettings).firestore().collection('activities').doc().id;
        newlogVM.startDate = window.firebase.firestore.Timestamp.now();
        newlogVM.addedByName = CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), localStorage.uid);
        newlogVM.notes = (_.isEmpty(objFields.notes) || _.isEmpty(objFields.notes.trim())) ?
            `${dealersettings.isPorsche === true ? 'Enquiry has been moved' : 'Lead has been converted'} to ${_.find(dealersettings.allPipelines, { 'value': objFields.pipeline }) ? _.find(dealersettings.allPipelines, { 'value': objFields.pipeline }).name : objFields.pipeline}` : objFields.notes;
        Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
        newlogVM.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

        if (!_.isEmpty(mentions)) {
            if (mentions.length > 0) {
                let convertedIDs = []
                let rawIDs = mentions.map(mentionObject => mentionObject.id)
                rawIDs.forEach(id => {
                    if (id.includes(',')) {
                        let allIDs = id.split(',')
                        allIDs.forEach(a => {
                            convertedIDs.push(a)
                        })
                    } else {
                        convertedIDs.push(id)
                    }
                })

                if (newlogVM.mentions && newlogVM.mentions.length > 0) {
                    newlogVM.mentions = _.uniq([...newlogVM.mentions, ...convertedIDs])
                } else {
                    newlogVM.mentions = _.uniq(convertedIDs)
                }
            }
        }

        newlogVM = CommonHelper.setLevelIDs(newlogVM, dealersettings, null,
            (enquiries.tasks &&
                !_.isEmpty(enquiries.tasks[objFields.enquiryID]) &&
                !_.isEmpty(enquiries.tasks[objFields.enquiryID].clientID) ? enquiries.tasks[objFields.enquiryID].clientID : null));
        firestoreDB(this.props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`).set(newlogVM, { merge: true })
            .catch(error => {
                console.error(error);
            });;
    }
    createAppoitment = (objFields) => {
        const { enquiries } = this.state;
        const { dealersettings, clientUsers, groupUsers } = this.props;
        let newlogVM = Object.assign({}, objActivitylog);
        newlogVM.type = 'activity';
        newlogVM.subType = objFields.activityType ? objFields.activityType : 'appointment';
        newlogVM.title = objFields.activityType ? CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.activityTypes, objFields.activityType) : 'Appointment';
        newlogVM.owner = objFields.owner;
        newlogVM.addedBy = localStorage.uid;
        newlogVM.modifiedBy = localStorage.uid;
        newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.modifiedFrom = 'web';
        newlogVM.enquiryID = objFields.enquiryID;
        newlogVM.enquiry = !_.isEmpty(objFields.enquiry) ? objFields.enquiry : null;
        newlogVM.contactID = objFields.contactID ? objFields.contactID : null;
        newlogVM.contact = !_.isEmpty(objFields.enquiry) && !_.isEmpty(objFields.enquiry.contact) ? objFields.enquiry.contact : null;
        //newlogVM.contactID = enquiries.tasks && !_.isEmpty(enquiries.tasks[objFields.enquiryID]) && !_.isEmpty(enquiries.tasks[objFields.enquiryID].contactID) ? enquiries.tasks[objFields.enquiryID].contactID : null;
        //newlogVM.clientID = objFields.clientID ? objFields.clientID : dealersettings.client.id;
        newlogVM.groupID = (dealersettings && dealersettings.client && dealersettings.client.group) ? dealersettings.client.group : null;
        newlogVM.documentID = firestoreDB(dealersettings).firestore().collection('activities').doc().id;
        newlogVM.startDate = objFields.activityDate;
        newlogVM.endDate = window.firebase.firestore.Timestamp.fromDate(moment.unix(objFields.activityDate.seconds).add('hour', 1).toDate());
        newlogVM.addedByName = CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), localStorage.uid);
        newlogVM.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

        const enableCustShowNoShow = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.clientSettings) &&
            dealersettings.client.clientSettings.enableCustShowNoShow) ? true : false);

        if (enableCustShowNoShow)//objFields.pipeline !== 'LeadsBucket' &&
            newlogVM = CommonHelper.setCustShowNoShow(newlogVM, dealersettings, true);

        newlogVM = CommonHelper.setLevelIDs(newlogVM, dealersettings, null, objFields.clientID);
        firestoreDB(dealersettings).firestore().doc(`activities/${newlogVM.documentID}`).set(newlogVM, { merge: true })
            .catch(error => {
                console.error(error);
            });;
    }
    updateActivity = async (currentOwner, objFields, _contactChange) => {
        if (!_.isEmpty(objFields) && (objFields.clientID || objFields.owner)) {
            let _objAct = {}
            if (objFields.owner) _objAct.owner = objFields.owner;
            if (objFields.clientID) _objAct.clientID = objFields.clientID;
            if (_contactChange) {
                _objAct.contactID = null;
                _objAct.contact = {};
            }
            const batch = firestoreDB(this.props.dealersettings).firestore().batch();
            await firestoreDB(this.props.dealersettings).firestore().collection('activities')
                .where('enquiryID', '==', objFields.enquiryID)
                .where('owner', '==', currentOwner)
                .where('isDeleted', '==', false)
                .where('isDone', '==', false)
                .where('type', '==', 'activity')
                .get().then(response => {
                    response.docs.forEach((doc) => {
                        batch.update(doc.ref, _objAct);
                    });
                });
            await firestoreDB(this.props.dealersettings).firestore().collection('activities')
                .where('enquiryID', '==', objFields.enquiryID)
                .where('owner', '==', currentOwner)
                .where('isHidden', '==', true)
                .where('isDeleted', '==', true)
                .get().then(response => {
                    response.docs.forEach((doc) => {
                        batch.update(doc.ref, _objAct);
                    });
                });
            batch.commit()
                .then(() => {
                    this.setState({ showLeadConvert: false, convertLeadLoader: false, selectedEnquiry: {} });
                })
                .catch((error) => {
                    console.log(error);
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }

    }

    handleLeadConvertShow = async (id, _objEnquiry) => {
        //    console.log('handleLeadConvertShow', id, _objEnquiry)
        var stage = 'Converted';
        var _objFav = {
            id: id,
            val: _objEnquiry.isFav ? _objEnquiry.isFav : false,
            stage: stage,
            oldstage: _objEnquiry.stage,
        }
        if (_objEnquiry.stage === 'InboundLead' || await this.isValidForStageUpdate(stage, (_objEnquiry.enqFields ? _objEnquiry.enqFields : _objEnquiry), _objFav) === true) {
            this.setState({ showLeadConvert: true, convertLeadId: id, convertLeadLoader: false, selectedEnquiry: Object.assign({}, _objEnquiry) })
        }
    }

    handleOEMLeadConvertShow = async (id, _objEnquiry) => {
        if (await this.isValidForStageUpdate('Converted', (!_.isEmpty(_objEnquiry.enqFields) ? _objEnquiry.enqFields : _objEnquiry)) === true) {
            this.setState({ showOEMLeadConvert: true, convertLeadId: id, convertLeadLoader: false, selectedEnquiry: Object.assign({}, _objEnquiry) })
        }


    }


    handleStatusFilter = (_status) => {
        if (_status) {
            if (!_.isEmpty(localStorage.pipelineFilter)) {
                let _pipelineFilter = JSON.parse(localStorage.pipelineFilter);
                if (!_.isEmpty(_pipelineFilter.value)) {
                    // update with the existing keyword
                    _pipelineFilter.value['status'] = _status
                }
                else {
                    // update with the new keyword
                    _pipelineFilter.value = { 'status': _status }
                }
                this.handleApplyFilter(_pipelineFilter);
            }
            else {
                // create new filter with keyword
                let _pipelineFilter = Object.assign({}, { name: 'Adv Filter', value: { 'status': _status }, type: 'filter' })
                this.handleApplyFilter(_pipelineFilter);
            }
        }
        else if (!_.isEmpty(localStorage.pipelineFilter)) {
            let _pipelineFilter = JSON.parse(localStorage.pipelineFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);
            if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty('status'))
                this.handleClearFilter();
            else {
                delete _pipelineFilter.value['status'];
                this.handleApplyFilter(_pipelineFilter);
            }
        }
    }

    handleStageAndStatusFilter = (_stage, _status) => {
        if (_status) {
            if (!_.isEmpty(localStorage.pipelineFilter)) {
                let _pipelineFilter = JSON.parse(localStorage.pipelineFilter);
                if (!_.isEmpty(_pipelineFilter.value)) {
                    // update with the existing keyword
                    _pipelineFilter.value['status'] = _status
                    _pipelineFilter.value['stage'] = _stage
                }
                else {
                    // update with the new keyword
                    _pipelineFilter.value = { 'status': _status, 'stage': _stage }
                }
                this.handleApplyFilter(_pipelineFilter);
            }
            else {
                // create new filter with keyword
                let _pipelineFilter = Object.assign({}, { name: 'Adv Filter', value: { 'status': _status, 'stage': _stage }, type: 'filter' })
                this.handleApplyFilter(_pipelineFilter);
            }
        }
        else if (!_.isEmpty(localStorage.pipelineFilter)) {
            let _pipelineFilter = JSON.parse(localStorage.pipelineFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);
            if (Object.keys(_filter).length === 2 && _filter.hasOwnProperty('status') && _filter.hasOwnProperty('stage'))
                this.handleClearFilter();
            else {
                delete _pipelineFilter.value['status'];
                delete _pipelineFilter.value['stage'];
                this.handleApplyFilter(_pipelineFilter);
            }
        }
    }

    handleApplyFilter = (_filter) => {
        //console.log('handleApplyFilter', _filter)
        //(pipelineFilter && Object.keys(pipelineFilter).length > 0 && _filter.name === pipelineFilter.name && pipelineFilter.type === 'custom')
        let _selectedPipeline = this.state.pipeline.filter(item => item.default === true)[0];
        _selectedPipeline && _selectedPipeline.stages.forEach(s => {
            window['unsub' + _selectedPipeline.value + s.value] && window['unsub' + _selectedPipeline.value + s.value]();
        })
        //window.unsubpipelinestages && window.unsubpipelinestages.unsubscribe();
        window.unSubPipelineCount && window.unSubPipelineCount();

        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('pipelineFilter', JSON.stringify(_filter));
            localStorage.enquirySearchText = '';
            this.setState({ isFilterApplied: true, isLoading: true, pageNum: 0, hasMore: [], enquiries: {}, stageCounts: {}, searchText: '' }, () => { this.LoadEnquiry() })
        }
        else {
            this.handleClearFilter();
        }

    }

    handleClearFilter = () => {
        //console.log('handleClearFilter',)
        if (!_.isEmpty(localStorage.pipelineFilter)) {
            localStorage.removeItem('pipelineFilter');
            localStorage.enquirySearchText = '';
            this.setState({ isFilterApplied: false, isLoading: true, pageNum: 0, hasMore: [], enquiries: {}, stageCounts: {}, searchText: '' }, () => { this.LoadEnquiry() })
        }
    }

    handlesearchText = (val) => {
        localStorage.removeItem('pipelineFilter');
        this.setState({ searchText: val, isFilterApplied: false, isLoading: true, pageNum: 0, hasMore: [], enquiries: {}, stageCounts: {} }, () => { this.LoadEnquiry() })
    }

    handlePageClear = () => {
        localStorage.removeItem('pipelineFilter');
        this.setState({ pageNum: 0, hasMore: [] })
    }

    handleApplyInBoundFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('inBoundFilter', JSON.stringify(_filter));
            localStorage.inBoundSearchText = '';
            this.setState({ isFilterApplied: true, isLoading: true, pageNum: 0, hasMore: [], enquiries: {}, stageCounts: {}, inBoundSearchText: '' }, () => { this.LoadEnquiry() })
        }
        else {
            this.handleClearInBoundFilter();
        }
    }

    handleClearInBoundFilter = () => {
        if (!_.isEmpty(localStorage.inBoundFilter)) {
            localStorage.removeItem('inBoundFilter');
            localStorage.inBoundSearchText = '';
            this.setState({ isFilterApplied: false, isLoading: true, pageNum: 0, hasMore: [], enquiries: {}, stageCounts: {}, inBoundSearchText: '' }, () => { this.LoadEnquiry() })
        }
    }

    handleInBoundSearchText = (val) => {
        localStorage.removeItem('inBoundFilter');
        this.setState({ inBoundSearchText: val, isFilterApplied: false, isLoading: true, pageNum: 0, hasMore: [], enquiries: {}, stageCounts: {} }, () => { this.LoadEnquiry() })
    }

    //#region MANDATORY PERMISSIONstage, oldstage, objEnquiry.enqFields
    isValidForStageUpdate = async (_stage, enqFields, origin) => {

        const { pipeline, stage } = enqFields;
        const _oldStage = pipeline + '-' + stage;
        const _newStage = pipeline + '-' + _stage;
        const { dealersettings } = this.props;

        let _mandPiplines;
        let _isValid = true;
        if (!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.settings) &&
            !_.isEmpty(dealersettings.client.settings.mandatoryFields) &&
            !_.isEmpty(dealersettings.client.settings.mandatoryFields.pipeline))
            _mandPiplines = dealersettings.client.settings.mandatoryFields.pipeline;
        //console.log('isValidForStageUpdate', _oldStage, _newStage, enqFields, origin, _mandPiplines);
        if (!_.isEmpty(_mandPiplines)) {

            let __newPipeline = _mandPiplines.filter(e => e.name === _newStage)[0];
            let __oldPipeline = _mandPiplines.filter(e => e.name === _oldStage)[0];

            if (!_.isEmpty(__newPipeline)) {

                if (!_.isEmpty(__oldPipeline) && _oldStage !== _newStage) {
                    for (let [key] of Object.entries(fieldsData)) {
                        let _oldKeyData = __oldPipeline[key] ? __oldPipeline[key].replace('stageNotes,', '').replace(',stageNotes', '') : ''
                        __newPipeline[key] = ((__newPipeline[key] ? __newPipeline[key] : "") + (_oldKeyData ? (__newPipeline[key] ? ',' + _oldKeyData : _oldKeyData) : ""));
                    }
                }

                _isValid = await this.handleMandatoryPermission(__newPipeline, enqFields, origin)
                //    console.log('isValidForStage------>Update', _newStage, _oldStage, 'ISVALID:' + _isValid, __newPipeline, __oldPipeline);
            }
            else if (!_.isEmpty(__oldPipeline) && _oldStage !== _newStage) {
                for (let [key] of Object.entries(fieldsData)) {
                    __oldPipeline[key] = (__oldPipeline[key] ? __oldPipeline[key].replace('stageNotes,', '').replace(',stageNotes', '') : "");
                }
                _isValid = await this.handleMandatoryPermission(__oldPipeline, enqFields, origin)
                //    console.log('isValidForStage------>Update---->old', _oldStage, 'ISVALID:' + _isValid, __oldPipeline);
            }
        }

        return _isValid;
    }
    handleMandatoryPermission = async (objFields, enqFields, origin) => {
        let isBreak = false;
        if (!_.isEmpty(objFields)) {
            const _fieldsData = Object.assign({}, fieldsData);
            for (let [key, value] of Object.entries(objFields)) {
                _fieldsData[key] = value;
            }
            const { contact } = enqFields;
            for (let [key, value] of Object.entries(_fieldsData)) {
                if (key === 'contact' && !contact?.businessContact && !_.isEmpty(value)) {
                    if (await this.checkMandatoryContact(value, enqFields, origin) === false) {
                        isBreak = true;
                        break;
                    }
                }

                if (key === 'businessContact' && contact?.businessContact && !_.isEmpty(value)) {
                    if (await this.checkMandatoryContact(value, enqFields, origin) === false) {
                        isBreak = true;
                        break;
                    }
                }

                if (key === 'enquiry' && !_.isEmpty(value)) {
                    if (await this.checkMandatoryEnquiry(value, enqFields, origin) === false) {
                        isBreak = true;
                        break;
                    }
                }
            }
        }
        if (isBreak)
            return false;
        else
            return true;
    }

    handleOpenEnquiry = (documentID, _newStatus) => {
        if (_newStatus && _newStatus.stage)
            this.props.history.push("/enquiry/details/" + documentID + '?stage=' + _newStatus.stage);
        else
            this.props.history.push('/enquiry/details/' + documentID);
    }

    checkMandatoryContact = async (value, enqFields, origin) => {
        const { isContact, contact } = enqFields;
        let _mandatoryFields = value ? _.uniq(value.split(',')) : [];
        if (!isContact) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Please add contact'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
                if (result.value) {
                    this.handleOpenEnquiry(enqFields.documentID, origin);
                }
            });

            return false;

        }
        else if (!_.isEmpty(_mandatoryFields)) {
            let ObjCustomer = Object.assign({}, contact);

            let msg;
            _mandatoryFields.some((el, index) => {
                if (el.trim() === 'licenseFrontURL') {
                    if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
                        msg = 'Please upload license front image';
                        return true;
                    }
                } else if (el.trim() === 'licenseBackURL') {
                    if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
                        msg = 'Please upload license back image';
                        return true;
                    }
                } else if (el.trim() === 'phoneORemail') {
                    if (!ObjCustomer['phone'] && !ObjCustomer['email']) {
                        msg = 'Please enter Phone or Email';
                        return true;
                    }
                } else if (el.trim() === 'phoneORemailORlicenseNo') {
                    if (!ObjCustomer['phone'] && !ObjCustomer['email'] && !ObjCustomer['licenseNo']) {
                        msg = 'Please enter Phone or Email or License No.';
                        return true;
                    }
                }
                else if (!ObjCustomer[el]) {
                    msg = 'Please enter ' + el;
                    return true;
                }
            });

            let _contactID = enqFields.contactID ? enqFields.contactID : (contact ? contact.documentID : null);
            if (msg && _contactID) {
                msg = null;
                const contSnapshot = await firestoreDB(this.props.dealersettings).firestore().doc(`contactDetails/${_contactID}`).get()
                ObjCustomer = contSnapshot.exists ? contSnapshot.data() : contact;

                _mandatoryFields.some((el, index) => {
                    if (el.trim() === 'licenseFrontURL') {
                        if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
                            msg = 'Please upload license front image';
                            return true;
                        }
                    } else if (el.trim() === 'licenseBackURL') {
                        if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
                            msg = 'Please upload license back image';
                            return true;
                        }
                    } else if (el.trim() === 'phoneORemail') {
                        if (!ObjCustomer['phone'] && !ObjCustomer['email']) {
                            msg = 'Please enter Phone or Email';
                            return true;
                        }
                    } else if (el.trim() === 'phoneORemailORlicenseNo') {
                        if (!ObjCustomer['phone'] && !ObjCustomer['email'] && !ObjCustomer['licenseNo']) {
                            msg = 'Please enter Phone or Email or License No.';
                            return true;
                        }
                    }
                    else if (!ObjCustomer[el]) {
                        msg = 'Please enter ' + el;
                        return true;
                    }
                });
            }

            if (msg) {
                Swal.fire({
                    title: CommonHelper.showLocale(this.props, 'Please enter mandatory fields in contact'),
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                    cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                }).then(result => {
                    if (result.value) {
                        this.handleOpenEnquiry(enqFields.documentID, origin);
                    }
                });
                return false;
            } else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    checkMandatoryEnquiry = async (value, enqFields, origin) => {
        let _mandatoryFields = !_.isEmpty(value) ? _.uniq(value.split(',')) : [];

        if (!_.isEmpty(this.mandatoryFields))
            _mandatoryFields = _.union(_mandatoryFields, this.mandatoryFields);

        if (!_.isEmpty(_mandatoryFields)) {
            const ObjEnquiry = Object.assign({}, objEnquiry);
            const ObjEnqFields = Object.assign({}, enqFields);
            for (let [key, value] of Object.entries(ObjEnqFields)) {
                ObjEnquiry[key] = value;
            }

            let msg;

            let modules = objModules;
            const { dealersettings } = this.props;
            if (!_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.client) &&
                !_.isEmpty(dealersettings.client.settings) &&
                !_.isEmpty(dealersettings.client.settings.pipelines)) {
                const pipelines = dealersettings.client.settings.pipelines;
                let _modules = !_.isEmpty(pipelines.filter(e => e.value === ObjEnquiry.pipeline)[0]) ? pipelines.filter(e => e.value === ObjEnquiry.pipeline)[0].modules : objModules;
                modules = !_.isEmpty(_modules) ? _modules : objModules;
            }

            const _moduleSettings = ((!_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.client) &&
                !_.isEmpty(dealersettings.client.moduleSettings)) ? dealersettings.client.moduleSettings : null);



            let _isbreak = false;

            let _requiredObject = _.filter(_mandatoryFields, (v) => ((v.startsWith('is') || v.startsWith('files_') || v.endsWith('_note')) && !['isFinanceRequired', 'isFav'].includes(v)));
            let _requiredFields = _.filter(_mandatoryFields, (v) => (!_requiredObject.includes(v)));

            //console.log('checkMandatory-Enquiry', value, _mandatoryFields, this.mandatoryFields, _requiredObject, _requiredFields)

            for (const el of _requiredObject) {
                if (el.trim() === 'isContact') {
                    if (!ObjEnquiry[el] && _.isEmpty(ObjEnquiry['contact'])) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add contact'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                this.handleOpenEnquiry(enqFields.documentID, origin);
                            }
                        });
                        _isbreak = true;
                        return false;
                    }
                } else if (el.trim() === 'isVehicle') {
                    if ((!ObjEnquiry[el] && (_.isEmpty(ObjEnquiry['requirement']) || (!_.isEmpty(ObjEnquiry['requirement']) && _.isEmpty(ObjEnquiry['requirement'].make) && _.isEmpty(ObjEnquiry['requirement'].stock)))) && !_.isEmpty(modules) && modules.requirement) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add requirement'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                this.handleOpenEnquiry(enqFields.documentID, origin);
                            }
                        });
                        _isbreak = true;
                        return false;
                    }
                } else if (el.trim() === 'isTestDrive') {
                    if (!ObjEnquiry[el] && !_.isEmpty(modules) && modules.testDrive) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add testdrive'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                this.handleOpenEnquiry(enqFields.documentID, origin);
                            }
                        });
                        _isbreak = true;
                        return false;
                    }
                } else if (el.trim() === 'isTradeIn') {
                    if (!ObjEnquiry[el] && !_.isEmpty(modules) && modules.tradeIn) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add tradein'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                this.handleOpenEnquiry(enqFields.documentID, origin);
                            }
                        });
                        _isbreak = true;
                        return false;
                    }
                } else if (el.trim() === 'isCafe') {
                    if (!ObjEnquiry[el] && !_.isEmpty(modules) && modules.cafe) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add cafe'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                this.handleOpenEnquiry(enqFields.documentID, origin);
                            }
                        });
                        _isbreak = true;
                        return false;
                    }
                } else if (el.trim() === 'isFiles') {
                    if (!ObjEnquiry[el] && !_.isEmpty(modules) && modules.files) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add file'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                this.handleOpenEnquiry(enqFields.documentID, origin);
                            }
                        });
                        _isbreak = true;
                        return false;
                    }
                } else if (_.trim(el).includes('files_')) {
                    let _data = await enquiryHelper.isFileCategoryExist(_.trim(el), enqFields.documentID, dealersettings)
                    if (_data === false) {

                        let filesCategory = Object.assign([], dealersettings?.client?.settings?.filesCategory)
                        let _info = _.find(filesCategory, { value: el.split('_')[2] })
                        if (!_.isEmpty(_info)) {
                            Swal.fire({
                                title: CommonHelper.showLocale(this.props, `Please add file in ${CommonHelper.autoCaps(_info.module)} (${_info.name}) Folder.`),
                                icon: 'info',
                                showCancelButton: true,
                                confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                            }).then(result => {
                                if (result.value) {
                                    this.handleOpenEnquiry(enqFields.documentID, origin);
                                }
                            });
                            _isbreak = true;
                            return false;
                        }
                    }
                } else if (_.trim(el).includes('_note')) {
                    let _data = await enquiryHelper.isActivityExist(_.trim(el), enqFields.documentID, dealersettings)
                    if (_data === false) {
                        let _type = _.trim(el).split('_')[0];
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, `Please add ${_type.replace('log', ' note').toLowerCase()}.`),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                this.handleOpenEnquiry(enqFields.documentID, origin);
                            }
                        });
                        _isbreak = true;
                        return false;
                    }
                } else if (el.trim() === 'isVideo') {
                    const amsVideoEnabled = ((!_.isEmpty(_moduleSettings) &&
                        !_.isEmpty(dealersettings.client.moduleSettings.amsVideo) &&
                        dealersettings.client.moduleSettings.amsVideo.enabled) ? true : false);
                    if (!ObjEnquiry[el] && !_.isEmpty(modules) && modules.amsVideo && amsVideoEnabled) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add video'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                this.handleOpenEnquiry(enqFields.documentID, origin);
                            }
                        });
                        _isbreak = true;
                        return false;
                    }
                } else if (el.trim() === 'isFinance') {
                    const drivaEnabled = ((!_.isEmpty(dealersettings) &&
                        !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
                        dealersettings.client.integrations.filter(e => e.type === "driva")[0] &&
                        dealersettings.client.integrations.filter(e => e.type === "driva")[0].active === true &&
                        dealersettings.client.integrations.filter(e => e.type === "driva")[0].enabled === true
                    ) ? true : false);

                    const financeEnabled = ((!_.isEmpty(_moduleSettings) &&
                        !_.isEmpty(dealersettings.client.moduleSettings.finance) &&
                        dealersettings.client.moduleSettings.finance.enabled) ? true : false);

                    if (!ObjEnquiry[el] && !_.isEmpty(modules) && modules.finance && (financeEnabled || drivaEnabled)) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add finance'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                this.handleOpenEnquiry(enqFields.documentID, origin);
                            }
                        });
                        _isbreak = true;
                        return false;
                    }
                }
            };

            if (_isbreak)
                return false;

            !_.isEmpty(_requiredFields) && _requiredFields.some((el, index) => {
                if (!ObjEnquiry[el]) {
                    if (ObjEnquiry.hasOwnProperty(el)) {
                        msg = `${CommonHelper.showLocale(this.props, 'Please enter')} ${CommonHelper.showLocale(this.props, el)}`;
                        return true;
                    }
                    else if (
                        (!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.value === el && m.active === true)[0])) &&
                        (_.isEmpty(ObjEnquiry.dynamicFields) ||
                            (!_.isEmpty(ObjEnquiry.dynamicFields) && _.isEmpty(ObjEnquiry.dynamicFields[el]) && !_.isBoolean(ObjEnquiry.dynamicFields[el]) && !_.isNumber(ObjEnquiry.dynamicFields[el]))
                        )
                    ) {
                        msg = `${CommonHelper.showLocale(this.props, 'Please enter')} ${CommonHelper.showLocale(this.props, el)}`;
                        return true;
                    }
                    // else if ((!_.isEmpty(enquiryOptions) && !_.isEmpty(enquiryOptions.filter(m => m.value === el && m.active === true)[0]))
                    //     && !_.isEmpty(ObjEnquiry.options) && ObjEnquiry.options.indexOf(el) < 0) {
                    //     msg = 'Please enter ' + el;
                    //     return true;
                    // }
                    else {
                        return true;
                    }
                }
            });

            if (msg) {
                let errors = {};
                let formIsValid = true;
                let errorClass = 'input_error';

                _requiredFields.forEach((key, index) => {
                    if (
                        !ObjEnquiry[key] &&
                        _requiredFields.indexOf(key) >= 0
                    ) {
                        if (ObjEnquiry.hasOwnProperty(key)) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                        else if ((!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.value === key && m.active === true)[0])) &&
                            (_.isEmpty(ObjEnquiry.dynamicFields) ||
                                (!_.isEmpty(ObjEnquiry.dynamicFields) && _.isEmpty(ObjEnquiry.dynamicFields[key]) && !_.isBoolean(ObjEnquiry.dynamicFields[key]) && !_.isNumber(ObjEnquiry.dynamicFields[key]))
                            )) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                        // else if ((!_.isEmpty(enquiryOptions) && !_.isEmpty(enquiryOptions.filter(m => m.value === key && m.active === true)[0])) &&
                        //     (_.isEmpty(ObjEnquiry.options) || (!_.isEmpty(ObjEnquiry.options) && ObjEnquiry.options.indexOf(key) < 0))) {
                        //     formIsValid = false;
                        //     errors[key] = errorClass;
                        // }
                    }
                });

                if (formIsValid === true) {
                    return true;
                } else {
                    this.setState({
                        enquiryModal: {
                            show: true,
                            enquiry: ObjEnquiry,
                            errors: errors,
                            origin: origin
                        },
                    });
                    return formIsValid;
                }
            } else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    handleEnquiryOptionClose = (status) => {
        const { enquiryModal, enquiries } = this.state;
        //   console.log('handleEnquiry-OptionClose', status, enquiryModal, enquiries);

        if (status && !_.isEmpty(enquiryModal) && !_.isEmpty(enquiryModal.origin)) {
            let _origin = Object.assign({}, enquiryModal.origin)
            const { id, val, stage, oldstage } = _origin;
            let objEnq = !_.isEmpty(enquiries['tasks']) && enquiries['tasks'][id] ? enquiries['tasks'][id] : enquiryModal.enquiry;
            if (!_.isEmpty(objEnq))
                this.handleEnquiryFav(id, val, stage, oldstage, objEnq);

        }
        this.setState({
            enquiryModal: {
                show: false,
                enquiry: {},
                errors: null,
                origin: {}
            },
        });
    };
    //#endregion

    handleShowInboundLead = (id, stage) => {
        //console.log(id);

        this.setState({ inboundModal: { show: true, docID: id, stage: stage } });
    }

    handleCloseInboundLead = (_data, _type, removeID) => {
        if (!_.isEmpty(_data)) {

            if (_type === 'delete') {
                const { enquiries } = this.state;
                if (_data.documentID && enquiries['tasks'] && enquiries['tasks'][_data.documentID]) {
                    let state = Object.assign({}, enquiries['tasks'][_data.documentID]);
                    let _enq = state.enqFields ? state.enqFields : state;
                    this.updateEnquiry({ ..._enq, ['isDeleted']: true }, true);
                }
            }
            else if (_type === 'accept') {
                this.updateEnquiry({ ..._data }, true);
                const { enquiries } = this.state;
                if (removeID && enquiries['tasks'] && enquiries['tasks'][removeID]) {
                    let state = Object.assign({}, enquiries['tasks'][removeID]);
                    let _enq = state.enqFields ? state.enqFields : state;
                    this.updateEnquiry({ ..._enq, ['isDeleted']: true }, true);
                }
            }
        }
        this.setState({ inboundModal: { show: false, docID: null, stage: null } });
        this.refreshStageCount()
    }

    render() {

        const { children, dealersettings, clientUsers } = this.props
        const {
            columns, pipeline, enquiries, hasMore,
            isLoading, stageLoading, showNotifier, notifierMsg, notifierInfo,
            sectionloading, showLeadConvert, convertLeadId, convertLeadLoader,
            showOEMLeadConvert, showOEMLeadConvertPrev,
            pageNum, isFilterApplied, enquiryModal, stageCounts, searchText, inboundModal, inBoundSearchText
        } = this.state


        //console.log('E N Q U I R I E S', enquiries)
        return (
            <>
                <StageContext.Provider
                    value={{
                        enquiries,
                        pipeline,
                        columns,
                        isLoading,
                        handleEnquiryFav: this.handleEnquiryFav,
                        loadMoreData: this.loadMoreData,
                        stageLoading,
                        showNotifier,
                        notifierMsg,
                        notifierInfo,
                        updateEnquiry: this.updateEnquiry,
                        sectionloading,
                        handleLeadConvertShow: this.handleLeadConvertShow,
                        handleOEMLeadConvertShow: this.handleOEMLeadConvertShow,
                        handleApplyFilter: this.handleApplyFilter,
                        handleClearFilter: this.handleClearFilter,
                        handleStatusFilter: this.handleStatusFilter,
                        handleStageAndStatusFilter: this.handleStageAndStatusFilter,
                        pageNum,
                        isFilterApplied,
                        stageCounts,
                        searchText,
                        handlesearchText: this.handlesearchText,
                        handleShowInboundLead: this.handleShowInboundLead,
                        dealersettings: this.props.dealersettings,
                        inBoundSearchText,
                        handleInBoundSearchText: this.handleInBoundSearchText,
                        handleApplyInBoundFilter: this.handleApplyInBoundFilter,
                        handleClearInBoundFilter: this.handleClearInBoundFilter,
                        handlePageClear: this.handlePageClear,
                        hasMore
                    }}
                >
                    {children}
                </StageContext.Provider>
                <PopUpModal show={showLeadConvert}>
                    <LeadConvert
                        show={showLeadConvert}
                        handleModalClose={this.handleModalClose}
                        pipeline={pipeline}
                        dealersettings={this.props.dealersettings}
                        clientUsers={this.props.clientUsers}
                        groupUsers={this.props.groupUsers}
                        enquiryID={convertLeadId}
                        loader={convertLeadLoader}
                        enquiry={this.state.selectedEnquiry}
                    />
                </PopUpModal>

                <PopUpModal show={enquiryModal.show}>
                    <EnquiryFields
                        title='enquiryInfo'
                        show={enquiryModal.show}
                        errors={enquiryModal.errors}
                        newStatus={'stage'}
                        enquiry={enquiryModal.enquiry}
                        enquiryID={enquiryModal.enquiry['documentID']}
                        requirement={enquiryModal.enquiry['requirement']}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        handleClose={this.handleEnquiryOptionClose}
                    />
                </PopUpModal>

                <PopUpModal show={inboundModal.show}>
                    <InBoundLeadConvert
                        show={inboundModal.show}
                        docID={inboundModal.docID}
                        stage={inboundModal.stage}
                        handleModalClose={this.handleCloseInboundLead}
                        pipeline={pipeline}
                        dealersettings={this.props.dealersettings}
                        clientUsers={this.props.clientUsers}
                        groupUsers={this.props.groupUsers}
                        loader={convertLeadLoader}
                    />
                </PopUpModal>

                <PopUpModal show={showOEMLeadConvert}>
                    <OEMLeadAllocation
                        show={showOEMLeadConvert}
                        handleModalClose={this.handlOEMLeadConvertClose}
                        pipeline={pipeline}
                        dealersettings={this.props.dealersettings}
                        clientUsers={this.props.clientUsers}
                        groupUsers={this.props.groupUsers}
                        enquiryID={convertLeadId}
                        loader={convertLeadLoader}
                        enquiry={this.state.selectedEnquiry}
                        convertLeadStage={this.state.convertLeadStage}
                        returnEnq={true}
                    />
                </PopUpModal>

                {/* <PopUpModal show={showOEMLeadConvertPrev}>
                    <OEMLeadTransfer
                        show={showOEMLeadConvertPrev}
                        handleModalClose={this.handlOEMLeadConvertClose}
                        pipeline={pipeline}
                        dealersettings={this.props.dealersettings}
                        clientUsers={this.props.clientUsers}
                        groupUsers={this.props.groupUsers}
                        enquiryID={convertLeadId}
                        loader={convertLeadLoader}
                        enquiry={this.state.selectedEnquiry}
                        convertLeadStage={this.state.convertLeadStage}
                    />
                </PopUpModal> */}
            </>
        )
    }

}