import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { InputText } from '../../components';
import Translate from '../../constants/translate';
import _ from 'lodash'
import { validateEmail } from '../../services/helper';

const AddRecipient = (props) => {
    const [errorFields, setErrorFields] = useState({})
    const [data, setData] = useState(props.data || {})

    const handleOnChange = e => {
        const { name, value } = e.target;

        setData({
            ...data,
            [name]: value,
        });
        setErrorFields({
            ...errorFields,
            [name]: '',
        });
    };

    const onSave = e => {
        e.preventDefault()
        let isValid = true;
        let errors = { ...errorFields }
        if (_.isEmpty(data.firstName)) {
            isValid = false;
            errors.firstName = 'input_error'
        }
        if (_.isEmpty(data.email) || !validateEmail(data.email)) {
            isValid = false;
            errors.email = 'input_error'
        }
        setErrorFields(errors);

        if (isValid) {
            props.handleClose({
                ...data,
                name: `${data.firstName || ''} ${data.lastName || ''}`
            }, props.new);
        }
    }

    return (<Modal

        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-centered modal-save-filter">

        <Modal.Header closeButton>
            <Modal.Title>
                <h5 className="modal-title" > <Translate text={props.title} /></h5>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body >
            <div className="container-fluid">
                <div className="row">

                    <div className="form-style w-100">

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label >First Name</label>
                                <InputText
                                    autoComplete="off"
                                    className={`form-control ${errorFields["firstName"]}`}
                                    name="firstName"
                                    onChange={handleOnChange}
                                    value={data.firstName}
                                    placeholder='first name'
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label >Last Name</label>
                                <InputText
                                    autoComplete="off"
                                    className={`form-control ${errorFields["lastName"]}`}
                                    name="lastName"
                                    onChange={handleOnChange}
                                    value={data.lastName}
                                    placeholder='last name'
                                />
                            </div>
                            <div className="form-group col-md-12">
                                <label >Email</label>
                                <InputText
                                    autoComplete="off"
                                    className={`form-control ${errorFields["email"]}`}
                                    name="email"
                                    onChange={handleOnChange}
                                    value={data.email}
                                    placeholder='email'
                                />
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <button type="button" className={`btn btn-primary float-right`} onClick={onSave}>
                <Translate text={props.title === 'Edit Recipient' ? 'Save' : 'Add'} />
            </button>
            <button type="button" className="btn btn-default float-left" onClick={() => {
                props.handleClose();
            }}>
                <Translate text={'cancel'} />
            </button>

        </Modal.Footer>
    </Modal>);
}

export default AddRecipient;