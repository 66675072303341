import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import moment from 'moment'
import { ReactMultiSelect } from '../../components';

const PipelineCounts = (props) => {

    const [counts, setCounts] = useState({})
    const [objFilter, setObjFilter] = useState({});

    useEffect(() => {
        if (props.loader)
            return

        const filterData = props.monthData.filter(({ regionID, subregionID, groupID, clientID, categoryValue }) =>
            (props?.dashboardFilter?.region?.length ? props.dashboardFilter.region.includes(regionID) : true) &&
            (props?.dashboardFilter?.subregion?.length ? _.isEmpty(subregionID) || props.dashboardFilter.subregion.includes(subregionID) : true) &&
            (props?.dashboardFilter?.group?.length ? _.isEmpty(groupID) || props.dashboardFilter.group.includes(groupID) : true) &&
            (props?.dashboardFilter?.dealer?.length ? props.dashboardFilter.dealer.includes(clientID) : true) &&
            (props?.dashboardFilter?.category?.length ? props.dashboardFilter.category.includes(categoryValue) : true)
        );
        const _counts = {
            vehicles: filterData.filter(a => a.path === 'fleetPro'),
            onfleet: filterData.filter(a => a.path === 'fleetPro' && a.statusValue === 'onfleeting'),
            operational: filterData.filter(a => a.path === 'fleetPro' && a.statusValue === 'operational'),
            offroard: filterData.filter(a => a.path === 'fleetPro' && a.statusValue === 'offroard'),
            bookings: filterData.filter(a => a.path === 'fleetProAssign' && a.statusAssign === 'booked')
        }
        _counts.utilisation = _counts?.operational?.length && _counts?.bookings?.length ? Math.round((100 / _counts?.operational?.length) * _counts?.bookings?.length) : 0;
        if (_counts.utilisation > 100) {
            _counts.utilisation = 100;
        }
        setCounts(_counts)
    }, [props.monthData, props.dashboardFilter])

    const getRegions = () => {
        let regionsToReturn;
        let filteredRegions;

        filteredRegions = props.dealersettings.regions.filter(region => {
            if (props.dealersettings.clients.some(client => client.region === region.code)) {
                return true
            }
            return false;
        })

        regionsToReturn = filteredRegions.map(e => {
            return { label: e.name, value: e.code };
        })

        return regionsToReturn
    }

    const getSubregions = () => {
        let regionsToReturn;
        let filteredSubregions;

        filteredSubregions = props.dealersettings.subregions.filter(subregion => {
            if (props.dealersettings.clients.some(client => (client.subregion === subregion.code) &&
                (!_.isEmpty(objFilter['region']) ? objFilter['region'].includes(client.region) : true))) {
                return true
            }
            return false;
        })

        regionsToReturn = filteredSubregions.map(e => {
            return { label: e.name, value: e.code };
        })

        return regionsToReturn
    }

    const getCountries = () => {
        let countriesToReturn;
        let filteredCountries;

        filteredCountries = props.dealersettings.countries.filter(country => {
            if (props.dealersettings.clients.filter(client => (
                (!_.isEmpty(objFilter['region']) ? objFilter['region'].includes(client.region) : true) &&
                (!_.isEmpty(objFilter['subregion']) ? objFilter['subregion'].includes(client.subregion) : true)
            )).some(client =>
                (client.country === country.code)
            )) {
                return true
            }
            return false;
        })

        countriesToReturn = filteredCountries.map(e => {
            return { label: e.name, value: e.code };
        })

        return countriesToReturn
    }

    const getDealers = () => {
        let clientsToReturn = props.dealersettings.clients;

        if (!_.isEmpty(objFilter['region'])) {
            clientsToReturn = clientsToReturn.filter(client =>
                objFilter['region'].includes(client.region)
            );
        }

        if (!_.isEmpty(objFilter['subregion'])) {
            clientsToReturn = clientsToReturn.filter(client =>
                objFilter['subregion'].includes(client.subregion)
            );
        }

        if (!_.isEmpty(objFilter['country'])) {
            clientsToReturn = clientsToReturn.filter(client =>
                objFilter['country'].includes(client.country)
            );
        }

        clientsToReturn = clientsToReturn.map(client => {
            return {
                value: client.documentID,
                doc: client,
                label: client.name,
                active: _.isBoolean(client.active) ? client.active : true,
            };
        })

        return clientsToReturn
    }

    const getCategories = () => {
        let setttings = props.dealersettings.client.fleetPro;
        let categories = [];
        setttings.category && setttings.category.forEach(doc => {
            categories.push({
                value: doc.value,
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: doc.name,
            });
        });

        return categories
    }

    const setMultibjFilter = (selectedOptions, name) => {
        let value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        let _objFilter = objFilter;
        if (name === 'region') {
            _objFilter = {
                ..._objFilter,
                [name]: value,
                ['subregion']: [],
                ['country']: [],
                ['dealer']: [],
            };
        } else if (name === 'subregion') {
            _objFilter = {
                ..._objFilter,
                [name]: value,
                ['country']: [],
                ['dealer']: [],
            };
        } else if (name === 'country') {
            _objFilter = {
                ..._objFilter,
                [name]: value,
                ['dealer']: [],
            };
        } else {
            _objFilter = {
                ..._objFilter,
                [name]: value,
            };
        }
        setObjFilter(_objFilter);
        props.setDashboardFilter(_objFilter);
    }


    return (<>
        <div className="dashboard-panel-top">
            <div className="dashboard-panel-head">
                <div className="float-left">
                    <h3>Fleet Stats</h3>
                </div>
                {props.loader && <div className="align-items-center d-flex justify-content-center text-primary spinner-loader-h3 float-left"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div>}
                <div className="float-lg-right form-dashboard">
                    <div className="form-row justify-content-end">
                        {
                            props.dealersettings.level === 'oem' || props.dealersettings.level === 'region' ? (
                                <div className="form-group col-md-3">
                                    <ReactMultiSelect
                                        options={getRegions()}
                                        name={'region'}
                                        placeholder={`select region`}
                                        onChange={(e) => {
                                            setMultibjFilter(e, 'region')
                                        }}
                                        value={objFilter['region']}
                                        classNamePrefix={`basic-select`}
                                        isMulti={true}
                                        isSearchable={true}
                                    >
                                    </ReactMultiSelect>
                                </div>
                            ) : (<></>)
                        }
                        {
                            props.dealersettings.level === 'oem' || props.dealersettings.level === 'region' || props.dealersettings.level === 'subregion' ? (
                                <div className="form-group col-md-3">
                                    <ReactMultiSelect
                                        options={getSubregions()}
                                        name={'subregion'}
                                        placeholder={`select subregion`}
                                        onChange={(e) => {
                                            setMultibjFilter(e, 'subregion')
                                        }}
                                        value={objFilter['subregion']}
                                        classNamePrefix={`basic-select`}
                                        isMulti={true}
                                        isSearchable={true}
                                    >
                                    </ReactMultiSelect>
                                </div>

                            ) : (<></>)
                        }
                        {/* <div className="form-group col-md-3">

                            <ReactMultiSelect
                                options={getDealers()}
                                name={'dealer'}
                                placeholder={`select dealer`}
                                onChange={(e) => {
                                    setMultibjFilter(e, 'dealer')
                                }}
                                value={objFilter['dealer']}
                                classNamePrefix={`basic-select`}
                                isMulti={true}
                                isSearchable={true}
                            >
                            </ReactMultiSelect>
                        </div> */}
                        <div className="form-group col-md-3">

                            <ReactMultiSelect
                                options={getCategories()}
                                name={'category'}
                                placeholder={`select category`}
                                onChange={(e) => {
                                    setMultibjFilter(e, 'category')
                                }}
                                value={objFilter['category']}
                                classNamePrefix={`basic-select`}
                                isMulti={true}
                                isSearchable={true}
                            >
                            </ReactMultiSelect>
                        </div>
                    </div>
                </div>
                {/* <div className="dashboard-panel-subhead-main">
                    <div className="float-left">
                        <div className="dash-head-currentdate">
                            {
                                moment().format('MMMM YYYY')
                            }
                        </div>

                    </div>
                </div> */}
            </div>
            <div className="dashboard-panel-body">
                <div className={`dashboard-custom-tileswrapper${props.dealersettings.level === 'individual' ? '-dealer' : ''}`}>
                    <ul>
                        {
                            props.dealersettings.level !== 'individual' ? (
                                <>
                                    <li>
                                        <div className="dashicon-fix">
                                            <div className="dashboard-module-loop dashboard-module-flex">
                                                <div className="dashboard-module-icon"><i className="ico icon-fleet-vehicles"></i></div>
                                                <div className="dashboard-module-label"> Vehicles </div>
                                                <div className="dashboard-module-count-wraper">
                                                    <div className=" row">
                                                        <div className="col-lg-12">
                                                            <div className="dashboard-count-value overdue-fix"> <a href="#" className="blue-link" onClick={(e) => {
                                                                e.preventDefault();
                                                                if (counts?.vehicles?.length)
                                                                    props.handleShowList(counts.vehicles, 'Fleet Vehicles')
                                                            }}>{counts?.vehicles?.length || 0}</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="dashicon-fix">
                                            <div className="dashboard-module-loop dashboard-module-flex">
                                                <div className="dashboard-module-icon"><i className="ico icon-fleet-on-fleeting"></i></div>
                                                <div className="dashboard-module-label"> On-Fleeting</div>
                                                <div className="dashboard-module-count-wraper">
                                                    <div className=" row">
                                                        <div className="col-lg-12">
                                                            <div className="dashboard-count-value overdue-fix"> <a href="#" className="blue-link" onClick={(e) => {
                                                                e.preventDefault();
                                                                if (counts?.onfleet?.length)
                                                                    props.handleShowList(counts.onfleet, 'On-Fleeting')
                                                            }}>{counts?.onfleet?.length || 0}</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </>
                            ) : (<></>)
                        }
                        <li>
                            <div className="dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-module-icon"><i className="ico icon-fleet-operational"></i></div>
                                    <div className="dashboard-module-label"> Operational</div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            <div className="col-lg-12">
                                                <div className="dashboard-count-value overdue-fix"> <a href="#" className="blue-link" onClick={(e) => {
                                                    e.preventDefault();
                                                    if (counts?.operational?.length)
                                                        props.handleShowList(counts.operational, 'Operational')
                                                }}>{counts?.operational?.length || 0}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-module-icon"><i className="ico ico icon-fleet-events"></i></div>
                                    <div className="dashboard-module-label"> Bookings</div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            <div className="col-lg-12">
                                                <div className="dashboard-count-value overdue-fix"> <a href="#" className="blue-link" onClick={(e) => {
                                                    e.preventDefault();
                                                    if (counts?.bookings?.length)
                                                        props.handleShowAssignList(counts.bookings, 'Bookings')
                                                }}>{counts?.bookings?.length || 0}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        {
                            props.dealersettings.level !== 'individual' ? (
                                <li>
                                    <div className="dashicon-fix">
                                        <div className="dashboard-module-loop dashboard-module-flex">
                                            <div className="dashboard-module-icon"><i className="ico icon-fleet-off-road"></i></div>
                                            <div className="dashboard-module-label"> Off-Road</div>
                                            <div className="dashboard-module-count-wraper">
                                                <div className=" row">
                                                    <div className="col-lg-12">
                                                        <div className="dashboard-count-value overdue-fix"> <a href="#" className="blue-link" onClick={(e) => {
                                                            e.preventDefault();
                                                            if (counts?.offroard?.length)
                                                                props.handleShowList(counts.offroard, 'Off-Road')
                                                        }}>{counts?.offroard?.length || 0}</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ) : (<></>)
                        }
                        <li>
                            <div className="dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-module-icon"><i className="ico icon-fleet-utilisation"></i></div>
                                    <div className="dashboard-module-label"> Utilisation</div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            <div className="col-lg-12">
                                                <div className="dashboard-count-value overdue-fix"> <a href="#" className="blue-link">{counts.utilisation || 0}%</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </>);
}

export default PipelineCounts;