import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { ContentOverlay, PopUpModal, TableView } from "../../components";
import CommonHelper from '../../services/common'
import _images from '../../images'
import Translate from '../../constants/translate';
import ViewRecipient from './viewRecipient';
import PandaDocEditor from '.';
import moment from 'moment';
import { tableFields } from './viewModel';
import Swal from 'sweetalert2';



const DocumentList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth - 30, windowHeight: window.innerHeight - 123, });
    const [loader, setLoader] = useState(true)
    const [documentList, setdocumentList] = useState([])
    const [pageNum, setPageNum] = useState(1);
    const [dataLoad, setDataload] = useState(true)
    const [recpModel, setrecpModel] = useState({ show: false })
    const [signModel, setSignModel] = useState({ show: false })
    const [tableLoader, settableLoader] = useState(false)

    const pandadocapikey = props?.dealersettings?.client?.integrations?.filter(e => e.type === "pandadoc")?.[0]?.settings?.api_key

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth - 30,
                windowHeight: window.innerHeight - 123,
            });
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            //window.unSubscribeCompanies && window.unSubscribeCompanies();
        };
    }, []);

    useEffect(() => {
        if (!dataLoad)
            return

        const listDocument = window.firebase.functions().httpsCallable('pandadoc-listDocument');
        listDocument({
            "api_key": pandadocapikey,
            "clientID": props?.dealersettings?.client?.id,
            pageNum
        }).then((resp) => {
            setLoader(false);
            setDataload(false)
            settableLoader(false)
            if (resp?.data?.success) {
                setdocumentList([
                    ...documentList,
                    ...convertVM(resp?.data?.data?.results)
                ])
            }
        });

    }, [dataLoad])

    const handleSignRequest = () => {
        setrecpModel({
            show: true,
            title: 'Review recipients',
            saveDoc: true,
            apikey: pandadocapikey,
            newDoc: true
        })
    }

    const handleActionClick = (id, data) => {
        return (<>
            {
                data.status === 'completed' ? (
                    <div style={{ cursor: 'pointer' }} className="mini-button mr-2" onClick={(e) => {
                        hadleDownload(data)
                    }}>
                        <i className="ico icon-mail-download"></i>
                    </div>
                ) : (<></>)
            }
            {
                data.status === 'draft' ? (
                    <div style={{ cursor: 'pointer' }} className="mini-button mr-2" onClick={(e) => {
                        setrecpModel({
                            show: true,
                            title: 'Review recipients',
                            saveDoc: true,
                            apikey: pandadocapikey,
                            document_id: data.id
                        })
                    }}>
                        <i className="ico icon-zoom"></i>
                    </div>
                ) : (<></>)
            }
            <div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                handleDelete(data)
            }}>
                <i className="ico icon-delete"></i>
            </div>
        </>)
    }

    const hadleDownload = (data) => {
        settableLoader(true)
        const options = {
            method: 'GET',
            headers: {
                accept: 'application/json',
                Authorization: `API-Key ${pandadocapikey}`
            }
        };
        // Fetch the original image
        fetch(`https://api.pandadoc.com/public/v1/documents/${data.id}/download`, options)
            // Retrieve its body as ReadableStream
            .then((response) => {
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                // When no more data needs to be consumed, close the stream
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    },
                });
            })
            // Create a new response out of the stream
            .then((stream) => new Response(stream))
            // Create an object URL for the response
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = `SINGED-document-${moment().format('X')}.pdf`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                settableLoader(false)
            })
            .catch((err) => console.error(err));
    }

    const handleDelete = (data) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            html: `Do you want to delete?.`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                settableLoader(true)
                const deleteDocument = window.firebase.functions().httpsCallable('pandadoc-deleteDocument');
                deleteDocument({
                    "api_key": pandadocapikey,
                    id: data.id
                }).then((resp) => {
                    if (resp?.data?.success) {
                        settableLoader(false)
                        setdocumentList(documentList.filter(a => a.id !== data.id));
                    } else {
                        Swal.fire(resp.data.message || 'Something went wrong', '', 'error')
                    }
                });
            }
        })
    }

    const handlePagination = () => {
        setPageNum(pageNum + 1)
        setDataload(true);
    }

    const convertVM = (list) => {
        if (list?.length) {
            return list.map(r => {
                const _sts = r?.status?.replace('document.', '') || '';
                return {
                    ...r,
                    status: _sts,
                    statusDOM: (<div className={`badge badge-pill badge-${_sts}-fill`}>{_sts}</div>),
                    createdOn: r.date_created ? moment(r.date_created).format('DD/MM/YYYY hh:mm A') : '--',
                    completedOn: r.date_completed ? moment(r.date_completed).format('DD/MM/YYYY hh:mm A') : '--'
                }
            })
        }
        return [];
    }

    return (
        <>
            <div className="middle-wrapper">
                <div className="filter-panel">
                    <div className="float-left fleet-head">
                        <h3><Translate text={'Overview'} /></h3>
                    </div>
                    <div className="float-right">
                        <div className="filter-add-button ml-3">
                            <button type="button" className="btn btn-primary" onClick={(e) => { handleSignRequest() }}> <i className="ico icon-sign"></i><Translate text={'requestSignature'} /></button>
                        </div>
                    </div>
                </div>
                {
                    loader ? (<div className="mt-5" style={{ height: `${window.innerHeight - 340} px` }}><ContentOverlay active={true} /></div>) : documentList?.length === 0 ? (
                        <div className="common-table">
                            <div className="text-center p-5">
                                <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                <div className="no-data-txt mt-2"> No records found</div>
                            </div>
                        </div>
                    ) : (<div className="common-table" style={{ overflowX: 'auto' }}>
                        <div className="common-table">
                            {
                                tableLoader
                                    ?
                                    <>
                                        <div className="dashboard-widget-loader h-100">
                                            <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                    :
                                    <>
                                    </>
                            }
                            <TableView
                                datalist={documentList}
                                height={windowSize.windowHeight}
                                width={windowSize.windowWidth}
                                columns={tableFields}
                                dynamicFields={tableFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                handleActionClick={handleActionClick}
                                handlePagination={handlePagination}
                                isPaging={true}
                                hasMore={[{ a: 1 }]}
                            />
                            {/* <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col" className="head-light" width="40%">Title</th>
                                        <th scope="col" className="head-light" width="10%">Status</th>
                                        <th scope="col" className="head-light" width="15%">Recipients</th>
                                        <th scope="col" className="head-light" width="15%">Status updated</th>
                                        <th scope="col" className="head-light" width="15%">Last action</th>
                                        <th scope="col" className="head-light" width="5%">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>

                                        <td><div className="docsign-title-wrap">
                                            <span className="docsign-active-icon"> <i className="ico icon-files-document"></i></span>
                                            <div>
                                                McLaren Dubai - Sales Contract 2024
                                                <div className="docsign-user-text">by Mohammed Thaha</div>
                                            </div>
                                        </div></td>
                                        <td><div className="badge badge-pill badge-draft-fill">Draft</div></td>
                                        <td><div>

                                            <div className="float-left"><div className="docsign-users pl-1"><a href="#"><img src="https://ui-avatars.com/api/?size=128&amp;name=Pramith Fusion&amp;font-size=0.5&amp;bold=true&amp;color=fff&amp;background=8cd9b9" width="300" height="300" alt="" className="rounded-circle img-object-fit" /></a></div></div>
                                            <div className="float-left"><div className="docsign-users pl-1"><a href="#"><img src="https://ui-avatars.com/api/?size=128&amp;name=Williams David&amp;font-size=0.5&amp;bold=true&amp;color=fff&amp;background=8cd9d9" width="300" height="300" alt="" className="rounded-circle img-object-fit" /></a></div></div>
                                        </div></td>
                                        <td>	Oct 1, 2024</td>
                                        <td>created by Mohammed Thaha <br />
                                            Oct 1, 2024</td>
                                        <td><div style={{ width: '90px', float: 'left' }}>
                                            <div className="action-fix">
                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button"> <i className="ico icon-mail-download" style={{ fontSize: '16px' }}></i></a></div>
                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button"> <i className="ico icon-delete"></i></a></div>
                                            </div>
                                        </div></td>


                                    </tr>

                                </tbody>
                            </table> */}
                        </div>
                    </div >
                    )
                }
            </div>
            <PopUpModal show={recpModel.show}>
                <ViewRecipient
                    {...recpModel}
                    clientUsers={props.clientUsers}
                    dealersettings={props.dealersettings}
                    uploadDoc={true}
                    handleClose={(val, data) => {
                        if (val && data) {
                            const __settings = props.dealersettings?.client?.integrations?.filter(e => e.type === "pandadoc")?.[0]?.settings || {};
                            setSignModel({
                                show: true,
                                title: 'Edit Document',
                                document: data,
                                iframeID: moment().format('X'),
                                payload: val,
                                apikey: pandadocapikey,
                                ...__settings
                            })
                        }
                        setrecpModel({ show: false })
                    }}
                />
            </PopUpModal>
            <PopUpModal show={signModel.show}>
                <PandaDocEditor
                    {...signModel}
                    handleClose={() => {
                        setSignModel({ show: false });
                        setdocumentList([]);
                        setPageNum(1)
                        setDataload(true)
                        setLoader(true)
                    }}
                />
            </PopUpModal>
        </>
    )
}

export default DocumentList;