import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Modal, Tabs, Tab, Dropdown } from 'react-bootstrap'
import { InputText, ReactSelect, ImageCropHook, PopUpModal, ContentOverlay, ReactMultiSelect } from '../../../../components'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { mandatoryFields, usersVM, levelOptions, multiOEMDynCols } from './viewModel'
import _ from 'lodash'
import moment from 'moment';
import { default as _images } from "../../../../images";
import UpdatePassword from './updatePassword'
import { validateEmail } from "../../../../services/helper";
import { ClientContext } from '../../../layout/clientContext'
import AddRole from './addRole'
import { CustomToggle, CustomSearchMenu } from '../../../../components/customdropdown';
import CommonHelper from '../../../../services/common'
import MultiOEMAccess from './multioem'


const AddClient = (props) => {
    const [user, setUser] = useState(props.user);
    const [loader, setLoader] = useState(false)
    const [verifyLoader, setVerifyLoader] = useState(false)
    const [errorFields, setErrorFields] = useState({});
    const [cropShow, setCropShow] = useState({ show: false, clsActive: '', src: null, id: '', type: '', ext: '', title: '' })
    const [imageLoader, setImageLoader] = useState(false)
    const [showPasswordModel, setShowPasswordModel] = useState(false)
    const [clientSearch, setClientSearch] = useState('')
    const newUser = !_.isEmpty(props.user) ? false : true
    const arruserClients = !_.isEmpty(props.user) && !_.isEmpty(props.user.arrayClientID) ? props.user.arrayClientID : []
    const [userClients, setUserClients] = useState([])
    const [olduserClients, setOldUserClients] = useState([])
    const [departments, setDepartments] = useState([])
    const [roles, setRoles] = useState([])
    const [roleLoader, setRoleLoader] = useState(true)
    const [selectedClientID, setSelectedClientID] = useState('')
    const [deleteuserClients, setDeleteUserClients] = useState([])
    const [regionGroupOptions, setRegionGroupOptions] = useState([])
    const [clientGroups, setClientGroups] = useState([]);
    const [clientRegions, setClientRegions] = useState([]);
    const [mandFields, setMandFields] = useState(mandatoryFields)
    const { clients, countryLookups, oemSettings } = useContext(ClientContext);
    const oldEmailaddress = !_.isEmpty(props.user) ? props.user.email : true
    const oldDepartment = !_.isEmpty(props.user) ? props.user.department : []
    const [showRoleModel, setShowRoleModel] = useState(false)
    const [popHeader, setPopHeader] = useState('')
    const [showMFAEnableModel, setShowMFAEnableModel] = useState(false)
    const [phoneCodes, setphoneCodes] = useState([])
    const [mfaLoader, setmfaLoader] = useState(false);
    const [dynFormValues, setDynFromValues] = useState({})
    const [selectedOEM, setSelectedOEM] = useState('')

    useEffect(() => {
        if (_.isEmpty(props.user)) {
            const newuserVM = Object.assign({}, usersVM);
            newuserVM.addedDate = window.firebase.firestore.Timestamp.now();
            newuserVM.addedBy = localStorage.uid;
            newuserVM.settingsID = props.selectedOEM.value;
            setUser(newuserVM)
        }
    }, [])

    useEffect(() => {
        let _phoneCodes = [];
        countryLookups.forEach((doc) => {
            if (doc.phone_code) {
                _phoneCodes.push({
                    country_code: doc.code,
                    value: doc.phone_code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name + ' (' + (doc.phone_code ? doc.phone_code : '--') + ')'
                });
            }
        });
        setphoneCodes(_phoneCodes);
    }, [])

    useEffect(() => {
        if (!_.isEmpty(props.selectedOEM) && !_.isEmpty(props.selectedOEM.data.departments)) {
            var _depts = [];
            props.selectedOEM.data.departments.forEach(rec => {
                _depts.push({ label: rec.name, value: rec.value })
            })
            setDepartments(_depts);
            setMandFields([...mandFields, "department"])
        }
        else {
            setMandFields([...mandFields.filter(item => item !== "department")])
        }
    }, [])

    useEffect(() => {
        setRoleLoader(true)
        const oemroleSnapshot = window.firebase.firestore().collection(`clientSettings/${props.selectedOEM.value}/roles`)
            .orderBy('addedDate', 'asc')
            .onSnapshot(querySnapshot => {
                let _roles = [];
                querySnapshot.forEach((rec) => {
                    _roles.push({ ...rec.data(), 'documentID': rec.id })
                })
                setRoles(_roles)
                setRoleLoader(false)
            })
        return () => {
            oemroleSnapshot && oemroleSnapshot();
        }
    }, [])

    useEffect(() => {
        const groupSnapshot = window.firebase.firestore().collection(`clientSettings/${props.selectedOEM.value}/groups`)
            .orderBy('addedDate', 'asc')
            .onSnapshot(querySnapshot => {
                let _groups = [];
                querySnapshot.forEach((rec) => {
                    _groups.push({ ...rec.data(), 'documentID': rec.id, value: rec.id, label: rec.data().name })
                })
                setClientGroups(_groups)
            })

        const regionSnapshot = window.firebase.firestore().collection(`clientSettings/${props.selectedOEM.value}/regions`)
            .orderBy('name', 'asc')
            .onSnapshot(querySnapshot => {
                let _regions = [];
                querySnapshot.forEach((rec) => {
                    if (clients.some(c => c.settingsID === props.selectedOEM.value && c.region === rec.id))
                        _regions.push({ ...rec.data(), 'documentID': rec.id, value: rec.id, label: rec.data().name })
                })
                setClientRegions(_regions)
            })
        return () => {
            groupSnapshot && groupSnapshot();
            regionSnapshot && regionSnapshot();
        }
    }, [])


    useEffect(() => {
        if (_.isEmpty(user) || _.isEmpty(user.roleID) || _.isEmpty(clientRegions))
            return

        if (user.level === 'region') {
            setRegionGroupOptions([{ label: 'All', value: 'all', clientIDs: [] }, ...clients.filter(c => c.settingsID === props.selectedOEM.value && (!_.isEmpty(user.arrayRegionID) ? user.arrayRegionID.includes(c.region) : true)).map((rec) => { return { value: rec.documentID, label: rec.name, doc: rec, clientIDs: [rec.documentID] } })])
            setMandFields([...mandFields.filter(item => item !== "arrayGroupID"), "arrayClientID", "arrayRegionID"])
        }
        else if (user.level === 'group') {
            setRegionGroupOptions([{ label: 'All', value: 'all', clientIDs: [] }, ...clients.filter(c => c.settingsID === props.selectedOEM.value && (!_.isEmpty(user.arrayGroupID) ? user.arrayGroupID.includes(c.group) : true)).map((rec) => { return { value: rec.documentID, label: rec.name, doc: rec, clientIDs: [rec.documentID] } })])
            setMandFields([...mandFields.filter(item => item !== "arrayRegionID"), "arrayClientID", "arrayGroupID"])
        }
        else if (user.level === 'individual' || (user.level === 'oem' && user.role !== 'Administrator')) {
            setRegionGroupOptions([{ label: 'All', value: 'all', clientIDs: [] }, ...clients.filter(c => c.settingsID === props.selectedOEM.value).map((rec) => { return { value: rec.documentID, label: rec.name, doc: rec, clientIDs: [rec.documentID] } })])
            setMandFields([...mandFields.filter(item => item !== "arrayRegionID" && item !== "arrayGroupID"), "arrayClientID"])
        }
        else {
            setRegionGroupOptions([])
            setMandFields([...mandFields.filter(item => item !== "arrayRegionID" && item !== "arrayGroupID" && item !== "arrayClientID")])
        }
    }, [user, clients, clientRegions])

    useEffect(() => {
        if (_.isEmpty(clients) || _.isEmpty(oemSettings))
            return

        let _values = {};
        _values.oem = oemSettings?.map(r => { return { label: r.name, value: r.id } })
        oemSettings.forEach(rec => {
            _values = {
                ..._values,
                [rec.id]: clients.filter(a => a.settingsID === rec.id)?.map(r => { return { label: r.name, value: r.documentID } })
            }
        })
        if (user?.arrayOEMID?.length) {
            let allRolePromise = [];
            user.arrayOEMID.filter(a => a !== user.settingsID).forEach(r => {
                allRolePromise.push(window.firebase.firestore().collection(`clientSettings/${r}/roles`).get());
            })
            Promise.all(allRolePromise)
                .then(snaps => {
                    snaps.forEach(snapshot => {
                        _values = {
                            ..._values,
                            [`${snapshot.docs[0].ref.parent.parent.id}-roles`]: snapshot.docs.map(r => { return { label: r.data().name, value: r.id } })
                        }
                    })
                    setDynFromValues(_values)
                })
        } else {
            setDynFromValues(_values)
        }

    }, [])

    useEffect(() => {
        if (_.isEmpty(selectedOEM) || !_.isEmpty(dynFormValues[`${selectedOEM}-roles`])) return;

        window.firebase.firestore().collection(`clientSettings/${selectedOEM}/roles`)
            .get()
            .then(snapshot => {
                setDynFromValues({
                    ...dynFormValues,
                    [`${selectedOEM}-roles`]: snapshot.docs.map(r => { return { label: r.data().name, value: r.id } })
                })
            })
    }, [selectedOEM])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (name === 'email' || name === 'password' || name === 'cpassword') {
            setUser({
                ...user,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setUser({
                    ...user,
                    [name]: newarray1.join(' ')
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setUser({
                    ...user,
                    [name]: str
                });
            }
        }

        errorChange(name);

    }

    const handleReactSelectChange = (e, data) => {
        if (e) {
            if (data.name === 'roleID') {
                setUser({
                    ...user,
                    [data.name]: e.value,
                    ['role']: e.doc.name,
                    ['level']: e.doc.level,
                    ['arrayRegionID']: [],
                    ['arrayGroupID']: [],
                    ['regionID']: null,
                    ['groupID']: null,
                    ['arrayClientID']: (e.doc.level === 'oem' && e.doc.name === 'Administrator') ? clients.filter(c => c.settingsID === props.selectedOEM.value).map(v => _.pick(v, ['documentID']).documentID) : []
                });
            }
            else {
                setUser({
                    ...user,
                    [data.name]: e.value
                });
            }
            setErrorFields({
                ...errorFields,
                [data.name]: ''
            });
        }
        else {
            setUser({
                ...user,
                [data.name]: ''
            });
        }



    }

    const errorChange = (key) => {
        let errorClass = 'input_error';

        if (errorFields !== {}) {
            if (!user[key] && mandatoryFields.indexOf(key) >= 0) {
                setErrorFields({
                    ...errorFields,
                    [key]: errorClass
                });
            }
            else {
                if (key === 'email') {
                    if (typeof user[key] !== "undefined") {
                        if (!validateEmail(user[key])) {
                            setErrorFields({
                                ...errorFields,
                                [key]: errorClass
                            });
                        }
                        else
                            setErrorFields({
                                ...errorFields,
                                [key]: ''
                            });
                    }
                }
                else
                    setErrorFields({
                        ...errorFields,
                        [key]: ''
                    });
            }


        }

    }

    const saveUser = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        for (let [key] of Object.entries(user)) {
            if (mandFields.indexOf(key) >= 0) {
                if (_.isEmpty(user[key])) {
                    formIsValid = false;
                    errors[key] = errorClass;

                }
                if (key === 'email') {
                    if (typeof user[key] !== "undefined") {
                        if (!validateEmail(user[key])) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                }
            }
        }
        if (newUser) {
            if (_.isEmpty(user['password'])) {
                formIsValid = false;
                errors['password'] = errorClass;
            }
            if (user['password'] !== user['cpassword']) {
                formIsValid = false;
                errors['cpassword'] = errorClass;
            }
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        if (!_.isEmpty(user.arrayOEM)) {
            user.arrayOEMID = user.arrayOEM.filter(a => a.oem !== user.settingsID).map(a => { return a.oem })
            user.arrayOEMID.push(user.settingsID);
            user.arrayOEM = user.arrayOEM.filter(a => a.oem !== user.settingsID)
            user.arrayOEM.push({
                oem: user.settingsID,
                roleID: user.roleID,
                clients: user?.roleName === 'Administrator' ? [] : user?.arrayClientID
            })
        } else {
            user.arrayOEMID = [];
        }
        setLoader(true)
        if (newUser === true) {
            const addUser = window.firebase.functions().httpsCallable('amsadmin-createUser');
            addUser({
                "email": user.email,
                "password": user.password
            }).then(async (data) => {
                //console.log(data)
                if (data.data.success === true) {
                    user.documentID = data.data.data;
                    updateUser(user)
                }
                else {
                    const existingWholesaler = await window.firebase.firestore().collection('users')
                        .where('email', '==', user.email)
                        .where('isWholesaler', '==', true)
                        .limit(1)
                        .get();
                    if (existingWholesaler.docs && existingWholesaler.docs.length > 0) {
                        updateUser({
                            ...existingWholesaler.docs[0].data(),
                            ...user,
                            documentID: existingWholesaler.docs[0].id
                        });
                    }
                    else {
                        setLoader(false)
                        Swal.fire(data.data.message, '', 'error')
                    }
                }
            });
        }
        else if (user.email !== oldEmailaddress) {
            const addUser = window.firebase.functions().httpsCallable('amsadmin-updateUserEmail');
            addUser({
                "email": user.email,
                "uid": user.documentID
            }).then((data) => {
                //console.log(data)
                if (data.data.success === true) {
                    //user.documentID = data.data.data;
                    updateUser(user)
                }
                else {
                    setLoader(false)
                    Swal.fire(data.data.message, '', 'error')
                }
            });
        }
        else {
            updateUser(user)
        }
    }

    const updateUser = (user) => {
        if (_.isEmpty(user) || _.isEmpty(user.documentID)) {
            Swal.fire('Invalid user.', '', 'info')
            return
        }
        user.modifiedDate = window.firebase.firestore.Timestamp.now();
        user.modifiedBy = localStorage.uid
        if (user.arrayClientID.length > 0 && (_.isEmpty(user.defaultClientID) || !user.arrayClientID.some(e => e === user.defaultClientID)))
            user.defaultClientID = user.arrayClientID[0]
        if (user.arrayRegionID.length > 0 && (_.isEmpty(user.regionID) || !user.arrayRegionID.some(e => e === user.regionID)))
            user.regionID = user.arrayRegionID[0]
        if (user.arrayGroupID.length > 0 && (_.isEmpty(user.groupID) || !user.arrayGroupID.some(e => e === user.groupID)))
            user.groupID = user.arrayGroupID[0]
        delete user.password
        delete user.cpassword
        if (oldDepartment !== user.department) {
            user.arrayClientID.forEach(clientid => {
                window.firebase.firestore().doc(`users/${user.documentID}/userClients/${clientid}`).set({ isQueue: false, queueNum: 0 }, { merge: true })
            })
            user.arrayClientID.forEach(clientid => {
                window.firebase.firestore().doc(`users/${user.documentID}/userClients/${clientid}`).set({ 'department': user.department }, { merge: true })
            })
        }
        window.firebase.firestore().doc(`users/${user.documentID}`).set(user, { merge: true })
            .then(snapshot => {
                if ((JSON.stringify(arruserClients) !== JSON.stringify(user.arrayClientID))) {
                    _.differenceWith(clients.map(v => _.pick(v, ['documentID']).documentID), user.arrayClientID, _.isEqual).forEach(_userClient => {
                        window.firebase.firestore().doc(`users/${user.documentID}/userClients/${_userClient}`).delete();
                    })
                    _.filter(clients, (v) => _.indexOf(_.uniq(_.map(user.arrayClientID)), v.documentID) >= 0).forEach(_userClient => {
                        window.firebase.firestore().doc(`users/${user.documentID}/userClients/${_userClient.documentID}`)
                            .set({
                                name: _userClient.name,
                                department: user.department,
                                roleID: user.roleID
                            }, { merge: true });
                    })
                }
                setLoader(false)
                toast.notify((newUser === true ? 'User added successfully' : 'User updated successfully'), {
                    duration: 2000
                })
                props.handleClose(user)
            })
            .catch(error => {
                setLoader(false)
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }


    const onSelectFile = (e, type, id, title) => {

        if (e.target.files && e.target.files.length > 0) {
            if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
                Swal.fire('File format not supported. Please select image file.', '', 'info')
                return;
            }
            var img = e.target.files[0].size;
            var imgsize = img / 1024 / 1024;
            if (imgsize > 10) {
                Swal.fire('Maximum file size exceeded.', '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {

                setCropShow({
                    show: true,
                    clsActive: 'overlay-modal active',
                    src: reader.result,
                    id: id,
                    type: type,
                    ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                    title: title
                })
            }
            reader.readAsDataURL(file)
        }
    }

    const handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";
        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: ''
        })
    }

    const handleCropSelect = (cropShow) => {

        const { src, id, type, ext } = cropShow;
        setUser({
            ...user,
            [id]: src
        });
        saveImageToStorage(src, id, type, ext);


        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: ''
        })
    }

    const saveImageToStorage = (imageBase64, id, type, ext) => {

        let uid = moment()._d.getTime().toString() + '.' + ext;
        setImageLoader(true)
        var storageRef = window.firebase.storage().ref(`users/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;

        },
            (error) => {
                //console.log(error);
            },
            () => {
                window.firebase.storage().ref(`users`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        setImageLoader(false)
                        setUser({
                            ...user,
                            [id]: dataURL
                        });
                    })
            })
    };

    const handlePasswordModelClose = () => {
        setShowPasswordModel(false)
    }

    const clientList = useMemo(() => clients
        .filter(
            ({ name, isActive }) => (isActive === true && (!_.isEmpty(clientSearch) ? name.toLowerCase().includes(clientSearch.toLowerCase()) : true))
        )
        .sort(function (x, y) {
            return (x.modifiedDate.seconds > y.modifiedDate.seconds ? -1 : 1);
        }), [clients, clientSearch])

    const handleReactMultiSelectChange = (selectedOptions) => {

        const value = [];
        let clientids = [];
        if (selectedOptions.length > 0 && selectedOptions.some(item => item.value === 'all')) {
            clientids = regionGroupOptions.filter(e => e.value !== 'all').map(rec => { return rec.value })
        }
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value);
            data.clientIDs.forEach(r => { clientids.push(r) })
        })

        setUser({
            ...user,
            ['arrayClientID']: _.uniq(clientids)
        });
    }

    const handleReactOEMMultiSelectChange = (selectedOptions, name) => {

        const value = [];

        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value);
        })

        setUser({
            ...user,
            [name]: _.uniq(value),
            ['arrayClientID']: (name === 'arrayRegionID' || name === 'arrayGroupID') ? [] : user.arrayClientID
        });
    }
    const handleModelClose = (role) => {
        setShowRoleModel(false)
        if (!_.isEmpty(role)) {
            setUser({
                ...user,
                roleID: role.documentID,
                role: role.name,
                level: role.level
            })
        }
    }

    const emailVerification = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        let _mandFields = ["name", "email"];
        for (let [key] of Object.entries(user)) {
            if (_mandFields.indexOf(key) >= 0) {
                if (_.isEmpty(user[key])) {
                    formIsValid = false;
                    errors[key] = errorClass;

                }
                if (key === 'email') {
                    if (typeof user[key] !== "undefined") {
                        if (!validateEmail(user[key])) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                }
            }
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setVerifyLoader(true);
        try {
            const emailVerificationLink = window.firebase.functions().httpsCallable('amsadmin-sendNewUserEmail');
            emailVerificationLink({
                "email": user.email,
                "name": user.name
            }).then((data) => {
                setVerifyLoader(false);
                if (data.data.success === true) {
                    toast.notify('Email verification link sent successfully.', {
                        duration: 2000
                    })
                }
                else {
                    Swal.fire(data.data.message, '', 'error')
                }
            });
        }
        catch (error) {
            setVerifyLoader(false);
            Swal.fire('Something went wrong. Please contact support.', '', 'error')
        }
    }

    const handleMFAChange = (cheked) => {
        if (cheked) {
            setShowMFAEnableModel(true);
        }
        else {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to disable multi-factor authentication?',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.value) {
                    handleEnableMFA(cheked)
                }
            })

        }
    }

    const handleMFAModelClose = (user) => {
        setShowMFAEnableModel(false);
        if (user)
            setUser({
                ...user,
                phone: user.phone,
                phoneCode: user.phoneCode
            })
    }

    const handlePhoneChange = e => {
        const { name, value } = e.target;
        const phoneReg = /^[0-9]+$/;
        if (phoneReg.test(value) || value === '') {
            setUser({ ...user, [name]: value });
        }
    };

    const handleAddPhoneCode = (name, value) => {
        let val = value ? value : '';
        setUser({ ...user, [name]: val });
    };

    const handleEnableMFA = (checked) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (checked) {
            if (_.isEmpty(user['phone']) || _.isEmpty(user['phoneCode'])) {
                formIsValid = false;
                errors['phone'] = errorClass;
            }
            setErrorFields(errors)
            if (!formIsValid) {
                return;
            }
        }
        setmfaLoader(true);
        try {
            const enrollMFA = window.firebase.functions().httpsCallable('amsadmin-enrollMFA');
            enrollMFA({
                "uid": user.documentID,
                "phone": `+${user.phoneCode.replace('+', '')}${user.phone}`,
                "enroll": checked
            }).then((data) => {
                setmfaLoader(false);
                if (data.data.success === true) {
                    let updateUser = {
                        phoneCode: user.phoneCode,
                        phone: user.phone,
                        enableMFA: checked
                    }
                    setUser({
                        ...user,
                        ...updateUser
                    })
                    setShowMFAEnableModel(false);
                    window.firebase.firestore().doc(`users/${user.documentID}`).set(updateUser, { merge: true });
                    toast.notify(`Multi-factor authentication ${checked ? 'enabled' : 'disabled'} successfully.`, {
                        duration: 2000
                    })
                }
                else {
                    let msg = data.data.message;
                    if (data.data.data.errorInfo && data.data.data.errorInfo.code === 'auth/invalid-phone-number') {
                        msg = 'Invalid phone number.'
                    }
                    Swal.fire(msg, '', 'error');
                }
            });
        }
        catch (error) {
            setmfaLoader(false);
            Swal.fire('Something went wrong. Please contact support.', '', 'error')
        }
    }

    return _.isEmpty(user) ? (<></>) : (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-add-user"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="form-style w-100">
                                <div className="form-row settings-profile pt-2">

                                    <div className="settings-profileimage">
                                        {
                                            (imageLoader)
                                                ?
                                                <div className="img-loader pl-2">
                                                    <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                        <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            (!_.isEmpty(user.profileImage))
                                                ?
                                                <a data-fancybox={`profileImage`} href={user.profileImage} onClick={(e) => { e.preventDefault(); }}>
                                                    <img src={user.profileImage} alt="" className="rounded-circle img-object-fit" />
                                                </a>
                                                :
                                                <img src={_images.nouser} alt="" className="rounded-circle img-object-fit" />
                                        }

                                    </div>
                                    <div className="settings-profile-info">
                                        <div className="settings-profile-upload">
                                            <label htmlFor="profileImage" className={`btn-common float-left mr-2 font-600`}>Choose Picture
                                                <input className="fileInput"
                                                    type="file"
                                                    name="profileImage"
                                                    id="profileImage"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => onSelectFile(e, 'users', 'profileImage', 'Profile Image')} />
                                            </label>
                                            <div className="settings-delete-button"> <a href="#" className="mini-button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setUser({
                                                        ...user,
                                                        ['profileImage']: ''
                                                    });
                                                }}> <i className="ico icon-delete"></i></a></div>

                                        </div>
                                        <div className=" settings-limit">Max size 2 MB. Formats: JPG, GIF, PNG.</div>
                                    </div>

                                </div>
                                <div className="settings-tab-wrap tabs">
                                    <div className="tabs-wraper">
                                        <nav>
                                            <Tabs defaultActiveKey="login" className="nav-fill" mountOnEnter={true}>
                                                <Tab eventKey="login" title="Login Details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label >Name</label>
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'name'}
                                                                className={`form-control ${errorFields["name"]}`}
                                                                name="name"
                                                                onChange={handleOnChange}
                                                                value={user.name}
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label >Email</label>
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'email'}
                                                                className={`form-control ${errorFields["email"]}`}
                                                                name="email"
                                                                onChange={handleOnChange}
                                                                value={user.email}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-row">

                                                        <div className="form-group col-md-6">
                                                            <a href="#" className={`inputlink-addmore ycustom-more`}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowRoleModel(true)
                                                                    setPopHeader('Add Role')
                                                                }}>
                                                                + add
                                                            </a>
                                                            <label>Role</label>
                                                            <ReactSelect
                                                                options={_.map(roles, function (c) {
                                                                    return {
                                                                        value: c.documentID,
                                                                        doc: c,
                                                                        label: <>{c.name}<span className="user-role-span">{_.find(levelOptions, { value: c.level }) ? _.find(levelOptions, { value: c.level }).label : c.level}</span></>,
                                                                        searchlabel: c.name
                                                                    };
                                                                })}
                                                                name={"roleID"}
                                                                placeholder={'select role'}
                                                                onChange={handleReactSelectChange}
                                                                value={!roleLoader ? user.roleID : ''}
                                                                classNamePrefix={`${errorFields["roleID"]} cursor-pointer basic-select`}
                                                                removeClearable={true}
                                                            >
                                                            </ReactSelect>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label >Department</label>
                                                            <ReactSelect
                                                                options={departments}
                                                                name={"department"}
                                                                placeholder={'select department'}
                                                                onChange={handleReactSelectChange}
                                                                value={user.department}
                                                                classNamePrefix={`${errorFields["department"]} cursor-pointer basic-select`}
                                                                removeClearable={true}
                                                            >
                                                            </ReactSelect>
                                                        </div>
                                                    </div>
                                                    {
                                                        user.level === 'region' || user.level === 'group' ? (<div className="form-row">
                                                            <div className="form-group col-md-12">
                                                                <label>{user.level === 'region' ? 'Region' : 'Group'}</label>
                                                                <ReactMultiSelect
                                                                    options={user.level === 'region' ? clientRegions : clientGroups}
                                                                    name={`array${CommonHelper.autoCaps(user.level)}ID`}
                                                                    placeholder={`select ${user.level}`}
                                                                    onChange={(data) => handleReactOEMMultiSelectChange(data, `array${CommonHelper.autoCaps(user.level)}ID`)}
                                                                    value={user[`array${CommonHelper.autoCaps(user.level)}ID`]}
                                                                    classNamePrefix={`${errorFields["arrayRegionID"]} ${errorFields["arrayGroupID"]} basic-select`}
                                                                    isMulti={true}
                                                                    isSearchable={true}
                                                                >
                                                                </ReactMultiSelect>
                                                            </div>
                                                        </div>) : (<></>)
                                                    }
                                                    {
                                                        (!_.isEmpty(user.level) && (user.level !== 'oem' || user.role !== 'Administrator')) ? (<div className="form-row">
                                                            <div className="form-group col-md-12">
                                                                <label >Client</label>
                                                                <ReactMultiSelect
                                                                    options={regionGroupOptions}
                                                                    name={'arrayClientID'}
                                                                    placeholder={'select client'}
                                                                    onChange={handleReactMultiSelectChange}
                                                                    value={user.arrayClientID}
                                                                    classNamePrefix={`${errorFields["arrayClientID"]} basic-select`}
                                                                    isMulti={true}
                                                                    isSearchable={true}
                                                                >
                                                                </ReactMultiSelect>
                                                            </div>
                                                        </div>) : (<></>)
                                                    }
                                                    {/* <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <label >OEM</label>
                                                            <ReactMultiSelect
                                                                options={oemSettings.map(rec => {
                                                                    return {
                                                                        label: rec.name,
                                                                        value: rec.id
                                                                    }
                                                                })}
                                                                name={'arrayOEMID'}
                                                                placeholder={'select oem'}
                                                                onChange={(data) => handleReactOEMMultiSelectChange(data, 'arrayOEMID')}
                                                                value={user.arrayOEMID}
                                                                classNamePrefix={`${errorFields["arrayOEMID"]} basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div>
                                                    </div> */}
                                                    {
                                                        newUser ? (<div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label >Password</label>
                                                                <InputText
                                                                    type={'password'}
                                                                    autoComplete="off"
                                                                    placeholder={'password'}
                                                                    className={`form-control ${errorFields["password"]}`}
                                                                    name="password"
                                                                    onChange={handleOnChange}
                                                                    value={user.password}
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label >Confirm Password</label>
                                                                <InputText
                                                                    type={'password'}
                                                                    autoComplete="off"
                                                                    placeholder={'confirm password'}
                                                                    className={`form-control ${errorFields["cpassword"]}`}
                                                                    name="cpassword"
                                                                    onChange={handleOnChange}
                                                                    value={user.cpassword}
                                                                />
                                                            </div>
                                                        </div>
                                                        ) : (<></>)
                                                    }
                                                    {
                                                        !newUser ? (
                                                            <div className="form-row">
                                                                <div className="user-mfa-wrapper">
                                                                    <h4>Two Factor Authentication</h4>
                                                                    {
                                                                        user.enableMFA ? (<div className="mfa-green">
                                                                            <div className="float-left mt-1"><i className="ico icon-settings-security"></i> You have enabled Multi-factor Authentication </div>
                                                                            <div className="float-right ">
                                                                                <button type="button" className="btn btn-red float-left ml-2 mfa-enable-btn" onClick={() => handleMFAChange(false)}>
                                                                                    {
                                                                                        mfaLoader ?
                                                                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                                            : (<></>)
                                                                                    }
                                                                                    Disable</button>
                                                                            </div>
                                                                        </div>) : (<div className="mfa-blue">
                                                                            <div className="float-left mt-1"><i className="ico icon-settings-security"></i> Enable Multi-factor Authentication</div>
                                                                            <div className="float-right ">
                                                                                <button type="button" className="btn btn-default float-left ml-2 mfa-enable-btn" onClick={() => handleMFAChange(true)}> Enable</button>
                                                                            </div>
                                                                        </div>)
                                                                    }


                                                                </div>
                                                            </div>
                                                        ) : (<></>)
                                                    }
                                                </Tab>
                                                <Tab eventKey="other" title="Multi OEM Access">
                                                    <MultiOEMAccess
                                                        cols={multiOEMDynCols}
                                                        subList={user?.arrayOEM?.filter(a => a.oem !== user.settingsID)}
                                                        setSubList={(list) => { setUser({ ...user, arrayOEM: list }) }}
                                                        dragDisabled={true}
                                                        setSelectedOEM={(val) => setSelectedOEM(val)}
                                                        {...dynFormValues}
                                                    ></MultiOEMAccess>
                                                </Tab>
                                            </Tabs>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    {
                        newUser === false ?
                            (<>
                                <button type="button" className="btn btn-secondary float-left" onClick={() => { setShowPasswordModel(true) }}>Update Password</button>
                                <button type="button" className="btn btn-secondary float-left  ml-2" onClick={() => emailVerification()} >

                                    {
                                        verifyLoader ?
                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                            : (<><i className="ico icon-email"></i></>)
                                    }
                                    Send Email Invitation</button>
                            </>)
                            : (<></>)
                    }
                    <button type="button" className={`btn btn-primary float-right ml-2 ${imageLoader === true ? 'btn-disable' : ''}`} onClick={(e) => saveUser(e)}>
                        {
                            loader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        Save
                    </button>
                    <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} >Cancel</button>
                </Modal.Footer>
            </Modal>
            {
                showMFAEnableModel ? (
                    <Modal
                        show={showMFAEnableModel}
                        onHide={handleMFAModelClose}
                        backdrop="static"
                        backdropClassName={props.show ? 'overlay-modal active' : ''}
                        dialogClassName="modal-dialog-centered modal-update-password"
                        enforceFocus={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Enable Multi-factor authentication </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="form-style w-100">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label >Phone</label>
                                                <div className={`input-group country-code ${errorFields["phone"] ? 'phone_error' : ''}`}>
                                                    <div className='input-group-prepend'>
                                                        <Dropdown className={`btn btn-outline-secondary`}>
                                                            <Dropdown.Toggle as={CustomToggle}>
                                                                <span id='spn-code-name' data-name={user.phoneCode}>
                                                                    {user.phoneCode &&
                                                                        phoneCodes.find(item => item.value === user.phoneCode)
                                                                        ? phoneCodes.find(
                                                                            item => item.value === user.phoneCode,
                                                                        ).value + ' '
                                                                        : 'Code'}
                                                                </span>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu
                                                                as={CustomSearchMenu}
                                                                className='rm-pointers dropdown-menu-lg dropdown-menu-right'
                                                                ChildClass='pipeline-dropdown'
                                                                xplacement='bottom-end'
                                                            >
                                                                {!_.isEmpty(phoneCodes) && phoneCodes.length > 0 ? (
                                                                    phoneCodes.map((rec, index) => {
                                                                        return (
                                                                            <Dropdown.Item
                                                                                key={index}
                                                                                className={`current-pipeline ${user.phoneCode && rec.value === user.phoneCode
                                                                                    ? 'active'
                                                                                    : ''
                                                                                    }`}
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    handleAddPhoneCode(
                                                                                        'phoneCode',
                                                                                        rec.value,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {rec.label}
                                                                            </Dropdown.Item>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'phone'}
                                                        className={`form-control`}
                                                        name="phone"
                                                        onChange={handlePhoneChange}
                                                        value={user.phone}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="modal-footer">
                            <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => handleEnableMFA(true)}>
                                {
                                    mfaLoader ?
                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                        : (<></>)
                                }
                                Enable
                            </button>
                            <button type="button" className="btn btn-default float-right" onClick={() => handleMFAModelClose()} >Cancel</button>
                        </Modal.Footer>
                    </Modal>
                ) : (<></>)
            }
            <ImageCropHook
                cropShow={cropShow}
                handleClose={handleCropClose}
                handleSelect={handleCropSelect}
            ></ImageCropHook>
            <PopUpModal show={showPasswordModel}>
                <UpdatePassword
                    show={showPasswordModel}
                    handleClose={handlePasswordModelClose}
                    uid={!_.isEmpty(props.user) ? props.user.documentID : ''}
                />
            </PopUpModal>
            <PopUpModal show={showRoleModel}>
                <AddRole
                    show={showRoleModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    role={null}
                    settingsID={props.selectedOEM.value}
                    allRoles={roles}
                />
            </PopUpModal>
        </>
    )
}

export default AddClient