import React, { useState } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import NumberFormat from 'react-number-format';
import { ReactSelect } from '../../../components';

const AddSource = (props) => {
    const [loader, setLoader] = useState(false)
    const [state, setState] = useState({})
    const [errorFields, setErrorFields] = useState({})

    const handleSave = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};

        if (_.isEmpty(state['source'])) {
            formIsValid = false;
            errors['source'] = errorClass;
        }
        if (_.isEmpty(state?.['cost']?.toString())) {
            formIsValid = false;
            errors['cost'] = errorClass;
        }

        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true);
        window.firebase.firestore().doc(`reports/${props.marketingCost.documentID}`).update({
            [`${props.groupKey}Cost.${state.source}-${props.parentid}.totalCost`]: Number(state.cost)
        }).then(snapshot => {
            props.handleClose();
        });
    }

    const onCurrencyChange = (e, name) => {
        var regexp = /^[0-9+ \b]+$/;
        let number = e.floatValue;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setState({
                ...state,
                [name]: number
            });
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    };

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-update-password"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label >Source</label>
                                    <ReactSelect
                                        options={props.source.map(r => { return { label: r.name, value: r.value } })}
                                        name={"source"}
                                        placeholder={'select source'}
                                        onChange={(e, data) => {
                                            setState({
                                                ...state,
                                                [data.name]: e?.value
                                            })
                                            setErrorFields({
                                                ...errorFields,
                                                [data.name]: ''
                                            });
                                        }}
                                        value={state.source}
                                        classNamePrefix={`${errorFields['source']} cursor-pointer basic-select`}
                                        removeClearable={false}
                                    >
                                    </ReactSelect>
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Investment</label>
                                    <NumberFormat
                                        allowNegative={false}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        prefix={props.currencySymbol}
                                        placeholder={props.currencySymbol}
                                        className={`form-control ${errorFields['cost']}`}
                                        onValueChange={e => onCurrencyChange(e, 'cost')}
                                        value={state?.cost || ''}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => handleSave(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    Save
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} >Cancel</button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddSource;