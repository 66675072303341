export const contactFieldsVM = [

    {
        name: 'Title',
        value: 'title'
    },
    {
        name: 'First Name',
        value: 'firstName'
    },
    {
        name: 'Middle Name',
        value: 'middleName'
    },
    {
        name: 'Last Name',
        value: 'lastName'
    },
    {
        name: 'Preferred Name',
        value: 'preferredName'
    },
    {
        name: 'Phone',
        value: 'phone'
    },
    {
        name: 'Email',
        value: 'email'
    },
    {
        name: 'Phone or Email',
        value: 'phoneORemail'
    },
    {
        name: 'Phone, Email or License',
        value: 'phoneORemailORlicenseNo'
    },
    {
        name: 'Other Phones',
        value: 'phones'
    },

    {
        name: 'Other Emails',
        value: 'emails'
    },
    {
        name: 'Profile Image',
        value: 'userImageURL'
    },
    {
        name: 'License No',
        value: 'licenseNo'
    },
    {
        name: 'License Expiry',
        value: 'licenseExpiry'
    },
    {
        name: 'License Front',
        value: 'licenseFrontURL'
    },
    {
        name: 'License Back',
        value: 'licenseBackURL'
    },
    {
        name: 'License Type',
        value: 'licenseType'
    },
    {
        name: 'License State',
        value: 'licenseState'
    }, ,
    {
        name: 'Date of Birth',
        value: 'dob'
    },
    {
        name: 'Gender',
        value: 'gender'
    },
    {
        name: 'Marital Status',
        value: 'maritalStatus'
    },
    {
        name: 'Language',
        value: 'language'
    },
    {
        name: 'VIP Customer',
        value: 'isVIP'
    },
    {
        name: 'Contact Type',
        value: 'contactType'
    },
    {
        name: 'Address',
        value: 'address'
    },
    {
        name: 'State',
        value: 'state'
    },
    {
        name: 'Area Code',
        value: 'areaCode'
    },
    {
        name: 'Country',
        value: 'country'
    },
    {
        name: 'Nationality',
        value: 'nationality'
    },
    {
        name: 'Company',
        value: 'company'
    }
    ,
    {
        name: 'Designation',
        value: 'designation'
    },
    {
        name: 'Visiting Card',
        value: 'visitingCardURL'
    },
    {
        name: 'Contact Method',
        value: 'contactMethod'
    },
    {
        name: 'Interests',
        value: 'interests'
    },
    {
        name: 'Dependents',
        value: 'dependents'
    },
    {
        name: 'Driver Name',
        value: 'driverName'
    },
    {
        name: 'Origin',
        value: 'origin'
    },
    {
        name: 'Campaign',
        value: 'campaign'
    },
    {
        name: 'Opt-in Phone',
        value: 'optinPhone'
    },
    {
        name: 'Opt-in SMS',
        value: 'optinSMS'
    },
    {
        name: 'Opt-in Email',
        value: 'optinEmail'
    },
    {
        name: 'Opt-in Post',
        value: 'optinPost'
    },
    {
        name: 'Marketing Email Opt In',
        value: 'marketingOptinEmail'
    },
    {
        name: 'Marketing SMS Opt In',
        value: 'marketingOptinSMS'
    },
    {
        name: 'Secondary First Name',
        value: 'secondaryFirstName'
    },
    {
        name: 'Secondary Last Name',
        value: 'secondaryLastName'
    },
    {
        name: 'Secondary Phone',
        value: 'secondaryPhone'
    },
    {
        name: 'Secondary Email',
        value: 'secondaryEmail'
    },
    {
        name: 'Secondary Other Phones',
        value: 'secondaryPhones'
    },
    {
        name: 'Secondary Other Emails',
        value: 'secondaryEmails'
    },
    {
        name: 'Secondary License Front',
        value: 'secondaryLicenseFrontUrl'
    },
    {
        name: 'Secondary Back Front',
        value: 'secondaryLicenseBackUrl'
    }
];

export const companyFieldsVM = [
    {
        name: 'Name',
        value: 'name'
    },
    {
        name: 'Phone',
        value: 'phone'
    },
    {
        name: 'Email',
        value: 'email'
    },
    {
        name: 'Other Phones',
        value: 'phones'
    },
    {
        name: 'Other Emails',
        value: 'emails'
    },
    {
        name: 'Website',
        value: 'website'
    },
    {
        name: 'Company Type',
        value: 'type'
    },
    {
        name: 'Address',
        value: 'address'
    },
    {
        name: 'State',
        value: 'state'
    },
    {
        name: 'Area Code',
        value: 'areaCode'
    },
    {
        name: 'Country',
        value: 'country'
    },
    {
        name: 'ABN',
        value: 'companyAbnAcn'
    },
    {
        name: 'ACN',
        value: 'companyACN'
    },
    {
        name: 'RMS Number',
        value: 'companyRMS'
    },
    {
        name: 'Associated Company',
        value: 'associatedCompany'
    },
    {
        name: 'Company Owner',
        value: 'companyOwner'
    }
]

export const enquiryFieldsVM = [
    {
        name: 'Enquiry Type',
        value: 'enquiryType'
    },
    {
        name: 'Origin',
        value: 'origin'
    },
    {
        name: 'Campaign',
        value: 'campaign'
    },
    {
        name: 'Lead Source',
        value: 'leadSource'
    },
    {
        name: 'Label (Hot/Warm/Cold)',
        value: 'label'
    },
    {
        name: 'Purchase Intention',
        value: 'purchaseIntention'
    },
    {
        name: 'Deposit Amount',
        value: 'depositAmount'
    },
    {
        name: 'Deposit Recipt Number',
        value: 'depositReciptNumber'
    },
    {
        name: 'Gross Amount',
        value: 'grossAmount'
    },
    {
        name: 'Contract Number',
        value: 'contractNumber'
    },
    {
        name: 'Chassis Number',
        value: 'chassisNo'
    },
    {
        name: 'Reg. Number',
        value: 'regNo'
    },
    {
        name: 'Stock Number',
        value: 'stockNo'
    }, {
        name: 'Delivery Date',
        value: 'deliveryDate'
    },
    {
        name: 'Warranty Start Date',
        value: 'warrantyStartDate'
    },
    {
        name: 'Warranty Expiry',
        value: 'warrantyExpiry'
    },

    {
        name: 'Lost Reason',
        value: 'lostReason'
    },
    {
        name: 'Lost Sub Reason',
        value: 'lostSubReason'
    },
    {
        name: 'Contact',
        value: 'isContact'
    },
    {
        name: 'Requirement',
        value: 'isVehicle'
    },
    {
        name: 'Test Drive',
        value: 'isTestDrive'
    },
    {
        name: 'Trade-In',
        value: 'isTradeIn'
    },
    {
        name: 'Cafe',
        value: 'isCafe'
    },
    {
        name: 'Files',
        value: 'isFiles'
    },
    {
        name: 'Finance',
        value: 'isFinance'
    },
    {
        name: 'AMS Video',
        value: 'isVideo'
    },
    {
        name: 'Lost Notes',
        value: 'lostNotes'
    },
    {
        name: 'Notes',
        value: 'stageNotes'
    },
    {
        name: 'Finance Business Manager',
        value: 'financeBM'
    },
    {
        name: 'Finance Status',
        value: 'financeStatus'
    },
    {
        name: 'Finance Provider',
        value: 'financeProvider'
    },
    {
        name: 'Finance Start Date',
        value: 'financeStartDate'
    },
    {
        name: 'Finance Terms',
        value: 'financeTerms'
    },
    {
        name: 'Finance Amount',
        value: 'financeAmount'
    },
    {
        name: 'Finance Interest Rate',
        value: 'financeRate'
    },
    {
        name: 'After Market Manager',
        value: 'afterMarketBM'
    },
    {
        name: 'After Market Status',
        value: 'afterMarketStatus'
    },
]

export const fleetFieldsVM = [
    {
        name: 'Purpose',
        value: 'purpose'
    },
    {
        name: 'Trade Plate',
        value: 'tradePlate'
    },
    {
        name: 'RO Number',
        value: 'roNumber'
    },
    {
        name: 'Start Date',
        value: 'startDate'
    },
    {
        name: 'End Date',
        value: 'endDate'
    },
    {
        name: 'Start Odometer',
        value: 'startOdometer'
    },
    {
        name: 'End Odometer',
        value: 'endOdometer'
    },
    {
        name: 'Expected Return',
        value: 'expectedReturn'
    },
    {
        name: 'Before Inspection',
        value: 'beforeInspection'
    },
    {
        name: 'After Inspection',
        value: 'afterInspection'
    },
    {
        name: 'Start Fuel Gauge',
        value: 'startFuelGauge'
    },
    {
        name: 'End Fuel Gauge',
        value: 'endFuelGauge'
    },
    {
        name: 'Notes',
        value: 'notes'
    },
    {
        name: 'Insurance Terms',
        value: 'insurance'
    },
    {
        name: 'Terms & Condition',
        value: 'terms'
    },
    {
        name: 'Signature',
        value: 'afterSignatureURL'
    },
    {
        name: 'Staff License No',
        value: 'staffLicenseNo'
    },
    {
        name: 'Staff License Expiry',
        value: 'staffLicenseExpiry'
    },
    {
        name: 'Staff License Front',
        value: 'staffLicenseFront'
    },
    {
        name: 'Staff License Back',
        value: 'staffLicenseBack'
    }
]

export const stockFieldsVM = [
    {
        name: 'Make',
        value: 'make'
    },
    {
        name: 'Model',
        value: 'model'
    },
    {
        name: 'Model Description',
        value: 'modelDescription'
    },
    {
        name: 'Sale Type',
        value: 'saleType'
    },
    {
        name: 'Body Type',
        value: 'type'
    },
    {
        name: 'Group',
        value: 'group'
    },
    {
        name: 'Year',
        value: 'year'
    },
    {
        name: 'Stock Number',
        value: 'stockNo'
    },
    {
        name: 'Reg. Number',
        value: 'regNo'
    },
    {
        name: 'Reg. Date',
        value: 'regDate'
    },
    {
        name: 'Warranty Start Date',
        value: 'warrantyStartDate'
    },
    {
        name: 'Warranty Exp. Date',
        value: 'warrantyExp'
    },
    {
        name: 'VIN Number',
        value: 'vinNo'
    },
    {
        name: 'Exterior Colour',
        value: 'extColor'
    },
    {
        name: 'Interior Colour',
        value: 'intColor'
    },
    {
        name: 'Purchase Price',
        value: 'purchasePrice'
    },
    {
        name: 'Price',
        value: 'price'
    },
    // {
    //     name: 'Location',
    //     value: 'location'
    // },
    {
        name: 'Stock Intake Date',
        value: 'stockInDate'
    },
    {
        name: 'Status',
        value: 'status'
    },
    {
        name: 'Stock Images',
        value: 'images'
    },
    {
        name: 'Transmission',
        value: 'transmission'
    },
    {
        name: 'Registration Expiry',
        value: 'regExp'
    },

    {
        name: 'Odometer Reading',
        value: 'miles'
    },
    {
        name: 'Location',
        value: 'location'
    },
    {
        name: 'Stock Intake Date',
        value: 'inStockDate'
    },
    {
        name: 'Build Date',
        value: 'buildDate'
    },
    {
        name: 'Trade Plate',
        value: 'tradePlate'
    },
    {
        name: 'Usage',
        value: 'usage'
    },
    {
        name: 'Transport Method',
        value: 'transportMethod'
    },
    {
        name: 'Stock ETA Date',
        value: 'etaDate'
    },
    {
        name: 'Stock ETA Date',
        value: 'fokDate'
    }
]

export const requirementFieldsVM = [

    {
        name: 'Make',
        value: 'make'
    },
    {
        name: 'Group',
        value: 'group'
    },
    {
        name: 'Type',
        value: 'type'
    },
    {
        name: 'Model',
        value: 'model'
    },
    {
        name: 'Sale Type',
        value: 'saleType'
    },
    {
        name: 'Body Type',
        value: 'type'
    },
    {
        name: 'Year',
        value: 'year'
    },

    {
        name: 'Exterior Colour',
        value: 'extColor'
    },
    {
        name: 'Interior Colour',
        value: 'intColor'
    },

    {
        name: 'Price',
        value: 'price'
    },
    {
        name: 'Other Info',
        value: 'reqOtherInfo'
    },
    {
        name: 'Transmission',
        value: 'transmission'
    }
]
export const testdriveFieldsVM = [
    {
        name: 'Start Date',
        value: 'startDate'
    },
    {
        name: 'End Date',
        value: 'endDate'
    },
    {
        name: 'Trade Plate',
        value: 'tradePlate'
    },
    {
        name: 'Expected Return',
        value: 'expectedReturn'
    },
    {
        name: 'Start Odometer',
        value: 'startOdometer'
    },
    {
        name: 'End Odometer',
        value: 'endOdometer'
    },
    {
        name: 'Before Inspection',
        value: 'beforeInspection'
    },
    {
        name: 'After Inspection',
        value: 'afterInspection'
    },
    {
        name: 'Insurance Terms',
        value: 'insurance'
    },
    {
        name: 'Terms & Condition',
        value: 'terms'
    },
    {
        name: 'Signature',
        value: 'afterSignatureURL'
    },
    {
        name: 'Secondary License',
        value: 'secondaryLicense'
    },
    {
        name: 'Secondary Signature',
        value: 'secondarySignatureURL'
    }


]

export const tradeinFieldsVM = [
    {
        name: 'Make',
        value: 'make'
    },
    {
        name: 'Model',
        value: 'model'
    },
    {
        name: 'Model Description',
        value: 'modelDescription'
    },
    {
        name: 'Body Type',
        value: 'type'
    },
    {
        name: 'Group',
        value: 'group'
    },
    {
        name: 'Year',
        value: 'year'
    },
    {
        name: 'Sale Type',
        value: 'saleType'
    },

    {
        name: 'Notes',
        value: 'notes'
    },
    {
        name: 'Reg. Number',
        value: 'regNo'
    },
    {
        name: 'Reg. Expiry',
        value: 'regDate'
    },
    {
        name: 'Drive Type',
        value: 'driveType',
    },
    {
        name: 'Fuel Type',
        value: 'fuelType',
    },
    {
        name: 'Cylinders',
        value: 'cylinders',
    },
    {
        name: 'Gears',
        value: 'gears',
    },
    {
        name: 'Seats',
        value: 'seats',
    },
    {
        name: 'Power kW',
        value: 'powerKw',
    },
    {
        name: 'Range',
        value: 'range',
    },
    {
        name: 'Series',
        value: 'series',
    },
    {
        name: 'Torque Nm',
        value: 'torqueNm',
    },
    {
        name: 'Transmission Type',
        value: 'transmissionType',
    },
    {
        name: 'Wheelbase',
        value: 'wheelbase',
    },
    {
        name: 'Registration State',
        value: 'regState',
    },
    {
        name: 'Registration Status',
        value: 'regStatus',
    },
    {
        name: 'Badge',
        value: 'badge',
    },
    {
        name: 'Region',
        value: 'region',
    },
    {
        name: 'Chassis Number',
        value: 'chassisNo'
    },
    {
        name: 'Engine Number',
        value: 'engineNo'
    },
    {
        name: 'Engine Type',
        value: 'engineType'
    },
    {
        name: 'Engine Capacity-Litres',
        value: 'engineSize'
    },
    {
        name: 'Transmission',
        value: 'transmission'
    },
    {
        name: 'Doors',
        value: 'doors'
    },
    {
        name: 'Mileage',
        value: 'mileage'
    },
    {
        name: 'Exterior Color',
        value: 'exteriorColor'
    },
    {
        name: 'Interior Color',
        value: 'interiorColor'
    },
    {
        name: 'Trim',
        value: 'trim'
    },
    {
        name: 'Owners',
        value: 'owners'
    },
    {
        name: 'Finance Company',
        value: 'financeCo'
    },
    {
        name: 'Service Contract From',
        value: 'serviceContFrom'
    },
    {
        name: 'Service Contract To',
        value: 'serviceContTo'
    },
    {
        name: 'Warranty Expiry',
        value: 'warrantyExpiry'
    },
    {
        name: 'Tyre Age',
        value: 'tyreAge'
    },
    {
        name: 'Last Service',
        value: 'lastService'
    },
    {
        name: 'Status',
        value: 'status'
    },
    {
        name: 'Status',
        value: 'status'
    },
    {
        name: 'Front Left Wheel',
        value: 'frontLeftWheel'
    },
    {
        name: 'Front Right Wheel',
        value: 'frontRightWheel'
    },
    {
        name: 'Rear Left Wheel',
        value: 'rearLeftWheel'
    },
    {
        name: 'Rear Right Wheel',
        value: 'rearRightWheel'
    },
    {
        name: 'Vehicle Condition',
        value: 'vehicleCondition'
    },
    {
        name: 'Build Date',
        value: 'buildDate'
    },
    {
        name: 'Compliance Date',
        value: 'complianceDate'
    },
    {
        name: 'Spare Key',
        value: 'spareKey'
    },
    {
        name: 'Log Books',
        value: 'logBooks'
    },
    {
        name: 'Service History',
        value: 'serviceHistory'
    },
    {
        name: 'Cargo Blind',
        value: 'cargoBlind'
    }, {
        name: 'Head Rests',
        value: 'headRests'
    },
    {
        name: 'SD Card',
        value: 'sdCard'
    },
    {
        name: 'Spare Tyre',
        value: 'spareTyre'
    },
    {
        name: 'Reconditioning Cost',
        value: 'reconditioningCost'
    },
    // {
    //     name: 'Vehicle Images',
    //     value: 'images'
    // }
]

export const financeFieldsVM = [
    {
        name: 'Vehicle Price',
        value: 'CarPrice'
    },
    {
        name: 'Finance Amount',
        value: 'FinanceAmount'
    },
    {
        name: 'Requirement Images',
        value: 'ImageList'
    },
    {
        name: 'Residence Type',
        value: 'ResidenceType'
    },
    {
        name: 'Residence Year',
        value: 'ResidenceYear'
    },
    {
        name: 'Residence Status',
        value: 'ResidenceStatus'
    },
    {
        name: 'Residence Address',
        value: 'ResidenceAddress'
    },
    {
        name: 'Previous Address',
        value: 'PreviousAddress'
    },
    {
        name: 'Employment Status',
        value: 'EmploymentStatus'
    },
    {
        name: 'Occupation',
        value: 'Occupation'
    },
    {
        name: 'Employer Name',
        value: 'EmployerName'
    },
    {
        name: 'Employer Address',
        value: 'EmployerAddress'
    },
    {
        name: 'Employer Period',
        value: 'EmploymentPeriod'
    },
    {
        name: 'Previous Employer',
        value: 'PreviousEmployer'
    },
    {
        name: 'Income - Primary Amount',
        value: 'PrimaryAmount'
    },
    {
        name: 'Income - Primary Frequency',
        value: 'PrimaryFrequency'
    },
    {
        name: 'Income - Benefits Amount',
        value: 'BenefitsAmount'
    },
    {
        name: 'Income - Benefits Frequency',
        value: 'BenefitsFrequency'
    },
    {
        name: 'Income - Other Amount',
        value: 'OtherAmount'
    },
    {
        name: 'Income - Other Frequency',
        value: 'OtherFrequency'
    },
    {
        name: 'Income - Rental Amount',
        value: 'RentalAmount'
    },
    {
        name: 'Income - Rental Frequency',
        value: 'RentalFrequency'
    },
    {
        name: 'Assests - Property One Value',
        value: 'PropertyOneValue'
    },
    {
        name: 'Assests - Property One Financed',
        value: 'PropertyOneFinanced'
    },
    {
        name: 'Assests - Property Two Value',
        value: 'PropertyTwoValue'
    },
    {
        name: 'Assests - Property Two Financed',
        value: 'PropertyTwoFinanced'
    },
    {
        name: 'Assests - Savings Amount',
        value: 'SavingsAmount'
    },
    {
        name: 'Assests - Deposit Amount',
        value: 'DepositAmount'
    },
    {
        name: 'Liabilitie - Mortgage Amount',
        value: 'MortgageAmount'
    },
    {
        name: 'Liabilities - Personal Loan Amount',
        value: 'PersonalLoanAmount'
    },
    {
        name: 'Liabilities - Credit Limit',
        value: 'CreditLimit'
    },
    {
        name: 'Liabilities - Outstanding Amount',
        value: 'OutstandingAmount'
    }
]

export const frontdeskFieldsVM = [
    {
        name: 'Contact',
        value: 'name'
    },
    {
        name: 'Make',
        value: 'make'
    },
    {
        name: 'Model',
        value: 'model'
    },
    {
        name: 'Body Type',
        value: 'type'
    },
    {
        name: 'Campaign',
        value: 'campaign'
    },
    {
        name: 'Origin',
        value: 'origin'
    },
    {
        name: 'Enquiry Type',
        value: 'enquiryType'
    },
    {
        name: 'Notes',
        value: 'notes'
    },

]
export const callLogFieldsVM = [
    {
        name: 'Duration',
        value: 'callDuration'
    },
    {
        name: 'Call Type',
        value: 'callType'
    },
    {
        name: 'Call Status',
        value: 'callStatus'
    }
]
export const allModules = [
    "company",
    "contact",
    "businessContact",
    "enquiry",
    "fleet",
    "requirement",
    "stock",
    "testdrive",
    "tradein",
    "fleetContact",
    "testdriveContact",
    "tradeinContact",
    "financeContact",
    "videoContact",
    "finance",
    "pipeline",
    "status",
    "financeStatus",
    "afterMarketStatus",
    "frontdesk",
    "callLog",
]

export const levelOptions = [
    { active: true, value: 'oem', label: 'OEM', index: 1 },
    { active: true, value: 'region', label: 'Region', index: 2 },
    { active: true, value: 'group', label: 'Group', index: 3 },
    { active: true, value: 'individual', label: 'Individual', index: 4 }
]


export const enquiryStatus = [
    { active: true, value: 'open', name: 'Open' },
    { active: true, value: 'won', name: 'Won' },
    { active: true, value: 'delivered', name: 'Delivered' },
    { active: true, value: 'lost', name: 'Lost' }
]

export const enquiryDates = [
    { active: true, value: 'addedDate', label: 'Created Date' },
    { active: true, value: 'stageDate', label: 'Stage Date' },
    { active: true, value: 'wonDate', label: 'Won Date' },
    { active: true, value: 'deliveredDate', label: 'Delivered Date' },
    { active: true, value: 'deliveryDate', label: 'Est. Delivery Date' },
    { active: true, value: 'lostDate', label: 'Lost Date' }
]

export const activityDates = [
    { active: true, value: 'addedDate', label: 'Created Date' },
    { active: true, value: 'startDate', label: 'Start Date' }
]

export const mandSettingsVM = {
    company: '',
    contact: '',
    businessContact: '',
    enquiry: '',
    fleet: '',
    requirement: '',
    stock: '',
    testdrive: '',
    tradein: '',
    fleetContact: '',
    testdriveContact: '',
    tradeinContact: '',
    financeContact: '',
    videoContact: '',
    pipeline: [],
    status: [],
    financeStatus: [],
    afterMarketStatus: [],
    finance: '',
    frontdesk: '',
    callLog: ''
}


export const moduleNameValue = [
    {
        name: 'Company',
        value: 'company'
    },
    {
        name: 'Contact',
        value: 'contact'
    },
    {
        name: 'Business Contact',
        value: 'businessContact'
    },
    {
        name: 'Enquiry',
        value: 'enquiry'
    },
    {
        name: 'Loan Vehicle',
        value: 'fleet'
    },
    {
        name: 'Requirement',
        value: 'requirement'
    },
    {
        name: 'Stock',
        value: 'stock'
    },
    {
        name: 'Test Drive',
        value: 'testdrive'
    },
    {
        name: 'Trade-In',
        value: 'tradein'
    },
    {
        name: 'Loan Vehicle',
        value: 'fleetContact'
    },
    {
        name: 'Test Drive',
        value: 'testdriveContact'
    },
    {
        name: 'Trade-In',
        value: 'tradeinContact'
    },
    {
        name: 'Finance',
        value: 'financeContact'
    },
    {
        name: 'AMS Video',
        value: 'videoContact'
    }
]

