/** LIBRARIES */
import React, { Component } from 'react';
import { Modal, Dropdown } from "react-bootstrap";
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
/** COMPONENTS */
import Translate from '../../../constants/translate';
import {
    DropDownMenu, InputText, ReactTimePicker, AntDatePicker
} from '../../../components';
import CommonHelper from '../../../services/common';
import { default as _images } from '../../../images';
import { service_type } from '../../../services/enum';

const emailList = [
    { active: true, value: 'Secondary', label: 'Secondary' },
    { active: true, value: 'Other', label: 'Other' },
]

export const objCustomer = {
    spokeTo: '',
    firstName: '',
    middleName: '',
    lastName: '',
    clientContactDate: null,
    email: '',
    emails: [],
    sendMail: false,
}

export default class ConfrimQuotation extends Component {
    constructor(props) {
        super(props);

        this.isLoading = false;
        this.mandatoryFields = [];
        this.state = {
            fields: Object.assign({}, objCustomer),
            errors: {},
            fullServiceCost: {
                total: 0,
                labourCost: 0,
                partsCost: 0,
                packagesCost: 0,
                taxCost: 0,
                subTotal: 0,
                actualDiscount: 0,
                actualDiscountRecomm: 0,
                actualDiscountPack: 0,
            }
        }
        const { dealersettings } = this.props;
        this.currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');
    }

    //#region PAGE LOAD
    loadRecomm(props) {
        const { show, contact, spokeTo } = props;
        const fields = Object.assign({}, objCustomer);
        if (show === true && contact) {
            const objData = Object.assign({}, contact);

            for (let [key, value] of Object.entries(objData)) {
                if (fields.hasOwnProperty(key))
                    fields[key] = value;
            }
            fields.spokeTo = spokeTo ? spokeTo : CommonHelper.getFullName(null, objData);

            this.getTotalServiceCost();

            this.setState({ fields: fields });
        }
        else if (show === true) {
            this.setState({ fields: fields });
        }
    }

    getTotalServiceCost = () => {
        const { recommendations, service, servicePackages } = this.props;
        let _fullCost = CommonHelper.getTotalServiceCost(service, recommendations, servicePackages);
        this.setState({ fullServiceCost: _fullCost });

    }


    componentDidMount() {
        this.loadRecomm(this.props);
    }
    //#endregion

    //#region CHANGE EVENT

    handleDateChange = (val, name) => {
        if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);
        let state = Object.assign({}, this.state.fields);
        state[name] = val;

        this.setState({ fields: state }, () => { });

    };

    handleTimeChange = (date, name) => {
        let state = Object.assign({}, this.state.fields);

        state[name] = window.firebase.firestore.Timestamp.fromDate(moment(date)._d);

        this.setState({ fields: state }, () => { });

    }


    handleEmailChange = idx => evt => {
        let state = Object.assign({}, this.state.fields);
        const newEmail = !_.isEmpty(state.emails) && state.emails.map((data, sidx) => {
            if (idx !== sidx) return data;
            return { ...data, value: evt.target.value };
        });
        state['emails'] = newEmail
        this.setState({ fields: state });
    };
    handleAddEmail = (type) => {
        let state = Object.assign({}, this.state.fields);
        state['emails'] = state.emails.concat([{ value: "", name: type }])
        this.setState({ fields: state });
    };
    handleRemoveEmail = idx => {
        let state = Object.assign({}, this.state.fields);
        state['emails'] = state.emails.filter((s, sidx) => idx !== sidx)
        this.setState({ fields: state });
    };


    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            this.setState({ fields: fields }, () => { });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str;
            this.setState({ fields: fields }, () => { });
        }

    }

    handleCheckChange = (e) => {
        //console.log('handleCheckChange', e, name, e.target.checked, e.target.name);
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        state[name] = checked;
        this.setState({ fields: state });
    }

    errorChange = (key, isScroll) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        let _mandatoryFields = this.mandatoryFields;

        if (errors !== {}) {
            if ((!fields[key] || (_.isObject(fields[key]) && _.isEmpty(fields[key]))) && _mandatoryFields && _mandatoryFields.indexOf(key) >= 0) {
                errors[key] = errorClass;
            }
            else {
                if (key === 'email' || (key === 'secondaryEmail' && fields[key].length > 1)) {
                    if (typeof fields[key] !== "undefined") {
                        let lastAtPos = fields[key].lastIndexOf('@');
                        let lastDotPos = fields[key].lastIndexOf('.');

                        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[key].indexOf('@@') === -1 && lastDotPos > 2 && (fields[key].length - lastDotPos) > 2)) {

                            errors[key] = errorClass;
                        }
                        else
                            errors[key] = '';
                    }
                }
                else
                    errors[key] = '';
            }

            this.setState({ errors: errors });
        }

        if (isScroll && document.getElementById(key) && !_.isEmpty(errors[key])) {
            document.getElementById(key).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }
    }
    //#endregion

    handleSubmit = (e) => {
        e.preventDefault();

        // let errors = {};
        // let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);

        // let errorClass = 'input_error';

        // for (let [key, value] of Object.entries(fields)) {
        //     if (this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
        //         if (!fields[key] && key !== 'totalCost') {
        //             formIsValid = false;
        //             errors[key] = errorClass;
        //         }
        //     }
        // }

        // this.setState({ errors: errors });

        // if (formIsValid === true) {

        // }
        // else
        //     return formIsValid;
        this.props.handleClose(fields);

    };

    handleModalClose = (ID) => {
        this.setState({ fields: Object.assign({}) });
        this.setState({ errors: {} });
        this.props.handleClose(ID);
    };


    render() {
        const { spokeTo, clientContactDate, email, emails, sendMail } = this.state.fields;
        const { fullServiceCost } = this.state;
        const { show, dealersettings } = this.props;
        const recommendationName = dealersettings?.client?.clientSettings?.service?.recommendationName;
        const { taxType, taxPercentage, serviceType, internalTaxExemption } = this.props.service;
        const _isTaxIncluded = (serviceType !== service_type.internal || (serviceType === service_type.internal && !internalTaxExemption)) && taxPercentage && taxType ? true : false;
        const _dispalyTax = _isTaxIncluded ? `(${taxType} Incl.)` : `(${taxType ? taxType : 'Tax'} Excl.)`

        return (
            <>
                <Modal
                    id="modal-add-service-part"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName={`modal-dialog modal-dialog-centered modal-confirm-quotation`}>

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" >
                                <Translate text={'Confirm Quotation'} upperCase={true} />
                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row">
                                <div className=" pop-layout form-style">
                                    <div className="labourcost-discountpop-head">
                                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                            <tbody>
                                                <tr >
                                                    <td className="labourcost-costtxt ">  <Translate text={'Service Packages Cost'} />:</td>
                                                    <td className="labourcost-cost-box right-align"><NumberFormat value={fullServiceCost.packagesCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={this.currencySymbol} /></td>
                                                </tr>
                                                <tr >
                                                    <td className="labourcost-costtxt ">  <Translate text={'Service Parts Cost'} />:</td>
                                                    <td className="labourcost-cost-box right-align"><NumberFormat value={fullServiceCost.servicePartsCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={this.currencySymbol} /></td>
                                                </tr>
                                                {
                                                    fullServiceCost.actualDiscountPack
                                                        ?
                                                        <>
                                                            <tr >
                                                                <td className="labourcost-costtxt "><Translate text={'Discount (Service Packages)'} />:</td>
                                                                <td className="labourcost-cost-box right-align"><NumberFormat value={fullServiceCost.actualDiscountPack} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${this.currencySymbol}-`} /></td>
                                                            </tr>
                                                        </>
                                                        :
                                                        <></>
                                                }
                                                <tr >
                                                    <td className="labourcost-costtxt"> <Translate text={'Part Cost'} />:</td>
                                                    <td className="labourcost-cost-box right-align" ><NumberFormat value={fullServiceCost.partsCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={this.currencySymbol} /></td>
                                                </tr>
                                                <tr >
                                                    <td className="labourcost-costtxt"> <Translate text={'Labour Repair Cost'} />:</td>
                                                    <td className="labourcost-cost-box right-align"><NumberFormat value={fullServiceCost.labourCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={this.currencySymbol} /></td>
                                                </tr>
                                                {
                                                    fullServiceCost.actualDiscountRecomm
                                                        ?
                                                        <>
                                                            <tr >
                                                                <td className="labourcost-costtxt "><Translate text={`Discount (${_.trim(recommendationName) ? recommendationName : 'Recommendations'})`} />:</td>
                                                                <td className="labourcost-cost-box right-align"><NumberFormat value={fullServiceCost.actualDiscountRecomm} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${this.currencySymbol}-`} /></td>
                                                            </tr>
                                                        </>
                                                        :
                                                        <></>
                                                }
                                                <tr>
                                                    <td className="labourcost-costtxt blue-color"><Translate text={'Sub Total Cost'} /> {`(${taxType ? taxType : 'Tax'} Excl.)`}:</td>
                                                    <td className="labourcost-cost-box right-align blue-color" ><span><NumberFormat value={fullServiceCost.subTotal} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={this.currencySymbol} /></span></td>
                                                </tr>
                                                {
                                                    fullServiceCost.actualDiscount
                                                        ?
                                                        <>
                                                            <tr >
                                                                <td className="labourcost-costtxt "><Translate text={'discount'} />:</td>
                                                                <td className="labourcost-cost-box right-align"><NumberFormat value={fullServiceCost.actualDiscount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${this.currencySymbol}-`} /></td>
                                                            </tr>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                {
                                                    _isTaxIncluded
                                                        ?
                                                        <>

                                                            <tr >
                                                                <td className="labourcost-costtxt">  {taxType}&nbsp;<Translate text={'Payable'} />:</td>
                                                                <td className="labourcost-cost-box right-align"><NumberFormat value={fullServiceCost.taxCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={this.currencySymbol} /></td>
                                                            </tr>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                <tr>
                                                    <td className="labourcost-costtxt-green"> <Translate text={'Total Cost'} /> {_dispalyTax}:</td>
                                                    <td className="labourcost-cost-box-total right-align" ><span><NumberFormat value={fullServiceCost.total} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={this.currencySymbol} /></span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-9">
                                            <label ><Translate text={'clientContactDate'} /></label>
                                            <AntDatePicker
                                                value={clientContactDate ? moment.unix(clientContactDate.seconds).format('YYYY-MM-DD') : null}
                                                name={'clientContactDate'}
                                                onChange={(e) => { this.handleDateChange(e, 'clientContactDate') }}
                                                format='DD/MM/YYYY'
                                                placeholder='DD/MM/YYYY'
                                                className={`form-control ${this.state.errors["clientContactDate"]}`}
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label ><Translate text={'time'} /></label>
                                            <div className="inner-addon right-addon"> <i className="bg-icons ico icon-time"></i>
                                                <ReactTimePicker
                                                    value={clientContactDate ? moment.unix(clientContactDate.seconds)._d : null}
                                                    name={'clientContactDate'}
                                                    onChange={this.handleTimeChange}
                                                    timeIntervals={15}
                                                    placeholder='HH:MM'
                                                    className={`form-control ${this.state.errors["clientContactDate"]}`}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'Spoke To'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'spoke To'}
                                                className={`form-control ${this.state.errors["spokeTo"]}`}
                                                name="spokeTo"
                                                onChange={this.handleOnChange}
                                                value={spokeTo}
                                            />
                                        </div>

                                        <div className="form-group col-md-12">
                                            <DropDownMenu
                                                text='+ add CC Email'
                                                keyVal="1002"
                                                tIndex="-1"
                                                className={`inputlink-addmore ${this.state.errors["emails"] ? 'label-error' : ''}`}
                                                menu={emailList}
                                                handleChange={this.handleAddEmail}
                                            >
                                            </DropDownMenu>
                                            <label ><Translate text={'email'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'email'}
                                                className={`form-control ${this.state.errors["email"]}`}
                                                name="email"
                                                onChange={(e) => { e.preventDefault(); }}
                                                value={email}
                                                readOnly={true}
                                            />
                                        </div>

                                        {!_.isEmpty(emails) && emails.map((email, idx) => (

                                            <div className="form-group col-md-12" key={idx}>
                                                <a href="#" className="inputlink-remove" onClick={(e) => { e.preventDefault(); this.handleRemoveEmail(idx)}}>- <Translate text={'remove'} /></a>
                                                <label >{email.name} <Translate text={'email'} /> </label>
                                                <InputText
                                                    type="text"
                                                    className="form-control"
                                                    id={idx}
                                                    placeholder={`${email.name.toLowerCase()} email `}
                                                    value={email.value}
                                                    onChange={this.handleEmailChange(idx)}
                                                />
                                            </div>
                                        ))}

                                        <div className="form-group col-md-12">
                                            <div className="checkbox icheck-success">
                                                <input
                                                    type="checkbox"
                                                    id="sendMail"
                                                    name="sendMail"
                                                    className="uncheck-activity"
                                                    checked={sendMail ? true : false}
                                                    onChange={(e) => {
                                                        this.handleCheckChange(e, 'sendMail')
                                                    }} />
                                                <label htmlFor="sendMail"><Translate text={'Send Quotation To Customer'} /> </label>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer className="modal-footer">

                        <button type="button" className="btn btn-default float-left" onClick={(e) => this.handleModalClose(null)}>
                            <Translate text={'cancel'} />
                        </button>

                        <button type="button"
                            className={`btn btn-primary float-right `}
                            onClick={(e) => this.handleSubmit(e)} >
                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'confirm'} />
                        </button>

                    </Modal.Footer>
                </Modal>



            </>
        );
    }
}
