/** LIBRARIES */
import React, { Component, PureComponent } from "react";
import _ from 'lodash'
import AsyncCreatableSelect from "react-select/async-creatable";
import CommonHelper from '../services/common';
import { firestoreDB } from "../services/helper";


class ReactAsyncSelect extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
        //this.handleCancel = this.handleCancel.bind(this);
    }

    filterColors = (inputValue) => {

        if (!_.isEmpty(this.props.options)) {
            return this.props.options.filter(i =>
                i.searchLabel.toLowerCase().includes(inputValue.toLowerCase())
            );
        }
        else
            return [];

    };

    handleCreate = inputValue => {
        //  console.log('handleCreate')
        //alert(inputValue);
        var array1 = inputValue.split(' ');
        var str = inputValue;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            inputValue = newarray1.join(' ');

        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            inputValue = str;
        }

        this.props.onCreate(inputValue);


    };

    handlecreatelabel = (inputValue) => {
        return <div className=""><i className="ico icon-add"></i> Create "{inputValue}"</div>;

    }

    promiseOptions = async (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        } else if (this?.props?.defaultOptions === true) {
            return callback(this.filterColors(inputValue));
        }
        else if (this?.props?.searchContact === true) {

            const { groupID, enableSharedContact, dealersettings } = this.props;
            let _currentClient = this.props.clientID;
            let contactDetailsRef = firestoreDB(dealersettings).firestore().collection('contactDetails');
            //await firestoreDB(this.props.dealersettings).firestore().collection('companies')
            if (localStorage.defaultModule === 'tradeinPro')
                contactDetailsRef = contactDetailsRef.where('clientID', 'in', CommonHelper.getTradeinProDealers(dealersettings, true))
            else if (enableSharedContact && groupID) {
                contactDetailsRef = contactDetailsRef.where('groupID', '==', groupID)
            }
            else {
                contactDetailsRef = contactDetailsRef.where('clientID', '==', _currentClient)
            }

            await contactDetailsRef.where('isDeleted', '==', false)
                .where('keywords', 'array-contains', inputValue.toLowerCase())
                .limit(20)
                .get().then(response => {
                    const contacts = [];
                    response.docs.forEach((doc) => {
                        const { firstName, lastName, clientID, phoneCode, phone, email, owner, userImageURL } = doc.data();
                        const name = `${firstName || ''} ${lastName || ''}`;
                        let dealerName = '';
                        if (_currentClient !== clientID || localStorage.defaultModule === 'oem') {
                            dealerName = CommonHelper.getOtherDealerName(dealersettings, clientID);
                        }
                        if (email) {
                            contacts.push({
                                value: doc.id,
                                active: _.isBoolean(doc.active) ? doc.active : true,
                                searchLabel: name,
                                phoneCode: phoneCode ? phoneCode : '',
                                phone: phone ? phone : '',
                                email: email ? email : '',
                                owner: owner ? owner : '',
                                label: (<div className='drop-image'>
                                    <img src={CommonHelper.showUserAvatar(userImageURL, name)} alt="" width="50" className="img-object-fit" />
                                    {name}
                                    <br /><span >{email}</span>
                                </div>
                                )
                            });
                        }
                    });
                    if (this?.props?.searchOptions === true) {
                        contacts.push(...this.filterColors(inputValue))
                    }
                    return callback(contacts);
                });

            this.setState({ loading: false });

        }
        else {

            const { groupID, enableSharedContact, dealersettings } = this.props;
            let _currentClient = this.props.clientID;
            let contactDetailsRef = firestoreDB(dealersettings).firestore().collection('companies');
            //await firestoreDB(this.props.dealersettings).firestore().collection('companies')
            if (localStorage.defaultModule === 'tradeinPro')
                contactDetailsRef = contactDetailsRef.where('clientID', 'in', CommonHelper.getTradeinProDealers(dealersettings, true))
            else if (enableSharedContact && groupID) {
                contactDetailsRef = contactDetailsRef.where('groupID', '==', groupID)
            }
            else {
                contactDetailsRef = contactDetailsRef.where('clientID', '==', _currentClient)
            }

            await contactDetailsRef.where('isDeleted', '==', false)
                .where('keywords', 'array-contains', inputValue.toLowerCase())
                .limit(20)
                .get().then(response => {
                    const companys = [];
                    response.docs.forEach((doc) => {
                        const { name, clientID, phoneCode, phone, email, owner } = doc.data();
                        let dealerName = '';
                        if (_currentClient !== clientID || localStorage.defaultModule === 'oem') {
                            dealerName = CommonHelper.getOtherDealerName(dealersettings, clientID);
                        }
                        companys.push({
                            value: doc.id,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            searchLabel: name ? name : '',
                            phoneCode: phoneCode ? phoneCode : '',
                            phone: phone ? phone : '',
                            email: email ? email : '',
                            owner: owner ? owner : '',
                            label: <>{name} {dealerName ? <><span className="blue-color">({dealerName})</span></> : <></>}</>
                        });
                    });

                    return callback(companys);
                });

            this.setState({ loading: false });

        }
    };

    // promiseOptions = (inputValue, callback) => {
    //     if (!inputValue) {
    //         return callback([]);
    //     } else {

    //         callback(this.filterColors(inputValue));
    //         this.setState({ loading: false });

    //     }
    // };


    render() {

        const singleStyles = {
            control: (base, state) => ({
                ...base,
                minHeight: "33px"
            }),
            dropdownIndicator: base => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 0
            }),
            clearIndicator: base => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 0
            }),
            option: (base, { data, isCreatable }) => ({
                ...base,
                color: _.isString(data.label) && data.label.indexOf("Create") >= 0 ? "#4466f2" : "",
                cursor: _.isString(data.label) && data.label.indexOf("Create") >= 0 ? "pointer" : "",
                borderTop: _.isString(data.label) && data.label.indexOf("Create") >= 0 ? "1px solid #d5dee5" : "",

            })
        };

        // const value = [];

        // if (this.props.value && !_.isEmpty(this.props.options)) {
        //     var index = this.props.options.findIndex(x => x.value === this.props.value);
        //     value.push(this.props.options[index])
        // }

        const { loading } = this.state;
        //console.log('RENDER REACT ASYNC', this.props.options)
        return (
            <div id={this.props.name + '-company'}>
                <AsyncCreatableSelect
                    isClearable
                    isLoading={loading}
                    placeholder={this.props.placeholder}
                    className={`basic - single`}
                    classNamePrefix={this.props.classNamePrefix}
                    components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null
                    }}
                    styles={singleStyles}
                    id={this.props.name}
                    name={this.props.name}
                    onChange={this.props.onChange}
                    defaultValue={this.props.value}
                    value={this.props.value}
                    //noOptionsMessage={() => null}
                    //defaultOptions={colourOptions}
                    //formatCreateLabel={this.handlecreatelabel}
                    defaultOptions={false}
                    loadOptions={this.promiseOptions}
                    onCreateOption={this.handleCreate}
                />
            </div>

        );
    }
}


export default ReactAsyncSelect;