import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { clientsVM } from '../../settings/workflow/clients/viewModel';
import OEMFilter from './oemFilter';
import { PopUpModal, SidePanel } from '../../../components';
import OEMCounts from './oemCounts';
import moment from 'moment';
import DealerSidePanel from './dealerSidePanel';
import Swal from 'sweetalert2';
import toast from 'toasted-notes';
import { MainContext } from '../../layout/provider';
import { RouteContext } from '../../layout/RouteProvider';
import MapWrapper from './mapWrapper';
import CommonHelper from '../../../services/common';

const OEMDashboard = props => {
	const [totalOEMCounts, setOEMCounts] = useState([]);
	const [countsLoader, setCountsLoader] = useState(true);
	const [sidePanel, setSidePanel] = useState({
		clsActive: '',
		clsName: '',
		dealerID: '',
		dealer: null,
	});
	const [oemClients, setOEMClients] = useState([]);
	const [clientLst, setClientList] = useState([]);
	const [clientsToFilter, setClientsToFilter] = useState([]);
	const [searchText] = useState('');
	const [showFilterModal, setShowFilterModal] = useState(false);
	const [region, setRegion] = useState(null);
	const [subregion, setSubregion] = useState(null);
	const [group, setGroup] = useState(null);
	const [country, setCountry] = useState(null);
	const [state, setState] = useState(null);
	const [dealer, setDealer] = useState(null);
	const { handleClientChange } = useContext(MainContext);
	const { resetnavbar } = useContext(RouteContext);
	const [initialLoad, setInitialLoad] = useState(true)
	const [refreshTime, setRefreshTime] = useState(0)
	const enqStatus = props.dealersettings &&
		props.dealersettings.client &&
		props.dealersettings.client.settings &&
		props.dealersettings.client.settings.enquiryStatus ? props.dealersettings.client.settings.enquiryStatus.filter(a => Boolean(a.active)) : [];

	const calcCurrentStatus = ((!_.isEmpty(props.dealersettings) &&
		!_.isEmpty(props.dealersettings.client) &&
		!_.isEmpty(props.dealersettings.client.clientSettings) &&
		props.dealersettings.client.clientSettings.calcCurrentStatus) ? true : false);
	const docPathRef = window.firebase.firestore().doc(`users/${localStorage.uid}/dashoardWidgets/${props.dealersettings.oemID}-pipelineStats`);

	useEffect(() => {
		document.getElementsByTagName('body')[0].classList.add('inner-bg');
		document.getElementById('main-container-fluid') &&
			document.getElementById('main-container-fluid').classList.add('px-0');
		return () => {
			document.getElementById('main-container-fluid') &&
				document
					.getElementById('main-container-fluid')
					.classList.remove('px-0');
			window.unsubpipelinestatsmapSnap && window.unsubpipelinestatsmapSnap();
		};
	}, []);

	useEffect(() => {
		if (refreshTime === 0)
			return;

		const timer = setTimeout(() => {
			if (moment().unix() - refreshTime > 1000) {
				fetchData(true);
			}
		}, 900000);
		return () => clearTimeout(timer);
	}, [refreshTime])

	useEffect(() => {
		if (!initialLoad)
			return

		setCountsLoader(true)
		window.unsubpipelinestatsmapSnap = docPathRef
			.onSnapshot(snap => {
				if (snap.exists && snap.data().counts) {
					setCountsLoader(false);
					setInitialLoad(false)
					const _counts = _.isArray(snap.data().counts) ? snap.data().counts : [snap.data().counts];
					setOEMCounts(getTotalCounts(_counts));
					setRefreshTime(snap.data().logDate ? snap.data().logDate.seconds : 0);
					if (snap.data().logDate && moment().unix() - snap.data().logDate.seconds > 1000) {
						fetchData(true);
					}
				}
				else {
					fetchData();
				}
			})
	}, [initialLoad])

	const fetchData = async (hideLoader) => {
		try {
			if (!hideLoader)
				setCountsLoader(true);
			let dataToSet = []
			let objToPass = {
				"timezone": !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
				"clientIDs": CommonHelper.getAllDealers(props.dealersettings),
				"filters": {}
			}


			if (calcCurrentStatus) {
				objToPass.filters.calcCurrentStatus = 'true'
			}

			objToPass.filters.inclInboundBuckeinRpt = props?.dealersettings?.client?.clientSettings?.inclInboundBuckeinRpt;
			objToPass.filters.inclInboundLeadsinRpt = props?.dealersettings?.client?.clientSettings?.inclInboundLeadsinRpt;
			objToPass.array = true;
			if (props?.dealersettings?.mvwReport) {
				objToPass.v2 = true;
			}
			const getDashboardCounts = window.firebase.functions().httpsCallable('generic-getOEMDashboardCounts');
			const resp = await getDashboardCounts(objToPass)
			setCountsLoader(false);
			if (resp.data.success) {
				//console.log('data returned', resp.data.data)
				const data = resp.data.data
				dataToSet = _.isArray(data) ? data : [data];
			}
			const _counts = getTotalCounts(dataToSet);
			setOEMCounts(_counts);
			await docPathRef.set({ counts: dataToSet, logDate: window.firebase.firestore.Timestamp.now() }, { merge: true })

		} catch (error) {
			console.error(error)
		}
	}

	const getTotalCounts = (countsByClients) => {
		let clients = props.dealersettings.clients;
		const data = [];
		countsByClients.forEach(snap => {
			const dataDoc = snap
			let _dynStatus = {};
			if (enqStatus.length > 0) {
				enqStatus.filter(a => !Boolean(a.default)).forEach(rec => {
					_dynStatus = {
						..._dynStatus,
						[`month${rec.value}`]: _.find(dataDoc.dynamicStatus, { status: rec.value }) ? _.find(dataDoc.dynamicStatus, { status: rec.value }).mtd : 0,
						[`today${rec.value}`]: _.find(dataDoc.dynamicStatus, { status: rec.value }) ? _.find(dataDoc.dynamicStatus, { status: rec.value }).today : 0
					}
				})
			}

			const otherStats = {
				todaynew: dataDoc?.todayNew || 0,
				monthnew: dataDoc?.monthNew || 0,
				todayopen: dataDoc?.todayOpen || 0,
				monthopen: dataDoc?.monthOpen || 0,
				todaywalkin: dataDoc?.todayWalkin || 0,
				monthwalkin: dataDoc?.monthWalkin || 0,
				todaynewCAP: dataDoc?.todayNewCAP || 0,
				monthnewCAP: dataDoc?.monthNewCAP || 0,
				todaytestdrive: dataDoc?.todayTestdrive || 0,
				monthtestdrive: dataDoc?.monthTestdrive || 0,
				todaywon: dataDoc?.todayWon || 0,
				monthwon: dataDoc?.monthWon || 0,
				todaylost: dataDoc?.todayLost || 0,
				monthlost: dataDoc?.monthLost || 0,
				todaydelivered: dataDoc?.todayDelivered || 0,
				monthdelivered: dataDoc?.monthDelivered || 0,
				todaytestdrive: dataDoc?.todayTestdrive || 0,
				monthtestdrive: dataDoc?.monthTestdrive || 0,
				outdated: dataDoc?.outdated || 0,
				todayoutdated: dataDoc?.outdated || 0,
				monthoutdated: dataDoc?.outdated || 0,
				outdatedLeads: dataDoc?.outdatedLeads || 0,
				todayoutdatedLeads: dataDoc?.outdatedLeads || 0,
				monthoutdatedLeads: dataDoc?.outdatedLeads || 0,
			}

			otherStats.todayleadConversion = (otherStats.todaywon + otherStats.todaylost) > 0 ? `${Math.round((100 / (otherStats.todaywon + otherStats.todaylost)) * otherStats.todaywon)}%` : '0%';
			otherStats.monthleadConversion = (otherStats.monthwon + otherStats.monthlost) > 0 ? `${Math.round((100 / (otherStats.monthwon + otherStats.monthlost)) * otherStats.monthwon)}%` : '0%';

			data.push({
				...dataDoc,
				..._dynStatus,
				...otherStats
			})
		});
		clients.forEach(cl => {
			if (!data.some(a => a.clientID === cl.id)) {
				let _dynStatus = {};
				if (enqStatus.length > 0) {
					enqStatus.filter(a => !Boolean(a.default)).forEach(rec => {
						_dynStatus = {
							..._dynStatus,
							[`month${rec.value}`]: 0,
							[`today${rec.value}`]: 0,
						}
					})
				}
				data.push({
					clientID: cl.id ? cl.id : '',
					clientName: cl.name ? cl.name : '',
					monthActivity: 0,
					monthDelivered: 0,
					monthLost: 0,
					monthnew: 0,
					monthOpen: 0,
					monthTestdrive: 0,
					monthTradein: 0,
					monthWon: 0,
					overdueActivity: 0,
					todayActivity: 0,
					todayDelivered: 0,
					todayLost: 0,
					todayOpen: 0,
					todayTestdrive: 0,
					todayTradein: 0,
					todayWon: 0,
					todayNew: 0,
					..._dynStatus
				})
			}
		});
		return data;
	}

	const refreshStageCount = (data) => {
		data.forEach(client => {
			if (client.updatedOn && moment.unix(client.updatedOn.seconds).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
				try {
					const clientCountsBQ = window.firebase.functions().httpsCallable('generic-updateOEMCounts');
					clientCountsBQ({ clientID: client.clientID }).then((response) => {
						//console.log('stageCountsBQ', _dpl, response);
					});
				}
				catch (error) {
					console.error(error)
				}
			}
		})

	}

	useEffect(() => {
		const fetchedClients = async () => {
			const availableClients = props.dealersettings.clients
			let clientPromises = []
			const fetchedClients = []
			availableClients.forEach(client => {
				clientPromises.push(window.firebase.firestore().collection('clients').doc(client.id).get())
			})
			const snapshots = await Promise.all(clientPromises)
			snapshots.forEach(snap => {
				const dataDoc = { ...snap.data(), 'documentID': snap.id };
				fetchedClients.push(dataDoc)
			})
			setOEMClients(fetchedClients);
		}

		fetchedClients()
	}, []);

	useEffect(() => {
		if (!_.isEmpty(oemClients)) {
			let _client = [];
			oemClients
				.filter(({ name, address, phone, email }) =>
					!_.isEmpty(searchText)
						? (!_.isEmpty(name)
							? name.toLowerCase().includes(searchText.toLowerCase())
							: false) ||
						(!_.isEmpty(address)
							? address.toLowerCase().includes(searchText.toLowerCase())
							: false) ||
						(!_.isEmpty(phone)
							? phone.toLowerCase().includes(searchText.toLowerCase())
							: false) ||
						(!_.isEmpty(email)
							? email.toLowerCase().includes(searchText.toLowerCase())
							: false)
						: true,
				)
				.forEach(rec => {
					_client.push(convertVM(rec));
				});
			setClientList(_client);
			// setClientsToRender(_client);
			setClientsToFilter(_client);
		}
	}, [oemClients, searchText]);

	useEffect(() => {
		document.getElementsByTagName('body')[0].classList.remove('inner-bg');
		document.getElementsByTagName('body')[0].style.overflow = 'hidden';
		return () => {
			document.getElementsByTagName('body')[0].style.overflow = 'auto';
		};
	}, []);

	const convertVM = doc => {
		const objclientData = Object.assign({}, doc);
		const listVM = Object.assign({}, clientsVM);
		for (let [key, value] of Object.entries(objclientData)) {
			if (listVM.hasOwnProperty(key)) listVM[key] = value;
		}
		listVM.countryName = _.find(props.dealersettings.countries, {
			code: listVM.country,
		})
			? _.find(props.dealersettings.countries, { code: listVM.country }).name
			: '';
		listVM.regionName = _.find(props.dealersettings.regions, {
			code: listVM.region,
		})
			? _.find(props.dealersettings.regions, { code: listVM.region }).name
			: '';
		listVM.subregionName = _.find(props.dealersettings.subregions, { code: listVM.subregion })
			? _.find(props.dealersettings.subregions, { code: listVM.subregion }).name
			: '';
		listVM.groupName = _.find(props.dealersettings.subregions, { code: listVM.group })
			? _.find(props.dealersettings.groups, { code: listVM.group }).name
			: '';
		return listVM;
	};

	useEffect(() => {
		function handleResize() {
			document.documentElement.style.setProperty(
				'--mapContainerHeight',
				window.innerHeight - 50 + 'px',
			);
		}
		window.addEventListener('resize', handleResize);

		document.documentElement.style.setProperty(
			'--mapContainerHeight',
			window.innerHeight - 50 + 'px',
		);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const getClientsCoordinatesArr = clientsList => {
		let newArr = clientsList.map(client => {
			return {
				lat: client.latitude,
				lng: client.longitude,
				documentID: client.documentID,
				name: client.name,
				markerURL: client.markerURL ? client.markerURL : ''
			};
		});
		return newArr;
	};

	const onMarkerClick = documentID => {
		handleOpenSidePanel(documentID);
	};

	const handleFilterClose = () => {
		setShowFilterModal(false);
	};

	const handleFilterOpen = () => {
		setShowFilterModal(true);
	};

	const handleApply = (region, subregion, country, state, dealer, group) => {
		let _region = ''
		let _subregion = ''
		let _group = ''
		let _country = ''
		let _state = ''
		let _dealer = ''
		if (region) {
			_region = region;
		} else {
			_region = null;
		}
		if (subregion) {
			_subregion = subregion;
		} else {
			_subregion = null;
		}
		if (group) {
			_group = group;
		} else {
			_group = null;
		}

		if (country) {
			_country = country;
		} else {
			_country = null;
		}

		if (state) {
			_state = state;
		} else {
			_state = null;
		}

		if (dealer) {
			_dealer = dealer;
		} else {
			_dealer = null;
		}

		let filteredClients = clientLst.filter(client => filterClients(client, _region, _subregion, _country, _state, _dealer, _group));

		setCountry(_country)
		setState(_state)
		setDealer(_dealer)
		setRegion(_region)
		setSubregion(_subregion)
		setGroup(_group)
		setClientsToFilter(filteredClients);
	}

	const filterClients = (client, region, subregion, country, state, dealer, group) => {
		let returnValue = true;

		if (region) {
			if (client.region === region.value) {
				returnValue = true;
			} else {
				returnValue = false;
			}
		}

		if (subregion) {
			if (client.subregion === subregion.value) {
				returnValue = true;
			} else {
				returnValue = false;
			}
		}

		if (group) {
			if (client.group === group.value) {
				returnValue = true;
			} else {
				returnValue = false;
			}
		}

		if (country) {
			if (client.country === country.value) {
				returnValue = true;
			} else {
				returnValue = false;
			}
		}

		if (state) {
			if (client.state === state.value) {
				returnValue = true;
			} else {
				returnValue = false;
			}
		}

		if (dealer) {
			if (client.documentID === dealer.value) {
				returnValue = true;
				return returnValue;
			} else {
				returnValue = false;
			}
		}

		return returnValue;
	};

	const clearFilter = clientList => {
		let filteredClients = clientList;
		setClientsToFilter(filteredClients);
		setRegion(null);
		setSubregion(null);
		setCountry(null);
		setState(null);
		setDealer(null);
		setGroup(null);
	};

	const handleOpenSidePanel = dealerID => {
		setSidePanel({
			clsActive: 'active',
			dealerID,
			title: clientLst.filter(client => client.documentID === dealerID)[0].name,
			dealer: clientLst.filter(client => client.documentID === dealerID)[0],
		});
	};

	const handleCloseSidePanel = () => {
		setSidePanel({
			clsActive: '',
			dealerID: '',
			title: '',
		});
	};

	const handleRedirectToDashboard = (path, clientID) => {
		const currentClient = props.dealersettings.client;
		const clientToNavigate = props.dealersettings.clients.filter(
			client => client.id === clientID,
		);

		// console.log('currentClient,', currentClient);
		// console.log('clientToNavigate,', clientToNavigate);

		if (clientToNavigate.length === 0) {
			toast.notify('You do not have access to this dealer!', {
				duration: 2000,
			});
			return;
		}

		Swal.fire({
			title: CommonHelper.showLocale(props, 'Are you sure?'),
			text: `${CommonHelper.showLocale(props, 'Do you want to switch to')} ${clientToNavigate[0].name}?`,
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
		}).then(result => {
			if (result.value) {
				// handleModuleChange(
				// 	'enquiry',
				// 	AllModules.find(item => item.module === 'enquiry').route,
				// );
				window.firebase.firestore().doc(`users/${localStorage.uid}/userClients/${clientID}`)
					.set({ defaultModule: 'enquiry' }, { merge: true });
				localStorage.setItem('oemswitchclient', clientID)
				localStorage.setItem('defaultModule', 'enquiry');
				resetnavbar();
				handleClientChange(clientID);
				// history.push('/dashboard')
			}
		});
		//}
	};

	const getFilterDisplay = () => {
		const str = `${dealer ? `${dealer.label}, ` : ''}${state ? `${state.label}, ` : ''}${country ? `${country.label}, ` : ''}${group ? `${group.label}, ` : ''}${subregion ? `${subregion.label}, ` : ''}${region ? `${region.label}, ` : ''}`;
		return str.substring(0, str.length - 2)
	}

	return (
		<>
			<div className='middle-wrapper'>
				<div
					className='oem-panel'
					style={{
						overflow: 'hidden',
						height: `${window.innerHeight - 50
							}px`,
					}}
				>
					<div
						className='oem-grid-panel'
					// style={{ height: `${ window.outerHeight - 100 } px` }}
					>
						{!_.isEmpty(props.dealersettings.clients) ? (
							<div className='oem-grid-leftbar '>
								<MapWrapper
									isMarkerShown={true}
									clientCoordinatesArray={getClientsCoordinatesArr(
										clientsToFilter,
									)}
									onMarkerClick={onMarkerClick}
								/>
							</div>
						) : (
							<div className='oem-grid-leftbar oem-map-bg'>
								<div className="oem-no-mapdata"> <span>No Dealers Available </span></div>
							</div>
						)}

						<div className='oem-grid-rightbar'>
							<div className='oem-rightbar-head'>
								<div className='oem-rightbar-filter-icon'>
									<a
										href='#'
										onClick={handleFilterOpen}
										title='Filter Dealers'
									>
										<i className='ico icon-filter'></i>
									</a>
								</div>
								<h3>{moment().format('dddd, MMMM D')}</h3>
							</div>

							<div className='oem-rightbar-filter-result'>
								<i className='ico icon-location'></i>
								{!dealer &&
									!state &&
									!country &&
									!region &&
									!group &&
									!subregion &&
									'No Filter Selected'}
								{getFilterDisplay()}
								{(dealer || state || country || region || subregion || group) ? (
									<a href="#" className="oem-rightbar-filter-clear" onClick={() => clearFilter(clientLst)} ><i className="ico icon-remove"></i></a>
								) : (
									<></>
								)}
							</div>

							<OEMCounts
								{...props}
								clientsToRender={clientsToFilter}
								totalOEMCounts={totalOEMCounts}
								countsLoader={countsLoader}
								enqStatus={enqStatus}
							/>
						</div>
					</div>
				</div>
				<PopUpModal show={showFilterModal}>
					<OEMFilter
						show={showFilterModal}
						handleClose={handleFilterClose}
						clientLst={clientLst}
						regionParent={region}
						subregionParent={subregion}
						groupParent={group}
						countryParent={country}
						stateParent={state}
						dealerParent={dealer}
						clearFilter={clearFilter}
						dealersettings={props.dealersettings}
						handleApply={handleApply}
					/>
				</PopUpModal>
			</div>
			<SidePanel
				clsActive={sidePanel.clsActive}
				clsName={'sidebar'}
				sidepanelClose={handleCloseSidePanel}
				title={sidePanel.title}
				titleTranslationDisabled={true}
				navigateToDashboard={{
					title: sidePanel.title,
					show: true,
					callback: handleRedirectToDashboard,
					clientID: sidePanel.dealerID,
				}}
			>
				<DealerSidePanel
					{...props}
					clientsToRender={
						clientLst.filter(
							client => client.documentID === sidePanel.dealerID,
						)[0]
							? [
								clientLst.filter(
									client => client.documentID === sidePanel.dealerID,
								)[0],
							]
							: []
					}
					totalOEMCounts={totalOEMCounts}
					countsLoader={countsLoader}
					enqStatus={enqStatus}
				/>
			</SidePanel>
		</>
	)
};

export default OEMDashboard;
