import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import _images from '../../images'
import Translate from '../../constants/translate';
import { ContentOverlay, ReactSelect } from '../../components';
import moment from 'moment';
import CommonHelper from '../../services/common';

const FinanceCreditScoreWidget = (props) => {
    const enquiryOptionsDF = props?.dealersettings?.client?.settings?.enquiryOptionsDF?.filter(a => a.active === true && a?.subList?.length && (a.type === "select" || a.type === "multiselect")) || [];
    const [selectedField, setSelectedField] = useState(enquiryOptionsDF?.[0]?.value)
    const [refreshData, setRefreshData] = useState(false)
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState([])
    const [firstTimeLoaded, setFirstTimeLoaded] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true)
    const [refreshTime, setRefreshTime] = useState(0)
    const [dealersCount, setDealersCount] = useState([])
    const [oldDaterange, setOldDaterange] = useState({})
    const docID = localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.id;
    const docPathRef = window.firebase.firestore().doc(`users/${localStorage.uid}/dashoardWidgets/${docID}-financeCreditScore`);
    const creditScoreapproved = props?.dealersettings?.client?.clientSettings?.creditScore?.approved || 0;

    const inclInboundLeadsinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundLeadsinRpt) ? true : false);



    useEffect(() => {
        return () => {
            window.unsubdynamicfieldswidhetSnap && window.unsubdynamicfieldswidhetSnap();
        }
    }, []);

    useEffect(() => {
        if (refreshTime === 0)
            return;

        const timer = setTimeout(() => {
            if (moment().unix() - refreshTime > 1000) {
                if (_.isEmpty(props.dashboardFilter))
                    fetchData(true);
            }
        }, 900000);
        return () => clearTimeout(timer);
    }, [refreshTime])

    useEffect(() => {
        if (!Boolean(props.dealersettings.ownDashboard) || props.fromOEM)
            return
        fetchData();
        setInitialLoad(false)
    }, [])

    useEffect(() => {
        if (!initialLoad || (props.dealersettings.ownDashboard && !props.fromOEM))
            return
        setLoader(true)
        window.unsubenquiryTypesSnap = docPathRef
            .onSnapshot(snap => {
                if (snap.exists) {
                    setInitialLoad(false)
                    setDealersCount(snap.data().counts);
                    setData(getTotalCounts(snap.data().counts));
                    if (snap.data().selectedField) {
                        setSelectedField(snap.data().selectedField)
                    }
                    setRefreshTime(snap.data().logDate ? snap.data().logDate.seconds : 0);
                    if (snap.data().logDate && moment().unix() - snap.data().logDate.seconds > 1000) {
                        fetchData(true, snap.data()?.selectedField);
                    }
                    setLoader(false);
                }
                else {
                    fetchData();
                }
            })
    }, [initialLoad])

    const fetchData = async (hideLoader, value) => {
        // console.log('lead by campaigns')
        let _filter = {
            clientID: CommonHelper.getAllDealers(props.dealersettings),
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
        }
        if (props?.dashboardFilter?.user?.length > 0) {
            _filter.owner = props.dashboardFilter.user.join(',')
        }
        if (props.ownerDashboard) {
            _filter.owner = localStorage.uid
        }
        if (props?.dashboardFilter?.daterange?.startDate && props?.dashboardFilter?.daterange?.endDate) {
            _filter.startDate = moment(props.dashboardFilter.daterange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dashboardFilter.daterange.endDate.toDate()).format('YYYY-MM-DD')
        }
        if (props?.dashboardFilter?.pipelines?.length > 0) {
            _filter.pipeline = props.dashboardFilter.pipelines.join(',')
        } else if (!_.isEmpty(props.pipeline)) {
            if (inclInboundLeadsinRpt)
                _filter.pipeline = props.pipeline.filter(item => !Boolean(item.disableReport)).map(item => item.value).join(',')
            else
                _filter.pipeline = props.pipeline.filter(item => item.value !== 'LeadsBucket' && !Boolean(item.disableReport)).map(item => item.value).join(',')
        }

        if (props?.dashboardFilter?.salesType?.length > 0) {
            _filter.saleType = props.dashboardFilter.salesType.join(',')
        } else if (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
            _filter.saleType = props.dealersettings.accessSalesType.join(',');
        }


        let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
        if (!_.isEmpty(excludeOrigins))
            _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);

        if (props?.dashboardFilter?.makes?.length > 0) {
            _filter.make = props.dashboardFilter.makes.join(',')
        }
        _filter.creditScore = creditScoreapproved

        // New Filters Added for Porsche - SaMeeR - 05-09-24
        if (props?.dashboardFilter?.models?.length > 0) _filter.model = props.dashboardFilter.models.join(',')
        if (props?.dashboardFilter?.campaigns?.length > 0) _filter.campaign = props.dashboardFilter.campaigns.join(',')
        if (props?.dashboardFilter?.origins?.length > 0) _filter.origin = props.dashboardFilter.origins.join(',')
        if (props?.dashboardFilter?.enquiryTypes?.length > 0) _filter.enquiryType = props.dashboardFilter.enquiryTypes.join(',')
        if (props?.dashboardFilter?.leadSource?.length > 0) _filter.leadSource = props.dashboardFilter.leadSource.join(',')

        let _searchObject = {
            type: props?.dealersettings?.mvwReport ? "getFinanceCreditScoreV2" : "getFinanceCreditScore",
            params: JSON.stringify(_filter),
        }
        if (!hideLoader)
            setLoader(true);
        const getData = window.firebase.functions().httpsCallable('generic-getData2');
        const resp = await getData(_searchObject);
        if (resp.data.success) {
            //console.log('resp', resp)

            setDealersCount(resp.data.data);
            const _data = getTotalCounts(resp.data.data);
            setData(_data)
            if (_.isEmpty(props.dashboardFilter)) {
                await docPathRef.set({
                    counts: resp.data.data,
                    logDate: window.firebase.firestore.Timestamp.now(),
                    selectedField: value || selectedField
                }, { merge: true })
            }
            setLoader(false)
            setFirstTimeLoaded(true)
        } else {
            // Swal.fire('Something went wrong.');
            setLoader(false)
            setFirstTimeLoaded(true)
        }
    }

    const getTotalCounts = (countsByClients) => {
        let _counts = {
            todaycreditScore: countsByClients[0]?.todaycreditScore?.split(',') || [],
            monthcreditScore: countsByClients[0]?.monthcreditScore?.split(',') || [],
            todaypreApproved: countsByClients[0]?.todaypreApproved?.split(',') || [],
            monthpreApproved: countsByClients[0]?.monthpreApproved?.split(',') || [],
            todaynotpreApproved: countsByClients[0]?.todaynotpreApproved?.split(',') || [],
            monthnotpreApproved: countsByClients[0]?.monthnotpreApproved?.split(',') || [],
        }
        return _counts;
    }

    useEffect(() => {
        if (initialLoad)
            return;
        if (!_.isEmpty(props.dashboardFilter)) {
            if (props.fromOEM) {
                if ((!_.isEmpty(oldDaterange) || !_.isEmpty(props?.dashboardFilter?.daterange)) && JSON.stringify(oldDaterange) !== JSON.stringify(props?.dashboardFilter?.daterange)) {
                    setFirstTimeLoaded(false);
                    fetchData()
                    setOldDaterange(props?.dashboardFilter?.daterange)
                }
                else
                    setData(getTotalCounts(dealersCount.filter(a => props?.dashboardFilter?.clients?.length > 0 ? props.dashboardFilter.clients.includes(a.clientID) : true)));
            } else {
                window.unsubenquiryTypesSnap && window.unsubenquiryTypesSnap();
                setFirstTimeLoaded(false);
                fetchData();
            }
        }
        else {
            setInitialLoad(true)
        }

    }, [props.dashboardFilter])

    useEffect(() => {
        if (initialLoad)
            return;
        fetchData();
    }, [props.refreshData])

    return (
        <>
            <div className="dashboard-box">
                <div className="dashboard-box-header draggable-section">
                    <div className="dashboard-toolbox">
                        <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setFirstTimeLoaded(false); fetchData(); }}> <i className="ico icon-refresh"></i></a>
                        {
                            props.fromOEM || props.hideRemoveBtn ? (<></>) : (
                                <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); props.removeWidget() }}> <i className="ico icon-remove"></i></a>
                            )
                        }
                        {/* <a href="#" className="mini-button ml-1"> <i className="ico icon-more"></i></a> */}
                    </div>
                    <h3><Translate text={'Finance Credit Score'} /></h3>

                </div>
                {
                    (loader) ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
                        <div className="dashboard-box-body" style={{ minHeight: `${props.dealersettings.isMclaren === true ? '800px' : '390px'}` }}>
                            <div className="h-100" style={{ maxHeight: `390px`, minHeight: `390px`, overflow: `auto` }}>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <td className="head-light" width="40%">Name</td>
                                            <td className="head-light text-center" width="30%">Today</td>
                                            <td className="head-light text-center" width="30%">MTD</td>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td><div className="dealership-tbl float-left" >Pre-approved (<> {'>='} {creditScoreapproved}</>)</div></td>
                                            <td className="text-center cursor-pointer" onClick={(e) => {
                                                e.preventDefault();
                                                if (data?.todaypreApproved?.length > 0) {
                                                    props.handleShowEnquiryList({
                                                        documentIDs: data.todaypreApproved
                                                    })
                                                }
                                            }}>{data?.todaypreApproved?.length || 0}</td>
                                            <td className="text-center cursor-pointer" onClick={(e) => {
                                                e.preventDefault();
                                                if (data?.monthpreApproved?.length > 0) {
                                                    props.handleShowEnquiryList({
                                                        documentIDs: data.monthpreApproved
                                                    })
                                                }
                                            }}>{data?.monthpreApproved?.length || 0}</td>
                                        </tr>
                                        <tr>
                                            <td><div className="dealership-tbl float-left" >Not Pre-approved (<> {'<'} {creditScoreapproved}</>)</div></td>
                                            <td className="text-center cursor-pointer" onClick={(e) => {
                                                e.preventDefault();
                                                if (data?.todaynotpreApproved?.length > 0) {
                                                    props.handleShowEnquiryList({
                                                        documentIDs: data.todaynotpreApproved
                                                    })
                                                }
                                            }}>{data?.todaynotpreApproved?.length || 0}</td>
                                            <td className="text-center cursor-pointer" onClick={(e) => {
                                                e.preventDefault();
                                                if (data?.monthnotpreApproved?.length > 0) {
                                                    props.handleShowEnquiryList({
                                                        documentIDs: data.monthnotpreApproved
                                                    })
                                                }
                                            }}>{data?.monthnotpreApproved?.length || 0}</td>
                                        </tr>
                                        <tr>
                                            <td><div className="dealership-tbl float-left" >Total</div></td>
                                            <td className="text-center cursor-pointer" onClick={(e) => {
                                                e.preventDefault();
                                                if (data?.todaycreditScore?.length > 0) {
                                                    props.handleShowEnquiryList({
                                                        documentIDs: data.todaycreditScore
                                                    })
                                                }
                                            }}>{data?.todaycreditScore?.length || 0}</td>
                                            <td className="text-center cursor-pointer" onClick={(e) => {
                                                e.preventDefault();
                                                if (data?.monthcreditScore?.length > 0) {
                                                    props.handleShowEnquiryList({
                                                        documentIDs: data.monthcreditScore
                                                    })
                                                }
                                            }}>{data?.monthcreditScore?.length || 0}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>)
                }

            </div>
        </>
    );
}

export default FinanceCreditScoreWidget;