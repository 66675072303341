import React from "react";

export const formVM = {
	documentID: null,
	title: '',
	type: '',
	fields: '',
	oemID: null,
	regionID: null,
	groupID: null,
	clientID: null,
	addedBy: '',
	addedDate: null,
	modifiedBy: '',
	modifiedDate: null,
	isDeleted: false,
	backgroundColor: '#f4f7f8',
	primaryColor: '#4466f2',
	formColor: '#ffffff',
	fontColor: '#333333',
	addTo: '',
	origin: '',
	owner: '',
	enquiryType: '',
	activityType: '',
	iframeHeight: null,
	formHeight: null,
	level: '',
	levelID: '',
	campaign: '',
	settingsID: '',
	projectId: null,
	fontFamily: null,
	fontSize: null,
	redirectURL: null,
	fieldRadius: null,
	btnRadius: null,
	makes: null,
	dealers: null,
	leadSource: null,
	models: null,

};

export const formTypes = [
	{ active: true, value: 'activity', label: 'Activity' },
	{ active: true, value: 'inbound', label: 'Inbound' },
];

export const formListFields = [
	{
		name: 'Title',
		value: 'title',
		sortValue: 'title',
		flex: 1,
		default: true,
	},
	{
		name: 'Add To',
		value: '_addTo',
		sortValue: '_addTo',
		flex: 1,
		default: true,
	},
	{
		name: 'Created On',
		value: 'createdOn',
		sortValue: 'createdOn',
		subText: 'createdBy',
		flex: 1,
		default: true,
	},
	{
		name: 'Updated On',
		value: 'updatedOn',
		sortValue: 'updatedOn',
		subText: 'updatedBy',
		flex: 1,
		default: true,
	},
	{
		name: 'OEM/Region/Group/Client',
		value: 'levelsDOM',
		sortValue: 'strlevels',
		flex: 1,
		default: true,
	},
	{
		name: 'Level',
		value: 'levelValue',
		sortValue: 'levelValue',
		flex: 0,
		width: 120,
		default: true,
	},
	{
		name: 'Settings',
		value: 'settings',
		subText: 'documentID',
		width: 280,
		default: true,
		type: 'settings',
		flex: 0,
		fixedRight: true,
	},
];

export const defaultFields = [
	{
		className: 'text-center',
		label: 'Form Header',
		access: false,
		subtype: 'h2',
		type: 'header',
	},
	{
		className: 'form-control',
		label: 'First Name',
		placeholder: 'enter your first name',
		name: 'first-name',
		required: true,
		type: 'text',
	},
	{
		className: 'form-control',
		label: 'Last Name',
		placeholder: 'enter your last name',
		name: 'last-name',
		required: true,
		type: 'text',
	},
	{
		type: 'textarea',
		required: false,
		label: 'Comments',
		placeholder: 'enter comments...',
		className: 'form-control',
		name: 'textarea-comments',
		access: false,
		subtype: 'textarea',
	},
];

export const levelOptions = [
	{ value: 'oem', label: 'OEM', index: 1 },
	{ value: 'region', label: 'Region', index: 2 },
	{ value: 'group', label: 'Group', index: 3 },
	{ value: 'individual', label: 'Individual', index: 4 },
];


export const fontFamilies = [
	{ value: 'Soho Gothic Pro', label: <div style={{ fontFamily: 'Soho Gothic Pro' }}>Soho Gothic Pro</div> },
	{ value: 'Noto Sans', label: <div style={{ fontFamily: 'Noto Sans' }}>Noto Sans</div> }
];