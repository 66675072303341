import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import moment from 'moment'
import { ReactSelect } from '../../../components';
import { default as _images } from "../../../images";
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import { firestoreDB } from '../../../services/helper';
import Swal from 'sweetalert2';

const tostatus = [
    { label: 'All Attendees', value: 'all' },
    { label: 'Checked-In Attendees', value: 'checkedIn' }
]

const Allocation = (props) => {

    const [fields, setFields] = useState({ eventsProID: props.eventsPro.documentID, clientID: props?.eventsPro?.clientIDs?.[0] || null, owner: localStorage.uid, userName: props.dealersettings.name, status: tostatus[0].value });
    const [dealers, setDealers] = useState([]);
    const [errors, setErrors] = useState({});
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const { dealersettings } = props;
        if (dealersettings) {

            let _dealers = [];
            let _allClients = dealersettings.clients;
            if (_allClients) {
                _dealers = Object.keys(_allClients).map(cid => {
                    return {
                        value: _allClients[cid].id ? _allClients[cid].id : _allClients[cid].documentID,
                        label: _allClients[cid].name,
                        projectId: _allClients[cid].projectId
                    }
                })
            }
            setDealers(_dealers);
        }
    }, [])

    const handleReactSelectChange = (e, data) => {
        if (e) {
            setFields({
                ...fields,
                [data.name]: e.value
            });
        }
    }

    const handleNotesChanges = (e) => {
        e.preventDefault();

        let { name, value } = e.target;
        if (value)
            value = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });

        if (value.length > 0) {
            setErrors({ ...errors, 'notes': '' });
        }
        setFields({
            ...fields,
            [name]: value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (fields.clientID) {
            fields.projectId = _.find(dealers, { value: fields.clientID })?.projectId || null
        }
        setLoader(true)
        let pendingAttendees = firestoreDB(props.dealersettings).firestore().collection(`eventsProAttendees`)
            .where('isDeleted', '==', false)
            .where('eventsProID', '==', props.eventsPro.documentID)
            .where('allocationStatus', '==', 'new')
            //.orderBy('addedDate', 'desc')
            ;
        if (fields.status === 'checkedIn') {
            pendingAttendees = pendingAttendees.where('isCheckedIn', '==', true)
        }
        pendingAttendees = pendingAttendees.limit(1)

        const attendeesDocs = await pendingAttendees.get();

        if (attendeesDocs?.docs?.length === 0) {
            setLoader(false);
            Swal.fire('No pending attendees found to allocate.', '', 'info');
            return;
        }
        try {
            window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/clients/${fields.clientID}`).get()
                .then((doc) => {
                    if (!_.find(doc?.data()?.leadSource, { value: props.eventsPro.documentID })) {
                        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/clients/${fields.clientID}`).update({
                            leadSource: window.firebase.firestore.FieldValue.arrayUnion({
                                active: true,
                                level: "individual",
                                name: props.eventsPro.title,
                                value: props.eventsPro.documentID
                            })
                        })
                    }
                })
                
            const convertAttendee = window.firebase
                .functions()
                .httpsCallable('eventsPro-convertAttendees');
            const resp = await convertAttendee(fields);

            if (resp.data.success) {
                props.handleModalClose({});
                toast.notify('Allocation is in-progress...', {
                    duration: 2000
                })
            } else {
                //console.log(resp)
                Swal.fire('Something went wrong.', '', 'error');
            }
        } catch (err) {
            console.log(err)
            Swal.fire('Something went wrong.', '', 'error');
        }
    };



    const handleCancel = (e) => {
        e.preventDefault();
        props.handleModalClose({});
    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleModalClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-add-company"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.isReAssign ? 'Re-Allocate Leads' : 'Allocate Leads'} /></Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    {
                        !_.isEmpty(props?.eventsPro?.log) ? (
                            <div className={`alert alert-${props?.eventsPro?.log?.success ? 'success' : 'danger'} event-allocation-alert mb-2`} role="alert">
                                <div className="event-allocation-alert-icon">
                                    {
                                        props?.eventsPro?.log?.success ? (
                                            <i className="ico icon-sent mr-1" aria-hidden="true"></i>

                                        ) : (
                                            <i className="ico icon-mail-spam mr-1" aria-hidden="true"></i>
                                        )
                                    }
                                </div>
                                <div>
                                    {
                                        props?.eventsPro?.log?.success ? 'Success : ' : 'Error :'
                                    }
                                    {
                                        props?.eventsPro?.log?.message ? props?.eventsPro?.log?.message : (<></>)
                                    }

                                    <div className="event-allocation-pop-alert ">
                                        {
                                            props?.eventsPro?.log?.owner ? `Allocated By : ${props?.eventsPro?.log?.owner}` : ''
                                        }
                                        {
                                            props?.eventsPro?.log?.dt?.seconds ? ` on ${moment.unix(props?.eventsPro?.log?.dt?.seconds).format('DD/MM/YYYY hh:mm A')}` : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        ) : (<></>)
                    }
                    <div className="container-fluid">
                        <div className="row">
                            <div className=" pop-layout form-style">

                                <div className="form-row ">
                                    <div className="form-group col-md-12">
                                        <label > <Translate text={'Dealers'} /></label>
                                        <ReactSelect
                                            options={dealers.filter(a => props?.eventsPro?.clientIDs?.length ? props.eventsPro.clientIDs.includes(a.value) : false)}
                                            name={"clientID"}
                                            placeholder={'select dealer'}
                                            onChange={handleReactSelectChange}
                                            value={fields.clientID}
                                            classNamePrefix={`${errors["clientID"]} basic-select`}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>

                                <div className="form-row ">
                                    <div className="form-group col-md-12">
                                        <label > <Translate text={'Attendees'} /></label>
                                        <ReactSelect
                                            options={tostatus}
                                            name={"status"}
                                            placeholder={'select'}
                                            onChange={handleReactSelectChange}
                                            value={fields.status}
                                            classNamePrefix={`${errors["status"]} basic-select`}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label ><Translate text={'notes'} /></label>
                                    <textarea
                                        className={`${errors["notes"]} form-control notes`}
                                        name="notes" onChange={(e) => handleNotesChanges(e)}
                                        value={fields.notes ? fields.notes : ''}
                                        placeholder="notes"
                                        cols="80"
                                        rows="3"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className={`modal-footer ${loader ? 'form-disable' : ''}`}>

                    <button
                        type="button"
                        className={`btn btn-primary float-right ml-2 ${loader ? 'form-disable' : ''}`}
                        onClick={(e) => handleSubmit(e)}
                    >
                        {
                            loader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        <Translate text={'Allocate Now'} />
                    </button>
                    <button
                        type="button"
                        className="btn btn-red float-right"
                        onClick={(e) => handleCancel(e)}
                    ><Translate text={'later'} />
                    </button>


                </Modal.Footer>
            </Modal>

        </>
    )
}

export default Allocation;