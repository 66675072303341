import React, { useState, useEffect, useContext } from 'react'
import { Modal, Tabs, Tab } from 'react-bootstrap'
import { Dropdown } from 'semantic-ui-react'
import moment from 'moment'
import momenttz from 'moment-timezone'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { clientsVM, weekDays, addClientOEMMandatoryFields, levelOptions } from '../../settings/workflow/clients/viewModel'
import _ from 'lodash'
import _images from '../../../images'
import Translate from '../../../constants/translate';
import { InputText, ReactSelect, ReactTimePicker, ImageCropHook, AutoComplete, ReactMultiSelect, PopUpModal } from '../../../components'
import AddGroup from '../../settings/workflow/clients/addGroups'
import AddSubcollectionData from '../../settings/workflow/clients/addRegion'
import { storageBucket, validateEmail } from "../../../services/helper";
import CommonHelper from '../../../services/common'

const actionList = [
    { active: true, value: 'add', label: <><i className="ico icon-add mr-1"></i> <Translate text={'add'} /></> },
    { active: true, value: 'edit', label: <><i className="ico icon-edit mr-1"></i> <Translate text={'edit'} /></> }
]

const AddClient = (props) => {
    const [client, setClient] = useState(props.client);
    const [loader, setLoader] = useState(false)
    const [cropShow, setCropShow] = useState({ show: false, clsActive: '', src: null, id: '', type: '', ext: '', title: '' })
    const [imageLoader, setImageLoader] = useState(false)
    const [errorFields, setErrorFields] = useState({});
    const newClient = !_.isEmpty(props.client) ? false : true
    // const [groupOptions, setGroupOptions] = useState([])
    const [showAddGroupModel, setShowAddGroupModel] = useState(false)
    const [showAddRegionModel, setShowAddRegionModel] = useState({ show: false })
    const [groupsLoader, setGroupsLoader] = useState(false)
    const [regionsLoader, setRegionsLoader] = useState(false)
    const [groups, setClientGroups] = useState([]);
    const [regions, setClientRegions] = useState([]);
    const [subregions, setClientSubRegions] = useState([]);
    // const [groups, setGroups] = useState(null);
    const oldGroup = !_.isEmpty(props.client) ? props.client.group : ''
    const [popHeader, setPopHeader] = useState();
    const [selectedGroup, setSelectedGroup] = useState()
    const [markerLoader, setMarkerLoader] = useState(false)
    const [roles, setRoles] = useState([])
    const pipelineModules = (props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.settings &&
        props.dealersettings.client.settings.pipelineModules) ? props.dealersettings.client.settings.pipelineModules : [];

    useEffect(() => {
        if (_.isEmpty(props.client)) {
            const newclientVM = Object.assign({}, clientsVM);
            newclientVM.documentID = window.firebase.firestore().collection('clients').doc().id;
            newclientVM.settingsID = props.dealersettings.oemID;
            newclientVM.addedBy = localStorage.uid;
            newclientVM.modifiedBy = localStorage.uid;
            newclientVM.addedDate = window.firebase.firestore.Timestamp.now();
            newclientVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newclientVM.timezone = momenttz.tz.guess();
            newclientVM.category = 'Automotive';
            newclientVM.moduleSettings = {
                sales: {
                    active: true,
                    enabled: true
                }
            };
            newclientVM.clientSettings = {
                liteVersion: props.dealersettings.liteVersion ? props.dealersettings.liteVersion : null
            }
            newclientVM.moduleSettings = {
                ...newclientVM.moduleSettings,
                cafe: {
                    active: pipelineModules.includes('cafe') ? true : false,
                    enabled: pipelineModules.includes('cafe') ? true : false
                }
            }
            setClient(newclientVM)
        }
    }, [])

    useEffect(() => {
        const oemroleSnapshot = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.oemID}/roles`)
            .onSnapshot(querySnapshot => {
                let _roles = [];
                querySnapshot.forEach((rec) => {
                    if (_.isEmpty(rec.data().clientID))
                        _roles.push({ ...rec.data(), 'documentID': rec.id, 'value': rec.id })
                })
                setRoles(_roles)
            })
        return () => {
            oemroleSnapshot && oemroleSnapshot();
        }
    }, [])

    useEffect(() => {
        window.groupSnapshot = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/groups`)
            .orderBy('addedDate', 'asc')
            .onSnapshot(querySnapshot => {
                let _groups = [];
                querySnapshot.forEach((rec) => {
                    _groups.push({ ...rec.data(), 'documentID': rec.id })
                })
                setClientGroups(_groups)
                setGroupsLoader(true)
            })
        return () => {
            window.groupSnapshot && window.groupSnapshot();
        }
    }, [])

    useEffect(() => {
        window.regionSnapshot = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/regions`)
            .orderBy('name', 'asc')
            .onSnapshot(querySnapshot => {
                let _regions = [];
                querySnapshot.forEach((rec) => {
                    _regions.push({ ...rec.data(), 'documentID': rec.id, 'code': rec.id })
                })
                setClientRegions(_regions)
                setRegionsLoader(true)
            })
        return () => {
            window.regionSnapshot && window.regionSnapshot();
        }
    }, [])

    useEffect(() => {
        const subregionSnapshot = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/subregions`)
            .orderBy('name', 'asc')
            .onSnapshot(querySnapshot => {
                let _subregions = [];
                querySnapshot.forEach((rec) => {
                    _subregions.push({ ...rec.data(), 'documentID': rec.id, 'code': rec.id })
                })
                setClientSubRegions(_subregions)
            })
        return () => {
            subregionSnapshot && subregionSnapshot();
        }
    }, [])

    useEffect(() => {
        if (!_.isEmpty(client) && client.workingHours.indexOf(';') > 0 && _.isEmpty(client.startworkingHours) && _.isEmpty(client.endworkingHours)) {
            setClient({
                ...client,
                ['startworkingHours']: window.firebase.firestore.Timestamp.fromDate(moment(moment().format('YYYY-MM-DD' + ' ' + client.workingHours.split(';')[0]))._d),
                ['endworkingHours']: window.firebase.firestore.Timestamp.fromDate(moment(moment().format('YYYY-MM-DD' + ' ' + client.workingHours.split(';')[1]))._d)
            });
        }
    }, [client])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (name === 'email' || name === 'website' || name === 'contactEmail') {
            setClient({
                ...client,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setClient({
                    ...client,
                    [name]: newarray1.join(' ')
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setClient({
                    ...client,
                    [name]: str
                });
            }
        }

        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const errorChange = (key) => {
        let errorClass = 'input_error';

        if (errorFields !== {}) {
            if (!client[key] && addClientOEMMandatoryFields.indexOf(key) >= 0) {
                setErrorFields({
                    ...errorFields,
                    [key]: errorClass
                });
            }
            else {
                if (key === 'email') {
                    if (typeof client[key] !== "undefined") {
                        if (!validateEmail(client[key])) {
                            setErrorFields({
                                ...errorFields,
                                [key]: errorClass
                            });
                        }
                        else
                            setErrorFields({
                                ...errorFields,
                                [key]: ''
                            });
                    }
                }
                else
                    setErrorFields({
                        ...errorFields,
                        [key]: ''
                    });
            }


        }

    }

    const handleNumberChange = e => {
        var regexp = /^[0-9+ \b]+$/;
        let number = e.target.value;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setClient({
                ...client,
                [e.target.name]: e.target.value
            });
        }
    };

    const handleReactSelectChange = (e, data) => {
        if (e) {
            setClient({
                ...client,
                [data.name]: e.value
            });
            // if (data.name === 'region') {
            //     setRegionName({ name: e.label, documentID: e.value })
            // }
        }
        else {
            setClient({
                ...client,
                [data.name]: ''
            });
            // if (data.name === 'region') {
            //     setRegionName(null)
            // }
        }

        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleReactMultiSelectChange = (selectedOptions) => {
        const value = [];

        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        setClient({
            ...client,
            ['clientBrandIDs']: value
        });
        setErrorFields({ ...errorFields, ['clientBrandIDs']: '' })
    }

    const handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: ''
        });
    }

    const handleCropSelect = (_cropShow) => {

        const { src, id, type, ext } = _cropShow;
        setClient({
            ...client,
            [id]: src
        });
        saveImageToStorage(src, id, type, ext);

        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: ''
        })
    }

    const onSelectFile = (e, type, id, title) => {

        if (e.target.files && e.target.files.length > 0) {
            if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
                Swal.fire('File format not supported. Please select image file.', '', 'info')
                return;
            }
            var img = e.target.files[0].size;
            var imgsize = img / 1024 / 1024;
            if (imgsize > 10) {
                Swal.fire('Maximum file size exceeded.', '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {
                setCropShow({
                    show: true,
                    clsActive: 'overlay-modal active',
                    src: reader.result,
                    id: id,
                    type: type,
                    ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                    title: title
                })

            }
            reader.readAsDataURL(file)
        }
    }

    const saveImageToStorage = (imageBase64, id, type, ext) => {
        setImageLoader(true)
        var storageRef = storageBucket(props.dealersettings).ref(`clients/${client.documentID}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;

        },
            (error) => {
                //console.log(error);
            },
            () => {
                storageBucket(props.dealersettings).ref(`clients`).child(client.documentID).getDownloadURL()
                    .then(dataURL => {
                        setImageLoader(false)
                        setClient({
                            ...client,
                            [id]: dataURL
                        });
                    })
            })
    };

    const saveMarkerImage = (e, id) => {
        if (e.target.files && e.target.files.length > 0) {
            if (!e.target.files[0].name.match(/.(png)$/i)) {
                Swal.fire('Uploaded file is not a valid image. Only PNG files are allowed', '', 'info')
                return;
            }
            var img = e.target.files[0].size;
            var imgsize = img / 1024 / 1024;
            if (imgsize > 2) {
                Swal.fire('Maximum file size exceeded.', '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {
                setMarkerLoader(true)
                var storageRef = storageBucket(props.dealersettings).ref(`clients/${client.documentID}/mapicon`).putString(reader.result, 'data_url');
                storageRef.on('state_changed', () => {

                },
                    (error) => {
                        //console.log(error);
                    },
                    () => {
                        storageBucket(props.dealersettings).ref(`clients`).child(`${client.documentID}/mapicon`).getDownloadURL()
                            .then(dataURL => {
                                setMarkerLoader(false)
                                setClient({
                                    ...client,
                                    [id]: dataURL
                                });
                            })
                    })
            }
            reader.readAsDataURL(file)
        }
    };

    const saveClient = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (newClient) {
            for (let [key] of Object.entries(client)) {
                if (addClientOEMMandatoryFields.indexOf(key) >= 0) {
                    if (key === 'email' || key === 'contactEmail') {
                        if (typeof client[key] !== "undefined") {
                            if (!validateEmail(client[key])) {
                                formIsValid = false;
                                errors[key] = errorClass;
                            }
                        }
                    }
                    if (_.isEmpty(client[key])) {
                        formIsValid = false;
                        errors[key] = errorClass;
                    }
                }
            }
            if (addClientOEMMandatoryFields && addClientOEMMandatoryFields.indexOf('phoneORemail') >= 0 && !client['phone'] && !client['email']) {
                Swal.fire('Please enter Phone or Email', '', 'info');
                formIsValid = false;
            }
        }
        else {
            for (let [key] of Object.entries(client)) {
                if (key === 'email' || key === 'contactEmail') {
                    if (client[key]) {
                        if (!validateEmail(client[key])) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                }
            }
        }
        setErrorFields(errors)
        setLoader(true)
        let existingClient;
        let existingUser;
        //check duplicate dealer
        if (newClient && formIsValid) {
            const websiteDomain = CommonHelper.getDomain(client.website, true);
            const checkDomain = await window.firebase.firestore().collection('clients').where('keywords', 'array-contains', websiteDomain)
                .where('settingsID', '!=', props.dealersettings.oemID).limit(1).get();
            if (checkDomain.docs.length > 0) {
                checkDomain.forEach(doc => {
                    const _client = { ...doc.data(), documentID: doc.id };
                    existingClient = _client;
                });
            }

            if (!_.isEmpty(existingClient)) {
                const existingOEM = await window.firebase.firestore().doc(`clientSettings/${existingClient.settingsID}`).get();
                const result = await Swal.fire({
                    title: `Dealer exists`,
                    html: `Dealer already exists ${existingOEM.data() ? ` in <b>${existingOEM.data().name}</b>` : ''}. Are you sure do you want to Link?`,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: 'Link',
                    cancelButtonText: 'No',
                })

                if (!result.value) {
                    formIsValid = false;
                }
            }
            if (formIsValid) {
                const checkUser = await window.firebase.firestore().collection('users')
                    .where('email', '==', client.contactEmail)
                    .limit(1).get();
                if (checkUser.docs.length > 0) {
                    checkUser.forEach(doc => {
                        existingUser = { ...doc.data(), documentID: doc.id };
                    });
                }

                if (!_.isEmpty(existingUser) && _.isEmpty(existingClient)) {
                    const existingOEM = await window.firebase.firestore().doc(`clientSettings/${existingUser.settingsID}`).get();
                    const result = await Swal.fire({
                        title: `User exists`,
                        html: `User already exists ${existingOEM.data() ? ` in <b>${existingOEM.data().name}</b>` : ''}. Are you sure do you want to Link?`,
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonText: 'Link',
                        cancelButtonText: 'No',
                    })

                    if (!result.value) {
                        formIsValid = false;
                    }
                }
            }
        }
        if (!formIsValid) {
            setLoader(false)
            return;
        }
        let updateClient = client;
        if (existingClient) {
            const existingOEM = await window.firebase.firestore().doc(`clientSettings/${existingClient.settingsID}`).get();
            updateClient = {
                ...existingClient,
                settingsName: existingOEM.data() ? existingOEM.data().name : null,
                settingsIDs: existingClient.settingsIDs ? [...existingClient.settingsIDs.filter(a => a !== props.dealersettings.oemID), props.dealersettings.oemID] : [props.dealersettings.oemID],
            }
        }
        if (!_.isEmpty(updateClient.startworkingHours) && !_.isEmpty(updateClient.endworkingHours))
            updateClient.workingHours = moment.unix(client.startworkingHours.seconds).format('HH:mm:ss') + ';' + moment.unix(updateClient.endworkingHours.seconds).format('HH:mm:ss')
        delete updateClient.startworkingHours;
        delete updateClient.endworkingHours;
        updateClient.modifiedDate = window.firebase.firestore.Timestamp.now();
        updateClient.modifiedBy = localStorage.uid
        window.firebase.firestore().doc(`clients/${updateClient.documentID}`).set(updateClient, { merge: true })
            .then(async (snapshot) => {
                // window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/clients/${updateClient.documentID}`).set({
                //     name: client.name,
                //     documentID: client.documentID
                // }, { merge: true })
                if (newClient && _.isEmpty(existingClient)) {

                    //assign client to oem admin/region admin/group admin/ 

                    addClienttoAdminUser(updateClient, existingUser)
                    //ADD DEFAULT CAFE ITEM
                    const cafeDocID = window.firebase.firestore().collection('cafeItems').doc().id;
                    window.firebase.firestore().doc(`cafeItems/${cafeDocID}`).set({
                        name: 'water',
                        clientID: client.documentID,
                        documentID: cafeDocID,
                        category: 'drinks',
                        image: `${window.location.origin}/images/water.jpg`,
                        isAvailable: true,
                        isDeleted: false,
                        sortIndex: 0,
                        sortOrder: 0
                    }, { merge: true })

                    window.firebase.firestore().doc(`clients/${client.documentID}/counts/${client.documentID}`).set({
                        clientName: client.name,
                        clientID: client.documentID
                    }, { merge: true })
                }
                else if (!_.isEmpty(existingClient)) {
                    addClienttoAdminUser(existingClient, null);
                    setLoader(false)
                    toast.notify('Client ' + (newClient ? 'added' : 'updated') + ' successfully', {
                        duration: 2000
                    })
                    props.handleClose(updateClient, true)
                }
                else {
                    setLoader(false)
                    toast.notify('Client ' + (newClient ? 'added' : 'updated') + ' successfully', {
                        duration: 2000
                    })
                    props.handleClose(updateClient, false)
                    UpdateClienttoFinance(updateClient)
                }
            })
            .catch(error => {
                setLoader(false)
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });

    }

    const addClienttoAdminUser = async (updateClient, existingUser) => {
        if (!_.isEmpty(updateClient.contactEmail) && _.isEmpty(existingUser)) {
            const updateUser = {
                "email": updateClient.contactEmail,
                "password": window.firebase.firestore().collection(`users`).doc().id,
                "phone": `${updateClient.contactPhone}`,
                "enableMFA": false,
                "name": updateClient.contactName
            }
            const addUser = window.firebase.functions().httpsCallable('amsadmin-createUser');
            const respUser = await addUser(updateUser);

            //console.log(data)
            if (respUser.data.success === true) {
                delete updateUser.password;
                await window.firebase.firestore().doc(`users/${respUser.data.data}`).set({
                    ...updateUser,
                    documentID: respUser.data.data,
                    role: CommonHelper.getNameByValue(roles, updateClient.contactRoleID),
                    level: CommonHelper.getFieldByValue(roles, updateClient.contactRoleID, 'level'),
                    roleID: updateClient.contactRoleID,
                    department: 'Management',
                    defaultClientID: updateClient.documentID,
                    arrayClientID: [updateClient.documentID],
                    settingsID: props.dealersettings.oemID,
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedBy: localStorage.uid,
                    addedDate: window.firebase.firestore.Timestamp.now(),
                    addedBy: localStorage.uid
                }, { merge: true })
                await window.firebase.firestore().doc(`users/${respUser.data.data}/userClients/${updateClient.documentID}`)
                    .set({
                        name: updateClient.name,
                        department: 'Management',
                        roleID: updateClient.contactRoleID
                    }, { merge: true });

            }
        }
        else if (!_.isEmpty(existingUser)) {
            await window.firebase.firestore().doc(`users/${existingUser.documentID}`).set({
                arrayClientID: !_.isEmpty(existingUser.arrayClientID) ? [...existingUser.arrayClientID.filter(c => c !== updateClient.documentID), updateClient.documentID] : [updateClient.documentID]
            }, { merge: true })
            await window.firebase.firestore().doc(`users/${existingUser.documentID}/userClients/${updateClient.documentID}`).set({
                name: updateClient.name,
                department: existingUser.department,
                roleID: existingUser.roleID
            }, { merge: true })
        }
        const currentUser = await window.firebase.firestore().doc(`users/${localStorage.uid}`).get();
        if (currentUser.data()) {
            await window.firebase.firestore().doc(`users/${currentUser.id}`).set({
                arrayClientID: !_.isEmpty(currentUser.data().arrayClientID) ? [...currentUser.data().arrayClientID.filter(c => c !== updateClient.documentID), updateClient.documentID] : [updateClient.documentID]
            }, { merge: true })
            await window.firebase.firestore().doc(`users/${localStorage.uid}/userClients/${updateClient.documentID}`).set({
                name: updateClient.name,
                department: currentUser.data().department,
                roleID: currentUser.data().roleID
            }, { merge: true })
        }


        const oemlevelUsers = await window.firebase.firestore().collection(`users`)
            .where('settingsID', '==', props.dealersettings.oemID)
            .where('level', '==', 'oem')
            .where('role', '==', 'Administrator')
            .get();

        if (oemlevelUsers.docs.length > 0) {
            const batch = window.firebase.firestore().batch();
            oemlevelUsers.docs.forEach(user => {
                batch.update(window.firebase.firestore().doc(`users/${user.id}`), {
                    arrayClientID: !_.isEmpty(user.data().arrayClientID) ? [...user.data().arrayClientID.filter(c => c !== updateClient.documentID), updateClient.documentID] : [updateClient.documentID]
                });
                batch.set(window.firebase.firestore().doc(`users/${user.id}/userClients/${updateClient.documentID}`), {
                    name: updateClient.name,
                    department: user.data().department,
                    roleID: user.data().roleID
                }, { merge: true });
            })

            batch.commit().then(snapshot => {
                setLoader(false)
                toast.notify('Client ' + (newClient ? 'added' : 'updated') + ' successfully', {
                    duration: 2000
                })
                props.handleClose(updateClient, true)
                UpdateClienttoFinance(updateClient)
            }).catch((error) => {
                console.error("Error updating document: ", error);
            });
        } else {
            setLoader(false)
            toast.notify('Client ' + (newClient ? 'added' : 'updated') + ' successfully', {
                duration: 2000
            })
            props.handleClose(updateClient, true)
            UpdateClienttoFinance(updateClient)
        }
    }

    const UpdateClienttoFinance = (client) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    DealerId: client.documentID,
                    Name: client.name,
                    Url: props.dealersettings.apiURL,
                    Phone: client.phone,
                    Email: client.email,
                    WebSite: client.website,
                    Address: client.address
                })
            };
            fetch(props.dealersettings.financeURL + 'api/AMSProFinance/Action/SaveDealerInfo', requestOptions)
                .catch(error => {
                    console.log(error)
                })
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleHoursChange = (val, name) => {
        setClient({
            ...client,
            [name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d)
        });
    }

    const bindAddress = (place) => {
        var objClient = client;
        let componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            sublocality_level_1: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'short_name',
            //administrative_area_level_1: 'long_name',
            //country: 'long_name',
            postal_code: 'short_name'
        };
        if (place.formatted_address !== null)
            objClient = {
                ...objClient,
                ['address']: place.formatted_address
            };
        if (place.geometry && place.geometry.location && place.geometry.location.lng()) {
            objClient = {
                ...objClient,
                ['latitude']: place.geometry.location.lat(),
                ['longitude']: place.geometry.location.lng()
            };
        }

        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (componentForm[addressType]) {
                var val = place.address_components[i][componentForm[addressType]];
                if (addressType === "administrative_area_level_1")
                    objClient = {
                        ...objClient,
                        ['state']: val
                    };

                if (addressType === "country")
                    objClient = {
                        ...objClient,
                        ['country']: val
                    };
            }
        }
        setClient(objClient);
    };

    const onEditorStateChange = (obj) => {
        setClient({
            ...client,
            [obj.name]: obj.value
        });
    }

    const handleModelClose = (group) => {
        setShowAddGroupModel(false)
        if (!_.isEmpty(group)) {
            setSelectedGroup(group)
            setClient({
                ...client,
                ['group']: group.documentID
            });
        }
    }

    const handleModelRegionClose = (data, fieldName) => {
        setShowAddRegionModel({ show: false })
        if (!_.isEmpty(data)) {
            setClient({
                ...client,
                [fieldName]: data.documentID
            });
        }
    }


    const handlegroupAction = (type) => {
        if (type === 'add') {
            setShowAddGroupModel(true)
            setSelectedGroup(null)
            setPopHeader('Add Group')
        }
        else {
            if (_.find(groups, { documentID: client.group })) {
                setSelectedGroup(_.find(groups, { documentID: client.group }))
            }
            setShowAddGroupModel(true)
            setPopHeader('Edit Group')
        }
    }

    return (_.isEmpty(client) || !regionsLoader || !groupsLoader) ? (<></>) : (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-client"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="form-style w-100">
                                <div className="form-row settings-company pt-2">
                                    <div className="settings-companyimage">
                                        {
                                            (imageLoader)
                                                ?
                                                <div className="img-loader">
                                                    <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                        <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            (client.logoURL)
                                                ?
                                                <a data-fancybox={`logoURL`} href={client.logoURL} onClick={(e) => { e.preventDefault(); }}>
                                                    <img className="img-object-fit-contain" src={client.logoURL} alt="" height="65" />
                                                </a>
                                                :
                                                <img src={_images.nocompany} alt="" height="65" />
                                        }
                                    </div>
                                    <div className="settings-profile-info">
                                        <div className="settings-profile-upload">
                                            <label htmlFor="client-logoURL" className={`btn-common float-left mr-2 font-600`}>
                                                <Translate text={'Choose Picture'} />
                                                <input className="fileInput"
                                                    type="file"
                                                    name="testee"
                                                    id="client-logoURL"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => onSelectFile(e, 'profile', 'logoURL', 'Client Logo')} />
                                            </label>
                                            <div className={`settings-delete-button ${_.isEmpty(client.logoURL) ? 'btn-disable' : ''}`}> <a href="#" className="mini-button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setClient({
                                                        ...client,
                                                        ['logoURL']: ''
                                                    });
                                                }}> <i className="ico icon-delete"></i></a></div>
                                        </div>
                                        <div className=" settings-limit"><Translate text={'maxSizeMB'} /></div>
                                    </div>
                                </div>
                                <div className="settings-tab-wrap tabs">
                                    <div className="tabs-wraper">
                                        <nav>
                                            <Tabs defaultActiveKey="clientDetails" className="nav-fill"
                                                onSelect={(key) => {

                                                }}>
                                                <Tab eventKey="clientDetails" title="Dealership Details">
                                                    <div className="settings-tab-fields-wrap">
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label ><Translate text={'Dealer Name'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'name'}
                                                                    className={`form-control ${errorFields["name"]}`}
                                                                    name="name"
                                                                    onChange={handleOnChange}
                                                                    value={client.name}
                                                                    id="client-name"
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label ><Translate text={'Brands'} /></label>
                                                                <ReactMultiSelect
                                                                    options={props.brands.filter(item => !_.isEmpty(props.dealersettings.client.settings.brandIDs) ? props.dealersettings.client.settings.brandIDs.some(a => a === item.value) : true)}
                                                                    name={"clientBrandIDs"}
                                                                    placeholder={'select brands'}
                                                                    onChange={handleReactMultiSelectChange}
                                                                    value={client.clientBrandIDs}
                                                                    classNamePrefix={`${errorFields["clientBrandIDs"]} basic-select`}
                                                                    isMulti={true}
                                                                    id="client-brand"
                                                                    isSearchable={true}
                                                                >
                                                                </ReactMultiSelect>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-3">
                                                                {props.dealersettings.level && (props.dealersettings.level === 'oem') && (
                                                                    <Dropdown text={'action'} key={"1001"} className={`inputlink-addmore`} tabIndex={-1} >
                                                                        <Dropdown.Menu style={{ right: 0, left: 'auto' }}>
                                                                            {
                                                                                actionList.filter(e => _.isEmpty(client.region) ? e.value !== 'edit' : true).map((data, idx) => (
                                                                                    <Dropdown.Item className={'action-menu-fix'} text={data.label} key={idx} onClick={() => {
                                                                                        setShowAddRegionModel({
                                                                                            show: true,
                                                                                            title: `${CommonHelper.autoCaps(data.value)} Region`,
                                                                                            collection: 'regions',
                                                                                            collectionName: 'Region',
                                                                                            fieldName: 'region',
                                                                                            stateData: data.value === 'add' ? null : _.find(regions, { documentID: client.region }),
                                                                                            allData: regions
                                                                                        })
                                                                                    }} />
                                                                                ))
                                                                            }
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                )}
                                                                <label ><Translate text={'Region'} /></label>
                                                                <ReactSelect
                                                                    options={_.map(regions, function (e) { return { label: e.name, value: e.documentID, data: e } })}
                                                                    name={"region"}
                                                                    placeholder={'select region'}
                                                                    onChange={(e, data) => {
                                                                        handleReactSelectChange(e, data)
                                                                    }}
                                                                    value={client.region}
                                                                    classNamePrefix={`${errorFields["region"]} cursor-pointer basic-select`}
                                                                    removeClearable={false}
                                                                    id="client-region"
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                {props.dealersettings.level && (props.dealersettings.level === 'oem') && (
                                                                    <Dropdown text={'action'} key={"1001"} className={`inputlink-addmore`} tabIndex={-1} >
                                                                        <Dropdown.Menu style={{ right: 0, left: 'auto' }}>
                                                                            {
                                                                                actionList.filter(e => _.isEmpty(client.subregion) ? e.value !== 'edit' : true).map((data, idx) => (
                                                                                    <Dropdown.Item className={'action-menu-fix'} text={data.label} key={idx} onClick={() => {
                                                                                        setShowAddRegionModel({
                                                                                            show: true,
                                                                                            title: `${CommonHelper.autoCaps(data.value)} Subregion`,
                                                                                            collection: 'subregions',
                                                                                            collectionName: 'Subregion',
                                                                                            fieldName: 'subregion',
                                                                                            stateData: data.value === 'add' ? null : _.find(subregions, { documentID: client.subregion }),
                                                                                            allData: subregions
                                                                                        })
                                                                                    }} />
                                                                                ))
                                                                            }
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                )}
                                                                <label ><Translate text={'Subregion'} /></label>
                                                                <ReactSelect
                                                                    options={_.map(subregions, function (e) { return { label: e.name, value: e.documentID, data: e } })}
                                                                    name={"subregion"}
                                                                    placeholder={'select subregion'}
                                                                    onChange={(e, data) => {
                                                                        handleReactSelectChange(e, data)
                                                                    }}
                                                                    value={client.subregion}
                                                                    classNamePrefix={`${errorFields["subregion"]} cursor-pointer basic-select`}
                                                                    removeClearable={false}
                                                                    id="client-subregion"
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                {props.dealersettings.level && (props.dealersettings.level === 'oem' || props.dealersettings.level === 'region') && (
                                                                    <Dropdown text={'action'} key={"1001"} className={`inputlink-addmore`} tabIndex={-1} >
                                                                        <Dropdown.Menu style={{ right: 0, left: 'auto' }}>
                                                                            {
                                                                                actionList.filter(e => _.isEmpty(client.group) ? e.value !== 'edit' : true).map((data, idx) => (
                                                                                    <Dropdown.Item className={'action-menu-fix'} text={data.label} key={idx} onClick={() => handlegroupAction(data.value)} />
                                                                                ))
                                                                            }
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                )}
                                                                <label ><Translate text={'Group'} /></label>
                                                                <ReactSelect
                                                                    options={_.map(groups, function (e) { return { label: e.name, value: e.documentID, data: e } })}
                                                                    name={"group"}
                                                                    placeholder={'select group'}
                                                                    onChange={(e, data) => {
                                                                        setSelectedGroup(e ? e.data : null);
                                                                        handleReactSelectChange(e, data)
                                                                    }}
                                                                    value={client.group}
                                                                    classNamePrefix={`${errorFields["group"]} cursor-pointer basic-select`}
                                                                    removeClearable={false}
                                                                    id="client-group"
                                                                >
                                                                </ReactSelect>
                                                            </div>

                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label ><Translate text={'email'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'email'}
                                                                    className={`form-control ${errorFields["email"]}`}
                                                                    name="email"
                                                                    onChange={handleOnChange}
                                                                    value={client.email}
                                                                    id="client-email"
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label ><Translate text={'phone'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'phone'}
                                                                    className={`form-control ${errorFields["phone"]}`}
                                                                    name="phone"
                                                                    onChange={handleNumberChange}
                                                                    value={client.phone}
                                                                    id="client-phone"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label ><Translate text={'website'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'website'}
                                                                    className={`form-control ${errorFields["website"]}`}
                                                                    name="website"
                                                                    onChange={handleOnChange}
                                                                    value={client.website}
                                                                    id="client-website"
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label ><Translate text={'Fax'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'fax'}
                                                                    className={`form-control ${errorFields["fax"]}`}
                                                                    name="fax"
                                                                    onChange={handleNumberChange}
                                                                    value={client.fax}
                                                                    id="client-fax"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label ><Translate text={'address'} /></label>
                                                                <AutoComplete
                                                                    className={`form-control ${errorFields["address"]}`}
                                                                    bindAddress={bindAddress}
                                                                    types={['address']}
                                                                    value={client.address}
                                                                    placeholder={'search address'}
                                                                    onChange={handleOnChange}
                                                                    componentRestrictions={{ country: [] }}
                                                                    name="address"
                                                                    id="client-address"
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label ><Translate text={'Latitude'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'latitude'}
                                                                    className={`form-control`}
                                                                    name="latitude"
                                                                    onChange={handleOnChange}
                                                                    value={client.latitude}
                                                                    id="client-latitude"
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label ><Translate text={'Longitude'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'longitude'}
                                                                    className={`form-control`}
                                                                    name="longitude"
                                                                    onChange={handleOnChange}
                                                                    value={client.longitude}
                                                                    id="client-longitude"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label ><Translate text={'Timezone'} /></label>
                                                                <ReactSelect
                                                                    options={momenttz.tz.names().map(rec => {
                                                                        return {
                                                                            label: rec, value: rec, active: true,
                                                                        }
                                                                    })}
                                                                    name={"timezone"}
                                                                    placeholder={'select timezone'}
                                                                    onChange={handleReactSelectChange}
                                                                    value={client.timezone}
                                                                    classNamePrefix={`${errorFields["timezone"]} cursor-pointer basic-select`}
                                                                    removeClearable={true}
                                                                    id="client-timezone"
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label ><Translate text={'state'} /></label>
                                                                <ReactSelect
                                                                    options={
                                                                        !_.isEmpty(client.country) ?
                                                                            _.map(props.dealersettings.states.filter(item => item.country_code === client.country), function (e) { return { label: e.name, value: e.code, active: _.isBoolean(e.active) ? e.active : true, } })
                                                                            : []
                                                                    }
                                                                    name={"state"}
                                                                    placeholder={'select state'}
                                                                    onChange={handleReactSelectChange}
                                                                    value={client.state}
                                                                    classNamePrefix={`${errorFields["state"]} cursor-pointer basic-select`}
                                                                    removeClearable={true}
                                                                    id="client-state"
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label ><Translate text={'country'} /></label>
                                                                <ReactSelect
                                                                    options={_.map(props.dealersettings.countries, function (e) { return { label: e.name, value: e.code, active: _.isBoolean(e.active) ? e.active : true, } })}
                                                                    name={"country"}
                                                                    placeholder={'select country'}
                                                                    onChange={handleReactSelectChange}
                                                                    value={client.country}
                                                                    classNamePrefix={`${errorFields["country"]} cursor-pointer basic-select`}
                                                                    removeClearable={true}
                                                                    id="client-country"
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label ><Translate text={'Week Start'} /></label>
                                                                <ReactSelect
                                                                    options={weekDays}
                                                                    name={"weekStart"}
                                                                    placeholder={'select week start'}
                                                                    onChange={handleReactSelectChange}
                                                                    value={client.weekStart}
                                                                    classNamePrefix={`${errorFields["weekStart"]} cursor-pointer basic-select`}
                                                                    removeClearable={true}
                                                                    id="client-wekstart"
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label ><Translate text={'Business Hours From'} /></label>
                                                                <div className="inner-addon right-addon">
                                                                    <i className="bg-icons ico icon-time"></i>
                                                                    <ReactTimePicker
                                                                        value={client.startworkingHours ? moment.unix(client.startworkingHours.seconds)._d : null}
                                                                        name={'startworkingHours'}
                                                                        onChange={handleHoursChange}
                                                                        timeIntervals={30}
                                                                        placeholder='h:mm aa'
                                                                        className={`form-control ${errorFields["startworkingHours"]}`}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label ><Translate text={'to'} /></label>
                                                                <div className="inner-addon right-addon">
                                                                    <i className="bg-icons ico icon-time"></i>
                                                                    <ReactTimePicker
                                                                        value={client.endworkingHours ? moment.unix(client.endworkingHours.seconds)._d : null}
                                                                        name={'endworkingHours'}
                                                                        onChange={handleHoursChange}
                                                                        timeIntervals={30}
                                                                        placeholder='h:mm aa'
                                                                        className={`form-control ${errorFields["endworkingHours"]}`}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="settings-sub-container mt-4">
                                                        <h3>Contact Person</h3>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label ><Translate text={'name'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'name'}
                                                                    className={`form-control ${errorFields["contactName"]}`}
                                                                    name="contactName"
                                                                    onChange={handleOnChange}
                                                                    value={client.contactName}
                                                                    id="client-contactName"
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label ><Translate text={'email'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'email'}
                                                                    className={`form-control ${errorFields["contactEmail"]}`}
                                                                    name="contactEmail"
                                                                    onChange={handleOnChange}
                                                                    value={client.contactEmail}
                                                                    id="client-contactEmail"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label ><Translate text={'phone'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'phone'}
                                                                    className={`form-control ${errorFields["contactPhone"]}`}
                                                                    name="contactPhone"
                                                                    onChange={handleNumberChange}
                                                                    value={client.contactPhone}
                                                                    id="client-contactPhone"
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label ><Translate text={'Role'} /></label>
                                                                <ReactSelect
                                                                    options={_.map(roles.filter(a => a.level !== 'oem'), function (c) {
                                                                        return {
                                                                            value: c.documentID,
                                                                            doc: c,
                                                                            label: <>{c.name}<span className="user-role-span">{_.find(levelOptions, { value: c.level }) ? _.find(levelOptions, { value: c.level }).label : c.level}</span></>,
                                                                            searchlabel: c.name
                                                                        };
                                                                    })}
                                                                    name={"contactRoleID"}
                                                                    placeholder={'select role'}
                                                                    onChange={handleReactSelectChange}
                                                                    value={client.contactRoleID}
                                                                    classNamePrefix={`${errorFields["contactRoleID"]} cursor-pointer basic-select`}
                                                                    removeClearable={true}
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="settings-sub-container mt-4">
                                                        <h3>Dealership Map Icon (OEM)</h3>
                                                        <div className="form-row settings-company pt-2">
                                                            <div className="settings-oem-mappin">
                                                                {
                                                                    (markerLoader)
                                                                        ?
                                                                        <div className="img-loader">
                                                                            <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }
                                                                {
                                                                    (client.markerURL)
                                                                        ?
                                                                        <a data-fancybox={`markerURL`} href={client.markerURL} onClick={(e) => { e.preventDefault(); }}>
                                                                            <img className="img-object-fit-contain" src={client.markerURL} alt="" width="80" height="80" />
                                                                        </a>
                                                                        :
                                                                        <img src={_images.nomapImage} alt="" width="80" height="80" />
                                                                }
                                                            </div>
                                                            <div className="settings-profile-info">
                                                                <div className="settings-profile-upload">
                                                                    <label htmlFor="client-mapURL" className="btn-common float-left mr-2 font-600"> Choose Picture
                                                                        <input className="fileInput"
                                                                            type="file"
                                                                            name="testee"
                                                                            id="client-mapURL"
                                                                            accept="image/*"
                                                                            style={{ display: 'none' }}
                                                                            onChange={(e) => saveMarkerImage(e, 'markerURL')} />
                                                                    </label>
                                                                    <div className={`settings-delete-button ${_.isEmpty(client.markerURL) ? 'btn-disable' : ''}`}> <a href="#" className="mini-button"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setClient({
                                                                                ...client,
                                                                                ['markerURL']: ''
                                                                            });
                                                                        }}> <i className="ico icon-delete"></i></a></div>
                                                                </div>
                                                                <div className=" settings-limit">Max size 2 MB. Formats: PNG.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                {/* <Tab eventKey="terms" title="Privacy Terms">
                                                    <Tabs defaultActiveKey="testdrivetc" className="nav-fill">
                                                        <Tab eventKey="testdrivetc" title="Test Drive">
                                                            <div className="settings-terms-wrap">
                                                                <div className="form-group">
                                                                    <TextEditor
                                                                        name={'testdriveTC'}
                                                                        placeholder={'test drive terms & conditions'}
                                                                        value={client.testdriveTC}
                                                                        onChange={onEditorStateChange}
                                                                        toolbarOptions={['inline', 'list','textAlign']}
                                                                        />
                                                                </div>
                                                            </div>
                                                        </Tab>
                                                        <Tab eventKey="loanvehicletc" title="Loan Vehicle">
                                                            <div className="settings-terms-wrap">
                                                                <div className="form-group">
                                                                    <TextEditor
                                                                        name={'fleetTC'}
                                                                        placeholder={'loan vehicle terms & conditions'}
                                                                        value={client.fleetTC}
                                                                        onChange={onEditorStateChange} 
                                                                        toolbarOptions={['inline', 'list','textAlign']}
                                                                        />
                                                                </div>
                                                            </div>
                                                        </Tab>
                                                        <Tab eventKey="insurancewaiver" title="Insurance Waiver">
                                                            <div className="settings-terms-wrap">
                                                                <div className="form-group">
                                                                    <TextEditor
                                                                        name={'insuranceTC'}
                                                                        placeholder={'insurance waiver terms & conditions'}
                                                                        value={client.insuranceTC}
                                                                        onChange={onEditorStateChange}
                                                                        toolbarOptions={['inline', 'list','textAlign']}
                                                                         />
                                                                </div>
                                                            </div>
                                                        </Tab>
                                                        <Tab eventKey="customerconsent" title="Customer Consent">
                                                            <div className="settings-terms-wrap">
                                                                <div className="form-group">
                                                                    <TextEditor
                                                                        name={'customerConsent'}
                                                                        placeholder={'customer consent'}
                                                                        value={client.customerConsent}
                                                                        onChange={onEditorStateChange} 
                                                                        toolbarOptions={['inline', 'list','textAlign']}
                                                                        />
                                                                </div>
                                                            </div>
                                                        </Tab>
                                                        <Tab eventKey="financetc" title="Finance">
                                                            <div className="settings-terms-wrap">
                                                                <div className="form-group">
                                                                    <TextEditor
                                                                        name={'financeTC'}
                                                                        placeholder={'finance terms & condition'}
                                                                        value={client.financeTC}
                                                                        onChange={onEditorStateChange}
                                                                        toolbarOptions={['inline', 'list','textAlign']}
                                                                         />
                                                                </div>
                                                            </div>
                                                        </Tab>
                                                    </Tabs>
                                                </Tab> */}
                                            </Tabs>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className={`btn btn-primary float-right ml-2 ${loader ? 'form-disable' : ''}`} onClick={(e) => saveClient(e)}>
                        {
                            loader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        <Translate text={'save'} />
                    </button>
                    <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} ><Translate text={'cancel'} /></button>
                </Modal.Footer>
            </Modal>
            <ImageCropHook
                cropShow={cropShow}
                handleClose={handleCropClose}
                handleSelect={handleCropSelect}
            ></ImageCropHook>
            <PopUpModal show={showAddGroupModel}>
                <AddGroup
                    {...props}
                    show={showAddGroupModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    group={selectedGroup}
                    allGroups={groups}
                />
            </PopUpModal>
            <PopUpModal show={showAddRegionModel.show}>
                <AddSubcollectionData
                    {...props}
                    {...showAddRegionModel}
                    handleClose={handleModelRegionClose}
                />
            </PopUpModal>
        </>
    )
}

export default AddClient