export const tableFields = [
    {
        name: 'Name',
        value: 'name',
        flex: 1,
        default: true
    },
    {
        name: 'Status',
        value: 'statusDOM',
        flex: 1,
        default: true,
    },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        default: true,
    },
    {
        name: 'Completed On',
        value: 'completedOn',
        flex: 1,
        default: true,
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 120,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]