import React, { Component, Fragment } from "react";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import moment from 'moment';



import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import SalesFunnel from "../../dashboard/salesFunnel";
import EnquiryLabelCounts from "../../dashboard/labelsCounts";
import OriginCounts from "../../dashboard/originCounts";
import EnquiryTypeCounts from "../../dashboard/enquiryTypeCounts";
import CampaignCounts from "../../dashboard/campaignCounts";
import StatusCounts from "../../dashboard/statusCounts";
import DealerTarget from "./dealerTarget";
import { dynamicBarChartWidgets, dynamicPieChartWidgets, widgetSettingsNames } from "../../dashboardPorsche/viewModel";
import LostLeadsReasons from '../../dashboardPorsche/lostReasonCounts'
import LeadsCreated from '../../dashboardPorsche/leadsCreated'
import DynamicPieChart from '../../dashboardPorsche/dynamicPieCharts'
import LeadsBucket from "./leadsBucket";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
export default class DragDropLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentBreakpoint: "lg",
      compactType: "vertical",
      mounted: false,
      layouts: { lg: props.initialLayout }
    };

    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.initialLayout !== this.state.layouts) {
      this.setState({ layouts: { lg: nextProps.initialLayout } });
    }
  }

  onBreakpointChange(breakpoint) {
    this.setState({
      currentBreakpoint: breakpoint
    });
  }

  onLayoutChange(layout, layouts) {
    //console.log('layout, layouts', layout, layouts)
    this.props.onLayoutChange(layout);
  }

  calculateHeight(sectionID, layoutArr) {
    let selectedSection = layoutArr.filter(obj => obj.i === sectionID)
    if (selectedSection.length === 0)
      return 30
    let columnHeight = Number(selectedSection[0].h)
    if (columnHeight === 1) {
      return 30
    }
    columnHeight = columnHeight - 1
    columnHeight = columnHeight * 45
    columnHeight = columnHeight + 30

    return columnHeight
  }

  filterLayouts = (layouts) => {

    if (!this.props.pipeline.some(item => item.value === 'LeadsBucket') || (this.props.clientLostApproval && this.props.managerLostApproval)) {
      if (layouts.lg[0].i === '0') {
        layouts.lg.shift()
      }
    }
    //console.log('layout passed to the grid', layouts)

    // settings height and width to 0 of hidden widgets
    // let layoutWithDashboardSettingsApplied = {...layouts}
    let layoutWithDashboardSettingsApplied = layouts.lg.filter(a => a.i !== 'null').map((rec, index) => {
      return {
        ..._.pickBy(rec, v => v !== null && v !== undefined),
        i: rec.i === 'null' ? `${index}` : rec.i,
        maxH: 16,
        maxW: 12,
        minH: (rec.i === 'LeadsBucket' || rec.i === 'ActivitiesWidget' || rec.i === 'InboundActivitiesWidget') ? 3 : rec.i === 'LeadsByLabels' ? 7 : 11,
        minW: 4,
        h: (rec.h === 0 || rec.h === 1 || rec.h === undefined) ? ((rec.i === 'LeadsBucket' || rec.i === 'ActivitiesWidget') ? 4 : (rec.i === 'InboundActivitiesWidget') ? 3 : rec.i === 'LeadsByLabels' ? 7 : 11) : rec.h,
        w: (rec.w === 0 || rec.w === 1 || rec.w === undefined) ? 4 : rec.w,
        isResizable: (rec.i === 'LeadsBucket' || rec.i === 'ActivitiesWidget' || rec.i === 'InboundActivitiesWidget') ? false : true,
        isBounded: false,
        isDraggable: true,
        resizeHandles: false
      }
    })
    return { lg: layoutWithDashboardSettingsApplied }
  }


  render() {
    // const enableLoanBooking = ((!_.isEmpty(this.props.dealersettings) &&
    //   !_.isEmpty(this.props.dealersettings.client) &&
    //   !_.isEmpty(this.props.dealersettings.client.clientSettings) &&
    //   this.props.dealersettings.client.clientSettings.enableLoanBooking) ? true : false);
    // const showInboundActivities = ((!_.isEmpty(this.props.dealersettings) &&
    //   !_.isEmpty(this.props.dealersettings.client) &&
    //   !_.isEmpty(this.props.dealersettings.client.clientSettings) &&
    //   this.props.dealersettings.client.clientSettings.separateInbdUserAct) ? true : false);
    // const performanceReviewEnabled = ((this.props.dealersettings && !_.isEmpty(this.props.dealersettings.client) && !_.isEmpty(this.props.dealersettings.client.moduleSettings) &&
    //   !_.isEmpty(this.props.dealersettings.client.moduleSettings.performanceReview) &&
    //   this.props.dealersettings.client.moduleSettings.performanceReview.enabled) ? true : false);

    const {
      handleShowEnquiryList,
      // handleShowActivityList,
      // pipelineEnquiries,
      // selectedPipeline,
      // selectedUser,
      // clientLostApproval,
      // managerLostApproval,
      dateRange,
      applydateRange,
      loader,
      // allActivities,
      // testdrives,
      // tradeins,
      // contacts,
      // dealersettings,
      // pipeline,
      staticDashboard, porscheDashboard
    } = this.props;

    return <ResponsiveReactGridLayout
      {...this.props}
      layouts={staticDashboard ? this.state.layouts : this.filterLayouts(this.state.layouts)}
      onBreakpointChange={this.onBreakpointChange}
      onLayoutChange={this.onLayoutChange}
      measureBeforeMount={false}
      useCSSTransforms={this.state.mounted}
      compactType={this.state.compactType}
      preventCollision={!this.state.compactType}
      margin={[15, 15]}
      isDraggable={!staticDashboard}
      isResizable={!staticDashboard}
      draggableHandle='.draggable-section'
      draggableCancel='.dashboard-box-body'
    >
      <div key={'LeadsBucket'} id={'LeadsBucket'}>
        <LeadsBucket {...this.props}
          handleShowEnquiryList={handleShowEnquiryList}
          clientLostApproval={false}
          managerLostApproval={false}
          dateRange={dateRange}
          applydateRange={applydateRange}
          height={this.calculateHeight('LeadsBucket', this.props.initialLayout)}
          reloadData={this.props.reloadData}
          ownerDashboard={false}
          removeWidget={() => this.props.removeWidget('LeadsBucket')}
          refreshData={this.props.refreshData}
          hideRemoveBtn={staticDashboard}
        />
      </div>

      <div key={'LeadsByLabels'} id={'LeadsByLabels'}>
        <EnquiryLabelCounts
          {...this.props}
          handleShowEnquiryList={handleShowEnquiryList}
          loader={loader}
          dateRange={dateRange}
          height={this.calculateHeight('LeadsByLabels', this.props.initialLayout)}
          minHeight={'350px'}
          title={porscheDashboard ? _.find(widgetSettingsNames, { value: 'LeadsByLabels' })?.name : ''}
        />
      </div>

      {porscheDashboard ? (<div key={'LeadsByOrigin'} id={'LeadsByOrigin'}>
        <DynamicPieChart
          {...this.props}
          handleShowEnquiryList={handleShowEnquiryList}
          loader={loader}
          dateRange={dateRange}
          height={this.calculateHeight('LeadsByOrigin', this.props.initialLayout)}
          id={'LeadsByOrigin'}
          kpi={'origins'}
          fieldName={'orgv'}
          fieldFullForm={'origin'}
          filterType={'enquiry'}
          isPorsche={null} // true, if filtertype contains 'lead
          title={_.find(widgetSettingsNames, { value: 'LeadsByOrigin' })?.name}
        />
      </div>) : (<div key={'LeadsByOrigin'} id={'LeadsByOrigin'}>
        <OriginCounts
          {...this.props}
          handleShowEnquiryList={handleShowEnquiryList}
          loader={loader}
          dateRange={dateRange}
          height={this.calculateHeight('LeadsByOrigin', this.props.initialLayout)}
          title={porscheDashboard ? _.find(widgetSettingsNames, { value: 'LeadsByOrigin' })?.name : ''}
        />
      </div>)}


      {porscheDashboard ? (<div key={'LeadsByEnquiryType'} id={'LeadsByEnquiryType'}>
        <DynamicPieChart
          {...this.props}
          handleShowEnquiryList={handleShowEnquiryList}
          loader={loader}
          dateRange={dateRange}
          height={this.calculateHeight('LeadsByEnquiryType', this.props.initialLayout)}
          id={'LeadsByEnquiryType'}
          kpi={'enquiryTypes'}
          fieldName={'etv'}
          fieldFullForm={'enquiryType'}
          filterType={'enquiry'}
          isPorsche={null} // true, if filtertype contains 'lead
          title={_.find(widgetSettingsNames, { value: 'LeadsByEnquiryType' })?.name}
        />
      </div>) : (<div key={'LeadsByEnquiryType'} id={'LeadsByEnquiryType'}>
        <EnquiryTypeCounts
          {...this.props}
          handleShowEnquiryList={handleShowEnquiryList}
          loader={loader}
          dateRange={dateRange}
          height={this.calculateHeight('LeadsByEnquiryType', this.props.initialLayout)}
          title={porscheDashboard ? _.find(widgetSettingsNames, { value: 'LeadsByEnquiryType' })?.name : ''}
        />
      </div>)}


      {porscheDashboard ? (<div key={'LeadsByCampaign'} id={'LeadsByCampaign'}>
        <DynamicPieChart
          {...this.props}
          handleShowEnquiryList={handleShowEnquiryList}
          loader={loader}
          dateRange={dateRange}
          height={this.calculateHeight('LeadsByCampaign', this.props.initialLayout)}
          id={'LeadsByCampaign'}
          kpi={'campaigns'}
          fieldName={'cpgv'}
          fieldFullForm={'campaign'}
          filterType={'enquiry'}
          isPorsche={null} // true, if filtertype contains 'lead
          title={_.find(widgetSettingsNames, { value: 'LeadsByCampaign' })?.name}
        />
      </div>) : (<div key={'LeadsByCampaign'} id={'LeadsByCampaign'}>
        <CampaignCounts
          {...this.props}
          handleShowEnquiryList={handleShowEnquiryList}
          dateRange={dateRange}
          height={this.calculateHeight('LeadsByCampaign', this.props.initialLayout)}
          title={porscheDashboard ? _.find(widgetSettingsNames, { value: 'LeadsByCampaign' })?.name : ''}
        />
      </div>)}


      <div key={'LeadsByStatus'} id={'LeadsByStatus'}>
        <StatusCounts
          {...this.props}
          handleShowEnquiryList={handleShowEnquiryList}
          dateRange={dateRange}
          applydateRange={applydateRange}
          height={this.calculateHeight('LeadsByStatus', this.props.initialLayout)}
          title={porscheDashboard ? _.find(widgetSettingsNames, { value: 'LeadsByStatus' })?.name : ''}
        />
      </div>
      {porscheDashboard ? (<div key={'LostLeadsReasons'} id={'LostLeadsReasons'}>
        <LostLeadsReasons
          {...this.props}
          handleShowEnquiryList={handleShowEnquiryList}
          loader={loader}
          dateRange={dateRange}
          height={this.calculateHeight('LostLeadsReasons', this.props.initialLayout)}
          //reloadData={this.props.reloadData}
          //ownerDashboard={ownerDashboard}
          //removeWidget={() => this.props.removeWidget('LostLeadsReasons')}
          //hideRemoveBtn={staticDashboard}
          id={`LostLeadsReasons`}
          isPorsche={true}
          title={_.find(widgetSettingsNames, { value: 'LostLeadsReasons' })?.name}
        />
      </div>) : (<Fragment key={'LostLeadsReasons'}></Fragment>)}

      {porscheDashboard ? (<div key={'LostEnquiryReasons'} id={'LostEnquiryReasons'}>
        <LostLeadsReasons
          {...this.props}
          handleShowEnquiryList={handleShowEnquiryList}
          loader={loader}
          dateRange={dateRange}
          height={this.calculateHeight('LostEnquiryReasons', this.props.initialLayout)}
          // reloadData={this.props.reloadData}
          // ownerDashboard={ownerDashboard}
          // removeWidget={() => this.props.removeWidget('LostEnquiryReasons')}
          // hideRemoveBtn={staticDashboard}
          id={`LostEnquiryReasons`}
          title={_.find(widgetSettingsNames, { value: 'LostEnquiryReasons' })?.name}
        />
      </div>) : (<Fragment key={'LostEnquiryReasons'}></Fragment>)}

      {
        porscheDashboard && dynamicBarChartWidgets.map((wid) => {
          return <div key={wid} id={wid}>
            <LeadsCreated
              {...this.props}
              handleShowEnquiryList={handleShowEnquiryList}
              loader={loader}
              dateRange={dateRange}
              height={this.calculateHeight(wid, this.props.initialLayout)}
              id={wid}
              title={_.find(widgetSettingsNames, { value: wid })?.name}
            />
          </div>
        })
      }

      {
        porscheDashboard && dynamicPieChartWidgets.map((widget) => {
          return <div key={widget.id} id={widget.id}>
            <DynamicPieChart
              {...this.props}
              handleShowEnquiryList={handleShowEnquiryList}
              loader={loader}
              dateRange={dateRange}
              height={this.calculateHeight(widget.id, this.props.initialLayout)}
              id={widget.id}
              kpi={widget.kpi}
              fieldName={widget.fieldName}
              fieldFullForm={widget.fieldFullForm}
              filterType={widget.filterType}
              isPorsche={widget.isPorsche} // true, if filtertype contains 'lead
              title={_.find(widgetSettingsNames, { value: widget.id })?.name}
            />
          </div>

        })
      }





      {
        this.props.isMclaren &&
          this.props.pipeline.filter(a => a.value !== 'LeadsBucket').length ? (
          this.props.pipeline
            .filter(a => a.value !== 'LeadsBucket')
            .map(a => (
              <div key={`SalesFunnel-${a.name}`} id={`SalesFunnel-${a.name}`}>
                <SalesFunnel
                  {...this.props}
                  handleShowEnquiryList={handleShowEnquiryList}

                  selectedPipeline={{
                    value: a.value,
                    label: a.name,
                  }}

                  loader={loader}
                  dateRange={dateRange}
                  height={this.calculateHeight(
                    'SalesFunnel',
                    this.props.initialLayout,
                  )}

                  enquiryStatus={this.props.enquiryStatus}
                  isMclaren={this.props.isMclaren}
                  id={`SalesFunnel-${a.value}`}
                />
              </div>
            ))
        ) : (
          <div key={'SalesFunnel'} id={'SalesFunnel'}>
            <SalesFunnel
              {...this.props}
              handleShowEnquiryList={handleShowEnquiryList}



              loader={loader}
              dateRange={dateRange}
              height={this.calculateHeight(
                'SalesFunnel',
                this.props.initialLayout,
              )}

              enquiryStatus={this.props.enquiryStatus}
              isMclaren={false}
              id={`SalesFunnel`}
            />
          </div>
        )
      }

      {
        this.props.isMclaren &&
          this.props.pipeline.filter(a => a.value !== 'LeadsBucket').length ? (
          this.props.pipeline
            .filter(a => a.value !== 'LeadsBucket')
            .map((a, i) =>
            (
              <div key={`SalesFunnel-${a.name}-OVERALL`} id={`SalesFunnel-${a.name}-OVERALL`}>
                <SalesFunnel
                  {...this.props}
                  handleShowEnquiryList={handleShowEnquiryList}

                  selectedPipeline={{
                    value: a.value,
                    label: a.name,
                  }}

                  loader={loader}
                  dateRange={{
                    startDate: window.firebase.firestore.Timestamp.fromDate(moment('2017-01-01').startOf('year')._d),
                    endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('year')._d)
                  }}
                  height={this.calculateHeight(
                    'SalesFunnel',
                    this.props.initialLayout,
                  )}

                  enquiryStatus={this.props.enquiryStatus}
                  isMclaren={this.props.isMclaren}
                  isYTD={true}
                  id={`SalesFunnel-${a.value}-OVERALL`}
                />
              </div>
            ))
        ) : (
          <Fragment key={`SalesFunnel-${'pipeline'}-OVERALL`}></Fragment>
        )
      }
      <div key={'DealerTarget'} id={'DealerTarget'}>
        <DealerTarget
          {...this.props}
          loader={loader}
          dateRange={dateRange}
          applydateRange={applydateRange}
          height={this.calculateHeight('DealerTarget', this.props.initialLayout)}
        />
      </div>
    </ResponsiveReactGridLayout >
  }
}

DragDropLayout.defaultProps = {
  className: "layout",
  rowHeight: 30,
  onLayoutChange: function () { },
  cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }
};

