/** LIBRARIES */
import React, {
	useState,
	useEffect,
	useReducer,
	useCallback,
	useRef,
	useMemo,
	useContext
} from 'react';
import { Tab, Nav } from 'react-bootstrap';
import toast from 'toasted-notes';
import Swal from 'sweetalert2';
import moment from 'moment';
import _ from 'lodash';
import CustomerEditLog from './editLogs';
import CustomerlogDetails from './customerlogDetails';
import CommonHelper from '../../../services/common'
import { ContentOverlay, PopUpModal } from '../../../components';
import { objActivitylog, enquiryActivities } from '../viewModel';
import { default as _images } from '../../../images';
import Translate from '../../../constants/translate';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import EmailPreview from "../../mail/emailPreview";
import ComposeEmail from '../../mail/composeEmail'
import { MainContext } from '../../layout/provider';
import { objOfferNotesVM } from '../../tradeinplus/viewModel';
import { firestoreDB } from '../../../services/helper';
import TemplatePreview from '../../settings/workflow/triggers/previewTemplate'
import { enqUnActionStatus } from '../../../services/enum';
import ImpelLogs from '../details/impelLogs';
//import CustomerlogReducer from './customerlogReducer';

const CustomerlogsReducer = (state, action) => {
	function cancelEditable() {
		return state.map((item, index) => {
			if (item.documentID === action.data.documentID) {
				item.isEditable = false;
				item.notes = action.data.oldnotes
				item.callDuration = action.data.oldcallDuration
				item.callType = action.data.callType
				item.callStatus = action.data.oldcallStatus
				item.addedDate = action.data.oldaddedDate
				return item;
			}
			return item;
		});
	}
	function updatechangelogs() {
		return state.map((item, index) => {
			if (item.documentID === action.id) {
				item[action.field] = action.value;
				return item;
			}
			return item;
		});
	}

	function updateCustomerlog() {
		return state.map((item, index) => {
			if (item.documentID === action.data.documentID) {
				item.isEditable = false;
				item.isNewlog = false;
				for (let [key, value] of Object.entries(action.data)) {
					item[key] = value;
				}
				return item;
			}
			return item;
		});
	}
	function editCustomerlog() {
		return state.map((item, index) => {
			if (item.documentID === action.data.documentID) {
				item.isEditable = true;
				item.oldnotes = item.notes
				item.oldcallDuration = item.callDuration
				item.oldcallStatus = item.callStatus
				item.oldcallType = item.callType
				item.oldaddedDate = item.addedDate
				return item;
			}
			return item;
		});
	}
	switch (action.type) {
		case "SUCCESS": {
			return action.data;
		}
		case "ADDNEWLOG": {
			return [action.data, ...state];
		}
		case "REMOVELOG": {
			return state.filter(item => item.documentID !== action.data.documentID);
		}
		case "CANCELLOG": {
			return cancelEditable(action.data);
		}
		case "CHANGELOG": {
			return updatechangelogs();
		}
		case "SAVELOG": {
			return [...state, action.data];
		}
		case "EDITLOG": {
			return editCustomerlog();
		}
		case "UPDATELOG": {
			return updateCustomerlog();
		}
		default:
			return state;
	}
};

function RealatedTab(props) {
	//const[ customerlogs,setCustomerlog ] = useState([])
	const [customerlogs, dispatch] = useReducer(CustomerlogsReducer, []);
	const [loading, setLoading] = useState(false);
	//const [activeKey, setActiveKey] = useState()
	const textareaRef = useRef(null);
	const [logCounter, setLogCounter] = useState({
		note: 0,
		message: 0,
		call: 0,
		email: 0,
	});
	const [activeTab, setActivetab] = useState('note');
	const [activeLogTab, setActiveLogTab] = useState('Notes');
	const [checkDataLoad, setDataload] = useState({
		Notes: true,
		Message: true,
		Call: true,
		Email: true,
	});
	const [pagingLoader, setPagingloader] = useState(false);
	const [customerlogLoader, setCustomerlogLoader] = useState(true);
	const customerlogTags = ['Notes', 'Message', 'Call', 'Email', 'EDMs'];
	const [newCustomerlog, setNewCustomerlog] = useState(null);
	const [hasMore, setHasMoreData] = useState([]);
	const pageLimit = 6;
	const [showCustomerlog, setShowCustomerlog] = useState(false);
	const [showPreviewModel, setPreviewModel] = useState(false)
	const [emailLog, setEmaillog] = useState(null)
	const [popheader, setPopheader] = useState()
	const [showComposeModal, setShowComposeModal] = useState({
		show: false,
		type: '',
		title: '',
		emailData: null,
	});
	const [sendLoader, setSendLoader] = useState(false);
	const { emailTemplates } = useContext(MainContext);
	const [tempData, setTempData] = useState([])

	const [showEDMsPreviewModel, setEDMsPreviewModel] = useState({
		show: false,
		title: '',
		template: null,
	});

	const _messagemedia = Object.assign({}, props.dealersettings &&
		props.dealersettings.client &&
		props.dealersettings.client.integrations &&
		props.dealersettings.client.integrations.filter(e => e.type === "messagemedia")[0]);

	const _smshub = Object.assign({}, props.dealersettings &&
		props.dealersettings.client &&
		props.dealersettings.client.integrations &&
		props.dealersettings.client.integrations.filter(e => e.type === "smshub")[0]);

	const _podium = Object.assign({}, props.dealersettings &&
		props.dealersettings.client &&
		props.dealersettings.client.integrations &&
		props.dealersettings.client.integrations.filter(e => e.type === "podium")[0]);

	const _cmdotcom = Object.assign({}, props.dealersettings &&
		props.dealersettings.client &&
		props.dealersettings.client.integrations &&
		props.dealersettings.client.integrations.filter(e => e.type === "cmdotcom")[0]);

	const _ringcentral = Object.assign({}, props.dealersettings &&
		props.dealersettings.client &&
		props.dealersettings.client.integrations &&
		props.dealersettings.client.integrations.filter(e => e.type === "ringcentral")[0]);

	const aircallEnabled = ((!_.isEmpty(props.dealersettings) &&
		!_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.integrations) &&
		props.dealersettings.client.integrations.filter(e => e.type === "aircall")[0] &&
		props.dealersettings.client.integrations.filter(e => e.type === "aircall")[0].active === true &&
		props.dealersettings.client.integrations.filter(e => e.type === "aircall")[0].enabled === true
	) ? true : false);

	const justcallEnabled = ((!_.isEmpty(props.dealersettings) &&
		!_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.integrations) &&
		props.dealersettings.client.integrations.filter(e => e.type === "justcall")[0] &&
		props.dealersettings.client.integrations.filter(e => e.type === "justcall")[0].active === true &&
		props.dealersettings.client.integrations.filter(e => e.type === "justcall")[0].enabled === true
	) ? true : false);

	const tssEnabled = ((!_.isEmpty(props.dealersettings) &&
		!_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.integrations) &&
		props.dealersettings.client.integrations.filter(e => e.type === "tss")[0] &&
		props.dealersettings.client.integrations.filter(e => e.type === "tss")[0].active === true &&
		props.dealersettings.client.integrations.filter(e => e.type === "tss")[0].enabled === true
	) ? true : false);

	const impelEnabled = ((!_.isEmpty(props.dealersettings) &&
		!_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.integrations) &&
		props.dealersettings.client.integrations.filter(e => e.type === "impel")[0] &&
		props.dealersettings.client.integrations.filter(e => e.type === "impel")[0].active === true &&
		props.dealersettings.client.integrations.filter(e => e.type === "impel")[0].enabled === true
	) ? true : false);

	const enableTwoWaySMS = ((!_.isEmpty(props.dealersettings) &&
		!_.isEmpty(props.dealersettings.client) &&
		!_.isEmpty(props.dealersettings.client.clientSettings) &&
		props.dealersettings.client.clientSettings.enableTwoWaySMS) ? true : false);

	const ringcentralEnabled = enableTwoWaySMS && _ringcentral && _ringcentral.active && _ringcentral.enabled ? true : false;
	const messageMediaEnabled = enableTwoWaySMS && _messagemedia && _messagemedia.active && _messagemedia.enabled ? true : false;
	const smsHubEnabled = _smshub && _smshub.active && _smshub.enabled ? true : false;
	const podiumEnabled = _podium && _podium.active && _podium.enabled ? true : false;
	const cmdotcomEnabled = _cmdotcom && _cmdotcom.active && _cmdotcom.enabled ? true : false;

	const [sendLoading, setSendLoading] = useState(false);

	let _enquirylogscompleted = impelEnabled && props.enquiry?.inboundLeadID ? _.uniq(_.union(customerlogTags, ["impel"])) : customerlogTags

	useEffect(() => {
		if (props.enquiry)
			setLogCounter(props.enquiry.counts);

		if (props.tradeinPro)
			setLogCounter(props.tradeinPro.counts);

		if (props.service)
			setLogCounter(props.service.counts);
	}, [props]);

	useEffect(() => {
		let newlogVM = Object.assign({}, objActivitylog);
		newlogVM.type = CommonHelper.getActivityType('log', props.originType);
		newlogVM.subType = activeTab;
		newlogVM.owner = localStorage.uid;
		newlogVM.addedBy = localStorage.uid;
		newlogVM.modifiedBy = localStorage.uid;
		newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
		newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();

		if (!_.isEmpty(props.enquiry)) {
            newlogVM.enquiryID = props.enquiry.documentID;
            newlogVM.enquiry = CommonHelper.getMinifiedData(props.enquiry, 'enquiry');
            if (!_.isEmpty(props.enquiry.contact)) {
                newlogVM.contactID = props.enquiry.contact.documentID;
                newlogVM.contact = CommonHelper.getMinifiedData(props.enquiry.contact, 'contact');
            }
        }
        else if (!_.isEmpty(props.tradeinPro)) {
            newlogVM.tradeinProID = props.tradeinPro.documentID;
            newlogVM.tradeinPro = CommonHelper.getMinifiedData(props.tradeinPro, 'tradeinPro');
            if (!_.isEmpty(props.tradeinPro.contact)) {
                newlogVM.contactID = props.tradeinPro.contact.documentID;
                newlogVM.contact = CommonHelper.getMinifiedData(props.tradeinPro.contact, 'contact');
            }
        }
        else if (!_.isEmpty(props.eventsPro)) {
            newlogVM.eventsProID = props.eventsPro.documentID;
            newlogVM.eventsPro = CommonHelper.getMinifiedData(props.eventsPro, 'eventsPro');
            if (!_.isEmpty(props.eventsPro.contact)) {
                newlogVM.contactID = props.eventsPro.contact.documentID;
                newlogVM.contact = CommonHelper.getMinifiedData(props.eventsPro.contact, 'contact');
            }
        }
        else if (!_.isEmpty(props.service)) {
            newlogVM.serviceID = props.service.documentID;
            newlogVM.service = CommonHelper.getMinifiedData(props.service, 'service');
            if (!_.isEmpty(props.service.contact)) {
                newlogVM.contactID = props.service.contact.documentID;
                newlogVM.contact = CommonHelper.getMinifiedData(props.service.contact, 'contact');
            }
        }
        else if (!_.isEmpty(props.contact)) {
            newlogVM.contactID = props.contact.documentID;
            newlogVM.contact = CommonHelper.getMinifiedData(props.contact, 'contact');
        }

		const { dealersettings, clientModule, pipelineView } = props;
		newlogVM = CommonHelper.setLevelIDs(newlogVM, dealersettings, pipelineView, (!_.isEmpty(props.enquiry)
			? props.enquiry.clientID
			: !_.isEmpty(props.tradeinPro)
				? props.tradeinPro.clientID
				: !_.isEmpty(props.service)
					? props.service.clientID
					: null));

		newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities')
			.doc().id;
		newlogVM.callDuration = 0;
		newlogVM.isDone = true;
		setNewCustomerlog(newlogVM);
	}, [activeTab, showCustomerlog]);


	useEffect(() => {
		if (_.isEmpty(props.enquiry) && _.isEmpty(props.tradeinPro) && _.isEmpty(props.service))
			return;

		let refEnquiryLogs = firestoreDB(props.dealersettings).firestore().collection('activities')
			//.where('enquiryID', '==', props.enquiry.documentID)
			.where('type', '==', CommonHelper.getActivityType('log', props.originType))
			.where('isDone', '==', true)
			.where('isDeleted', '==', false);

		if (props.tradeinPro)
			refEnquiryLogs = refEnquiryLogs.where('tradeinProID', '==', props.tradeinPro.documentID)
		else if (props.service)
			refEnquiryLogs = refEnquiryLogs.where('serviceID', '==', props.service.documentID)
		else
			refEnquiryLogs = refEnquiryLogs.where('enquiryID', '==', props.enquiry.documentID)


		let linkEmailsData = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails')
		if (props.contact)
			linkEmailsData = linkEmailsData.where('contactID', '==', props.contact.documentID)
		else if (props.tradeinPro)
			linkEmailsData = linkEmailsData.where('tradeinProID', '==', props.tradeinPro.documentID)
		else if (props.service)
			linkEmailsData = linkEmailsData.where('serviceID', '==', props.service.documentID)
		else {
			linkEmailsData = linkEmailsData.where('enquiryID', '==', props.enquiry.documentID)

			if (props.originType)
				linkEmailsData = linkEmailsData.where('type', '==', CommonHelper.getActivityType('log', props.originType))
		}

		let edmsData = firestoreDB(props.dealersettings).firestore().collection('triggersLog')
			.where('isDeleted', '==', false)
		if (props.contact)
			edmsData = edmsData.where('contactID', '==', props.contact.documentID)
		else if (props.tradeinPro)
			edmsData = edmsData.where('tradeinProID', '==', props.tradeinPro.documentID)
		else if (props.service)
			edmsData = edmsData.where('serviceID', '==', props.service.documentID)
		else
			edmsData = edmsData.where('enquiryID', '==', props.enquiry.documentID)

		let oemNotes = ''
		if (props.enquiry && localStorage.defaultModule === 'oem')
			oemNotes = firestoreDB(props.dealersettings).firestore().collection('oemNotes')
				.where('isDeleted', '==', false)
				.where('enquiryID', '==', props.enquiry.documentID)

		let tipNotes = ''
		if (props.tradeinPro)
			tipNotes = firestoreDB(props.dealersettings).firestore().collection(`tradeinPro/${props.tradeinPro.documentID}/offerNotes`)

		let serviceNotes = ''
		if (props.service)
			serviceNotes = firestoreDB(props.dealersettings).firestore().collection(`serviceJobs/${props.service.documentID}/notes`)

		let aircallContactsLogs = ''
		let linkContactEmailsData = ''
		if (aircallEnabled || justcallEnabled || tssEnabled) {
			let contactID = null;
			if (props.contact)
				contactID = props.contact.documentID;
			else if (props?.tradeinPro?.contact?.documentID)
				contactID = props?.tradeinPro?.contact?.documentID;
			else if (props?.service?.contact?.documentID)
				contactID = props?.service?.contact?.documentID;
			else if (props?.enquiry?.contact?.documentID)
				contactID = props?.enquiry?.contact?.documentID;
			if (!_.isEmpty(contactID)) {
				aircallContactsLogs = firestoreDB(props.dealersettings).firestore().collection('activities')
					.where('isDeleted', '==', false)
					//.where('type', 'in', ['log', 'servicelog', 'tradeinProlog', 'financelog'])
					//.where('subType', '==', 'call')
					.where('tags', 'array-contains-any', ['aircall', 'justcall', 'tss'])
					.where('contactID', '==', contactID)

				if (_.includes(['tradeinPro', 'service'], localStorage.defaultModule)) {
					aircallContactsLogs = aircallContactsLogs.where('type', '==', `${localStorage.defaultModule}log`)
				} else {
					aircallContactsLogs = aircallContactsLogs.where('type', '==', `log`)
				}

				linkContactEmailsData = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails')
					.where('contactID', '==', contactID)
			}
		}

		let refEnquiryActivities = firestoreDB(props.dealersettings).firestore().collection('activities')
			.where('type', '==', CommonHelper.getActivityType('activity', props.originType))
			//.where('isDone', '==', true)
			.where('isDeleted', '==', false);

		if (props.tradeinPro)
			refEnquiryActivities = refEnquiryActivities.where('tradeinProID', '==', props.tradeinPro.documentID)
		else if (props.service)
			refEnquiryActivities = refEnquiryActivities.where('serviceID', '==', props.service.documentID)
		else
			refEnquiryActivities = refEnquiryActivities.where('enquiryID', '==', props.enquiry.documentID)

		//events pro EDMS
		let eventsProEDMs = firestoreDB(props.dealersettings).firestore().collection('triggersLog')
			.where('isDeleted', '==', false)
		if (props?.enquiry?.eventsProAttendeeID) {
			eventsProEDMs = eventsProEDMs.where('eventsProAttendeeID', '==', props.enquiry.eventsProAttendeeID)
		}
		else
			eventsProEDMs = ''

		//inbound lead EDMS
		let inboundLeadEDMs = firestoreDB(props.dealersettings).firestore().collection('triggersLog')
			.where('isDeleted', '==', false)
		if (props?.enquiry?.inboundLeadID) {
			inboundLeadEDMs = inboundLeadEDMs
				.where('triggerRecordID', '==', props.enquiry.inboundLeadID)
				.where('triggerCategory', '==', "inboundLead")
		} else if (props?.tradeinPro?.inboundLeadID) {
			inboundLeadEDMs = inboundLeadEDMs
				.where('triggerRecordID', '==', props.tradeinPro.inboundLeadID)
				.where('triggerCategory', '==', "tradeinProInbound")
		}
		else
			inboundLeadEDMs = ''

		var activitiesCollection = combineLatest(
			collection(refEnquiryLogs),
			collection(linkEmailsData),
			collection(edmsData),
			oemNotes ? collection(oemNotes) : of([]),
			tipNotes ? collection(tipNotes) : of([]),
			serviceNotes ? collection(serviceNotes) : of([]),
			aircallContactsLogs ? collection(aircallContactsLogs) : of([]),
			collection(refEnquiryActivities),
			eventsProEDMs ? collection(eventsProEDMs) : of([]),
			inboundLeadEDMs ? collection(inboundLeadEDMs) : of([]),
			linkContactEmailsData ? collection(linkContactEmailsData) : of([])
		).pipe(
			map(([activities, emails, edms, oemnotes, tipNotes, serviceNotes, aircallcontactsLogs, activtyNotes, eventsproedms, inboundLeadEDMs, contactEmails]) => {
				return [
					_.map(activities, function (obj) { return { ...obj.data(), documentID: obj.id, startDate: _.isEmpty(obj.data().startDate) ? obj.data().addedDate : obj.data().startDate, owner: (!_.isEmpty(obj.data().owner) ? obj.data().owner : obj.data().addedBy) } }),
					[
						..._.map(emails, function (obj) {
							let _emailData = obj.data();
							return {
								..._emailData,
								documentID: obj.id,
								type: _emailData.type ? _emailData.type : CommonHelper.getActivityType('log', props.originType),
								subType: 'email', isDone: true, startDate: _emailData.linkedDate,
								addedDate: _emailData.linkedDate,
								owner: _emailData.linkedBy,
								threadID: (_emailData.thread ? _emailData.thread.id : null)
							}
						}),
						..._.map(contactEmails, function (obj) {
							let _emailData = obj.data();
							return {
								..._emailData,
								documentID: obj.id,
								type: _emailData.type ? _emailData.type : CommonHelper.getActivityType('log', props.originType),
								subType: 'email', isDone: true, startDate: _emailData.linkedDate,
								addedDate: _emailData.linkedDate,
								owner: _emailData.linkedBy,
								threadID: (_emailData.thread ? _emailData.thread.id : null)
							}
						})
					],
					[
						..._.map(edms, function (obj) { return { ...obj.data(), documentID: obj.id, iconType: obj.data().subType, subType: 'edms', addedDate: obj.data().startDate, collection: 'triggersLog' } }),
						..._.map(eventsproedms, function (obj) { return { ...obj.data(), documentID: obj.id, iconType: obj.data().subType, subType: 'edms', addedDate: obj.data().startDate, collection: 'triggersLog' } }),
						..._.map(inboundLeadEDMs, function (obj) { return { ...obj.data(), documentID: obj.id, iconType: obj.data().subType, subType: 'edms', addedDate: obj.data().startDate, collection: 'triggersLog' } })
					],
					_.map(oemnotes, function (obj) { return { ...obj.data(), documentID: obj.id, startDate: (_.isEmpty(obj.data().startDate) ? obj.data().addedDate : obj.data().startDate), owner: (!_.isEmpty(obj.data().owner) ? obj.data().owner : obj.data().addedBy) } }),
					_.map(tipNotes, function (obj) {
						return {
							...obj.data(),
							documentID: obj.id,
							iconType: 'note',
							type: CommonHelper.getActivityType('log', props.originType),
							subType: 'note',
							originType: 'offerNotes',
							isDone: true,
							startDate: obj.data().addedDate ? obj.data().addedDate : null,
							owner: obj.data().addedBy,
							modifiedBy: obj.data().addedBy,
						}
					}),
					_.map(serviceNotes, function (obj) {
						return {
							...obj.data(),
							addedFrom: null,
							documentID: obj.id,
							iconType: 'note',
							type: 'servicelog',
							subType: 'note',
							originType: 'serviceNotes',
							isDone: true,
							startDate: obj.data().addedDate ? obj.data().addedDate : null,
							owner: obj.data().addedBy,
							modifiedBy: obj.data().addedBy,
							notes: obj.data().note
						}
					}),
					_.map(aircallcontactsLogs, function (obj) { return { ...obj.data(), documentID: obj.id, startDate: (_.isEmpty(obj.data().startDate) ? obj.data().addedDate : obj.data().startDate), owner: (!_.isEmpty(obj.data().owner) ? obj.data().owner : obj.data().addedBy) } }),
					_.map(activtyNotes, function (obj) { return { ...obj.data(), subType: 'allnotes', documentID: obj.id, startDate: _.isEmpty(obj.data().startDate) ? obj.data().addedDate : obj.data().startDate, owner: (!_.isEmpty(obj.data().owner) ? obj.data().owner : obj.data().addedBy) } }),
				]
			}))
			.subscribe(async ([activities, emails, edms, oemnotes, tipNotes, serviceNotes, aircallcontactsLogs, activtyNotes]) => {
				const offerNotes = [];
				tipNotes && tipNotes.forEach((doc) => {
					const _notes = Object.assign({}, objOfferNotesVM);
					for (let [key, value] of Object.entries(doc)) {
						_notes[key] = value;
					}
					if (!_notes.isDeleted && ((_notes.isInternalNotes === true && _notes.wholeSalerNotes === false) || // isDealerInternalNotes 
						(_notes.isInternalNotes === false && _notes.wholeSalerNotes === false) || // isDealerPublicNotes 
						(_notes.isInternalNotes === false && _notes.wholeSalerNotes === true) // isWholesalerPublicNotes 
					)) offerNotes.push(_notes);
				});
				let allActivities = _.uniqBy(activities.concat(aircallcontactsLogs).concat(activtyNotes.filter(a => !_.isEmpty(a.notes))), 'documentID');
				let _emails = _.uniqBy(emails, 'documentID').filter(e => e.type === CommonHelper.getActivityType('log', props.originType))
				if (_emails.length === 0) {
					setCustomerlogLoader(false)
					dispatch({
						type: "SUCCESS",
						data: allActivities.concat(_emails).concat(oemnotes).concat(_.uniqBy(edms, 'documentID')).concat(offerNotes).concat(serviceNotes)
					});
				}
				else {
					setTempData(allActivities.concat(_emails).concat(oemnotes).concat(_.uniqBy(edms, 'documentID')).concat(offerNotes).concat(serviceNotes))
				}
			});
		return () => {
			activitiesCollection && activitiesCollection.unsubscribe()
		}

	}, [])

	useEffect(() => {
		if (tempData.length === 0)
			return
		async function getTheardEmails(activities) {
			const promises = []
			activities.filter(e => e.type === CommonHelper.getActivityType('log', props.originType) && e.subType === 'email' && !_.isEmpty(e.thread)).forEach(el => {
				promises.push(window.firebase.firestore().doc(`nylas-accounts/${el.account_id ? el.account_id : el.thread.account_id}/nylas-threads/${el.threadID}`).get())
			});
			const snapshots = await Promise.all(promises)
			snapshots.forEach(snap => {
				if (snap.exists) {
					activities = [...activities.filter(e => e.threadID !== snap.id), {
						..._.find(activities, { 'threadID': snap.id }),
						thread: snap.data()
					}]
				}
			})
			setCustomerlogLoader(false)
			dispatch({
				type: "SUCCESS",
				data: activities
			});
		}

		getTheardEmails(tempData);

	}, [tempData])

	// useEffect(() => {
	// 	return () => {
	// 		window.unsubquickviewcustomerlog && window.unsubquickviewcustomerlog();
	// 	};
	// }, []);

	// useEffect(() => {
	// 	if (!checkDataLoad[activeLogTab]) {
	// 		return;
	// 	}
	// 	setPagingloader(true);
	// 	let refCustomerLogData = window.firebase
	// 		.firestore()
	// 		.collection('activities')
	// 		.where('enquiryID', '==', props.enquiry.documentID)
	// 		.where('type', '==', 'log')
	// 		.where('isDone', '==', true)
	// 		.where('isDeleted', '==', false);
	// 	// .where(
	// 	// 	'subType',
	// 	// 	'==',
	// 	// 	enquiryActivities.find(item => item.name === activeLogTab).value,
	// 	// )
	// 	// .orderBy('startDate', 'desc');

	// 	//.limit(pageLimit)
	// 	// let lastRecord = hasMore.filter(e => e.type === activeLogTab);
	// 	// if (lastRecord.length > 0) {
	// 	// 	refCustomerLogData = refCustomerLogData
	// 	// 		.startAfter(
	// 	// 			lastRecord[0].lastDoc
	// 	// 		)
	// 	// 		.limit(pageLimit);
	// 	// } else {
	// 	// 	refCustomerLogData = refCustomerLogData.limit(pageLimit);
	// 	// }

	// 	window.unsubquickviewcustomerlog = refCustomerLogData.onSnapshot(
	// 		onCollectionUpdate,
	// 	);
	// }, [checkDataLoad, activeLogTab]);

	// const onCollectionUpdate = querySnapshot => {
	// 	// let actionType;
	// 	// let snapshotDoc;
	// 	// querySnapshot.docChanges().forEach(change => {
	// 	// 	if (change.oldIndex >= 0) {
	// 	// 		//if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
	// 	// 		snapshotDoc = {
	// 	// 			...change.doc.data(),
	// 	// 			documentID : change.doc.id
	// 	// 		};
	// 	// 		if (change.type === 'added') {
	// 	// 			actionType = 'ADDNEWLOG';
	// 	// 		} else if (change.type === 'modified') {
	// 	// 			actionType = 'UPDATELOG';
	// 	// 		} else if (change.type === 'removed') {
	// 	// 			actionType = 'REMOVELOG';
	// 	// 		}
	// 	// 	}
	// 	// });
	// 	// const _customerlogs = [];
	// 	// if (!actionType) {
	// 	// 	if (
	// 	// 		querySnapshot.docs.length > 0 &&
	// 	// 		querySnapshot.docs.length === pageLimit
	// 	// 	) {
	// 	// 		setHasMoreData([
	// 	// 			...hasMore.filter(e => e.type !== activeLogTab),
	// 	// 			{
	// 	// 				type: activeLogTab,
	// 	// 				lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1],
	// 	// 			},
	// 	// 		]);
	// 	// 	} else {
	// 	// 		setHasMoreData([...hasMore.filter(e => e.type !== activeLogTab)]);
	// 	// 	}
	// 	// 	querySnapshot.forEach(function (doc) {
	// 	// 		const objlogData = Object.assign({}, doc.data());

	// 	// 		objlogData.documentID = doc.id;
	// 	// 		const logVM = Object.assign({}, objActivitylog);
	// 	// 		for (let [key, value] of Object.entries(objlogData)) {
	// 	// 			logVM[key] = value;
	// 	// 		}
	// 	// 		_customerlogs.push(logVM);
	// 	// 	});
	// 	// }
	// 	// dispatch({
	// 	// 	type: actionType ? actionType : 'SUCCESS',
	// 	// 	data: actionType ? snapshotDoc : _customerlogs,
	// 	// 	activeTab: activeLogTab,
	// 	// });
	// 	// setDataload({
	// 	// 	...checkDataLoad,
	// 	// 	[activeLogTab]: false,
	// 	// });
	// 	// setCustomerlogLoader(false);
	// 	// setPagingloader(false);

	// 	const _customerlogs = [];
	// 	querySnapshot.forEach(function (doc) {
	// 		const objlogData = Object.assign({}, doc.data());

	// 		objlogData.documentID = doc.id;
	// 		const logVM = Object.assign({}, objActivitylog);
	// 		for (let [key, value] of Object.entries(objlogData)) {
	// 			logVM[key] = value;
	// 		}
	// 		//_customerlogs.push(logVM)
	// 		_customerlogs.push({ ...logVM, 'startDate': logVM.addedDate })
	// 	});
	// 	setCustomerlogLoader(false)

	// 	dispatch({
	// 		type: "SUCCESS",
	// 		data: _customerlogs
	// 	});
	// };

	// const handleActiveKey = useCallback((index) => {
	//     setActiveKey(index)
	// }, [])

	const logPanelOpen = () => {

		const { enquiry } = props;
		if (props.isValidForActivity && enquiry) {
			if (props.isValidForActivity(enquiry.pipeline, enquiry.stage, 'activity', null, ['stageNotes'])) setShowCustomerlog(true);
		}
		else setShowCustomerlog(true);

	}
	const handlecancellog = useCallback((e, objLog, aTab) => {
		e.preventDefault();

		if (objLog.isEditable) {
			dispatch({
				type: objLog.isNewlog ? "REMOVELOG" : "CANCELLOG",
				data: objLog
			});
		} else {
			setShowCustomerlog(false);
		}
	}, []);

	const handlechangelog = useCallback((e, objLog, aTab) => {
		e.preventDefault && e.preventDefault();
		var domTextref = document.getElementById('notes-tab-' + objLog.documentID);
		if (e.target.name === 'notes' && e.target.value.length === 0) {
			domTextref && domTextref.classList.add('input_error');
		} else {
			domTextref && domTextref.classList.remove('input_error');
		}
		if (objLog.isEditable) {
			dispatch({
				type: "CHANGELOG",
				id: objLog.documentID,
				field: e.target.name,
				value: e.target.value
			});
		} else {
			setNewCustomerlog({
				...objLog,
				[e.target.name]: e.target.value,
			});
		}
	}, []);

	const handleRadioChange = useCallback((e, objLog) => {
		//e.preventDefault && e.preventDefault();
		setNewCustomerlog({
			...objLog,
			[e.target.name]: e.target.checked
		});

	}, [])

	const handleDateChangelog = useCallback((value, name, objLog, aTab) => {
		if (objLog.isEditable) {
			dispatch({
				type: 'CHANGELOG',
				id: objLog.documentID,
				field: name,
				value:
					name === 'callDuration' || name === 'callStatus' || name === 'callType' || name === 'sourceNumber'
						? value
						: window.firebase.firestore.Timestamp.fromDate(moment(value)._d)
			});
		} else {
			setNewCustomerlog({
				...objLog,
				subType: objLog.subType,
				[name]:
					name === 'callDuration' || name === 'callStatus' || name === 'callType' || name === 'sourceNumber'
						? value
						: window.firebase.firestore.Timestamp.fromDate(moment(value)._d),
			});
		}
	}, []);

	const handleFileChange = useCallback((url, name, objLog, aTab) => {
		setNewCustomerlog({
			...objLog,
			subType: objLog.subType,
			['files']: _.isEmpty(url) ? null : [{
				name,
				url
			}],
		});
	}, []);

	const handlesavelog = useCallback((e, objLog, completedTab, mentions, isSend) => {
		e.preventDefault();
		var domTextref = document.getElementById('notes-tab-' + objLog.documentID);
		if (objLog.notes.length === 0 && domTextref && _.isEmpty(objLog?.files)) {
			domTextref.focus();
			domTextref.classList.add('input_error');
			return;
		}

		delete objLog.oldnotes;
		delete objLog.oldcallDuration;
		delete objLog.oldcallStatus;
		delete objLog.oldcallType;
		delete objLog.oldaddedDate;
		objLog.startDate = objLog.addedDate;
		if (objLog.isNewlog) {
			delete objLog.isNewlog;
			objLog.modifiedDate = window.firebase.firestore.Timestamp.now();
		} else if (objLog.isEditable) {
			delete objLog.isEditable;
			objLog.modifiedBy = localStorage.uid;
			objLog.modifiedDate = window.firebase.firestore.Timestamp.now();
		}

		objLog.modifiedByName = props.dealersettings.name;
		objLog.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;

		if (mentions.length > 0) {
			let convertedIDs = []
			let rawIDs = mentions.map(mentionObject => mentionObject.id)
			rawIDs.forEach(id => {
				if (id.includes(',')) {
					let allIDs = id.split(',')
					allIDs.forEach(a => {
						convertedIDs.push(a)
					})
				} else {
					convertedIDs.push(id)
				}
			})

			if (objLog.mentions && objLog.mentions.length > 0) {
				objLog.mentions = _.uniq([...objLog.mentions, ...convertedIDs])
			} else {
				objLog.mentions = _.uniq(convertedIDs)
			}
		}
		if (isSend)
			setSendLoading(true);
		else
			setLoading(true);
		if (objLog.oemNote === true) {
			firestoreDB(props.dealersettings)
				.firestore()
				.doc(`oemNotes/${objLog.documentID}`)
				.set(objLog, { merge: true })
				.then(snapshot => {
					toast.notify('Log updated successfully', {
						duration: 2000,
						position: 'top-right',
					});
					setLoading(false);
					setSendLoading(false);
					setShowCustomerlog(false);
				})
		}
		else {
			firestoreDB(props.dealersettings)
				.firestore()
				.doc(`activities/${objLog.documentID}`)
				.set(objLog, { merge: true })
				.then(snapshot => {
					toast.notify('Log updated successfully', {
						duration: 2000,
						position: 'top-right',
					});
					setLoading(false);
					setSendLoading(false);
					setShowCustomerlog(false);

					//update enquiry modified date if new log is added
					if (!_.isEmpty(objLog.enquiryID)) {
						let _objEq = {
							modifiedBy: localStorage.uid,
							modifiedFrom: 'web',
							modifiedDate: window.firebase.firestore.Timestamp.now(),
						}
						if (objLog.subType !== 'note') _objEq.unactionedNotify = enqUnActionStatus.COMPLETED;
						firestoreDB(props.dealersettings)
							.firestore()
							.doc(`enquiries/${objLog.enquiryID}`)
							.set(_objEq, { merge: true })
					}

					if (objLog.type === CommonHelper.getActivityType('log', props.originType) && objLog.subType === 'message' && (messageMediaEnabled || smsHubEnabled || podiumEnabled || cmdotcomEnabled || ringcentralEnabled || justcallEnabled) && isSend) {
						try {
							handleSendToMessageMedia(objLog)
						}
						catch (error) {
							console.error(error)
						}
					}

					if (document.getElementById('customerlog-' + objLog.documentID)) {
						setTimeout(function () {
							document
								.getElementById('customerlog-' + objLog.documentID)
								.scrollIntoView({
									behavior: 'smooth',
									block: 'center',
								});
						}, 1000);
					}
				})
				.catch(error => {
					console.error(error);
					setLoading(false);
					setSendLoading(false);
					toast.notify('Something went wrong', {
						duration: 2000,
						position: 'top-right',
					});
				});
		}

	}, []);

	const handleSendToMessageMedia = (objLog) => {
		var objData = Object.assign({}, {
			"clientID": objLog.clientID,
			"activityID": objLog.documentID,
			"text": objLog.notes,
			"owner": objLog.owner,
			"projectId": props.dealersettings?.client?.projectId || null

		});

		if (!_.isEmpty(props.contact)) {
			let _contact = Object.assign({}, props.contact);
			objData.phone = _contact.phone ? _contact.phone : '';
			if (podiumEnabled) {
				objData.phone = _contact.phone && _contact.phoneCode ? `${_contact.phoneCode}${_contact.phone}` : _contact.phone ? _contact.phone : '';
				objData.contactName = CommonHelper.displayContactName([], _contact)
				objData.senderName = props?.dealersettings?.name ? props.dealersettings.name : ''
			}
			if (cmdotcomEnabled) {
				objData.phoneCode = _contact.phoneCode ? _contact.phoneCode : '';
			}
		}
		else if (!_.isEmpty(props.enquiry?.contact)) {
			let _contact = Object.assign({}, props.enquiry.contact);
			objData.phone = _contact.phone ? _contact.phone : '';
			if (podiumEnabled) {
				objData.phone = _contact.phone && _contact.phoneCode ? `${_contact.phoneCode}${_contact.phone}` : _contact.phone ? _contact.phone : '';
				objData.contactName = CommonHelper.displayContactName([], _contact)
				objData.senderName = props?.dealersettings?.name ? props.dealersettings.name : ''
			}
			if (cmdotcomEnabled || ringcentralEnabled || justcallEnabled) {
				objData.phoneCode = _contact.phoneCode ? _contact.phoneCode : '';
			}
		}
		else if (!_.isEmpty(props.tradeinPro?.contact) || !_.isEmpty(props.service?.contact)) {
			let _contact = Object.assign({}, props.tradeinPro?.contact ? props.tradeinPro?.contact : props.service.contact);
			objData.phone = _contact.phone ? _contact.phone : '';
			if (podiumEnabled) {
				objData.phone = _contact.phone && _contact.phoneCode ? `${_contact.phoneCode}${_contact.phone}` : _contact.phone ? _contact.phone : '';
				objData.contactName = CommonHelper.displayContactName([], _contact)
				objData.senderName = props?.dealersettings?.name ? props.dealersettings.name : ''
			}
			if (cmdotcomEnabled || ringcentralEnabled || justcallEnabled) {
				objData.phoneCode = _contact.phoneCode ? _contact.phoneCode : '';
			}
		}
		if (objLog?.files?.length > 0) {
			objData.mms = objLog.files[0].url
		}
		if (justcallEnabled) {
			objData.email = props?.dealersettings?.email || null;
		}
		if (props?.dealersettings?.client?.integrations?.filter(e => e.type === "justcall")?.[0]?.settings) {
			objData = {
				...objData,
				...props.dealersettings.client.integrations.filter(e => e.type === "justcall")[0].settings,
				sourceNumber: objLog.sourceNumber || null
			}
		}
		//	console.log('handleSendToMessageMedia-quickview', objData);
		const messagemediasend = window.firebase.functions().httpsCallable(`${messageMediaEnabled ? 'messagemedia' : smsHubEnabled ? 'smshub' : cmdotcomEnabled ? 'cmdotcom' : ringcentralEnabled ? 'ringcentral' : justcallEnabled ? 'justcall' : 'podium'}-send`);
		messagemediasend(objData).then((_data) => {
			//	console.log('messagemediasend-quickview', _data);
		});

	}

	const handleReplylog = useCallback((e, objLog) => {
		e.preventDefault();
		setActivetab('message');//
		//setShowActivity(true);
		setShowCustomerlog(true);
		if (document.getElementById('activity-nav-new')) {
			document.getElementById('activity-nav-new').scrollIntoView(
				{
					behavior: "smooth",
					block: "center"
				});
		}
	});

	const handleEditlog = useCallback((e, objLog, cTab) => {
		e.preventDefault();
		dispatch({
			type: 'EDITLOG',
			data: objLog
		});
	}, []);

	const handleDeletelog = useCallback((e, objLog) => {
		e.preventDefault();
		delete objLog.searchUsers;
		objLog.isDeleted = true;
		objLog.modifiedBy = localStorage.uid;
		objLog.modifiedDate = window.firebase.firestore.Timestamp.now();
		Swal.fire({
			title: CommonHelper.showLocale(props, 'Are you sure?'),
			text: CommonHelper.showLocale(props, 'You want to delete?'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
		}).then(result => {
			if (result.value) {
				setLoading(true);
				firestoreDB(props.dealersettings)
					.firestore()
					.doc(`activities/${objLog.documentID}`)
					.set({
						modifiedDate: window.firebase.firestore.Timestamp.now(),
						modifiedBy: localStorage.uid,
						modifiedFrom: 'web',
						isDeleted: true
					}, { merge: true })
					.then(snapshot => {
						toast.notify('Log deleted successfully', {
							duration: 2000,
							position: 'top-right',
						});
						setLoading(false);

						let logNotes = 'Log deleted';
						if (!_.isEmpty(objLog) && objLog.type)
							logNotes = objLog.type + ': ' + (objLog.subType ? objLog.subType : '') + ' deleted';

						let _objLogData = {
							notes: logNotes,
							type: 'activities',
							subType: 'delete',
							recordId: objLog.documentID,
						}
						_objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
						CommonHelper.saveAuditLog(_objLogData);

					})
					.catch(error => {
						console.error(error);
						setLoading(false);
						toast.notify('Something went wrong', {
							duration: 2000,
							position: 'top-right',
						});
					});
			}
		});
	}, []);

	const updateActivity = useCallback((e, objLog) => {
		firestoreDB(props.dealersettings).firestore().doc(`activities/${objLog.documentID}`).update(objLog, { merge: true }).then(snapshot => {
			toast.notify('Log updated successfully', {
				duration: 2000
			});
		})
	}, [])

	const handleUnlickEmail = useCallback((e, objLog) => {
		Swal.fire({
			title: CommonHelper.showLocale(props, 'Are you sure?'),
			text: CommonHelper.showLocale(props, 'You want to unlink?'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
		}).then((result) => {
			if (result.value) {
				firestoreDB(props.dealersettings).firestore().doc(`enquiryEmails/${objLog.documentID}`).delete()
					.then(snapshot => {
						toast.notify('Email unlink successfully.', {
							duration: 2000
						})
					})
					.catch(error => {
						console.error(error);
						toast.notify('Something went wrong', {
							duration: 2000
						})
					});
			}
		})

	}, [])

	const handlePreviewEmail = useCallback((emailLog, id, token) => {
		setEmaillog({
			...emailLog,
			documentID: id,
			token: token
		})
		setPopheader(emailLog.subject)
		setPreviewModel(true)
	}, [])

	const handleModelClose = () => {
		setEmaillog(null)
		setPreviewModel(false)
	}

	const handleComposeEmail = () => {
		if (_.isEmpty(props.dealersettings.nylas)) {
			Swal.fire({
				title: CommonHelper.showLocale(props, 'Please configure email settings'),
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale(props, 'Add Settings'),
				cancelButtonText: CommonHelper.showLocale(props, 'Later'),
			}).then(result => {
				if (result.value) {
					props.history.push('/settings/profile?key=emailsettings');
				}
			});
		}
		else {
			setShowComposeModal({
				show: true,
				type: 'compose',
				title: 'New Message'
			})
		}
	}

	const handleCloseComposeModal = () => {
		setShowComposeModal({
			show: false,
			type: '',
			title: ''
		})
	}

	const handleSend = async (trigger, emailData, handleClose, replyToAll) => {
		const { mailTo, mailCc, mailBcc, subject, email } = trigger;
		setSendLoader(true);

		if (mailTo === '' && mailCc === '' && mailBcc === '') {
			toast.notify('Please specify at least one recipient.', {
				duration: 2000,
			});
			setSendLoader(false);
			return;
		}

		if (subject === '') {
			toast.notify('Please write subject.', {
				duration: 2000,
			});
			setSendLoader(false);
			return;
		}

		if (email === '' || email === '<p></p>\n') {
			toast.notify('Please write in email body.', {
				duration: 2000,
			});
			setSendLoader(false);
			return;
		}

		try {
			let emailObj = {
				token: props.dealersettings.nylasAccessToken,
				draft: {
					subject: trigger.subject,
					body: trigger.email,
				},
			};

			if (trigger.mailTo !== '') {
				emailObj.draft.to = trigger.mailTo.split(',').map(email => {
					return {
						name: '',
						email: email,
					};
				});
			}

			if (trigger.mailCc !== '') {
				emailObj.draft.cc = trigger.mailCc.split(',').map(email => {
					return {
						name: '',
						email: email,
					};
				});
			}

			if (trigger.mailBcc !== '') {
				emailObj.draft.bcc = trigger.mailBcc.split(',').map(email => {
					return {
						name: '',
						email: email,
					};
				});
			}

			if (trigger.files.length > 0) {
				emailObj.files = trigger.files;
			}

			setTimeout(() => {
				// toast.notify('sending..', {
				// 	duration: 2000,
				// });
				toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> sending...</div>),
					{
						position: 'bottom-left',
						duration: null
					},
				)
				setShowComposeModal({
					...showComposeModal,
					show: false,
					type: '',
					title: '',
					emailData: null,
				});
			}, 300);

			// console.log('emailObj', emailObj);
			const nylasSendEmail = window.firebase
				.functions()
				.httpsCallable('nylas-sendEmail');
			const resp = await nylasSendEmail(emailObj);

			if (resp.data.success) {
				linkEnqruiy(resp.data)
				setSendLoader(false);
			} else {
				setSendLoader(false);
				toast.closeAll();
				if (resp.data && resp.data.data && resp.data.data.statusCode === 401) {
					Swal.fire({
						title: CommonHelper.showLocale(props, `the accounts credentials are out of date`),
						icon: 'info',
						showCancelButton: true,
						confirmButtonText: CommonHelper.showLocale(props, 'Reauthenticate'),
						cancelButtonText: CommonHelper.showLocale(props, 'Later'),
					}).then(result => {
						if (result.value) {
							window.firebase.firestore().doc(`users/${localStorage.uid}`).set({
								nylas: null,
							}, { merge: true });
							props.history.push('/settings/profile?key=emailsettings');
						}
					});
					return
				}
				else {
					Swal.fire('There was an error in sending email.', '', 'error');
				}
			}

			// console.log('response', resp);
		} catch (error) {
			toast.closeAll();
			Swal.fire('There was an error in sending email.', '', 'error');

			setSendLoader(false);
			console.log(error);
		}
	};

	const linkEnqruiy = (data) => {

		var id = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails').doc().id;
		const linkref = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails').doc(id);

		const model = {
			documentID: id,
			clientID: props.dealersettings.client.documentID,
			thread: data.thread,
			token: props.dealersettings.nylasAccessToken,
			linkedBy: localStorage.uid,
			linkedDate: window.firebase.firestore.Timestamp.now(),
			type: CommonHelper.getActivityType('log', props.originType),
			projectId: props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null
		};

		if (!_.isEmpty(props.enquiry)) {
			model.enquiryID = props.enquiry.documentID;
			model.enquiry = props.enquiry;
			if (!_.isEmpty(props.enquiry.contact)) {
				model.contactID = props.enquiry.contact.documentID;
				model.contact = props.enquiry.contact;
			}
		}
		else if (!_.isEmpty(props.tradeinPro)) {
			model.tradeinProID = props.tradeinPro.documentID;
			model.tradeinPro = props.tradeinPro;
			if (!_.isEmpty(props.tradeinPro.contact)) {
				model.contactID = props.tradeinPro.contact.documentID;
				model.contact = props.tradeinPro.contact;
			}
		}
		else if (!_.isEmpty(props.service)) {
			model.serviceID = props.service.documentID;
			model.service = props.service;
			if (!_.isEmpty(props.service.contact)) {
				model.contactID = props.service.contact.documentID;
				model.contact = props.service.contact;
			}
		}
		else if (!_.isEmpty(props.contact)) {
			model.contactID = props.contact.documentID;
			model.contact = props.contact;
		}

		linkref.set(model, {
			merge: true,
		});

		if (!_.isEmpty(model.enquiryID)) {
			firestoreDB(props.dealersettings).firestore().doc(`enquiries/${model.enquiryID}`)
				.set({
					modifiedBy: localStorage.uid,
					modifiedFrom: 'web',
					unactionedNotify: enqUnActionStatus.COMPLETED,
					modifiedDate: window.firebase.firestore.Timestamp.now(),
				}, { merge: true })
		}

		if (!_.isEmpty(data.data)) {
			let messageRef = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails').doc(id).collection('messages').doc(data.data.id)
			messageRef.set({
				...data.data,
				projectId: props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null
			})
		}
		toast.closeAll();
		toast.notify('Email sent successfully.', {
			duration: 2000,
		});
	}

	const handleEDMsPreviewEmail = useCallback((e, objLog) => {
		setEDMsPreviewModel({
			show: true,
			title: objLog.title,
			template: {
				...objLog,
				['bodyHTML']: objLog.email && objLog.email.body ? objLog.email.body : ''
			}
		})
	}, [])

	const handleEDMsPreviewEmailClose = () => {
		setEDMsPreviewModel({
			show: false,
			title: '',
			template: null
		})
	}
	const noteLogs = useMemo(() => customerlogs
		.filter(
			({ subType, notes }) => ((subType === 'note' || subType === 'allnotes' || subType === 'call') && !_.isEmpty(notes))
		)
		.sort(function (x, y) {
			return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
		}), [customerlogs])
	const messageLogs = useMemo(() => customerlogs
		.filter(
			({ subType }) => (subType === 'message')
		)
		.sort(function (x, y) {
			return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
		}), [customerlogs])
	const callLogs = useMemo(() => customerlogs
		.filter(
			({ subType }) => (subType === 'call')
		)
		.sort(function (x, y) {
			return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
		}), [customerlogs])
	const emailLogs = useMemo(() => customerlogs
		.filter(
			({ subType }) => (subType === 'email')
		)
		.sort(function (x, y) {
			return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
		}), [customerlogs])
	const edmsLogs = useMemo(() => customerlogs
		.filter(
			({ subType }) => (subType === 'edms')
		)
		.sort(function (x, y) {
			return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
		}), [customerlogs])
	const allLogs = {
		Notes: noteLogs,
		Message: messageLogs,
		Call: callLogs,
		Email: emailLogs,
		EDMs: edmsLogs
	}

	return (
		<>
			<div className='mid-column-wrap'>
				<div className='activity-nav' id="activity-nav-new">
					<Tab.Container activeKey={activeTab}>
						<Nav as='ul' bsPrefix='ul-nav' onSelect={k => setActivetab(k)}>
							{enquiryActivities
								.filter(item => item.value !== 'activity')
								.map((rec, index) => {
									return (
										<Nav.Item as='li' bsPrefix='li-nav' key={index}>
											<Nav.Link eventKey={rec.value} bsPrefix='li-a-nav'>
												{
													<>
														<i className={rec.ico}></i>
														{/* <Translate text={(rec.value === 'message' && (messageMediaEnabled || smsHubEnabled || podiumEnabled)) ? 'sendMessage' : rec.name} /> */}
														{CommonHelper.showLimitedChar(CommonHelper.showLocale(props, (rec.value === 'message' && (messageMediaEnabled || smsHubEnabled || podiumEnabled || ringcentralEnabled || justcallEnabled)) ? 'sendMessage' : rec.name), 12)}
													</>
												}
											</Nav.Link>
										</Nav.Item>
									);
								})}
						</Nav>
					</Tab.Container>
				</div>
				{showCustomerlog ? (
					activeTab === "call" && aircallEnabled ? (
						<div className="activity-nav-subinput"
							onClick={() => {
								const _contact =
									!_.isEmpty(props.enquiry)
										? props.enquiry.contact
										: !_.isEmpty(props.tradeinPro)
											? props.tradeinPro.contact
											: !_.isEmpty(props.service)
												? props.service.contact
												: !_.isEmpty(props.contact)
													? props.contact
													: null
								if (_contact?.phone) {
									props.sidepanelClose()
									props.dialNumber(CommonHelper.validPhone(_contact?.phone, _contact?.phoneCode), props?.enquiry?.documentID, _contact?.documentID, props.originType === 'finance' ? 'financelog' : null, props?.service?.documentID, props?.tradeinPro?.documentID)
								} else {
									Swal.fire(CommonHelper.showLocale(props, 'Please add phone number to log a call'), '', 'info');
								}
							}}><span className="activity-edit-label"><Translate text={enquiryActivities.filter(e => e.value === activeTab)[0].emptymessage} /></span></div>

					) : activeTab === 'note' ||
						activeTab === 'call' ||
						activeTab === 'message' ? (
						<div className='activity-nav-editmode pl-3 pr-3'>
							<CustomerEditLog
								handlechangelog={handlechangelog}
								handlesavelog={handlesavelog}
								handlecancellog={handlecancellog}
								customerlog={newCustomerlog}
								textareaRef={textareaRef}
								handleDateChangelog={handleDateChangelog}
								loading={loading}
								clientUsers={props.clientUsers}
								groupUsers={props.groupUsers}
								dealersettings={props.dealersettings}
								messageMediaEnabled={messageMediaEnabled}
								ringcentralEnabled={ringcentralEnabled}
								justcallEnabled={justcallEnabled}
								smsHubEnabled={smsHubEnabled}
								cmdotcomEnabled={cmdotcomEnabled}
								podiumEnabled={podiumEnabled}
								sendLoading={sendLoading}
								enquiry={props.enquiry}
								tradeinPro={props.tradeinPro}
								service={props.service}
								contact={
									!_.isEmpty(props.enquiry)
										? props.enquiry.contact
										: !_.isEmpty(props.tradeinPro)
											? props.tradeinPro.contact
											: !_.isEmpty(props.service)
												? props.service.contact
												: null
								}
								handleRadioChange={handleRadioChange}
								originType={props.originType}
								handleFileChange={handleFileChange}
							/>
						</div>
					) : (activeTab === "email") ? (
						<div
							className='related-list-subinput'
							onClick={() => {
								handleComposeEmail()
							}}
						>
							<span className='activity-edit-label'>
								<Translate text={
									enquiryActivities.filter(e => e.value === activeTab)[0]
										.emptymessage
								} />
							</span>
						</div>
					) : (
						<div className='related-list-subinput'>
							<span className='activity-edit-label'>
								<Translate text={
									enquiryActivities.filter(e => e.value === activeTab)[0]
										.emptymessage
								} />
							</span>
						</div>
					)
				) : activeTab === "call" && aircallEnabled ? (
					<div className="activity-nav-subinput"
						onClick={() => {
							const _contact =
								!_.isEmpty(props.enquiry)
									? props.enquiry.contact
									: !_.isEmpty(props.tradeinPro)
										? props.tradeinPro.contact
										: !_.isEmpty(props.service)
											? props.service.contact
											: !_.isEmpty(props.contact)
												? props.contact
												: null
							if (_contact?.phone) {
								props.sidepanelClose()
								props.dialNumber(CommonHelper.validPhone(_contact?.phone, _contact?.phoneCode), props?.enquiry?.documentID, _contact?.documentID, props.originType === 'finance' ? 'financelog' : null, props?.service?.documentID, props?.tradeinPro?.documentID)
							} else {
								Swal.fire(CommonHelper.showLocale(props, 'Please add phone number to log a call'), '', 'info');
							}
						}}><span className="activity-edit-label"><Translate text={enquiryActivities.filter(e => e.value === activeTab)[0].emptymessage} /></span></div>

				) : (
					<div
						className='related-list-subinput'
						onClick={() => {
							logPanelOpen();
						}}
					>
						<span className='activity-edit-label'>
							<Translate text={
								enquiryActivities.filter(e => e.value === activeTab)[0]
									.emptymessage
							} />
						</span>
					</div>
				)}
			</div>
			<div className='quickview-activity-wraper'>
				{customerlogLoader ? (
					<div className='mt-5'>
						<ContentOverlay active={true} />
					</div>
				) : (<>
					<div className='enquiry-flow-nav pl-0'>
						<ul>
							{_enquirylogscompleted.map((rec, index) => {
								return (
									<li key={index}>
										<a
											href='#'
											id={rec}
											className={`nav-activity-tabs nav-li-${rec} ${activeLogTab === rec ? 'active' : ''
												}`}
											onClick={event => {
												if (event.target.id) {
													setActiveLogTab(event.target.id);
												}
												event.preventDefault();
											}}
										>
											<Translate text={rec === 'Notes' ? 'All Notes' : rec === 'impel' ? 'Sales' : rec} />
											{rec === 'impel' && (<img src={_images.a_i} height="18" className="ml-1" />)}
											{
												allLogs[rec] && allLogs[rec].length > 0 ? (
													<span className="count">({allLogs[rec].length})</span>
												) : (<></>)
											}
										</a>
									</li>
								);
							})}
						</ul>
					</div>
					{
						activeLogTab === 'impel'
							?
							<>
								<ImpelLogs {...props} paddingSet={true}></ImpelLogs>
							</>
							:
							<>
								{allLogs[activeLogTab].length > 0 ? (
									<>
										<div className='quickview-activity-item'>
											{allLogs[activeLogTab]
												.sort(function (x, y) {
													return x.startDate.seconds > y.startDate.seconds ? -1 : 1;
												})
												.map((obj, index) => {
													return (
														<CustomerlogDetails
															key={index}
															type={obj.subType}
															activeLogTab={activeLogTab}
															clientUsers={props.clientUsers}
															groupUsers={props.groupUsers}
															customerlog={obj}
															handlecancellog={handlecancellog}
															handlechangelog={handlechangelog}
															handlesavelog={handlesavelog}
															handleEditlog={handleEditlog}
															isNewlog={obj.isNewlog}
															isEditable={obj.isEditable}
															id={obj.documentID}
															notes={obj.notes}
															addedDate={obj.addedDate}
															callDuration={obj.callDuration}
															callStatus={obj.callStatus}
															textareaRef={textareaRef}
															handleDeletelog={handleDeletelog}
															handleDateChangelog={handleDateChangelog}
															loading={loading}
															completedTab={enquiryActivities.find(
																item => item.name === activeLogTab,
															)}
															dealersettings={props.dealersettings}
															handleUnlickEmail={handleUnlickEmail}
															handlePreviewEmail={handlePreviewEmail}
															handleReplylog={handleReplylog}
															sendLoading={sendLoading}
															handleRadioChange={handleRadioChange}
															tipOffers={props.tipOffers}
															originType={props.originType}
															updateActivity={updateActivity}
															handleEDMsPreviewEmail={handleEDMsPreviewEmail}
															handleFileChange={handleFileChange}
															contact={
																!_.isEmpty(props.enquiry)
																	? props.enquiry.contact
																	: !_.isEmpty(props.tradeinPro)
																		? props.tradeinPro.contact
																		: !_.isEmpty(props.service)
																			? props.service.contact
																			: !_.isEmpty(props.contactBasicVM)
																				? props.contactBasicVM
																				: null
															}
														></CustomerlogDetails>
													);
												})}
										</div>
									</>
								) : (
									<div className='spinner-loader h-100 p-5'>
										<div className='no-data-flex h-100 justify-content-center align-items-center '>
											<div className='no-data-img'>
												{' '}
												<img src={_images.nodata} width='60' height='60' alt='' />
											</div>
											<div className='no-data-txt mt-2'>  <Translate text={'No Logs'} /></div>
										</div>
									</div>
								)}
							</>
					}
					{/* {pagingLoader ? (
									<div className='pull-left mt-4 w-100'>
										<ContentOverlay active={true} />
									</div>
								) : (logCounter['note'] ||
									logCounter['message'] ||
									logCounter['email'] ||
									logCounter['call']) > 0 &&
									hasMore.filter(e => e.type === activeLogTab).length > 0 ? (
											<div className='load-more-button'>
												{' '}
												<a
													href='#'
													onClick={e => {
														e.preventDefault();
														setDataload({
															...checkDataLoad,
															[activeLogTab]: true,
														});
													}}
												>
													{' '}<Translate text={'Load More'} />
													<i className='fa fa-angle-down' aria-hidden='true'></i>{' '}
												</a>
											</div>
										) : (
											<></>
										)} */}
				</>
				)}
			</div>
			<PopUpModal show={showPreviewModel}>
				<EmailPreview
					show={showPreviewModel}
					handleClose={handleModelClose}
					title={popheader}
					dealersettings={props.dealersettings}
					clientUsers={props.clientUsers}
					groupUsers={props.groupUsers}
					emailLog={emailLog}
					contact={
						!_.isEmpty(props.enquiry)
							? props.enquiry.contact
							: !_.isEmpty(props.tradeinPro)
								? props.tradeinPro.contact
								: !_.isEmpty(props.service)
									? props.service.contact
									: !_.isEmpty(props.contactBasicVM)
										? props.contactBasicVM
										: null
					}
					history={props.history}
					enquiry={!_.isEmpty(props.enquiry) ? props.enquiry : null}
					tradeinPro={!_.isEmpty(props.tradeinPro) ? props.tradeinPro : null}
					service={!_.isEmpty(props.service) ? props.service : null}
				/>
			</PopUpModal>
			<PopUpModal show={showComposeModal.show}>
				<ComposeEmail
					show={showComposeModal.show}
					handleClose={handleCloseComposeModal}
					token={props.dealersettings.nylasAccessToken}
					type={showComposeModal.type}
					title={showComposeModal.title}
					emailData={showComposeModal.emailData}
					dealersettings={props.dealersettings}
					sendLoader={sendLoader}
					setSendLoader={setSendLoader}
					handleSend={handleSend}
					structureType={!_.isEmpty(props.dealersettings.nylasAccountData) ? props.dealersettings.nylasAccountData.organization_unit + 's' : ''}
					emailtemplates={emailTemplates}
					contact={
						!_.isEmpty(props.enquiry)
							? props.enquiry.contact
							: !_.isEmpty(props.tradeinPro)
								? props.tradeinPro.contact
								: !_.isEmpty(props.service)
									? props.service.contact
									: !_.isEmpty(props.contactBasicVM)
										? props.contactBasicVM
										: null
					}
					enquiry={!_.isEmpty(props.enquiry) ? props.enquiry : null}
					tradeinPro={!_.isEmpty(props.tradeinPro) ? props.tradeinPro : null}
					service={!_.isEmpty(props.service) ? props.service : null}
					userData={{ emailSignature: props.dealersettings.emailSignature }}
				></ComposeEmail>
			</PopUpModal>
			<PopUpModal show={showEDMsPreviewModel.show}>
				<TemplatePreview
					{...props}
					show={showEDMsPreviewModel.show}
					title={showEDMsPreviewModel.title}
					handleClose={handleEDMsPreviewEmailClose}
					template={showEDMsPreviewModel.template}
				/>
			</PopUpModal>
		</>
	);

	// return (
	//     <>

	//         <div className="quickview-activity-item">
	//             <div className="quickview-related">
	//                 <Accordion>
	//                     {
	//                         customerlogTypes.map((objtypes, index) => {
	//                             return <CustomerLogCards
	//                                 key={index}
	//                                 activeindex={index}
	//                                 activeKey={activeKey}
	//                                 type={objtypes.value}
	//                                 clsicon={objtypes.ico}
	//                                 name={objtypes.name}
	//                                 emptymessage={objtypes.emptymessage}
	//                                 customerlogSorted={customerlogList.filter(e => e.subType === objtypes.value)}
	//                                 handlenewlog={handlenewlog}
	//                                 clientUsers={props.clientUsers}
	//                                 handlecancellog={handlecancellog}
	//                                 handlechangelog={handlechangelog}
	//                                 handlesavelog={handlesavelog}
	//                                 handleEditlog={handleEditlog}
	//                                 handleActiveKey={handleActiveKey}
	//                                 textareaRef={textareaRef}
	//                                 handleDeletelog={handleDeletelog}
	//                                 handleDateChangelog={handleDateChangelog}
	//                                 loading={loading}
	//                             />
	//                         })
	//                     }
	//                 </Accordion>
	//             </div>
	//         </div>
	//     </>
	// );
}

export default RealatedTab;
