import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { ProgressBar } from 'react-bootstrap'
import moment from 'moment'
import _images from '../../images'
import { ContentOverlay, PopUpModal, GuageChart, ReactSelect } from "../../components";
import UserTargetModel from "./usertargetModel";
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';

const AllUsersTarget = (props) => {
    const [loader, setLoader] = useState(true)
    const [contactLoader, setContactLoader] = useState(true)
    const [alluserTarget, setAllUserTarget] = useState([]);
    const [targets, setTargets] = useState([])
    const [avgUserTargets, setAvgUserTargets] = useState([])
    const mStartdate = window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d)
    const mEnddate = window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    const [months, setMonths] = useState(moment().format("MMM"))
    const [year, setYear] = useState(moment().format("YYYY"));
    const [allData, setData] = useState({
        enquiries: [],
        contacts: [],
        activities: [],
        testdrives: [],
        tradeins: []
    })
    const [modelshow, setShow] = useState(false);
    const [selectedTarget, setSelectedtarget] = useState()
    const [popHeader, setHeader] = useState()
    const [refreshData, setRefreshData] = useState(true)
    const calcCurrentStatus = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.calcCurrentStatus) ? 'true' : 'false');
    const [initialLoad, setInitialLoad] = useState(true)
    const [refreshTime, setRefreshTime] = useState(0)
    const [targetDD, setTargetDD] = useState([])
    const [selectedType, setSelectedType] = useState()
    const docID = localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.id;
    const docPathRef = window.firebase.firestore().doc(`users/${localStorage.uid}/dashoardWidgets/${docID}-allUsersTarget`);

    useEffect(() => {
        return () => {
            window.unsuballuserstargetSnap && window.unsuballuserstargetSnap();
        }
    }, []);

    useEffect(() => {
        if (refreshTime === 0)
            return;

        const timer = setTimeout(() => {
            if (moment().unix() - refreshTime > 1000) {
                if (_.isEmpty(props.dashboardFilter))
                    fetchData(true);
            }
        }, 900000);
        return () => clearTimeout(timer);
    }, [refreshTime])

    useEffect(() => {
        if (!initialLoad)
            return
        setLoader(true)
        window.unsuballuserstargetSnap = docPathRef
            .onSnapshot(snap => {
                if (snap.exists) {
                    setLoader(false);
                    setInitialLoad(false)
                    setTargets(snap.data().data)
                    if (snap.data().data && snap.data()?.data?.length === 0) {
                        setAvgUserTargets([]);
                    }
                    setRefreshTime(snap.data().logDate ? snap.data().logDate.seconds : 0);
                    if (snap.data().logDate && moment().unix() - snap.data().logDate.seconds > 1000) {
                        fetchData(true);
                    }
                }
                else {
                    fetchData();
                }
            })
    }, [initialLoad])

    const fetchData = async (hideLoader) => {
        // console.log('lead by campaigns')
        if (!hideLoader)
            setLoader(true);
        const reportData = window.firebase.functions().httpsCallable('generic-getData');
        reportData({
            type: props?.dealersettings?.mvwReport ? "getSalesTargetV2" : "getSalesTarget",
            params: JSON.stringify({
                clientID: props.dealersettings.client.id,
                startDate: moment.unix(mStartdate.seconds).format('YYYY-MM-DD'),
                endDate: moment.unix(mEnddate.seconds).format('YYYY-MM-DD'),
                pipeline: !_.isEmpty(props.selectedPipeline) ? props.selectedPipeline.value : '',
                timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
                calcCurrentStatus: calcCurrentStatus
            })
        }).then((response) => {
            setLoader(false);
            if (response.data.success) {
                setTargets(response.data.data)
                if (response.data.data && response.data.data.length === 0) {
                    setAvgUserTargets([]);
                }
                if (_.isEmpty(props.dashboardFilter)) {
                    docPathRef.set({ data: response.data.data, logDate: window.firebase.firestore.Timestamp.now() }, { merge: true })
                }
            }
        });
    }

    useEffect(() => {
        if (initialLoad)
            return;
        if (!_.isEmpty(props?.dashboardFilter?.pipelines)) {
            window.unsuballuserstargetSnap && window.unsuballuserstargetSnap();
            fetchData();
        }
        else {
            setInitialLoad(true)
        }

    }, [props?.dashboardFilter?.pipelines ? props.dashboardFilter.pipelines : false])

    // useEffect(() => {
    //     if (props.applydateRange === true || props.loader === true || contactLoader)
    //         return

    //     setData({
    //         ...allData,
    //         enquiries: props.pipelineEnquiries,
    //         activities: props.allActivities,
    //         testdrives: props.testdrives,
    //         tradeins: props.tradeins
    //     })
    // }, [props.loader, props.applydateRange, props.pipelineEnquiries, props.allActivities, props.testdrives, props.tradeins, contactLoader])

    // useEffect(() => {
    //     setLoader(true)
    //     const userTargetRef = window.firebase.firestore().collection('targets')
    //         .where('clientID', '==', props.dealersettings.client.id)
    //         .where('isDeleted', '==', false)
    //         .where('type', '==', 'user')
    //         .onSnapshot(snapshot => {
    //             if (snapshot.docs.length > 0) {
    //                 setAllUserTarget(snapshot.docs.map(doc => {
    //                     return {
    //                         ...doc.data(),
    //                         id: doc.id
    //                     }
    //                 }))
    //             }
    //             else {
    //                 setAllUserTarget(null)
    //             }
    //             setLoader(false)
    //             if (snapshot.docs.length === 0)
    //                 setContactLoader(false)
    //         })

    //     return () => {
    //         userTargetRef && userTargetRef()
    //         window.unsubUsersTargetContactsList && window.unsubUsersTargetContactsList();
    //     }
    // }, [])

    // useEffect(() => {
    //     if (_.isEmpty(alluserTarget))
    //         return

    //     let _roleids = props.dealersettings.client.roles ? props.dealersettings.client.roles.filter(rec => rec.pipeline && rec.pipeline.some(p => p !== 'LeadsBucket')).map(rec => { return rec.id }) : [];
    //     let salesUserids = _.filter(props.clientUsers, (v) => _roleids.length > 0 ? _.includes(_roleids, v.roleID) : true).map(rec => { return rec.id })
    //     if (alluserTarget.some(a => !_.isEmpty(a['2021'])
    //         && !_.isEmpty(a['2021']['Mar'])
    //         && a['2021']['Mar'].filter(e => e.type === 'dataQuality').length > 0
    //         && _.filter(a.userIDs, (v) => _.includes(salesUserids, v)).length > 0)) {

    //         window.unsubUsersTargetContactsList = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails')
    //             .where('clientID', '==', props.dealersettings.client.id)
    //             .where('isDeleted', '==', false)
    //             .where('addedDate', '>=', mStartdate)
    //             .where('addedDate', '<=', mEnddate)
    //             .orderBy('addedDate', 'desc')
    //             .limit(1000)
    //             .onSnapshot(querysnapshot => {
    //                 let _contacts = [];
    //                 querysnapshot.docs.forEach(snap => {
    //                     _contacts.push({
    //                         ...snap.data(),
    //                         'documentID': snap.id
    //                     })
    //                 })
    //                 setData({
    //                     ...allData,
    //                     contacts: _contacts
    //                 })
    //                 setContactLoader(false)
    //             })
    //     }
    //     else {
    //         setContactLoader(false)
    //     }
    // }, [alluserTarget])

    // useEffect(() => {
    //     if (_.isEmpty(alluserTarget) || contactLoader)
    //         return

    //     let _roleids = props.dealersettings.client.roles ? props.dealersettings.client.roles.filter(rec => rec.pipeline && rec.pipeline.some(p => p !== 'LeadsBucket')).map(rec => { return rec.id }) : [];
    //     let salesUsers = props.clientUsers;//_.filter(props.clientUsers, (v) => _roleids.length > 0 ? _.includes(_roleids, v.roleID) : true).map(rec => { return { ...rec } })
    //     let targets = [];
    //     salesUsers.forEach(user => {
    //         let userTarget = alluserTarget.filter(a => a.userIDs.some(u => u === user.id))[0];
    //         let owner = user.id;
    //         if (userTarget && userTarget[year] && Object.keys(userTarget[year]).some(e => e === months)) {
    //             Object.keys(userTarget[year]).filter(e => e === months).forEach(month => {
    //                 var monthStartdate = window.firebase.firestore.Timestamp.fromDate(moment().month(month).startOf('month')._d)
    //                 var monthEnddate = window.firebase.firestore.Timestamp.fromDate(moment().month(month).endOf('month')._d)
    //                 var monthTarget = userTarget[year][month];

    //                 var monthnewLeads = allData.enquiries.filter(a =>
    //                     a.ownerValue === owner
    //                     && a.addedDate.seconds >= monthStartdate.seconds
    //                     && a.addedDate.seconds <= monthEnddate.seconds
    //                 )
    //                 var monthallLeads = allData.enquiries.filter(a =>
    //                     a.ownerValue === owner
    //                     && ((a.statusValue === 'won' && a.wonDate && a.wonDate.seconds >= monthStartdate.seconds
    //                         && a.wonDate.seconds <= monthEnddate.seconds) ||
    //                         (a.statusValue === 'delivered' && a.deliveredDate && a.deliveredDate.seconds >= monthStartdate.seconds
    //                             && a.deliveredDate.seconds <= monthEnddate.seconds) ||
    //                         (a.statusValue === 'lost' && a.lostDate && a.lostDate.seconds >= monthStartdate.seconds
    //                             && a.lostDate.seconds <= monthEnddate.seconds) ||
    //                         (a.testdrives && a.testdrives.some(item => item.startDateValue.seconds >= monthStartdate.seconds
    //                             && item.startDateValue.seconds <= monthEnddate.seconds)) ||
    //                         (a.addedDate.seconds >= monthStartdate.seconds
    //                             && a.addedDate.seconds <= monthEnddate.seconds) ||
    //                         (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= monthStartdate.seconds
    //                             && item.walkinDate.seconds <= monthEnddate.seconds)))
    //                 )

    //                 var monthconvertedLeads = allData.enquiries.filter(a =>
    //                     ((a.ownerValue === owner && a.pipelineValue === 'LeadsBucket') || (a.convertedBy === owner && a.pipelineValue !== 'LeadsBucket'))
    //                     && ((a.statusValue === 'won' && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= monthStartdate.seconds
    //                         && a.wonDate.seconds <= monthEnddate.seconds) ||
    //                         (a.statusValue === 'delivered' && !_.isEmpty(a.deliveredDate) && a.deliveredDate.seconds >= monthStartdate.seconds
    //                             && a.deliveredDate.seconds <= monthEnddate.seconds) ||
    //                         (a.statusValue === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= monthStartdate.seconds
    //                             && a.lostDate.seconds <= monthEnddate.seconds) ||
    //                         (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= monthStartdate.seconds
    //                             && item.startDate.seconds <= monthEnddate.seconds)) ||
    //                         (!_.isEmpty(a.convertedDate) && a.convertedDate.seconds >= monthStartdate.seconds
    //                             && a.convertedDate.seconds <= monthEnddate.seconds) ||
    //                         (a.addedDate.seconds >= monthStartdate.seconds
    //                             && a.addedDate.seconds <= monthEnddate.seconds) ||
    //                         (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= monthStartdate.seconds
    //                             && item.walkinDate.seconds <= monthEnddate.seconds)))
    //                 )

    //                 var doneActivities = allData.activities.filter(a =>
    //                     a.owner === owner
    //                     && a.isDone === true
    //                     && a.modifiedDate.seconds >= monthStartdate.seconds
    //                     && a.modifiedDate.seconds <= monthEnddate.seconds
    //                 )
    //                 var testdriveLeads = allData.testdrives.filter(a =>
    //                     a.addedByValue === owner
    //                     && a.startDateValue.seconds >= monthStartdate.seconds
    //                     && a.startDateValue.seconds <= monthEnddate.seconds
    //                 )
    //                 var tradeinLeads = allData.tradeins.filter(a =>
    //                     a.addedByValue === owner
    //                     && a.addedDate.seconds >= monthStartdate.seconds
    //                     && a.addedDate.seconds <= monthEnddate.seconds
    //                 )
    //                 var monthContacts = allData.contacts && allData.contacts.filter(a =>
    //                     a.owner === owner
    //                     && a.addedDate.seconds >= monthStartdate.seconds
    //                     && a.addedDate.seconds <= monthEnddate.seconds
    //                 )

    //                 //targets by enquiry types
    //                 monthTarget.filter(e => e.type === 'enquiryTypes').forEach(rec => {
    //                     var leadsbyenquiryType = !_.isEmpty(rec.subType) ? monthnewLeads.filter(e =>
    //                         (!_.isEmpty(rec.pipeline) ? e.pipelineValue === rec.pipeline : true)
    //                         && rec.subType.split(',').some(a => a === e.enquiryTypeValue)) : [];
    //                     targets.push(targetCalculation(leadsbyenquiryType, rec, monthnewLeads.filter(a => (!_.isEmpty(rec.pipeline) ? a.pipelineValue === rec.pipeline : a.pipelineValue !== 'LeadsBucket')), month, monthTarget, user));
    //                 })

    //                 //targets by campaigns
    //                 monthTarget.filter(e => e.type === 'campaigns').forEach(rec => {
    //                     var leadsbyCampaign = !_.isEmpty(rec.subType) ? monthnewLeads.filter(e =>
    //                         (!_.isEmpty(rec.pipeline) ? e.pipelineValue === rec.pipeline : true)
    //                         && rec.subType.split(',').some(a => a === e.campaignValue)) : [];
    //                     targets.push(targetCalculation(leadsbyCampaign, rec, monthnewLeads.filter(a => (!_.isEmpty(rec.pipeline) ? a.pipelineValue === rec.pipeline : a.pipelineValue !== 'LeadsBucket')), month, monthTarget, user));
    //                 })

    //                 //targets by origin
    //                 monthTarget.filter(e => e.type === 'origins').forEach(rec => {
    //                     var leadsbyOrigin = !_.isEmpty(rec.subType) ? monthnewLeads.filter(e =>
    //                         (!_.isEmpty(rec.pipeline) ? e.pipelineValue === rec.pipeline : true)
    //                         && rec.subType.split(',').some(a => a === e.originValue)) : [];
    //                     targets.push(targetCalculation(leadsbyOrigin, rec, monthnewLeads.filter(a => (!_.isEmpty(rec.pipeline) ? a.pipelineValue === rec.pipeline : a.pipelineValue !== 'LeadsBucket')), month, monthTarget, user));
    //                 })

    //                 //targets by status
    //                 monthTarget.filter(e => e.type === 'status').forEach(rec => {
    //                     var leadsbyStatus = [];
    //                     if (!_.isEmpty(rec.subType)) {
    //                         rec.subType.split(',').forEach(status => {
    //                             var sdate = status === 'delivered' ? 'deliveredDate' : status === 'won' ? 'wonDate' : status === 'lost' ? 'lostDate' : 'addedDate'
    //                             leadsbyStatus = [...leadsbyStatus, ..._.map(allData.enquiries.filter(a =>
    //                                 a.ownerValue === owner
    //                                 && (!_.isEmpty(rec.pipeline) ? a.pipelineValue === rec.pipeline : true)
    //                                 && (status === 'won' ? (a.statusValue === 'won' || a.statusValue === 'delivered') : a.statusValue === status)
    //                                 && a[sdate].seconds >= monthStartdate.seconds
    //                                 && a[sdate].seconds <= monthEnddate.seconds
    //                             ), function (e) {
    //                                 return {
    //                                     documentID: e.documentID,
    //                                     status: e.statusValue
    //                                 }
    //                             })]
    //                         })
    //                     }

    //                     targets.push(targetCalculation(_.uniq(leadsbyStatus), rec, monthallLeads.filter(a => (!_.isEmpty(rec.pipeline) ? a.pipelineValue === rec.pipeline : a.pipelineValue !== 'LeadsBucket')), month, monthTarget, user));
    //                 })

    //                 //targets by inbound status
    //                 monthTarget.filter(e => e.type === 'inboundStatus').forEach(rec => {
    //                     var leadsbyinboundStatus = [];
    //                     if (!_.isEmpty(rec.subType)) {
    //                         rec.subType.split(',').forEach(status => {
    //                             var sdate = status === 'created' ? 'addedDate' : 'convertedDate'
    //                             leadsbyinboundStatus = [...leadsbyinboundStatus, ..._.map(allData.enquiries.filter(a =>
    //                                 ((a.ownerValue === owner && a.pipelineValue === 'LeadsBucket') || (a.convertedBy === owner && a.pipelineValue !== 'LeadsBucket'))
    //                                 //&& (!_.isEmpty(rec.pipeline) ? a.pipelineValue === rec.pipeline : true)
    //                                 && !_.isEmpty(a.stageHistory)
    //                                 && a.stageHistory.some(s => s.pipeline === 'LeadsBucket')
    //                                 && (status === 'show' ? a.bdcStatus === 'show' : true)
    //                                 && (status === 'noshow' ? a.bdcStatus === 'noshow' : true)
    //                                 && a[sdate].seconds >= monthStartdate.seconds
    //                                 && a[sdate].seconds <= monthEnddate.seconds
    //                             ), function (e) {
    //                                 return {
    //                                     documentID: e.documentID
    //                                 }
    //                             })]
    //                         })
    //                     }

    //                     targets.push(targetCalculation(_.uniq(leadsbyinboundStatus), rec, monthconvertedLeads, month, monthTarget, user));
    //                 })

    //                 //targets by activities
    //                 monthTarget.filter(e => e.type === 'activities').forEach(rec => {
    //                     var activitiesbyType = !_.isEmpty(rec.subType) ? doneActivities.filter(e =>
    //                         (!_.isEmpty(rec.pipeline) ? (!_.isEmpty(e.enquiry) ? e.enquiry.pipeline === rec.pipeline : false) : true)
    //                         && rec.subType.split(',').some(a => a === e.subType)) : [];
    //                     targets.push(targetCalculation(activitiesbyType, rec, doneActivities, month, monthTarget, user));
    //                 })

    //                 //target by salestype
    //                 monthTarget.filter(e => e.type === 'salesTypes').forEach(rec => {
    //                     var leadsbySalestypes = [];
    //                     if (!_.isEmpty(rec.subType)) {
    //                         rec.subType.split(',').forEach(salesType => {
    //                             var splitType = salesType.split('AND');
    //                             var status = splitType[1]
    //                             var saleType = splitType[0]
    //                             var sdate = status === 'delivered' ? 'deliveredDate' : status === 'won' ? 'wonDate' : status === 'lost' ? 'lostDate' : 'addedDate'
    //                             leadsbySalestypes = [...leadsbySalestypes, ..._.map(allData.enquiries.filter(a =>
    //                                 a.ownerValue === owner
    //                                 && (!_.isEmpty(rec.pipeline) ? a.pipelineValue === rec.pipeline : true)
    //                                 && (!_.isEmpty(status) ? (status === 'won' ? (a.statusValue === 'won' || a.statusValue === 'delivered') : a.statusValue === status) : true)
    //                                 && (!_.isEmpty(a.requirement))
    //                                 && (!_.isEmpty(a.requirement.saleType))
    //                                 && (a.requirement.saleType.toLowerCase() === saleType.toLowerCase())
    //                                 && !_.isEmpty(a[sdate])
    //                                 && a[sdate].seconds >= monthStartdate.seconds
    //                                 && a[sdate].seconds <= monthEnddate.seconds
    //                             ), function (e) {
    //                                 return {
    //                                     documentID: e.documentID,
    //                                     status: e.statusValue
    //                                 }
    //                             })]
    //                         })
    //                     }

    //                     targets.push(targetCalculation(_.uniq(leadsbySalestypes), rec, monthallLeads.filter(a => (!_.isEmpty(rec.pipeline) ? a.pipelineValue === rec.pipeline : a.pipelineValue !== 'LeadsBucket')), month, monthTarget, user));
    //                 })

    //                 //targets by test drives
    //                 monthTarget.filter(e => e.type === 'testdrive').forEach(rec => {
    //                     var testdrives = !_.isEmpty(rec.subType) ? _.map(testdriveLeads.filter(e =>
    //                         (!_.isEmpty(rec.pipeline) ? e.pipelineValue === rec.pipeline : e.pipelineValue !== 'LeadsBucket')
    //                     ), function (e) { return e.enquiryID }) : [];
    //                     var monthopenLeads = monthallLeads.filter(e =>
    //                         (!_.isEmpty(rec.pipeline) ? e.pipelineValue === rec.pipeline : e.pipelineValue !== 'LeadsBucket')
    //                         //&& e.statusValue === 'open'
    //                     )
    //                     targets.push(targetCalculation(_.uniq(testdrives), rec, monthopenLeads, month, monthTarget, user));
    //                 })
    //                 //targets by tradein
    //                 monthTarget.filter(e => e.type === 'tradein').forEach(rec => {
    //                     var tradeins = !_.isEmpty(rec.subType) ? tradeinLeads.filter(e =>
    //                         (!_.isEmpty(rec.pipeline) ? e.pipelineValue === rec.pipeline : e.pipelineValue !== 'LeadsBucket')
    //                     ) : [];
    //                     var monthopenLeads = monthallLeads.filter(e =>
    //                         (!_.isEmpty(rec.pipeline) ? e.pipelineValue === rec.pipeline : e.pipelineValue !== 'LeadsBucket')
    //                         //&& e.status === 'open'
    //                     )
    //                     targets.push(targetCalculation(tradeins, rec, monthopenLeads, month, monthTarget, user));
    //                 })


    //                 //targets by dataQuality
    //                 let dataCapture = [];
    //                 monthTarget.filter(e => e.type === 'dataQuality').forEach(rec => {
    //                     if (!_.isEmpty(rec.subType)) {
    //                         monthContacts.forEach(contact => {
    //                             let i = 1;
    //                             rec.subType.split(',').forEach(field => {
    //                                 if (_.isEmpty(contact[field])) {
    //                                     i = 0;
    //                                 }
    //                             })
    //                             if (i > 0)
    //                                 dataCapture.push(contact)
    //                         })
    //                     }
    //                     // var dataCapture = !_.isEmpty(rec.subType) ? monthContacts.filter(e =>
    //                     //     rec.subType.split(',').some(a => !_.isEmpty(e[a]))) : [];
    //                     targets.push(targetCalculation(dataCapture, rec, monthContacts, month, monthTarget, user));
    //                 })

    //                 let enquiryOptions = [];
    //                 monthTarget.filter(e => e.type === 'enquiryOptions').forEach(rec => {
    //                     if (!_.isEmpty(rec.subType)) {
    //                         monthnewLeads.forEach(enquiry => {
    //                             let i = 1;
    //                             rec.subType.split(',').forEach(field => {
    //                                 if (!enquiry.options || !enquiry.options.some(e => e === field)) {
    //                                     i = 0;
    //                                 }
    //                             })
    //                             if (i > 0)
    //                                 enquiryOptions.push(enquiry)
    //                         })
    //                     }
    //                     // var dataCapture = !_.isEmpty(rec.subType) ? monthContacts.filter(e =>
    //                     //     rec.subType.split(',').some(a => !_.isEmpty(e[a]))) : [];
    //                     targets.push(targetCalculation(enquiryOptions, rec, monthnewLeads.filter(a => (!_.isEmpty(rec.pipeline) ? a.pipelineValue === rec.pipeline : a.pipelineValue !== 'LeadsBucket')), month, monthTarget, user));
    //                 })
    //             })
    //         }
    //     })
    //     setTargets(targets)
    // }, [alluserTarget, allData, contactLoader])

    const targetCalculation = (leads, target, monthLeads, month, targetList, owner) => {
        monthLeads = _.uniq(_.map(monthLeads, function (e) { return e.documentID }))
        var actualPtg = monthLeads.length > 0 ? Math.round((100 / monthLeads.length) * leads.length) : 0;
        if (target.calculationType === 'count') {
            actualPtg = target.target > 0 ? Math.round((100 / target.target) * leads.length) : 0;
        }
        else if (target.calculationType === 'percentage' && actualPtg >= target.target) {
            actualPtg = 100
        }
        var targetVM = {
            ...target,
            userid: owner.id,
            username: owner.name,
            userimage: CommonHelper.showUserAvatar(owner.profileImage, owner.name),
            avgTarget: actualPtg > 100 ? 100 : actualPtg,
            id: target.targetID,
            month: month,
            sortOrder: _.findIndex(targetList, (e) => {
                return e.targetID == target.targetID;
            }, 0)
        }
        if (target.calculationType === 'count') {
            targetVM = {
                ...targetVM,
                target: target.target,
                actual: leads.length,
                percentage: target.target > 0 ? Math.round((100 / target.target) * leads.length) + '%' : '0%',
                showRed: (target.target > leads.length) ? true : false,
                different: target.target - leads.length
            }
        }
        else {
            var targetPtg = target.target;
            targetVM = {
                ...targetVM,
                target: targetPtg + '%',
                actual: actualPtg + '%',
                percentage: targetPtg > 0 ? Math.round((100 / targetPtg) * actualPtg) + '%' : '0%',
                showRed: (targetPtg > actualPtg) ? true : false,
                different: targetPtg > 0 ? (100 - (Math.round((100 / targetPtg) * actualPtg))) + '%' : '0%'
            }
        }
        return targetVM
    }

    useEffect(() => {
        if (_.isEmpty(targets))
            return

        const _targets = _.chain(targets)
            // Group the elements of Array based on `color` property
            .groupBy("targetID")
            // `key` is group's name (color), `value` is the array of objects
            .map((value, key) => ({ name: value[0].name, value: key }))
            .value();

        setTargetDD(_.chain(_targets)
            // Group the elements of Array based on `color` property
            .groupBy("name")
            // `key` is group's name (color), `value` is the array of objects
            .map((value, key) => ({ label: key, value: value.map(a => a.value).join(',') }))
            .value())

    }, [targets])

    useEffect(() => {
        if (_.isEmpty(targets))
            return

        setAvgUserTargets(_.chain(targets.filter(a => selectedType ? selectedType.split(',').includes(a.targetID) : true))
            // Group the elements of Array based on `color` property
            .groupBy("userid")
            // `key` is group's name (color), `value` is the array of objects
            .map((value, key) => ({
                name: CommonHelper.getUserNamebyId(props.clientUsers, value[0].userid),
                image: CommonHelper.getUserImagebyId(props.clientUsers, value[0].userid),
                avgValue: _.round(_.mean(value.map(r => { return r.achieved })), 0),
                targetValue: selectedType && value.some(a => a.calculationType === 'count') ? _.sum(value.map(r => { return Number(r.target) })) : `${_.round(_.mean(value.map(r => { return r.achieved })), 0)}%`,
                actualValue: selectedType && value.some(a => a.calculationType === 'count') ? _.sum(value.map(r => { return Number(r.actual) })) : `${_.round(_.mean(value.map(r => { return r.achieved })), 0)}%`,
                targets: value
            }))
            .value())
    }, [targets, selectedType])

    const handleModelClose = () => {
        setShow(false);
        setSelectedtarget(null)
        setHeader(null)
    }

    return (<>
        <div className="dashboard-box  draggable-section">
            <div className="dashboard-box-header">
                <div className="dashboard-toolbox">
                    <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); fetchData(); }}> <i className="ico icon-refresh"></i></a>
                    {/* <a href="#" className="mini-button"> <i className="ico icon-filter"></i></a> 
                     <a href="#" className="mini-button ml-1"> <i className="ico icon-more"></i></a>  */}
                    {
                        props.fromOEM || props.hideRemoveBtn ? (<></>) : (
                            <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); props.removeWidget() }}> <i className="ico icon-remove"></i></a>
                        )
                    }

                </div>
                <div className="form-group col-md-3 dashboard-widget-target-select" style={{ right: '65px' }}>
                    <ReactSelect
                        options={targetDD}
                        name={"targetType"}
                        placeholder={'select target'}
                        onChange={(e) => {
                            setSelectedType(e ? e.value : null)
                        }}
                        value={selectedType}
                        classNamePrefix={`cursor-pointer basic-select`}
                    >
                    </ReactSelect>
                </div>
                <h3><Translate text={'Sales Target'} /> - {moment().format('MMM YYYY')}</h3>
            </div>
            <div className="dashboard-box-body">
                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        avgUserTargets.length > 0 ?
                            (
                                <div className="dashboard-salestarget">
                                    <div className="salestarget-chart">
                                        <GuageChart
                                            id="target-guage-chart"
                                            height={'175px'}
                                            marginTop={'-15px'}
                                            value={_.round(_.mean(avgUserTargets.map(r => { return r.avgValue })), 0)}
                                        ></GuageChart>
                                    </div>
                                    <div className="salestarget-user h-100 pr-3" style={{ maxHeight: `275px`, minHeight: `275px`, overflow: `auto`, marginTop: `-30px` }}>
                                        {
                                            _.orderBy(avgUserTargets, ['avgValue'], ['desc']).map((rec, i) => {
                                                return <div key={i} className="salestarget-user-loop salestarget-user-flex" onClick={(e) => {
                                                    e.preventDefault();
                                                    setSelectedtarget(rec.targets)
                                                    setHeader(rec.name)
                                                    setShow(true)
                                                }}>
                                                    <div className="salestarget-user-avatar">
                                                        <img className="img-object-fit" src={rec.image} /> </div>
                                                    <div className="salestarget-user-detail">
                                                        <h3>{rec.name}</h3>
                                                        <ProgressBar className={`bg-progress-green ${rec.avgValue === 0 ? 'progress-bar-zero' : ''}`} now={rec.avgValue} label={`${rec.actualValue}`} />
                                                        <h5>{rec.targetValue}</h5>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            ) : (<div className="spinner-loader h-100 p-5">
                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                    <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                    <div className="no-data-txt mt-2"> <Translate text={'Target not set'} /></div>
                                </div>
                            </div>)
                }

            </div>
        </div>
        <PopUpModal show={modelshow}>
            <UserTargetModel
                {...props}
                show={modelshow}
                handleClose={handleModelClose}
                title={popHeader}
                usertarget={selectedTarget}
            ></UserTargetModel>
        </PopUpModal>
    </>
    )
}

export default AllUsersTarget;