import React, { useState, useEffect, useContext } from 'react';
import _, { isEmpty } from 'lodash'
import toast from 'toasted-notes'
import { ContentOverlay, ReactSelect } from "../../../../components";
import images from "../../../../images";
import { ClientContext } from '../../../layout/clientContext'
import Swal from 'sweetalert2'
import { allintegrations } from './viewModel';

const levelOptions = [
    { value: 'oem', label: 'OEM', index: 1 },
    { value: 'region', label: 'Region', index: 2 },
    { value: 'group', label: 'Group', index: 3 },
    { value: 'individual', label: 'Individual', index: 4 }
]

const IntegrationAddOns = (props) => {
    const [selectedLevel, setSelectedLevel] = useState({ level: 'oem' })

    const [oemOptions, setOEMOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);

    const [loader, setLoader] = useState(true)
    const [btnLoader, setBtnLoader] = useState({});
    const [nylasDisableLoader, setNylasDisableLoader] = useState([]);

    const [modules, setModules] = useState({});
    const [selectedOEM, setSelectedOEM] = useState()

    const { oemSettings } = useContext(ClientContext);


    // fetching the oems list
    useEffect(() => {
        if (_.isEmpty(oemSettings))
            return

        let _oemSettings = [];
        oemSettings.forEach((rec) => {
            _oemSettings.push({ label: rec.name, value: rec.id, data: rec })
        })
        setOEMOptions(_.orderBy(_oemSettings, ['label'], ['asc']));
        if (_oemSettings.length > 0)
            setSelectedOEM(_oemSettings[0].data)
    }, [oemSettings])

    // fetching the oems clients, regions, groups list if ome is selected 
    useEffect(() => {
        if (_.isEmpty(selectedOEM))
            return

        const fetchOptions = async () => {
            let _clients = [];
            let _regions = [];
            let _groups = [];

            const clientSnapshots = await window.firebase.firestore()
                .collection(`clients`)
                .where('settingsID', '==', selectedOEM.id).get()
            clientSnapshots.docs.forEach(rec => {
                _clients.push({
                    label: rec.data().name,
                    value: rec.id,
                    data: rec.data()
                })
            })

            const regionSnapshots = await window.firebase.firestore()
                .collection(`clientSettings`)
                .doc(selectedOEM.id)
                .collection(`regions`)
                .get()
            regionSnapshots.docs.forEach(rec => {
                _regions.push({
                    label: rec.data().name,
                    value: rec.id,
                    data: rec.data()
                })
            })

            const groupSnapshots = await window.firebase.firestore()
                .collection(`clientSettings`)
                .doc(selectedOEM.id)
                .collection(`groups`)
                .get()
            groupSnapshots.docs.forEach(rec => {
                _groups.push({
                    label: rec.data().name,
                    value: rec.id,
                    data: rec.data()
                })
            })


            setClientOptions(_clients)
            setRegionOptions(_regions)
            setGroupOptions(_groups)
            setLoader(false)
        }
        fetchOptions()

    }, [selectedOEM])

    // fetching the _integrations of client/region/group/oem if oem is selected 
    useEffect(() => {
        if (_.isEmpty(selectedOEM)) {
            return
        }

        const isLevelOEM = !selectedLevel || selectedLevel.level === 'oem' ? true : false

        if (!isLevelOEM && !selectedLevel.levelID) {
            return
        }

        setModules({})

        let integrationRef
        if (isLevelOEM) {
            integrationRef = window.firebase.firestore().collection(`clientSettings`).doc(selectedOEM.id).collection('integrations')
        } else if (selectedLevel.level === 'region') {
            integrationRef = window.firebase.firestore().collection(`clientSettings`).doc(selectedOEM.id).collection('regions').doc(selectedLevel.levelID).collection('integrations')
        } else if (selectedLevel.level === 'group') {
            integrationRef = window.firebase.firestore().collection(`clientSettings`).doc(selectedOEM.id).collection('groups').doc(selectedLevel.levelID).collection('integrations')
        } else if (selectedLevel.level === 'individual') {
            integrationRef = window.firebase.firestore().collection(`clients`).doc(selectedLevel.levelID).collection('integrations')
        }

        const integrationsSnapshot = integrationRef
            .onSnapshot(querySnapshot => {
                let _integrations = [];
                querySnapshot.docs.forEach(rec => {
                    _integrations.push(rec.data())
                })
                if (!_.isEmpty(_integrations)) {
                    let _modules = {}
                    if (!isEmpty(_.find(_integrations, { 'type': 'imotor' }))) {
                        _modules.imotor = _.find(_integrations, { 'type': 'imotor' })
                    }
                    if (!isEmpty(_.find(_integrations, { 'type': 'messagemedia' }))) {
                        _modules.messagemedia = _.find(_integrations, { 'type': 'messagemedia' })
                    }
                    if (!isEmpty(_.find(_integrations, { 'type': 'vision6' }))) {
                        _modules.vision6 = _.find(_integrations, { 'type': 'vision6' })
                    }
                    if (!isEmpty(_.find(_integrations, { 'type': 'driva' }))) {
                        _modules.driva = _.find(_integrations, { 'type': 'driva' })
                    }
                    if (!isEmpty(_.find(_integrations, { 'type': 'evalexpert' }))) {
                        _modules.evalexpert = _.find(_integrations, { 'type': 'evalexpert' })
                    }
                    if (!isEmpty(_.find(_integrations, { 'type': 'digitallicence_nsw' }))) {
                        _modules.digitallicence_nsw = _.find(_integrations, { 'type': 'digitallicence_nsw' })
                    }
                    if (!isEmpty(_.find(_integrations, { 'type': 'facebook' }))) {
                        _modules.facebook = _.find(_integrations, { 'type': 'facebook' })
                    }
                    if (!isEmpty(_.find(_integrations, { 'type': 'autograb' }))) {
                        _modules.autograb = _.find(_integrations, { 'type': 'autograb' })
                    }
                    if (!isEmpty(_.find(_integrations, { 'type': 'smshub' }))) {
                        _modules.smshub = _.find(_integrations, { 'type': 'smshub' })
                    }
                    if (!isEmpty(_.find(_integrations, { 'type': 'cmdotcom' }))) {
                        _modules.cmdotcom = _.find(_integrations, { 'type': 'cmdotcom' })
                    }
                    if (!isEmpty(_.find(_integrations, { 'type': 'podium' }))) {
                        _modules.podium = _.find(_integrations, { 'type': 'podium' })
                    }
                    if (!isEmpty(_.find(_integrations, { 'type': 'titan' }))) {
                        _modules.titan = _.find(_integrations, { 'type': 'titan' })
                    }
                    if (!isEmpty(_.find(_integrations, { 'type': 'autogate' }))) {
                        _modules.autogate = _.find(_integrations, { 'type': 'autogate' })
                    }
                    if (!isEmpty(_.find(_integrations, { 'type': 'autotrader' }))) {
                        _modules.autotrader = _.find(_integrations, { 'type': 'autotrader' })
                    }
                    if (!isEmpty(_.find(_integrations, { 'type': 'dealersolutions' }))) {
                        _modules.dealersolutions = _.find(_integrations, { 'type': 'dealersolutions' })
                    }
                    if (!isEmpty(_.find(_integrations, { 'type': 'drivechat' }))) {
                        _modules.drivechat = _.find(_integrations, { 'type': 'drivechat' })
                    }
                    if (!isEmpty(_.find(_integrations, { 'type': 'carsguide' }))) {
                        _modules.carsguide = _.find(_integrations, { 'type': 'carsguide' })
                    }
                    if (!isEmpty(_.find(_integrations, { 'type': 'erapower' }))) {
                        _modules.erapower = _.find(_integrations, { 'type': 'erapower' })
                    }
                    if (!isEmpty(_.find(_integrations, { 'type': 'saleslogs' }))) {
                        _modules.saleslogs = _.find(_integrations, { 'type': 'saleslogs' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'aircall' }))) {
                        _modules.aircall = _.find(_integrations, { 'type': 'aircall' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'mailchimp' }))) {
                        _modules.mailchimp = _.find(_integrations, { 'type': 'mailchimp' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'mailup' }))) {
                        _modules.mailup = _.find(_integrations, { 'type': 'mailup' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'mailjet' }))) {
                        _modules.mailjet = _.find(_integrations, { 'type': 'mailjet' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'nylas' }))) {
                        _modules.nylas = _.find(_integrations, { 'type': 'nylas' })
                        // NEWONES 
                    } if (!isEmpty(_.find(_integrations, { 'type': 'adtorqueedge' }))) {
                        _modules.adtorqueedge = _.find(_integrations, { 'type': 'adtorqueedge' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'appraisal_solutions' }))) {
                        _modules.appraisal_solutions = _.find(_integrations, { 'type': 'appraisal_solutions' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'autoit' }))) {
                        _modules.autoit = _.find(_integrations, { 'type': 'autoit' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'pmds' }))) {
                        _modules.pmds = _.find(_integrations, { 'type': 'pmds' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'keyloop' }))) {
                        _modules.keyloop = _.find(_integrations, { 'type': 'keyloop' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'bentley_cab' }))) {
                        _modules.bentley_cab = _.find(_integrations, { 'type': 'bentley_cab' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'carsales' }))) {
                        _modules.carsales = _.find(_integrations, { 'type': 'carsales' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'conversica' }))) {
                        _modules.conversica = _.find(_integrations, { 'type': 'conversica' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'creativo' }))) {
                        _modules.creativo = _.find(_integrations, { 'type': 'creativo' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'dubizzle' }))) {
                        _modules.dubizzle = _.find(_integrations, { 'type': 'dubizzle' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'facebook' }))) {
                        _modules.facebook = _.find(_integrations, { 'type': 'facebook' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'autograb' }))) {
                        _modules.autograb = _.find(_integrations, { 'type': 'autograb' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'fca' }))) {
                        _modules.fca = _.find(_integrations, { 'type': 'fca' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'ferrari_modis' }))) {
                        _modules.ferrari_modis = _.find(_integrations, { 'type': 'ferrari_modis' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'gumtree' }))) {
                        _modules.gumtree = _.find(_integrations, { 'type': 'gumtree' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'heroleads' }))) {
                        _modules.heroleads = _.find(_integrations, { 'type': 'heroleads' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'mattaki' }))) {
                        _modules.mattaki = _.find(_integrations, { 'type': 'mattaki' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'motorweb' }))) {
                        _modules.motorweb = _.find(_integrations, { 'type': 'motorweb' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'cap' }))) {
                        _modules.cap = _.find(_integrations, { 'type': 'cap' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'red_book' }))) {
                        _modules.red_book = _.find(_integrations, { 'type': 'red_book' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'roi' }))) {
                        _modules.roi = _.find(_integrations, { 'type': 'roi' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'salesforce' }))) {
                        _modules.salesforce = _.find(_integrations, { 'type': 'salesforce' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'shiftdigital' }))) {
                        _modules.shiftdigital = _.find(_integrations, { 'type': 'shiftdigital' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'sms_broadcast' }))) {
                        _modules.sms_broadcast = _.find(_integrations, { 'type': 'sms_broadcast' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'wildjar' }))) {
                        _modules.wildjar = _.find(_integrations, { 'type': 'wildjar' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'dubaicars' }))) {
                        _modules.dubaicars = _.find(_integrations, { 'type': 'dubaicars' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'aapwebsite' }))) {
                        _modules.aapwebsite = _.find(_integrations, { 'type': 'aapwebsite' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'gubagoo' }))) {
                        _modules.gubagoo = _.find(_integrations, { 'type': 'gubagoo' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'autoforce' }))) {
                        _modules.autoforce = _.find(_integrations, { 'type': 'autoforce' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'amsme' }))) {
                        _modules.amsme = _.find(_integrations, { 'type': 'amsme' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'projectcoavme' }))) {
                        _modules.projectcoavme = _.find(_integrations, { 'type': 'projectcoavme' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'nexa' }))) {
                        _modules.nexa = _.find(_integrations, { 'type': 'nexa' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'adtorqueedgeloan' }))) {
                        _modules.adtorqueedgeloan = _.find(_integrations, { 'type': 'adtorqueedgeloan' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'c2native' }))) {
                        _modules.c2native = _.find(_integrations, { 'type': 'c2native' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'ringcentral' }))) {
                        _modules.ringcentral = _.find(_integrations, { 'type': 'ringcentral' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'ppsr' }))) {
                        _modules.ppsr = _.find(_integrations, { 'type': 'ppsr' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'd365' }))) {
                        _modules.d365 = _.find(_integrations, { 'type': 'd365' })
                    } if (!isEmpty(_.find(_integrations, { 'type': 'pandadoc' }))) {
                        _modules.pandadoc = _.find(_integrations, { 'type': 'pandadoc' })
                    }
                    allintegrations.forEach(rec => {
                        if (!isEmpty(_.find(_integrations, { 'type': rec.value }))) {
                            _modules[rec.value] = _.find(_integrations, { 'type': rec.value })
                        }
                    })
                    setModules(_modules)
                }
                setLoader(false)
            })
        return () => {
            integrationsSnapshot && integrationsSnapshot()
        }

    }, [selectedLevel, selectedOEM])

    const disableAllNylasEmail = async id => {
        if (!id) {
            setNylasDisableLoader(false)
            return
        }
        setNylasDisableLoader(true)
        toast.notify(`Nylas integrations are being disabled...`, {
            duration: 2000
        })
        const nylasCancelAccount = window.firebase.functions().httpsCallable('nylassync-cancelAccount');
        const data = await nylasCancelAccount({
            "clientID": id,
        })

        //console.log(data)
        if (data.data.success === true) {
            toast.notify(`All nylas integrations have been disabled successfully.`, {
                duration: 2000
            })
        }
        else {
            setLoader(false)
            Swal.fire(data.data.message, '', 'error')
        }

        setNylasDisableLoader(false)
    }

    const enableAddons = async (module, checked) => {
        setBtnLoader({
            [module]: true
        })

        const isLevelOEM = !selectedLevel || selectedLevel.level === 'oem' ? true : false

        let integrationRef
        if (isLevelOEM) {
            integrationRef = window.firebase.firestore().collection(`clientSettings`).doc(selectedOEM.id).collection('integrations')
        } else if (selectedLevel.level === 'region') {
            integrationRef = window.firebase.firestore().collection(`clientSettings`).doc(selectedOEM.id).collection('regions').doc(selectedLevel.levelID).collection('integrations')
        } else if (selectedLevel.level === 'group') {
            integrationRef = window.firebase.firestore().collection(`clientSettings`).doc(selectedOEM.id).collection('groups').doc(selectedLevel.levelID).collection('integrations')
        } else if (selectedLevel.level === 'individual') {
            integrationRef = window.firebase.firestore().collection(`clients`).doc(selectedLevel.levelID).collection('integrations')
        }

        // check if there is already a where type === module
        const snap = await integrationRef.where("type", "==", module).get()
        let documentID;
        if (snap.empty) {
            documentID = window.firebase.firestore().collection('clients').doc().id;
        } else {
            snap.forEach(doc => {
                documentID = doc.id
            })
        }
        integrationRef.doc(documentID)
            .set({
                type: module,
                active: checked
            }, { merge: true })
            .then(snapshot => {
                if (selectedLevel && selectedLevel.level === 'individual') {
                    //update settings date and priority
                    window.firebase.firestore().doc(`clients/${selectedLevel.levelID}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'critical',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: null,
                        }, { merge: true })

                    //end
                }

                toast.notify(`Add-on ${checked ? 'enabled' : 'disabled'} successfully.`, {
                    duration: 2000
                })
                setBtnLoader({})
            })
            .catch(error => {
                console.log(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });

    }
    return (<>
        <h2>Add-ons</h2>
        <div className='divider-line'></div>
        <div className="mt-4">
            <div className="settings-head form-style">
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-3 pr-0">
                                <ReactSelect
                                    options={oemOptions}
                                    name={"oem"}
                                    placeholder={'select oem'}
                                    onChange={(e) => {
                                        setLoader(true)
                                        setSelectedOEM(e.data)
                                        setSelectedLevel({ level: 'oem' })
                                    }}
                                    value={!_.isEmpty(selectedOEM) ? selectedOEM.id : ''}
                                    classNamePrefix={`basic-select`}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                            <div className="col-md-3 pr-0">
                                <ReactSelect
                                    options={levelOptions}
                                    name={"level"}
                                    placeholder={'select level'}
                                    onChange={(e, data) => {
                                        if (e) {
                                            setSelectedLevel({
                                                level: e.value
                                            });
                                        }
                                        else
                                            setSelectedLevel(null);
                                    }}
                                    value={selectedLevel ? selectedLevel.level : 'oem'}
                                    classNamePrefix={`cursor-pointer basic-select`}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                            {
                                (!_.isEmpty(selectedLevel) && selectedLevel.level !== 'oem') ? (<div className="col-md-3 pr-0">
                                    <ReactSelect
                                        options={selectedLevel.level === 'group' ? groupOptions : selectedLevel.level === 'region' ? regionOptions : clientOptions}
                                        name={'levelID'}
                                        placeholder={`select ${selectedLevel.level === 'individual' ? 'client' : selectedLevel.level}`}
                                        onChange={(e, data) => {
                                            if (e) {
                                                setLoader(true)
                                                setSelectedLevel({
                                                    ...selectedLevel,
                                                    levelID: e.value
                                                });
                                            }
                                            else
                                                setSelectedLevel(null);
                                        }}
                                        value={selectedLevel ? selectedLevel.levelID : ''}
                                        classNamePrefix={`basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>) : (<></>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
            loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (_.isEmpty(selectedOEM)) ? (
                <div className="common-table">
                    <div className="text-center p-5">
                        <p>Please select client</p>
                    </div>
                </div>
            ) : !loader && !_.isEmpty(selectedLevel) && ((!selectedLevel || selectedLevel.level === 'oem') || !_.isEmpty(selectedLevel.levelID)) ? (
                <div className="settings-integration-list">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.titandms} /></div>
                                    <div className="integration-title"><a href="#">Titan DMS</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.titan && modules.titan.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('titan', false) }}>
                                            {
                                                btnLoader.titan ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('titan', true) }}>
                                                {
                                                    btnLoader.titan ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }

                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.imotor} /></div>
                                    <div className="integration-title"><a href="#">I-Motor</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.imotor && modules.imotor.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('imotor', false) }}>
                                            {
                                                btnLoader.imotor ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('imotor', true) }}>
                                                {
                                                    btnLoader.imotor ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.messagemedia} /></div>
                                    <div className="integration-title"><a href="#">Message Media</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.messagemedia && modules.messagemedia.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('messagemedia', false) }}>
                                            {
                                                btnLoader.messagemedia ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('messagemedia', true) }}>
                                                {
                                                    btnLoader.messagemedia ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.autogate} /></div>
                                    <div className="integration-title"><a href="#">Auto Gate</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.autogate && modules.autogate.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('autogate', false) }}>
                                            {
                                                btnLoader.autogate ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('autogate', true) }}>
                                                {
                                                    btnLoader.autogate ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.smshub} /></div>
                                    <div className="integration-title"><a href="#">SMS Hub</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.smshub && modules.smshub.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('smshub', false) }}>
                                            {
                                                btnLoader.smshub ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('smshub', true) }}>
                                                {
                                                    btnLoader.smshub ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.cmdotcom} /></div>
                                    <div className="integration-title"><a href="#">CM.COM</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.cmdotcom && modules.cmdotcom.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('cmdotcom', false) }}>
                                            {
                                                btnLoader.cmdotcom ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('cmdotcom', true) }}>
                                                {
                                                    btnLoader.cmdotcom ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.podium} /></div>
                                    <div className="integration-title"><a href="#">Podium</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.podium && modules.podium.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('podium', false) }}>
                                            {
                                                btnLoader.podium ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('podium', true) }}>
                                                {
                                                    btnLoader.podium ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            {/* /////// */}
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.autotrader} /></div>
                                    <div className="integration-title"><a href="#">Auto Trader</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.autotrader && modules.autotrader.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('autotrader', false) }}>
                                            {
                                                btnLoader.autotrader ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('autotrader', true) }}>
                                                {
                                                    btnLoader.autotrader ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.dealersolutions} /></div>
                                    <div className="integration-title"><a href="#">Dealer Solutions</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.dealersolutions && modules.dealersolutions.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('dealersolutions', false) }}>
                                            {
                                                btnLoader.dealersolutions ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('dealersolutions', true) }}>
                                                {
                                                    btnLoader.dealersolutions ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.drivechat} /></div>
                                    <div className="integration-title"><a href="#">Drive Chat</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.drivechat && modules.drivechat.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('drivechat', false) }}>
                                            {
                                                btnLoader.drivechat ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('drivechat', true) }}>
                                                {
                                                    btnLoader.drivechat ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.carsguide} /></div>
                                    <div className="integration-title"><a href="#">Cars Guide</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.carsguide && modules.carsguide.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('carsguide', false) }}>
                                            {
                                                btnLoader.carsguide ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('carsguide', true) }}>
                                                {
                                                    btnLoader.carsguide ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.erapower} /></div>
                                    <div className="integration-title"><a href="#">Era Power</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.erapower && modules.erapower.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('erapower', false) }}>
                                            {
                                                btnLoader.erapower ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('erapower', true) }}>
                                                {
                                                    btnLoader.erapower ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.saleslogs} /></div>
                                    <div className="integration-title"><a href="#">Sales Logs</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.saleslogs && modules.saleslogs.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('saleslogs', false) }}>
                                            {
                                                btnLoader.saleslogs ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('saleslogs', true) }}>
                                                {
                                                    btnLoader.saleslogs ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            {/* <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.ppsr} /></div>
                                    <div className="integration-title"><a href="#">PPSR</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.ppsr && modules.ppsr.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('ppsr', false) }}>
                                            {
                                                btnLoader.ppsr ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('ppsr', true) }}>
                                                {
                                                    btnLoader.ppsr ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div> */}
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.ringcentral} /></div>
                                    <div className="integration-title"><a href="#">Ring Central</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.ringcentral && modules.ringcentral.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('ringcentral', false) }}>
                                            {
                                                btnLoader.ringcentral ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('ringcentral', true) }}>
                                                {
                                                    btnLoader.ringcentral ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.aircall} /></div>
                                    <div className="integration-title"><a href="#">Aircall</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.aircall && modules.aircall.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('aircall', false) }}>
                                            {
                                                btnLoader.aircall ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('aircall', true) }}>
                                                {
                                                    btnLoader.aircall ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.mailchimp} /></div>
                                    <div className="integration-title"><a href="#">Mailchimp</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.mailchimp && modules.mailchimp.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('mailchimp', false) }}>
                                            {
                                                btnLoader.mailchimp ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('mailchimp', true) }}>
                                                {
                                                    btnLoader.mailchimp ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.mailup} /></div>
                                    <div className="integration-title"><a href="#">Mailup</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.mailup && modules.mailup.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('mailup', false) }}>
                                            {
                                                btnLoader.mailup ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('mailup', true) }}>
                                                {
                                                    btnLoader.mailup ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.mailjet} /></div>
                                    <div className="integration-title"><a href="#">Mailjet</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.mailjet && modules.mailjet.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('mailjet', false) }}>
                                            {
                                                btnLoader.mailjet ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('mailjet', true) }}>
                                                {
                                                    btnLoader.mailjet ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.nylas} /></div>
                                    <div className="integration-title"><a href="#">Nylas</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.nylas && modules.nylas.active ? (
                                            <button type="button" className="btn btn-green" onClick={() => {
                                                Swal.fire({
                                                    title: 'Are you sure?',
                                                    text: 'Do you want to deactivate this addon? Deactivating this will disable all active users\' email accounts which are using this integration.',
                                                    icon: 'info',
                                                    showCancelButton: true,
                                                    confirmButtonText: 'Yes',
                                                    cancelButtonText: 'No'
                                                }).then((result) => {
                                                    if (result.value) {
                                                        if (!_.isEmpty(selectedLevel) && selectedLevel.level === 'individual' && selectedLevel.levelID) {
                                                            disableAllNylasEmail(selectedLevel.levelID)
                                                        }
                                                        enableAddons('nylas', false)
                                                    }
                                                })
                                            }}>
                                                {
                                                    btnLoader.nylas ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('nylas', true) }}>
                                                {
                                                    btnLoader.nylas ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            {/* NEWONES */}
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.adtorqueedge} /></div>
                                    <div className="integration-title"><a href="#">AdTorque Edge</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.adtorqueedge && modules.adtorqueedge.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('adtorqueedge', false) }}>
                                            {
                                                btnLoader.adtorqueedge ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('adtorqueedge', true) }}>
                                                {
                                                    btnLoader.adtorqueedge ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.adtorqueedge} /></div>
                                    <div className="integration-title"><a href="#">AdTorque Edge Loan</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.adtorqueedgeloan && modules.adtorqueedgeloan.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('adtorqueedgeloan', false) }}>
                                            {
                                                btnLoader.adtorqueedgeloan ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('adtorqueedgeloan', true) }}>
                                                {
                                                    btnLoader.adtorqueedgeloan ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.appraisal_solutions} /></div>
                                    <div className="integration-title"><a href="#">Appraisal Solutions</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.appraisal_solutions && modules.appraisal_solutions.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('appraisal_solutions', false) }}>
                                            {
                                                btnLoader.appraisal_solutions ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('appraisal_solutions', true) }}>
                                                {
                                                    btnLoader.appraisal_solutions ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.autoit} /></div>
                                    <div className="integration-title"><a href="#">Auto-IT</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.autoit && modules.autoit.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('autoit', false) }}>
                                            {
                                                btnLoader.autoit ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('autoit', true) }}>
                                                {
                                                    btnLoader.autoit ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.pmds} /></div>
                                    <div className="integration-title"><a href="#">PMDS</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.pmds && modules.pmds.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('pmds', false) }}>
                                            {
                                                btnLoader.pmds ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('pmds', true) }}>
                                                {
                                                    btnLoader.pmds ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.keyloop} /></div>
                                    <div className="integration-title"><a href="#">Keyloop</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.keyloop && modules.keyloop.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('keyloop', false) }}>
                                            {
                                                btnLoader.keyloop ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('keyloop', true) }}>
                                                {
                                                    btnLoader.keyloop ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.bentley_cab} /></div>
                                    <div className="integration-title"><a href="#">Bentley Cab</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.bentley_cab && modules.bentley_cab.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('bentley_cab', false) }}>
                                            {
                                                btnLoader.bentley_cab ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('bentley_cab', true) }}>
                                                {
                                                    btnLoader.bentley_cab ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.carsales} /></div>
                                    <div className="integration-title"><a href="#">Car Sales</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.carsales && modules.carsales.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('carsales', false) }}>
                                            {
                                                btnLoader.carsales ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('carsales', true) }}>
                                                {
                                                    btnLoader.carsales ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.conversica} /></div>
                                    <div className="integration-title"><a href="#">Conversica</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.conversica && modules.conversica.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('conversica', false) }}>
                                            {
                                                btnLoader.conversica ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('conversica', true) }}>
                                                {
                                                    btnLoader.conversica ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.creativo} /></div>
                                    <div className="integration-title"><a href="#">Creativo</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.creativo && modules.creativo.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('creativo', false) }}>
                                            {
                                                btnLoader.creativo ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('creativo', true) }}>
                                                {
                                                    btnLoader.creativo ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.dubizzle} /></div>
                                    <div className="integration-title"><a href="#">Dubizzle</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.dubizzle && modules.dubizzle.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('dubizzle', false) }}>
                                            {
                                                btnLoader.dubizzle ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('dubizzle', true) }}>
                                                {
                                                    btnLoader.dubizzle ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.facebook} /></div>
                                    <div className="integration-title"><a href="#">Facebook</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.facebook && modules.facebook.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('facebook', false) }}>
                                            {
                                                btnLoader.facebook ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('facebook', true) }}>
                                                {
                                                    btnLoader.facebook ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.autograb} /></div>
                                    <div className="integration-title"><a href="#">Autograb</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.autograb && modules.autograb.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('autograb', false) }}>
                                            {
                                                btnLoader.autograb ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('autograb', true) }}>
                                                {
                                                    btnLoader.autograb ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.fca_digital} /></div>
                                    <div className="integration-title"><a href="#">FCA Digital</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.fca && modules.fca.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('fca', false) }}>
                                            {
                                                btnLoader.fca ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('fca', true) }}>
                                                {
                                                    btnLoader.fca ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.ferrari_modis} /></div>
                                    <div className="integration-title"><a href="#">Ferrari Modis</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.ferrari_modis && modules.ferrari_modis.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('ferrari_modis', false) }}>
                                            {
                                                btnLoader.ferrari_modis ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('ferrari_modis', true) }}>
                                                {
                                                    btnLoader.ferrari_modis ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.gumtree} /></div>
                                    <div className="integration-title"><a href="#">Gumtree</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.gumtree && modules.gumtree.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('gumtree', false) }}>
                                            {
                                                btnLoader.gumtree ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('gumtree', true) }}>
                                                {
                                                    btnLoader.gumtree ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.heroleads} /></div>
                                    <div className="integration-title"><a href="#">Heroleads</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.heroleads && modules.heroleads.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('heroleads', false) }}>
                                            {
                                                btnLoader.heroleads ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('heroleads', true) }}>
                                                {
                                                    btnLoader.heroleads ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.c2native} /></div>
                                    <div className="integration-title"><a href="#">C2 Native</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.c2native && modules.c2native.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('c2native', false) }}>
                                            {
                                                btnLoader.c2native ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('c2native', true) }}>
                                                {
                                                    btnLoader.c2native ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.mattaki} /></div>
                                    <div className="integration-title"><a href="#">Mattaki</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.mattaki && modules.mattaki.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('mattaki', false) }}>
                                            {
                                                btnLoader.mattaki ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('mattaki', true) }}>
                                                {
                                                    btnLoader.mattaki ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.motorweb} /></div>
                                    <div className="integration-title"><a href="#">MotorWeb</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.motorweb && modules.motorweb.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('motorweb', false) }}>
                                            {
                                                btnLoader.motorweb ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('motorweb', true) }}>
                                                {
                                                    btnLoader.imotor ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.porsche_cap} /></div>
                                    <div className="integration-title"><a href="#">Porsche Cap</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.cap && modules.cap.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('cap', false) }}>
                                            {
                                                btnLoader.cap ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('cap', true) }}>
                                                {
                                                    btnLoader.cap ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.red_book} /></div>
                                    <div className="integration-title"><a href="#">Red Book</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.red_book && modules.red_book.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('red_book', false) }}>
                                            {
                                                btnLoader.red_book ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('red_book', true) }}>
                                                {
                                                    btnLoader.red_book ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.roi} /></div>
                                    <div className="integration-title"><a href="#">ROI</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.roi && modules.roi.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('roi', false) }}>
                                            {
                                                btnLoader.roi ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('roi', true) }}>
                                                {
                                                    btnLoader.roi ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.salesforce} /></div>
                                    <div className="integration-title"><a href="#">Salesforce</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.salesforce && modules.salesforce.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('salesforce', false) }}>
                                            {
                                                btnLoader.salesforce ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('salesforce', true) }}>
                                                {
                                                    btnLoader.salesforce ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.shiftdigital} /></div>
                                    <div className="integration-title"><a href="#">Shift Digital</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.shiftdigital && modules.shiftdigital.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('shiftdigital', false) }}>
                                            {
                                                btnLoader.shiftdigital ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('shiftdigital', true) }}>
                                                {
                                                    btnLoader.shiftdigital ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.sms_broadcast} /></div>
                                    <div className="integration-title"><a href="#">SMS Broadcast</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.sms_broadcast && modules.sms_broadcast.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('sms_broadcast', false) }}>
                                            {
                                                btnLoader.sms_broadcast ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('sms_broadcast', true) }}>
                                                {
                                                    btnLoader.sms_broadcast ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.wildjar} /></div>
                                    <div className="integration-title"><a href="#">WildJar</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.wildjar && modules.wildjar.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('wildjar', false) }}>
                                            {
                                                btnLoader.wildjar ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('wildjar', true) }}>
                                                {
                                                    btnLoader.wildjar ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.dubicars} /></div>
                                    <div className="integration-title"><a href="#">DubiCars</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.dubaicars && modules.dubaicars.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('dubaicars', false) }}>
                                            {
                                                btnLoader.dubaicars ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('dubaicars', true) }}>
                                                {
                                                    btnLoader.dubaicars ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.integrations} /></div>
                                    <div className="integration-title"><a href="#">ProjectCO AVME</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.projectcoavme && modules.projectcoavme.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('projectcoavme', false) }}>
                                            {
                                                btnLoader.projectcoavme ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('projectcoavme', true) }}>
                                                {
                                                    btnLoader.projectcoavme ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.integrations} /></div>
                                    <div className="integration-title"><a href="#">NEXA</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.nexa && modules.nexa.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('nexa', false) }}>
                                            {
                                                btnLoader.nexa ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('nexa', true) }}>
                                                {
                                                    btnLoader.nexa ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.integrations} /></div>
                                    <div className="integration-title"><a href="#">AAP Website</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.aapwebsite && modules.aapwebsite.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('aapwebsite', false) }}>
                                            {
                                                btnLoader.aapwebsite ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('aapwebsite', true) }}>
                                                {
                                                    btnLoader.aapwebsite ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.digitallicence_nsw} /></div>
                                    <div className="integration-title"><a href="#">NSW Digital Driver Licence</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.digitallicence_nsw && modules.digitallicence_nsw.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('digitallicence_nsw', false) }}>
                                            {
                                                btnLoader.digitallicence_nsw ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('digitallicence_nsw', true) }}>
                                                {
                                                    btnLoader.digitallicence_nsw ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.gubagoo} /></div>
                                    <div className="integration-title"><a href="#">Gubagoo</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.gubagoo && modules.gubagoo.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('gubagoo', false) }}>
                                            {
                                                btnLoader.gubagoo ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('gubagoo', true) }}>
                                                {
                                                    btnLoader.gubagoo ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.autoforce} /></div>
                                    <div className="integration-title"><a href="#">AutoForce</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.autoforce && modules.autoforce.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('autoforce', false) }}>
                                            {
                                                btnLoader.autoforce ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('autoforce', true) }}>
                                                {
                                                    btnLoader.autoforce ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.vision6} /></div>
                                    <div className="integration-title"><a href="#">Vision 6</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.vision6 && modules.vision6.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('vision6', false) }}>
                                            {
                                                btnLoader.vision6 ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('vision6', true) }}>
                                                {
                                                    btnLoader.vision6 ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>

                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.driva} style={{ 'maxHeight': '60px', 'maxWidth': '137px' }} /></div>
                                    <div className="integration-title"><a href="#">Driva</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.driva && modules.driva.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('driva', false) }}>
                                            {
                                                btnLoader.driva ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('driva', true) }}>
                                                {
                                                    btnLoader.driva ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>

                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.evalexpert} /></div>
                                    <div className="integration-title"><a href="#">EvalExpert</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.evalexpert && modules.evalexpert.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('evalexpert', false) }}>
                                            {
                                                btnLoader.evalexpert ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('evalexpert', true) }}>
                                                {
                                                    btnLoader.evalexpert ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>

                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.amsme} /></div>
                                    <div className="integration-title"><a href="#">AMS ME</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.amsme && modules.amsme.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('amsme', false) }}>
                                            {
                                                btnLoader.amsme ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('amsme', true) }}>
                                                {
                                                    btnLoader.amsme ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.d365} /></div>
                                    <div className="integration-title"><a href="#">McLaren Stock d365</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.d365 && modules.d365.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('d365', false) }}>
                                            {
                                                btnLoader.d365 ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('d365', true) }}>
                                                {
                                                    btnLoader.d365 ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.justcall} /></div>
                                    <div className="integration-title"><a href="#">Justcall</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.justcall && modules.justcall.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('justcall', false) }}>
                                            {
                                                btnLoader.justcall ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('justcall', true) }}>
                                                {
                                                    btnLoader.justcall ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.impel} /></div>
                                    <div className="integration-title"><a href="#">Impel AI</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.impel && modules.impel.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('impel', false) }}>
                                            {
                                                btnLoader.impel ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('impel', true) }}>
                                                {
                                                    btnLoader.impel ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.tune} /></div>
                                    <div className="integration-title"><a href="#">Revolution</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.revolution && modules.revolution.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('revolution', false) }}>
                                            {
                                                btnLoader.revolution ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('revolution', true) }}>
                                                {
                                                    btnLoader.revolution ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.tss} /></div>
                                    <div className="integration-title"><a href="#">TSS</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.tss && modules.tss.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('tss', false) }}>
                                            {
                                                btnLoader.tss ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('tss', true) }}>
                                                {
                                                    btnLoader.tss ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 margin-btm-30">
                                <div className="integration-box">
                                    <div className="integration-icon"><img alt="" src={images.pandadoc} /></div>
                                    <div className="integration-title"><a href="#">Panda Doc</a></div>
                                    <p className="integration-content">  </p>
                                    {
                                        modules && modules.pandadoc && modules.pandadoc.active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons('pandadoc', false) }}>
                                            {
                                                btnLoader.pandadoc ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Deactivate </button>)
                                            : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons('pandadoc', true) }}>
                                                {
                                                    btnLoader.pandadoc ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Activate </button>)
                                    }
                                </div>
                            </div>
                            {
                                allintegrations.map((rec, i) => {
                                    return <div key={i} className="col-md-4 margin-btm-30">
                                        <div className="integration-box">
                                            <div className="integration-icon"><img alt="" src={images[rec.icon]} /></div>
                                            <div className="integration-title"><a href="#">{rec.name}</a></div>
                                            <p className="integration-content">  </p>
                                            {
                                                modules && modules[rec.value] && modules[rec.value].active ? (<button type="button" className="btn btn-green" onClick={() => { enableAddons(rec.value, false) }}>
                                                    {
                                                        btnLoader[rec.value] ?
                                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                            : (<></>)
                                                    }
                                                    Deactivate </button>)
                                                    : (<button type="button" className="btn btn-primary" onClick={() => { enableAddons(rec.value, true) }}>
                                                        {
                                                            btnLoader[rec.value] ?
                                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                : (<></>)
                                                        }
                                                        Activate </button>)
                                            }
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            ) : (
                <div style={{ height: '55vh' }}>
                    <div className='spinner-loader h-100'>
                        <div className='d-flex h-100 justify-content-center align-items-center text-primary' style={{ flexDirection: 'column' }}>
                            <div className="no-data-img"> <img src={images.nodata} width="60" height="60" alt="" /></div>
                            <div className="no-data-txt mt-2">
                                <p>{`Please select ${selectedLevel ? (selectedLevel.level === 'individual' ? 'client' : selectedLevel.level) : ''}`}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    </>);
}

export default IntegrationAddOns;