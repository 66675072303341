import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import moment from 'moment'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { ContentOverlay, TableView, ReactSelect, PopUpModal } from "../../../../components";
import { queueSettingsFields } from './viewModel'
import Translate from '../../../../constants/translate';
import { TemplateContext } from '../templateContext'
import CommonHelper from '../../../../services/common';
import AddQueueSettings from './addQueueSettings'

const QueueSettings = (props) => {

    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 259) })
    const [loader, setLoader] = useState(true)
    const [searchText, setSearchText] = useState('');
    const [selectedMake, setSelectedMake] = useState(null)
    const [selectedClient, setSelectedClient] = useState([])
    const [clientOptions, setClientOptions] = useState([]);
    const [departments, setDepartments] = useState([])
    const [selectedDept, setSelectedDept] = useState(null);
    const [popheader, setPopheader] = useState('');
    const [showModel, setShowModel] = useState(false)
    const { clients } = useContext(TemplateContext);


    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
                windowHeight: (window.innerHeight - 259)
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        if (!_.isEmpty(clients)) {
            let _clients = [];
            _.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(_client => {
                _clients.push({ label: _client.name, value: _client.documentID, data: _client, active: _.isBoolean(_client.active) ? _client.active : true, })
            });
            setClientOptions(_clients)
            if (_.isEmpty(selectedClient)) {
                setSelectedClient(_.find(clients, { 'documentID': props.dealersettings.client.id }))
            }
        }
    }, [clients])

    useEffect(() => {
        if (_.isEmpty(selectedClient))
            return
        setLoader(true)
        const queueCollectionSnap = window.firebase.firestore().collection(`clients/${selectedClient.documentID}/queues`)
            .onSnapshot(querySnapshot => {
                let _dept = [];
                if (querySnapshot.docs.length > 0) {
                    props.dealersettings.client.settings.departments.forEach(rec => {
                        if (querySnapshot.docs.some(e => e.id === rec.value)) {
                            let qDoc = querySnapshot.docs.find(e => e.id === rec.value).data();
                            _dept.push({
                                name: rec.name,
                                isQueue: true,
                                strqTimeout: qDoc.qTimer === false ? 'Timer Disabled' : qDoc.qTimeout > 0 ? `${qDoc.qTimeout} Minutes` : '',
                                updatedOn: qDoc.modifiedDate ? moment.unix(qDoc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '',
                                updatedBy: CommonHelper.getUserNamebyId(props.clientUsers, qDoc.modifiedBy),
                                documentID: rec.value,
                                qTimer: qDoc.qTimer,
                                qTimeout: qDoc.qTimer ? qDoc.qTimeout : '',
                                users: qDoc.users
                            })
                        }
                        else {
                            _dept.push({
                                name: rec.name,
                                isQueue: false,
                                strqTimeout: '',
                                updatedOn: '',
                                documentID: rec.value,
                                users: []
                            })
                        }
                    })
                }
                else {
                    props.dealersettings.client.settings.departments.forEach(rec => {
                        _dept.push({
                            name: rec.name,
                            isQueue: false,
                            strqTimeout: '',
                            updatedOn: '',
                            documentID: rec.value,
                            users: []
                        })
                    })
                }
                setDepartments(_dept);
                setLoader(false)
            })

        return () => {
            queueCollectionSnap && queueCollectionSnap()
        }
    }, [selectedClient])


    const handleClientdetailsClick = (e, id, data) => {
        e.preventDefault();
        hadleEditSettings(data)
    }

    const handleActionClick = (id, data) => {

        return (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
            hadleEditSettings(data)
        }}>
            <i className="ico icon-edit"></i>
        </div>)
    }

    const hadleEditSettings = (data) => {
        setSelectedDept(data)
        setPopheader('Queue Settings')
        setShowModel(true)
    }

    const handleSwitchChange = (id, checked, data) => {
        if (_.isEmpty(data))
            return

        if (checked) {
            setSelectedDept(data)
            setPopheader('Queue Settings')
            setShowModel(true)
            return
        }

        setDepartments(_.map(departments, function (obj) {
            return obj.documentID === data.documentID ? { ...obj, 'isQueue': checked } : { ...obj }
        }))


        window.firebase.firestore().doc(`clients/${selectedClient.documentID}/queues/${data.documentID}`).delete()
            .then(snapshot => {
                toast.notify('Queue settings updated successfully', {
                    duration: 2000
                })
            })
            .catch(error => {
                console.log(error);
                Swal.fire(error, '', 'error')
            })
    }

    const handleModelClose = () => {
        setSelectedDept(null)
        setShowModel(false)
    }

    return (<>
        <h2><Translate text={'Queue Settings'} /></h2>
        <div className='divider-line'></div>
        <div className="mt-4">
            <div className="settings-head form-style">
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-3 pr-0">
                                <ReactSelect
                                    options={clientOptions}
                                    name={"client"}
                                    placeholder={'select client'}
                                    onChange={(e) => {
                                        setLoader(true)
                                        setSelectedClient(e.data)
                                    }}
                                    value={!_.isEmpty(selectedClient) ? selectedClient.documentID : ''}
                                    classNamePrefix={`basic-select`}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                            <div className="filter-search search-icon col-md-3">
                                <input placeholder="search..." aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                    departments.length > 0 ? (
                        <div className="common-table">
                            <TableView
                                datalist={departments.filter(({ name }) =>
                                (!_.isEmpty(searchText) ? (
                                    (!_.isEmpty(name) ? name.toLowerCase().includes(searchText.toLowerCase()) : false)
                                ) : true)
                                )}
                                height={windowSize.windowHeight}
                                width={windowSize.windowWidth}
                                columns={queueSettingsFields}
                                dynamicFields={queueSettingsFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                handleActionClick={handleActionClick}
                                handleRowSingleClick={handleClientdetailsClick}
                                isSettings={false}
                                isReorderable={false}
                                handleSwitchChange={handleSwitchChange}
                                isSorting={true}
                                isTranslate={true}
                                dealersettings={props.dealersettings}
                            />
                        </div>
                    ) : (
                        <div className="common-table">
                            <div className="text-center p-5">
                                <p><Translate text={'No departments found.'} /></p>
                            </div>
                        </div>
                    )
            }
        </div>
        <PopUpModal show={showModel}>
            <AddQueueSettings
                {...props}
                show={showModel}
                title={popheader}
                handleClose={handleModelClose}
                selectedClient={selectedClient}
                department={selectedDept}
            />
        </PopUpModal>
    </>);
}

export default QueueSettings;