import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import CommonHelper from '../services/common';


am4core.useTheme(am4themes_animated);

const BarChart = (props) => {

    const [chartData, setChart] = useState(null)

    useEffect(() => {
        if (_.isEmpty(chartData))
            return

        if (JSON.stringify(chartData.data) !== JSON.stringify(props.data))
            chartData.data = props.data
        am4core.array.each(chartData.data, (item) => {
            if (_.find(props.data, { value: item.value }))
                item.count = _.find(props.data, { value: item.value }).count
        })

        if (props.calculationType === 'duration') {
            chartData.yAxes.values[0].baseUnit = "second";
        }
        else {
            chartData.yAxes.values[0].min = 0;
            let _maxValue = 0;
            props.columns.forEach(col => {
                if (_.maxBy(props.data, col) && _maxValue < _.maxBy(props.data, col)[col])
                    _maxValue = _.maxBy(props.data, col)[col];
            })
            if (props.sameYAxis === true) {
                props.lineColumns.forEach(col => {
                    if (_.maxBy(props.data, col) && _maxValue < _.maxBy(props.data, col)[col])
                        _maxValue = _.maxBy(props.data, col)[col];
                })
            }
            if (_maxValue > 0 && _maxValue < 10) {
                if (Math.ceil(_maxValue / 5) * 5 === _maxValue) {
                    chartData.yAxes.values[0].max = _maxValue + 5;
                }
                else {
                    chartData.yAxes.values[0].max = Math.ceil(_maxValue / 5) * 5;
                }
                chartData.yAxes.values[0].strictMinMax = true;
            }
            else if (_maxValue >= 10) {
                chartData.yAxes.values[0].max = Math.ceil(_maxValue / 4) * 5;
                chartData.yAxes.values[0].strictMinMax = true;
            }
            else {
                chartData.yAxes.values[0].max = 5;
                chartData.yAxes.values[0].strictMinMax = true;
            }
        }
        chartData.invalidateRawData();

    }, [props.data])

    useEffect(() => {
        if (_.isEmpty(chartData) || _.isEmpty(props.title))
            return
        if (chartData?.chartContainer?.children?.values?.[0]?.children?.values?.[0]?.text)
            chartData.chartContainer.children.values[0].children.values[0].text = props.title
    }, [props.title])

    useEffect(() => {
        if (_.isEmpty(chartData))
            return

        chartData.series.clear()
        chartData.invalidateData();
        props.columns.forEach(rec => {
            createSeries(rec, CommonHelper.getNameByValue(props.dynamicColumns, rec), chartData);
        })
        // if (props.columns && props.dynamicColumns) {
        //     chartData.chartContainer.children.removeIndex(chartData.chartContainer.children.values.length - 1)
        //     createLegend(chartData)
        // }


    }, [props.columns.join(',')])

    useEffect(() => {
        if (_.isEmpty(chartData))
            return
        props.setChartData && props.setChartData(chartData)
    }, [chartData])


    useEffect(() => {
        let chart = am4core.create(props.id, am4charts.XYChart);
        chart.logo.disabled = true;
        if (props.title) {
            let topContainer = chart.chartContainer.createChild(am4core.Container);
            topContainer.layout = "absolute";
            topContainer.toBack();
            topContainer.paddingBottom = 15;
            topContainer.width = am4core.percent(100);

            let label = topContainer.createChild(am4core.Label);
            label.text = props.title;
            label.fontSize = 16;
            label.fontWeight = "bold";
            label.align = "center";
        }


        //create legend
        if (props.dynColor === true) {
            let legend = createLegend(chart)
            if (props.hideLegend) {
                legend.disabled = true;
            }
        }
        else {
            chart.legend = new am4charts.Legend()
            chart.legend.marginTop = 20
            chart.legend.labels.template.maxWidth = 95;
            if (props.hideLegend) {
                chart.legend.disabled = true;
            }
        }

        chart.colors.step = 2;
        let xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
        xAxis.dataFields.category = props.xCategory ? props.xCategory : 'name'
        xAxis.renderer.cellStartLocation = 0.1
        xAxis.renderer.cellEndLocation = 0.9
        xAxis.renderer.grid.template.location = 0;
        xAxis.cursorTooltipEnabled = false;
        if (props.removeGridLines) xAxis.renderer.grid.template.strokeOpacity = 0; // Remove vertical grid lines
        if (props.minGridDistance) {
            xAxis.renderer.minGridDistance = props.minGridDistance;
        }
        else {
            xAxis.renderer.minGridDistance = 30;
        }

        var label = xAxis.renderer.labels.template;
        label.wrap = true;
        label.fontSize = 12
        if (props.labelMaxWidth) {
            label.maxWidth = props.labelMaxWidth;
        }
        else {
            label.maxWidth = 120;
        }

        let yAxis = null;
        if (props.calculationType === 'duration') {
            yAxis = chart.yAxes.push(new am4charts.DurationAxis());
            yAxis.baseUnit = "second";
        }
        else {
            yAxis = chart.yAxes.push(new am4charts.ValueAxis());
            yAxis.min = 0;
            let _maxValue = 0;
            props.columns.forEach(col => {
                if (_.maxBy(props.data, col) && _maxValue < _.maxBy(props.data, col)[col])
                    _maxValue = _.maxBy(props.data, col)[col];
            })
            if (props.sameYAxis === true) {
                props.lineColumns.forEach(col => {
                    if (_.maxBy(props.data, col) && _maxValue < _.maxBy(props.data, col)[col])
                        _maxValue = _.maxBy(props.data, col)[col];
                })
            }
            if (_maxValue > 0 && _maxValue < 10) {
                if (Math.ceil(_maxValue / 5) * 5 === _maxValue) {
                    yAxis.max = _maxValue + 5;
                }
                else {
                    yAxis.max = Math.ceil(_maxValue / 5) * 5;
                }
                yAxis.strictMinMax = true;
            }
            else if (_maxValue >= 10) {
                yAxis.max = Math.ceil(_maxValue / 4) * 5;
                yAxis.strictMinMax = true;
            }
            else {
                yAxis.max = 5;
                yAxis.strictMinMax = true;
            }
            if (props.removeGridLines) yAxis.renderer.grid.template.strokeOpacity = 0; // Remove horizontal grid lines
        }

        chart.data = props.data;
        const snapToSeries = [];
        props.columns.forEach(rec => {
            const series = createSeries(rec, CommonHelper.getNameByValue(props.dynamicColumns, rec), chart);
            snapToSeries.push(series);
        })

        if (props.lineColumns) {
            let lineAxis = chart.yAxes.push(new am4charts.ValueAxis());
            lineAxis.renderer.opposite = true;
            lineAxis.min = 0;
            lineAxis.extraMax = 0.1;

            props.lineColumns.forEach(rec => {
                createLineSeries(rec, CommonHelper.getNameByValue(props.dynamicColumns, rec), chart, (props.sameYAxis === true ? yAxis : lineAxis));
            })
        }


        // Add chart cursor
        chart.cursor = new am4charts.XYCursor();
        //chart.cursor.snapToSeries = snapToSeries;
        // chart.cursor.behavior = "zoomX";
        // chart.colors.list = props.columns.map(rec => {
        //     return am4core.color(_.find(props.dynamicColumns,{value : rec}).color)
        // });


        const barchart = chart;
        setChart(chart)
        return () => {
            barchart && barchart.dispose()
        }
    }, [])

    const createSeries = (value, name, chart) => {
        var series = chart.series.push(new am4charts.ColumnSeries())
        series.dataFields.valueY = value
        series.dataFields.categoryX = props.xCategory ? props.xCategory : 'name'
        series.name = name
        series.labelText = name
        if (props.calculationType === 'duration')
            series.tooltipText = `{${props.xCategory ? props.xCategory : 'name'}}\n {labelText}: {valueY.formatDuration()}`;
        else if (Boolean(props.hideTooltip) === false)
            series.tooltipText = `{${props.xCategory ? props.xCategory : 'name'}}\n {labelText}: {valueY}${props.calculationType === 'percentage' ? '%' : ''}`;

        series.strokeWidth = 0;
        if (props.columnsStacked) series.stacked = true;
        //series.legendSettings.valueText = "{valueY}";


        //series.events.on("hidden", arrangeColumns);
        //series.events.on("shown", arrangeColumns);
        if (!Boolean(props.hideBullet)) {
            var bullet = series.bullets.push(new am4charts.LabelBullet())
            bullet.interactionsEnabled = false;
            bullet.label.truncate = false;
            bullet.dy = -10;
            if (props.calculationType === 'duration')
                bullet.label.text = `{valueY.formatDuration()}`;
            else
                bullet.label.text = `{valueY}${props.calculationType === 'percentage' ? '%' : ''}`;


            if (props.lblWhitecolor) {
                bullet.label.fill = am4core.color('#ffffff')
                bullet.locationY = 0.9; // Center of the column
                //bullet.label.dy = 0.5;// Align in the middle of the column
            }
            else
                bullet.label.fill = am4core.color('#000000')

        }
        // series.columns.template.events.on("sizechanged", function (ev) {
        //     if (ev.target.dataItem && ev.target.dataItem.bullets) {
        //         var width = ev.target.pixelWidth;
        //         if (width < 20) {
        //             //bullet.locationY = 0;
        //             bullet.dy = -10;
        //             bullet.label.fill = am4core.color('#000000')
        //         }
        //     }
        // });

        series.columns.template.events.on("doublehit", function (ev) {
            props.handleShowEnquiryList(ev.target.dataItem.component.dataFields.valueY, ev.target.dataItem.dataContext);
        }, this);

        if (props.dynColor === true) {
            series.columns.template.adapter.add("fill", function (fill, target) {
                var col = props.dynamicColumns.find(item => item.value === target.dataItem.component.dataFields.valueY)
                if (_.isEmpty(col)) {
                    col = props.dynamicColumns.find(item => item.name === target.dataItem.categoryX)
                }
                if (col) {
                    return am4core.color(col.color);
                }
                else {
                    return fill;
                }
            });

            series.columns.template.adapter.add("stroke", function (stroke, target) {
                var col = props.dynamicColumns.find(item => item.value === target.dataItem.component.dataFields.valueY)
                if (_.isEmpty(col)) {
                    col = props.dynamicColumns.find(item => item.name === target.dataItem.categoryX)
                }
                if (col?.borderColor) {
                    series.columns.template.strokeWidth = 1;
                    //series.columns.template.stroke = am4core.color("red")
                    series.columns.template.strokeOpacity = 1;
                    return am4core.color(col.borderColor);
                }
                else {
                    return stroke;
                }
            });
        }

        // Set mouse style on hover
        series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

        return series;
    }

    const createLineSeries = (value, name, chart, lineAxis) => {
        let series1 = chart.series.push(new am4charts.LineSeries());
        series1.dataFields.valueY = value;
        series1.yAxis = lineAxis;
        series1.dataFields.categoryX = props.xCategory ? props.xCategory : "name";
        series1.name = name;
        series1.labelText = name;
        // series1.labels.template.text = "{value}";
        // series1.labels.template.radius = am4core.percent(-30);
        // series1.labels.template.fill = am4core.color("black");
        let _calculationType = props.calculationLineType ? props.calculationLineType : props.calculationType
        let bullet = new am4charts.CircleBullet();
        bullet.strokeWidth = 1;
        bullet.width = 4;
        bullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        bullet.tooltipText = `{labelText} : {valueY}${_calculationType === 'percentage' ? '%' : ''}`;
        series1.bullets.push(bullet);
        if (!Boolean(props.hideBullet)) {
            var labelBullet = new am4charts.LabelBullet();
            if (_calculationType === 'duration')
                labelBullet.label.text = `{valueY.formatDuration()}`;
            else
                labelBullet.label.text = `{valueY}${_calculationType === 'percentage' ? '%' : ''}`;
            labelBullet.label.dy = -15;
            series1.bullets.push(labelBullet);
        }

        if (_calculationType === 'duration') {
            series1.tooltipText = `{labelText} : {valueY.formatDuration()}`;
            series1.legendSettings.valueText = "{valueY.formatDuration()}";
        }
        else if (_calculationType === 'revenue') {
            if (value === 'approvedamount')
                series1.tooltipText = `{labelText} : {approvedcount} \n Total Cost: ${props.currencySymbol}{valueY}`;
            else
                series1.tooltipText = `{labelText} : {declinedcount} \n Total Cost: ${props.currencySymbol}{valueY}`;

            series1.legendSettings.valueText = "{valueY}";
        }
        else {
            series1.tooltipText = `{labelText} : {valueY}${_calculationType === 'percentage' ? '%' : ''}`;
            series1.legendSettings.valueText = "{valueY}";
        }
        // series1.visible = false;
        if (value === 'target') {
            series1.stroke = am4core.color('#ff0000');
            series1.fill = am4core.color('#ff0000');
        }
        else {
            var col = props.dynamicColumns && props.dynamicColumns.find(item => item.value === value);
            if (col) {
                series1.stroke = am4core.color(col.color);
                series1.fill = am4core.color(col.color);
            }
        }

        let hs1 = series1.segments.template.states.create("hover")
        hs1.properties.strokeWidth = 3;
        series1.segments.template.strokeWidth = 1;
        series1.strokeWidth = 3;

        bullet.events.on("doublehit", function (ev) {
            props.handleShowEnquiryList(ev.target.dataItem.component.dataFields.valueY, ev.target.dataItem.dataContext);
        }, this);

        if (props.dynamicColumns.find(item => item.value === value)?.dasharray) {
            series1.strokeDasharray = props.dynamicColumns.find(item => item.value === value)?.dasharray;
        }
        return series1;
    }


    const createLegend = (chart) => {
        var legend = new am4charts.Legend()
        legend.parent = chart.chartContainer;
        legend.chartData = chart;
        legend.itemContainers.template.togglable = true;
        legend.marginTop = 20;


        legend.useDefaultMarker = true;
        let marker = legend.markers.template.children.getIndex(0);
        marker.strokeWidth = 1;
        marker.strokeOpacity = 1;
        marker.adapter.add("innerWidth", function (width, target) {
            if (!target.dataItem) {
                return width;
            }
            var settings = target.dataItem.dataContext.dummyData;
            return settings && settings.width !== undefined ? settings.width : width;
        });
        marker.adapter.add("innerHeight", function (height, target) {
            if (!target.dataItem) {
                return height;
            }
            var settings = target.dataItem.dataContext.dummyData;
            return settings && settings.height !== undefined ? settings.height : height;
        });
        marker.adapter.add("paddingTop", function (top, target) {
            if (!target.dataItem) {
                return top;
            }
            var settings = target.dataItem.dataContext.dummyData;
            return settings && settings.top !== undefined ? settings.top : top;
        });

        if (props.columns && props.dynamicColumns) {

            legend.data = props.columns.map(rec => {
                return {
                    name: props.dynamicColumns.find(val => val.value === rec) ? CommonHelper.getNameByValue(props.dynamicColumns, rec) : 'In Active',
                    fill: CommonHelper.getCustomValue(props.dynamicColumns, rec, 'color', 'value'),
                    bColor: CommonHelper.getCustomValue(props.dynamicColumns, rec, 'borderColor', 'value'),
                    value: rec,
                    dummyData: CommonHelper.getCustomValue(props.dynamicColumns, rec, 'dummyData', 'value')
                }
            })
        }

        if (props.lineColumns && props.dynamicColumns) {
            legend.data = [
                ...legend.data,
                ...props.lineColumns.map(rec => {
                    return {
                        name: props.dynamicColumns.find(val => val.value === rec) ? CommonHelper.getNameByValue(props.dynamicColumns, rec) : 'In Active',
                        fill: CommonHelper.getCustomValue(props.dynamicColumns, rec, 'color', 'value'),
                        value: rec,
                        dummyData: CommonHelper.getCustomValue(props.dynamicColumns, rec, 'dummyData', 'value')
                    }
                })];
        }

        legend.itemContainers.template.events.on("hit", function (ev) {
            if (_.isEmpty(ev.target.parent.chartData))
                return
            var series = ev.target.parent.chartData.series.getIndex(_.union(props.columns, props.lineColumns).indexOf(ev.target.dataItem.dataContext.value));
            if (_.isEmpty(series))
                return
            if (series.isHiding || series.isHidden) {
                series.show();
            }
            else {
                series.hide();
            }
        });

        return legend
    }

    //https://www.amcharts.com/docs/v4/tutorials/chart-legend-in-an-external-container/
    return (
        <div id={props.id} style={{ width: "100%", height: props.height }}></div>
    );
}

export default BarChart;