import React, { useState, useEffect } from 'react';
import images, { default as _images } from '../../../images';
import { ContentOverlay, InputCheckBox } from '../../../components';
import Swal from 'sweetalert2';
import _ from 'lodash'
import CommonHelper from '../../../services/common';
const ContactUnsubscribe = (props) => {

    const [contact, setContact] = useState();
    const [loader, setLoader] = useState(true);
    const [btnLoader, setBtnLoader] = useState();
    const [firestoreDB, setFirestoreDB] = useState();
    const [projectId, setProjectId] = useState()
    const [unsub, setunsub] = useState(false)
    const [client, setclient] = useState(false)
    const [prefall, setprefall] = useState(false)

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let _email = params.get('email');

    const pref = [
        { value: 'optinPhone', name: 'Opt In Phone' },
        //{ value: 'optinSMS', name: 'Opt In SMS' },
        //{ value: 'optinEmail', name: 'Opt In Email' },
        { value: 'optinPost', name: 'Opt In Post' },
        { value: 'marketingOptinEmail', name: 'Opt In Marketing Email' },
        { value: 'marketingOptinSMS', name: 'Opt In Marketing SMS' }
    ]

    useEffect(() => {
        if (!_.isEmpty(_email)) {
            window.firebase.firestore().doc(`clients/${props.contactid}`).get()
                .then(doc => {
                    if (doc?.data()?.projectId) {
                        setFirestoreDB(window.firebase2)
                        setProjectId(window.secondaryProjectId);
                    }
                    else {
                        setFirestoreDB(window.firebase)
                    }
                })
        } else {
            window.firebase.firestore().doc(`contactDetails/${props.contactid}`).get()
                .then(doc => {
                    if (doc.exists) {
                        setFirestoreDB(window.firebase)
                    }
                    else {
                        setFirestoreDB(window.firebase2)
                        setProjectId(window.secondaryProjectId);
                    }
                })
        }

    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return
        if (!_.isEmpty(_email)) {
            firestoreDB.firestore().collection(`contactDetails`)
                .where('clientID', '==', props.contactid)
                .where('isDeleted', '==', false)
                .where('email', '==', _email)
                .get()
                .then((doc) => {
                    if (doc.size >= 1) {
                        setContact({
                            ...doc.docs[0].data(),
                            id: doc.docs[0].id
                        })
                    } else {
                        setunsub(true)
                    }
                    setLoader(false)
                })
        } else {
            firestoreDB.firestore().doc(`contactDetails/${props.contactid}`).get()
                .then((doc) => {
                    if (doc.exists) {
                        setContact({
                            ...doc.data(),
                            id: doc.id
                        })
                    }
                    else {
                        setunsub(true)
                    }
                    setLoader(false)
                })
        }
    }, [firestoreDB])

    useEffect(() => {
        if (_.isEmpty(contact?.clientID))
            return

        window.firebase.firestore().doc(`clients/${contact.clientID}`).get()
            .then(doc => {
                if (doc?.data()) {
                    setclient(doc?.data());
                }
            })
    }, [contact?.clientID])

    const handleUnsubscribe = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to unsubscribe.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                setBtnLoader(true)
                try {
                    const unsubscribeContact = window.firebase.functions().httpsCallable('contact-unsubscribe');
                    unsubscribeContact({
                        contactID: contact.id,
                        contact: _.pick(contact, pref.map(r => { return r.value })),
                        projectId: projectId ? projectId : null
                    }).then((res) => {
                        setBtnLoader(false);
                        setunsub(true)
                        Swal.fire(CommonHelper.showLocale(props, 'You have successfully unsubscribed'), '', 'success')
                    });
                }
                catch (error) {
                    setBtnLoader(false);
                    setunsub(true)
                    Swal.fire(CommonHelper.showLocale(props, 'You have successfully unsubscribed'), '', 'success')
                }
            }
        })

    }

    const handleCheckChange = (e) => {
        setContact({
            ...contact,
            [e.target.name]: e.target.checked
        })
    }

    return loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
        !unsub ? (
            <div className="unsubscribe-wrapper">
                {/* <div className="unsubscribe-box-logo">
                    <img src={images.amswhite} />
                </div> */}
                <div className="unsubscribe-box">
                    {
                        client ? (
                            <div className="unsubscribe-box-logo">
                                {
                                    client.logoURL ? (
                                        <img src={client.logoURL} height="70" />
                                    ) : (<div className="dealer">{client.name}</div>)
                                }

                            </div>
                        ) : (<></>)
                    }

                    <div className="unsubscribe-img">
                        <img src={_images.unsubscribe} alt="" width="100" /> </div>
                    <div className="unsubscribe-header">
                        <h2>Your current preferences</h2>
                        <h3>Please select your preferences for receiving marketing communications{client.name ? ` from ${client.name}` : ''}, you can opt in or out at any time..</h3>
                        <div className="unsubscribe-list">
                            <ul>
                                <li className='all-optout'> <span className="switch switch-sm mr-2">
                                    <InputCheckBox
                                        id={`pref-all`}
                                        className="switch"
                                        name={'all'}
                                        checked={prefall}
                                        onChange={(e) => {
                                            setprefall(e.target.checked);
                                            if (e.target.checked) {
                                                let _cont = {};
                                                pref.forEach((r) => {
                                                    _cont = {
                                                        ..._cont,
                                                        [r.value]: false
                                                    }
                                                })
                                                setContact({
                                                    ...contact,
                                                    ..._cont
                                                })
                                            }
                                        }} />
                                    <label htmlFor={`pref-all`}></label>
                                </span>{'Opt Out All'}</li>
                                {
                                    pref.map((r, i) => {
                                        return <li> <span className="switch switch-sm mr-2">
                                            <InputCheckBox
                                                id={`pref-${r.value}`}
                                                className="switch"
                                                name={r.value}
                                                checked={_.isEmpty(contact?.[r.value]?.toString()) ? true : contact?.[r.value]}
                                                onChange={handleCheckChange} />
                                            <label htmlFor={`pref-${r.value}`}></label>
                                        </span>{r.name}</li>
                                    })
                                }
                            </ul>

                        </div>
                    </div>
                    <div className="unsubscribe-button-wrap"><a className="unsubscribe-button" href="#" onClick={(e) => {
                        e.preventDefault();
                        handleUnsubscribe();

                    }}>{
                            btnLoader ? (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }<span>Update My Preferences</span></a></div>
                    {
                        client?.unsubscribeDisclaimer ? (
                            <div className="unsubscribe-footer-disclaimer">
                                <div dangerouslySetInnerHTML={{ __html: client.unsubscribeDisclaimer }}
                                ></div>
                            </div>
                        ) : (<></>)
                    }
                    {
                        client ? (
                            <>
                                <div className="unsubscribe-footer-wrap">{client.name}</div>
                                <div className="unsubscribe-footer-wrap-sub">
                                    <p>
                                        <span style={{ paddingLeft: 0 }}>
                                            {client.email ? <>Email: <span style={{ paddingLeft: 0 }} >{client.email}</span>&nbsp;&nbsp;</> : <></>}
                                            {client.phone ? <>Tel: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                        </span>
                                    </p>
                                </div>
                            </>
                        ) : (<></>)
                    }
                </div>


            </div>
        ) : (
            <div className="unsubscribe-wrapper">
                <div className="unsubscribe-box">
                    {
                        client ? (
                            <div className="unsubscribe-box-logo">
                                {
                                    client.logoURL ? (
                                        <img src={client.logoURL} height="70" />
                                    ) : (<div className="dealer">{client.name}</div>)
                                }

                            </div>
                        ) : (<></>)
                    }
                    <div className="unsubscribe-img">
                        <img src={_images.unsubscribed} alt="" width="100" /> </div>

                    <div className="unsubscribed">
                        <h2>You've been Unsubscribed</h2>
                    </div>

                </div>
            </div>
        )

}

export default ContactUnsubscribe;