import React from 'react'
import Translate from '../../constants/translate';

export const objFleetPro = {
    documentID: null,
    projectId: null,
    oemID: null,
    regionID: null,
    subregionID: null,
    groupID: null,

    make: '',
    model: '',
    modelDescription: '',
    type: '',
    group: '',
    year: '',
    regNo: '',
    vinNo: '',
    extColor: '',
    intColor: '',
    mileage: '',
    status: '',
    country: '',
    category: '',
    defleetDate: null,
    dynamicFields: {},
    images: [],

    fleetOwner: '',
    addedBy: '',
    modifiedBy: '',
    addedDate: null,
    modifiedDate: null,
    modifiedFrom: 'web',
    addedFrom: 'web',
    isDeleted: false,
};

export const objFleetProAssign = {
    documentID: null,
    projectId: null,
    fleetProID: null,
    fleetPro: null,
    oemID: null,
    eventsProID: null,
    clientID: null,
    clientName:null,

    status: '',
    assignType: '',
    methodOfTransport: '',
    transportCompany: '',
    name: '',
    companyName: '',
    email: '',
    phone: '',
    address: '',
    eventName: '',
    location: '',
    typeOfActivity: '',

    recipientName: '',
    recipientEmail: '',
    recipientPhone: '',
    recipientAddress: '',
    recipientLicenseFront: '',
    recipientLicenseBack: '',
    recipientLicenseNo: '',
    recipientLicenseExpiry: null,   

    purpose: '',
    eventType: '',

    startDate: null,
    endDate: null,
    departureDate: null,
    expectedArrivalDate: null,
    expectedReturnDate: null,
    malTechnicalSupportReq: false,

    inspection: [],
    notes: '',
    requestNotes: '',
    odometer: 0,
    terms: {},

    preInspection: [],
    preNotes: '',
    preOdometer: 0,
    preSignature: '',


    postInspection: [],
    postNotes: '',
    postOdometer: 0,
    postSignature: '',

    requestedBy: '',
    assignBy: '',
    approvedBy: '',
    receivedBy: '',
    releasedBy: '',
    receivedByName:'',
    releasedByName:'',

    requestedDate: '',
    assignDate: null,
    approvedDate: null,
    receivedDate: null,
    releasedDate: null,

    addedBy: '',
    modifiedBy: '',
    addedDate: null,
    modifiedDate: null,
    modifiedFrom: 'web',
    addedFrom: 'web',
    isDeleted: false,
};

export const objFleetProBasic = {
    documentID: null,
    oemID: null,
    regionID: null,
    subregionID: null,
    groupID: null,
    defleetDate: null,
    make: '',
    model: '',
    type: '',
    group: '',
    year: '',
    regNo: '',
    vinNo: '',
    extColor: '',
    intColor: '',
    mileage: '',
    status: '',
    country: '',
    category: '',
    fleetOwner: '',
    images: [],
    dynamicFields: {},
    addedBy: '',
    modifiedBy: '',
    addedDate: null,
    modifiedDate: null,
    modifiedFrom: 'web',
    addedFrom: 'web',
    isDeleted: false,
};

export const event_Types = [
    { active: true, value: 'external', label: "External" },
    { active: true, value: 'internal', label: 'Internal' },
]


export const allFleetProFields = [
    {
        name: 'Make',
        value: 'make',
        flex: 1,
        default: true,
        nestedText: 'dealersName',
        nestedTextTitle: 'Region/Subregion/Group',
    },
    {
        name: 'Model',
        value: 'model',
        flex: 1,
        default: true
    },
    {
        name: 'Vehicle Group',
        value: 'group',
        flex: 1,

    },
    {
        name: 'Year',
        value: 'year',
        flex: 0.5,
        default: true
    },

    {
        name: 'Reg #',
        value: 'regNo',
        flex: 1,
        default: true
    },
    {
        name: 'Category',
        value: 'category',
        flex: 1,
        default: true
    },

    {
        name: 'Status',
        value: 'status',
        elementName: 'statusName',
        subText: 'statusUpdatedBy',
        subTextTitle: 'Status Updated By',
        //nestedText: 'statusModifiedBy',
        //nestedTextTitle: 'Status Modified By',
        flex: 1,
        default: true
    },
    {
        name: 'VIN #',
        value: 'vinNo',
        flex: 1
    },
    {
        name: 'Model Description',
        value: 'modelDescription',
        flex: 1,
        limit: 60
    },
    {
        name: 'Fleet Owner',
        value: 'owner',
        flex: 1
    },
    {
        name: 'Country',
        value: 'country',
        flex: 1
    },
    {
        name: 'Vehicle Type',
        value: 'type',
        flex: 1
    },
    {
        name: 'Exterior Color',
        value: 'extColor',
        flex: 1,
        limit: 60
    },
    {
        name: 'Interior Color',
        value: 'intColor',
        flex: 1,
        limit: 60
    },
    {
        name: 'Mileage',
        value: 'mileage',
        flex: 1
    },
    {
        name: 'Defleet Date',
        value: 'defleetOn',
        flex: 1
    },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        default: true,
        subText: 'addedBy',
        subTextTitle: 'Created By'
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        flex: 1,
        subText: 'modifiedBy',
        subTextTitle: 'Last Updated By'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 250,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]


export const allFleetAssignFields = [
    {
        name: 'Vehicle Model',
        value: 'vehicleModel',
        flex: 1,
        default: true,
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer',
    },
    {
        name: 'Fleet Owner',
        value: 'fleetOwner',
        flex: 1,
    },
    {
        name: 'Responsible Person',
        value: 'name',
        flex: 1,
        default: true
    },
    {
        name: 'Email',
        value: 'email',
        default: true,
        flex: 1,
    },
    {
        name: 'Phone',
        value: 'phone',
        flex: 1,
    },
    {
        name: 'Address',
        value: 'address',
        flex: 1,
    },
    {
        name: 'Company',
        value: 'companyName',
        flex: 1,
    },
    {
        name: 'Event',
        value: 'eventName',
        default: true,
        flex: 1,
    },
    {
        name: 'Event Location',
        value: 'location',
        flex: 1,
    },
    {
        name: 'Type Of Activity',
        value: 'typeOfActivity',
        flex: 1,
    },
    {
        name: 'Purpose',
        value: 'purpose',
        flex: 1,
    },
    {
        name: 'Method Of Transport',
        value: 'methodOfTransport',
        flex: 1,
    },
    {
        name: 'Transport Company',
        value: 'transportCompany',
        flex: 1,
    },
    {
        name: 'Event Date',
        value: 'eventDate',
        default: true,
        flex: 1,
    },
    {
        name: 'Departure Date',
        value: 'departureDate',
        flex: 1,
    },
    {
        name: 'Expected Arrival',
        value: 'expectedArrivalDate',
        flex: 1,
    },
    {
        name: 'Expected Return',
        value: 'expectedReturnDate',
        flex: 1,
    },
    {
        name: 'MAL Technical Support Req.',
        value: 'malTechnicalSupportReq',
        flex: 1,
    },
    {
        name: 'Request Note',
        value: 'requestNotes',
        flex: 1,
    },
    {
        name: 'Note',
        value: 'notes',
        flex: 1,
    },
    {
        name: 'Received Note',
        value: 'preNotes',
        flex: 1,
    },
    {
        name: 'Release Notes',
        value: 'postNotes',
        flex: 1,
    },
    {
        name: 'Requested On',
        value: 'requestedDate',
        flex: 1,
        default: true,
        subText: 'requestedBy',
        subTextTitle: 'Requested By'
    },
    {
        name: 'Assigned On',
        value: 'assignDate',
        flex: 1,
        subText: 'assignBy',
        subTextTitle: 'Assigned By'
    },
    {
        name: 'Approved On',
        value: 'approvedDate',
        flex: 1,
        default: true,
        subText: 'approvedBy',
        subTextTitle: 'Approved By'
    },
    {
        name: 'Received On',
        value: 'receivedDate',
        flex: 1,
        subText: 'receivedBy',
        subTextTitle: 'Received By'
    },
    {
        name: 'Released On',
        value: 'releasedDate',
        flex: 1,
        subText: 'releasedBy',
        subTextTitle: 'Released By'
    },
    {
        name: 'Status',
        value: 'status',
        elementName: 'statusName',
        flex: 1,
        default: true
    },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        default: true,
        subText: 'addedBy',
        subTextTitle: 'Created By'
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        flex: 1,
        subText: 'modifiedBy',
        subTextTitle: 'Last Updated By'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 100,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const userLevelOptions = [
    { value: 'oem', label: 'OEM', index: 1 },
    { value: 'region', label: 'Region', index: 2 },
    { value: 'subregion', label: 'Subregion', index: 3 },
    { value: 'group', label: 'Group', index: 4 },
    { value: 'individual', label: 'Individual', index: 5 }
]

export const fleetAssignStatusLevel = [
    { index: 0, value: 'pendingApproval', name: 'Pending Approval' },
    { index: 1, value: 'requested', name: 'Requested' },
    { index: 2, value: 'cancelled', name: 'Cancelled' },
    { index: 3, value: 'declined', name: 'Declined' },
    { index: 4, value: 'approved', name: 'Approved' },
    { index: 5, value: 'received', name: 'Received' },
    { index: 6, value: 'released', name: 'released' }
]

export const fleetSortOptions = [
    "make",
    "group",
    "type",
    "model",
    "year",
    "modelDescription",
    "vinNo",
    "regNo",
    "extColor",
    "intColor",
    "mileage",
    "status",
    "fleetOwner",
    "country",
    "regionID",
    "subregionID",
    "groupID",
    "defleetDate",
];