/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import _ from 'lodash';

import NumberFormat from 'react-number-format';
/** COMPONENTS */

import CommonHelper from '../../../services/common'

import Translate from '../../../constants/translate';
import { objStock } from '../viewModel'

var excludedStock = [
    'documentID',
    'clientID',
    'enquiryID',
    'keywords',
    'lastStatus',
    'images',
    'testDriveDetail',
    'fleetDetail',
    'statusModifiedBy',
    'statusModifiedFrom',
    'addedBy',
    'modifiedBy',
    'addedDate',
    'modifiedDate',
    'modifiedFrom',
    'addedFrom',
    'firstEnqDate',
    'priceDate',
    'regNoDate',
    'lastNoteDate',
];

const ActivityTag = React.memo(({
    activityDate,
    id,
    addedBy,
    activitylog,
    dealersettings,
    clientUsers,
    groupUsers,
    stockStatuses
}) => {

    const [showLog, setShowLog] = useState(false)

    const currencySymbol = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) &&
        !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

    const vbmcurrencySymbol = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) &&
        !_.isEmpty(dealersettings.client.vbm)) ? dealersettings.client.vbm.symbol + ' ' : '$ ');

    const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
    const hideStockPrice = (dealersettings?.rolePermissions?.permissions?.hideStockPrice) ? true : false;
    const bindChangeLog = (objLog) => {

        if (!_.isEmpty(objLog)) {
            if (!_.isEmpty(objLog.notes)) {
                if (!showLog) {
                    setShowLog(true);
                }
                return <div className="flow-activity-title">{objLog.notes}</div>
            }
            else {

                const _client = objLog.clientID && !_.isEmpty(dealersettings) &&
                    !_.isEmpty(dealersettings.group) &&
                    !_.isEmpty(dealersettings.group.clients) &&
                    dealersettings.group.clients[objLog.clientID] ? dealersettings.group.clients[objLog.clientID] : dealersettings.client;

                let _settings = _client && _client.settings;

                let _oldData = Object.assign({}, objLog.oldData);
                let _newData = Object.assign({}, objLog.newData);

                let cells = [];

                for (let [key] of Object.entries(objStock)) {
                    let i = 99;
                    if ((excludedStock.indexOf(key) < 0) && ((_oldData[key] ? _oldData[key] : 'empty') !== (_newData[key] ? _newData[key] : 'empty')) && ((!_.isEmpty(_oldData) && _oldData.hasOwnProperty(key)) || (!_.isEmpty(_newData) && _newData.hasOwnProperty(key)))) {
                        if (!showLog) {
                            setShowLog(true);
                        }

                        if (key === 'usage') {
                            cells.push(
                                <div key={`${i}${key}`} className="flow-activity-title">
                                    <Translate text={key} />{': '}
                                    <span className="log-sub">
                                        {CommonHelper.getNameByValue(_settings && _settings.stockUsage, _oldData[key], 'empty')}
                                        <span className="log-arrow">&rarr;</span>
                                        {CommonHelper.getNameByValue(_settings && _settings.stockUsage, _newData[key], 'empty')}
                                    </span>
                                </div>
                            )
                        }
                        else if (key === 'transmission') {
                            cells.push(
                                <div key={`${i}${key}`} className="flow-activity-title">
                                    <Translate text={key} />{': '}
                                    <span className="log-sub">
                                        {CommonHelper.getNameByValue(_settings && _settings.transmissions, _oldData[key], 'empty')}
                                        <span className="log-arrow">&rarr;</span>
                                        {CommonHelper.getNameByValue(_settings && _settings.transmissions, _newData[key], 'empty')}
                                    </span>
                                </div>
                            )
                        }
                        else if (key === 'location') {
                            cells.push(
                                <div key={`${i}${key}`} className="flow-activity-title">
                                    <Translate text={key} />{': '}
                                    <span className="log-sub">
                                        {CommonHelper.getNameByValue(_settings && _settings.stockLocation, _oldData[key], 'empty')}
                                        <span className="log-arrow">&rarr;</span>
                                        {CommonHelper.getNameByValue(_settings && _settings.stockLocation, _newData[key], 'empty')}
                                    </span>
                                </div>
                            )
                        }
                        else if (key === 'transportMethod') {
                            cells.push(
                                <div key={`${i}${key}`} className="flow-activity-title">
                                    <Translate text={key} />{': '}
                                    <span className="log-sub">
                                        {CommonHelper.getNameByValue(_settings && _settings.stockTransport, _oldData[key], 'empty')}
                                        <span className="log-arrow">&rarr;</span>
                                        {CommonHelper.getNameByValue(_settings && _settings.stockTransport, _newData[key], 'empty')}
                                    </span>
                                </div>
                            )
                        }
                        else if (key === 'clientID') {
                            cells.push(
                                <div key={`${i}${key}`} className="flow-activity-title">
                                    <Translate text={'dealership'} />{': '}
                                    <span className="log-sub">
                                        {CommonHelper.getOtherDealerName(dealersettings, _oldData[key], 'empty')}
                                        <span className="log-arrow">&rarr;</span>
                                        {CommonHelper.getOtherDealerName(dealersettings, _newData[key], 'empty')}
                                    </span>
                                </div>
                            )
                        }
                        else if (key === 'status') {
                            cells.push(
                                <div key={`${i}${key}`} className="flow-activity-title">
                                    <Translate text={'status'} />{': '}
                                    <span className="log-sub">
                                        {CommonHelper.getLabelByValue(stockStatuses, _oldData[key], 'empty')}
                                        <span className="log-arrow">&rarr;</span>
                                        {CommonHelper.getLabelByValue(stockStatuses, _newData[key], 'empty')}
                                    </span>
                                </div>
                            )
                        }
                        else if (key === 'owner' || key === 'serviceAdvisor' || key === 'technician'
                            || key === 'workshopManager' || key === 'partsInterpretor' || key === 'checkInBy') {
                            cells.push(
                                <div key={`${i}${key}`} className="flow-activity-title">
                                    <Translate text={key} />{': '}
                                    <span className="log-sub">
                                        {CommonHelper.getUserNamebyId(allUsers, _oldData[key], 'empty')}
                                        <span className="log-arrow">&rarr;</span>
                                        {CommonHelper.getUserNamebyId(allUsers, _newData[key], 'empty')}
                                    </span>
                                </div>
                            )
                        }

                        else if (key === 'buildDate') {
                            cells.push(
                                <div key={`${i}${key}`} className="flow-activity-title">
                                    <Translate text={key} />{': '}
                                    <span className="log-sub">
                                        {_oldData[key] ? moment(_oldData[key]).format('DD MMM YYYY h:mm A') : 'empty'}
                                        <span className="log-arrow">&rarr;</span>
                                        {_newData[key] ? moment(_newData[key]).format('DD MMM YYYY h:mm A') : 'empty'}
                                    </span>
                                </div>
                            )
                        }
                        else if (key === 'regDate' || key === 'regExp' || key === 'warrantyStartDate' || key === 'warrantyExp' || key === 'inStockDate' ||
                            key === 'stockInDate' || key === 'soldDate' || key === 'deliveredDate' || key === 'etaDate' || key === 'fokDate') {
                            cells.push(
                                <div key={`${i}${key}`} className="flow-activity-title">
                                    <Translate text={key} />{': '}
                                    <span className="log-sub">
                                        {_oldData[key] ? moment.unix(_oldData[key].seconds).format('DD MMM YYYY h:mm A') : 'empty'}
                                        <span className="log-arrow">&rarr;</span>
                                        {_newData[key] ? moment.unix(_newData[key].seconds).format('DD MMM YYYY h:mm A') : 'empty'}
                                    </span>
                                </div>
                            )
                        }
                        else if (key === 'price' || key === 'purchasePrice') {

                            cells.push(
                                <div key={`${i}${key}`} className="flow-activity-title">
                                    <Translate text={CommonHelper.getStaticFieldName(_settings?.stockOptionsStatic, key)} />{': '}
                                    <span className="log-sub">
                                        {_oldData[key] ?
                                            <>
                                                {
                                                    hideStockPrice
                                                        ?
                                                        <span>{CommonHelper.formatCurrency(currencySymbol, _oldData[key], true)}</span>
                                                        :
                                                        <NumberFormat value={_oldData[key]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} />

                                                }
                                            </>
                                            : <>{'empty'}</>}
                                        <span className="log-arrow">&rarr;</span>
                                        {_newData[key] ?
                                            <>
                                                {
                                                    hideStockPrice
                                                        ?
                                                        <span>{CommonHelper.formatCurrency(currencySymbol, _newData[key], true)}</span>
                                                        :
                                                        <NumberFormat value={_newData[key]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} />

                                                }
                                            </>
                                            : <>{'empty'}</>}
                                    </span>
                                </div>
                            )
                        }
                        else if (key === 'miles') {

                            cells.push(
                                <div key={`${i}${key}`} className="flow-activity-title">
                                    <Translate text={key} />{': '}
                                    <span className="log-sub">
                                        {_oldData[key] ? <NumberFormat value={_oldData[key]} decimalScale={2} displayType={'text'} thousandSeparator={true} /> : <>{'empty'}</>}
                                        <span className="log-arrow">&rarr;</span>
                                        {_newData[key] ? <NumberFormat value={_newData[key]} decimalScale={2} displayType={'text'} thousandSeparator={true} /> : <>{'empty'}</>}
                                    </span>
                                </div>
                            )
                        }
                        else if (key === 'marginValue' || key === 'marginRetail' || key === 'marginOrderFill' || key === 'marginPayments' || key === 'marginMarketing') {

                            cells.push(
                                <div key={`${i}${key}`} className="flow-activity-title">
                                    <Translate text={key} />{': '}
                                    <span className="log-sub">
                                        {_oldData[key] ? <NumberFormat value={_oldData[key]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={vbmcurrencySymbol} /> : <>{'empty'}</>}
                                        <span className="log-arrow">&rarr;</span>
                                        {_newData[key] ? <NumberFormat value={_newData[key]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={vbmcurrencySymbol} /> : <>{'empty'}</>}
                                    </span>
                                </div>
                            )
                        }
                        else if (key === 'isLoan' || key === 'isServiceLoan' || key === 'isTestDrive' || key === 'isSale' || key === 'isStockArrived') {

                            cells.push(
                                <div key={`${i}${key}`} className="flow-activity-title">
                                    <Translate text={key} />{': '}
                                    <span className="log-sub">
                                        {_oldData[key] === true ? 'Yes' : 'No'}
                                        <span className="log-arrow">&rarr;</span>
                                        {_newData[key] === true ? 'Yes' : 'No'}
                                    </span>
                                </div>
                            )
                        }
                        else if (key === 'isDeleted') {
                            cells.push(
                                <div key={`${i}${key}`} className="flow-activity-title">
                                    <Translate text={'Stock'} />{': '}
                                    <span className="log-sub">
                                        {deletedStatus(_oldData[key])}
                                        <span className="log-arrow">&rarr;</span>
                                        {deletedStatus(_newData[key])}
                                    </span>
                                </div>
                            )
                        }
                        // else if (key === 'vehicle') {
                        //     let newCells = bindVehicleChangeLog(_oldData[key], _newData[key]);
                        //     cells = _.union(cells, newCells);
                        // }
                        // else if (key === 'dynamicFields') {

                        //     let newCells = bindDynamicChangeLog(_oldData[key], _newData[key], _settings);
                        //     cells = _.union(cells, newCells);
                        // }
                        else {
                            cells.push(
                                <div key={Math.round(Math.random() * 1000)} className="flow-activity-title" >
                                    <Translate text={key} />{': '}
                                    <span className="log-sub">
                                        {!_.isEmpty(_oldData[key]) && !_.isObject(_oldData[key]) ? _oldData[key] : 'empty'}
                                        <span className="log-arrow">&rarr;</span>
                                        {!_.isEmpty(_newData[key]) && !_.isObject(_newData[key]) ? _newData[key] : 'empty'}
                                    </span>
                                </div>
                            )

                        }
                        i++;
                    }
                }

                return cells;
            }
        }
        else {
            return <>{''}</>
        }
    }

    const deletedStatus = (_data) => {
        if (_data)
            return <div className={`badge badge-pill badge-status-lost`}>DELETED</div>
        else
            return <div className={`badge badge-pill badge-status-won`}>ACTIVE</div>
    }

    // const _permissions = ((activitylog.clientID === dealersettings.client.id && !_.isEmpty(dealersettings) &&
    //     !_.isEmpty(dealersettings.rolePermissions) &&
    //     !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

    // const deleteActivity = ((!_.isEmpty(_permissions) && _permissions.deleteActivity) ? true : false);

    let dealerName = '';
    if (!_.isEmpty(dealersettings) && dealersettings.group && (activitylog.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
        dealerName = CommonHelper.getOtherDealerName(dealersettings, activitylog.clientID);
    }

    return (
        (!_.isEmpty(dealersettings))
            ?
            <>
                <div id={`enquiry-item-${id}`} className={`enquiry-flow-item  ${activitylog.subType === 'log' && showLog ? 'flow-style-none' : ''} ${activitylog.subType === 'log' && !showLog ? 'hide' : ''}`}>
                    <div className={`flow-item  ${activitylog.subType === 'log' && showLog ? 'flow-style-none' : ''} ${activitylog.subType === 'log' && !showLog ? 'hide' : ''}`}>
                        <div className="flow-item-content">
                            {

                                <>
                                    <div className="flow-text-wrap">
                                        {bindChangeLog(activitylog)}
                                        <div className="flow-activity-date">
                                            <span>{moment.unix(activityDate.seconds).format('DD/MM/YYYY h:mm A')}</span> - {addedBy}
                                            {activitylog.modifiedFrom ?
                                                <>
                                                    {` - `}
                                                    <i className={`ico icon-${activitylog.modifiedFrom === 'web' ? 'monitor' : activitylog.modifiedFrom} mr-1`}></i>
                                                    {activitylog.modifiedFrom} </> : <></>}
                                        </div>
                                        {
                                            (!_.isEmpty(dealerName)) ? (
                                                <div className="dealer-group-title text-capitalize">{dealerName}</div>
                                            ) : (<></>)
                                        }
                                        {
                                            (!_.isEmpty(activitylog.origin)) ? (
                                                <div className="badge badge-pill badge-input-white mr-1 text-capitalize">{activitylog.origin}</div>
                                            ) : (<></>)
                                        }
                                    </div>
                                </>


                            }
                        </div>
                    </div>
                    {
                        <span className="flow-icon"><i className={`ico icon-${activitylog.subType === 'file' ? 'attachment' : (activitylog.subType === 'call' || activitylog.subType === 'email') ? activitylog.subType + '-log' : activitylog.subType === 'edms' ? activitylog.iconType : (activitylog.inbound ? 'sms-inbound' : activitylog.subType)}`}></i> </span>
                    }
                </div>
            </>

            :
            <></>

    )
})

export default ActivityTag
