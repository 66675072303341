/** LIBRARIES */
import React, { Component } from 'react';
import Translate from '../../constants/translate';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
/** COMPONENTS */

import CommonHelper from '../../services/common';

export default class ShowCosts extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { fullServiceCost, currencySymbol, isTaxIncluded, dispalyTax, taxType, dealersettings } = this.props;
        const recommendationName = dealersettings?.client?.clientSettings?.service?.recommendationName;
        return (
            <>
                <div className="popover-body popover-content">

                    <div className="form-style popover-middle-pipeline">
                        <div className="labourcost-discountpop-head">
                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                <tbody>
                                    <tr >
                                        <td className="labourcost-costtxt ">  <Translate text={'Service Packages Cost'} />:</td>
                                        <td className="labourcost-cost-box right-align"><NumberFormat value={fullServiceCost.packagesCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                                    </tr>
                                    <tr >
                                        <td className="labourcost-costtxt ">  <Translate text={'Service Parts Cost'} />:</td>
                                        <td className="labourcost-cost-box right-align"><NumberFormat value={fullServiceCost.servicePartsCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                                    </tr>
                                    {
                                        fullServiceCost.actualDiscountPack
                                            ?
                                            <>
                                                <tr >
                                                    <td className="labourcost-costtxt "><Translate text={'Discount (Service Packages)'} />:</td>
                                                    <td className="labourcost-cost-box right-align"><NumberFormat value={fullServiceCost.actualDiscountPack} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${currencySymbol}-`} /></td>
                                                </tr>
                                            </>
                                            :
                                            <></>
                                    }
                                    <tr >
                                        <td className="labourcost-costtxt"> <Translate text={'Part Cost'} />:</td>
                                        <td className="labourcost-cost-box right-align" ><NumberFormat value={fullServiceCost.partsCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                                    </tr>
                                    <tr >
                                        <td className="labourcost-costtxt"> <Translate text={'Labour Repair Cost'} />:</td>
                                        <td className="labourcost-cost-box right-align"><NumberFormat value={fullServiceCost.labourCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                                    </tr>
                                    {
                                        fullServiceCost.actualDiscountRecomm
                                            ?
                                            <>
                                                <tr >
                                                    <td className="labourcost-costtxt "><Translate text={`Discount (${_.trim(recommendationName) ? recommendationName : 'Recommendations'})`} />:</td>
                                                    <td className="labourcost-cost-box right-align"><NumberFormat value={fullServiceCost.actualDiscountRecomm} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${currencySymbol}-`} /></td>
                                                </tr>
                                            </>
                                            :
                                            <></>
                                    }
                                    <tr>
                                        <td className="labourcost-costtxt blue-color"><Translate text={'Sub Total Cost'} /> {`(${taxType ? taxType : 'Tax'} Excl.)`}:</td>
                                        <td className="labourcost-cost-box right-align blue-color" ><span><NumberFormat value={fullServiceCost.subTotal} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></span></td>
                                    </tr>
                                    {
                                        fullServiceCost.actualDiscount
                                            ?
                                            <>
                                                <tr >
                                                    <td className="labourcost-costtxt "><Translate text={'discount'} />:</td>
                                                    <td className="labourcost-cost-box right-align"><NumberFormat value={fullServiceCost.actualDiscount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${currencySymbol}-`} /></td>
                                                </tr>
                                            </>
                                            :
                                            <></>
                                    }

                                    {
                                        isTaxIncluded
                                            ?
                                            <>

                                                <tr >
                                                    <td className="labourcost-costtxt">  {taxType}&nbsp;<Translate text={'Payable'} />:</td>
                                                    <td className="labourcost-cost-box right-align"><NumberFormat value={fullServiceCost.taxCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                                                </tr>
                                            </>
                                            :
                                            <></>
                                    }
                                    <tr>
                                        <td className="labourcost-costtxt-green"> <Translate text={'Total Cost'} /> {dispalyTax}:</td>
                                        <td className="labourcost-cost-box-total right-align" ><span><NumberFormat value={fullServiceCost.total} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

            </>
        );
    }
}
