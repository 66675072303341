import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { ReactSelect, ReactMultiSelect } from '../../../../components'
import Translate from '../../../../constants/translate';
import { ClientContext } from '../clientContext'

const UpdateLevel = (props) => {
    const [role, setRole] = useState({ ...props.role, regionID: null, groupID: null, oemID: null, arrayClientID: [] });
    const [groupOptions, setGroupOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);
    const { groups, regions } = useContext(ClientContext);
    const [loader, setLoader] = useState(false)
    const [errorFields, setErrorFields] = useState({});
    const [regionGroupOptions, setRegionGroupOptions] = useState([])

    useEffect(() => {
        setRegionOptions(_.filter(regions, (v) => _.indexOf(_.uniq(_.map(props.dealersettings.clients, 'region')), v.code) >= 0)
            .map((rec) => {
                return {
                    value: rec.code,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true
                }
            }))
        setGroupOptions(_.filter(groups, (v) => _.indexOf(_.uniq(_.map(props.dealersettings.clients, 'group')), v.documentID) >= 0)
            .map((rec) => {
                return {
                    value: rec.documentID,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true
                }
            }))

    }, [groups, regions])

    useEffect(() => {
        if (_.isEmpty(role))
            return

        if (role?.level?.includes('region')) {
            if (props.dealersettings.clients.filter(c => (!_.isEmpty(role.levelID) ? c.region === role.levelID : true)).length > 0) {
                setRegionGroupOptions([{ label: 'All', value: 'all', clientIDs: [] }, ...props.dealersettings.clients.filter(c => (!_.isEmpty(role.levelID) ? c.region === role.levelID : true)).map((rec) => { return { value: rec.documentID, label: rec.name, doc: rec, clientIDs: [rec.documentID] } })])
            }
            else {
                setRegionGroupOptions([])
            }
        }
        else {
            if (props.dealersettings.clients.filter(c => (!_.isEmpty(role.levelID) ? c.group === role.levelID : true)).length > 0) {
                setRegionGroupOptions([{ label: 'All', value: 'all', clientIDs: [] }, ...props.dealersettings.clients.filter(c => (!_.isEmpty(role.levelID) ? c.group === role.levelID : true)).map((rec) => { return { value: rec.documentID, label: rec.name, doc: rec, clientIDs: [rec.documentID] } })])
            }
            else {
                setRegionGroupOptions([])
            }
        }


    }, [role.levelID])

    const handleSelectChange = (e, data) => {
        setRole({
            ...role,
            [data.name]: e.value,
        });
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleReactMultiSelectChange = (selectedOptions) => {
        const value = [];
        let clientids = [];
        if (selectedOptions.length > 0 && selectedOptions.some(item => item.value === 'all')) {
            clientids = regionGroupOptions.filter(e => e.value !== 'all').map(rec => { return rec.value })
        }
        else {
            !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                value.push(data.value);
                data.clientIDs.forEach(r => { clientids.push(r) })
            })
        }
        setRole({
            ...role,
            ['arrayClientID']: _.uniq(clientids)
        });
    }

    const updateLevel = (e) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(role['levelID'])) {
            formIsValid = false;
            errors['levelID'] = errorClass;
        }
        if (_.isEmpty(role['arrayClientID'])) {
            formIsValid = false;
            errors['arrayClientID'] = errorClass;
        }

        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true);
        try {
            let updateRole = {
                ...role,
                [`${role.level}ID`]: role.levelID,
                defaultClientID: role.arrayClientID[0]
            };
            props.setClientUsers([{ ...props.user, ...updateRole }, ...props.clientUsers.filter(item => item.id !== props.user.id)]);
            if (!_.isEmpty(props.user.arrayOEM)) {
                updateRole.arrayOEMID = props.user.arrayOEM.filter(a => a.oem !== props.dealersettings.oemID).map(a => { return a.oem })
                updateRole.arrayOEMID.push(props.dealersettings.oemID);
                updateRole.arrayOEM = props.user.arrayOEM.filter(a => a.oem !== props.dealersettings.oemID)
                updateRole.arrayOEM.push({
                    oem: props.dealersettings.oemID,
                    roleID: role.roleID,
                    clients: role.role === 'Administrator' ? [] : updateRole.arrayClientID
                })
            }
            window.firebase.firestore().doc(`users/${props.user.id}`).set({ ...updateRole }, { merge: true })
            _.differenceWith(props.user.arrayClientID, role.arrayClientID, _.isEqual).forEach(_userClient => {
                window.firebase.firestore().doc(`users/${props.user.id}/userClients/${_userClient}`).delete();
            })
            _.filter(props.dealersettings.clients, (v) => _.indexOf(_.uniq(_.map(role.arrayClientID)), v.documentID) >= 0).forEach(_userClient => {
                window.firebase.firestore().doc(`users/${props.user.id}/userClients/${_userClient.documentID}`)
                    .set({
                        name: _userClient.name,
                        department: props.user.department,
                        roleID: role.roleID
                    }, { merge: true });
            })
            // role.arrayClientID.forEach(clientid => {
            //     window.firebase.firestore().doc(`users/${props.user.id}/userClients/${clientid}`).set({ 'roleID': role.roleID }, { merge: true })
            // })
            toast.notify('Role updated successfully', {
                duration: 2000
            })
            setLoader(false);
            window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                .set({
                    settingsUpdate: window.firebase.firestore.Timestamp.now(),
                    settingsUpdatePriority: 'normal',
                    settingsUpdateBy: props?.dealersettings?.name || null,
                    settingsUpdateText: 'Roles Settings has been updated',
                }, { merge: true });
            if (localStorage.defaultModule === 'oem' && props.dealersettings.oemID) {
                window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}`)
                    .set({
                        settingsUpdate: window.firebase.firestore.Timestamp.now(),
                        settingsUpdatePriority: 'normal',
                        settingsUpdateBy: props?.dealersettings?.name || null,
                        settingsUpdateText: 'Roles Settings has been updated',
                    }, { merge: true })
            }
            props.handleClose();
        }
        catch (error) {
            console.log(error);
            setLoader(false);
            Swal.fire('Something went wrong.', '', 'error')
        }

    }

    return _.isEmpty(role) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-update-password"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title><Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label >{props.role.level === 'group' ? 'Group' : 'Region'}</label>
                                    <ReactSelect
                                        options={props.role.level === 'group' ? groupOptions : regionOptions}
                                        name={"levelID"}
                                        placeholder={`select ${props.role.level}`}
                                        onChange={handleSelectChange}
                                        value={role.levelID}
                                        classNamePrefix={`${errorFields["levelID"]} basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>
                                <div className="form-group col-md-12">
                                    <label ><Translate text={'client'} /></label>
                                    <ReactMultiSelect
                                        options={regionGroupOptions}
                                        name={'arrayClientID'}
                                        placeholder={'select client'}
                                        onChange={handleReactMultiSelectChange}
                                        value={role.arrayClientID}
                                        classNamePrefix={`${errorFields["arrayClientID"]} basic-select`}
                                        isMulti={true}
                                        isSearchable={true}
                                    >
                                    </ReactMultiSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => updateLevel(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} ><Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal >
    );
}

export default UpdateLevel;