import React, { useState, useEffect, useReducer, useRef, Fragment } from 'react';
import { Modal, Accordion, Card, Tabs, Tab } from "react-bootstrap";
import moment from 'moment'
import Swal from 'sweetalert2'
import _ from 'lodash'

import { collection } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators'

import { ContentOverlay, PopUpModal } from '../../components'
import Translate from '../../constants/translate';
import FileInfo from './fileInfo'
import AddFile from './add'
import Files from './index'

import FleetHistoryReducer from '../fleet/history/historyReducer'
import CommonHelper from '../../services/common';
import { objFiles } from './viewModel'
import { firestoreDB } from '../../services/helper';
import { defaultFileCategories } from '../../services/enum';

const EnquiryListReducer = (state, action) => {
    function updateTradeinPro() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                return action.data;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data
        }
        case "APPEND_LIST": {
            return [...state, ..._.differenceBy(action.data, state, 'documentID')]
        }
        case "ADD_ENQUIRY_LIST": {
            return [action.data, ...state, action.data]
        }
        case "REMOVE_ENQUIRY_LIST": {
            return state.filter(item => item.documentID !== action.data.documentID)
        }
        case "UPDATE_ENQUIRY_LIST": {
            return updateTradeinPro()
        }
        default:
            return state;
    }
};

const contactDefaultCategories = [
    { module: 'contact', category: 'quotation' },
    { module: 'contact', category: 'invoice' },
    { module: 'contact', category: 'contract' },
    { module: 'contact', category: 'others' }
]
const contactCategories = [
    { active: true, default: true, level: 'oem', module: 'contact', value: 'quotation', name: 'Quotation', label: 'Quotation' },
    { active: true, default: true, level: 'oem', module: 'contact', value: 'invoice', name: 'Invoice', label: 'Invoice' },
    { active: true, default: true, level: 'oem', module: 'contact', value: 'contract', name: 'Contract', label: 'Contract' },
    { active: true, default: true, level: 'oem', module: 'contact', value: 'others', name: 'Others', label: 'Others' }
]

const defaultFileArray = ['quotation', 'invoice', 'contract', 'others']

const ContactFiles = (props) => {
    let _contract = localStorage.defaultModule === 'service' ? 'serviceContract' :
        localStorage.defaultModule === 'fleetPro' ? 'fleetContract' :
            (localStorage.defaultModule === 'tradeinPro' ? 'tradeinContract' :
                'contract')
    let _fileCategories = _.map(defaultFileCategories, function (e) {
        return {
            ...e,
            label: CommonHelper.showLocale(props, (e.value === 'contract' ? _contract : e.value), e.label),
            name: CommonHelper.showLocale(props, (e.value === 'contract' ? _contract : e.value), e.name),
        }
    })
    if (!_.isEmpty(props.dynamicCategories)) _fileCategories = Object.assign([], props.dynamicCategories)
    const [files, dispatch] = useReducer(EnquiryListReducer, [])
    const [checkDataLoad, setDataload] = useState(true);
    const [modelAddShow, setModelAddShow] = useState(false)
    const [filesLoader, setLoader] = useState(true)

    const [activeTab, setActiveTab] = useState('')
    const node = useRef();

    const { dealersettings } = props;

    let filesCategory = Object.assign([], dealersettings?.client?.settings?.filesCategory)
    let _allCategories = contactCategories.concat(filesCategory)


    const _moduleSettings = (!_.isEmpty(dealersettings?.client?.moduleSettings) ? dealersettings.client.moduleSettings : null);

    const enquiryEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.sales) &&
        _moduleSettings.sales.enabled) ? true : false);

    const serviceEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.service) &&
        _moduleSettings.service.enabled) ? true : false);

    const tradeinProEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.tradeinPro) &&
        _moduleSettings.tradeinPro.enabled) ? true : false);

    const enquiryAccess = (enquiryEnabled && (!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.rolePermissions) &&
        !_.isEmpty(dealersettings.rolePermissions.modules) &&
        dealersettings.rolePermissions.modules.enquiry) ? true : false);

    const serviceAccess = (serviceEnabled && (!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.rolePermissions) &&
        !_.isEmpty(dealersettings.rolePermissions.modules) &&
        dealersettings.rolePermissions.modules.service) ? true : false);

    const tradeinProAccess = (tradeinProEnabled && (!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.rolePermissions) &&
        !_.isEmpty(dealersettings.rolePermissions.modules) &&
        dealersettings.rolePermissions.modules.tradeinPro) ? true : false);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {

        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        //setShowMove(false);
    };

    useEffect(() => {
        if (!props.showAddOpen) return;
        handleFileOpen();
    }, [props.showAddOpen]);

    useEffect(() => {

        return () => {
            //window.removeEventListener('resize', handleResize);
            window[`unSubscribeContactFileList`] && window[`unSubscribeContactFileList`]();
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(props.contacts)) {
            return;
        }
        if (props.companyID)
            setDataload(true)

    }, [props.contacts])

    useEffect(() => {
        if (!checkDataLoad) {
            return;
        }
        let refCollectionData;

        if (props.contactID) {
            refCollectionData = firestoreDB(props.dealersettings).firestore().collectionGroup(`files`)
                .where('contactID', '==', props.contactID)
                //.where('category', '==', activeTab)
                .where('isDeleted', '==', false)

            window[`unSubscribeContactFileList`] = refCollectionData.onSnapshot(onCollectionUpdate);
        }

    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {

                let _data = {
                    ...change.doc.data(),
                    documentID: change.doc.id,
                    refPath: (change.doc?.ref?.path || null)
                }
                if (!_data.module) _data.module = setModule(change.doc.ref.path)
                snapshotDoc = dataMappingVM(_data);

                if (change.type === 'added') {
                    actionType = "ADD_ENQUIRY_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_ENQUIRY_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_ENQUIRY_LIST"
                }
            }
        })
        let _files = [];
        if (!actionType) {
            querySnapshot.forEach(function (doc) {
                if (isValid(doc.ref.path) === true) {
                    let _data = {
                        ...doc.data(),
                        documentID: doc.id,
                        refPath: (doc?.ref?.path || null)
                    }
                    if (!_data.module) _data.module = setModule(doc.ref.path)
                    var logVM = dataMappingVM(_data);
                    _files.push(logVM)
                }
            });
        }

        dispatch({
            type: actionType ? actionType : "SUCCESS",
            data: actionType ? snapshotDoc : _files,
        });

        setLoader(false)
        setDataload(false)
    }


    const dataMappingVM = (doc) => {
        const objlogData = Object.assign({}, doc);
        const fileVM = Object.assign({}, objFiles);
        for (let [key, value] of Object.entries(objlogData)) {
            fileVM[key] = value;
        }

        fileVM.categoryName = CommonHelper.getNameByValue(_allCategories.filter(e => e.module === doc.module), doc.category, (defaultFileArray.includes(doc.category) ? doc.category : 'other'))
        if (props.companyID && fileVM.contactID && !_.isEmpty(props.contacts) && _.find(props.contacts, { documentID: fileVM.contactID })) {
            //fileVM.contact = _.find(props.contacts, { documentID: fileVM.contactID })
            fileVM.displayName = CommonHelper.displayContactName(null, _.find(props.contacts, { documentID: fileVM.contactID }))
        }
        else
            fileVM.displayName = ''

        fileVM.createdOn = moment.unix(fileVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A');
        fileVM.updatedOn = moment.unix(fileVM.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A');
        fileVM.addedBy = CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), fileVM.addedBy);
        fileVM.modifiedBy = CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), fileVM.modifiedBy);

        return fileVM
    }

    const setModule = (_path) => {
        let _module = 'contact'
        if (_path.includes('serviceJobs')) _module = 'service'
        else if (_path.includes('tradeinPro')) _module = 'tradein'
        else if (_path.includes('fleetPro')) _module = 'fleet'
        else if (_path.includes('enquiries')) _module = 'sales'
        else if (_path.includes('stock')) _module = 'stock'
        else if (_path.includes('companies')) _module = 'company'
        else if (_path.includes('contactDetails')) _module = 'contact'

        return _module
    }

    const isValid = (_path) => {
        let _return = true
        if (_path.includes('serviceJobs')) {
            if (!serviceAccess) _return = false;
        }
        else if (_path.includes('tradeinPro')) {
            if (!tradeinProAccess) _return = false;
        }
        else if (_path.includes('enquiries')) {
            if (!enquiryAccess) _return = false;
        }
        return _return;
    }


    const handleCheckChange = (e) => {
        const { name, checked } = e.target;
        //console.log('handleCheckChange', name, checked)



    }



    //#region FILE CHANGES

    const handleFileClose = (ID) => {
        setModelAddShow(false);
        if (props.handleFileClose) props.handleFileClose(ID);
    }

    const handleFileOpen = (_type) => {
        //if (_type) activeTab(_type)
        setModelAddShow(true);
    }

    //#endregion

    const filterFiles = (_files) => {
        if (!_.isEmpty(props.sharedEnquiryIDs) && !_.isEmpty(_files)) {
            return _files.filter(_data => _data.module === 'sales' ? ((_.isEmpty(_data.enquiryID) || props.sharedEnquiryIDs.includes(_data.enquiryID)) ? true : false) : true)
        }
        else {
            return _files
        }
    }

    let _filesCategory = !_.isEmpty(filterFiles(files)) ? _.chain(contactDefaultCategories.concat(filterFiles(files)))
        .groupBy("module")
        .map((value, key) => ({ value: key, name: key, categories: value }))
        .value() : []
    return (
        <>
            {filesLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : <>
                {
                    !_.isEmpty(_filesCategory)
                        ?
                        <>
                            <div className={`settings-tab-wrap tabs`} >
                                <div className='tabs-wraper'>

                                    <Tabs defaultActiveKey={_filesCategory[0]?.value}
                                        className={`nav-fill nav-file-customer-paddingfix`}
                                        mountOnEnter={true}
                                        unmountOnExit={true}
                                    >
                                        {
                                            _filesCategory && _filesCategory.map((_module, index) => {
                                                return <Tab
                                                    key={index}
                                                    eventKey={_module.value}
                                                    title={CommonHelper.showLocale(props, _module.name)}
                                                >
                                                    <div className="file-manager-accordion" >
                                                        <Accordion className="quickview-related" defaultActiveKey={activeTab}>
                                                            {
                                                                _module.categories && _.chain(_module.categories)
                                                                    .groupBy("category")
                                                                    .map((value, key) => ({ value: key, name: (value[0]?.categoryName || key), files: !_.isEmpty(value) ? value.filter(e => !_.isEmpty(e.name)) : [] }))
                                                                    .value().map((_category, _index) => {
                                                                        return <Card key={_index} >
                                                                            <>
                                                                                {(() => {
                                                                                    return <>
                                                                                        <Accordion.Toggle as={Card.Header} eventKey={_category.value} id={`file-item-${_category.value}`} onClick={(e) => {
                                                                                            e.preventDefault();

                                                                                            if (activeTab === _category.value)
                                                                                                setActiveTab(null);
                                                                                            else
                                                                                                setActiveTab(_category.value);
                                                                                        }}>
                                                                                            <div className="mb-0">
                                                                                                <div className="cardlink">
                                                                                                    <a onClick={(e) => { e.preventDefault(); }}
                                                                                                        className={`btn btn-link ${activeTab === _category.value ? '' : 'collapsed'}`}                                                                                            >


                                                                                                        <div className="file-manager-accordion-icon"> <i className="ico icon-folder"></i> </div>
                                                                                                        <div className="file-manager-accordion-head">
                                                                                                            <span className="text-file-limit" title={_category.name}><Translate text={_category.name} /></span>
                                                                                                        </div>

                                                                                                        <i className="arrow-collapse"></i>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Accordion.Toggle>


                                                                                        <Accordion.Collapse eventKey={_category.value}>
                                                                                            <Card.Body>
                                                                                                <div className="form-style file-manager-accordion-inner">
                                                                                                    {

                                                                                                        _category.files && _category.files.length > 0 ?
                                                                                                            (
                                                                                                                <>
                                                                                                                    {/* {
                                                                                                        props.isDeivered
                                                                                                            ?
                                                                                                            <>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                {
                                                                                                                    ((!_.isEmpty(_category.files) && _category.files.length > 1) || (_.isObject(checkFile[activeTab]) && !_.isEmpty(checkFile[activeTab]))) && (<div className="file-manager-control">

                                                                                                                        {!_.isEmpty(_category.files) && _category.files.length > 1 && (<div className="file-manager-check">
                                                                                                                            <div className="checkbox icheck-success">
                                                                                                                                <input
                                                                                                                                    id={activeTab}
                                                                                                                                    type="checkbox"
                                                                                                                                    onChange={handleAllChecked}
                                                                                                                                    value={activeTab}
                                                                                                                                    checked={(_.isObject(checkFile[activeTab]) && !_.isEmpty(checkFile[activeTab]) && !_.isEmpty(_category.files) && checkFile[activeTab].length === _category.files.length) ? true : false}
                                                                                                                                />
                                                                                                                                <label htmlFor={activeTab}><Translate text={'Select All'} /></label>
                                                                                                                            </div>
                                                                                                                        </div>)}

                                                                                                                        {
                                                                                                                            (_.isObject(checkFile[activeTab]) && !_.isEmpty(checkFile[activeTab]))
                                                                                                                                ?
                                                                                                                                <div className={`file-manager-action`}>

                                                                                                                                    {
                                                                                                                                        deleteFiles
                                                                                                                                            ?
                                                                                                                                            <div className="file-manager-btn float-right">
                                                                                                                                                <button type="button" className="btn btn-default"
                                                                                                                                                    onClick={(e) => { e.preventDefault(); handleFileDelete() }}>
                                                                                                                                                    <i className="ico icon-delete"></i>
                                                                                                                                                    <Translate text={'delete'} />
                                                                                                                                                </button>
                                                                                                                                            </div>
                                                                                                                                            :
                                                                                                                                            <></>
                                                                                                                                    }

                                                                                                                                    <div className="file-manager-btn float-right mr-2">
                                                                                                                                        <button type="button" className="btn btn-default"
                                                                                                                                            onClick={(e) => {
                                                                                                                                                e.preventDefault();
                                                                                                                                                setShowMove(!showMove)
                                                                                                                                                if (showMove)
                                                                                                                                                    document.addEventListener('click', setShowMove(false));
                                                                                                                                            }}>
                                                                                                                                            <i className="ico icon-folder"></i>
                                                                                                                                            <Translate text={'Move To'} />
                                                                                                                                        </button>
                                                                                                                                        <div ref={node} className={`custom-drop file-manager-drop-align ${showMove ? '' : 'hide'}`}>

                                                                                                                                            <ul className="more-dropdown">
                                                                                                                                                {
                                                                                                                                                    _fileCategories.map((info, index) => {
                                                                                                                                                        return <li key={index} style={activeTab === info.value ? { display: 'none' } : {}} onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleFileMoveTo(info.value) }}>
                                                                                                                                                            <span>
                                                                                                                                                                <i className="ico icon-folder"></i><Translate text={info.name} />
                                                                                                                                                            </span>
                                                                                                                                                        </li>

                                                                                                                                                    })
                                                                                                                                                }
                                                                                                                                            </ul>


                                                                                                                                        </div>
                                                                                                                                    </div>

                                                                                                                                </div>
                                                                                                                                :
                                                                                                                                <></>
                                                                                                                        }
                                                                                                                    </div>)
                                                                                                                }

                                                                                                            </>

                                                                                                    } */}
                                                                                                                    {
                                                                                                                        // !_.isEmpty(_category.files) && _.chain(_.orderBy(_category.files, ['displayName'], ['asc']))
                                                                                                                        //     .groupBy("displayName")
                                                                                                                        //     .map((value, key) => ({ displayName: key, lists: value }))
                                                                                                                        //     .value()
                                                                                                                        // _category.files.map((info, index) => {
                                                                                                                        //     return <div className={info.displayName ? 'file-manager-username-wrap' : ''} key={index}>
                                                                                                                        //         {info.displayName ? <div className="file-manager-username">
                                                                                                                        //             <i className='ico icon-Contacts mr-1'></i>
                                                                                                                        //             {info.displayName}</div> : <></>}
                                                                                                                        //         {
                                                                                                                        _.orderBy(_category.files, ['modifiedDate'], ['desc']).map((file, iindex) => {
                                                                                                                            return <FileInfo
                                                                                                                                key={iindex}
                                                                                                                                file={file}
                                                                                                                                dealersettings={props.dealersettings}
                                                                                                                                isDeivered={props.isDeivered}
                                                                                                                                enquiryID={props.enquiryID}
                                                                                                                                contactID={props.contactID}
                                                                                                                                companyID={props.companyID}
                                                                                                                                serviceID={props.serviceID}
                                                                                                                                stockID={props.stockID}
                                                                                                                                tradeinProID={props.tradeinProID}
                                                                                                                                fleetProID={props.fleetProID}
                                                                                                                                pathToDelete={props.filePath}
                                                                                                                                contact={props.contact}
                                                                                                                                history={props.history}
                                                                                                                                hideSelect={true}
                                                                                                                                clientUsers={props.clientUsers}
                                                                                                                            >
                                                                                                                            </FileInfo>
                                                                                                                        })
                                                                                                                        //         }
                                                                                                                        //     </div>
                                                                                                                        // })
                                                                                                                    }
                                                                                                                </>
                                                                                                            )
                                                                                                            : (
                                                                                                                <div className="common-table">
                                                                                                                    <div className="text-center p-2">
                                                                                                                        <p><Translate text={'nodata'} /></p>
                                                                                                                        {/* {
                                                                                                            !props.isDeivered && (<button className="btn btn-sm btn-default" onClick={(e) => {
                                                                                                                e.preventDefault();
                                                                                                                handleFileOpen();
                                                                                                            }}>
                                                                                                                <i className="ico icon-add mr-1"></i> Add </button>)
                                                                                                        } */}

                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            )
                                                                                                    }
                                                                                                </div>
                                                                                            </Card.Body>
                                                                                        </Accordion.Collapse>
                                                                                    </>
                                                                                })()}
                                                                            </>


                                                                        </Card>
                                                                    })
                                                            }
                                                        </Accordion>
                                                    </div>
                                                </Tab>
                                            })
                                        }
                                    </Tabs>

                                </div>
                            </div>
                        </>
                        :
                        <>
                            <Files
                                {...props}
                            ></Files>
                        </>

                }


                <PopUpModal show={modelAddShow}>
                    <AddFile
                        show={modelAddShow}
                        enquiryID={props.enquiryID}
                        contactID={props.contactID}
                        companyID={props.companyID}
                        serviceID={props.serviceID}
                        stockID={props.stockID}
                        tradeinProID={props.tradeinProID}
                        fleetProID={props.fleetProID}
                        handleClose={handleFileClose}
                        dealersettings={props.dealersettings}
                        clientUsers={props.clientUsers}
                        groupUsers={props.groupUsers}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        filePath={props.filePath}
                        dynamicCategories={_fileCategories}
                        module={props.module}
                    >
                    </AddFile>
                </PopUpModal>
            </>
            }

        </>

    )
}

export default ContactFiles;