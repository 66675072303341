import React, { useState, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import { Dropdown } from 'react-bootstrap'
import moment from 'moment'
// import { combineLatest, of } from 'rxjs';
// import { map } from 'rxjs/operators';
// import { collection } from 'rxfire/firestore';
import { ContentOverlay, AntDatePicker, PopUpModal, PopoverPanel, InputAutoNumber } from "../../../components";
import CommonHelper from '../../../services/common'
import _images from '../../../images'
import EnquiryList from '../../dashboard/enquiryList'
import TestDriveList from '../../dashboard/testdriveList'
import { CustomToggle, CustomFilter, CustomMenu } from '../../../components/customdropdown';
import ReactExport from 'react-export-excel-xlsx-fix';
import ActivityList from '../../dashboard/activitiesList'
import { reportVM, reportDynColumns, defaultFilters, reportGroupBy } from '../viewModel'
import Swal from 'sweetalert2'
import DynamicFilters from "../dynFilters";
import ScheduleReport from '../scheduleReport';
import cronstrue from 'cronstrue';
import NumberFormat from 'react-number-format';
import SettingsKPIs from './settings';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const options = [
    { active: true, value: 'campaigns', label: 'Campaigns', title: 'Campaign', field: 'cpgv' },
    { active: true, value: 'origins', label: 'Lead Origins', title: 'Origin', field: 'orgv' },
    { active: true, value: 'enquiryTypes', label: 'Enquiry Types', title: 'Enquiry Type', field: 'etv' },
]

const statisFilters = [
    'user',
    'makeModels',
    'salesType',
    'leadSource',
    'inboundSource'
];

const MarketingROI = (props) => {
    const [loader, setLoader] = useState(true)
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d),
        date: moment().format('YYYY-MM-DD'),
        monthYear: moment().format('MMM-YYYY')
    })
    const [modelShow, setModelShow] = useState({
        enquiry: false,
        testdrive: false,
        activity: false
    })
    const [filter, setFilter] = useState(null)
    const [refreshData, setRefreshData] = useState(true)
    const [filterShow, setShow] = useState(false)
    const [showCount, setShowCount] = useState(false)
    const node = useRef();
    const [csvData, setCSVData] = useState([])
    const [counts, setCounts] = useState([])
    const [objFilter, setObjFilter] = useState(defaultFilters)

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');


    const [schedueReport, setSchedeReport] = useState({});
    const [multiSchedule, setMultiSchedule] = useState([]);
    const [popover, setPopover] = useState({ show: false, target: null })
    const [allData, setAllData] = useState([]);
    const [reportData, setReportData] = useState([])
    const [reportDoc, setReportDoc] = useState({})
    const [textEditor, setTexteditor] = useState({})
    const [editFields, setEditFields] = useState({})
    const [settingsShow, setsettingsShow] = useState({
        show: false
    })
    const [popHeader, setPopHeader] = useState()
    const [filterData, setFilterData] = useState(false)

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
        return () => {
            document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
        }
    }, [])

    useEffect(() => {

        const refScheduleReport = window.firebase.firestore().doc(`schedular/marketing-${props.dealersettings.client.id}`)
            .onSnapshot(doc => {
                if (doc.exists && doc.data().enabled === true && !_.isEmpty(doc.data().cron)) {
                    setSchedeReport(doc.data())
                    if (doc.data().multiSchedule?.length > 0) {
                        let refPromise = [];
                        doc.data().multiSchedule.forEach(rpt => {
                            refPromise.push(window.firebase.firestore().doc(`schedular/${rpt}`).get())
                        })
                        Promise.all(refPromise)
                            .then(docs => {
                                let rpts = [];
                                docs.forEach(doc => {
                                    if (doc.exists) {
                                        let arrCron = [];
                                        if (doc.data().cron && doc.data().cron !== '* * * * *') {
                                            arrCron = doc.data().cron.split(' ');
                                        }
                                        rpts.push({
                                            ...doc.data(),
                                            schedulartime: arrCron.length > 0 ?
                                                window.firebase.firestore.Timestamp.fromDate(new Date(`${moment().format('YYYY-MM-DD')} ${arrCron[1]}:${arrCron[0]}`)) : null
                                        })
                                    }
                                })
                                setMultiSchedule([
                                    doc.data(),
                                    ...rpts
                                ]);
                            })
                    }
                }
                else {
                    setSchedeReport(null)
                }
            })
        return () => {
            refScheduleReport && refScheduleReport();
        }

    }, [])

    const handleClick = e => {
        if ((node && node.current && node.current.contains(e.target)) || e.target.classList.contains('ant-picker-cell-inner') || e.target.tagName === 'path' || e.target.tagName === 'svg' || e.target.classList.contains('basic-select__indicator')) {
            return;
        }
        setShow(false);
    };

    useEffect(() => {
        if (_.isEmpty(reportData))
            return

        let _headers = [];
        let fontFamily = 'Noto Sans'
        let _borderStyle = {
            border: {
                top: {
                    style: "thin",
                    color: { rgb: "000000" }
                },
                bottom: {
                    style: "thin",
                    color: { rgb: "000000" }
                },
                left: {
                    style: "thin",
                    color: { rgb: "000000" }
                },
                right: {
                    style: "thin",
                    color: { rgb: "000000" }
                }
            }
        }
        let _headerStyle = {
            style:
            {
                font: {
                    sz: 10,
                    bold: false,
                    color: { rgb: "FFFFFF" },
                    name: fontFamily
                },
                fill: { patternType: "solid", fgColor: { rgb: "000000" } },
                ..._borderStyle

            }
        }
        let _headerStyleCenter = {
            style: {
                ..._headerStyle.style,
                alignment: {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
        }
        let _cellStyle = {
            style: {
                font: {
                    sz: 10,
                    name: fontFamily
                },
                ..._borderStyle
            }
        };
        let _cellStyleCenter = {
            style: {
                ..._cellStyle.style,
                alignment: {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
        };
        let _numStyle = { style: { font: { sz: 11 } }, numFmt: "0" };
        let _footerStyle = {
            style:
            {
                font: {
                    sz: 10,
                    bold: false,
                    name: fontFamily
                },
                fill: { patternType: "solid", fgColor: { rgb: "ffeede" } },
                ..._borderStyle
            }
        };
        let _footerStyleCenter = {
            style: {
                ..._footerStyle.style,
                alignment: {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
        };

        _headers.push({ title: 'Type', alignLeft: true, dynValue: 'groupValue', dynWidth: 10, ..._headerStyle })
        _headers.push({ title: 'Name', alignLeft: true, dynValue: 'name', dynWidth: 10, ..._headerStyle })
        _headers.push({ title: 'Lead Target', dynValue: 'target', dynWidth: 10, ..._headerStyleCenter })
        _headers.push({ title: 'Lead Actual', dynValue: 'newLeads', dynWidth: 10, ..._headerStyleCenter })
        _headers.push({ title: 'Delta', dynValue: 'delta', dynWidth: 10, ..._headerStyleCenter })
        _headers.push({ title: 'Lead Cost', currency: true, dynValue: 'leadCost', dynWidth: 10, ..._headerStyleCenter })
        _headers.push({ title: 'Activity Cost', currency: true, dynValue: 'totalCost', dynWidth: 10, ..._headerStyleCenter })
        _headers.push({ title: 'Cost Per Lead', currency: true, dynValue: 'costperlead', dynWidth: 10, ..._headerStyleCenter })
        _headers.push({ title: 'Sales', dynValue: 'soldLeads', dynWidth: 10, ..._headerStyleCenter })
        _headers.push({ title: 'Cost per Sales', currency: true, dynValue: 'costperwon', dynWidth: 10, ..._headerStyleCenter })
        _headers.push({ title: 'Lost', dynValue: 'lostLeads', dynWidth: 10, ..._headerStyleCenter })
        _headers.push({ title: 'Cost per Lost', currency: true, dynValue: 'costperlost', dynWidth: 10, ..._headerStyleCenter })

        var exportData = []
        var totals = {
            id: "totals",
            groupValue: "Totals",
            name: `${moment.unix(dateRange.startDate.seconds).format('DD MMM')} - ${moment.unix(dateRange.endDate.seconds).format('DD MMM YYYY')} `,
            newLeads: _.sumBy(reportData, "newLeads.actual"),
            soldLeads: _.sumBy(reportData, "soldLeads.actual"),
            lostLeads: _.sumBy(reportData, "lostLeads.actual"),
            delta: _.sumBy(reportData, "delta"),
            target: _.sumBy(reportData, "target"),
            leadCost: `${currencySymbol}${Math.round((_.sumBy(reportData, "leadCost") + Number.EPSILON) * 100) / 100}`,
            totalCost: `${currencySymbol}${Math.round((_.sumBy(reportData, "totalCost") + Number.EPSILON) * 100) / 100}`,
            costperlead: `${currencySymbol}${Math.round((_.sumBy(reportData, "costperlead") + Number.EPSILON) * 100) / 100}`,
            costperwon: `${currencySymbol}${Math.round((_.sumBy(reportData, "costperwon") + Number.EPSILON) * 100) / 100}`,
            costperlost: `${currencySymbol}${Math.round((_.sumBy(reportData, "costperlost") + Number.EPSILON) * 100) / 100}`,
        };

        reportData.forEach(rec => {
            let arrCols = []
            _headers.forEach(col => {
                arrCols.push({ value: `${col.currency === true ? currencySymbol : ''}${rec[col.dynValue]?.actual >= 0 ? rec[col.dynValue]?.actual : rec[col.dynValue]}`, ...(col.alignLeft ? _cellStyle : _cellStyleCenter), dynValue: col.dynValue, dynWidth: _.isString(rec[col.dynValue]) ? rec[col.dynValue].toString().length : 10 })
            })
            exportData.push(arrCols)
        })
        if (exportData.length > 0) {
            let arrCols = []
            _headers.forEach(col => {
                arrCols.push({ value: `${totals[col.dynValue]}`, ...(col.alignLeft ? _footerStyle : _footerStyleCenter), dynValue: col.dynValue, dynWidth: _.isString(totals[col.dynValue]) ? totals[col.dynValue].toString().length : 10 })
            })
            exportData.push(arrCols)
        }

        _headers.forEach(rec => {
            var w = _.maxBy(exportData.map(arrrec => { return { ...arrrec.find(r => r.dynValue === rec.dynValue) } }), 'dynWidth')
            _headers = _headers.map(h => {
                return h.dynValue === rec.dynValue && w && w.dynWidth > h.dynWidth ? {
                    ...h,
                    'width': { wch: w.dynWidth + 5 }
                } : h.width ? {
                    ...h
                } : {
                    ...h,
                    'width': { wch: h.dynWidth + 5 }
                }
            })
        })
        setCSVData([{
            columns: [..._headers.map(r => { return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 } })],
            data: [...exportData]
        }])
    }, [reportData])


    useEffect(() => {
        window.firebase.firestore().doc(`reports/marketing-${props.dealersettings.client.id}`)
            .onSnapshot(snap => {
                if (snap.exists) {
                    setReportDoc(snap.data())
                } else {
                    window.firebase.firestore().doc(`reports/marketing-${props.dealersettings.client.id}`).set({
                        documentID: `marketing-${props.dealersettings.client.id}`,
                        clientID: props.dealersettings.client.id,
                        name: 'Marketing ROI Report'
                    }, { merge: true })
                }
            })
    }, [])

    useEffect(() => {
        if (!refreshData)
            return

        setLoader(true)
        try {
            const reportData = window.firebase.functions().httpsCallable('generic-getData');
            reportData({
                type: 'getSummaryReport',
                params: JSON.stringify({
                    clientID: props.dealersettings.client.id,
                    startDate: moment.unix(dateRange.startDate.seconds).format('YYYY-MM-DD'),
                    endDate: moment.unix(dateRange.endDate.seconds).format('YYYY-MM-DD'),
                    hideTradein: true,
                    hideActivities: true,
                    hideWalkin: true,
                    hideTestdrive: true
                })
            }).then((response) => {
                setLoader(false)
                setRefreshData(false)
                if (response.data.success) {
                    setAllData(response.data.data)
                }
                else {
                    Swal.fire('Something went wrong', '', 'error')
                }
            });
        }
        catch (error) {
            setLoader(false)
            Swal.fire('Something went wrong', '', 'error')
        }
    }, [refreshData])

    useEffect(() => {
        if (refreshData)
            return

        const _allData = allData.filter(a =>
            (objFilter?.user?.length ? objFilter.user.includes(a.uid) : true) &&
            (objFilter?.make ? a.mk && objFilter.make.trim().toLowerCase() === a.mk.trim().toLowerCase() : true) &&
            (objFilter?.model?.length ? objFilter.model.includes(a.ml) : true) &&
            (objFilter?.salesType?.length ? objFilter.salesType.includes(a.st) : true) &&
            (objFilter?.dmstype?.length ? a.tags && _.filter(JSON.parse(a.tags), (v) => _.indexOf(objFilter.dmstype, v.type) >= 0) : true)
        )
        const _data = [];
        options.forEach(option => {
            let i = 0;
            const dynOptions = props?.dealersettings?.client?.settings?.[option.value] || [];
            dynOptions.filter(a => reportDoc?.[option.value]?.length ? reportDoc[option.value].includes(a.value) : false).forEach(rec => {
                const leadCount = checkDataFilter(_allData.filter((a) => a.col === 'enq' && a.stgv !== 'Archived' && a[option.field] === rec.value), dateRange.startDate.seconds, dateRange.endDate.seconds);
                const wonCount = checkDataFilter(_allData.filter((a) => a.col === 'enq' && a.stgv !== 'Archived' && a[option.field] === rec.value), dateRange.startDate.seconds, dateRange.endDate.seconds, 'sold', 'wd');
                const lostCount = checkDataFilter(_allData.filter((a) => a.col === 'enq' && a.stgv !== 'Archived' && a[option.field] === rec.value), dateRange.startDate.seconds, dateRange.endDate.seconds, 'lost', 'ld');
                const leadCost = reportDoc?.[`${option.value}Cost`]?.[rec.value]?.leadCost || 0;
                const target = reportDoc?.[`${option.value}Cost`]?.[rec.value]?.targetMonth?.[dateRange.monthYear] || reportDoc?.[`${option.value}Cost`]?.[rec.value]?.target || 0;
                const totalCost = leadCost > 0 ? leadCount.actual * leadCost : reportDoc?.[`${option.value}Cost`]?.[rec.value]?.totalCost || 0;
                const costperlead = totalCost > 0 && leadCount.actual > 0 ? _.round(totalCost / leadCount.actual, 2) : 0;
                const costperwon = totalCost > 0 && wonCount.actual > 0 ? _.round(totalCost / wonCount.actual, 2) : 0;
                const costperlost = totalCost > 0 && lostCount.actual > 0 ? _.round(totalCost / lostCount.actual, 2) : 0;
                _data.push({
                    groupKey: option.value,
                    groupName: i === 0 ? option.title : null,
                    groupValue: option.title,
                    name: `${rec.name} ${!rec.active ? '(In Active)' : ''} `,
                    value: rec.value,
                    newLeads: leadCount,
                    soldLeads: wonCount,
                    lostLeads: lostCount,
                    delta: target > 0 ? leadCount.actual - target : 0,
                    leadCost,
                    totalCost,
                    costperlead,
                    costperwon,
                    costperlost,
                    target
                })
                i++;
            });
        })
        setReportData(_data);
    }, [allData, reportDoc, filterData])

    const checkDataFilter = (_data, startDt, endDt, status, dateType, newlead, saleType) => {
        const filterData = status === 'sold' || status === 'delivered' ? _.map(_data.filter((a) =>
            ((status === 'sold') ? (a.sts && (a.sts.toLowerCase() === 'won' || a.sts.toLowerCase() === 'delivered')) : true)
            && ((status === 'delivered') ? (a.sts && a.sts.toLowerCase() === 'delivered') : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        )) : _.map(_data.filter((a) =>
            ((status === 'lost') ? (a.sts && (a.sts.toLowerCase() === 'lost' || a.sts.toLowerCase() === 'prospectlost' || a.sts.toLowerCase() === 'pendinglost')) : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        ));
        return {
            arractual: _.uniqBy(filterData, 'eid').map(r => { return r.eid }),
            actual: status === 'sold' || status === 'delivered' ? _.sumBy(_.uniqBy(filterData, 'eid'), 'wc') : _.uniqBy(filterData, 'eid').length
        }
    }


    const handleDateRangeChange = (val) => {
        if (val) {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
            })
        }
        else {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
            })
        }
    }

    const handleModelClose = () => {
        setFilter(null)
        setModelShow({
            enquiry: false,
            testdrive: false,
            activity: false
        })
    }

    const filterCounts = () => {

        var FilterCount = Object.keys(_.pickBy(objFilter, function (value, key) {
            return !_.isEmpty(value);
        }));

        return (FilterCount.length > 0 && showCount) ? (<span className="badge badge-pill badge-advanced-filter">{FilterCount.length}</span>) : (<></>)
    }

    const saveEnquiryField = (name, value) => {
        if (name === 'target') {
            window.firebase.firestore().doc(`reports/${reportDoc.documentID}`).update({
                [`${textEditor.groupKey}Cost.${textEditor.value}.${name}`]: Number(value),
                [`${textEditor.groupKey}Cost.${textEditor.value}.targetMonth.${dateRange.monthYear}`]: Number(value)
            }).then(snapshot => {
                setTexteditor({});
                setEditFields(null);
            });
        } else {
            window.firebase.firestore().doc(`reports/${reportDoc.documentID}`).update({
                [`${textEditor.groupKey}Cost.${textEditor.value}.${name}`]: Number(value)
            }).then(snapshot => {
                setTexteditor({});
                setEditFields(null);
            });
        }
    }

    const cancelEnquiryField = (name, value) => {
        setTexteditor({});
        setEditFields(null);
    }

    const handleNumberChange = (e) => {
        var regexp = /^[0-9+ \b]+$/;
        let number = e.floatValue;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setEditFields(number);
        }
    };

    return (
        <>
            <div className="middle-wrapper">
                <div className="report-page-head mt-3">
                    <h3>Marketing ROI Report
                        <div className='float-right'>
                            <div className='enquiry-view-more float-right mt-0 dropdown'>
                                <a href="#" className="dropdown-toggle common-button" onClick={(e) => {
                                    e.preventDefault();
                                    setsettingsShow({
                                        show: true,
                                        title: 'Settings',
                                        report: reportDoc
                                    })
                                }}>
                                    <i className="ico icon-settings"></i>
                                </a>
                            </div>
                        </div>
                        <Dropdown className='enquiry-view-more float-right mt-0'>
                            <Dropdown.Toggle as={CustomToggle} className='common-button'>
                                <i className='ico icon-more'></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={CustomMenu}
                                ChildClass="more-dropdown dropsub-fix"
                                xplacement="bottom-end"
                            >
                                {
                                    <Dropdown.Item eventKey="2" as={'div'} bsPrefix={'li-excel'}>
                                        <ExcelFile element={<a href="#"><i className="ico icon-excel-download"></i> Export Excel</a>} filename={`marketing-report`}>
                                            <ExcelSheet dataSet={csvData} name="Sheet1" />
                                        </ExcelFile>
                                    </Dropdown.Item>
                                }
                                <Dropdown.Item eventKey="4" onClick={(e) => {
                                    setModelShow({
                                        ...modelShow,
                                        sheduleReport: true
                                    })
                                }}><i className="ico icon-email"></i> Schedule Report </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        {
                            !_.isEmpty(schedueReport) ? (
                                <div className="reportsuccess-item-btn mr-2 float-right cursor-pointer" onClick={(e) => {
                                    if (schedueReport?.multiSchedule?.length > 0) {
                                        setPopover({ show: true, target: e.target })
                                    }
                                }}>
                                    <div className="reportsuccess-logo"><i className="ico icon-files-document"></i></div>
                                    <div className="reportsuccess-inner-text">
                                        <div className="reportsuccess-inner-text-top"> Scheduled </div>
                                        {
                                            schedueReport?.multiSchedule?.length > 0 ? (
                                                <div className="reportsuccess-inner-text-sub">{`${schedueReport.multiSchedule.length + 1} Scheduler is Active`}</div>
                                            ) : (
                                                <div className="reportsuccess-inner-text-sub">{`${schedueReport.frequency === 'daily' ? 'Daily' : ''} ${cronstrue.toString(schedueReport.cron)} `}</div>
                                            )
                                        }
                                    </div>
                                    <div className="reportsuccess-sent-right"><img src={_images.meSent} alt="" width="22" /></div>
                                </div>
                            ) : (<></>)
                        }
                    </h3>
                </div>
                <div className="divider-line"></div>
                <div className="report-content-head">

                    <div className="row">
                        <div className="col-sm-12 col-md-9">
                            <div className="form-style d-block">
                                <div className="form-row">
                                    <div className="form-group col-md-2 daterange-fix">
                                        {/* <AntDateRangePicker
                                            value={dateRange ? [moment.unix(dateRange.startDate.seconds), moment.unix(dateRange.endDate.seconds)] : null}
                                            id={"dashboard-date-range"}
                                            name={'leadkpi-date'}
                                            format='DD/MM/YYYY'
                                            onChange={(e) => { handleDateRangeChange(e, 'leadkpi-date') }}
                                            placeholder='DD/MM/YYYY'
                                        /> */}
                                        <AntDatePicker
                                            value={moment(dateRange.date)}
                                            name={'date-range-date'}
                                            onChange={(val) => {
                                                setDateRange({
                                                    date: moment(val).format('YYYY-MM-DD'),
                                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(val).startOf('month').toDate()),
                                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(val).endOf('month').toDate()),
                                                    monthYear: moment(val).format('MMM-YYYY')
                                                })
                                                setRefreshData(true)
                                            }}
                                            format={'MMMM YYYY'}
                                            allowClear={false}
                                            placeholder='DD/MM/YYYY'
                                            className={`form-control`}
                                            picker={'month'}
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <div>
                                            {/* <button type="button" className="btn btn-primary" onClick={() => {
                                                setRefreshData(true)
                                            }}> <i className="ico icon-search"></i> Search</button> */}
                                            <button type="button" className="btn btn-default ml-1" onClick={() => {
                                                setDateRange({
                                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d),
                                                    date: moment().format('YYYY-MM-DD'),
                                                    monthYear: moment().format('MMM-YYYY')
                                                })
                                                setShowCount(false)
                                                setObjFilter(defaultFilters)
                                                setRefreshData(true)
                                            }}>Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <div className="float-right">
                                <Dropdown ref={node} alignRight show={filterShow}>
                                    <div className="advanced-filter-btn mr-2 float-right">
                                        <Dropdown.Toggle as={CustomToggle} className="dropdown" onClick={() => {
                                            setShow(!filterShow)
                                        }}>
                                            {filterCounts()}
                                            <i className="ico icon-filter"></i>Filters
                                        </Dropdown.Toggle>
                                    </div>
                                    <Dropdown.Menu as={CustomFilter}
                                        id="table-reports-cols-settings-pipeline"
                                        className="dropdown-menu dropdown-menu-left"
                                        ChildClass="form-style quick-filter-wrap"
                                        xplacement="bottom-end"
                                    >
                                        <div className="quick-filter-body mt-1 h-100 overflow-inherit">
                                            <div className="report-filter-wraper form-style report-filter-height-fix">
                                                {
                                                    statisFilters.map((rec, i) => {
                                                        return <DynamicFilters
                                                            key={i}
                                                            {...props}
                                                            filter={rec}
                                                            objFilter={objFilter}
                                                            columns={[]}
                                                            setObjFilter={(e, name) => {
                                                                setObjFilter({
                                                                    ...objFilter,
                                                                    [name]: e ? e.value : ''
                                                                })
                                                            }}
                                                            setMultibjFilter={(selectedOptions, name) => {
                                                                let value = [];
                                                                !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                                                                    value.push(data.value)
                                                                })
                                                                setObjFilter({
                                                                    ...objFilter,
                                                                    [name]: value
                                                                });
                                                            }}
                                                        />
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="quick-filter-footer">
                                            <button type="button" className="btn btn-default float-left" onClick={() => {
                                                setDateRange({
                                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                                })
                                                setShow(false)
                                                setShowCount(false)
                                                setObjFilter(defaultFilters)
                                                setFilterData(!filterData)
                                                setLoader(true);
                                                setTimeout(async function () {
                                                    setLoader(false);
                                                }, 500)
                                            }}>Clear</button>
                                            <button type="button" className="btn btn-primary float-right" onClick={() => {
                                                setShow(false)
                                                setShowCount(true)
                                                setFilterData(!filterData)
                                                setLoader(true);
                                                setTimeout(async function () {
                                                    setLoader(false);
                                                }, 500)
                                            }}>Apply</button>

                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    loader ? (<div className="mt-5" style={{ height: `${window.innerHeight - 340} px` }}><ContentOverlay active={true} /></div>) : reportData?.length === 0 ? (
                        <div className="common-table">
                            <div className="text-center p-5">
                                <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                <div className="no-data-txt mt-2"> No records found</div>
                            </div>
                        </div>
                    ) : (<div className="common-table" style={{ overflowX: 'auto' }}>

                        <div className="common-table">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th colSpan="2" className="head-light" scope="col" width="15%"></th>
                                        <th scope="col" className="head-light text-center" width="8.5%">Lead Target</th>
                                        <th scope="col" className="head-light text-center" width="8.5%">Lead Actual</th>
                                        <th scope="col" className="head-light text-center" width="8.5%">Delta</th>
                                        <th scope="col" className="head-light text-center" width="8.5%">Lead Cost</th>
                                        <th scope="col" className="head-light text-center" width="8.5%">Activity Cost</th>
                                        <th scope="col" className="head-light text-center" width="8.5%">Cost Per Lead</th>
                                        <th scope="col" className="head-light text-center" width="8.5%">Sales</th>
                                        <th scope="col" className="head-light text-center" width="8.5%">Cost Per Sales</th>
                                        <th scope="col" className="head-light text-center" width="8.5%">Lost</th>
                                        <th scope="col" className="head-light text-center" width="8.5%">Cost Per Lost</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        reportData.map((data, index) => {
                                            return <tr key={index}>
                                                {
                                                    data.groupName ?
                                                        <td rowSpan={_.size(reportData.filter(a => a.groupValue === data.groupName))}><div className="dealership-tbl">{data.groupName}</div></td> : (<></>)
                                                }
                                                <td className="text-left">{data.name}</td>
                                                <td className="text-center ">{
                                                    textEditor[`target-${data.value}`] ? (<div className='form-style'><InputAutoNumber
                                                        id={'data-target'}
                                                        name={`target`}
                                                        allowNegative={false}
                                                        thousandSeparator={false}
                                                        placeholder={''}
                                                        className={`form-control mtdreport-input`}
                                                        onValueChange={(e) => handleNumberChange(e)}
                                                        value={editFields}
                                                        onSaveField={saveEnquiryField}
                                                        onCancelField={cancelEnquiryField}
                                                        returnDefault={true}
                                                    /></div>) : (<>{data?.target || 0}<span className="mtdreport-edit-span" onClick={(e) => {
                                                        e.preventDefault();
                                                        setEditFields(data.target)
                                                        setTexteditor({
                                                            ...data,
                                                            [`target-${data.value}`]: true
                                                        })
                                                    }}><i className="ico icon-edit"></i></span></>)
                                                }</td>
                                                <td className="text-center cursor-pointer" onClick={() => {
                                                    setFilter({
                                                        documentIDs: data?.newLeads?.arractual || []
                                                    })
                                                    setPopHeader('Enquiry List')
                                                    setCounts(data?.newLeads?.arractual?.length || 0)
                                                    setModelShow({
                                                        ...modelShow,
                                                        enquiry: true
                                                    })
                                                }}>{data?.newLeads?.actual || 0}</td>
                                                <td className={`text-center  ${data?.delta < 0 ? 'text-red' : ''}`}>{data?.delta || 0}</td>
                                                <td className="text-center ">{
                                                    textEditor[`leadCost-${data.value}`] ? (<div className='form-style'><InputAutoNumber
                                                        id={'data-leadCost'}
                                                        name={`leadCost`}
                                                        allowNegative={false}
                                                        thousandSeparator={true}
                                                        placeholder={''}
                                                        prefix={currencySymbol}
                                                        className={`form-control mtdreport-input`}
                                                        onValueChange={(e) => handleNumberChange(e)}
                                                        value={editFields}
                                                        onSaveField={saveEnquiryField}
                                                        onCancelField={cancelEnquiryField}
                                                        returnDefault={true}
                                                    /></div>) : (<><NumberFormat
                                                        thousandSeparator={true}
                                                        prefix={currencySymbol}
                                                        displayType={'text'}
                                                        value={data?.leadCost || 0}
                                                    /><span className="mtdreport-edit-span" onClick={(e) => {
                                                        e.preventDefault();
                                                        setEditFields(data.leadCost)
                                                        setTexteditor({
                                                            ...data,
                                                            [`leadCost-${data.value}`]: true
                                                        })
                                                    }}><i className="ico icon-edit"></i></span></>)
                                                }</td>
                                                <td className="text-center ">{
                                                    textEditor[`totalCost-${data.value}`] ? (<div className='form-style'><InputAutoNumber
                                                        id={'data-totalCost'}
                                                        name={`totalCost`}
                                                        allowNegative={false}
                                                        thousandSeparator={true}
                                                        placeholder={''}
                                                        prefix={currencySymbol}
                                                        className={`form-control mtdreport-input`}
                                                        onValueChange={(e) => handleNumberChange(e)}
                                                        value={editFields}
                                                        onSaveField={saveEnquiryField}
                                                        onCancelField={cancelEnquiryField}
                                                        returnDefault={true}
                                                    /></div>) : (<><NumberFormat
                                                        thousandSeparator={true}
                                                        prefix={currencySymbol}
                                                        displayType={'text'}
                                                        value={data?.totalCost || 0}
                                                    /><span className="mtdreport-edit-span" onClick={(e) => {
                                                        e.preventDefault();
                                                        setEditFields(data.totalCost)
                                                        setTexteditor({
                                                            ...data,
                                                            [`totalCost-${data.value}`]: true
                                                        })
                                                    }}><i className="ico icon-edit"></i></span></>)
                                                }</td>
                                                <td className="text-center table-middle-total">
                                                    <NumberFormat
                                                        thousandSeparator={true}
                                                        prefix={currencySymbol}
                                                        displayType={'text'}
                                                        value={data?.costperlead || 0}
                                                    />
                                                </td>
                                                <td className="text-center  cursor-pointer" onClick={() => {
                                                    setFilter({
                                                        documentIDs: data?.soldLeads?.arractual || []
                                                    })
                                                    setCounts(data?.soldLeads?.arractual?.length || 0)
                                                    setPopHeader('Enquiry List')
                                                    setModelShow({
                                                        ...modelShow,
                                                        enquiry: true
                                                    })
                                                }}>{data?.soldLeads?.actual || 0}</td>
                                                <td className="text-center table-middle-total">
                                                    <NumberFormat
                                                        thousandSeparator={true}
                                                        prefix={currencySymbol}
                                                        displayType={'text'}
                                                        value={data?.costperwon || 0}
                                                    />
                                                </td>
                                                <td className="text-center  cursor-pointer" onClick={() => {
                                                    setFilter({
                                                        documentIDs: data?.lostLeads?.arractual || []
                                                    })
                                                    setCounts(data?.lostLeads?.arractual?.length || 0)
                                                    setPopHeader('Enquiry List')
                                                    setModelShow({
                                                        ...modelShow,
                                                        enquiry: true
                                                    })
                                                }}>{data?.lostLeads?.actual || 0}</td>
                                                <td className="text-center table-middle-total">
                                                    <NumberFormat
                                                        thousandSeparator={true}
                                                        prefix={currencySymbol}
                                                        displayType={'text'}
                                                        value={data?.costperlost || 0}
                                                    />
                                                </td>
                                            </tr>
                                        })
                                    }
                                    <tr>
                                        <td className="table-sub-total">Total Leads</td>
                                        <td className="text-left text-size-total">{`${moment.unix(dateRange.startDate.seconds).format('DD MMM')} - ${moment.unix(dateRange.endDate.seconds).format('DD MMM YYYY')} `}</td>
                                        <td className="text-center table-sub-total">{_.sumBy(reportData, 'target')}</td>
                                        <td className="text-center table-sub-total cursor-pointer" onClick={() => {
                                            var IDs = [];
                                            reportData.forEach(rec => {
                                                IDs = [...IDs, ...(rec?.newLeads?.arractual || [])]
                                            })
                                            setFilter({
                                                documentIDs: IDs
                                            })
                                            setCounts(IDs.length)
                                            setPopHeader('Enquiry List')
                                            setModelShow({
                                                ...modelShow,
                                                enquiry: true
                                            })
                                        }}>{_.sumBy(reportData, 'newLeads.actual')}</td>
                                        <td className={`text-center table-sub-total ${_.sumBy(reportData, 'delta') < 0 ? 'text-red' : ''}`}>{_.sumBy(reportData, 'delta')}</td>
                                        <td className="text-center table-sub-total">
                                            <NumberFormat
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                displayType={'text'}
                                                value={_.sumBy(reportData, 'leadCost').toFixed(2)}
                                            />
                                        </td>
                                        <td className="text-center table-sub-total">
                                            <NumberFormat
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                displayType={'text'}
                                                value={_.sumBy(reportData, 'totalCost').toFixed(2)}
                                            />
                                        </td>
                                        <td className="text-center table-sub-total">
                                            <NumberFormat
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                displayType={'text'}
                                                value={_.sumBy(reportData, 'costperlead').toFixed(2)}
                                            />
                                        </td>
                                        <td className="text-center table-sub-total cursor-pointer" onClick={() => {
                                            var IDs = [];
                                            reportData.forEach(rec => {
                                                IDs = [...IDs, ...(rec?.soldLeads?.arractual || [])]
                                            })
                                            setFilter({
                                                documentIDs: IDs
                                            })
                                            setCounts(IDs.length)
                                            setPopHeader('Enquiry List')
                                            setModelShow({
                                                ...modelShow,
                                                enquiry: true
                                            })
                                        }}>{_.sumBy(reportData, 'soldLeads.actual')}</td>
                                        <td className="text-center table-sub-total">
                                            <NumberFormat
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                displayType={'text'}
                                                value={_.sumBy(reportData, 'costperwon').toFixed(2)}
                                            />
                                        </td>
                                        <td className="text-center table-sub-total cursor-pointer" onClick={() => {
                                            var IDs = [];
                                            reportData.forEach(rec => {
                                                IDs = [...IDs, ...(rec?.lostLeads?.arractual || [])]
                                            })
                                            setFilter({
                                                documentIDs: IDs
                                            })
                                            setCounts(IDs.length)
                                            setPopHeader('Enquiry List')
                                            setModelShow({
                                                ...modelShow,
                                                enquiry: true
                                            })
                                        }}>{_.sumBy(reportData, 'lostLeads.actual')}</td>
                                        <td className="text-center table-sub-total">
                                            <NumberFormat
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                displayType={'text'}
                                                value={_.sumBy(reportData, 'costperlost').toFixed(2)}
                                            />
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div >
                    )
                }
            </div>
            <PopUpModal show={modelShow.enquiry}>
                <EnquiryList
                    {...props}
                    show={modelShow.enquiry}
                    counts={counts}
                    handleClose={handleModelClose}
                    title={popHeader}
                    history={props.history}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    filter={filter}
                    isFromDashboard={true}
                ></EnquiryList>
            </PopUpModal>
            <PopUpModal show={modelShow.testdrive}>
                <TestDriveList
                    {...props}
                    show={modelShow.testdrive}
                    counts={counts}
                    handleClose={handleModelClose}
                    title={popHeader}
                    filter={filter}
                    isFromDashboard={true}
                ></TestDriveList>
            </PopUpModal>
            <PopUpModal show={modelShow.activity}>
                <ActivityList
                    {...props}
                    show={modelShow.activity}
                    counts={counts}
                    handleClose={handleModelClose}
                    title={popHeader}
                    filter={filter}
                    isFromDashboard={true}
                ></ActivityList>
            </PopUpModal>
            <PopUpModal show={modelShow.sheduleReport}>
                <ScheduleReport
                    {...props}
                    show={modelShow.sheduleReport}
                    handleClose={handleModelClose}
                    title={popHeader}
                    report={reportDoc}
                    marketing={true}
                ></ScheduleReport>
            </PopUpModal >
            <PopoverPanel
                showpopover={popover.show}
                targetpopover={popover.target}
                title='Scheduler'
                position='left'
                id='popover-schdule-report'
                className='popover-schdule-report'
                closepopover={() => {
                    setPopover({ show: false })
                }}>
                {
                    multiSchedule.map((rec, index) => {
                        return <div key={index} className="reportsuccess-multi-btn mb-2">
                            <div className="reportsuccess-multi-logo">
                                <i className="ico icon-files-document"></i>
                            </div>
                            <div className="reportsuccess-multi-inner-text">
                                <div className="reportsuccess-multi-inner-text-top"> Scheduled </div>
                                <div className="reportsuccess-multi-inner-text-sub"> {`${rec.frequency === 'daily' ? 'Daily' : ''} ${cronstrue.toString(rec.cron)} `}</div>
                            </div>
                        </div>
                    })
                }
            </PopoverPanel>
            <PopUpModal show={settingsShow.show}>
                <SettingsKPIs
                    {...props}
                    {...settingsShow}
                    handleClose={() => {
                        setsettingsShow({
                            show: false
                        })
                    }}
                    options={options}
                />
            </PopUpModal>
        </>
    )
}

export default MarketingROI;