import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'
import { stockAge, columnValues, stockOverview, reportModels } from './viewModel';
import ReportHelper from './reportHelper';
import "../../../styles/fonts/mclarenFonts/stylesheet.scss"
import "../../../styles/stockPipeline.scss";
import { BarChart } from '../../../components';
import CommonHelper from '../../../services/common';
import images from '../../../images';

const StockPipelineReport = (props) => {
    const [loader, setLoader] = useState(true)
    const [client, setClient] = useState({});
    const [clientSettings, setClientSettings] = useState({});
    const [reportData, setReportData] = useState([]);
    const [timezone, setTimezone] = useState();
    const [tblStockAge, setTblStockAge] = useState([]);
    const [rptStockAge, setRptStockAge] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [tblstockOverview, setStockOverview] = useState({
        sold: [],
        notRetailed: [],
        notWholesaled: []
    })
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let module = params.get('module');
    let oemid = params.get('oemid') ? params.get('oemid').split(',') : [];
    let region = params.get('region') ? params.get('region').split(',') : [];
    let subregion = params.get('subregion') ? params.get('subregion').split(',') : [];
    let country = params.get('country') ? params.get('country').split(',') : [];
    let dealer = params.get('dealer') ? params.get('dealer').split(',') : [];
    let showdealer = params.get('showdealer');
    const allClient = localStorage.allClients ? JSON.parse(localStorage.allClients) : [];

    useEffect(() => {
        if (!_.isEmpty(module)) {
            let query = `clientSettings/${oemid}`;
            if (module !== 'oem') {
                query += `/${module}s/${props.clientid}`;
            }
            window.firebase.firestore().doc(query)
                .get()
                .then(async (doc) => {
                    if (doc.exists) {
                        let _timezone = doc.data().timezone ? doc.data().timezone : moment.tz.guess();
                        setTimezone(_timezone);
                        const settingsDoc = await window.firebase.firestore().doc(`clientSettings/${oemid}/currentSettings/${oemid}`).get();
                        setClientSettings(settingsDoc.data());
                        setClient({
                            ...doc.data(),
                            documentID: doc.id
                        });

                    }
                })
        } else {
            window.firebase.firestore().doc(`clients/${props.clientid}`)
                .get()
                .then(async (doc) => {
                    if (doc.exists) {
                        let _timezone = doc.data().timezone ? doc.data().timezone : moment.tz.guess();
                        setTimezone(_timezone);
                        const settingsDoc = await window.firebase.firestore().doc(`clients/${doc.id}/currentSettings/${doc.id}`).get();
                        setClientSettings(settingsDoc.data());
                        setClient({
                            ...doc.data(),
                            documentID: doc.id
                        });

                    }
                })
        }

    }, [])

    useEffect(() => {
        if (_.isEmpty(client))
            return

        let stockcollectionPromise = [];
        if (module && dealer.length > 0) {
            dealer.forEach(rec => {
                let collectionDataRef = window.firebase.firestore().collection('stock')
                    .where('isDeleted', '==', false)
                    .where('clientID', '==', rec)
                    .where('saleType', '==', 'New')

                stockcollectionPromise.push(collectionDataRef.get());
            })
        }
        else {
            let collectionDataRef = window.firebase.firestore().collection('stock')
                .where('isDeleted', '==', false)
                .where('saleType', '==', 'New')
            if (clientSettings?.group?.enableSharedStock === true && client.group) {
                collectionDataRef = collectionDataRef.where('groupID', '==', client.group)
            }
            else {
                collectionDataRef = collectionDataRef.where('clientID', '==', client.documentID)
            }
            stockcollectionPromise.push(collectionDataRef.get());
        }

        Promise.all(stockcollectionPromise)
            .then(allDocs => {
                var querySnapshot = [];
                allDocs.forEach(doc => {
                    querySnapshot.push(...doc.docs);
                });
                const _stocks = [];
                const _unavailablestocks = [];
                querySnapshot.forEach((doc) => {
                    if (doc.data().status === 'unavailable') {
                        _unavailablestocks.push(dataMappingVM({
                            ...doc.data(),
                            documentID: doc.id
                        }));

                    } else {
                        _stocks.push(dataMappingVM({
                            ...doc.data(),
                            documentID: doc.id
                        }));
                    }

                });
                const customerUsage = _.find(clientSettings?.settings?.stockUsage, { name: 'Customer' }) ? _.find(clientSettings?.settings?.stockUsage, { name: 'Customer' }).value : 'Customer';
                setChartData(ReportHelper.getChartData(columnValues));
                setReportData(ReportHelper.getReportData(_stocks, customerUsage, reportModels));
                setTblStockAge(ReportHelper.getStockAge(_stocks, stockAge, reportModels));
                setRptStockAge(ReportHelper.getRptStockAge(_stocks, stockAge, reportModels));
                setStockOverview(ReportHelper.getStockOverview(_stocks, reportModels))
                setLoader(false)
            })

    }, [client])

    const dataMappingVM = (doc) => {
        doc.regDate = doc.regDate && doc.regDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.regDate._seconds)._d) : doc.regDate;
        doc.regExp = doc.regExp && doc.regExp._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.regExp._seconds)._d) : doc.regExp;
        doc.warrantyStartDate = doc.warrantyStartDate && doc.warrantyStartDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.warrantyStartDate._seconds)._d) : doc.warrantyStartDate;
        doc.warrantyExp = doc.warrantyExp && doc.warrantyExp._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.warrantyExp._seconds)._d) : doc.warrantyExp;
        doc.stockInDate = doc.stockInDate && doc.stockInDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.stockInDate._seconds)._d) : doc.stockInDate;
        doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;


        doc.etaDate = doc.etaDate && doc.etaDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.etaDate._seconds)._d) : doc.etaDate;
        doc.fokDate = doc.fokDate && doc.fokDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.fokDate._seconds)._d) : doc.fokDate;
        doc.soldDate = doc.soldDate && doc.soldDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.soldDate._seconds)._d) : doc.soldDate;
        doc.deliveredDate = doc.deliveredDate && doc.deliveredDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveredDate._seconds)._d) : doc.deliveredDate;
        doc.deliveryDate = doc.deliveryDate && doc.deliveryDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveryDate._seconds)._d) : doc.deliveryDate;
        const stock = Object.assign({}, doc);
        stock.regDateValue = doc.regDate
        stock.regExpValue = doc.regExp
        stock.warrantyExpValue = doc.warrantyExp
        stock.warrantyStartDateValue = doc.warrantyStartDate
        stock.stockInDateValue = doc.stockInDate
        stock.addedDateValue = doc.addedDate
        stock.modifiedDateValue = doc.modifiedDate
        stock.addedByValue = doc.addedBy
        stock.statusValue = doc.status
        stock.saleTypeValue = doc.saleType
        stock.locationValue = doc.location
        stock.usageValue = doc.usage
        stock.transmissionValue = doc.transmission
        stock.transportMethodValue = doc.transportMethod
        stock.createdOn = moment.unix(stock.addedDate.seconds).format('DD/MM/YYYY hh:mm A');
        stock.updatedOn = moment.unix(stock.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A');

        if (!_.isEmpty(stock.fokDate))
            stock.strfokDate = moment.unix(stock.fokDate.seconds).format('DD/MM/YYYY');
        if (!_.isEmpty(stock.stockInDate))
            stock.strstockInDate = moment.unix(stock.stockInDate.seconds).format('DD/MM/YYYY');

        stock.statusName = CommonHelper.getNameByValue(clientSettings?.settings?.stockStatus, stock.status, '');
        stock.status = CommonHelper.bindStockLabel(clientSettings?.settings?.stockStatus, stock.status, '');
        // stock.status = (stock.status
        //     ?
        //     <div className={`badge badge-pill badge-${stock.status.trim().toLowerCase()}-fill `}>
        //         {stock.status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
        //     </div>
        //     :
        //     <></>);

        stock.location = CommonHelper.getNameByValue(clientSettings?.settings?.stockLocation, stock.location, '');
        stock.usage = CommonHelper.getNameByValue(clientSettings?.settings?.stockUsage, stock.usage, '');
        stock.transmission = CommonHelper.getNameByValue(clientSettings?.settings?.transmissions, stock.transmission, '');
        stock.transportMethod = CommonHelper.getNameByValue(clientSettings?.settings?.stockTransport, stock.transportMethod, '');
        stock.saleType = CommonHelper.getNameByValue(clientSettings?.settings?.salesType, stock.saleType, '');


        stock.availability = (<div className="Vehicle-availability">
            {stock.isTestDrive ? <div><span>{'Test Drive'}</span></div> : <></>}
            {stock.isLoan ? <div><span>{'Loan'}</span></div> : <></>}
            {stock.isServiceLoan ? <div><span>{'Service Loan'}</span></div> : <></>}
            {stock.isSale ? <div><span>{'Sale'}</span></div> : <></>}
        </div>);


        stock.availabileFor = ((stock.isTestDrive ? 'Test Drive, ' : '') +
            (stock.isLoan ? 'Loan, ' : '') +
            (stock.isServiceLoan ? 'Service Loan, ' : '') +
            (stock.isSale ? 'Sale' : ''));

        stock.addedBy = CommonHelper.getUserNamebyId(clientSettings?.users, stock.addedBy);
        stock.modifiedBy = CommonHelper.getUserNamebyId(clientSettings?.users, stock.modifiedBy);
        stock.statusUpdatedBy = stock.statusModifiedBy ? CommonHelper.getUserNamebyId(clientSettings?.users, stock.statusModifiedBy) : '';

        stock.vehicleModel = `${stock.model}`;
        stock.stockAgeNo = stock.stockInDate ? moment().diff(moment.unix(stock.stockInDate.seconds), 'days') : 0
        stock.wholesaleAgeNo = stock.stockInDate ? moment().diff(moment.unix(stock.stockInDate.seconds), 'days') : 0
        if (!_.isEmpty(module)) {
            stock.dealerName = CommonHelper.getOtherDealerName({ clients: allClient }, stock.clientID);
        }
        return stock
    }

    return loader || _.isEmpty(client) ? (
        <div className='loader-center-screen'>
            <div className="spinner-loader h-100 undefined "><div className=" d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="report-loader spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
        </div>) : (
        <div className="A4 landscape report-data-load">
            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                <div className="print-ico">
                    <a href="#" onClick={() => window.print()}>  <img src={images.print} width="19" height="18" /> <span>Print</span></a>
                </div>
                <div className="header">
                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                            <tr>
                                <td width="50%">

                                    <div className="header-left-align align-left-text">
                                        <div className="header-company"> <div className="header-right"> {`Pipeline Report - ${client.name}`} <br></br>
                                            <span> {moment().tz(timezone).format('DD MMMM YYYY')}</span> </div>
                                        </div>
                                    </div>

                                </td>
                                <td width="50%">
                                    <div className="header-right-align">
                                        <div className="logo-head"> <img src={images.mclaren_logo} height="60" /></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="middle-section-wraper">
                    {
                        allClient && (region.length > 0 || subregion.length > 0 || country.length > 0 || showdealer) ? (
                            <div className="report-filter-data-list">
                                {
                                    region.length > 0 ? (<>
                                        <strong>Region :</strong> <span>{_.uniq(allClient.filter(a => region.includes(a.region)).map(r => { return r.regionName })).join(',')}</span>
                                    </>) : (<></>)
                                }
                                {
                                    subregion.length > 0 ? (<>
                                        <strong>Subregion :</strong> <span>{_.uniq(allClient.filter(a => subregion.includes(a.subregion)).map(r => { return r.subregionName })).join(',')}</span>
                                    </>) : (<></>)
                                }
                                {
                                    country.length > 0 ? (<>
                                        <strong>Country :</strong> <span>{_.uniq(allClient.filter(a => country.includes(a.country)).map(r => { return r.countryName })).join(',')}</span>
                                    </>) : (<></>)
                                }
                                {
                                    dealer.length > 0 && showdealer ? (<>
                                        <strong>Dealer :</strong> <span>{_.uniq(allClient.filter(a => dealer.includes(a.id)).map(r => { return r.name })).join(',')}</span>
                                    </>) : (<></>)
                                }
                            </div>
                        ) : (<></>)
                    }
                    {
                        <>
                            {
                                chartData.map((data, i) => {
                                    return <div key={i}>
                                        <div className='report-section-heading'>{data.name}      </div>
                                        <div className='report-loop-box-wrapper'>
                                            {
                                                data.value.map((rec, j) => {
                                                    return <div key={j} className="col-loop-3">
                                                        <div className="col-report-box">
                                                            <BarChart
                                                                id={`stock-${rec.v}`}
                                                                height={`400px`}
                                                                data={reportData.map(data => {
                                                                    return {
                                                                        ...data,
                                                                        [`${rec.v}data`]: data[rec.v],
                                                                        [rec.v]: data[rec.v].length
                                                                    }
                                                                })}
                                                                columns={[rec.v]}
                                                                dynamicColumns={columnValues}
                                                                xCategory={'name'}
                                                                calculationType={'count'}
                                                                handleShowEnquiryList={(value, data) => {

                                                                }}
                                                                hideLegend={true}
                                                                title={`${rec.n} (${_.sumBy(reportData.map(data => {
                                                                    return {
                                                                        ...data,
                                                                        [rec.v]: data[rec.v].length
                                                                    }
                                                                }), rec.v)})`}
                                                                dynColor={true}
                                                                labelMaxWidth={75}
                                                            />
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                })
                            }
                            <div className='report-section-heading'>
                                {'Age Profile - Wholesale Not Retailed'}
                            </div>
                            <div className='report-loop-box-wrapper'>
                                <div className="col-loop-6">
                                    <div className="col-report-box" style={{ height: '450px', overflow: 'auto', overflowX: 'hidden' }}>
                                        <table className='col-table-loop col-table'>
                                            <thead>
                                                <tr>
                                                    <th className='col-cell-head' width='20%'>Model</th>
                                                    {
                                                        stockAge.map((header, index) => {
                                                            return <th width='13.3%' key={index}>{header.name}</th>
                                                        })
                                                    }
                                                    <th width='13.3%' >Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tblStockAge.map((rows, index) => {
                                                        return (<tr key={index}>
                                                            <td className='col-td-head'>{rows.name}</td>
                                                            {
                                                                stockAge.map((row, index) => {
                                                                    return <td className='' key={index}>{rows[row.value]}</td>
                                                                })
                                                            }
                                                            <td className='cell-bg-light-orange'>{rows.total}</td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td className='col-td-head cell-bg-light-orange'>Total</td>
                                                    {
                                                        stockAge.map((row, index) => {
                                                            return <td className='cell-bg-light-orange' key={index}>{_.sumBy(tblStockAge, row.value)}</td>
                                                        })
                                                    }
                                                    <td className='cell-bg-orange'>{_.sumBy(tblStockAge, 'total')}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-loop-6">
                                    <div className="col-report-box">
                                        <BarChart
                                            id={`stock-age-profile`}
                                            height={`400px`}
                                            data={rptStockAge.map(rec => {
                                                return {
                                                    ...rec,
                                                    [`countdata`]: rec.count,
                                                    ['count']: rec.count.length
                                                }
                                            })}
                                            columns={['count']}
                                            dynamicColumns={stockAge}
                                            xCategory={'name'}
                                            calculationType={'count'}
                                            handleShowEnquiryList={(value, data) => {

                                            }}
                                            hideLegend={true}
                                            title={'Age Profile'}
                                            dynColor={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            {
                                stockOverview.map((rec, index) => {
                                    return <div key={index}>
                                        <div className='report-section-heading'>
                                            {rec.name}
                                        </div>
                                        <div className="report-loop-box-wrapper">
                                            <table className="col-table-loop col-table">
                                                <thead>
                                                    <tr>
                                                        <th width="12%" className='font-bold text-left'>Model</th>
                                                        <th width="8%" className='font-bold text-left'>Model Year</th>
                                                        <th width="8%" className='font-bold text-left'>Sales Order</th>
                                                        <th width="10%" className='font-bold text-left'>Vin No</th>
                                                        <th width="8%" className='font-bold text-left'>Usage</th>
                                                        <th width="10%" className='font-bold text-left'>Status</th>
                                                        <th width="20%" className='font-bold text-left'>Color </th>
                                                        <th width="8%" className='font-bold text-left'>FOK Date </th>
                                                        <th width="8%" className='font-bold text-left'>Wholesale Date </th>
                                                        <th width="8%" className='font-bold text-left'>Days Since Wholesale </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        tblstockOverview[rec.value]?.length > 0 ? tblstockOverview[rec.value].map((rec, index) => {
                                                            return <tr key={index}>
                                                                <td className="text-left ">{rec.model}</td>
                                                                <td className="text-left">{rec.year}</td>
                                                                <td className="text-left ">{rec.stockNo}{
                                                                    !_.isEmpty(rec.dealerName) ? (
                                                                        <span className="tbl-sub-dealer">{rec.dealerName}</span>
                                                                    ) : (<></>)
                                                                }</td>
                                                                <td className="text-left">{rec.vinNo}</td>
                                                                <td className="text-left">{rec.usage}</td>
                                                                <td className="text-left">{rec.status}</td>
                                                                <td className="text-left">{rec.extColor}</td>
                                                                <td className="text-left">{rec.strfokDate}</td>
                                                                <td className="text-left">{rec.strstockInDate}</td>
                                                                <td className="text-left">{rec.wholesaleAgeNo > 0 ? rec.wholesaleAgeNo : 0}</td>
                                                            </tr>
                                                        }) : (<>
                                                            <tr>
                                                                <td colSpan={10}>
                                                                    <div className="spinner-loader">
                                                                        <div className="no-data-flex justify-content-center align-items-center ">
                                                                            <div className="no-data-txt mt-2"> No data</div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>)
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                })
                            }
                        </>
                    }
                </div>
                <div className="footer">
                    <div className="footer-bottom">
                        <p>
                            <span style={{ paddingLeft: 0 }} >
                                {client.address ? <>{client.address}</> : <></>}
                            </span>
                            {/* <span style={{ paddingLeft: 0 }}>
                                {client.phone ? <>Tel: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                {client.website ? <>Web: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                            </span> */}
                        </p>
                    </div>
                    <div className="footer-cpy">
                        <div className="footer-left">Data source: Live feed from McLaren Retailer Portal | Issued On - {moment().tz(timezone).format('DD/MM/YYYY hh:mm A')}</div>
                        <div className="footer-right">{'Powered by FusionSD.'}</div>
                    </div>
                    <div className="clear"></div>
                </div>
            </section>
        </div>
    )
}

export default StockPipelineReport;