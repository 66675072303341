/** LIBRARIES */
import React, { useState } from 'react';
import _ from 'lodash'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
import moment from 'moment';
/** COMPONENTS */
import Translate from '../../../../constants/translate';
import uuid from 'react-uuid';
import { ReactMultiSelect, ReactSelect } from '../../../../components';

const MultiOEMAccess = props => {
    const state = props.subList || [];
    const [addSubList, setAddSubList] = useState({})
    const [editSubList, setEditSubList] = useState({})
    const [textEditor, setTexteditor] = useState({})
    const [errorFields, setErrorFields] = useState({})

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const onDragEnd = (result) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        const [removed] = state.splice(result.source.index, 1);
        state.splice(result.destination.index, 0, removed);
        props.setSubList(state)
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging && ("#e6eaf2"),
        ...draggableStyle,
    })

    const handleSublistSave = (rec, newlist, index) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        props.cols.filter(a => a.mandatory).map(r => { return r.value }).forEach(key => {
            if (_.isEmpty(rec?.[key]?.toString())) {
                formIsValid = false;
                errors[`${index >= 0 ? 'edit' : ''}${key}`] = errorClass;
            }
        });
        setErrorFields(errors)
        // if (newlist && state.some(item => item === rec)) {
        //     Swal.fire('Options with same name already exists', '', 'error')
        //     formIsValid = false;
        // }
        if (!formIsValid) {
            return;
        }
        if (newlist && props.createUUID) {
            rec.id = uuid()
        }
        let addList = newlist ? [...state, rec] : [
            ..._.map(state, function (obj, i) {
                return i === index ? rec : obj;
            })];
        props.setSubList(addList)
        setAddSubList({})
        setEditSubList(null)
        setTexteditor({})

    }

    const handleOnSubChange = (e, edit) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            if (edit) {
                setEditSubList({
                    ...editSubList,
                    [name]: newarray1.join(' ')
                });

            } else {
                setAddSubList({
                    ...addSubList,
                    [name]: newarray1.join(' ')
                });
            }
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            if (edit) {
                setEditSubList({
                    ...editSubList,
                    [name]: str
                });

            } else {
                setAddSubList({
                    ...addSubList,
                    [name]: str
                });
            }
        }
        setErrorFields({
            ...errorFields,
            [`${edit ? 'edit' : ''}${name}`]: ''
        });
    }

    const onCurrencyChange = (e, name, edit, formulaCols, destCol) => {
        let _value = e.floatValue
        // console.log('onAmountChange value ', _value)
        const rec = edit ? editSubList : addSubList;
        if (formulaCols && destCol) {
            let val = _value;
            formulaCols.split('+').forEach(r => {
                val += rec?.[r] || 0
            })
            rec[destCol] = val;
        }

        if (_value === undefined) {
            _value = ''
        }
        if (edit) {
            setEditSubList({
                ...rec,
                [name]: _value
            });

        } else {
            setAddSubList({
                ...rec,
                [name]: _value
            });
        }

        setErrorFields({
            ...errorFields,
            [`${edit ? 'edit' : ''}${name}`]: ''
        });
    }

    const handleReactSelectChange = (e, data, edit) => {
        if (edit) {
            setEditSubList({
                ...editSubList,
                [data.name]: e?.value || ''
            });

        } else {
            setAddSubList({
                ...addSubList,
                [data.name]: e?.value || ''
            });
        }
        if (e?.value) {
            props.setSelectedOEM(e.value)
        }
        setErrorFields({
            ...errorFields,
            [`${edit ? 'edit' : ''}${data.name}`]: ''
        });
    }

    const handleReactMultiSelectChange = (selectedOptions, name, edit) => {
        const value = [];

        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        if (edit) {
            setEditSubList({
                ...editSubList,
                [name]: value
            });

        } else {
            setAddSubList({
                ...addSubList,
                [name]: value
            });
        }

        setErrorFields({
            ...errorFields,
            [`${edit ? 'edit' : name}`]: ''
        });
    }

    const handleNumberChange = (e, edit, formulaCols, destCol) => {
        const { name, value } = e.target;
        var regexp = /^[0-9+ \b]+$/;
        let number = value;
        const rec = edit ? editSubList : addSubList;
        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            if (formulaCols && destCol) {
                let val = Number(number);
                formulaCols.split('+').forEach(r => {
                    val += Number(rec?.[r] || 0)
                })
                rec[destCol] = val;
            }

            if (edit) {
                setEditSubList({
                    ...rec,
                    [name]: Number(number)
                });

            } else {
                setAddSubList({
                    ...rec,
                    [name]: Number(number)
                });
            }

            setErrorFields({
                ...errorFields,
                [`${edit ? 'edit' : name}`]: ''
            });
        }


    };


    const handleDateChange = (val, name, edit) => {
        if (edit) {
            setEditSubList({
                ...editSubList,
                [name]: val ? window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d) : null
            });

        } else {
            setAddSubList({
                ...addSubList,
                [name]: val ? window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d) : null
            });
        }
        setErrorFields({
            ...errorFields,
            [`${edit ? 'edit' : name}`]: ''
        });

    };

    const handleCancelSubList = () => {
        setEditSubList({})
        setTexteditor({})
    }

    const handleEditSubList = (rec, index) => {
        setEditSubList(rec)
        setTexteditor({
            [index]: true
        })
    }
    const handleDeleteSubList = (rec, index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                let deleteList = [];
                _.map(state, (e, i) => {
                    return index !== i ? deleteList.push(e) : false
                })
                props.setSubList(deleteList)
            }
        })
    }

    return (
        <>

            <div className="common-table col-md-12 pl-0 pr-0">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" className="head-light" width={`28.3%`}>Oem</th>
                            <th scope="col" className="head-light" width={`28.3%`}>Clients</th>
                            <th scope="col" className="head-light" width={`28.3%`}>Roles</th>
                            <th scope="col" className="head-light border-right-0" width="15%"> Actions</th>
                        </tr>
                        {

                            <tr>
                                <td>
                                    <div className="form-group mb-0">
                                        <ReactSelect
                                            options={props.oem}
                                            name={'oem'}
                                            placeholder={`select oem`}
                                            onChange={handleReactSelectChange}
                                            value={addSubList['oem']}
                                            classNamePrefix={`${errorFields['oem']} cursor-pointer basic-select`}
                                            removeClearable={false}
                                        >
                                        </ReactSelect>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group mb-0">
                                        <ReactMultiSelect
                                            options={props[`${addSubList?.oem}`] || []}
                                            name={'clients'}
                                            placeholder={`select clients`}
                                            onChange={(option) => handleReactMultiSelectChange(option, 'clients')}
                                            value={addSubList['clients'] || []}
                                            classNamePrefix={`${errorFields['clients']} basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group mb-0">
                                        <ReactSelect
                                            options={props[`${addSubList?.oem}-roles`] || []}
                                            name={'roleID'}
                                            placeholder={`select role`}
                                            onChange={handleReactSelectChange}
                                            value={addSubList['roleID']}
                                            classNamePrefix={`${errorFields['roleID']} cursor-pointer basic-select`}
                                            removeClearable={false}
                                        >
                                        </ReactSelect>
                                    </div>
                                </td>
                                <td className="border-right-0">
                                    <div className="button-height-fix">
                                        <button type="button" className="btn btn-primary" onClick={() => { handleSublistSave(addSubList, true) }}> <Translate text={'add'} /></button>
                                    </div>
                                </td>
                            </tr>
                        }
                    </thead>
                    <tbody>
                        {
                            state.length > 0 ?
                                state.map((rec, index) => {
                                    return <tr key={index} >
                                        <td>
                                            {textEditor[index] ? (
                                                <ReactSelect
                                                    options={props['oem']}
                                                    name={'oem'}
                                                    placeholder={`select oem`}
                                                    onChange={(e, data) => handleReactSelectChange(e, data, true)}
                                                    value={editSubList['oem']}
                                                    classNamePrefix={`${errorFields['editoem']} cursor-pointer basic-select`}
                                                    removeClearable={false}
                                                >
                                                </ReactSelect>
                                            ) : (
                                                <>{_.find(props['oem'], { value: rec['oem'] })?.label || ''}</>
                                            )}
                                        </td>
                                        <td>
                                            {textEditor[index] ? (
                                                <ReactMultiSelect
                                                    options={props[`${editSubList?.oem}`] || []}
                                                    name={'clients'}
                                                    placeholder={`select clients`}
                                                    onChange={(option) => handleReactMultiSelectChange(option, 'clients')}
                                                    value={editSubList['clients'] || []}
                                                    classNamePrefix={`${errorFields['editclients']} basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            ) : (
                                                <div className={'Vehicle-availability'}>{
                                                    props[`${rec?.oem}`]?.filter(e => rec['clients']?.includes(e.value)).map((r, index) => {
                                                        return <div key={index}><span>{r.label}</span></div>;
                                                    })
                                                }</div>
                                            )}
                                        </td>
                                        <td>
                                            {textEditor[index] ? (
                                                <ReactSelect
                                                    options={props[`${editSubList?.oem}-roles`] || []}
                                                    name={'roleID'}
                                                    placeholder={`select role`}
                                                    onChange={(e, data) => handleReactSelectChange(e, data, true)}
                                                    value={editSubList['roleID']}
                                                    classNamePrefix={`${errorFields['editroleID']} cursor-pointer basic-select`}
                                                    removeClearable={false}
                                                >
                                                </ReactSelect>
                                            ) : (
                                                <>{_.find(props[`${rec?.oem}-roles`], { value: rec['roleID'] })?.label || ''}</>
                                            )}
                                        </td>
                                        <td className="border-right-0">
                                            <div className="action-fix">
                                                {
                                                    textEditor[index] ?
                                                        (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleSublistSave(editSubList, false, index) }}> <i className="ico icon-check"></i></a></div>
                                                            <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleCancelSubList(rec, index) }}> <i className="ico icon-remove"></i></a></div>
                                                        </>) :
                                                        (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditSubList(rec, index) }}> <i className="ico icon-edit"></i></a></div>
                                                            <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteSubList(rec, index) }}> <i className="ico icon-delete"></i></a></div>
                                                        </>)
                                                }
                                            </div>
                                        </td>

                                    </tr>
                                }) : (<></>)
                        }
                    </tbody>
                </table>
            </div >

        </>


    )
}

export default MultiOEMAccess