export const templateVM = {
    documentID: null,
    title:'',
    type: '',
    bodyHTML: '',
    templateJSON: '',
    clientID: null,
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null,
    isDeleted: false,
    visibility:'',
    oemID : null,
    groupID : null,
    regionID : null,
    rawHTML: '',
}

export const visibilities = [
    { active: true, value: 'private', label: 'Private' },
    { active: true, value: 'shared', label: 'Shared' }
]


export const templatelistFields = [
    {
        name: 'Title',
        value: 'title',
        sortValue: 'title',
        flex: 1,
        default: true
    },
    {
        name: 'Email',
        value: 'emailContent', 
        sortValue: 'emailContent',
        flex: 2,
        default: true
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        sortValue: 'updatedOn',
        subText : 'updatedBy',
        flex: 1,
        default: true
    },
      {
        name: 'Visibility',
        value: 'visibilityDOM',
        width: 80, 
        flex: 0,
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 120,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]


export const smslistFields = [
    {
        name: 'Title',
        value: 'title',
        sortValue: 'title',
        flex: 1,
        default: true
    },
    {
        name: 'Message',
        value: 'message', 
        sortValue: 'message',
        flex: 2,
        default: true
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        sortValue: 'updatedOn',
        subText : 'updatedBy',
        flex: 1,
        default: true
    },
      {
        name: 'Visibility',
        value: 'visibilityDOM',
        width: 80, 
        flex: 0,
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 120,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const mentions = [{
    name : 'First Name', 
    value : '@FIRST_NAME'
},
{
    name : 'Last Name',
    value : '@LAST_NAME'
},
{
    name : 'Preferred Name',
    value : '@PREF_NAME'
},
{
    name : 'Full Name',
    value : '@FULL_NAME'
},
{
    name : 'Company Name',
    value : '@COMPANY_NAME'
},
{
    name : 'User Name',
    value : '@USER_NAME'
},
{
    name : 'User Email',
    value : '@USER_EMAIL'
},
{
    name : 'User Phone',
    value : '@USER_PHONE'
},
{
    name : 'User Rating',
    value : '@USER_RATING'
},
{
    name : 'Designation',
    value : '@USER_DESIGNATION'
},
{
    name : 'Vehicle Type',
    value : '@REQ_TYPE'
},
{
    name : 'Warranty Expiry',
    value : '@WARRANTY_EXPIRY'
},
{
    name : 'Test Drive Vehicle',
    value : '@TESTDRIVE_VEHICLE'
},
{
    name : 'Trade-In Vehicle',
    value : '@TRADEIN_VEHICLE'
},
{
    name : 'Vehicle Reg#',
    value : '@VEHICLE_REGO'
},
{
    name : 'Dealer Name',
    value : '@DEALER_NAME'
},
{
    name : 'Dealer Phone',
    value : '@DEALER_PHONE'
},
{
    name : 'Dealer Email',
    value : '@DEALER_EMAIL'
},
{
    name : 'Dealer Address',
    value : '@DEALER_ADDRESS'
},
{
    name: 'Facebook URL',
    value: '@FACEBOOK_URL'
},
{
    name: 'X URL',
    value: '@X_URL'
},
{
    name: 'Instagram URL',
    value: '@INSTAGRAM_URL'
},
{
    name: 'YouTube URL',
    value: '@YOUTUBE_URL'
},
{
    name: 'Unsubscribe URL',
    value: '@UNSUBSCRIBE_URL'
},
{
    name : 'Schedule Date',
    value : '@SCHEDULE_DATE'
},
{
    name : 'Schedule Time',
    value : '@SCHEDULE_TIME'
},
{
    name : 'Completed Date',
    value : '@COMPLETED_DATE'
},
{
    name : 'Completed Time',
    value : '@COMPLETED_TIME'
},
{
    name: 'Owner Name',
    value: '@OWNER_NAME'
},
{
    name: 'Owner Email',
    value: '@OWNER_EMAIL'
},
{
    name: 'Owner Phone',
    value: '@OWNER_PHONE'
},
{
    name: 'Owner Designation',
    value: '@OWNER_DESIGNATION'
},
{
    name: 'Sales Owner Name',
    value: '@SALESOWNER_NAME'
},
{
    name: 'Sales Owner Email',
    value: '@SALESOWNER_EMAIL'
},
{
    name: 'Sales Owner Phone',
    value: '@SALESOWNER_PHONE'
},
{
    name: 'Sales Owner Designation',
    value: '@SALESOWNER_DESIGNATION'
},
{
    name: 'Current Month',
    value: '@CURRENT_MONTH'
},
{
    name: 'Internal Report',
    value: '@INTERNAL_REPORT_LINK'
},
{
    name: 'External Report',
    value: '@EXTERNAL_REPORT_LINK'
},
{
    name: 'Tradein Report',
    value: '@TRADEIN_REPORT_LINK'
}]