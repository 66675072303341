/** LIBRARIES */
import React, { useState, useEffect, useReducer, useRef } from 'react';
import { Dropdown } from "react-bootstrap";
import _ from 'lodash'
import moment from 'moment';
import toast from 'toasted-notes'
/** PROVIDERS */
/** VIEW-MODELS */
import { objContact } from '../viewModel';
/** COMPONENTS */
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import { PopUpModal, ContentOverlay, TableView, ReactSelect, PopoverPanel } from '../../../components';

import { default as _images } from '../../../images';
import AddAttendee from './add'
import SlotOptions from './slot'
import Swal from 'sweetalert2'

import AttendeeInfo from '../../eventsProAttendees/details'
import MergeAttendee from './merge'
import ContactImport from './import';
import ImportCSV from './importCSV'
import StockListReducer from '../../stock/stockListReducer'
import { firestoreDB } from '../../../services/helper';
import { objAttendees, eventProMapVM } from '../viewModel'
import { allcontactFields, USAFormatExcelFields } from '../../eventsProAttendees/viewModel';
import { eventAttendeeStatus, eventStatus } from '../../../services/enum';
import ReactExport from 'react-export-excel-xlsx-fix';
import ReAuthenticate from '../../common/reAuthenticate';
import { CustomFilter, CustomToggle } from '../../../components/customdropdown';
import DynamicFilters from '../../reports/dynFilters';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const statisFilters = [
    'customclient',
    'source',
    'slot',
    'customstatus',
    'dietary'
];

const Contacts = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 465) : (window.innerWidth - 555), windowHeight: (window.innerHeight - 315) })
    const [attendeeFields, setAttendeeFields] = useState(props.dealersettings.attendeeFields)
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [tableLoader, setTableLoader] = useState(false)
    const [csvHeader, setHeader] = useState([])

    const [attendeeInfoModal, setAttendeeInfoModal] = useState({ show: false, docID: null })
    const [contacts, dispatch] = useReducer(StockListReducer, [])
    const [checkDataLoad, setDataload] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [filterClient, setFilterClient] = useState(null)
    const [filterSource, setFilterSource] = useState(null)
    const [hasMore, setHasMoreData] = useState([])
    const [isPaging, setPaging] = useState(false)
    const [contactLoader, setContactLoader] = useState(true)
    const [showImportCSV, setImportCSV] = useState(false)
    const [eventSettings, setEventSettings] = useState()
    const [contactModal, setcontactModal] = useState({
        show: false,
        id: null,
        title: '',
        clsActive: '',
    })

    const [showContactImport, setShowContactImport] = useState({
        show: false,
        title: '',
        slot: '',
    });
    const [fullPageLoader, setFullPageLoader] = useState({
        show: false,
        title: '',
    });

    const [selectedIds, setSelectedIds] = useState({})
    const [modalMergeShow, setModalMergeShow] = useState({ show: false, mergeContacts: [] })

    const pageLimit = 20;
    const excelLimit = 1000
    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers
    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');
    const defaultCountry = props?.eventsPro?.country ? props.eventsPro.country : props.dealersettings.client.countryCode ? props.dealersettings.client.countryCode.toUpperCase() : 'AU';

    const [allAttendeeFields, setAllAttendeeFields] = useState([])

    const [showReAuthentication, setReAuthentication] = useState(false);
    const [csvData, setCSVData] = useState([])
    const csvBtn = useRef();
    const node = useRef();
    const [filterShow, setShow] = useState(false)
    const [objFilter, setObjFilter] = useState({});
    const [showCount, setShowCount] = useState(false)
    const [notesPopOver, setNotesPopOver] = useState({ show: false })
    const [usaData, setUSAData] = useState([])
    const [usaHeader, setUsaHeader] = useState([])
    const usaBtn = useRef();
    const [sortConfig, setSortConfig] = useState();
    // useEffect(() => {
    //     setAttendeeFields(props.dealersettings.attendeeFields);
    // }, [props.dealersettings.attendeeFields])
    let _allUser = _.union((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers));

    useEffect(() => {
        if (props?.eventsPro?.documentID === props?.eventSettings?.eventsProID) {
            setEventSettings(props.eventSettings);
        }
    }, [props.eventSettings])

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        }
    }, []);

    useEffect(() => {
        if (props.excelDownload) {
            excelUSADownload();
            props.setExcelDownload(false)
        }
    }, [props.excelDownload])

    const handleClick = e => {
        if ((node && node.current && node.current.contains(e.target))
            || e.target.classList.contains('ant-picker-cell-inner')
            || (e?.target?.classList?.value && e.target.classList.value.includes('ant-picker'))
            || e.target.tagName === 'path' || e.target.tagName === 'svg' || e.target.classList.contains('basic-select__indicator')) {
            return;
        }
        setShow(false);
    };

    useEffect(() => {
        setDataload(!props.settingLoading);
    }, [props.settingLoading])

    useEffect(() => {
        let headerFields = attendeeFields;
        let allHeaderFields = allAttendeeFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index >= 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.elementsubName || rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.elementsubName || rec.subText })
            }
        })
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.nestedText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index >= 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.nestedTextTitle, key: rec.nestedText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.nestedTextTitle, key: rec.nestedText })
            }
        })
        setHeader(_headers);

    }, [allAttendeeFields])

    useEffect(() => {
        let headerFields = USAFormatExcelFields;
        var _headers = _.map(headerFields, function (e) {
            return {
                label: e.name,
                key: e.value
            }
        });
        setUsaHeader(_headers);
    }, [])

    useEffect(() => {
        if (_.isEmpty(eventSettings?.attendeesDF)) {
            setAllAttendeeFields([...allcontactFields]);
            return
        }

        let _allcontactFieldsVM = [...allcontactFields];
        eventSettings.attendeesDF.forEach(rec => {
            if (!allAttendeeFields.some(e => e.value === rec.value))
                _allcontactFieldsVM.push({
                    name: rec.name,
                    value: rec.value,
                    flex: 1,
                    default: true,
                    elementName: rec.type === 'multiselect' ? `${rec.value}Value` : rec.value
                })
        })
        var deleteids = []
        _allcontactFieldsVM.forEach(rec => {
            if (!_allcontactFieldsVM.some(e => e.value === rec))
                deleteids.push(rec)
        })
        setAllAttendeeFields([..._allcontactFieldsVM]);
        setAttendeeFields(_.differenceBy(attendeeFields, deleteids))
    }, [eventSettings])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 465) : (window.innerWidth - 555),
                windowHeight: (window.innerHeight - 315)
            })
        }
        window.addEventListener('resize', handleResize);
        ////document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            //window.unSubContacts && window.unSubContacts();
        }
    }, [])


    useEffect(() => {
        if (!checkDataLoad) {
            return;
        }

        const { dealersettings, eventsPro } = props;

        let attlstIdxDataRef = firestoreDB(props.dealersettings).firestore().collection('eventsProAttendees')
            .where('eventsProID', '==', eventsPro.documentID)
            .where('isDeleted', '==', false)

        if (_.trim(searchText) && _.trim(searchText).length > 2) {
            let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)
            if (formattedSearchText.trim())
                attlstIdxDataRef = attlstIdxDataRef
                    .where('keywords', 'array-contains', formattedSearchText.trim().toLowerCase())
        }

        if (objFilter.client) attlstIdxDataRef = attlstIdxDataRef.where('clientID', '==', objFilter.client)
        if (objFilter.source) attlstIdxDataRef = attlstIdxDataRef.where('source', '==', objFilter.source)
        if (objFilter.slot) attlstIdxDataRef = attlstIdxDataRef.where('eventSlot', '==', objFilter.slot)
        if (objFilter.status) attlstIdxDataRef = attlstIdxDataRef.where('status', '==', objFilter.status)
        if (objFilter.dietary) attlstIdxDataRef = attlstIdxDataRef.where('dietaryPreference', '==', objFilter.dietary)

        if (_.isEmpty(objFilter)) {
            let _sortName = (sortConfig?.key || 'addedDate');
            let _sortOrder = (sortConfig?.sort || 'desc');
            attlstIdxDataRef = attlstIdxDataRef.orderBy(_sortName, _sortOrder)
        }

        if (hasMore.length > 0) {
            attlstIdxDataRef = attlstIdxDataRef
                .startAfter(hasMore[0])
                .limit(pageLimit)
        }
        else {
            attlstIdxDataRef = attlstIdxDataRef
                .limit(pageLimit)
        }

        attlstIdxDataRef.onSnapshot(onCollectionUpdate);
        // .get().then(querySnapshot => {
        //     const _contacts = [];

        //     if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
        //         setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
        //     }
        //     else {
        //         setHasMoreData([]);
        //     }
        //     querySnapshot.docs.forEach((doc) => {
        //         _contacts.push(convertAttendeeVM({
        //             ...doc.data(),
        //             documentID: doc.id
        //         }));
        //     });

        //     if (isPaging) {
        //         dispatch({
        //             type: "APPENDCONTACT",
        //             data: _contacts,
        //             sortName: 'firstName',
        //             sortOrder: 'asc'
        //         });
        //     }
        //     else {
        //         dispatch({
        //             type: "SUCCESS",
        //             data: _contacts,
        //             sortName: 'firstName',
        //             sortOrder: 'asc'
        //         });
        //     }

        //     setDataload(false)
        //     setContactLoader(false)
        //     setPaging(false)

        // });


    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = convertAttendeeVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "_ADD";
                }
                else if (change.type === 'modified') {
                    actionType = "_UPDATE"
                }
                else if (change.type === 'removed') {
                    actionType = "_REMOVE"
                }
            }
        })
        const _contacts = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            }
            else {
                setHasMoreData([]);
            }
            querySnapshot.forEach((doc) => {
                _contacts.push(convertAttendeeVM({
                    ...doc.data(),
                    documentID: doc.id
                }));
            });
        }
        if (isPaging) {
            dispatch({
                type: "_APPEND",
                data: _contacts,
                sortName: (sortConfig?.key || 'addedDate'),
                sortOrder: (sortConfig?.sort || 'desc')
            });
        }
        else {
            dispatch({
                type: actionType ? actionType : "_SUCCESS",
                data: actionType ? snapshotDoc : _contacts,
                sortName: (sortConfig?.key || 'addedDate'),
                sortOrder: (sortConfig?.sort || 'desc')
            });
        }

        //console.log('hasMore', hasMore)
        setDataload(false)
        setContactLoader(false)
        setPaging(false)
        setTableLoader(false)

    }


    const convertAttendeeVM = (doc) => {
        const { eventsPro, dealersettings } = props;
        const objcontactData = Object.assign({}, doc);
        const contact = Object.assign({});
        for (let [key, value] of Object.entries(objcontactData)) {
            contact[key] = value;
        }

        contact.objDoc = doc;
        contact.fullName = CommonHelper.getFullName(null, contact, '', true);
        contact.phone = CommonHelper.phoneFormat(doc.phoneCode, doc.phone, props.dealersettings);
        contact.displayPhone = CommonHelper.phoneFormat(doc.phoneCode, doc.phone, props.dealersettings, true);
        contact.displayID = contact.displayID ? contact.displayID : '--';
        contact.csvPhone = contact.displayPhone ? `${contact.displayPhone}` : ''

        contact.licenseExpiry = contact.licenseExpiry ? moment(contact.licenseExpiry).format(`${eventSettings?.dateFormat || 'DD/MM/YYYY'}`) : '';
        contact.createdOn = contact.addedDate ? moment.unix(contact.addedDate.seconds).tz(eventsPro.timezone).format(`${eventSettings?.dateFormat || 'DD/MM/YYYY'} ${eventSettings?.timeFormat || 'hh:mm A'}`) : '';
        contact.updatedOn = contact.modifiedDate ? moment.unix(contact.modifiedDate.seconds).tz(eventsPro.timezone).format(`${eventSettings?.dateFormat || 'DD/MM/YYYY'} ${eventSettings?.timeFormat || 'hh:mm A'}`) : '';
        contact.checkedOn = contact.checkinDate ? moment.unix(contact.checkinDate.seconds).tz(eventsPro.timezone).format(`${eventSettings?.dateFormat || 'DD/MM/YYYY'} ${eventSettings?.timeFormat || 'hh:mm A'}`) : '';

        contact.source = CommonHelper.getNameByValue(eventSettings?.source, contact.source, '');
        contact.dietaryPreference = CommonHelper.getNameByValue(eventSettings?.dietary, contact.dietaryPreference, '');
        //contact.eventSlot = CommonHelper.getNameByValue(eventSettings?.slots, contact.eventSlot, '');
        if (contact.eventSlot) {
            const _eventSlot = _.find(eventSettings?.slots, { value: contact.eventSlot });
            contact.eventSlot = _eventSlot ? moment(_eventSlot.date).format(`${eventSettings?.dateFormat || 'DD/MM/YYYY'}`) : ''
            contact.slotTime = _eventSlot ? `${moment.unix(_eventSlot?.startTime?.seconds).tz(eventsPro.timezone).format(`${eventSettings?.timeFormat || 'hh:mm A'}`)} - ${moment.unix(_eventSlot?.endTime?.seconds).tz(eventsPro.timezone).format(`${eventSettings?.timeFormat || 'hh:mm A'}`)}` : ''
            contact.eventSlotDate = _eventSlot?.name || '';
            contact.slotName = _eventSlot?.slotName || '';
        }
        contact.dataLocation = CommonHelper.getNameByValue(eventSettings?.dataLocation, contact.dataLocation, '');
        if (!_.isEmpty(contact.notes)) {
            let _notes = '';
            let i = 0;
            _.orderBy(contact.notes, 'addedDate.seconds', 'desc').forEach(note => {
                i++;
                _notes += `${note.addedDate ? moment.unix(note.addedDate.seconds).format(`${eventSettings?.dateFormat || 'DD/MM/YYYY'} ${eventSettings?.timeFormat || 'hh:mm A'}`) + ' ' : ''}`;
                _notes += note.notes;
                if (i < contact.notes.length) {
                    _notes += '\n\n'
                }
            })
            contact.lastNote = _notes;
        }

        contact.addedBy = CommonHelper.getUserNamebyId(allUsers, contact.addedBy);
        contact.modifiedBy = CommonHelper.getUserNamebyId(allUsers, contact.modifiedBy);

        if (contact.clientID && !contact.clientName)
            contact.clientName = CommonHelper.getOtherDealerName(dealersettings, contact.clientID);

        if (contact.toClientID && !contact.toClientName)
            contact.toClientName = CommonHelper.getOtherDealerName(dealersettings, contact.toClientID);

        contact.state = CommonHelper.getNameByCode(dealersettings.states.filter(a => contact?.country ? a.country_code === contact.country : true), contact.state, '');
        contact.country = CommonHelper.getNameByCode(dealersettings.countries, contact.country, '');
        contact.licenseState = CommonHelper.getNameByCode(dealersettings.states.filter(e => e.country_code === defaultCountry), doc.licenseState, '');
        contact.licenseType = CommonHelper.getNameByValue(dealersettings.client.settings.licenseType, contact.licenseType, '');
        contact.optinPhone = doc.optinPhone === null ? '' : (doc.optinPhone ? 'Yes' : 'No');
        contact.optinEmail = doc.optinEmail === null ? '' : (doc.optinEmail ? 'Yes' : 'No');
        contact.optinPost = doc.optinPost === null ? '' : (doc.optinPost ? 'Yes' : 'No');
        contact.optinSMS = doc.optinSMS === null ? '' : (doc.optinSMS ? 'Yes' : 'No');

        contact.marketingOptinEmail = doc.marketingOptinEmail === null ? '' : (doc.marketingOptinEmail ? 'Yes' : 'No');
        contact.marketingOptinSMS = doc.marketingOptinSMS === null ? '' : (doc.marketingOptinSMS ? 'Yes' : 'No');

        const enquiryAllStatus = Object.assign([], dealersettings?.client?.settings?.enquiryStatus);
        contact.leadStatusName = CommonHelper.getNameByValue(enquiryAllStatus, doc.leadStatus, '');
        let _objCurrentStatus = doc.leadStatus && enquiryAllStatus.filter(e => e.value === doc.leadStatus)[0];
        contact.leadStatus = !_.isEmpty(doc.leadStatus) ?
            (
                _objCurrentStatus
                    ?
                    <>
                        <span key={doc.leadStatus}
                            className={`status-${doc.leadStatus === 'open' ? 'open' : 'empty'}`}
                            style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                        >
                            {_objCurrentStatus.name}
                        </span>
                    </>
                    :
                    <>
                    </>
            ) : ('');
        contact.statusName = doc.status;
        contact.status = (contact.status
            ?
            <div key={contact.status} className={`badge badge-pill badge-events-${contact.status}`}>
                {contact.status}
            </div>
            :
            <></>);
        contact.checkedStatusName = contact.checkinDate ? 'CHECKED-IN' : '';
        contact.checkedStatus = contact.checkinDate ? <><div className="badge badge-pill badge-available-fill ">CHECKED-IN</div></> : <></>;
        contact.guestTag = contact.guestOf ? <><div className="badge badge-pill badge-white badge-mini">{contact.guestOf}</div></> : <></>;
        contact.strGuestTag = contact.guestOf || '';


        contact.requirements = CommonHelper.getAttendeReq(eventSettings, doc);
        if (contact?.requirements?.length > 0) {
            contact.vehicleModel = (contact.requirements[0].make ? contact.requirements[0].make + ' ' : '') + contact.requirements[0].model;
            contact.vehicleType = contact.requirements[0].saleType;
        }
        if (contact?.ownedVehicle) {
            contact.ownedVehicleModel = (contact.ownedVehicle.make ? contact.ownedVehicle.make + ' ' : '') + (contact?.ownedVehicle?.model || '');
            contact.ownedVehicleYear = contact?.ownedVehicle?.year || '';
        }
        if (contact?.testDrives?.length > 0) {
            const _vehicle = contact.testDrives[0].stock ? contact.testDrives[0].stock : contact.testDrives[0];
            contact.testdriveModel = `${_vehicle.make || ''} ${_vehicle.model || ''} ${_vehicle.year || ''} ${_vehicle.extColor || ''}`;
            contact.testdriveVin = _vehicle.vinNo || '';
            contact.testdriveDone = 'Yes';
            contact.testdriveDate = contact.testDrives[0].startDate ? moment.unix(contact.testDrives[0].startDate.seconds).tz(eventsPro.timezone).format(`${eventSettings?.dateFormat || 'DD/MM/YYYY'} ${eventSettings?.timeFormat || 'hh:mm A'}`) : contact.checkedOn;
        }
        if (!_.isEmpty(doc.dynamicFields)) {

            eventSettings?.attendeesDF && eventSettings.attendeesDF.forEach(rec => {
                if (rec.type === 'toggle') {
                    contact[rec.value] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
                }
                else if (rec.type === 'price') {
                    contact[rec.value] = doc.dynamicFields[rec.value] ? currencySymbol + doc.dynamicFields[rec.value] : ''
                }
                else if (rec.type === 'date') {
                    doc.dynamicFields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.dynamicFields[rec.value]._seconds)._d) : doc.dynamicFields[rec.value] : ''
                    contact[rec.value] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).tz(eventsPro.timezone).format(`${eventSettings?.dateFormat || 'DD/MM/YYYY'}`) : '';
                }
                else if (rec.type === 'monthYear') {
                    contact[rec.value] = doc.dynamicFields[rec.value] ? moment(doc.dynamicFields[rec.value]).format('MMM YYYY') : '';
                }
                else if (rec.type === 'multiselect' && doc.dynamicFields[rec.value]) {
                    contact[`${rec.value}Value`] = _.isArray(doc.dynamicFields[rec.value]) ? doc.dynamicFields[rec.value].join(',') : doc.dynamicFields[rec.value]
                    contact[rec.value] = _.isArray(doc.dynamicFields[rec.value]) ? (<div className="Vehicle-availability">
                        {
                            doc.dynamicFields[rec.value].map((rec, index) => {
                                return <div key={index}><span>{rec}</span></div>
                            })
                        }
                    </div>) : (<div className="Vehicle-availability">{doc.dynamicFields[rec.value]}</div>)
                }
                else {
                    contact[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value] : ''
                }
            })
        }
        contact.emptyValue = '';
        contact.stroptinPhone = doc.optinPhone === null ? '' : (doc.optinPhone ? 'Allow' : 'Do Not Allow');
        contact.stroptinEmail = doc.optinEmail === null ? '' : (doc.optinEmail ? 'Allow' : 'Do Not Allow');
        contact.stroptinPost = doc.optinPost === null ? '' : (doc.optinPost ? 'Allow' : 'Do Not Allow');
        contact.stroptinSMS = doc.optinSMS === null ? '' : (doc.optinSMS ? 'Allow' : 'Do Not Allow');
        contact.eventTitle = eventsPro?.title || '';

        if (!contact.clientID || (contact.clientID && _.find(props.dealersettings?.clients, { documentID: contact.clientID })))
            return contact
        else
            return {
                documentID: contact.documentID,
                clientName: contact.clientName,
                fullName: 'Hidden',
                clientID: contact.clientID,
                addedDate: contact.addedDate,
                checkDisabled: true
            }
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }

    const listenScrollEvent = (e) => {
        if (hasMore.length === 0) return;
        //console.log('listenScrollEvent', e.target.scrollHeight - e.target.scrollTop, e.target.offsetHeight)
        if (e.target.scrollHeight - e.target.scrollTop === e.target.offsetHeight) {
            //console.log('listenScrollEvent')
            //handlePagination();
        }
        return;
    }


    //#region CONTACT CHANGES

    const handleContactOpen = (id, _manFields) => {

        setcontactModal({
            show: true,
            id: id ? id : null,
            title: id ? 'attendee' : 'addAttendee',
            clsActive: 'overlay-modal active',
            mandatoryFields: !_.isEmpty(_manFields) ? _manFields : [],
        })

    }

    const handleContactClose = (ID) => {
        setcontactModal({
            show: false,
            id: null,
            title: '',
            clsActive: ''
        })

    }

    const handleContactImportOpen = () => {

        setShowContactImport({
            ...showContactImport,
            show: true,
            title: 'Import Attendees',
            slot: '',
        })

    }
    const handleContactImportClose = async (selectedContacts, _slot, _dealer) => {

        if (_.isEmpty(selectedContacts)) {
            setShowContactImport({
                ...showContactImport,
                show: false,
                slot: '',
            });
            setImportCSV(false)

            setFullPageLoader({
                show: false,
                title: ''
            })
            return;
        }

        let selectedContactsArr = Object.entries(selectedContacts).map(e => e[1])
        //console.log('selectedContactsArr', selectedContactsArr, _slot, _dealer)
        const { dealersettings, eventsPro } = props;

        const batchArray = [];
        batchArray.push(firestoreDB(dealersettings).firestore().batch());
        let operationCounter = 0;
        let batchIndex = 0;

        for (const objcontactData of selectedContactsArr) {

            let objData = Object.assign({}, objAttendees);
            for (let [key, value] of Object.entries(objcontactData)) {
                if (objData.hasOwnProperty(key))
                    objData[key] = value;
            }

            if (!_.isEmpty(objData.firstName) || !_.isEmpty(objData.lastName) || !_.isEmpty(objData.phone) || !_.isEmpty(objData.email)) {
                objData.phone = objData.phone ? objData.phone.replace(/\D/g, '') : null;
                objData.addedBy = dealersettings ? dealersettings.id : '';
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
                objData.modifiedBy = dealersettings ? dealersettings.id : '';
                objData.modifiedDate = window.firebase.firestore.Timestamp.now();
                objData.modifiedFrom = 'web';
                objData.isShow = false;
                objData.documentID = firestoreDB(dealersettings).firestore().collection('eventsProAttendees').doc().id;
                //if (!objData.projectId)
                objData.projectId = window.secondaryProjectId;//dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
                let categories = dealersettings?.client?.eventsPro?.categories;
                if (categories.length > 0) {
                    let category = {};
                    categories.map(rec => { return category = { ...category, [rec.value]: false } });
                    objData.category = category;
                }
                else {
                    objData.category = { invitation: false };
                }


                if (!_.isEmpty(eventsPro)) {
                    let eventData = Object.assign({});
                    for (let [key, value] of Object.entries(eventsPro)) {
                        if (eventProMapVM.hasOwnProperty(key) && value !== undefined) eventData[key] = value;
                    }
                    objData.eventsPro = eventData;
                }

                if (eventsPro?.documentID) objData.eventsProID = eventsPro?.documentID;
                if (objcontactData.documentID) objData.contactID = objcontactData.documentID;

                if (_dealer) {
                    objData.clientID = _dealer;
                    objData.clientName = CommonHelper.getOtherDealerName(dealersettings, _dealer);
                }
                else if (objData.clientID) {
                    objData.clientName = CommonHelper.getOtherDealerName(dealersettings, objData.clientID);
                }
                if (_slot) objData.eventSlot = _slot;
                //Remove empty value from object
                for (let propName in objData) {
                    if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                        delete objData[propName];
                    }
                }

                batchArray[batchIndex].set(firestoreDB(dealersettings).firestore().doc(`eventsProAttendees/${objData.documentID}`), objData, { merge: true });
            }

            operationCounter++;
            if (operationCounter === 299) {
                batchArray.push(firestoreDB(dealersettings).firestore().batch());
                batchIndex++;
                operationCounter = 0;
            }
            //console.log('operationCounter', operationCounter)
        }

        for (const batch of batchArray) {
            await batch.commit().then(() => {

                if (batchArray[batchArray.length - 1] === batch) {
                    setShowContactImport({
                        ...showContactImport,
                        show: false,
                        slot: '',
                    });
                    setImportCSV(false)
                    setFullPageLoader({
                        show: false,
                        title: ''
                    })
                    toast.notify('Attendee added successfully', {
                        duration: 2000
                    })
                }
            }).catch(function (error) {
                console.error("Error updating document: ", error);
            });
        }

        // const batch = firestoreDB(dealersettings).firestore().batch();

        // selectedContactsArr.forEach((objcontactData) => {
        // })

        // batch.commit().then(snapshot => {

        // }).catch((error) => {
        //     console.error("Error updating document: ", error);
        // });


    };
    //#endregion

    const deleteRequest = (id) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete this attendee?'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                let modifiedBy = props.dealersettings ? props.dealersettings.id : '';
                let modifiedDate = window.firebase.firestore.Timestamp.now();
                const updateRef = firestoreDB(props.dealersettings).firestore().collection('eventsProAttendees').doc(id);
                updateRef.update({
                    isDeleted: true,
                    modifiedBy: modifiedBy,
                    modifiedFrom: 'web',
                    modifiedDate: modifiedDate
                }).then((docRef) => {

                    dispatch({
                        type: "_REMOVE",
                        data: { documentID: id },
                        sortName: (sortConfig?.key || 'addedDate'),
                        sortOrder: (sortConfig?.sort || 'desc')
                    });
                    toast.notify('Attendee successfully deleted', {
                        duration: 2000
                    });
                }).catch((error) => {
                    console.error("Error updating contact_details: ", error);
                });
            }
        })


    }

    const handleSavesettings = (fields, showToast) => {
        if (!showToast)
            setTableLoader(true)
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}/columnSettings/${localStorage.uid}`)
            .set({ attendeeFields: fields }, { merge: true })
            .then(() => {
                setAttendeeFields(fields)
                setSettingsLoader(false)
                setTableLoader(false)
                props.updateDealerSettings('attendeeFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setTableLoader(false)
                setSettingsLoader(false)
                console.error(error)
            });

    }

    const handleActionClick = (id, contact) => {
        const modifyAccess = props.canModifyEvent;
        const isDraft = props.isDraft;
        const _permissions = props.dealersettings?.rolePermissions?.eventsProPermissions

        const addAttendeeAccess = ((!_.isEmpty(_permissions) && _permissions.addAttendee) ? true : false);
        const deleteAttendeeAccess = ((!_.isEmpty(_permissions) && _permissions.deleteAttendee) ? true : false);

        return <>
            {
                modifyAccess && addAttendeeAccess && (!contact.clientID || (contact.clientID && _.find(props.dealersettings?.clients, { documentID: contact.clientID })))
                    ?
                    <div style={{ cursor: 'pointer' }} className="mini-button" title='view' onClick={(e) => {
                        e.preventDefault();
                        handleContactOpen(id)
                    }}>
                        <i className="ico icon-edit"></i>
                    </div>
                    :
                    <div className="mini-button"> <i className="ico icon-lock"></i></div>

            }
            {
                isDraft && deleteAttendeeAccess
                    ?
                    <div style={{ cursor: 'pointer' }} className="mini-button ml-2" title='view' onClick={(e) => {
                        e.preventDefault();
                        deleteRequest(id)
                    }}>
                        <i className="ico icon-delete"></i>
                    </div>
                    :
                    <div className="mini-button ml-2"> <i className="ico icon-lock"></i></div>
            }

        </>
    }

    const handleContactdetailsClick = (e, id, data, tblprops) => {
        e.preventDefault();
        if (tblprops.columnKey === "lastNote" && data.notes) {
            setNotesPopOver({ show: true, target: e.target, notes: _.orderBy(data.notes, 'addedDate.seconds', 'desc') })
        }
        else if (!data.clientID || (data.clientID && _.find(props.dealersettings?.clients, { documentID: data.clientID })))
            handleAttendeeInfoOpen(id);
    }

    const handleAttendeeInfoOpen = (docID) => {
        setAttendeeInfoModal({ show: true, docID: docID })
    }
    const handleAttendeeInfoClose = () => {
        setAttendeeInfoModal({ show: false, docID: null })
    }

    //#region 
    const handleAllChecked = (event) => {
        let _selectedIds = {};
        if (event.target.checked) {
            !_.isEmpty(contacts) && contacts.map(file => {
                let record = Object.assign({}, contacts.filter(e => e.documentID === file.documentID)[0].objDoc);
                _selectedIds[file.documentID] = record;
            })
        }
        setSelectedIds(_selectedIds);
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked) {
            let record = Object.assign({}, contacts.filter(e => e.documentID === name)[0]);
            if (!_.isEmpty(record))
                _selectedIds[name] = record.objDoc;
        }
        else {
            delete _selectedIds[name];
        }
        setSelectedIds(_selectedIds);
    }

    const handleShowMergeContacts = () => {
        let _selectedIds = Object.assign({}, selectedIds);

        if (_.isEmpty(_selectedIds) || (!_.isEmpty(_selectedIds) && Object.keys(selectedIds).length !== 2)) {
            Swal.fire(CommonHelper.showLocale(props, 'Please select two attendees to merge.'));
            return;
        }
        else {
            const _mergeContacts = [];
            for (let [key, value] of Object.entries(_selectedIds)) {
                if (value?.gdprDeleted) {
                    Swal.fire(CommonHelper.showLocale(props, "Can't merge GDPR attendees"));
                    return;
                }
                _mergeContacts.push(value);
            }

            if (_mergeContacts.length === 2)
                setModalMergeShow({ show: true, mergeContacts: _mergeContacts })
        }
    }
    const handleCloseMergeContacts = (mergeID) => {
        if (!_.isEmpty(mergeID)) {
            dispatch({
                type: "_REMOVE",
                data: { documentID: mergeID },
                sortName: (sortConfig?.key || 'addedDate'),
                sortOrder: (sortConfig?.sort || 'desc')
            });

        }

        setSelectedIds({});
        setModalMergeShow({ show: false, mergeContacts: [] })


    }
    //#endregion

    //#region RE-AUTHENTICATION

    const excelDownload = async () => {
        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> {CommonHelper.showLocale(props, 'downloading...')}</div>),
            {
                position: 'top',
                duration: null
            },
        )
        const { dealersettings, eventsPro } = props;

        let refDataExport = firestoreDB(dealersettings).firestore().collection('eventsProAttendees')
            .where('eventsProID', '==', eventsPro.documentID)
            .where('isDeleted', '==', false)

        if (_.trim(searchText) && _.trim(searchText).length > 2) {
            let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)
            if (formattedSearchText.trim())
                refDataExport = refDataExport
                    .where('keywords', 'array-contains', formattedSearchText.trim().toLowerCase())
        }

        if (objFilter.client) refDataExport = refDataExport.where('clientID', '==', objFilter.client)
        if (objFilter.source) refDataExport = refDataExport.where('source', '==', objFilter.source)
        if (objFilter.slot) refDataExport = refDataExport.where('eventSlot', '==', objFilter.slot)
        if (objFilter.status) refDataExport = refDataExport.where('status', '==', objFilter.status)
        if (objFilter.dietary) refDataExport = refDataExport.where('dietaryPreference', '==', objFilter.dietary)

        if (_.isEmpty(objFilter)) {
            refDataExport = refDataExport.orderBy('addedDate', 'desc')
        }


        refDataExport = refDataExport.limit(excelLimit)

        refDataExport.get().then(querySnapshot => {
            let _enquiries = [];
            querySnapshot.forEach(doc => {
                var logVM = convertAttendeeVM({
                    ...doc.data(),
                    documentID: doc.id
                });
                _enquiries.push(logVM);
            });

            setCSVData(CommonHelper.generateExcelData(_enquiries, csvHeader, props))
            setTimeout(() => {csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload();}, 1000)
            toast.closeAll();
            Swal.fire({
                icon: 'success',
                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                showConfirmButton: false,
                timer: 1500
            });

            // let _objLogData = {
            //     notes: 'Excel export from attendees',
            //     type: 'excelExport',
            //     subType: 'e',
            // }
            // _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
            // CommonHelper.saveAuditLog(_objLogData);
        });

    }

    const excelUSADownload = async () => {
        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> {CommonHelper.showLocale(props, 'downloading...')}</div>),
            {
                position: 'top',
                duration: null
            },
        )
        const { dealersettings, eventsPro } = props;

        let refDataExport = firestoreDB(dealersettings).firestore().collection('eventsProAttendees')
            .where('eventsProID', '==', eventsPro.documentID)
            .where('isDeleted', '==', false)

        if (_.trim(searchText) && _.trim(searchText).length > 2) {
            let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)
            if (formattedSearchText.trim())
                refDataExport = refDataExport
                    .where('keywords', 'array-contains', formattedSearchText.trim().toLowerCase())
        }

        if (objFilter.client) refDataExport = refDataExport.where('clientID', '==', objFilter.client)
        if (objFilter.source) refDataExport = refDataExport.where('source', '==', objFilter.source)
        if (objFilter.slot) refDataExport = refDataExport.where('eventSlot', '==', objFilter.slot)
        if (objFilter.status) refDataExport = refDataExport.where('status', '==', objFilter.status)
        if (objFilter.dietary) refDataExport = refDataExport.where('dietaryPreference', '==', objFilter.dietary)

        if (_.isEmpty(objFilter)) {
            refDataExport = refDataExport.orderBy('addedDate', 'desc')
        }


        refDataExport = refDataExport.limit(excelLimit)

        refDataExport.get().then(querySnapshot => {
            let _enquiries = [];
            querySnapshot.forEach(doc => {
                var logVM = convertAttendeeVM({
                    ...doc.data(),
                    documentID: doc.id
                });
                _enquiries.push(logVM);
            });

            setUSAData(generateUSAExcelData(_enquiries, usaHeader, props))
            setTimeout(() => { usaBtn.current && usaBtn.current.handleDownload && usaBtn.current.handleDownload(); }, 1000)
            toast.closeAll();
            Swal.fire({
                icon: 'success',
                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                showConfirmButton: false,
                timer: 1500
            });
        });

    }


    const generateUSAExcelData = (_enquiries, csvHeader, props) => {
        let _headers = [];
        let csvStyle = CommonHelper.getCSVStyle();
        _headers = csvHeader.map(rec => {
            return {
                ...rec,
                title: CommonHelper.showLocale(props, rec.key, rec.label),
                dynValue: rec.key,
                dynWidth: rec.label.length + 3,
                ...csvStyle.header
            }
        })
        var exportData = []
        _enquiries.forEach(rec => {
            let obj = rec;
            let arrCols = []
            _headers.forEach(col => {
                arrCols.push({ value: `${obj[col.dynValue] ? obj[col.dynValue] : ''}`, ...csvStyle.cell, dynValue: col.dynValue, dynWidth: obj[col.dynValue] ? obj[col.dynValue].toString().length : 0 })
            })
            exportData.push(arrCols)
        })
        _headers.forEach(rec => {
            var w = _.maxBy(exportData.map(arrrec => { return { ...arrrec.find(r => r.dynValue === rec.dynValue) } }), 'dynWidth')
            _headers = _headers.map(h => {
                return (h.dynValue === rec.dynValue && w && w.dynWidth > h.dynWidth) ? {
                    ...h,
                    'width': { wch: w.dynWidth }
                } : h.width ? { ...h } : {
                    ...h,
                    'width': { wch: h.dynWidth }
                }
            })
        })
        return [{
            columns: [..._headers.map(r => { return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 } })],
            data: [...exportData]
        }];
    }

    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                excelDownload();
            }, 1000);
        }
        setReAuthentication(false);

    }

    const handleDynamicSort = (sortConfig) => {
        setSortConfig(sortConfig);
        setTableLoader(true)
        setDataload(true);
        setPaging(false);
        setHasMoreData([]);
    }
    //#endregion

    const filterCounts = () => {

        var FilterCount = Object.keys(_.pickBy(objFilter, function (value, key) {
            return !_.isEmpty(value);
        }));

        return (FilterCount.length > 0 && showCount) ? (<span className="badge badge-pill badge-advanced-filter">{FilterCount.length}</span>) : (<></>)
    }

    const _permissions = props.dealersettings?.rolePermissions?.eventsProPermissions

    const addAttendeeAccess = ((!_.isEmpty(_permissions) && _permissions.addAttendee) ? true : false);
    const deleteAttendeeAccess = ((!_.isEmpty(_permissions) && _permissions.deleteAttendee) ? true : false);

    const modifyAccess = props.canModifyEvent
    const isDraft = props.isDraft;
    const isClosed = props?.isClosed;
    const exportData = props.dealersettings?.rolePermissions?.eventsProPermissions?.exportData && props.dataCount > 0 ? true : false;

    allAttendeeFields.filter(function (obj) {
        if (obj.name === 'Settings') {
            obj.width = 80;
            return true;
        }
        return false;
    });


    return (
        <>
            <div className="events-detailsection-inner-boxloop">
                {fullPageLoader.show && (
                    <div className="lds-roller-loader text-center add-message-lds ">
                        <div className="lds-roller-loader-inner ">
                            <div role="status" className="spinner-border text-primary"></div>
                            <p className="text-muted mt-3">{fullPageLoader.title}</p>
                        </div>
                    </div>
                )}
                <div className="events-inner-detailwrapper">

                    {/* <div className="events-inner-section-header">
                        <div className="float-left"><h2>Attendees </h2></div>
                        <div className="float-right">

                        </div>
                    </div>

                    <div className="divider-line"></div> */}

                    <div className="settings-head mt-2">
                        {
                            isClosed
                                ?
                                <></>
                                :
                                <div className="filter-item-btn">
                                    <a href="#" onClick={(e) => { e.preventDefault(); handleShowMergeContacts() }}>
                                        <i className="ico icon-merge"></i> <Translate text={'merge'} />
                                    </a>
                                </div>
                        }

                        <div className={`float-left ${isClosed ? '' : 'ml-2'}`}>

                            <div className="filter-search search-icon">
                                <input
                                    type="text"
                                    aria-label="Search"
                                    placeholder="Search..."
                                    value={searchText ? searchText : ''}
                                    autoComplete="off"
                                    onChange={e => {
                                        let _value = e.target.value;
                                        if (!_value)
                                            setSearchText('');
                                        else
                                            setSearchText(_value);

                                        if (_.trim(_value) && _.trim(_value).length > 2)
                                            setContactLoader(true);

                                        setTimeout(() => {
                                            setHasMoreData([]);
                                            setDataload(true);
                                        }, 1000);


                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            setSearchText(searchText)
                                            setHasMoreData([]);
                                            setDataload(true)
                                            setContactLoader(true)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        {/*  <div className={`float-left`}>
                            <div className="form-loan-history form-row ml-2">
                                <div className="form-group col-md-12 daterange-fix">
                                    <ReactSelect
                                        options={props?.dealersettings?.clients ?
                                            _.orderBy(props.dealersettings.clients, 'name', 'asc').map(rec => {
                                                return {
                                                    value: rec.id,
                                                    label: rec.name
                                                }
                                            })
                                            : []}
                                        name={"filterClient"}
                                        placeholder={'filter client'}
                                        onChange={(e) => {
                                            if (e)
                                                setFilterClient(e.value);
                                            else
                                                setFilterClient('');
                                            setHasMoreData([]);
                                            setDataload(true)
                                            setContactLoader(true)
                                        }}
                                        value={filterClient}
                                        classNamePrefix={`basic-select`}
                                        removeClearable={false}
                                    >
                                    </ReactSelect>
                                </div>
                            </div>
                        </div>
                         {
                            !_.isEmpty(eventSettings?.source) && (<div className={`float-left`}>
                                <div className="form-loan-history form-row ml-2">
                                    <div className="form-group col-md-12 daterange-fix">
                                        <ReactSelect
                                            options={eventSettings?.source.map(rec => {
                                                return {
                                                    value: rec.value,
                                                    label: rec.name
                                                }
                                            })}
                                            name={"filterSource"}
                                            placeholder={'filter source'}
                                            onChange={(e) => {
                                                if (e)
                                                    setFilterSource(e.value);
                                                else
                                                    setFilterSource('');
                                                setHasMoreData([]);
                                                setDataload(true)
                                                setContactLoader(true)
                                            }}
                                            value={filterSource}
                                            classNamePrefix={`basic-select`}
                                            removeClearable={false}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>
                            </div>)
                        } */}
                        {
                            props?.eventsPro?.status === eventStatus.CLOSED && props?.eventsPro?.level === 'individual' && props?.eventsPro?.clientIDs?.length === 1 ? (
                                <div className="filter-add-button ml-4 float-right">
                                    <button onClick={(e) => {
                                        e.preventDefault();
                                        props.setModelAllocationShow(true)
                                    }} type="button" className={`btn btn-${props?.eventsPro?.log?.success ? 'green' : props?.eventsPro?.log?.success === false ? 'red' : 'primary'} ${props?.eventsPro?.log?.start === true ? 'btn-disable' : ''}`}>
                                        {
                                            props?.eventsPro?.log?.start === true ?
                                                (<><span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>Allocating...</>)
                                                : props?.eventsPro?.log?.success ? (<><i className="ico icon-sent mt-1 mr-2"></i> Allocated</>) : props?.eventsPro?.log?.success === false ? (<><i className="ico icon-mail-spam mt-1 mr-2"></i> Reallocated</>) : (<>Allocate</>)
                                        }
                                    </button>
                                </div>
                            ) : (<></>)
                        }
                        {
                            props.dealersettings.email === 'admin@fusionsd.com' ? (
                                <div className="filter-item-btn ml-2 float-right hide" title='McLaren Excel Format'>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        excelUSADownload();
                                    }} title={'Excel Export'}>
                                        <i className="ico icon-excel-download"></i>
                                    </a>
                                    <ExcelFile ref={usaBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`attendees_${moment().format('DD_MM_YYYY_HH_mm')}`}>
                                        <ExcelSheet dataSet={usaData} name={`Sheet1`} />
                                    </ExcelFile>
                                </div>
                            ) : (<></>)
                        }
                        {
                            (exportData)
                                ?
                                <>
                                    <div className="filter-item-btn ml-2 float-right">
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            excelDownload();
                                        }} title={'Excel Export'}>
                                            <i className="ico icon-excel-download"></i>
                                        </a>
                                        <ExcelFile ref={csvBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`attendees_${moment().format('DD_MM_YYYY_HH_mm')}`}>
                                            <ExcelSheet dataSet={csvData} name={`Sheet1`} />
                                        </ExcelFile>
                                    </div>
                                </>
                                :
                                <>
                                </>
                        }
                        {
                            isDraft && addAttendeeAccess
                                ?
                                <div className="float-right">
                                    <button type="button" className="btn btn-secondary float-right ml-2" onClick={e => {
                                        e.preventDefault();
                                        setImportCSV(true)
                                    }}>
                                        <i className="ico icon-excel-export mr-2"></i>   Upload Excel
                                    </button>
                                    <button type="button" className="btn btn-secondary float-right ml-2" onClick={e => {
                                        e.preventDefault();
                                        handleContactImportOpen();
                                    }}><i className="ico icon-mail-download mr-2"></i> Import</button>
                                    <button type="button" className="btn btn-primary float-right " onClick={e => {
                                        e.preventDefault();
                                        handleContactOpen();
                                    }}><i className="ico icon-add mr-2"></i> Add Attendees</button>
                                </div>
                                :
                                <></>
                        }
                        <div className="float-right">
                            <Dropdown ref={node} alignRight show={filterShow}>
                                <div className="advanced-filter-btn mr-2 float-right">
                                    <Dropdown.Toggle as={CustomToggle} className="dropdown" onClick={() => {
                                        setShow(!filterShow)
                                    }}>
                                        {filterCounts()}
                                        <i className="ico icon-filter"></i>Filters
                                    </Dropdown.Toggle>
                                </div>
                                <Dropdown.Menu as={CustomFilter}
                                    id="table-reports-cols-settings-pipeline"
                                    className="dropdown-menu dropdown-menu-left"
                                    ChildClass="form-style quick-filter-wrap"
                                    xplacement="bottom-end"
                                >
                                    <div className="quick-filter-body mt-1 h-100 overflow-inherit">
                                        <div className="report-filter-wraper form-style">
                                            {
                                                statisFilters.filter(a => !_.isEmpty(eventSettings?.source) ? true : a !== 'source').map((rec, i) => {
                                                    return <DynamicFilters
                                                        key={i}
                                                        {...props}
                                                        filter={rec}
                                                        objFilter={objFilter}
                                                        columns={[]}
                                                        setObjFilter={(e, name, slots) => {
                                                            if (name === 'slotdate') {
                                                                setObjFilter({
                                                                    ...objFilter,
                                                                    [name]: e ? e.value : '',
                                                                    ['slot']: _.find(slots, { value: e?.value })?.slots?.[0]?.value
                                                                })
                                                            } else {
                                                                setObjFilter({
                                                                    ...objFilter,
                                                                    [name]: e ? e.value : ''
                                                                })
                                                            }

                                                        }}
                                                        clients={props?.dealersettings?.clients ?
                                                            _.orderBy(props.dealersettings.clients, 'name', 'asc').map(rec => {
                                                                return {
                                                                    value: rec.id,
                                                                    label: rec.name
                                                                }
                                                            })
                                                            : []}
                                                        sources={eventSettings?.source?.length ?
                                                            eventSettings?.source.map(rec => {
                                                                return {
                                                                    value: rec.value,
                                                                    label: rec.name
                                                                }
                                                            })
                                                            : []}
                                                        slots={eventSettings?.slots?.length ?
                                                            _.chain(_.orderBy(eventSettings?.slots, 'startTime.seconds', 'asc'))
                                                                .groupBy('date')
                                                                .map((value, key) => ({
                                                                    label: moment(key).format(`${props?.eventsPro?.dateFormat || 'DD/MM/YYYY'}`),
                                                                    value: key,
                                                                    slots: value.map(r => {
                                                                        return {
                                                                            label: `${moment.unix(r?.startTime?.seconds).tz(props?.eventsPro?.timezone).format(`${props?.eventsPro?.timeFormat || 'hh:mm A'}`)} - ${moment.unix(r?.endTime?.seconds).tz(props?.eventsPro?.timezone).format(`${props?.eventsPro?.timeFormat || 'hh:mm A'}`)}`,
                                                                            value: r.value
                                                                        }
                                                                    })
                                                                })).value()
                                                            : []}
                                                        dietarys={eventSettings?.dietary?.length ?
                                                            eventSettings?.dietary.map(rec => {
                                                                return {
                                                                    value: rec.value,
                                                                    label: rec.name
                                                                }
                                                            })
                                                            : []}
                                                        statuss={Object.keys(eventAttendeeStatus).filter(a => props?.eventsPro?.enableEOI ? true : !a.startsWith('EOI')).map(r => { return { label: r, value: eventAttendeeStatus[r] } })}
                                                    />
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="quick-filter-footer">
                                        <button type="button" className="btn btn-default float-left" onClick={() => {
                                            setShow(false)
                                            setShowCount(false)
                                            setObjFilter({})
                                            setHasMoreData([]);
                                            setDataload(true)
                                            setContactLoader(true)
                                        }}>Clear</button>
                                        <button type="button" className="btn btn-primary float-right" onClick={() => {
                                            setShow(false)
                                            setShowCount(true)
                                            setHasMoreData([]);
                                            setDataload(true)
                                            setContactLoader(true)
                                        }}>Apply</button>

                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    {
                        contactLoader ? (<div className="mt-3"><ContentOverlay active={true} /></div>) :
                            (
                                <div className="contact-panel mt-0" style={{ width: windowSize.windowWidth + 'px' }}>
                                    {
                                        tableLoader
                                            ?
                                            <>
                                                <div className="dashboard-widget-loader h-100">
                                                    <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                    <TableView
                                        isReorderable={true}
                                        datalist={contacts}
                                        height={contacts.length === 0 ? 400 : contacts.length < 10 ? ((contacts.length * 60) + 60) : 660}
                                        width={windowSize.windowWidth}
                                        columns={allAttendeeFields}
                                        handleSavesettings={handleSavesettings}
                                        dynamicFields={(attendeeFields && attendeeFields.length > 0) ? _.reject([...attendeeFields.filter(item => item !== 'checkbox' && item !== 'settings' && allAttendeeFields.some(b => b.value === item)), (isClosed ? '' : 'checkbox'), 'settings'], _.isEmpty) : allAttendeeFields.filter(e => e.default === true && (isClosed ? e.value !== 'checkbox' : true)).map(v => _.pick(v, ['value']).value)}
                                        settingsLoader={settingsLoader}
                                        handleActionClick={handleActionClick}
                                        handleRowSingleClick={handleContactdetailsClick}
                                        isSettings={true}
                                        hasMore={hasMore}
                                        isPaging={false}
                                        selectedIds={selectedIds}
                                        handleAllChecked={handleAllChecked}
                                        handleCheckChange={handleCheckChange}
                                        hideAllCheck={true}
                                        isSorting={_.isEmpty(objFilter) ? true : false}
                                        dynamicSort={true}
                                        handleDynamicSort={handleDynamicSort}
                                        sortConfig={sortConfig}
                                    />
                                </div>
                            )
                    }
                    {/* {
                        contactLoader ? (
                            <>
                                <div className='loader-center-screen' style={{ height: 'auto', width: '100%' }}>
                                    <div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
                                </div>
                            </>
                        ) : (

                            <div className="common-table">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="head-light" width="15%">Name</th>
                                            <th scope="col" className="head-light" width="15%">Email</th>
                                            <th scope="col" className="head-light" width="10%">Phone</th>
                                            <th scope="col" className="head-light" width="10%">Check In</th>
                                            <th scope="col" className="head-light border-right-0" width="10%">Status</th>
                                            <th scope="col" className="head-light" width="10%">Event Slot</th>
                                            <th scope="col" className="head-light" width="10%">Dealer</th>
                                            <th scope="col" className="head-light" width="10%">Checked-In</th>
                                            <th scope="col" className="head-light border-left-0" width="10%">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (contacts && contacts.length) > 0
                                                ?
                                                <>
                                                    {contacts.map((contact, index) =>
                                                        <tr key={index}>
                                                            <td><div className="table-list-users">
                                                                <img src={CommonHelper.showUserAvatar(contact.userImageURL, contact.fullName)} alt="" />
                                                               
                                                            </div> {contact.fullName}</td>
                                                            <td>{contact.email ? contact.email : '--'}</td>
                                                            <td>{contact.displayPhone ? contact.displayPhone : '--'}</td>
                                                            <td>--</td>
                                                            <td className="border-right-0">
                                                                --
                                                            </td>
                                                            <td>{contact.eventSlot ? CommonHelper.getLabelByValue(props.eventSlots, contact.eventSlot) : '--'} </td>
                                                            <td>{contact.clientID ? CommonHelper.getOtherDealerName(props.dealersettings, contact.clientID, props.eventsPro?.clientName) : '--'}</td>
                                                            <td>
                                                                {
                                                                    contact.checkedOn
                                                                        ?
                                                                        <>
                                                                            <div className="badge badge-pill badge-available-fill ">CHECKED-IN</div>
                                                                            {contact.checkedOn}
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                            </td>
                                                            <td className="border-left-0">

                                                                <div className="float-right">
                                                                    {
                                                                        modifyAccess && addAttendeeAccess && (!contact.clientID || (contact.clientID && _.find(props.dealersettings?.clients, { documentID: contact.clientID })))
                                                                            ?
                                                                            <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={e => {
                                                                                e.preventDefault();
                                                                                handleContactOpen(contact.documentID);
                                                                            }}> <i className="ico icon-edit"></i></a></div>
                                                                            :
                                                                            <>

                                                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={e => {
                                                                                    e.preventDefault();
                                                                                }}> <i className="ico icon-lock"></i></a></div>


                                                                            </>

                                                                    }
                                                                    {
                                                                        isDraft && deleteAttendeeAccess
                                                                            ?
                                                                            <div className="table-edit float-left"> <a href="#" className="mini-button" onClick={e => {
                                                                                e.preventDefault();
                                                                                deleteRequest(contact.documentID);
                                                                            }}> <i className="ico icon-delete"></i></a></div>
                                                                            :
                                                                            <div className="table-edit float-left"> <a href="#" className="mini-button" onClick={e => {
                                                                                e.preventDefault();
                                                                            }}> <i className="ico icon-lock"></i></a></div>
                                                                    }

                                                                </div>


                                                            </td>
                                                        </tr>
                                                    )}</>
                                                :
                                                <>
                                                    <tr>
                                                        <td colSpan='9'>
                                                            <div className="spinner-loader h-100 p-5">
                                                                <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                                    <div className="no-cafe-img">
                                                                        <i className="ico icon-Contacts"></i> </div>
                                                                    <div className="no-data-txt mt-2"> <Translate text={'noAttendeesFound'} /></div>
                                                                </div>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                        }


                                    </tbody>
                                </table>

                            </div>)
                    } */}
                    {
                        hasMore && hasMore.length > 0 ? (
                            <div className="load-more-button pb-2"> <a href="#" className="mt-1" onClick={(e) => {
                                e.preventDefault();
                                handlePagination();
                            }}><Translate text={'Load More'} />
                                {
                                    checkDataLoad ? (
                                        <span className="spinner-border spinner-button ml-2 float-right" role="status" aria-hidden="true"></span>
                                    ) : (<i className="fa fa-angle-down" aria-hidden="true"></i>)
                                }

                            </a></div>

                        ) : (<></>)
                    }

                </div>

            </div>

            <PopUpModal show={contactModal.show}>
                <AddAttendee
                    {...props}
                    docID={contactModal.id}
                    show={contactModal.show}
                    clsActive={contactModal.clsActive}
                    handleClose={handleContactClose}
                    title={contactModal.title}
                    showSearch={true}
                    mandatoryFields={contactModal.mandatoryFields}
                    eventsProID={props.eventsPro?.documentID}
                    eventsPro={props.eventsPro}
                    clientID={props.clientID}
                >
                </AddAttendee>
            </PopUpModal>

            <PopUpModal show={showContactImport.show}>
                <ContactImport
                    {...props}
                    show={showContactImport.show}
                    clientID={props.clientID}
                    handleClose={handleContactImportClose}
                    title={showContactImport.title}
                    setFullPageLoader={setFullPageLoader}
                />
            </PopUpModal>

            <PopUpModal show={showImportCSV}>
                <ImportCSV
                    {...props}
                    show={showImportCSV}
                    handleClose={handleContactImportClose}
                    setFullPageLoader={setFullPageLoader}
                    eventSettings={eventSettings}
                />
            </PopUpModal>

            <PopUpModal show={attendeeInfoModal.show}>
                <AttendeeInfo
                    {...props}
                    attendeeid={attendeeInfoModal.docID}
                    show={attendeeInfoModal.show}
                    clsActive='overlay-modal active'
                    handleClose={handleAttendeeInfoClose}
                    title={'attendee'}
                >
                </AttendeeInfo>
            </PopUpModal>

            <PopUpModal show={modalMergeShow.show}>
                <MergeAttendee
                    {...props}
                    show={modalMergeShow.show}
                    contactA={modalMergeShow.mergeContacts[0]}
                    contactB={modalMergeShow.mergeContacts[1]}
                    handleClose={handleCloseMergeContacts}
                    title={'Merge Attendees'}
                    eventSettings={eventSettings}
                >
                </MergeAttendee>
            </PopUpModal>
            <PopUpModal show={showReAuthentication}>
                <ReAuthenticate
                    show={showReAuthentication}
                    handleClose={handleReAuthOpenClose}
                    dealersettings={props.dealersettings}
                    privacy='file'
                />
            </PopUpModal>
            <PopoverPanel
                showpopover={notesPopOver.show}
                targetpopover={notesPopOver.target}
                title='Notes'
                position='left'
                id='pipeline-switch'
                className='popover-pipeline-switch'
                closepopover={() => {
                    setNotesPopOver({ show: false })
                }}>
                <div className='popover-notes-inner-wrap'>
                    {
                        notesPopOver?.notes && notesPopOver.notes.map((rec, index) => {
                            const logBy = rec.addedByName ? rec.addedByName : (rec.owner || rec.addedBy ? CommonHelper.getUserNamebyId(_allUser, (rec.owner ? rec.owner : rec.addedBy)) : '');
                            return <div key={index} className="popover-notes-inner m-3">
                                {rec.notes}
                                <div className="popover-notes-sub">{logBy} {rec.addedDate ? moment.unix(rec.addedDate.seconds).format(`${eventSettings?.dateFormat || 'DD/MM/YYYY'} ${eventSettings?.timeFormat || 'hh:mm A'}`) : ''} </div>
                            </div>
                        })
                    }
                </div>
            </PopoverPanel>
        </>
    )
}

export default Contacts