import React, { useEffect, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import axios from 'axios';
import toast from 'toasted-notes'
import Papa from 'papaparse'
import Swal from 'sweetalert2'
import CommonHelper from '../../../../services/common';
import Translate from '../../../../constants/translate';
import { PopUpModal } from '../../../../components';
import TemplateEditor from '../../../settings/workflow/templates/templateEditor';
import { TemplateContext } from '../../../settings/workflow/templateContext';
import AddTemplate from '../addTemplate';
import AddContact from '../addContact';
import ViewRecipients from '../viewRecipients';
import AddRecipient from '../addRecipient'
import PreviewTemplate from '../previewTemplate';
import ScheduleCampaign from '../scheduleCampaign';
import ImportCSV from '../importCSV'
import moment from 'moment';

const compileWithTemplateLanguage = (bodyHTML, dealersettings) => {
	let html = bodyHTML;

	// @FIRST_NAME
	html = html.replace(/@FIRST_NAME/g, '[[data:firstname]]')
	// @LAST_NAME
	html = html.replace(/@LAST_NAME/g, '[[data:firstname]]')
	// @FULL_NAME
	html = html.replace(/@FULL_NAME/g, `[[data:name]]`)
	//@DEALER_NAME
	html = html.replace(/@DEALER_NAME/g, dealersettings.client && dealersettings.client.name ? dealersettings.client.name : '')
	//@DEALER_PHONE
	html = html.replace(/@DEALER_PHONE/g, dealersettings.client && dealersettings.client.phone ? dealersettings.client.phone : '')
	//@DEALER_EMAIL
	html = html.replace(/@DEALER_EMAIL/g, dealersettings.client && dealersettings.client.email ? dealersettings.client.email : '')
	//@DEALER_ADDRESS
	html = html.replace(/@DEALER_ADDRESS/g, dealersettings.client && dealersettings.client.address ? dealersettings.client.address : '')
	//@FACEBOOK_URL
	html = html.replace(/@FACEBOOK_URL/g, dealersettings.client && dealersettings.client.facebookURL ? dealersettings.client.facebookURL : '')
	//@X_URL
	html = html.replace(/@X_URL/g, dealersettings.client && dealersettings.client.xURL ? dealersettings.client.xURL : '')
	//@INSTAGRAM_URL
	html = html.replace(/@INSTAGRAM_URL/g, dealersettings.client && dealersettings.client.instagramURL ? dealersettings.client.instagramURL : '')
	//@YOUTUBE_URL
	html = html.replace(/@YOUTUBE_URL/g, dealersettings.client && dealersettings.client.youtubeURL ? dealersettings.client.youtubeURL : '')

	let unsubscribe = '';
	if (!html.includes('/contact/unsubscribe/')) {
		unsubscribe = `<div style="width:100%; display:inline-block;">
		<div style="width:600px;margin:30px auto;word-wrap:break-word; padding-bottom:20px;">
			<p style="font-size:11px; font-family:Arial, sans-serif; text-align:center; margin:0px; padding:0px 0 10px 0;">No longer want to receive these emails? 
			<a style="font-size:11px; font-weight: bold; color: #4466f2;" href="${window.location.origin}/contact/unsubscribe/${dealersettings.client.id}?email=[[EMAIL_TO]]" target="_blank">Unsubscribe</a></p>                    
		</div>
		</div>`;
	}


	return html + unsubscribe;
}

const AddCampaign = props => {
	const [data, setData] = useState(props.data || {
		title: 'Untitled Campaign',
		subject: '',
		fromName: '',
		fromEmail: '',
		replyEmail: "",
		template: '',
	});
	const [loader, setLoader] = useState(props.title === 'Edit Campaign' ? true : false);
	const [templateFromVision, setTemplateFromVision] = useState(false);
	const [saveLoader, setSaveLoader] = useState(false);
	const [scheduleLoader, setScheduleLoader] = useState(false);
	const [saveLaterLoader, setSaveLaterLoader] = useState(false);
	const [editTitle, setEditTitle] = useState({ show: false, snapshot: { title: '' } });
	const [editSubject, setEditSubject] = useState({ show: false, snapshot: { subject: '' } });
	const [editFrom, setEditFrom] = useState({ show: false, snapshot: { fromName: '', fromEmail: '', replyEmail: '' } });
	const [recipients, setRecipients] = useState([]);
	const [errorFields, setErrorFields] = useState({});
	const [showEdiorModel, setShowEdiorModel] = useState({
		show: false,
		template: null
	});
	const [popHeader, setPopHeader] = useState('');
	const [selectedTemplate, setSelectedTemplate] = useState('');
	const { emailTemplateList } = useContext(TemplateContext);
	const [showAddTemplate, setShowAddTemplate] = useState({
		show: false,
		title: '',
	});
	const [showAddContact, setShowAddContact] = useState({
		show: false,
		title: '',
	});

	const [showRecipients, setShowRecipients] = useState({
		show: false,
		title: '',
	});

	const [showAddRecipient, setShowAddRecipient] = useState({
		show: false,
		title: '',
	});

	const [showPreviewTemplate, setShowPreviewTemplate] = useState({
		show: false,
		title: '',
	})

	const [showScheduleCampaign, setShowScheduleCampaign] = useState({
		show: false,
		title: '',
	});

	const [showImportCSV, setShowImportCSV] = useState({
		show: false,
		title: '',
	});

	const [trustedSender, setTrustedSenders] = useState(!_.isEmpty(props.data))
	const [trustedSenderEmail, setTrustedSenderEmail] = useState(false)
	const [senderLoader, setSenderLoader] = useState(false);
	const [verifyMsg, setVerifyMsg] = useState()
	const [contactListID, setContactListID] = useState(0)
	const [contactLoader, setContactLoader] = useState(false);
	const [totalContacts, setTotalContatcs] = useState(0)
	const [fullPageLoader, setfullPageLoader] = useState(!_.isEmpty(props.data))

	useEffect(() => {
		if (recipients?.length === 0) {
			setContactListID(0)
		}
	}, [recipients?.length])

	useEffect(() => {
		if (_.isEmpty(props.data))
			return

		const fetchData = async () => {
			let overview = {};
			const payload = {
				id: props.campaignID,
				listID: props.ContactsListID,
				settingsID: props.dealersettings.oemID
			}
			if (props.dealersettings.level === 'oem' && localStorage.defaultModule.includes('oem') && props.dealersettings.oemID) {
				payload.oemID = props.dealersettings.oemID;
			}
			else if (props.dealersettings.level === 'region' && localStorage.defaultModule.includes('oem') && props.dealersettings.regionID) {
				payload.regionID = props.dealersettings.regionID;
			}
			else if (props.dealersettings.level === 'group' && localStorage.defaultModule.includes('oem') && props.dealersettings.groupID) {
				payload.groupID = props.dealersettings.groupID;
			}
			else {
				payload.clientID = props.dealersettings.client.id
			}
			const recipientsCount = await axios.post(
				`${props.apiUrl}mailjet-api/api/mailjet/overview-campaign`,
				payload,
			);
			if (recipientsCount?.data?.data?.bodyHTML) {
				setTotalContatcs(recipientsCount.data.data.Total)
				setData({
					...data,
					template: {
						bodyHTML: recipientsCount.data.data.bodyHTML
					}
				})
			}
		}
		fetchData()
	}, [])

	useEffect(() => {
		if (_.isEmpty(props.data) || totalContacts === 0)
			return
		const fetchRecipients = async () => {
			const payload = {
				limit: totalContacts,
				offset: 0,
				listID: props.ContactsListID,
				settingsID: props.dealersettings.oemID
			}
			if (props.dealersettings.level === 'oem' && localStorage.defaultModule.includes('oem') && props.dealersettings.oemID) {
				payload.oemID = props.dealersettings.oemID;
			}
			else if (props.dealersettings.level === 'region' && localStorage.defaultModule.includes('oem') && props.dealersettings.regionID) {
				payload.regionID = props.dealersettings.regionID;
			}
			else if (props.dealersettings.level === 'group' && localStorage.defaultModule.includes('oem') && props.dealersettings.groupID) {
				payload.groupID = props.dealersettings.groupID;
			}
			else {
				payload.clientID = props.dealersettings.client.id
			}
			const recipientsCount = await axios.post(
				`${props.apiUrl}mailjet-api/api/mailjet/list-contact`,
				payload
			);
			if (recipientsCount?.data?.data?.length) {
				setRecipients(recipientsCount.data.data.map(r => {
					const name = r.Name?.split(' ');
					return {
						firstName: name[0],
						lastName: r.Name?.replace(name[0], "")?.trim() || '',
						email: r.Email
					}
				}))
				setfullPageLoader(false)
			}
		}
		fetchRecipients()
	}, [totalContacts])

	const handleTemplateModalClose = email => {
		setShowAddTemplate({
			...showAddTemplate,
			show: false,
		});
		if (email) {
			setData({
				...data,
				template: email,
			});
			toast.notify('Templa te added successfully', {
				duration: 2000
			})
		}
	};

	const handleShowRecipientsModalClose = () => {
		setShowRecipients({
			...showRecipients,
			show: false,
			title: ''
		});
	}

	const handleShowAddRecipientOpen = () => {
		setShowAddRecipient({
			show: true,
			title: 'Add Recipient'
		})
	}

	const handleShowScheduleCampaignOpen = () => {
		if (!data.title) {
			toast.notify('Please add a title', {
				duration: 2000
			})
			return
		}

		if (!data.subject) {
			toast.notify('Please add a subject', {
				duration: 2000
			})
			return
		}

		if (!(data.fromName && data.fromEmail)) {
			toast.notify('Please fill out from section', {
				duration: 2000
			})
			return
		}

		if (!(recipients.length > 0)) {
			toast.notify('Please add recipients', {
				duration: 2000
			})
			return
		}

		if (_.isEmpty(data.template)) {
			toast.notify('Please add a template', {
				duration: 2000
			})
			return
		}

		if (!trustedSender) {
			Swal.fire(CommonHelper.showLocale(props, 'Please verify sender email address'), '', 'info')
			return
		}
		setShowScheduleCampaign({
			show: true,
		})

	}

	const handleShowScheduleCampaignClose = (scheduleDate) => {
		setShowScheduleCampaign({
			show: false,
		})

		if (!scheduleDate) {
			return
		}

		if (!data.title) {
			toast.notify('Please add a title', {
				duration: 2000
			})
			return
		}

		if (!data.subject) {
			toast.notify('Please add a subject', {
				duration: 2000
			})
			return
		}

		if (!(data.fromName && data.fromEmail)) {
			toast.notify('Please fill out from section', {
				duration: 2000
			})
			return
		}

		if (!(recipients.length > 0)) {
			toast.notify('Please add recipients', {
				duration: 2000
			})
			return
		}

		if (_.isEmpty(data.template)) {
			toast.notify('Please add a template', {
				duration: 2000
			})
			return
		}
		if (scheduleDate) {
			console.log(scheduleDate, 'scheduleDate')
			// onSaveAndSend()
		}

	}

	const handleShowImportCSVClose = () => {
		setShowImportCSV({
			show: false,
		})
	}

	const handleShowImportCSVOpen = () => {
		setShowImportCSV({
			show: true,
		})
	}

	const handleShowAddRecipientClose = (recipient) => {
		setShowAddRecipient({
			show: false,
			title: ''
		})

		if (recipient) {
			const recipientObj = {
				email: recipient.email ? recipient.email : '',
				firstName: recipient.firstName ? recipient.firstName : '',
				middleName: recipient?.middleName || '',
				lastName: recipient.lastName ? recipient.lastName : '',
				contactID: recipient.contactID ? recipient.contactID : null,
				documentID: window.firebase.firestore().collection('campaignRecipients').doc().id,
			}
			let contactWithNoEmail = recipients.filter(contact => !contact.email)
			let contactWithEmail = recipients.filter(contact => contact.email)
			contactWithEmail = _.uniqBy([...[recipientObj], ...contactWithEmail], 'email')
			setRecipients([...contactWithEmail, ...contactWithNoEmail]);
		}
	}

	const handleContactModalClose = selectedContacts => {
		setShowAddContact({
			...showAddContact,
			show: false,
		});
		if (_.isEmpty(selectedContacts)) {
			return;
		}
		// console.log('tehse are selected contactas', Object.keys(selectedContacts))

		let selectedContactsArr = Object.entries(selectedContacts).map(e => e[1])
		// console.log('selectedContactsArr', selectedContactsArr)
		selectedContactsArr = selectedContactsArr
			// .filter(contact => contact.email)
			.map(contact => {
				return {
					email: contact.email ? contact.email : '',
					firstName: contact.firstName ? contact.firstName : '',
					middleName: contact?.middleName || '',
					lastName: contact.lastName ? contact.lastName : '',
					documentID: window.firebase.firestore().collection('campaignRecipients').doc().id,
					amsproDocID: contact.documentID,
				};
			});
		let contactWithNoEmail = selectedContactsArr.filter(contact => !contact.email)
		let contactWithEmail = selectedContactsArr.filter(contact => contact.email)
		let data = _.uniqBy([...recipients, ...contactWithEmail], 'email')
		setRecipients([...data, ...contactWithNoEmail]);
		toast.notify('Recipients added successfully', {
			duration: 2000
		})
	};

	const handleModelClose = (template) => {
		setShowEdiorModel({
			show: false,
			template: null
		});

		if (template) {
			setData({
				...data,
				template,
			});
			toast.notify('Template added successfully', {
				duration: 2000
			})
		}
	};

	const handleOnChange = e => {
		const { name, value } = e.target;

		setData({
			...data,
			[name]: value,
		});
		setErrorFields({
			...errorFields,
			[name]: '',
		});
	};

	const handleSaveTitle = () => {
		let isValid = true;
		if (data.title === '') {
			isValid = false;
			setErrorFields({
				...errorFields,
				title: 'input_error',
			});
		}

		if (isValid) {
			setEditTitle({ show: false, snapshot: { title: '' } });
		}
	}

	const handleSaveSubject = () => {
		let isValid = true;
		if (data.subject === '') {
			isValid = false;
			setErrorFields({
				...errorFields,
				subject: 'input_error',
			});
		}

		if (isValid) {
			setEditSubject({ show: false, snapshot: { subject: '' } });
		}
	}

	const handleSaveFrom = () => {
		let isValid = true;
		let errors = { ...errorFields }
		if (data.fromName === '') {
			isValid = false;
			errors.fromName = 'input_error'
		}
		if (data.fromEmail === '') {
			isValid = false;
			errors.fromEmail = 'input_error'
		}
		// if (data.replyEmail === '') {
		// 	isValid = false;
		// 	errors.replyEmail = 'input_error'
		// }
		setErrorFields(errors);

		if (isValid) {
			setEditFrom({ show: false, snapshot: { fromName: '', fromEmail: '', replyEmail: '' } });
		}
	};

	const handleVerify = async () => {
		let isValid = true;
		let errors = { ...errorFields }
		if (data.fromName === '') {
			isValid = false;
			errors.fromName = 'input_error'
		}
		if (data.fromEmail === '') {
			isValid = false;
			errors.fromEmail = 'input_error'
		}
		setErrorFields(errors);

		if (!isValid) {
			return
		}
		setSenderLoader(true);
		const payload = {
			email: data.fromEmail,
			name: data.fromName,
			settingsID: props.dealersettings.oemID
		}
		if (props.dealersettings.level === 'oem' && localStorage.defaultModule.includes('oem') && props.dealersettings.oemID) {
			payload.oemID = props.dealersettings.oemID;
		}
		else if (props.dealersettings.level === 'region' && localStorage.defaultModule.includes('oem') && props.dealersettings.regionID) {
			payload.regionID = props.dealersettings.regionID;
		}
		else if (props.dealersettings.level === 'group' && localStorage.defaultModule.includes('oem') && props.dealersettings.groupID) {
			payload.groupID = props.dealersettings.groupID;
		}
		else {
			payload.clientID = props.dealersettings.client.id
		}
		const verifySender = await axios.post(
			`${props.apiUrl}mailjet-api/api/mailjet/verify-sender`,
			payload,
		);
		let msg = null;
		if (verifySender?.data?.Status === 'Active') {
			setTrustedSenders(true);
			setTrustedSenderEmail(data.fromEmail)
			setEditFrom({ show: false, snapshot: { fromName: '', fromEmail: '' } });
			msg = 'Email verified Successfully';
			setSenderLoader(false);
			setVerifyMsg(null)
		} else {
			setSenderLoader(false);
			msg = 'Email added Successfully';
			setVerifyMsg(`Your new email address has successfully added. Please verify it by clicking on the link you just received by email at ${data.fromEmail}.`)
		}
		Swal.fire({
			icon: 'success',
			title: msg,
			showConfirmButton: false,
			timer: 1500
		})
	}

	const onSaveAndSend = async (scheduleDate) => {
		if (!data.title) {
			toast.notify('Please add a title', {
				duration: 2000
			})
			return
		}

		if (!data.subject) {
			toast.notify('Please add a subject', {
				duration: 2000
			})
			return
		}

		if (!(data.fromName && data.fromEmail)) {
			toast.notify('Please fill out from section', {
				duration: 2000
			})
			return
		}

		if (!(recipients.length > 0)) {
			toast.notify('Please add recipients', {
				duration: 2000
			})
			return
		}

		if (_.isEmpty(data.template)) {
			toast.notify('Please add a template', {
				duration: 2000
			})
			return
		}

		if (!trustedSender) {
			Swal.fire(CommonHelper.showLocale(props, 'Please verify sender email address'), '', 'info')
			return
		}

		const result = await Swal.fire({
			title: CommonHelper.showLocale(props, 'Are you sure?'),
			text: CommonHelper.showLocale(props, 'Do you want to send this campaign'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
		})
		if (!result.value) {
			return
		}


		try {
			toast.notify('Campaign is being sent', {
				duration: 2000
			})

			let objData = {
				SenderName: data.fromName,
				SenderEmail: data.fromEmail,
				Title: data.title,
				Subject: data.subject,
				"Text-part": '',
				"ReplyEmail": data.replyEmail
			};
			let recipientsToSend = []


			if (!_.isEmpty(data.template) && data.template.bodyHTML) {
				objData["Html-part"] = compileWithTemplateLanguage(data.template.bodyHTML, props.dealersettings);
			}
			if (scheduleDate) {
				setScheduleLoader(true)
				objData['scheduleDate'] = scheduleDate
			}
			else {
				setSaveLoader(true);
			}
			console.log(scheduleDate, 'scheduleDate')
			recipientsToSend = recipients.filter(a => a.firstName && a.email).map(cont => {
				return {
					Email: cont?.email,
					Firstname: cont?.firstName,
					Name: CommonHelper.displayContactName([], cont),
					Properties: {
						firstname: cont?.firstName,
						name: CommonHelper.displayContactName([], cont)
					}
				}
			});

			//console.log('sendNewCampaign-objData', { campaign: objData, contact: { name: `${data.title}-${moment().unix()}`, list: recipientsToSend } })
			const url = `${props.apiUrl}mailjet-api/api/mailjet/create-campaign`
			const payload = {
				campaign: {
					...objData,
					Sender: props.dealersettings.client.id ? props.dealersettings.client.id : '',
					clientID: props.dealersettings.client.id ? props.dealersettings.client.id : '',
					clientName: props.dealersettings.client.name,
					clientAddress: props.dealersettings.client.address,
					clientCountry: props.dealersettings.client.country,
					clientWebsite: props.dealersettings.client.website,
				},
				contact: { name: `${data?.title?.substr(0, 40)}-${moment().unix()}`, list: recipientsToSend },
				settingsID: props.dealersettings.oemID,
				contactListID
			}
			// const timezone = props.timezone || moment.tz.guess();
			// const offset = moment().tz(timezone).utcOffset()
			// const date = moment().add(-offset, 'minutes').add(2, 'minutes').format('YYYY-MM-DDTHH:mm:ss')
			// if (_.isEmpty(scheduleDate) && recipientsToSend.length > 99) {
			// 	payload.campaign['scheduleDate'] = date
			// }
			if (props.dealersettings.level === 'oem' && localStorage.defaultModule.includes('oem') && props.dealersettings.oemID) {
				payload.oemID = props.dealersettings.oemID;
			}
			else if (props.dealersettings.level === 'region' && localStorage.defaultModule.includes('oem') && props.dealersettings.regionID) {
				payload.regionID = props.dealersettings.regionID;
			}
			else if (props.dealersettings.level === 'group' && localStorage.defaultModule.includes('oem') && props.dealersettings.groupID) {
				payload.groupID = props.dealersettings.groupID;
			}
			else {
				payload.clientID = props.dealersettings.client.id
			}
			const sendNewCampaignResp = await axios.post(
				url,
				payload,
			);
			if (
				sendNewCampaignResp?.data?.success
			) {
				toast.notify('Campaign sent successfully', {
					duration: 2000
				})
			}
			else {
				Swal.fire(sendNewCampaignResp?.data?.message, '', 'error')
			}
			setSaveLoader(false);
			setScheduleLoader(false)
			props.handleClose(true);
		} catch (error) {
			let message = error?.response?.data?.message
			if (message) {
				Swal.fire(message, '', 'error')
			}
			setSaveLoader(false);
			setScheduleLoader(false)
			console.log(error);
		}
	};

	const handleUploadRecipients = async () => {
		if (!data.title) {
			toast.notify('Please add a title', {
				duration: 2000
			})
			return
		}

		if (!(recipients.length > 0)) {
			toast.notify('Please add recipients', {
				duration: 2000
			})
			return
		}

		try {

			let recipientsToSend = []
			setContactLoader(true);
			recipientsToSend = recipients.filter(a => a.firstName && a.email).map(cont => {
				return {
					Email: cont?.email,
					Firstname: cont?.firstName,
					Name: CommonHelper.displayContactName([], cont),
					Properties: {
						firstname: cont?.firstName,
						name: CommonHelper.displayContactName([], cont)
					}
				}
			});

			//console.log('sendNewCampaign-objData', { campaign: objData, contact: { name: `${data.title}-${moment().unix()}`, list: recipientsToSend } })
			const url = `${props.apiUrl}mailjet-api/api/mailjet/upload-recipients`
			const payload = {
				contact: { name: `${data.title.substr(0, 39)}-${moment().unix()}`, list: recipientsToSend },
				settingsID: props.dealersettings.oemID
			}
			if (props.dealersettings.level === 'oem' && localStorage.defaultModule.includes('oem') && props.dealersettings.oemID) {
				payload.oemID = props.dealersettings.oemID;
			}
			else if (props.dealersettings.level === 'region' && localStorage.defaultModule.includes('oem') && props.dealersettings.regionID) {
				payload.regionID = props.dealersettings.regionID;
			}
			else if (props.dealersettings.level === 'group' && localStorage.defaultModule.includes('oem') && props.dealersettings.groupID) {
				payload.groupID = props.dealersettings.groupID;
			}
			else {
				payload.clientID = props.dealersettings.client.id
			}
			const sendNewCampaignResp = await axios.post(
				url,
				payload,
			);
			if (
				sendNewCampaignResp?.data?.success
			) {
				setContactListID(sendNewCampaignResp?.data?.data)
				toast.notify('Recipients uploaded successfully', {
					duration: 2000
				})
			}
			else {
				Swal.fire(sendNewCampaignResp?.data?.message, '', 'error')
			}
			setContactLoader(false);
		} catch (error) {
			let message = error?.response?.data?.message
			if (message) {
				Swal.fire(message, '', 'error')
			}
			setContactLoader(false);
			console.log(error);
		}
	}

	return (
		<>
			<Modal
				show={props.show}
				backdrop='static'
				backdropClassName={'overlay-modal active'}
				dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
			>
				<div className='modal-content modal-content-fullscreen'>
					{fullPageLoader && (
						<div className="lds-roller-loader text-center add-message-lds "><div className="lds-roller-loader-inner "><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3">fetching recipients...</p></div></div>
					)}
					<Modal.Header className='modal-header-fullscreen'>
						<div className='modal-title'>
							{' '}
							<Translate text={props.title} />
						</div>
						<div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom'>
							{
								contactListID > 0 && recipients?.length ? (
									<>
										<button
											type='button'
											onClick={() => onSaveAndSend()}
											className={`btn btn-primary float-left ml-2 mr-3 email-send-btn ${data.title &&
												data.subject &&
												data.fromEmail &&
												data.fromName &&
												recipients.length > 0 &&
												!_.isEmpty(data.template)
												? ''
												: 'disabled'
												}`}
										>
											{
												saveLoader ?
													(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
													: (<><i className='ico icon-mail-sent'> </i></>)
											}
											Send
										</button>
										<button
											type='button'
											className={`btn btn-default float-left ml-2 mr-3 ${data.title &&
												data.subject &&
												data.fromEmail &&
												data.fromName &&
												recipients.length > 0 &&
												!_.isEmpty(data.template)
												? ''
												: 'disabled'
												}`}
											onClick={handleShowScheduleCampaignOpen}
										>
											Schedule
										</button>
									</>
								) : (
									<button
										type='button'
										className={`btn btn-primary float-left ml-2 mr-3 ${recipients.length > 0 ? '' : 'disabled'}`}
										onClick={handleUploadRecipients}
									>
										{
											contactLoader ?
												(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
												: (<></>)
										}
										Upload Recipients
									</button>
								)
							}

							{/* <button
								type='button'
								className={`btn btn-default float-left ml-2 mr-3 ${data.title &&
									data.subject &&
									data.fromEmail &&
									data.fromName &&
									!_.isEmpty(data.template)
									? ''
									: 'disabled'
									}`}
								onClick={onFinishLater}
							>
								{
									saveLaterLoader ?
										(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
										: (<></>)
								}
								Finish Later
							</button> */}
							<button
								type='button'
								className='close close-fullscreen float-right'
								onClick={async () => {
									const result = await Swal.fire({
										title: CommonHelper.showLocale(props, 'Are you sure?'),
										text: CommonHelper.showLocale(props, 'Do you want to close this campaign.'),
										icon: 'info',
										showCancelButton: true,
										confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
										cancelButtonText: CommonHelper.showLocale(props, 'No'),
									})
									if (result.value) {
										props.handleClose()
									}
								}}
							>
								×
							</button>
						</div>
					</Modal.Header>
					<Modal.Body className='modal-body-fullscreen'>
						<div className='popup-wrap-fullscreen h-100'>
							<div className='container-fluid pl-0 '>
								<div className='campaign-add-wrapper-head'>
									{editTitle.show ? (
										<div className='form-group campaign-title-edit'>
											<label htmlFor=''>Title</label>
											<input
												type='text'
												name='title'
												className={`form-control ${errorFields['title']}`}
												id=''
												value={data.title}
												onChange={handleOnChange}
												placeholder='title'
												autoComplete="off"
											/>
											<button
												type='submit'
												className='btn btn-primary float-right mt-3 ml-2'
												onClick={handleSaveTitle}
											>
												Save
											</button>
											<button
												type='submit'
												className='btn btn-default float-right mt-3'
												onClick={() => {
													setData({
														...data,
														title: editTitle.snapshot.title
													})
													setEditTitle({ show: false, snapshot: { title: '' } });
												}}
											>
												Cancel
											</button>
										</div>
									) : (
										<div className='campaign-add-title'>
											{data.title ? data.title : 'Untitled Campaign'}{' '}
											<a
												className='mini-button campaign-edit-title'
												onClick={() => setEditTitle({ show: true, snapshot: { title: data.title } })}
											>
												{' '}
												<i className='ico icon-edit'></i>
											</a>
										</div>
									)}
									<div className='campaign-add-sub-title'>
										<div className='badge badge-pill badge-draft-fill float-left'>
											DRAFT
										</div>
										{/* <span className='float-left campaign-add-date'>
											Created on 29/01/2021 04:00 PM
										</span> */}
									</div>
								</div>
								<div className='campaign-add-wrapper'>
									<div className='campaign-add-loop'>
										<div className='campaign-check-icon'>
											<div
												className={`campaign-check-icon-wrap ${data.subject.length > 0 ? 'active-icon' : ''
													}`}
											>
												<i className='ico icon-sent'></i>
											</div>
										</div>
										<div className='campaign-add-head'>Subject</div>
										{editSubject.show ? (
											<div className='campaign-edit-view mt-3 form-style'>
												<div className='form-group'>
													<label htmlFor=''>Subject</label>
													<input
														type='text'
														name='subject'
														className={`form-control ${errorFields['subject']}`}
														value={data.subject}
														onChange={handleOnChange}
														placeholder='subject'
														autoComplete="off"
													/>
												</div>

												<button
													type='submit'
													className='btn btn-primary float-right mt-3 ml-2'
													onClick={handleSaveSubject}
												>
													Save
												</button>
												<button
													type='submit'
													className='btn btn-default float-right mt-3'
													onClick={() => {
														setData({
															...data,
															subject: editSubject.snapshot.subject,
														});
														setEditSubject({ show: false, snapshot: { subject: '' } });
													}}
												>
													Cancel
												</button>
											</div>
										) : data.subject.length > 0 ? (
											<div className='campaign-add-contentwrap'>
												<div className='campaign-add-content-info font-bold'>
													{data.subject ? data.subject : 'No Subject'}
												</div>
												<div className='campaign-add-content-button'>
													<button
														type='button'
														className='btn btn-primary-outline '
														onClick={() => setEditSubject({ show: true, snapshot: { subject: data.subject } })}
													>
														{' '}
														Edit Subject{' '}
													</button>
												</div>
											</div>
										) : (
											<div className='campaign-add-contentwrap'>
												<div className='campaign-add-content-info'>
													What's the subject line for this campaign?
												</div>
												<div className='campaign-add-content-button'>
													<button
														type='button'
														className='btn btn-primary '
														onClick={() => setEditSubject({ show: true, snapshot: { subject: data.subject } })}
													>
														{' '}
														Add Subject{' '}
													</button>
												</div>
											</div>
										)}
									</div>

									<div className='campaign-add-loop'>
										<div className='campaign-check-icon'>
											<div
												className={`campaign-check-icon-wrap ${data.fromName && data.fromEmail ? 'active-icon' : ''
													}`}
											>
												<i className='ico icon-sent'></i>
											</div>
										</div>
										<div className='campaign-add-head'>From</div>
										{editFrom.show ? (
											<div className='campaign-edit-view mt-3 form-style'>
												<div className='form-group'>
													<label htmlFor=''>Name</label>
													<input
														type='text'
														name='fromName'
														className={`form-control ${errorFields['fromName']}`}
														id=''
														value={data.fromName}
														onChange={handleOnChange}
														placeholder='name'
														autoComplete="off"
													/>
												</div>
												<div className='form-group'>
													<label htmlFor=''>Email Address</label>
													<input
														type='email'
														name='fromEmail'
														className={`form-control ${errorFields['fromEmail']}`}
														id=''
														value={data.fromEmail}
														onChange={handleOnChange}
														placeholder='email'
														autoComplete="off"
													/>
												</div>
												<div className='form-group'>
													<label htmlFor=''>Reply Email</label>
													<input
														type='email'
														name='replyEmail'
														className={`form-control ${errorFields['replyEmail']}`}
														id=''
														value={data.replyEmail}
														onChange={handleOnChange}
														placeholder='email'
														autoComplete="off"
													/>
												</div>
												{
													!_.isEmpty(verifyMsg) ? (
														<div className="alert alert-success mb-0 mt-2" role="alert">{verifyMsg}</div>
													) : (<></>)
												}
												{
													!trustedSender || trustedSenderEmail !== data.fromEmail ? (
														<button
															type='submit'
															className='btn btn-primary float-right mt-3 ml-2'
															onClick={handleVerify}
														>
															{
																senderLoader ?
																	(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
																	: (<></>)
															}
															Verify Now
														</button>
													) : (<>
														<button
															type='submit'
															className='btn btn-primary float-right mt-3 ml-2'
															onClick={handleSaveFrom}
														>
															Save
														</button>
														<button
															type='submit'
															className='btn btn-default float-right mt-3'
															onClick={() => {
																setData({
																	...data,
																	fromEmail: editFrom.snapshot.fromEmail,
																	fromName: editFrom.snapshot.fromName,
																	replyEmail: editFrom.snapshot.replyEmail
																});
																setEditFrom({ show: false, snapshot: { fromName: '', fromEmail: '', replyEmail: '' } });
															}}
														>
															Cancel
														</button>
													</>)
												}

											</div>
										) : data.fromName && data.fromEmail ? (
											<div className='campaign-add-contentwrap'>
												<div className='campaign-add-content-info'>
													<div>{data.fromName}</div>
													<div className='font-bold'>{data.fromEmail}</div>
												</div>

												<div className='campaign-add-content-button'>
													<button
														type='button'
														className='btn btn-primary-outline '
														onClick={() => setEditFrom({ show: true, snapshot: { fromName: data.fromName, fromEmail: data.fromEmail, replyEmail: data.replyEmail } })}
													>
														{' '}
														Edit From{' '}
													</button>
												</div>
											</div>
										) : (
											<div className='campaign-add-contentwrap'>
												<div className='campaign-add-content-info'>
													Who is sending this campaign?
												</div>
												<div className='campaign-add-content-button'>
													<button
														type='button'
														className='btn btn-primary '
														onClick={() => setEditFrom({ show: true, snapshot: { fromName: data.fromName, fromEmail: data.fromEmail, replyEmail: data.replyEmail } })}
													>
														{' '}
														Add From{' '}
													</button>
												</div>
											</div>
										)}
									</div>

									<div className='campaign-add-loop'>
										<div className='campaign-check-icon'>
											<div
												className={`campaign-check-icon-wrap ${recipients.length > 0 ? 'active-icon' : ''
													}`}
											>
												<i className='ico icon-sent'></i>
											</div>
										</div>
										<div className='campaign-add-head'>To</div>
										{recipients.length > 0 ? (
											<div className='campaign-add-contentwrap'>
												<div className='campaign-add-content-info'>
													{recipients.length > 0 ? (
														<>
															Selected Contacts -{' '}
															<span className='font-bold'>{recipients.length}</span>{' '}
														</>
													) : (
														<>None Selected</>
													)}
												</div>
												<div className='campaign-add-content-button'>
													<button
														type='button'
														className='btn btn-primary-outline mr-2'
														onClick={() =>
															setShowRecipients({
																...showRecipients,
																show: true,
																title: 'View Recipients',
															})
														}
													>
														{' '}
														View Recipients{' '}
													</button>
													<button
														type='button'
														className='btn btn-primary '
														onClick={() =>
															setShowAddContact({
																...showAddContact,
																show: true,
																title: 'Import Recipients',
															})
														}
														disabled={contactListID > 0 && recipients?.length}
													>
														{' '}
														Import Recipients{' '}
													</button>
													<button
														type='button'
														className='btn btn-secondary ml-2 mb-0'
														onClick={handleShowImportCSVOpen}
														disabled={contactListID > 0 && recipients?.length}
													>
														{' '}
														Import CSV{' '}
													</button>
													{/* <label htmlFor="logoURL" className={`btn btn-secondary ml-2 mb-0`}>
															Import CSV
															<input 
																	className="fileInput"
																	type="file"
																	name="testee"
																	id="logoURL"
																	// accept="image/*"
																	style={{ display: 'none' }}
																	onChange={(e) => onSelectFile(e)}
																	autoComplete="off"
															/>
													</label> */}
													<button
														type='button'
														className='btn btn-secondary ml-2'
														onClick={handleShowAddRecipientOpen}
														disabled={contactListID > 0 && recipients?.length}
													>
														{' '}
														Add Recipient{' '}
													</button>

												</div>
											</div>
										) : (
											<div className='campaign-add-contentwrap'>
												<div className='campaign-add-content-info'>
													Add the contacts you want to send this email to.
												</div>
												<div className='campaign-add-content-button'>
													<button
														type='button'
														className='btn btn-primary'
														onClick={() =>
															setShowAddContact({
																...showAddContact,
																show: true,
																title: 'Import Recipients',
															})
														}
													>
														{' '}
														Import Recipients{' '}
													</button>
													<button
														type='button'
														className='btn btn-secondary ml-2 mb-0'
														onClick={handleShowImportCSVOpen}
													>
														{' '}
														Import CSV{' '}
													</button>
													{/* <label htmlFor="logoURL" className={`btn btn-secondary ml-2 mb-0`}>
															Import CSV
															<input className="fileInput"
																	type="file"
																	name="testee"
																	id="logoURL"
																	style={{ display: 'none' }}
																	onChange={(e) => onSelectFile(e)} 
																	autoComplete="off"
															 />
													</label> */}
													<button
														type='button'
														className='btn btn-secondary ml-2'
														onClick={handleShowAddRecipientOpen}
													>
														{' '}
														Add Recipient{' '}
													</button>
												</div>
											</div>
										)}
									</div>

									<div className='campaign-add-loop'>
										<div className='campaign-check-icon'>
											<div
												className={`campaign-check-icon-wrap ${!_.isEmpty(data.template) ? 'active-icon' : ''
													}`}
											>
												<i className='ico icon-sent'></i>
											</div>
										</div>
										<div className='campaign-add-head'>Content</div>
										{!_.isEmpty(data.template) ? (
											<div className='campaign-add-contentwrap'>
												<div className='campaign-add-content-info'>
													Template Options <span className="font-weight-bold">{data.template.title}</span>
												</div>
												<div className='campaign-add-content-button'>
													{!templateFromVision && (
														<button
															type='button'
															className='btn btn-primary-outline float-left ml-2'
															onClick={() => {
																setPopHeader(data.template.name ? data.template.name : data.template.title ? data.template.title : 'Edit Template')
																setShowEdiorModel({
																	show: true,
																	template: data.template
																});
															}
															}
														>
															{' '}
															<i className='ico icon-template custom-icon-margin'></i> Edit Template{' '}
														</button>
													)}
													{!_.isEmpty(data.template) && data.template.bodyHTML && (
														<button
															type='button'
															className='btn btn-green float-left ml-2'
															onClick={() =>
																setShowPreviewTemplate({
																	...showPreviewTemplate,
																	title: 'Preview',
																	show: true,
																})
															}
														>
															{' '}
															<i className="fa fa-eye custom-icon-preview" aria-hidden="true"></i>  Preview{' '}
														</button>
													)}
													<button
														type='button'
														className='btn btn-primary float-left ml-2'
														onClick={() => {
															setData({
																...data,
																template: null,
															});
															setPopHeader('Add Email Template');
															setShowEdiorModel({
																show: true,
																template: null
															});
														}}
													>
														<i className='ico icon-add mr-2'></i>{' '}
														<Translate text={'Design Email'} />
													</button>
													<button
														type='button'
														className='btn btn-secondary float-right ml-2'
														onClick={() =>
															setShowAddTemplate({
																...showAddTemplate,
																title: 'Select Template',
																show: true,
															})
														}
													>
														{' '}
														<i className='ico icon-template custom-icon-margin'></i> Select Template{' '}
													</button>
												</div>
											</div>
										) : (
											<div className='campaign-add-contentwrap'>
												<div className='campaign-add-content-info'>
													Design the content for your email.
												</div>
												{/* <div className='campaign-add-template-icon'>
													<img src='images/template.svgz' alt='' width='100' />
												</div> */}
												<div className='campaign-add-content-button'>
													<button
														type='button'
														className='btn btn-primary'
														onClick={() => {
															setData({
																...data,
																template: null,
															});
															setPopHeader('Add Email Template');
															setShowEdiorModel({
																show: true,
																template: null
															});
														}}
													>
														<i className='ico icon-add custom-icon-margin mr-2'></i>{' '}
														<Translate text={'Design Email'} />
													</button>
													<button
														type='button'
														className='btn btn-secondary float-right ml-2'
														onClick={() =>
															setShowAddTemplate({
																...showAddTemplate,
																title: 'Select Template',
																show: true,
															})
														}
													>
														{' '}
														<i className='ico icon-template'></i> Select Template{' '}
													</button>

												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
				</div >
				<PopUpModal show={showEdiorModel.show}>
					<TemplateEditor
						{...props}
						show={showEdiorModel.show}
						title={popHeader}
						handleClose={handleModelClose}
						template={showEdiorModel.template}
						dealersettings={props.dealersettings}
						returnAfterCreation={true}
					/>
				</PopUpModal>
				<PopUpModal show={showAddTemplate.show}>
					<AddTemplate
						show={showAddTemplate.show}
						handleClose={handleTemplateModalClose}
						title={showAddTemplate.title}
						dealersettings={props.dealersettings}
						clientUsers={props.clientUsers}
						emailTemplateList={emailTemplateList}
						selectedTemplate={data.template}
						setSelectedTemplate={setSelectedTemplate}
						apiUrl={props.apiUrl}
						SERVER_PREFIX={props.SERVER_PREFIX}
					/>
				</PopUpModal>
				<PopUpModal show={showAddContact.show}>
					<AddContact
						show={showAddContact.show}
						handleClose={handleContactModalClose}
						title={showAddContact.title}
						dealersettings={props.dealersettings}
						clientUsers={props.clientUsers}
						updateDealerSettings={props.updateDealerSettings}
						history={props.history}
						apiUrl={props.apiUrl}
						SERVER_PREFIX={props.SERVER_PREFIX}
					/>
				</PopUpModal>
				<PopUpModal show={showRecipients.show}>
					<ViewRecipients
						show={showRecipients.show}
						handleClose={handleShowRecipientsModalClose}
						title={showRecipients.title}
						dealersettings={props.dealersettings}
						clientUsers={props.clientUsers}
						updateDealerSettings={props.updateDealerSettings}
						history={props.history}
						recipients={recipients}
						setRecipients={setRecipients}
						apiUrl={props.apiUrl}
						SERVER_PREFIX={props.SERVER_PREFIX}
					/>
				</PopUpModal>
				<PopUpModal show={showAddRecipient.show}>
					<AddRecipient
						show={showAddRecipient.show}
						handleClose={handleShowAddRecipientClose}
						title={showAddRecipient.title}
						dealersettings={props.dealersettings}
						clientUsers={props.clientUsers}
						updateDealerSettings={props.updateDealerSettings}
						history={props.history}
						recipients={recipients}
						setRecipients={setRecipients}
					/>
				</PopUpModal>
				<PopUpModal show={showScheduleCampaign.show}>
					<ScheduleCampaign
						show={showScheduleCampaign.show}
						handleClose={handleShowScheduleCampaignClose}
						handleSchedule={onSaveAndSend}
						title={showScheduleCampaign.title}
						scheduleLoader={scheduleLoader}
						timezone={props.timezone}
					/>
				</PopUpModal>
				<PopUpModal show={showImportCSV.show}>
					<ImportCSV
						show={showImportCSV.show}
						handleClose={handleShowImportCSVClose}
						title={showImportCSV.title}
						recipients={recipients}
						setRecipients={setRecipients}
					/>
				</PopUpModal>

				<PopUpModal show={showPreviewTemplate.show}>
					<PreviewTemplate
						show={showPreviewTemplate.show}
						handleClose={() =>
							setShowPreviewTemplate({
								...showPreviewTemplate,
								title: '',
								show: false,
							})}
						title={showPreviewTemplate.title}
						bodyHTML={!_.isEmpty(data.template) ? data.template.bodyHTML : null}
					/>
				</PopUpModal>
			</Modal >
		</>
	);
};

export default AddCampaign;
