import React, { useState, useEffect } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import uuid from 'react-uuid'
import NumberFormat from 'react-number-format';
import { InputText, ReactSelect, InputCheckBox, ReactMultiSelect, ReactAsyncSelect } from '../../../../components'
import Translate from '../../../../constants/translate';
import { lookupVM, clientLookups, levelOptions, activityIcons, productCategory, fileCategory, mappingProductCols } from './viewModel'
import { statusColors } from '../../../../services/enum'
import { SketchPicker } from 'react-color';
import CommonHelper from '../../../../services/common';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CustomTable from '../../../../components/customTable';

const AddLookup = (props) => {

    const [lookup, setLookup] = useState(props.lookup)
    const [oldLookup, setOldLookup] = useState(props.lookup ? props.lookup : {})
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)
    const newLookup = !_.isEmpty(props.lookup) ? false : true;
    const [integarions, setIntegration] = useState([]);
    const [addSubList, setAddSubList] = useState({ type: '', value: '', id: uuid() })
    const [textEditor, setTexteditor] = useState({})
    const [editSubList, setEditSubList] = useState()
    const [subList, setSubList] = useState(!_.isEmpty(props.lookup) && !_.isEmpty(props.lookup.dmsKeys) ? props.lookup.dmsKeys.map(r => { return { ...r, id: r.id ? r.id : uuid() } }) : []);
    const [colorPicker, setColorPicker] = useState(false)
    const [brands, setBrands] = useState([])
    const [colorerrorFields, setColorErrorFields] = useState({});
    const [addColorSubList, setColorAddSubList] = useState({ make: '', color: '', id: uuid() })
    const [colorsubList, setColorSubList] = useState(!_.isEmpty(props.lookup) && !_.isEmpty(props.lookup.colors) ? props.lookup.colors.map(r => { return { ...r, id: r.id ? r.id : uuid() } }) : []);
    const [editColorSubList, setColorEditSubList] = useState()
    const [colortextEditor, setColorTexteditor] = useState({})
    const [editcolorPicker, setEditColorPicker] = useState(false)
    const [addonList, setAddonList] = useState(!_.isEmpty(props.lookup) && !_.isEmpty(props.lookup.subList) ? props.lookup.subList : []);
    const [addAddonList, setaddAddonList] = useState({ name: '', value: '' })
    const [editaddonList, setEditAddonList] = useState()
    const [subtextEditor, setSubTexteditor] = useState({})
    const [provider, setProvider] = useState([]);

    const disableEdit = (newLookup || props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: lookup.level })) ? false :
        (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: lookup.level }).index) ? true : false
    const styles = {
        colorPicker: {
            width: '100%',
            height: '20px',
            borderRadius: '2px',
            border: '1px solid #e5e5e5',
            background:
                !_.isEmpty(lookup) && lookup.color
                    ? lookup.color
                    : '#FFFFFF',
        },
        swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
            width: '100%',
            display: 'flex'
        },
        popover: {
            position: 'absolute',
            zIndex: '2',
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
    };
    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const rosterCategories = props?.dealersettings?.rosterSettings?.rosterCategories ?
        props.dealersettings.rosterSettings.rosterCategories.filter(a => a.active === true) : []

    useEffect(() => {
        if (_.isEmpty(props.lookup)) {
            const newlookupVM = Object.assign({}, lookupVM);
            newlookupVM.sortNo = props.allLookups[props.activeLookup] ? (props.allLookups[props.activeLookup].length + 1) : 1
            if (props.activeLookup === 'activityTypes')
                newlookupVM.icon = 'activity-training'
            setLookup(newlookupVM)
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(props?.dealersettings?.oemBrands))
            return
        let _brands = []
        props.dealersettings.oemBrands.map(rec => {
            return _brands.push({
                label: rec.name,
                value: rec.value
            })
        })
        setBrands([
            ..._.orderBy(_brands, 'label', 'asc'),
            {
                label: 'Others',
                value: 'others'
            }
        ])
    }, [])

    useEffect(() => {
        if (_.isEmpty(props.dealersettings) || _.isEmpty(props.dealersettings.integrations) || _.isEmpty(props.dealersettings.client) || _.isEmpty(props.dealersettings.client.integrations))
            return
        let _integration = [];
        _.filter(props.dealersettings.integrations, (v) => _.indexOf(props.dealersettings.client.integrations.filter(e => e.active === true && e.enabled === true).map(v => _.pick(v, ['type']).type), v.value) >= 0)
            .map(rec => {
                return _integration.push({
                    label: rec.name,
                    value: rec.value
                })
            })
        setIntegration(_integration)
    }, [])

    useEffect(() => {
        if (props.activeLookup !== 'productAddons') {
            return;
        }
        const _provider = [];
        if (props?.allLookups?.[props.activeLookup]?.length) {
            props.allLookups[props.activeLookup].forEach(rec => {
                if (rec?.subList?.length)
                    _provider.push(...rec.subList.map(r => {
                        return {
                            ...r,
                            label: r.name,
                            searchLabel: r.name
                        }
                    }))
            })
        }

        setProvider(_provider)

    }, [props.allLookups])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (name === 'value') {
            setLookup({
                ...lookup,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setLookup({
                    ...lookup,
                    [name]: newarray1.join(' '),
                    ['value']: newLookup ? props.activeLookup === 'rosters' ? '' : uuid() : lookup.value
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setLookup({
                    ...lookup,
                    [name]: str,
                    ['value']: newLookup ? props.activeLookup === 'rosters' ? '' : uuid() : lookup.value
                });
            }
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handlecolorSelectChange = (e, data) => {
        setLookup({
            ...lookup,
            [data.name]: e ? e.value : ''
        });
    }

    const handleSelectChange = (e, data) => {
        if (data.name === 'level') {
            setLookup({
                ...lookup,
                [data.name]: e.value,
                ['levelName']: (data.name === 'level' && e.value === 'oem') ? props.selectedOEM.name : data.name === 'levelID' ? e.data.name : '',
                ['levelID']: (data.name === 'level' && e.value === 'oem') ? [props.selectedOEM.id] : '',
                ['groupIDs']: '',
                ['clientIDs']: ''
            });
        }
        else {
            setLookup({
                ...lookup,
                [data.name]: e.value,
                ['levelName']: (data.name === 'level' && e.value === 'oem') ? props.selectedOEM.name : data.name === 'levelID' ? e.data.name : '',
                ['groupIDs']: e.groupIDs,
                ['clientIDs']: e.clientIDs
            });
        }
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleReactMultiSelectChange = (selectedOptions, name) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        setLookup({
            ...lookup,
            [name]: value
        });

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleCheckedChange = (e) => {
        setLookup({
            ...lookup,
            [e.target.name]: e.target.checked
        })
    }

    // CAN UPDATE DIFFIRENT LEVEL
    const saveLookup = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        let _regionLookups = [];
        let _groupLookups = [];
        let _clientLookups = []
        let activeLookupName = (_.find(clientLookups, { 'value': props.activeLookup }) ? _.find(clientLookups, { 'value': props.activeLookup }).name : 'Lookups')
        if (_.isEmpty(lookup['name']) && props.activeLookup !== 'productAddons') {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        if (_.isEmpty(lookup['module']) && (props.activeLookup === 'productAddons' || props.activeLookup === 'filesCategory')) {
            formIsValid = false;
            errors['module'] = errorClass;
        }
        if (_.isEmpty(lookup['category']) && (props.activeLookup === 'productAddons')) {
            formIsValid = false;
            errors['category'] = errorClass;
        }
        if (props.activeLookup === 'rosters' && _.isEmpty(lookup['value'])) {
            formIsValid = false;
            errors['value'] = errorClass;
        }
        if (!lookup['price'] && lookup['price'] !== 0 && (props.activeLookup === 'servicePackages')) {
            formIsValid = false;
            errors['price'] = errorClass;
        }
        if (_.isEmpty(lookup['level'])) {
            formIsValid = false;
            errors['level'] = errorClass;
        }
        if (!_.isEmpty(lookup.level) && lookup.level !== 'oem' && _.isEmpty(lookup['levelID'])) {
            formIsValid = false;
            errors['levelID'] = errorClass;
        }
        setErrorFields(errors)
        if (props.activeLookup === 'filesCategory' && newLookup && props.allLookups[props.activeLookup] && props.allLookups[props.activeLookup].some(item => item?.name?.toLowerCase() === lookup?.name?.toLowerCase() && item.module === lookup.module && lookup.levelID.some(a => item.levelID === a))) {
            Swal.fire(`${activeLookupName} ${CommonHelper.showLocale(props, 'with same name already exists')}`, '', 'error')
            formIsValid = false;
        } else if (props.activeLookup !== 'filesCategory' && newLookup && props.allLookups[props.activeLookup] && props.allLookups[props.activeLookup].some(item => item?.name?.toLowerCase() === lookup?.name?.toLowerCase() && lookup.levelID.some(a => item.levelID === a))) {
            Swal.fire(`${activeLookupName} ${CommonHelper.showLocale(props, 'with same name already exists')}`, '', 'error')
            formIsValid = false;
        }
        if (!lookup['color'] && (props.activeLookup === 'financeStatus' || props.activeLookup === 'productStatus' || props.activeLookup === 'afterMarketStatus' || props.activeLookup === 'enquiryStatus' || props.activeLookup === 'stockStatus' || props.activeLookup === 'checkoutPerformance' || props.activeLookup === 'acquisitionSource' || props.activeLookup === 'rosters')) {
            formIsValid = false;
            errors['color'] = errorClass;
        }
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        if (_.isEmpty(lookup.value)) {
            lookup.value = uuid();
        }
        const batch = window.firebase.firestore().batch();
        if (lookup.level === 'oem' || oldLookup.level === 'oem') {
            const lookupObj = {
                [props.activeLookup]: (newLookup || oldLookup.level !== 'oem') ? [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level), function (obj) { return { ..._.pick(obj, _.keys(lookupVM)) }; }), _.pick(lookup, _.keys(lookupVM))] :
                    [..._.map(props.allLookups[props.activeLookup].filter(item => ((oldLookup.level === 'oem' && lookup.level !== 'oem') ? (item.value !== oldLookup.value && item.level === oldLookup.level) : item.level === lookup.level)), function (obj) {
                        return obj.value === lookup.value ? { ..._.pick(lookup, _.keys(lookupVM)) } : { ..._.pick(obj, _.keys(lookupVM)) };
                    })]
            }
            console.log('oem', lookupObj)
            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}`),
                {
                    ...CommonHelper.removeEmptyOrNull(lookupObj),
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedBy: localStorage.uid
                }, { merge: true });
            if (_.isArray(oldLookup.levelID)) {
                oldLookup.levelID.forEach(rec => {
                    const updatelookupObj = {
                        [props.activeLookup]: [..._.map(props.allLookups[props.activeLookup].filter(item => item.value !== oldLookup.value && item.level === oldLookup.level && rec === oldLookup.levelID), function (obj) {
                            return { ..._.pick(obj, _.keys(lookupVM)) };
                        })]
                    }
                    console.log(`${oldLookup.level}-${rec}`, updatelookupObj)
                    if (oldLookup.level !== 'oem')
                        batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${oldLookup.level === 'group' ? 'groups' : oldLookup.level === 'region' ? 'regions' : 'clients'}/${rec}`),
                            {
                                ...CommonHelper.removeEmptyOrNull(updatelookupObj),
                                modifiedDate: window.firebase.firestore.Timestamp.now(),
                                modifiedBy: localStorage.uid
                            }, { merge: true });
                })
            }

        }
        if (_.isArray(lookup.levelID)) {
            lookup.levelID.forEach(rec => {
                const lookupObj = {
                    [props.activeLookup]: (newLookup || !oldLookup.levelID.includes(rec)) ? [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level && item.levelID === rec), function (obj) { return { ..._.pick(obj, _.keys(lookupVM)) }; }), _.pick(lookup, _.keys(lookupVM))] :
                        [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level && rec === item.levelID), function (obj) {
                            return obj.value === lookup.value ? { ..._.pick(lookup, _.keys(lookupVM)) } : { ..._.pick(obj, _.keys(lookupVM)) };
                        })]
                }
                console.log(`${lookup.level}-${rec}`, lookupObj)
                if (lookup.level !== 'oem')
                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${lookup.level === 'group' ? 'groups' : lookup.level === 'region' ? 'regions' : 'clients'}/${rec}`),
                        {
                            ...CommonHelper.removeEmptyOrNull(lookupObj),
                            modifiedDate: window.firebase.firestore.Timestamp.now(),
                            modifiedBy: localStorage.uid
                        }, { merge: true });
            })
            if (_.isArray(oldLookup.levelID)) {
                (oldLookup.level === lookup.level ? _.difference(oldLookup.levelID, lookup.levelID) : oldLookup.levelID).forEach(rec => {
                    const updatelookupObj = {
                        [props.activeLookup]: [..._.map(props.allLookups[props.activeLookup].filter(item => item.value !== oldLookup.value && item.level === oldLookup.level && rec === item.levelID), function (obj) {
                            return { ..._.pick(obj, _.keys(lookupVM)) };
                        })]
                    }
                    console.log(`${oldLookup.level}-${rec}`, updatelookupObj)
                    if (oldLookup.level !== 'oem')
                        batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${oldLookup.level === 'group' ? 'groups' : oldLookup.level === 'region' ? 'regions' : 'clients'}/${rec}`),
                            {
                                ...CommonHelper.removeEmptyOrNull(updatelookupObj),
                                modifiedDate: window.firebase.firestore.Timestamp.now(),
                                modifiedBy: localStorage.uid
                            }, { merge: true });
                })
            }
        }
        // else {
        //     const lookupObj = {
        //         [props.activeLookup]: newLookup ? [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level && item.levelID === lookup.levelID), function (obj) { return { ..._.pick(obj, _.keys(lookupVM)) }; }), _.pick(lookup, _.keys(lookupVM))] :
        //             [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level && item.levelID === lookup.levelID), function (obj) {
        //                 return obj.value === lookup.value ? { ..._.pick(lookup, _.keys(lookupVM)) } : { ..._.pick(obj, _.keys(lookupVM)) };
        //             })]
        //     }
        //     console.log(`${lookup.level}`, lookupObj)
        //     batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${lookup.level === 'group' ? 'groups' : lookup.level === 'region' ? 'regions' : 'clients'}/${lookup.levelID}`),
        //         lookupObj, { merge: true });
        // }

        if (lookup.level !== 'oem' && (props.activeLookup === 'activityTypes' || props.activeLookup === 'enquiryStatus' || props.activeLookup === 'stockStatus' || props.activeLookup === 'enquiryLabels' || props.activeLookup === 'filesCategory') && props.allLookups[props.activeLookup].some(a => a.static === true)) {
            const lookupObj = {
                [props.activeLookup]: _.map(props.allLookups[props.activeLookup].filter(item => item.level === 'oem'), function (obj) { return { ..._.pick(obj, _.keys(lookupVM)) }; })

            }
            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}`),
                {
                    ...CommonHelper.removeEmptyOrNull(lookupObj),
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedBy: localStorage.uid
                }, { merge: true });
        }

        batch.commit()
            .then(snapshot => {
                setLoader(false)
                //update settings date and priority 
                //console.log('addLookup props.selectedClient', props.selectedClient)
                // if (!_.isEmpty(props.selectedClient)) {
                window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                    .set({
                        settingsUpdate: window.firebase.firestore.Timestamp.now(),
                        settingsUpdatePriority: 'normal',
                        settingsUpdateBy: props?.dealersettings?.name || null,
                        settingsUpdateText: 'Dynamic Field Settings has been updated',
                    }, { merge: true })
                if (localStorage.defaultModule === 'oem' && props.dealersettings.oemID) {
                    window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: 'Dynamic Field Settings has been updated',
                        }, { merge: true })
                }
                // }
                //end 
                toast.notify((newLookup === true ? activeLookupName + ' added successfully' : activeLookupName + ' updated successfully'), {
                    duration: 2000
                })
                props.handleClose(lookup, newLookup)
            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
                Swal.fire('Something went wrong', '', 'error')
            });

    }

    const onCurrencyChange = (e, name) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  
        let _value = e.floatValue
        if (_value === undefined) {
            _value = ''
        }
        setLookup({
            ...lookup,
            [name]: _value
        });

        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const handleiconChange = (val) => {
        setLookup({
            ...lookup,
            ['icon']: val
        });
    }

    const handleOnSubChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;

        setAddSubList({
            ...addSubList,
            [name]: value
        });

        setErrorFields({
            ...errorFields,
            ['sublist' + name]: ''
        });
    }

    const handleonSubSelectChange = (e, data) => {

        setAddSubList({
            ...addSubList,
            [data.name]: e ? e.value : ''
        })
        setErrorFields({
            ...errorFields,
            ['sublist' + data.name]: ''
        });
    }
    const handleonEditSubSelectChange = (e, data) => {

        setEditSubList({
            ...editSubList,
            [data.name]: e ? e.value : ''
        })
        setErrorFields({
            ...errorFields,
            ['editsublist' + data.name]: ''
        });
    }
    const handleOnEditSubChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        setEditSubList({
            ...editSubList,
            [name]: value
        });
        setErrorFields({
            ...errorFields,
            ['editsublist' + name]: ''
        });
    }

    const handleCancelSubList = () => {
        setEditSubList(null)
        setTexteditor({})
    }

    const handleEditSubList = (rec) => {
        setEditSubList(rec)
        setTexteditor({
            [rec.id]: true
        })
    }
    const handleDeleteSubList = (rec) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                let deleteList = [...subList.filter(item => item.id !== rec.id)];
                setSubList(deleteList)
                setLookup({
                    ...lookup,
                    ['dmsKeys']: deleteList
                });
            }
        })
    }

    const handleSublistSave = (rec, newlist) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(rec['type'])) {
            formIsValid = false;
            if (newlist)
                errors['sublistname'] = errorClass;
            else
                errors['editsublistname'] = errorClass;
        }
        if (_.isEmpty(rec['value']) || _.isEmpty(rec['value'].trim())) {
            formIsValid = false;
            if (newlist)
                errors['sublistvalue'] = errorClass;
            else
                errors['editsublistvalue'] = errorClass;
        }
        setErrorFields(errors)
        if (subList.filter(item => item.id !== rec.id).some(item => item.type === rec.type)) {
            Swal.fire(CommonHelper.showLocale(props, 'Integration with same keys already exists'), '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        let addList = newlist ? [...subList, rec] : [
            ..._.map(subList, function (obj) {
                return obj.id === rec.id ? { ...rec } : { ...obj };
            })];
        setSubList(addList)
        setLookup({
            ...lookup,
            ['dmsKeys']: addList
        });
        setAddSubList({ type: '', value: '', id: uuid() })
        setEditSubList(null)
        setTexteditor({})
    }

    const handleColorSubSelectChange = (e, data) => {

        setColorAddSubList({
            ...addColorSubList,
            [data.name]: e ? e.value : ''
        })
        setColorErrorFields({
            ...colorerrorFields,
            ['sublist' + data.name]: ''
        });
    }

    const handleColorSublistSave = (rec, newlist) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(rec['make'])) {
            formIsValid = false;
            if (newlist)
                errors['sublistmake'] = errorClass;
            else
                errors['editsublistmake'] = errorClass;
        }
        if (_.isEmpty(rec['color']) || _.isEmpty(rec['color'].trim())) {
            formIsValid = false;
            if (newlist)
                errors['sublistcolor'] = errorClass;
            else
                errors['editsublistcolor'] = errorClass;
        }
        setColorErrorFields(errors)
        if (colorsubList.filter(item => item.id !== rec.id).some(item => item.make === rec.make)) {
            Swal.fire(CommonHelper.showLocale(props, 'Make already exists'), '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        let addList = newlist ? [...colorsubList, rec] : [
            ..._.map(colorsubList, function (obj) {
                return obj.id === rec.id ? { ...rec } : { ...obj };
            })];
        setColorSubList(addList)
        setLookup({
            ...lookup,
            ['colors']: addList
        });
        setColorAddSubList({ type: '', value: '', id: uuid() })
        setColorEditSubList(null)
        setColorTexteditor({})
    }

    const handleColorEditSubSelectChange = (e, data) => {

        setColorEditSubList({
            ...editColorSubList,
            [data.name]: e ? e.value : ''
        })
        setColorErrorFields({
            ...colorerrorFields,
            ['editsublist' + data.name]: ''
        });
    }

    const handleColorCancelSubList = () => {
        setColorEditSubList(null)
        setColorTexteditor({})
    }

    const handleColorEditSubList = (rec) => {
        setColorEditSubList(rec)
        setColorTexteditor({
            [rec.id]: true
        })
    }

    const handleColorDeleteSubList = (rec) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                let deleteList = [...colorsubList.filter(item => item.id !== rec.id)];
                setColorSubList(deleteList)
                setLookup({
                    ...lookup,
                    ['colors']: deleteList
                });
            }
        })
    }

    const handleaddonlistSave = (rec, newlist) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(rec['name'])) {
            formIsValid = false;
            if (newlist)
                errors['addonlistname'] = errorClass;
            else
                errors['editaddonlistname'] = errorClass;
        }
        if (_.isEmpty(rec['value'])) {
            formIsValid = false;
            if (newlist)
                errors['addonlistvalue'] = errorClass;
            else
                errors['editaddonlistvalue'] = errorClass;
        }
        setErrorFields(errors)
        if (addonList.filter(item => item.value !== rec.value).some(item => item.name === rec.name)) {
            Swal.fire(CommonHelper.showLocale(props, 'Name already exists'), '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        let addList = newlist ? [...addonList, rec] : [
            ..._.map(addonList, function (obj) {
                return obj.value === rec.value ? { ...rec } : { ...obj };
            })];
        setAddonList(addList)
        setLookup({
            ...lookup,
            ['subList']: addList
        });
        setaddAddonList({ name: '', value: '' })
        setEditAddonList(null)
        setSubTexteditor({})
    }

    const handleCanceladdonList = () => {
        setEditAddonList(null)
        setSubTexteditor({})
    }

    const handleEditAddonList = (rec) => {
        setEditAddonList(rec)
        setSubTexteditor({
            [rec.value]: true
        })
    }
    const handleDeleteaddonList = (rec) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                let deleteList = [...addonList.filter(item => item.value !== rec.value)];
                setAddonList(deleteList)
                setLookup({
                    ...lookup,
                    ['subList']: deleteList
                });
            }
        })
    }

    const onDragEnd = (result) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        const [removed] = addonList.splice(result.source.index, 1);
        addonList.splice(result.destination.index, 0, removed);
        setAddonList(addonList)
        setLookup({
            ...lookup,
            ['subList']: addonList
        });
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging && ("#e6eaf2"),
        ...draggableStyle,
    })

    const handleOnEditaddonChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (name === 'value') {
            setEditAddonList({
                ...editaddonList,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setEditAddonList({
                    ...editaddonList,
                    [name]: newarray1.join(' ')
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setEditAddonList({
                    ...editaddonList,
                    [name]: str
                });
            }
        }
        setErrorFields({
            ...errorFields,
            ['editaddonlist' + name]: ''
        });
    }

    const handleOnAddonChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (name === 'value') {
            setaddAddonList({
                ...addAddonList,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setaddAddonList({
                    ...addAddonList,
                    [name]: newarray1.join(' '),
                    ['value']: _.isEmpty(subtextEditor) ? uuid() : addAddonList.value
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setaddAddonList({
                    ...addAddonList,
                    [name]: str,
                    ['value']: _.isEmpty(subtextEditor) ? uuid() : addAddonList.value
                });
            }
        }
        setErrorFields({
            ...errorFields,
            ['addonlist' + name]: ''
        });
    }

    const handleReactSelectChange = (e) => {
        handleaddonlistSave({ value: uuid(), name: e?.name }, true)
    }

    const handleCreate = (e) => {
        handleaddonlistSave({ value: uuid(), name: e }, true)
    }

    return _.isEmpty(lookup) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName={`modal-dialog-centered ${(() => { return props.activeLookup === 'productAddons' ? 'modal-interest-url' : 'modal-add-lookup' })()}`}
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title> <Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                {
                                    clientLookups.some(a => a.module === 'Product Add-ons' && a.value === props.activeLookup && props.activeLookup !== 'productStatus') ? (
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'Module'} /></label>
                                            <ReactSelect
                                                options={productCategory}
                                                name={"module"}
                                                placeholder={'select module'}
                                                onChange={handlecolorSelectChange}
                                                value={lookup.module}
                                                classNamePrefix={`${lookup.disableDelete ? 'select-readonly' : ''} ${errorFields["module"]} basic-select`}
                                                isDisabled={lookup.disableDelete}
                                            >
                                            </ReactSelect>
                                        </div>
                                    ) : (<></>)
                                }
                                {(() => {
                                    return props.activeLookup === 'productAddons' ? (
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'Category'} /></label>
                                            <ReactSelect
                                                options={props?.allLookups?.['productCategory']?.filter(r => r.module === lookup?.module)?.map(r => { return { label: r.name, value: r.value } })}
                                                name={"category"}
                                                placeholder={'select category'}
                                                onChange={handlecolorSelectChange}
                                                value={lookup.category}
                                                classNamePrefix={`${lookup.disableDelete ? 'select-readonly' : ''} ${errorFields["category"]} basic-select`}
                                                isDisabled={lookup.disableDelete}
                                            >
                                            </ReactSelect>
                                        </div>
                                    ) : (<></>)
                                })()}
                                {(() => {
                                    return props.activeLookup === 'filesCategory' ? (
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'Module'} /></label>
                                            <ReactSelect
                                                options={fileCategory}
                                                name={"module"}
                                                placeholder={'select module'}
                                                onChange={handlecolorSelectChange}
                                                value={lookup.module}
                                                classNamePrefix={`${lookup.disableDelete ? 'select-readonly' : ''} ${errorFields["module"]} basic-select`}
                                                isDisabled={lookup.disableDelete}
                                            >
                                            </ReactSelect>
                                        </div>
                                    ) : (<></>)
                                })()}
                                {(() => {
                                    return props.activeLookup !== 'productAddons' ? (
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'name'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'name'}
                                                className={`form-control ${errorFields["name"]}`}
                                                name="name"
                                                onChange={handleOnChange}
                                                value={lookup.name}
                                                maxLength={150}
                                                disabled={(!Boolean(lookup.disableEdit) || newLookup || props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: lookup.level })) ? false :
                                                    (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: lookup.level }).index) ? true : false}
                                            />
                                        </div>
                                    ) : (<></>)
                                })()}
                                {
                                    props.activeLookup === 'rosters' ? (
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'Code'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'value'}
                                                className={`form-control ${errorFields["value"]}`}
                                                name="value"
                                                onChange={handleOnChange}
                                                value={lookup.value}
                                                maxLength={150}
                                                disabled={(!Boolean(lookup.disableEdit) || newLookup || props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: lookup.level })) ? false :
                                                    (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: lookup.level }).index) ? true : false}
                                            />
                                        </div>) : (<></>)
                                }
                                {
                                    props.activeLookup === 'activityTypes' ? (
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'Icon'} /></label>
                                            <div className={`activity-buttongroup ${lookup.disableDelete === true ? 'btn-disable' : ''}`}>
                                                {
                                                    activityIcons.map((rec, index) => {
                                                        return <a href="#" title={rec.name} className={`${lookup.icon === rec.icon ? 'activity-active' : ''}`} onClick={(e) => { e.preventDefault(); handleiconChange(rec.icon) }}> <i className={`ico icon-${rec.icon}`}></i></a>
                                                    })
                                                }
                                            </div>
                                        </div>) : (<></>)
                                }
                                {
                                    props.activeLookup === 'financeStatus' || props.activeLookup === 'productStatus' || props.activeLookup === 'afterMarketStatus' || props.activeLookup === 'enquiryStatus' || props.activeLookup === 'enquiryLabels' || props.activeLookup === 'checkoutPerformance' ? (
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'Colour'} /></label>
                                            <ReactSelect
                                                options={statusColors}
                                                name={"color"}
                                                placeholder={'select status colour'}
                                                onChange={handlecolorSelectChange}
                                                value={lookup.color}
                                                classNamePrefix={`${lookup.disableDelete ? 'select-readonly' : ''} ${errorFields["color"]} basic-select`}
                                                isDisabled={lookup.disableDelete}
                                            >
                                            </ReactSelect>
                                        </div>
                                    ) : (<></>)
                                }
                                {(() => {
                                    return props.activeLookup === 'stockStatus' ? (
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'Colour'} /></label>
                                            <ReactSelect
                                                options={statusColors}
                                                name={"color"}
                                                placeholder={'select status colour'}
                                                onChange={handlecolorSelectChange}
                                                value={lookup.color}
                                                classNamePrefix={`${lookup.disableDelete ? 'select-readonly' : ''} ${errorFields["color"]} basic-select`}
                                                isDisabled={lookup.disableDelete}
                                            >
                                            </ReactSelect>
                                        </div>
                                    ) : (<></>)
                                })()}
                                {
                                    props.activeLookup === 'acquisitionSource' || props.activeLookup === 'origins' ? (
                                        <div className="form-group col-md-3">
                                            <label > <Translate text={'Colour'} /></label>
                                            <div
                                                style={styles.swatch}
                                                onClick={() => {
                                                    setColorPicker(!colorPicker);
                                                }}
                                            >
                                                <div
                                                    //className='custom-color-picker-form-template'
                                                    style={styles.colorPicker}
                                                />
                                                {(() => {
                                                    return lookup.color ? (
                                                        <div className='float-left'> <a href="#" className="color-picker-closeico" onClick={() => {
                                                            setLookup({
                                                                ...lookup,
                                                                color: null
                                                            });
                                                        }}> <i className="ico icon-remove"></i></a></div>
                                                    ) : (<></>)
                                                })()}
                                            </div>
                                            {colorPicker ? (
                                                <div style={styles.popover}>
                                                    <div
                                                        style={styles.cover}
                                                        onClick={() => {
                                                            setColorPicker(false);
                                                        }}
                                                    />
                                                    <SketchPicker
                                                        color={
                                                            !_.isEmpty(lookup) && lookup.color
                                                                ? lookup.color
                                                                : '#000000'
                                                        }
                                                        onChange={color =>
                                                            setLookup({
                                                                ...lookup,
                                                                color: color.hex
                                                            })
                                                        }
                                                    />
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : (<></>)

                                }
                                {
                                    props.activeLookup === 'servicePackages' ? (
                                        <div className={`form-group col-md-${props.activeLookup === 'productAddons' ? 6 : 12}`}>
                                            <label> <Translate text={'price'} /></label>
                                            <NumberFormat
                                                id={'price'}
                                                decimalScale={2}
                                                allowNegative={false}
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                placeholder={currencySymbol}
                                                className={`form-control ${errorFields["price"]}`}
                                                onValueChange={(e) => {
                                                    onCurrencyChange(e, 'price')
                                                }}
                                                value={lookup.price}
                                            />
                                        </div>
                                    ) : (<></>)
                                }
                                {
                                    props.activeLookup === 'checkoutPerformance' ? (<div className="form-group col-md-12">
                                        <label ><Translate text={'Roster Category'} /></label>
                                        <ReactMultiSelect
                                            options={rosterCategories.map(rec => {
                                                return {
                                                    label: rec.name,
                                                    value: rec.value
                                                }
                                            })}
                                            name={'categories'}
                                            placeholder={`select roster category`}
                                            onChange={(rec) => { handleReactMultiSelectChange(rec, 'categories') }}
                                            value={_.isArray(lookup.categories) ? lookup.categories : []}
                                            classNamePrefix={`${errorFields["categories"]} basic-select`}
                                            removeClearable={false}
                                            //${!newLookup ? 'select-readonly' : ''} 
                                            //isDisabled={!newLookup}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>) : (<></>)
                                }
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'Level'} /></label>
                                    <ReactSelect
                                        options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
                                            levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
                                        name={"level"}
                                        placeholder={'select level'}
                                        onChange={handleSelectChange}
                                        value={lookup.level}
                                        classNamePrefix={`${errorFields["level"]} basic-select`}
                                        removeClearable={true}
                                        isDisabled={lookup.disableDelete}
                                    >
                                    </ReactSelect>
                                </div>
                                {
                                    (!_.isEmpty(lookup.level) && lookup.level !== 'oem') ? (<div className="form-group col-md-12">
                                        <label ><Translate text={lookup.level === 'group' ? 'Group' : lookup.level === 'region' ? 'Region' : 'client'} /></label>
                                        <ReactMultiSelect
                                            options={lookup.level === 'group' ? props.groupOptions : lookup.level === 'region' ? props.regionOptions : props.clientOptions}
                                            name={'levelID'}
                                            placeholder={`select ${lookup.level === 'individual' ? 'client' : lookup.level}`}
                                            onChange={(rec) => { handleReactMultiSelectChange(rec, 'levelID') }}
                                            value={lookup.levelID ? _.isArray(lookup.levelID) ? lookup.levelID : [lookup.levelID] : []}
                                            classNamePrefix={` ${errorFields["levelID"]} basic-select`}
                                            removeClearable={true}
                                            //${!newLookup ? 'select-readonly' : ''} 
                                            //isDisabled={!newLookup}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>) : (<></>)
                                }
                            </div>
                            {(() => {
                                return props.activeLookup === 'productAddons' ? (
                                    <>
                                        <div className="settings-sub-divider">
                                            <h3> <Translate text={`Products`} /></h3>
                                        </div>
                                        <CustomTable
                                            dealersettings={props.dealersettings}
                                            cols={mappingProductCols}
                                            subList={lookup?.subList}
                                            setSubList={(list) => { setLookup({ ...lookup, subList: list }) }}
                                            dragDisabled={true}
                                            createUUID={true}
                                        ></CustomTable>
                                    </>
                                ) : (<></>)
                            })()}
                            {
                                _.find(clientLookups, { 'value': props.activeLookup })?.hideDMSKeys ? (<></>) : _.find(clientLookups, { 'value': props.activeLookup })?.subList ? (
                                    <><div className="settings-sub-divider">
                                        <h3> <Translate text={`${_.find(clientLookups, { 'value': props.activeLookup })?.subListTitle || 'List'}`} /></h3>
                                    </div>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <div className="common-table"
                                            >
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="head-light" width="70%"> <Translate text={'name'} /></th>
                                                            {/* <th scope="col" className="head-light" width="45%">Value</th> */}
                                                            <th scope="col" className="head-light border-right-0" width="20%"> <Translate text={'Actions'} /></th>
                                                        </tr>
                                                        {
                                                            disableEdit ? (<></>) : (
                                                                <tr>
                                                                    <td>
                                                                        <div className="form-group">
                                                                            <InputText
                                                                                id="addonlistname"
                                                                                autoComplete="off"
                                                                                placeholder={'name'}
                                                                                className={`form-control ${errorFields["addonlistname"]}`}
                                                                                name="name"
                                                                                onChange={handleOnAddonChange}
                                                                                value={addAddonList.name}
                                                                                disabled={disableEdit}
                                                                            />
                                                                            {/* <ReactAsyncSelect
                                                                            options={_.uniqBy(provider, 'label')}
                                                                            name={"name"}
                                                                            placeholder={'provider'}
                                                                            search={true}
                                                                            onChange={handleReactSelectChange}
                                                                            value={!_.isEmpty(addAddonList) ? [{ value: addAddonList.value, label: addAddonList.name }] : []}
                                                                            classNamePrefix={`${errorFields["provider"]} basic-select`}
                                                                            defaultOptions={true}
                                                                            onCreate={handleCreate}
                                                                        //clientID={this.props.dealersettings ? this.props.dealersettings.client.id : ''}
                                                                        //groupID={this.selectedGroupID}
                                                                        //enableSharedContact={enableSharedContact}
                                                                        //dealersettings={dealersettings}
                                                                        > 
                                                                        </ReactAsyncSelect>*/}
                                                                        </div></td>
                                                                    <td className="border-right-0">
                                                                        <div className="button-height-fix">
                                                                            <button type="button" className="btn btn-primary" onClick={() => { handleaddonlistSave(addAddonList, true) }}> <Translate text={'add'} /></button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </thead>
                                                    <Droppable droppableId="droppableLostReason">
                                                        {(provided, snapshot) => (
                                                            <tbody
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                            >
                                                                {
                                                                    addonList.length > 0 ?
                                                                        addonList.map((rec, index) => {
                                                                            return <Draggable key={rec.value} draggableId={rec.value} index={index}>
                                                                                {(provided1, snapshot1) => (
                                                                                    <tr
                                                                                        ref={provided1.innerRef}
                                                                                        {...provided1.draggableProps}
                                                                                        {...provided1.dragHandleProps}
                                                                                        style={getItemStyle(
                                                                                            snapshot1.isDragging,
                                                                                            provided1.draggableProps.style
                                                                                        )}
                                                                                        onDoubleClick={(e) => { e.preventDefault(); handleEditAddonList(rec) }}
                                                                                    >
                                                                                        <td style={{ width: '395px' }} >
                                                                                            {
                                                                                                subtextEditor[rec.value] ? (<><InputText
                                                                                                    id="editaddonlistname"
                                                                                                    autoComplete="off"
                                                                                                    placeholder={'name'}
                                                                                                    className={`form-control ${errorFields["editaddonlistname"]}`}
                                                                                                    name="name"
                                                                                                    onChange={handleOnEditaddonChange}
                                                                                                    value={editaddonList.name}
                                                                                                /></>) : (<>{rec.name}</>)
                                                                                            }
                                                                                        </td>
                                                                                        <td className="border-right-0">
                                                                                            <div className="action-fix">
                                                                                                {
                                                                                                    subtextEditor[rec.value] ?
                                                                                                        (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleaddonlistSave(editaddonList, false) }}> <i className="ico icon-check"></i></a></div>
                                                                                                            <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleCanceladdonList(rec) }}> <i className="ico icon-remove"></i></a></div>
                                                                                                        </>) :
                                                                                                        (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditAddonList(rec) }}> <i className="ico icon-edit"></i></a></div>
                                                                                                            <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteaddonList(rec) }}> <i className="ico icon-delete"></i></a></div>
                                                                                                        </>)
                                                                                                }

                                                                                            </div>
                                                                                            <div className="table-edit float-right"> <i className="ico icon-drag"></i></div>
                                                                                        </td>
                                                                                        {provided1.placeholder}
                                                                                    </tr>
                                                                                )}
                                                                            </Draggable>
                                                                        })
                                                                        : (<></>)
                                                                }
                                                                {provided.placeholder}
                                                            </tbody>
                                                        )}
                                                    </Droppable>
                                                </table>
                                            </div>

                                        </DragDropContext></>
                                ) : (
                                    <div className="settings-tab-wrap tabs">
                                        <div className="tabs-wraper">
                                            <nav>
                                                <Tabs defaultActiveKey={props.activeLookup === 'salesType' ? 'colors' : 'dmsKeys'} className="nav-fill">
                                                    {
                                                        props.activeLookup === 'salesType' ? (
                                                            <Tab eventKey="colors" title="Colors">
                                                                <div className="common-table w-100 mt-2">
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col" className="head-light" width="45%"> <Translate text={'make'} /></th>
                                                                                <th scope="col" className="head-light" width="45%"> <Translate text={'Color'} /></th>
                                                                                <th scope="col" className="head-light border-right-0" width="10%"> <Translate text={'Actions'} /></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td><div className="">
                                                                                    <ReactSelect
                                                                                        options={brands}
                                                                                        name={"make"}
                                                                                        placeholder={'select make'}
                                                                                        onChange={handleColorSubSelectChange}
                                                                                        value={addColorSubList.make}
                                                                                        classNamePrefix={`${colorerrorFields["sublistmake"]} cursor-pointer basic-select`}
                                                                                        id="sublistmake"
                                                                                    >
                                                                                    </ReactSelect>
                                                                                </div></td>
                                                                                <td><div style={colorerrorFields['sublistcolor'] ? { border: '1px solid red' } : {}}>
                                                                                    <div
                                                                                        style={styles.swatch}
                                                                                        onClick={() => {
                                                                                            setColorPicker(!colorPicker);
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            //className='custom-color-picker-form-template'
                                                                                            style={{
                                                                                                ...styles.colorPicker,
                                                                                                background: !_.isEmpty(addColorSubList) && addColorSubList.color
                                                                                                    ? addColorSubList.color
                                                                                                    : '#FFFFFF'
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    {colorPicker ? (
                                                                                        <div style={styles.popover}>
                                                                                            <div
                                                                                                style={styles.cover}
                                                                                                onClick={() => {
                                                                                                    setColorPicker(false);
                                                                                                }}
                                                                                            />
                                                                                            <SketchPicker
                                                                                                color={
                                                                                                    !_.isEmpty(addColorSubList) && addColorSubList.color
                                                                                                        ? addColorSubList.color
                                                                                                        : '#000000'
                                                                                                }
                                                                                                onChange={color => {
                                                                                                    setColorAddSubList({
                                                                                                        ...addColorSubList,
                                                                                                        color: color.hex
                                                                                                    })
                                                                                                    setColorErrorFields({
                                                                                                        ...colorerrorFields,
                                                                                                        ['sublistcolor']: null
                                                                                                    })
                                                                                                }
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    ) : null}
                                                                                </div></td>
                                                                                <td className="border-right-0">
                                                                                    <div className="button-height-fix">
                                                                                        <button type="button" className="btn btn-primary" onClick={() => { handleColorSublistSave(addColorSubList, true) }}> <Translate text={'add'} /></button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            {
                                                                                colorsubList.length > 0 ?
                                                                                    colorsubList.map((rec, index) => {
                                                                                        return <tr key={index} onDoubleClick={(e) => { e.preventDefault(); handleEditSubList(rec) }}>
                                                                                            <td>
                                                                                                {
                                                                                                    colortextEditor[rec.id] ? (<> <ReactSelect
                                                                                                        options={brands}
                                                                                                        name={"make"}
                                                                                                        placeholder={'select make'}
                                                                                                        onChange={handleColorEditSubSelectChange}
                                                                                                        value={editColorSubList.make}
                                                                                                        classNamePrefix={`${colorerrorFields["editsublistmake"]} cursor-pointer basic-select`}
                                                                                                        id="editsublistmake"
                                                                                                    >
                                                                                                    </ReactSelect></>) : (<>{_.find(brands, { 'value': rec.make }) ? _.find(brands, { 'value': rec.make }).label : rec.make}</>)
                                                                                                }
                                                                                            </td>
                                                                                            <td>
                                                                                                {
                                                                                                    colortextEditor[rec.id] ? (<>
                                                                                                        <div
                                                                                                            style={styles.swatch}
                                                                                                            onClick={() => {
                                                                                                                setEditColorPicker(!editcolorPicker);
                                                                                                            }}
                                                                                                        >
                                                                                                            <div
                                                                                                                //className='custom-color-picker-form-template'
                                                                                                                style={{
                                                                                                                    ...styles.colorPicker,
                                                                                                                    background: !_.isEmpty(editColorSubList) && editColorSubList.color
                                                                                                                        ? editColorSubList.color
                                                                                                                        : '#FFFFFF'
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                        {editcolorPicker ? (
                                                                                                            <div style={styles.popover}>
                                                                                                                <div
                                                                                                                    style={styles.cover}
                                                                                                                    onClick={() => {
                                                                                                                        setEditColorPicker(false);
                                                                                                                    }}
                                                                                                                />
                                                                                                                <SketchPicker
                                                                                                                    color={
                                                                                                                        !_.isEmpty(editColorSubList) && editColorSubList.color
                                                                                                                            ? editColorSubList.color
                                                                                                                            : '#000000'
                                                                                                                    }
                                                                                                                    onChange={color =>
                                                                                                                        setColorEditSubList({
                                                                                                                            ...editColorSubList,
                                                                                                                            color: color.hex
                                                                                                                        })
                                                                                                                    }
                                                                                                                />
                                                                                                            </div>
                                                                                                        ) : null}
                                                                                                    </>) : (<div className="color-picker-fillwrap">
                                                                                                        <div style={{ background: rec.color }} className="color-picker-fillsub"></div>
                                                                                                    </div>)
                                                                                                }
                                                                                            </td>
                                                                                            <td className="border-right-0">
                                                                                                <div className="action-fix">
                                                                                                    {
                                                                                                        colortextEditor[rec.id] ?
                                                                                                            (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleColorSublistSave(editColorSubList, false) }}> <i className="ico icon-check"></i></a></div>
                                                                                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleColorCancelSubList(rec) }}> <i className="ico icon-remove"></i></a></div>
                                                                                                            </>) :
                                                                                                            (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleColorEditSubList(rec) }}> <i className="ico icon-edit"></i></a></div>
                                                                                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleColorDeleteSubList(rec) }}> <i className="ico icon-delete"></i></a></div>
                                                                                                            </>)
                                                                                                    }

                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    })
                                                                                    : (<></>)
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </Tab>
                                                        ) : (<></>)
                                                    }
                                                    <Tab eventKey="dmsKeys" title="DMS Keys">
                                                        <div className="common-table w-100 mt-2">
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" className="head-light" width="45%"> <Translate text={'name'} /></th>
                                                                        <th scope="col" className="head-light" width="45%"> <Translate text={'Key'} /></th>
                                                                        <th scope="col" className="head-light border-right-0" width="10%"> <Translate text={'Actions'} /></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><div className="">
                                                                            <ReactSelect
                                                                                options={integarions}
                                                                                name={"type"}
                                                                                placeholder={'select name'}
                                                                                onChange={handleonSubSelectChange}
                                                                                value={addSubList.type}
                                                                                classNamePrefix={`${errorFields["sublistname"]} cursor-pointer basic-select`}
                                                                                id="sublisttype"
                                                                            >
                                                                            </ReactSelect>
                                                                        </div></td>
                                                                        <td><div className="">
                                                                            <InputText
                                                                                id="sublistvalue"
                                                                                autoComplete="off"
                                                                                placeholder={'value'}
                                                                                className={`form-control ${errorFields["sublistvalue"]}`}
                                                                                name="value"
                                                                                onChange={handleOnSubChange}
                                                                                value={addSubList.value}
                                                                            />
                                                                        </div></td>
                                                                        <td className="border-right-0">
                                                                            <div className="button-height-fix">
                                                                                <button type="button" className="btn btn-primary" onClick={() => { handleSublistSave(addSubList, true) }}> <Translate text={'add'} /></button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    {
                                                                        subList.length > 0 ?
                                                                            subList.map((rec, index) => {
                                                                                return <tr key={index} onDoubleClick={(e) => { e.preventDefault(); handleEditSubList(rec) }}>
                                                                                    <td>
                                                                                        {
                                                                                            textEditor[rec.id] ? (<> <ReactSelect
                                                                                                options={integarions}
                                                                                                name={"type"}
                                                                                                placeholder={'select name'}
                                                                                                onChange={handleonEditSubSelectChange}
                                                                                                value={editSubList.type}
                                                                                                classNamePrefix={`${errorFields["editsublistname"]} cursor-pointer basic-select`}
                                                                                                id="editsublistname"
                                                                                            >
                                                                                            </ReactSelect></>) : (<>{_.find(integarions, { 'value': rec.type }) ? _.find(integarions, { 'value': rec.type }).label : rec.type}</>)
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            textEditor[rec.id] ? (<><InputText
                                                                                                id="editsublistvalue"
                                                                                                autoComplete="off"
                                                                                                placeholder={'value'}
                                                                                                className={`form-control ${errorFields["editsublistvalue"]}`}
                                                                                                name="value"
                                                                                                onChange={handleOnEditSubChange}
                                                                                                value={editSubList.value}
                                                                                            /></>) : (<>{rec.value}</>)
                                                                                        }
                                                                                    </td>
                                                                                    <td className="border-right-0">
                                                                                        <div className="action-fix">
                                                                                            {
                                                                                                textEditor[rec.id] ?
                                                                                                    (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleSublistSave(editSubList, false) }}> <i className="ico icon-check"></i></a></div>
                                                                                                        <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleCancelSubList(rec) }}> <i className="ico icon-remove"></i></a></div>
                                                                                                    </>) :
                                                                                                    (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditSubList(rec) }}> <i className="ico icon-edit"></i></a></div>
                                                                                                        <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteSubList(rec) }}> <i className="ico icon-delete"></i></a></div>
                                                                                                    </>)
                                                                                            }

                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            })
                                                                            : (<></>)
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Tab>
                                                </Tabs>
                                            </nav>
                                        </div>
                                    </div>


                                )
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <span className={`switch switch-sm float-left mt-2 ${lookup.disableDelete ? 'btn-disable' : (newLookup || props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: lookup.level })) ? '' :
                    (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: lookup.level }).index) ? 'btn-disable' : ''}`}>
                    <InputCheckBox
                        className={`switch`}
                        id={`lookup-active`}
                        name={'active'}
                        checked={lookup.active ? lookup.active : false}
                        onChange={(e) => { handleCheckedChange(e) }} />
                    <label htmlFor={`lookup-active`}><Translate text={'active'} /></label>
                </span>
                {
                    props.activeLookup === 'campaigns' || props.activeLookup === 'enquiryTypes' || props.activeLookup === 'origins' || props.activeLookup === 'leadSource' ? (
                        <span className={`switch switch-sm float-left mt-2 ${lookup.disableDelete ? 'btn-disable' : ''}`}>
                            <InputCheckBox
                                className={`switch`}
                                id={`lookup-autoSelect`}
                                name={'autoSelect'}
                                checked={lookup.autoSelect ? lookup.autoSelect : false}
                                onChange={(e) => { handleCheckedChange(e) }} />
                            <label htmlFor={`lookup-autoSelect`}><Translate text={'Auto Select'} /></label>
                        </span>
                    ) : (<></>)
                }
                {
                    props.activeLookup === 'enquiryStatus' ? (<>
                        <span className={`switch switch-sm float-left mt-2 ${lookup.disableDelete ? 'btn-disable' : ''}`}>
                            <InputCheckBox
                                className={`switch`}
                                id={`lookup-afterSold`}
                                name={'afterSold'}
                                checked={lookup.afterSold ? lookup.afterSold : false}
                                onChange={(e) => { handleCheckedChange(e) }} />
                            <label htmlFor={`lookup-afterSold`}><Translate text={'After Won'} /></label>
                        </span>
                        <span className={`switch switch-sm float-left mt-2 ${lookup.disableDelete ? 'btn-disable' : ''}`}>
                            <InputCheckBox
                                className={`switch`}
                                id={`lookup-afterLost`}
                                name={'afterLost'}
                                checked={lookup.afterLost ? lookup.afterLost : false}
                                onChange={(e) => { handleCheckedChange(e) }} />
                            <label htmlFor={`lookup-afterLost`}><Translate text={'After Lost'} /></label>
                        </span>
                    </>) : (<></>)
                }
                {
                    props.activeLookup === 'activityTypes' ? (
                        <span className={`switch switch-sm float-left mt-2  ${lookup.disableDelete ? 'btn-disable' : ''}`}>
                            <InputCheckBox
                                className={`switch`}
                                id={`lookup-linkVehicle`}
                                name={'linkVehicle'}
                                checked={lookup.linkVehicle ? lookup.linkVehicle : false}
                                onChange={(e) => { handleCheckedChange(e) }} />
                            <label htmlFor={`lookup-linkVehicle`}><Translate text={'linkVehicle'} /></label>
                        </span>
                    ) : (<></>)
                }
                <button type="button" className={`btn btn-primary float-right ml-2`} onClick={(e) => saveLookup(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal >
    );
}

export default AddLookup;