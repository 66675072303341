import React, { useState, useEffect, useContext } from "react";
import images from "../../../../images";
import { Modal } from "react-bootstrap";
import Translate from "../../../../constants/translate";
import { InputCheckBox, PopUpModal, ReactSelect } from "../../../../components";
import Options from "../options";
import _ from 'lodash'
import Swal from "sweetalert2";
import PublishForm from "../options/publishModal";
import CommonHelper from "../../../../services/common";


const GenericIntegration = (props) => {
  const [modalOptionsShow, setOptionsShow] = useState({
    show: false,
    title: "",
    isEdit: false,
  });
  const [options, setOptions] = useState({
    origin: "",
    enqType: "",
  });
  const [moduleToChange, setModuleToChange] = useState({
    module: "",
    checked: null,
  });
  const [publishForm, setPublishForm] = useState({
    show: false,
    title: "",
    feedbackForm: "",
  });
  const [addGenericList, setAddGenericList] = useState({
    enquiryType: "",
    make: "",
    id: "",
    value: "",
    origin: "",
    campaign: "",
    accessKey: "",
    secretKey: "",
    active: false,
    integration: "",
  });
  const [error, setError] = useState({})
  const genericList = props?.integrations?.length ? props.integrations.filter(a => a.generic === true) : [];

  const handleSublistGenericIntegrationSave = async (
    rec,
    newlist,
    id,
    checkDublication = true
  ) => {
    let formIsValid = true;
    let errorClass = "input_error";
    let errors = {};
    let mandatoryFields = ["integration"];
    mandatoryFields.forEach((field) => {
      if (_.isEmpty(rec[field])) {
        formIsValid = false;
        if (newlist) errors[`${field}`] = errorClass;
        else errors[`edit-${field}`] = errorClass;
      }
    });
    setError(errors);
    if (!formIsValid) {
      return;
    }
    let integrationExistance = {};
    if (newlist) {
      integrationExistance = genericList.find((x) => x.name === rec.name);
    } else {
      integrationExistance = genericList.find(
        (x) => x.name === rec.name && x.id !== rec?.id
      );
    }
    if (!_.isEmpty(integrationExistance) && checkDublication) {
      Swal.fire("Integration already exist.", "", "error");
      return;
    } else {
      let integrationRef;
      if (props.selectedLevel.level === "oem") {
        integrationRef = window.firebase
          .firestore()
          .collection(`clientSettings`)
          .doc(props.dealersettings.client.settingsID)
          .collection("integrations");
      } else if (props.selectedLevel.level === "region") {
        integrationRef = window.firebase
          .firestore()
          .collection(`clientSettings`)
          .doc(props.dealersettings.client.settingsID)
          .collection("regions")
          .doc(props.selectedLevel.levelID)
          .collection("integrations");
      } else if (props.selectedLevel.level === "group") {
        integrationRef = window.firebase
          .firestore()
          .collection(`clientSettings`)
          .doc(props.dealersettings.client.settingsID)
          .collection("groups")
          .doc(props.selectedLevel.levelID)
          .collection("integrations");
      } else if (props.selectedLevel.level === "individual") {
        integrationRef = window.firebase
          .firestore()
          .collection(`clients`)
          .doc(props.selectedLevel.levelID)
          .collection("integrations");
      }
      if (!id) {
        id = window.firebase.firestore().collection("clients").doc().id;
      }
      const reqData = {
        inbound: true,
        generic: true,
        enabled: newlist ? true : rec?.enabled,
        active: newlist ? true : rec?.enabled,
        appraisal: rec?.appraisal || null,
        name: rec?.name || "",
        type: rec?.value || "",
        settings: {
          enquiryType: rec?.enquiryType || "",
          origin: rec?.origin || "",
          campaign: rec?.campaign || "",
          accessKey: id,
          secretKey: props.integrationKey || "",
          active: newlist ? true : rec?.enabled,
        },
      };
      integrationRef
        .doc(id)
        .set(reqData, { merge: true })
        .then(async (snapshot) => {
          setAddGenericList({
            enquiryType: "",
            make: "",
            id: "",
            value: "",
            origin: "",
            campaign: "",
            active: false,
            accessKey: "",
            secretKey: "",
          });
        })
        .catch((err) => {
          setAddGenericList({
            enquiryType: "",
            make: "",
            id: "",
            value: "",
            origin: "",
            campaign: "",
            accessKey: "",
            secretKey: "",
            active: false,
          });
        });
    }
  };

  const handleDeleteGenericSubList = (rec) => {
    Swal.fire({
      title: CommonHelper.showLocale(props, "Are you sure?"),
      text: CommonHelper.showLocale(
        props,
        "Do you want to delete"
      ),
      icon: "info",
      showCancelButton: true,
      confirmButtonText: CommonHelper.showLocale(props, "Yes"),
      cancelButtonText: CommonHelper.showLocale(props, "No"),
    }).then((result) => {
      if (result.value) {
        let integrationRef;
        if (props.selectedLevel.level === "oem") {
          integrationRef = window.firebase
            .firestore()
            .collection(`clientSettings`)
            .doc(props.dealersettings.client.settingsID)
            .collection("integrations");
        } else if (props.selectedLevel.level === "region") {
          integrationRef = window.firebase
            .firestore()
            .collection(`clientSettings`)
            .doc(props.dealersettings.client.settingsID)
            .collection("regions")
            .doc(props.selectedLevel.levelID)
            .collection("integrations");
        } else if (props.selectedLevel.level === "group") {
          integrationRef = window.firebase
            .firestore()
            .collection(`clientSettings`)
            .doc(props.dealersettings.client.settingsID)
            .collection("groups")
            .doc(props.selectedLevel.levelID)
            .collection("integrations");
        } else if (props.selectedLevel.level === "individual") {
          integrationRef = window.firebase
            .firestore()
            .collection(`clients`)
            .doc(props.selectedLevel.levelID)
            .collection("integrations");
        }
        integrationRef = integrationRef
          .doc(rec?.id)
          .delete()
          .catch((error) => {
            console.error("Error deleting document:", error);
          });
      }
    });
  };

  const handleEmbedOpen = (e, data) => {
    e.preventDefault();
    if (_.isEmpty(data)) {
      Swal.fire(
        CommonHelper.showLocale("something", "No integration detail found."),
        "",
        "info"
      );
      return;
    }
    let feedbackForm = {
      ...data,
      secretKey: data.settings.secretKey || props.integrationKey,
      accessKey: data.settings.accessKey || data.id,
      value: data.value || data.type
    };
    // OPEN EMBED MODAL
    setPublishForm({
      feedbackForm: feedbackForm,
      show: true,
      title: 'Api Keys',
    });
  };

  return (<>
    <Modal
      id="add-cafe"
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      backdropClassName={props.show ? "overlay-modal active" : ""}
      enforceFocus={false}
      dialogClassName={"modal-dialog modal-dialog-centered modal-configure-integration-generic-api"}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="modal-title">
            <Translate text={props.title} />
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            <div className="form-style w-100">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <div className="configure-integration-logo">
                    <img src={images.genericApi} alt="" />
                  </div>
                </div>
              </div>
              <div className="form-row ">
                <div className="settings-tab-wrap tabs col-md-12">
                  <div className="tabs-wraper">
                    <p
                      style={{
                        margin: "0px auto 15px auto",
                        color: "#0000007a",
                        paddingBottom: "15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      Generic Api Integrations
                    </p>
                    <nav>
                      <div className="common-table w-100 mt-2">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="head-light"
                                width="15%"
                              >
                                {" "}
                                <Translate text={"Integration"} />
                              </th>
                              {/* <th
                                    scope="col"
                                    className="head-light"
                                  // width="33%"
                                  >
                                    {" "}
                                    <Translate text={"Values"} />
                                  </th> */}
                              <th
                                scope="col"
                                className="head-light"
                                width="13%"
                              >
                                {" "}
                                <Translate text={"Enquiry Type"} />
                              </th>
                              <th
                                scope="col"
                                className="head-light"
                                width="15%"
                              >
                                {" "}
                                <Translate text={"Origin"} />
                              </th>
                              <th
                                scope="col"
                                className="head-light"
                                width="15%"
                              >
                                {" "}
                                <Translate text={"Campaign"} />
                              </th>

                              <th
                                scope="col"
                                className="head-light"
                                width="5%"
                              >
                                {" "}
                                <Translate text={"Appraisal"} />
                              </th>
                              <th
                                scope="col"
                                className="head-light border-right-0"
                                width="15%"
                              >
                                {" "}
                                <Translate text={"Actions"} />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="form-group mb-0">
                                  <ReactSelect
                                    options={props.dealersettings?.integrations?.map(
                                      (integration) => {
                                        return {
                                          label: integration.name,
                                          value: integration.value,
                                        };
                                      }
                                    )}
                                    name={"integration"}
                                    placeholder={"select integration"}
                                    onChange={(e, data) => {
                                      if (e?.value) {
                                        setAddGenericList({
                                          ...addGenericList,
                                          integration: e.value,
                                          name: e.label,
                                          value: e.value,
                                        });
                                      } else {
                                        setAddGenericList({
                                          ...addGenericList,
                                          integration: "",
                                          name: "",
                                          value: "",
                                        });
                                      }
                                    }}
                                    value={
                                      addGenericList?.integration || null
                                    }
                                    classNamePrefix={`${error["target-integration"]} basic-select`}
                                  ></ReactSelect>
                                </div>
                              </td>
                              <td>
                                <div className="form-group mb-0">
                                  <ReactSelect
                                    options={props?.dealersettings?.client?.settings?.enquiryTypes?.map(r => { return { label: r.name, value: r.value } })}
                                    name={"enquiryType"}
                                    placeholder={"select enquiry type"}
                                    onChange={(e, data) => {
                                      setAddGenericList({
                                        ...addGenericList,
                                        enquiryType: e.value,
                                      });
                                    }}
                                    value={
                                      addGenericList?.enquiryType || null
                                    }
                                    classNamePrefix={`${error['enquiryType'] ? "input_error" : ""
                                      } basic-select`}
                                  ></ReactSelect>
                                </div>
                              </td>
                              <td>
                                <div className="form-group mb-0">
                                  <ReactSelect
                                    options={props?.dealersettings?.client?.settings?.origins?.map(r => { return { label: r.name, value: r.value } })}
                                    name={"origin"}
                                    placeholder={"select origin"}
                                    onChange={(e, data) => {
                                      setAddGenericList({
                                        ...addGenericList,
                                        origin: e.value,
                                      });
                                    }}
                                    value={addGenericList?.origin || null}
                                    classNamePrefix={`${error["target-origin"]} basic-select`}
                                    id={`target-origin`}
                                  ></ReactSelect>
                                </div>
                              </td>
                              <td>
                                <div className="form-group">
                                  <ReactSelect
                                    options={props?.dealersettings?.client?.settings?.campaigns?.map(r => { return { label: r.name, value: r.value } })}
                                    name={"campaign"}
                                    placeholder={"select campaign"}
                                    onChange={(e, data) => {
                                      setAddGenericList({
                                        ...addGenericList,
                                        campaign: e.value,
                                      });
                                    }}
                                    value={
                                      addGenericList?.campaign || null
                                    }
                                    classNamePrefix={`${error['campaign'] ? "input_error" : ""} basic-select`}
                                  ></ReactSelect>
                                </div>
                              </td>
                              <td>
                                <div className="form-group col-md-6 mt-2">
                                  <span
                                    className={`switch switch-sm float-left`}
                                  >
                                    <InputCheckBox
                                      className={`switch`}
                                      id={`source-appraisal`}
                                      name={"appraisal"}
                                      checked={Boolean(
                                        addGenericList.appraisal
                                      )}
                                      onChange={(e, data) => {
                                        setAddGenericList({
                                          ...addGenericList,
                                          appraisal: e.target.checked
                                        });
                                      }}
                                    />
                                    <label htmlFor={`source-appraisal`}>Appraisal</label>
                                  </span>
                                </div>
                              </td>

                              <td className="border-right-0">
                                <div className="button-height-fix">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                      handleSublistGenericIntegrationSave(
                                        addGenericList,
                                        true,
                                        null,
                                        false
                                      );
                                    }}
                                  >
                                    {" "}
                                    <Translate text={"add"} />
                                  </button>
                                </div>
                              </td>
                            </tr>
                            {genericList.length > 0 ? (
                              genericList.map((rec, index) => {
                                return (
                                  <tr
                                    key={index}
                                    onDoubleClick={(e) => {
                                      e.preventDefault();
                                      // if (_.isEmpty(textEditor))
                                      //   handleEditSubList(rec);
                                    }}
                                  >
                                    <td>
                                      {props.dealersettings?.integrations.filter(
                                        (a) => a.name === rec.name
                                      ).length
                                        ? props.dealersettings?.integrations.filter(
                                          (a) => a.name === rec.name
                                        )[0].name
                                        : rec.name || rec.type || rec.value}
                                    </td>

                                    <td> {_.find(props?.dealersettings?.client?.settings?.enquiryTypes, { value: rec?.settings?.enquiryType })?.name || ''} </td>
                                    <td> {_.find(props?.dealersettings?.client?.settings?.origins, { value: rec?.settings?.origin })?.name || ''} </td>
                                    <td> {_.find(props?.dealersettings?.client?.settings?.campaigns, { value: rec?.settings?.campaign })?.name || ''} </td>

                                    <td> {rec.appraisal ? "True" : "False"}  </td>
                                    <td className="border-right-0">
                                      <div className="d-flex ">
                                        <>
                                          <div className="table-edit float-left mr-2">
                                            {/* <button
				                                	type='button'
			                                		className='btn btn-secondary float-left mr-2'
			                                		style={{ padding: `3px 8px`, fontSize: `11px` }}
			                                		onClick={e =>  handleSublistGenericIntegrationSave(
                                            {...rec, enabled: !rec?.enabled,
                                              active: !rec?.active,},
                                            false,
                                            rec?.id
                                          )}
			                                	>
			                                		{' '}
			                                		<span><Translate text={`${rec?.active ?'Deactive':'Active'}`} /></span>{' '}
			                                	</button> */}
                                            <span
                                              className={`switch switch-sm float-left  mr-2`}
                                            >
                                              <InputCheckBox
                                                className={`switch`}
                                                id={`active-${rec?.id}`}
                                                name={"active"}
                                                checked={Boolean(
                                                  rec?.active
                                                )}
                                                onChange={(e) =>
                                                  handleSublistGenericIntegrationSave(
                                                    {
                                                      ...rec,
                                                      ...rec.settings,
                                                      integration: rec.type,
                                                      name: rec.type,
                                                      value: rec.type,
                                                      enabled: !rec?.enabled,
                                                      active: !rec?.active,
                                                    },
                                                    false,
                                                    rec?.id,
                                                    false
                                                  )
                                                }
                                              />
                                              <label
                                                htmlFor={`active-${rec?.id}`}
                                              ></label>
                                            </span>
                                            <button
                                              type="button"
                                              className="btn btn-secondary float-left mr-2"
                                              style={{
                                                padding: `3px 8px`,
                                                fontSize: `11px`,
                                              }}
                                              onClick={(e) =>
                                                handleEmbedOpen(e, rec)
                                              }
                                            >
                                              {" "}
                                              <i
                                                className="fa fa-link custom-icon-preview"
                                                aria-hidden="true"
                                                style={{
                                                  paddingTop: "0px",
                                                  marginRight: "2px",
                                                }}
                                              ></i>{" "}
                                            </button>
                                          </div>
                                          <div className="table-edit float-left mr-2">
                                            {" "}
                                            <a
                                              href="#"
                                              className="mini-button"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setModuleToChange({
                                                  module: 'emailIntg',
                                                  checked: true,
                                                  data: rec
                                                });
                                                setOptionsShow({
                                                  show: true,
                                                  title: rec.type,
                                                  isEdit: true,
                                                });
                                              }}
                                            >
                                              {" "}
                                              <i className="ico icon-edit"></i>
                                            </a>
                                          </div>

                                          <div className="table-edit float-left mr-2">
                                            {" "}
                                            <a
                                              href="#"
                                              className="mini-button"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleDeleteGenericSubList(
                                                  rec
                                                );
                                              }}
                                            >
                                              {" "}
                                              <i className="ico icon-delete"></i>
                                            </a>
                                          </div>
                                        </>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    <PopUpModal show={modalOptionsShow.show}>
      <Options
        {...props}
        {...modalOptionsShow}
        handleClose={() => { setOptionsShow({ show: false }) }}
        setOptions={setOptions}
        options={options}
        moduleToChange={moduleToChange}
        enableAddons={props.enableAddons}
        hideEmailFields={true}
      ></Options>
    </PopUpModal>
    <PopUpModal show={publishForm.show}>
      <PublishForm
        {...publishForm}
        dealersettings={props.dealersettings}
        handleClose={() => {
          setPublishForm({ show: false, title: "", feedbackForm: null });
        }}
        isFromTableView={true}
      />
    </PopUpModal>
  </>)
};

export default GenericIntegration;
