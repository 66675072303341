import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import _images from '../../images'
import moment from 'moment'
import { FunnelChart, NoDataFunnelChart, ReactSelect } from '../../components'
import { colorPieArray, pipelinestatus } from './viewModel'
import toast from 'toasted-notes'
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import NumberFormat from 'react-number-format';

const CampaignPerformance = (props) => {
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true);
    const inclInboundLeadsinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundLeadsinRpt) ? true : false);
    const inclInboundBuckeinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundBuckeinRpt) ? true : false);
    const calcCurrentStatus = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.calcCurrentStatus) ? true : false);
    const [dealersData, setDealersData] = useState([])
    const [marketingROI, setMarketingROI] = useState({})
    const [initialLoad, setInitialLoad] = useState(true)
    const [marketingCost, setMarketingCost] = useState({})

    const dynamicKpis = (!_.isEmpty(props.dealersettings.client.settings[props.kpi]) ?
        _.map(props.dealersettings.client.settings[props.kpi], function (c) {
            return {
                value: c.value,
                label: `${c.name} ${c.active === true ? '' : '(In Active)'}`
            };
        }) : [])
    const [selectedKpi, setSelectedKpi] = useState(null)

    const currencySymbol = props.dealersettings?.isPorsche === true ? 'USD ' : ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const options = [
        { value: 'newLeads', name: 'New Enquiries', color: '#2b3990', handleClick: 'handleShowEnquiryList' },
        //{ value: 'qualified', name: 'In Process', color: '#4466f2', handleClick: 'handleShowEnquiryList' },
        //{ value: 'appts', name: 'Appointment Scheduled', color: '#8bbbff', handleClick: 'handleShowEnquiryList' },
        //{ value: 'testdrives', name: 'Test Drive Completed', color: '#b6d2f2', handleClick: 'handleShowTestDriveList' },
        { value: 'capLeads', name: 'Leads Created in C@P', color: '#c7d7e5', handleClick: 'handleShowEnquiryList' },
        { value: 'won', name: 'Won C@P', color: '#b0c0cc', handleClick: 'handleShowEnquiryList' },
        { value: 'delivered', name: 'Deliveries C@P', color: '#b0c0cc', handleClick: 'handleShowEnquiryList' },
    ]
    useEffect(() => {
        if (!initialLoad)
            return;

        fetchData();
    }, [initialLoad])


    const fetchData = async () => {
        // console.log('lead by stage')
        let _filter = {
            clientID: CommonHelper.getAllDealers(props.dealersettings),
            //campaignReq: true,
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
            hideTradein: true,
            hideActivities: true,
            hideWalkin: true,
            hideTestdrive: true,
            inclInboundBuckeinRpt,
            inclInboundLeadsinRpt,
        }
        if (props?.dashboardFilter?.daterange?.startDate && props?.dashboardFilter?.daterange?.endDate) {
            _filter.startDate = moment(props.dashboardFilter.daterange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dashboardFilter.daterange.endDate.toDate()).format('YYYY-MM-DD')
        }
        else if (!_.isEmpty(props.dateRange)) {
            _filter.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
        }

        let _searchObject = {
            type: "getSummaryReport",
            params: JSON.stringify(_filter),
        }
        const getData = window.firebase.functions().httpsCallable('generic-getData2');
        const marketingData = await window.firebase.firestore().doc(`/reports/marketing-${props.dealersettings.client.id}`).get();
        if (marketingData.exists) {
            setMarketingROI(marketingData.data())
        }
        const resp = await getData(_searchObject);
        if (resp.data.success) {
            //console.log('resp alternate', resp);
            setDealersData(resp.data.data);
            setData(getFunnelData(resp.data.data, _filter.startDate, _filter.endDate, marketingData.data()));
            //setData(_data);
            setLoader(false)
            setInitialLoad(false)
        } else {
            toast.closeAll();
            // Swal.fire('Something went wrong.');
            setLoader(false)
            setInitialLoad(false)
        }
    }

    const getFunnelData = (data, startDate, endDate, marketingData) => {
        const filterData = data.filter((a) =>
            //!_.isEmpty(a[props.fieldName])
            //&& 
            a.stgv !== 'Archived'
            && (props?.dashboardFilter?.pipelines?.length ? props.dashboardFilter.pipelines.includes(a.plnv) :
                (!_.isEmpty(props.pipeline) ? (props.pipeline.filter(item => !Boolean(item.disableReport) && (inclInboundLeadsinRpt ? true : item.value !== 'LeadsBucket')).some(b => b.value === a.plnv)) : true))
            && (props?.dashboardFilter?.makes?.length ? a.mk && props.dashboardFilter.makes.some(b => b.toLowerCase() === a.mk.toLowerCase()) : true)
            && (props?.dashboardFilter?.salesType?.length ? a.st && props.dashboardFilter.salesType.includes(a.st) :
                (!_.isEmpty(props.dealersettings?.accessSalesType) ? props.dealersettings.accessSalesType.includes(a.st) : true))
            && (props?.dashboardFilter?.user?.length ? a.st && props.dashboardFilter.user.includes(a.uid) : true)
            && (props?.dashboardFilter?.models?.length ? a.ml && props.dashboardFilter.models.some(b => b.toLowerCase() === a.ml.toLowerCase()) : true)
            && ((props?.dashboardFilter?.campaigns?.length && props.kpi !== 'campaigns') ? a.cpgv && props.dashboardFilter.campaigns.includes(a.cpgv) : true)
            && ((props?.dashboardFilter?.origins?.length && props.kpi !== 'origins') ? a.orgv && props.dashboardFilter.origins.includes(a.orgv) : true)
            && ((props?.dashboardFilter?.enquiryTypes?.length && props.kpi !== 'enquiryTypes') ? a.etv && props.dashboardFilter.enquiryTypes.includes(a.etv) : true)
            && ((props?.dashboardFilter?.leadSource?.length && props.kpi !== 'leadSource') ? a.lsv && props.dashboardFilter.leadSource.includes(a.lsv) : true)
            && (selectedKpi ? a[props.fieldName] === selectedKpi : true)
        );

        const dataObj = {
            newLeads: checkDataFilter(filterData.filter((a) => a.col === 'enq'), 'eid', moment(startDate).startOf('day').unix(), moment(endDate).endOf('day').unix()),
            capLeads: checkDataFilter(filterData.filter((a) => a.col === 'enq' && a.tags && JSON.parse(a.tags).some(b => b.type === 'cap')), 'eid', moment(startDate).startOf('day').unix(), moment(endDate).endOf('day').unix()),
            won: checkDataFilter(filterData.filter((a) => a.col === 'enq' && a.tags && JSON.parse(a.tags).some(b => b.type === 'cap')), 'eid', moment(startDate).startOf('day').unix(), moment(endDate).endOf('day').unix(), 'sold', 'wd'),
            delivered: checkDataFilter(filterData.filter((a) => a.col === 'enq' && a.tags && JSON.parse(a.tags).some(b => b.type === 'cap')), 'eid', moment(startDate).startOf('day').unix(), moment(endDate).endOf('day').unix(), 'delivered', 'dd'),
        }

        const totalCost = marketingData?.[`${props.kpi}Cost`]?.[selectedKpi]?.totalCost || 0;
        const costperlead = totalCost > 0 && dataObj?.newLeads?.actual > 0 ? _.round(totalCost / dataObj.newLeads.actual, 2) : 0;
        const costperwon = totalCost > 0 && dataObj?.won?.actual > 0 ? _.round(totalCost / dataObj.won.actual, 2) : 0;
        setMarketingCost({
            totalCost,
            costperlead,
            costperwon
        });
        return options.map((rec, index) => {
            return {
                ...rec,
                ...dataObj?.[rec.value] || {},
                count: (dataObj?.[rec.value]?.actual || 0),
                color: props.dealersettings?.isPorsche === true ? colorPieArray[index % colorPieArray.length] : null
            }
        })

    }

    const checkDataFilter = (_data, fieldName, startDt, endDt, status, dateType, newlead, saleType) => {
        const filterData = status === 'sold' || status === 'delivered' ? _.map(_data.filter((a) =>
            ((status === 'sold') ? (a.sts && (a.sts.toLowerCase() === 'won' || a.sts.toLowerCase() === 'delivered')) : true)
            && ((status === 'delivered') ? (a.sts && a.sts.toLowerCase() === 'delivered') : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        )) : _.map(_data.filter((a) =>
            ((status === 'lost') ? (a.sts && (a.sts.toLowerCase() === 'lost' || a.sts.toLowerCase() === 'prospectlost' || a.sts.toLowerCase() === 'pendinglost')) : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        ));
        return {
            arractual: _.uniqBy(filterData, fieldName).map(r => { return r[fieldName] }),
            actual: status === 'sold' || status === 'delivered' ? _.sumBy(_.uniqBy(filterData, fieldName), 'wc') : _.uniqBy(filterData, fieldName).length
        }
    }

    useEffect(() => {
        if (initialLoad)
            return;
        if ((props?.dashboardFilter?.daterange?.startDate && props?.dashboardFilter?.daterange?.endDate) || _.isEmpty(props.dashboardFilter)) {
            setLoader(true)
            setInitialLoad(true)
        }
        else {
            setData(getFunnelData(dealersData, moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD'), moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD'), marketingROI));
        }

    }, [props.dashboardFilter])

    useEffect(() => {
        if (initialLoad)
            return;
        setData(getFunnelData(dealersData, moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD'), moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD'), marketingROI));
    }, [selectedKpi])

    return (
        <>
            <div className="dashboard-box">
                {
                    loader ? (<div className="dashboard-widget-loader h-100">
                        <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                            <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                        </div>
                    </div>) : (<></>)
                }
                <div className="dashboard-box-header draggable-section">
                    <div className="dashboard-toolbox">
                        <div className="float-left mr-1" style={{ width: '200px' }}>
                            <ReactSelect
                                options={dynamicKpis}
                                name={props.kpi}
                                placeholder={`select ${props.kpi}`}
                                onChange={(e) => {
                                    setLoader(true)
                                    setTimeout(async function () {
                                        setLoader(false);
                                    }, 500)
                                    setSelectedKpi(e?.value || null)
                                }}
                                value={selectedKpi}
                                classNamePrefix={`cursor-pointer basic-select`}
                                removeClearable={false}
                            >
                            </ReactSelect>
                        </div>
                        <a href="#" className="mini-button mt-1" onClick={(e) => {
                            e.preventDefault();
                            setLoader(true)
                            fetchData()
                            //}
                        }}> <i className="ico icon-refresh"></i></a>
                        {/* <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setRefresh(true); }}> <i className="ico icon-refresh"></i></a>
                        <a href="#" className="mini-button ml-1" onClick={(e) => { setFilterPopOver({ show: true, target: e.target }); e.preventDefault(); }}> <i className="ico icon-filter"></i></a> 
                        <a href="#" className="mini-button ml-1"> <i className="ico icon-more"></i></a>*/}
                        {
                            props.fromOEM || props.hideRemoveBtn ? (<></>) : (
                                <a href="#" className="mini-button ml-1 mt-1" onClick={(e) => { e.preventDefault(); props.removeWidget() }}> <i className="ico icon-remove"></i></a>
                            )
                        }
                    </div>
                    <h3>{<Translate text={props.title} />}</h3>
                </div>
                {
                    data.some(item => item.count > 0) ? (<>
                        <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                            <div className="text-center">
                                <FunnelChart
                                    id={`${props.id}-funnel-chart`}
                                    height={'350px'}
                                    data={data}
                                    handleShowEnquiryList={(value, name, startDate, endDate, data) => {
                                        data?.arractual?.length && props[data.handleClick] && props[data.handleClick]({
                                            documentIDs: data?.arractual || []
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        {
                            marketingCost?.totalCost > 0 ? (
                                <div className="widget-cost-container">
                                    <div className="float-left">
                                        <div className="widget-cost-list mr-3"> <span className="dot dot-blue"></span> Cost Per Enquiry <span>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                displayType={'text'}
                                                value={marketingCost?.costperlead?.toFixed(2) || 0}
                                            />
                                        </span>
                                        </div>
                                        <div className="widget-cost-list"> <span className="dot dot-green"></span>Cost Per Lead Won <span>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                displayType={'text'}
                                                value={marketingCost?.costperwon?.toFixed(2) || 0}
                                            />
                                        </span> </div>
                                    </div>
                                    <div className="float-right">
                                        <div className="widget-cost-total ml-3"> Total Spent: <span>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                displayType={'text'}
                                                value={marketingCost?.totalCost || 0}
                                            />
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            ) : (<></>)
                        }
                    </>) : (
                        <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                            <div className="text-center">
                                <NoDataFunnelChart
                                    id={`${props.id}-funnel-chart`}
                                    height={'350px'}
                                />
                            </div>

                        </div>
                    )
                }

            </div>
        </>
    );
}
export default CampaignPerformance;