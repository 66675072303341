/** LIBRARIES */
import React, { useState, useEffect } from 'react'
import "../../../styles/service-preview.scss";
import _ from 'lodash'
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'

/** COMPONENTS */
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import { LoaderOverlay } from '../../../components'
import Translate from '../../../constants/translate';
import { recommPartQuotes, recommendationChoice, serviceStatus, service_type } from '../../../services/enum';
import { partQuotes } from '../viewModel'
//#region  ASSIGN VARIABLES

const engineTypes = [
  { active: true, value: 'Petrol', label: 'Petrol' },
  { active: true, value: 'Diesel', label: 'Diesel' },
  { active: true, value: 'Electric', label: 'Electric' },
  { active: true, value: 'Hybrid', label: 'Hybrid' }
]
//#endregion

const ServicePreview = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [servieData, setServieData] = useState({})
  const [isPDF, setPDF] = useState(false)
  const [fullCost, setFullCost] = useState({
    total: 0,
    totalDiscount: 0,
    labourCost: 0,
    partsCost: 0,
    packagesCost: 0,
    servicePartsCost: 0,
    taxCost: 0,
    subTotal: 0,
    actualDiscount: 0,
    actualDiscountRecomm: 0,
    actualDiscountPack: 0,
    actualTotalCost: 0,
    actualSubTotal: 0
  })

  const {
    documentID, clientID, contactID, displayID,
    pipeline, stage, appointmentType, serviceAdvisor,
    //technician, 
    roNumber, status, brakeNotes, batteryNotes, tyreNotes,

    checkInDate, completionDate, appointmentDate, slot, timeOfDay,
    stageDate, stageHistory,
    isServicePackage, isContact, isVehicle, isInspection, isChecklist, isBbtChecklist, isFiles, isFav,
    totalServiceCost,
    images, videos, marks, checklist,
    servicePackages, teammates, mentions, teams, notes,
    owner, checkInBy, addedBy, modifiedBy, addedDate, modifiedDate, modifiedFrom,
    isDelete, spokeTo, ownerResponse,
    jobAllocated, jobConfirmed, clientContactDate,
    discountType, discount,
    partsConfirmed, activities,
    contact, vehicle, client, users, recommendations,
    taxType, taxPercentage,
    serviceType, internalTaxExemption,
    disclaimer, userSign
  } = servieData;

  const _isTaxIncluded = (serviceType !== service_type.internal || (serviceType === service_type.internal && !internalTaxExemption)) && taxPercentage && taxType ? true : false;
  const _dispalyTax = _isTaxIncluded ? `(${taxType} Incl.)` : `(${taxType ? taxType : 'Tax'} Excl.)`

  const _jobStatus = status && status === serviceStatus.OWNERRESPONDED ? ownerResponse : status;

  const currencySymbol = ((!_.isEmpty(servieData) &&
    !_.isEmpty(servieData.client) &&
    !_.isEmpty(servieData.client.currency)) ? servieData.client.currency.symbol + ' ' : '$ ');

  const _settings = ((!_.isEmpty(servieData) &&
    !_.isEmpty(servieData.currentSettings) &&
    !_.isEmpty(servieData.currentSettings.services)) ? servieData.currentSettings.services : null);

  const recommendationName = servieData?.client?.clientSettings?.service?.recommendationName;

  const serviceAllStatus = Object.assign([], servieData?.currentSettings?.services?.serviceStatus)
  let _objCurrentStatus = _jobStatus && serviceAllStatus.filter(e => e.value === _jobStatus)[0]


  const [firestoreDB, setFirestoreDB] = useState()
  useEffect(() => {
    window.firebase.firestore().doc(`serviceJobs/${props.serviceid}`).get()
      .then(doc => {
        if (doc.exists) {
          setFirestoreDB(window.firebase)
        }
        else {
          setFirestoreDB(window.firebase2)
        }
      })
  }, [])

  useEffect(() => {
    if (_.isEmpty(firestoreDB))
      return
    //console.log('ServicePreview', props.serviceid);
    if (props.serviceid) {
      loadServieData();
    }
    return () => {
      window.refCollectionData && window.refCollectionData.unsubscribe()
    }
  }, [firestoreDB])

  const loadServieData = async () => {


    window.refCollectionData = docData(firestoreDB.firestore().doc(`serviceJobs/${props.serviceid}`))
      .pipe(
        switchMap(tradeIns => {
          //console.log(tradeIns);
          let _contactID = tradeIns.contactID ? tradeIns.contactID : tradeIns?.contact?.documentID
          const serviceIds = [props.serviceid];
          const enqIds = _contactID ? [_contactID] : [];
          const clientIds = [tradeIns.clientID];
          return combineLatest(
            of(tradeIns),
            combineLatest(
              enqIds.length > 0 ? enqIds.map(contactID =>
                collectionData(firestoreDB.firestore().collection('contactDetails').where('documentID', '==', contactID)).pipe(
                  map(enquirySnapshot => enquirySnapshot[0] ? enquirySnapshot[0] : {})
                )
              ) : of([])
            ),
            combineLatest(
              serviceIds.length > 0 ? serviceIds.map(serviceID =>
                collectionData(firestoreDB.firestore().collection(`serviceJobs/${serviceID}/recommendations`)).pipe(
                  map(recommendationSnapshot => recommendationSnapshot)
                )
              ) : of([])
            ),
            combineLatest(
              serviceIds.length > 0 ? serviceIds.map(serviceID =>
                collectionData(firestoreDB.firestore().collection(`serviceJobs/${serviceID}/servicePackages`)).pipe(
                  map(servicePackagesSnapshot => servicePackagesSnapshot)
                )
              ) : of([])
            ),
            combineLatest(
              serviceIds.length > 0 ? serviceIds.map(serviceID =>
                collectionData(firestoreDB.firestore().collection(`activities`)
                  .where('serviceID', '==', serviceID)
                  .where('type', 'in', ['servicelog'])
                  .where('isDeleted', '==', false)
                ).pipe(
                  map(activitiesSnapshot => activitiesSnapshot)
                )
              ) : of([])
            ),
            combineLatest(
              clientIds.length > 0 ? clientIds.map(clientID =>
                collectionData(window.firebase.firestore().collection('clients').where('documentID', '==', clientID)).pipe(
                  map(clientSnapshot => clientSnapshot[0] ? clientSnapshot[0] : {})
                )
              ) : of([])
            ),
            combineLatest(
              clientIds.length > 0 ? clientIds.map(clientID =>
                docData(window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}`)).pipe(
                  map(clientSettingsSnapshot => clientSettingsSnapshot)
                )
              ) : of([])
            ),
            combineLatest(
              clientIds.length > 0 ? clientIds.map(clientID =>
                collectionData(window.firebase.firestore().collection('users').where('arrayClientID', 'array-contains', clientID)).pipe(
                  map(usersSnapshot => usersSnapshot)
                )
              ) : of([])
            ),
          )
        }),
        map(([tradeIns, enquirySnapshot, recommendationSnapshot, servicePackagesSnapshot, activitiesSnapshot,
          clientSnapshot, clientSettingsSnapshot, usersSnapshot,]) => {
          let _cID = tradeIns.contactID ? tradeIns.contactID : tradeIns?.contact?.documentID
          let activities = Object.assign([], activitiesSnapshot[0])
          let allActivities = _.uniqBy(activities.filter(a => a.type === 'servicelog' ? ['note'].includes(a.subType) : true), 'documentID');
          return {
            ...tradeIns,
            contact: _cID ? enquirySnapshot.find(a => a.documentID === _cID) : tradeIns.contact,
            client: clientSnapshot.find(a => a.documentID === tradeIns.clientID),
            users: usersSnapshot[0],
            activities: allActivities.filter(a => !_.isEmpty(a.notes)),
            recommendations: recommendationSnapshot[0],
            servicePackages: servicePackagesSnapshot[0],
            currentSettings: clientSettingsSnapshot[0]
          }

        })
      )
      .subscribe(_tradeIns => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let backURL = params.get('isView');

        setPDF(backURL ? false : true);

        if (!_.isEmpty(_tradeIns)) {
          setServieData(_tradeIns);
          getTotalServiceCost(_tradeIns);
          setIsLoading(false);
        }
        window.refCollectionData && window.refCollectionData.unsubscribe()
        //console.log('ServicePreview_tradeIns', _tradeIns);

      });

  }


  //region FOR INSPECTION IMAGES
  const getInspection = (id, _marks) => {
    const canvas = document.createElement("canvas");
    canvas.width = 395;
    canvas.height = 600;
    // var canvas = document.getElementById("canvas");
    var context = canvas.getContext('2d');

    var img = new Image();
    if (client?.category === 'Motorcycle')
      img.src = require("../../../images/inspection-motorcycle.jpg");
    else
      img.src = require("../../../images/inspection.jpg");
    //img.src = require(`../../../images/${CommonHelper.getVehicleIcons(client?.category, 'inspection')}.jpg`);
    img.onload = async function (e) {
      context.drawImage(img, 0, 0, 395, 600);

      if (!_.isEmpty(_marks)) {
        await asyncForEach(_marks, async (mark) => {
          let imgURL = require(`../../../images/${mark.markType}.png`);
          await loadImage(imgURL).then(img2 => {
            context.drawImage(img2, mark.dx, mark.dy, 25, 25);
          });
        });
      }

      var dataURL = canvas.toDataURL();
      document.getElementById(id).src = dataURL;
      //console.log('imageToBase64:', dataURL)
      return dataURL;
    }
  }

  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  // It's better to use async image loading.
  const loadImage = url => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => reject(new Error(`load ${url} fail`));
      img.src = url;
    });
  };
  //#endregion


  const getTotalServiceCost = (service) => {
    const { recommendations, servicePackages } = service;
    let _fullCost = CommonHelper.getTotalServiceCost(service, recommendations, servicePackages);
    setFullCost(_fullCost);
  }

  const _recommendationList = !_.isEmpty(recommendations) && recommendations.filter(item => item.choice === recommendationChoice.Required)


  return (
    <>

      {
        isLoading
          ?
          <>
            <LoaderOverlay active={true} text='Fetching details...' />
          </>
          :
          <>
            <div className="quotation-wrap report-data-load">
              <div className="print-ico">
                <a href="#" onClick={() => window.print()}> <img src={_images.print} width="19" height="18" /> <span><Translate text={'Print'} /></span></a>
              </div>
              <div className="wrapper">
                <div className="print-wrap">
                  <div className="header">
                    <div className="headerwrap-left">
                      <div className="header-left">
                        {
                          client && client.logoURL
                            ?
                            <div className="logo"> <img alt='' src={client.logoURL ? client.logoURL : ''} height="60" /></div>
                            :
                            <>
                              <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                            </>
                        }
                      </div>
                    </div>

                    <div className="headerwrap-right">
                      <div className='header-right'>
                        {' '}
                        {client.name} <br />
                        <span>
                          {' '}
                          {client.address ? client.address : ''}
                          {client.phone ? (
                            <>
                              <br />
                              {`Phone: ${client.phone}`}
                            </>
                          ) : <></>}
                          {client.email || client.website ? (
                            <>
                              <br />
                              {client.email ? client.email : ''}{client.website ? ` | ${client.website}` : ''}
                            </>
                          ) : <></>}
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="clear"></div>

                  <div className="middle">
                    <div className="title-head"><Translate text={_.trim(recommendationName) ? recommendationName : 'Service Recommendation'} upperCase={true} /></div>
                    <div className="table-wraper">
                      <div className="table-details">
                        <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                          <tbody>
                            <tr>
                              <td width="25%"><div className="grid-one"> <span><Translate text={'serviceAdvisor'} /></span> {(!_.isEmpty(users) && serviceAdvisor) ? CommonHelper.getUserNamebyDocumentId(users, serviceAdvisor) : '--'} </div></td>
                              <td width="25%"><div className="grid-one"> <span><Translate text={'roNumber'} /></span>{roNumber ? roNumber : '--'} </div></td>
                              <td width="25%"><div className="grid-one"> <span><Translate text={'Job Number'} /></span> {displayID ? displayID : '--'} </div></td>
                              <td width="25%"><div className="grid-one"> <span><Translate text={'appointmentType'} /></span> {appointmentType ? CommonHelper.getNameByValue(_settings && _settings.appointmentTypes, appointmentType, '--') : '--'} </div></td>
                            </tr>

                            <tr>
                              <td width="25%"><div className="grid-one"> <span> <Translate text={'appointmentDate'} /></span> {appointmentDate ? moment(appointmentDate).format('DD MMM YYYY h:mm A') : '--'} </div></td>
                              <td width="25%"><div className="grid-one"> <span><Translate text={'slot'} /></span>{
                                !_.isEmpty(slot)
                                  ?
                                  <>
                                    <OverlayTrigger
                                      placement='bottom'
                                      overlay={<Tooltip>{slot.from}{'-'}{slot.to}</Tooltip>}
                                    >
                                      <div style={{ color: `${slot.color ? slot.color : '#f47815 '}` }}>
                                        <i className="ico icon-service-timer"></i> {slot.name}
                                        {
                                          slot.isExpress ? <div className="express-inline-tag"><div className="badge red-ribbon ml-2 express-fix"><Translate text={'express'} upperCase={true} /></div></div> : <></>
                                        }
                                      </div>
                                    </OverlayTrigger>
                                  </>

                                  :
                                  <></>
                              }
                              </div></td>
                              <td width="25%"><div className="grid-one"> <span><Translate text={'completionDate'} /></span>{completionDate ? moment.unix(completionDate.seconds).format('DD MMM YYYY h:mm A') : '--'} </div></td>
                              <td width="25%"><div className="grid-one"> <span><Translate text={'addedDate'} /></span>{addedDate ? moment.unix(addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</div></td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="table-wraper">
                      <div className="table-head table-head-fix"><Translate text={'vehicleInformation'} upperCase={true} />


                      </div>
                      <div className="table-details">
                        <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                          <tbody>
                            <tr>
                              <td width="25%"><div className="grid-one"> <span><Translate text={'vinNo'} upperCase={true} /></span> {vehicle && vehicle.vinNo ? vehicle.vinNo : '--'} </div></td>
                              <td width="25%"><div className="grid-one"> <span><Translate text={'rego'} upperCase={true} /></span>{vehicle && vehicle.rego ? vehicle.rego : (vehicle && vehicle.regNo ? vehicle.regNo : '--')} </div></td>
                              <td width="25%"><div className="grid-one"> <span><Translate text={'make'} upperCase={true} /> </span> {vehicle && vehicle.make ? vehicle.make : '--'}  </div></td>
                              <td width="25%"><div className="grid-one"> <span> <Translate text={'model'} upperCase={true} /> </span>{vehicle && vehicle.model ? vehicle.model : '--'}</div></td>
                            </tr>

                            <tr>
                              <td width="25%"><div className="grid-one"> <span><Translate text={'year'} upperCase={true} /> </span> {vehicle && vehicle.year ? vehicle.year : '--'}</div></td>
                              <td width="25%"><div className="grid-one"> <span><Translate text={'mileage'} upperCase={true} /> (Km)</span>{vehicle && vehicle.odometer ? vehicle.odometer : '--'} </div></td>
                              <td width="25%">&nbsp;</td>
                              <td width="25%">&nbsp; </td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>




                    <div className="table-wraper">
                      <div className="table-head table-head-fix">{'CUSTOMER INFORMATION'} {((contact && contact.displayID) ? '(' + contact.displayID + ')' : '')}&nbsp;

                        <div className="table-head-right">
                          {
                            _jobStatus ?
                              <>
                                {
                                  !_.isEmpty(_objCurrentStatus)
                                    ?
                                    <>
                                      <div
                                        className={`mrg-fixstatus badge-preview-empty`} style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}>
                                        <Translate
                                          text={_objCurrentStatus.name}
                                          upperCase={true}
                                        />

                                      </div>
                                    </>
                                    :
                                    <>
                                      <div className={`mrg-fixstatus badge-preview-${_jobStatus.toLowerCase()}`}>{_jobStatus.split(/(?=[A-Z])/).join(' ').toUpperCase()}</div>
                                    </>
                                }

                              </>

                              :
                              <></>
                          }

                        </div>
                      </div>
                      <div className="table-details">
                        <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                          <tbody>
                            <tr>
                              <td width="25%"><div className="grid-one"> <span><Translate text={'name'} /></span> {CommonHelper.displayFullContact(null, contact, '--')} </div></td>
                              <td width="25%"><div className="grid-one"> <span><Translate text={'phone'} /></span>{contact && contact.phone ?
                                CommonHelper.phoneFormat(contact.phoneCode, contact.phone, { client: client })
                                : '--'} </div></td>
                              <td width="25%"><div className="grid-one"> <span><Translate text={'email'} /></span> {contact && contact.email ? contact.email : '--'} </div></td>
                              <td width="25%"><div className="grid-one"> <span><Translate text={'licenseNo'} /></span>{contact && contact.licenseNo ? contact.licenseNo : '--'} </div></td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>



                    {/* {
                      !_.isEmpty(notes)
                        ?
                        <>
                          <div className="right-notes">
                            <h4>Service Notes</h4>
                            {
                              _.orderBy(notes, ['addedDate'], ['desc']).map((noteData, index) => {
                                return <div key={index} className="notes notes-item text-area-space ">
                                  {noteData.note}
                                  <div className="notes-item-sub">
                                    {CommonHelper.getUserNamebyId(users, noteData.addedBy)}
                                    <span>{noteData.addedDate ? moment.unix(noteData.addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</span>
                                  </div>
                                </div>
                              })
                            }
                          </div>
                        </>
                        :
                        <></>
                    } */}

                    {
                      (!_.isEmpty(servicePackages))
                        ?
                        <>
                          <div className="partslist-main-head"><Translate text={'Service Packages'} upperCase={true} /></div>
                          <div className="service-section-page-content form-style">
                            {
                              servicePackages.map((_serviceData, index) => {
                                return <div key={index} className="partslist-loop-wrapper" >
                                  <>
                                    {(() => {

                                      let _packCosts = CommonHelper.getTotalPackageCost(servieData, _serviceData);
                                      const _isDiscountAppliedPack = _serviceData.discount && _serviceData.discountType ? true : false;
                                      return <>

                                        <div className="partslist-package-table">
                                          <div className="partslist-loop-head"> <strong> {_serviceData.name ? _serviceData.name : '--'} </strong>

                                          </div>
                                          <table className="table-loop table-sub-fix">
                                            <thead>
                                              <tr>
                                                <th width="70%"><Translate text={'Package Name'} /></th>
                                                <th width="15%"> <Translate text={'durationHRS'} /> </th>
                                                <th width="15%"> <Translate text={'cost'} /> </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>{_serviceData.name ? _serviceData.name : '--'}  </td>
                                                <td>{_serviceData.description ? _serviceData.description : '--'}  </td>
                                                <td>{_serviceData.price ?
                                                  <NumberFormat value={_serviceData.price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} />
                                                  : ''}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          {
                                            _isDiscountAppliedPack
                                              ?
                                              <div className="reporttotal-cost">
                                                <span style={{ fontSize: '11px' }}><Translate text={'discount'} upperCase={true} />:&nbsp;
                                                  <span className="discount-cost">
                                                    <NumberFormat value={_packCosts.actualDiscount} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={`${currencySymbol}-`} />
                                                  </span>
                                                </span>

                                                <Translate text={'Sub Total'} />:&nbsp;
                                                <NumberFormat value={_packCosts.subtotalAfterDiscount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} />

                                              </div>
                                              :
                                              <></>
                                          }
                                          {
                                            _serviceData.reason
                                              ?
                                              <div className="ovr-reason "><span><Translate text={'Reason'} />: </span>
                                                <p>{_serviceData.reason}</p>
                                              </div>
                                              :
                                              <></>
                                          }
                                        </div>
                                      </>
                                    })()}
                                  </>

                                </div>
                              })
                            }


                            {
                              servieData && !_.isEmpty(servieData.parts)
                                ?
                                <>
                                  <div className="partslist-loop-wrapper">
                                    <div className="partslist-package-table">
                                      <div className="partslist-loop-head"> <strong> {'Service Parts'} </strong>

                                      </div>
                                      <table className="table-loop table-sub-fix">
                                        <thead>
                                          <tr>
                                            <th width="15%"><Translate text={'partName'} /></th>
                                            <th width="10%"><Translate text={'partNumber'} /></th>
                                            <th width="10%"><Translate text={'quote'} /></th>
                                            <th width="10%"><Translate text={'partStatus'} /></th>
                                            <th width="25%"><Translate text={'comments'} /></th>
                                            <th width="10%"><Translate text={'quantity'} /></th>
                                            <th width="10%"><Translate text={'Unit Cost'} /> </th>
                                            <th width="10%"><Translate text={'Part Cost'} /> </th>

                                          </tr>
                                        </thead>
                                        <tbody>
                                          {
                                            servieData.parts.map((_part, index1) => {
                                              return <tr key={index1} className={[recommPartQuotes.warranty, recommPartQuotes.includedInPackage].includes(_part.quote) ? 'font-italic' : ''}>

                                                <td>{_part.partName ? _part.partName : '--'}  </td>
                                                <td>{_part.partNumber ? _part.partNumber : '--'}  </td>
                                                <td>{_part.quote ? CommonHelper.getLabelByValue(partQuotes, _part.quote, '--') : '--'}  </td>
                                                <td>
                                                  {_part.partStatus ? CommonHelper.getNameByValue(_settings && _settings.partStatus, _part.partStatus, '--') : '--'}
                                                </td>
                                                <td>{_part.comments ? _part.comments : '--'}  </td>
                                                <td>{_part.quantity ? _part.quantity : '--'}  </td>
                                                <td>{_part.partCost ? <NumberFormat value={_part.partCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /> : '--'} </td>

                                                <td>{_part.totalCost ? <strong><NumberFormat value={([recommPartQuotes.warranty, recommPartQuotes.includedInPackage].includes(_part.quote)) ? 0 : _part.totalCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></strong> : '--'} </td>

                                              </tr>
                                            })
                                          }
                                          <tr>
                                            <td colSpan="8">
                                              <div className="ovr-cost"><Translate text={'Parts Cost'} /> :&nbsp;
                                                <NumberFormat value={fullCost.servicePartsCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} />
                                              </div>

                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </>
                                :
                                <></>

                            }


                          </div>


                        </>
                        :
                        <></>
                    }


                    {
                      (!_.isEmpty(_recommendationList))
                        ?
                        <>
                          <div className="partslist-main-head"><Translate text={_.trim(recommendationName) ? recommendationName : 'Service Recommendations'} upperCase={true} /></div>
                          {
                            _.orderBy(_recommendationList, ["addedDate"], ["asc"]).map((_recommData, index) => {
                              return <div key={index} className="partslist-loop-wrapper">
                                <>
                                  {(() => {

                                    let _recommCosts = CommonHelper.getTotalRecommCost(servieData, _recommData);

                                    const _isDiscountAppliedRecomm = _recommData.detail && _recommData.detail.discount && _recommData.detail.discountType ? true : false;

                                    return <>
                                      <div className="partslist-header-table">
                                        <div className="partslist-loop-head">
                                          <strong> {_recommData.name}</strong>
                                          <div className="partslist-loop-todo"><Translate text={'oKToDo'} />
                                            {
                                              !_.isEmpty(_recommData.detail)
                                                ?
                                                <img src={_recommData.detail.oKToDo ? (_recommData.detail.totalLabourCost ? _images.tickcircular : _images.todoyes) : (_recommData.detail.oKToDo === false ? _images.closecircular : _images.todoempty)} alt="" width="20" />
                                                :
                                                <img src={_images.todoempty} alt="" width="20" />
                                            }
                                          </div>

                                          {
                                            _recommData?.detail?.oKToDo && _recommData?.detail?.recomStatus && (<div className="recom-list-current-status">
                                              <div title="Recommendation Status" className={`mrg-fixstatus badge-preview-${_recommData.detail.recomStatus.toLowerCase()} ml-2`}>
                                                <Translate
                                                  text={_recommData.detail.recomStatus.split(/(?=[A-Z])/).join(' ').toLowerCase()}
                                                  upperCase={true}
                                                />
                                              </div>
                                            </div>)
                                          }

                                        </div>

                                        <table className="table-loop table-sub-fix">
                                          <thead>
                                            <tr>
                                              <th width="10%"><Translate text={'option'} /></th>
                                              <th width="60%"><Translate text={'faultReports'} /></th>
                                              <th width="10%"><Translate text={'status'} /></th>
                                              <th width="10%"><Translate text={'oilMiscSub'} /></th>
                                              <th width="10%"><Translate text={'Repair Cost'} /></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>
                                                {
                                                  !_.isEmpty(_recommData.detail) && !_.isEmpty(_recommData.detail.option)
                                                    ?
                                                    <div className="badge-white">{_recommData.detail.option.charAt(0).toUpperCase()}</div>
                                                    :
                                                    <></>
                                                }
                                              </td>
                                              <td>{
                                                !_.isEmpty(_recommData.detail) && !_.isEmpty(_recommData.detail.faultReports)
                                                  ?
                                                  _recommData.detail.faultReports
                                                  :
                                                  '--'
                                              }</td>
                                              <td>
                                                {
                                                  !_.isEmpty(_recommData.detail) && !_.isEmpty(_recommData.detail.status)
                                                    ?
                                                    <div className={`${_recommData.detail.status === 'urgent' ? 'urgent' : 'badge-white'}`}>{_recommData.detail.status.charAt(0).toUpperCase()}</div>
                                                    :
                                                    <></>
                                                }
                                              </td>
                                              <td>{
                                                !_.isEmpty(_recommData.detail) && !_.isEmpty(_recommData.detail.oilMiscSub)
                                                  ?
                                                  _recommData.detail.oilMiscSub
                                                  :
                                                  '--'
                                              }</td>
                                              <td><NumberFormat value={_recommCosts.labourCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                                            </tr>



                                          </tbody>
                                        </table>
                                      </div>

                                      <div className="partslist-middle-table">

                                        {
                                          _recommData.detail && _recommData.detail.parts
                                            ?
                                            <>
                                              <table className="table-loop table-sub-fix">
                                                <thead>
                                                  <tr>
                                                    <th width="15%"><Translate text={'partName'} /></th>
                                                    <th width="10%"><Translate text={'partNumber'} /></th>
                                                    <th width="10%"><Translate text={'quote'} /></th>
                                                    <th width="10%"><Translate text={'partStatus'} /></th>
                                                    <th width="25%"><Translate text={'comments'} /></th>
                                                    <th width="10%"><Translate text={'quantity'} /></th>
                                                    <th width="10%"><Translate text={'Unit Cost'} /> </th>
                                                    <th width="10%"><Translate text={'Part Cost'} /> </th>

                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {
                                                    _recommData.detail.parts.map((_part, index1) => {
                                                      return <tr key={index1} className={[recommPartQuotes.warranty, recommPartQuotes.includedInPackage].includes(_part.quote) ? 'font-italic' : ''}>

                                                        <td>{_part.partName ? _part.partName : '--'}  </td>
                                                        <td>{_part.partNumber ? _part.partNumber : '--'}  </td>
                                                        <td>{_part.quote ? CommonHelper.getLabelByValue(partQuotes, _part.quote, '--') : '--'}  </td>
                                                        <td>
                                                          {_part.partStatus ? CommonHelper.getNameByValue(_settings && _settings.partStatus, _part.partStatus, '--') : '--'}
                                                        </td>
                                                        <td>{_part.comments ? _part.comments : '--'}  </td>
                                                        <td>{_part.quantity ? _part.quantity : '--'}  </td>
                                                        <td>{_part.partCost ? <NumberFormat value={_part.partCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /> : '--'} </td>

                                                        <td>{_part.totalCost ? <strong><NumberFormat value={([recommPartQuotes.warranty, recommPartQuotes.includedInPackage].includes(_part.quote)) ? 0 : _part.totalCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></strong> : '--'} </td>

                                                      </tr>
                                                    })
                                                  }
                                                  <tr>
                                                    <td colSpan="8">
                                                      <div className="ovr-cost"><Translate text={'Parts Cost'} /> :&nbsp;
                                                        <NumberFormat value={_recommCosts.partsCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} />
                                                      </div>

                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </>
                                            :
                                            <></>

                                        }

                                        <div className="reporttotal-cost">
                                          {
                                            _isDiscountAppliedRecomm
                                              ?
                                              <><span style={{ fontSize: '11px' }}><Translate text={'discount'} upperCase={true} />:&nbsp;
                                                <span className="discount-cost">
                                                  <NumberFormat value={_recommCosts.actualDiscount} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={`${currencySymbol}-`} />
                                                </span>
                                              </span></>
                                              :
                                              <></>
                                          }

                                          <Translate text={'Sub Total'} />:&nbsp;
                                          <NumberFormat value={_recommCosts.subtotalAfterDiscount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} />

                                        </div>
                                        {
                                          !_.isEmpty(_recommData.detail) && !_.isEmpty(_recommData.detail.reason)
                                            ?
                                            <div className="ovr-reason "><span><Translate text={'Reason'} />: </span><p>{_recommData.detail.reason}</p></div>
                                            :
                                            <></>
                                        }

                                      </div>
                                    </>
                                  })()}
                                </>
                              </div>
                            })
                          }
                        </>
                        :
                        <></>
                    }

                    {
                      !_.isEmpty(activities)
                        ?
                        <>
                          <div className="table-wraper brd-none page-break">
                            <div className="right-notes pt-0">
                              <div className="table-head page-break pt-0"><Translate text={'Notes'} /> </div>
                              <div className="notes-list-loop">

                                {
                                  _.orderBy(activities, ['addedDate'], ['desc']).map((noteData, index) => {
                                    return <div key={index} className="notes text-area-space">
                                      {_.trim(noteData.notes)}
                                      <div className="notes-sub-info">
                                        <>
                                          <span>{noteData.addedDate ? moment.unix(noteData.addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</span>
                                          {(!_.isEmpty(users) && noteData.addedBy) ? ` - ${CommonHelper.getUserNamebyId(users, noteData.addedBy)}` : '--'}
                                        </>


                                        {
                                          (noteData.type === "activity" || noteData.subType !== 'note') && (
                                            <div className="badge badge-pill badge-input-white ml-2 text-capitalize">
                                              <Translate text={noteData.type === 'activity' ? 'activity' : noteData.subType} toUpperCase={true} />
                                            </div>
                                          )
                                        }

                                        {/* {
                                          noteData.tags && noteData.tags.map((rec, index) => {
                                            return <div key={index} className="badge badge-pill badge-input-white ml-2 text-capitalize">{CommonHelper.getNameByValue(servieData?.currentSettings?.integrations, (_.isObject(rec) ? rec.type : rec), (_.isObject(rec) ? rec.type : rec))}</div>
                                          })
                                        } */}

                                        {noteData.modifiedDate && noteData.addedDate && noteData.addedDate.seconds !== noteData.modifiedDate.seconds && noteData.subType === 'note' && (<div >
                                          <Translate text={"Last Edited"} />:&nbsp;
                                          <span className={'pl-0'}>{moment.unix(noteData.modifiedDate.seconds).format("DD MMM YYYY h:mm A")}</span>
                                          {!_.isEmpty(users) && noteData.modifiedBy ? ` - ${CommonHelper.getUserNamebyId(users, noteData.modifiedBy)}` : ""}
                                          {/* {noteData.modifiedFrom ? (
                                <>  &nbsp;{` - `}&nbsp;  <i className={`ico icon-${noteData.modifiedFrom === "web" ? "monitor" : noteData.modifiedFrom} mr-1`}  ></i>  {noteData.modifiedFrom}{" "}</>
                              ) : (<></>)} */}
                                        </div>)}

                                      </div>
                                    </div>
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        </>
                        :
                        <></>
                    }





                    <div className="totalcost">
                      <div className="signwrap-loop">
                        <div className="signwrap">

                          <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                            <tbody><tr>
                              <td colSpan="3"> <div className="head-lbl"><Translate text={'Authorisation of Repairs'} /></div> </td>
                            </tr>
                              <tr>
                                <td width="25%"><Translate text={'Email Sent on'} /></td>
                                <td width="1%"> :</td>
                                <td><div className="inline-table">-</div></td>
                              </tr>
                              <tr>
                                <td><Translate text={'Quote viewed on'} /> </td>
                                <td>:</td>
                                <td><div className="inline-table">-</div></td>
                              </tr>
                              <tr>
                                <td><Translate text={'Approved By'} /> </td>
                                <td>:</td>
                                <td ><div className="inline-table">-</div></td>
                              </tr>

                            </tbody>
                          </table>

                          <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                            <tbody><tr>
                              <td colSpan="3"> <div className="head-lbl" style={{ marginTop: '10px' }}><Translate text={'Confirmation'} /></div> </td>
                            </tr>
                              <tr>
                                <td width="23%"><Translate text={'Spoke to'} /></td>
                                <td width="1%"> :</td>
                                <td ><div className="inline-table">{spokeTo ? spokeTo : '-'}</div>
                                  {
                                    clientContactDate ? <>on <div className="inline-table">{moment.unix(clientContactDate.seconds).format('DD MMM YYYY h:mm A')}</div></> : ''
                                  }

                                </td>
                              </tr>
                              {/* <tr>
                                <td>Comments </td>
                                <td>:</td>
                                <td><div className="inline-table">{spokeTo ? spokeTo : '-'}</div></td>
                              </tr> */}


                            </tbody></table>

                        </div>

                      </div>

                      <div className="signwrap-loop">

                        <table width="100%" cellSpacing="0" cellPadding="0" border="0">

                          <tbody>

                            <tr >
                              <td rowSpan="5" style={{ display: 'none' }}>

                              </td>
                              <td className="costtxt"><Translate text={'Service Packages Cost'} /></td>
                              <td className="cost-box right-align"><NumberFormat value={fullCost.packagesCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                            </tr>

                            <tr >
                              <td className="costtxt"><Translate text={'Service Parts Cost'} /></td>
                              <td className="cost-box right-align"><NumberFormat value={fullCost.servicePartsCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                            </tr>
                            <tr>

                              <td className="costtxt"><Translate text={'Parts Quote Cost'} /></td>
                              <td className="cost-box right-align"><NumberFormat value={fullCost.partsCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                            </tr>
                            <tr>
                              <td className="costtxt"><Translate text={'Labour Repair Cost'} /></td>
                              <td className="cost-box right-align"><NumberFormat value={fullCost.labourCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                            </tr>
                            <tr>
                              <td className="costtxt blue-color"><Translate text={'Sub Total Cost'} /> {`(${taxType ? taxType : 'Tax'} Excl.)`}:</td>
                              <td className="cost-box right-align blue-color" ><span><NumberFormat value={fullCost.actualSubTotal} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></span></td>
                            </tr>
                            {
                              fullCost.totalDiscount
                                ?
                                <>
                                  <tr >
                                    <td className="costtxt"><Translate text={'Total Discount'} /></td>
                                    <td className="cost-box right-align"><NumberFormat value={fullCost.totalDiscount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${currencySymbol}-`} /></td>
                                  </tr>
                                </>
                                :
                                <></>
                            }
                            {
                              _isTaxIncluded
                                ?
                                <>
                                  <tr >
                                    <td className="costtxt">  {taxType}&nbsp;<Translate text={'Payable'} /></td>
                                    <td className="cost-box right-align"><NumberFormat value={fullCost.taxCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                                  </tr>
                                </>
                                :
                                <></>
                            }
                            <tr>
                              <td className="costtxt-green"><Translate text={'Total Repair Cost'} /> {_dispalyTax}</td>
                              <td className="cost-box total right-align"><NumberFormat value={fullCost.total} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                            </tr>

                          </tbody></table>
                      </div>
                    </div>



                  </div>
                </div>
                <div className="push"></div>
              </div>

              {
                !_.isEmpty(disclaimer)
                  ?
                  <>

                    <div className="table-wraper brd-none">
                      <div className="table-head"><Translate text={'disclaimer'} /></div>
                      <div className="terms-customer-list" dangerouslySetInnerHTML={{ __html: (disclaimer ? disclaimer : '--') }} />
                    </div>
                    <div className="table-wraper brd-none">
                      <div className="table-head"><Translate text={'signature'} /> </div>
                      <div className="sign-list"> {
                        userSign
                          ?
                          <img id={`terms-signature`} src={userSign} width="120" />
                          :
                          <img id={`terms-signature`} src={_images.sign} width="120" />
                      } </div>
                    </div>
                  </>
                  :
                  <>
                  </>
              }




              <div className="clear"></div>
              <div className="footer">
                <div className="footer-icons">

                  <div className="icons">
                    <div className="urgent"> U</div>
                    <Translate text={'urgent'} upperCase={true} /></div>
                  <div className="icons">
                    <div className="advisable"> A</div>
                    <Translate text={'advisable'} upperCase={true} /></div>
                  <div className="icons">
                    <div className="report"> R</div>
                    <Translate text={'report'} upperCase={true} /></div>
                  <div className="divider"> | </div>
                  <div className="icons">
                    <div className="badge-white"> C</div>
                    <Translate text={'customer'} upperCase={true} /></div>
                  <div className="icons">
                    <div className="badge-white"> W</div>
                    <Translate text={'warranty'} upperCase={true} /></div>
                  <div className="icons">
                    <div className="badge-white"> I</div>
                    <Translate text={'internal'} upperCase={true} /></div>

                </div>
                <div className="clear"></div>
                <div className="footer-bottom">
                  <p>
                    <span style={{ paddingLeft: 0 }} >
                      {client.address ? <>{client.address}</> : <></>}
                    </span>
                    <span style={{ paddingLeft: 0 }}>
                      {client?.phone ? <>&nbsp; | &nbsp;<Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                      {client?.abn ? <>&nbsp; | &nbsp;<Translate text={'ABN'} />: <span style={{ paddingLeft: 0 }} >{client.abn}</span></> : <></>}
                      {client?.acn ? <>&nbsp; | &nbsp;<Translate text={'ACN'} />: <span style={{ paddingLeft: 0 }} >{client.acn}</span></> : <></>}
                      {client?.website ? <>&nbsp; | &nbsp;<Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                    </span>
                  </p>
                </div>
                <div className="footer-cpy">
                  <div className="footer-left">Powered by <a target="_blank" href="http://fusionsd.com.au/">Fusion SD</a></div>
                  <div className='footer-right'>Created On - {!_.isEmpty(client) && client.timezone ? moment.tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment().format('DD/MM/YYYY hh:mm A')}</div>
                </div>
                <div className="clear"></div>
              </div>

            </div>
          </>
      }
    </>



  )
};

export default ServicePreview;
