import React, { Component } from 'react';
import { Modal, Dropdown } from "react-bootstrap";
import _ from 'lodash'
import moment from 'moment';
import Translate from '../../constants/translate';
import QRCode from 'react-qr-code';

/** COMPONENTS */
import { default as _images } from '../../images';

export default class DigitalLicenseScan extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.state = {
            contacts: []
        }

    }

    componentDidMount() {
        const { digitalLicence } = this.props;
        let currentDT = moment().add(-1, 'seconds');
        window.unSubDigitalLicenceList = window.firebase.firestore().collection(`digitalLicence`)
            .where('trigger.clubName', '==', digitalLicence.settings.clubName)
            .where('logDate', '>=', window.firebase.firestore.Timestamp.now())
            .orderBy('logDate', 'desc')
            //.where('logDate', '<=', window.firebase.firestore.Timestamp.fromDate(currentDT._d))
            .onSnapshot(querySnapshot => {
                let _dList = [];
                querySnapshot.forEach((rec) => {
                    _dList.push({ ...rec.data(), 'documentID': rec.id })
                })
                this.setState({ contacts: _dList });
            })
    }

    componentWillUnmount() {
        window.unSubDigitalLicenceList && window.unSubDigitalLicenceList();
    }

    render() {
        const { contacts } = this.state;
        const { show, digitalLicence } = this.props;
        //console.log('digitalLicence', digitalLicence)
        return (
            <>
                <Modal
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-digital-license">
                    <Modal.Header closeButton>
                        <Modal.Title> <h5 className="modal-title"><Translate text={'isDLScan'} /></h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid">
                            <div className="row">

                                <div className="digital-license-wrapper">
                                    <div className="digital-license-qrcode">
                                        <QRCode value={digitalLicence.settings.qr} size={200} />
                                        {/* <img src="images/appstore.png" width="200" height="200" alt="" /> */}
                                    </div>
                                    <div className="digital-license-infohead"><Translate text={'Please scan QR code from Service NSW App'} /></div>
                                    <div className="digital-license-info"><Translate text={'Waiting for License data...'} /></div>
                                    {
                                        !_.isEmpty(contacts)
                                            ?
                                            <>
                                                <div className="digital-license-list">
                                                    {
                                                        contacts.map((info, index) => {
                                                            return <div key={index} className="digital-license-userinfo" onClick={(e) => { e.preventDefault(); this.props.handleClose(info); }}>
                                                                <div className="digital-license-userinfoimage">
                                                                    <img src={info.photo ? 'data:image/png;base64,' + info.photo : _images.nouser} className="rounded-circle  img-object-fit" />
                                                                </div>
                                                                <div className="digital-license-userinfo-title"> {info.licenseename}
                                                                    <div className="digital-license-userinfo-subtitle">{info.licencenumber}</div>
                                                                </div>
                                                            </div>
                                                        })
                                                    }



                                                </div>

                                            </>
                                            :
                                            <></>
                                    }

                                </div>

                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>


        );
    }
}