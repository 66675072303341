/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2'
import moment from 'moment';
import NumberFormat from 'react-number-format';
import _, { pick } from 'lodash'
/** COMPONENTS */
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { ReactSelect, InputText, EvalSearch, PopUpModal } from '../../components';
import { firestoreDB } from "../../services/helper";
import { default as _images } from '../../images';
import ExistingTIPLIst from '../tradeinplus/list/existingList'
import AutograbFacet from "./autograbFacet";


let australian_state = [
    { value: 'ACT', active: true, label: 'Australian Capital Territory' },
    { value: 'NSW', active: true, label: 'New South Wales' },
    { value: 'NT', active: true, label: 'Northern Territory' },
    { value: 'QLD', active: true, label: 'Queensland' },
    { value: 'SA', active: true, label: 'South Australia' },
    { value: 'TAS', active: true, label: 'Tasmania' },
    { value: 'VIC', active: true, label: 'Victoria' },
    { value: 'WA', active: true, label: 'Western Australia' }
]


export default class AutograbScan extends Component {
    constructor(props) {
        super(props);
        this.unsubscribeAutoGrab = null;
        this.state = {
            fields: {
                regNum: (this.props.regNo ? this.props.regNo.toUpperCase() : ''),
                vinNum: (this.props.chassisNo ? this.props.chassisNo.toUpperCase() : ''),
                _state: (this.props.regState ? this.props.regState : ''),
            },
            oldfields: {},
            activeTab: 'reg',
            errors: {},
            license_state: [],
            isLoading: false,
            autograbList: [],
            autograbVehicles: {},
            showExisting: { show: false, keywords: null },
            facetSearch: { show: false, keywords: null }
        }
        //this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount = () => {
        const { dealersettings } = this.props;
        let license_state = [];
        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
            const client = dealersettings.client;
            let defaultCountry = client.countryCode ? client.countryCode.toUpperCase() : 'AU';

            dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === (defaultCountry)).forEach((doc) => {
                    license_state.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
        }
        this.setState({ license_state: license_state });//, () => { this.searchPPSRList(); })
    }

    componentWillUnmount() {
        this.unsubscribeAutoGrab && this.unsubscribeAutoGrab();
    }

    // searchPPSRList = (_vinNum) => {
    //     const { vinNum, regNum, _state } = this.state.fields
    //     const { dealersettings, clientID } = this.props;
    //     let chassisNo = _vinNum ? _vinNum : vinNum;
    //     let _clientID = (clientID ? clientID : dealersettings.client.id)
    //     if ((chassisNo || (regNum && _state)) && _clientID) {
    //         this.setState({ isLoading: true })
    //         this.unsubscribeAutoGrab && this.unsubscribeAutoGrab();
    //         let refAutoGrabData = firestoreDB(dealersettings).firestore().collection(`autoGrabLogs`)
    //             .where('clientID', '==', _clientID)

    //         if (chassisNo)
    //             refAutoGrabData = refAutoGrabData.where('data.vehicle.vinNo', '==', chassisNo)
    //         else
    //             refAutoGrabData = refAutoGrabData.where('data.vehicle.regNo', '==', regNum).where('data.vehicle.state', '==', _state)

    //         this.unsubscribeAutoGrab = refAutoGrabData.onSnapshot((querySnapshot) => {
    //             let _customFilters = [];
    //             querySnapshot.forEach(doc => {
    //                 const _filt = {
    //                     ...doc.data(),
    //                     documentID: doc.id
    //                 }

    //                 _filt.stolen = !_.isEmpty(_filt?.summary?.NevdisData?.NevdisVehicles?.NevdisVehicle?.StolenDetails?.StolenDetail) ? 'Yes' : 'No'
    //                 _filt.wov = !_.isEmpty(_filt?.summary?.NevdisData?.NevdisVehicles?.NevdisVehicle?.WrittenOffDetails?.WrittenOffDetail) ? 'Yes' : 'No'

    //                 _customFilters.push(_filt);
    //             });
    //             if (_.isEmpty(_customFilters)) {
    //                 //this.handleAutoGrabSearch();
    //             }
    //             else {
    //                 this.setState({
    //                     autograbList: _.orderBy(_customFilters, ['logDate'], ['desc']),
    //                     isLoading: false
    //                 });
    //             }


    //         });
    //     }
    //     else {
    //         this.clearList();
    //     }

    // }

    clearList = () => {
        this.unsubscribeAutoGrab && this.unsubscribeAutoGrab();
        this.setState({ autograbList: [], autograbVehicles: {} });
    }

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = _.trim(value.replace(/[@!|~&\/\\#,+()$~%.'":*?_^`<>{}-]/g, '')).toUpperCase();
        else
            fields[name] = '';

        this.setState({ fields: fields }, () => { this.errorChange(name) });
        if (name === 'vinNum' && _.isEmpty(fields[name])) this.clearList();
    }


    handleReactSelectChange = (e, data) => {
        let fields = Object.assign({}, this.state.fields);

        if (e)
            fields[data.name] = e.value;
        else
            fields[data.name] = '';

        this.setState({ fields: fields }, () => { this.errorChange(data.name) });

    }

    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  

        let state = Object.assign({}, this.state.fields);
        state[name] = e.floatValue;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (!_.isEmpty(errors)) {
            if (!fields[key])
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        let mandatoryFields = fields.regNum && !fields.vinNum ? ['regNum', '_state'] : ['vinNum']
        if (this.props.milesMandatory) mandatoryFields.push('miles')
        for (let [key, value] of Object.entries(fields)) {
            if (mandatoryFields.indexOf(key) >= 0) {
                if (!fields[key]) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }
            }
        }

        if (this.props.milesMandatory && !fields['miles']) {
            formIsValid = false;
            errors['miles'] = errorClass;
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.setState({ oldfields: fields })
            this.handleSearch();
        }
        else
            return formIsValid;
    };

    handleSearch = async () => {
        const { dealersettings, clientID, existingSearch, tradeinProID } = this.props;
        const { fields } = this.state;
        if (existingSearch && tradeinProID) {
            let _keywords = [];
            let exists = false;
            let existingContact;
            if (fields.vinNum) _keywords.push(fields.vinNum.toLowerCase());
            if (fields.regNum) _keywords.push(fields.regNum.toLowerCase());

            const checkExist = firestoreDB(dealersettings).firestore().collection('tradeinPro')
                .where('clientID', 'in', CommonHelper.getTradeinProDealers(dealersettings, true))
                //.where('clientID', '==', (clientID ? clientID : dealersettings?.client?.id))
                .where('keywords', 'array-contains-any', _keywords)
                .where('isDeleted', '==', false).limit(5).get()
                .then(snapshot => {
                    if (tradeinProID) {
                        snapshot.forEach(doc => {
                            const _enq = doc.data();
                            if (tradeinProID !== doc.id && ((_enq?.tradein?.regNo && _keywords.includes(_enq?.tradein?.regNo.toLowerCase())) || (_enq?.tradein?.chassisNo && _keywords.includes(_enq?.tradein?.chassisNo.toLowerCase())))) {
                                exists = true;
                                existingContact = _enq;
                            }
                        });
                        return exists;
                    }
                    return exists;
                });
            const [checkQuery] = await Promise.all([checkExist]);
            if (checkQuery) {
                this.handleExist(_keywords)
            }
            else {
                this.handleAutoGrabSearch();
            }
        }
        else {
            this.handleAutoGrabSearch();
        }
    }

    handleExist = (_keywords) => {
        Swal.fire({
            title: 'Appraisal Exists!',
            html: 'There are already appraisals that meet the search criteria.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonClass: 'viewButton',
            confirmButtonColor: '#459E20',
            cancelButtonColor: '#545a6d',
            confirmButtonText: CommonHelper.showLocale(this.props, 'View Appraisal(s)'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'Continue, Search'),
            //reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                this.setState({ showExisting: { show: true, keywords: _keywords } })
                //this.handleXContactShow(response.data)
            }
            else {
                this.handleAutoGrabSearch();
            }
        });

    }




    handleAutoGrabSearch = () => {
        const { dealersettings, clientID } = this.props;
        let state = Object.assign({}, this.state.fields);
        var objData = Object.assign({},
            {
                "clientID": clientID ? clientID : dealersettings?.client?.id
            });

        this.setState({ isLoading: true, autograbVehicles: {} })

        if (state.vinNum) {
            objData.vinNo = state.vinNum;
        }
        else {
            objData.regNo = state.regNum;
            objData.state = state._state;
        }
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }
        //console.log('handleAutoGrabSearch', objData);
        const tradeinplussendEmail = window.firebase.functions().httpsCallable('autograb-searchVehicle');
        tradeinplussendEmail(objData).then((response) => {
            //console.log('autograb-searchVehicle', response, response.data?.success);
            this.setState({ isLoading: false })
            if (response.data.success && !_.isEmpty(response.data?.data?.vehicle)) {
                this.handleSelect(response.data?.data)
            }
            else if (response.data.success && !_.isEmpty(response.data?.data?.vehicles)) {
                this.setState({ autograbVehicles: response.data?.data });
            }
            else {
                if (response.data?.success === false) {
                    if (response.data?.message)
                        Swal.fire(response.data.message, '', 'error')
                    else
                        Swal.fire(CommonHelper.showLocale(this.props, 'Data not found!'), 'Search with different data.', 'error')
                }
                this.clearList();
            }

        });
    };

    handleSelect = async (_data) => {
        this.props.handleClose(_data, this.state.fields);
    }

    getMonthNameFromNumber = (monthNumber) => {
        const monthName = moment()
            .month(monthNumber - 1)
            .format("MMM");
        return monthName;
    };

    bindDescription = (vehicle, colour) => {
        let _description = null;

        if (vehicle) {
            if (vehicle.badge)
                _description = (_description ? _description : '') + vehicle.badge + ' '
            if (vehicle.series)
                _description = (_description ? _description : '') + vehicle.series + ' '
            if (vehicle.num_gears)
                _description = (_description ? _description : '') + vehicle.num_gears + 'sp '
            if (vehicle.num_cylinders)
                _description = (_description ? _description : '') + vehicle.num_cylinders + 'cyl '
            if (vehicle.transmission_type)
                _description = (_description ? _description : '') + vehicle.transmission_type + ' '
            if (vehicle.body_type)
                _description = (_description ? _description : '') + vehicle.body_type + ' '
            if (vehicle.drive)
                _description = (_description ? _description : '') + vehicle.drive + ' '
            if (vehicle.capacity_cc)
                _description = (_description ? _description : '') + vehicle.capacity_cc + 'cc '
            if (vehicle.power_kw)
                _description = (_description ? _description : '') + vehicle.power_kw + 'kw '
            if (vehicle.torque_nm)
                _description = (_description ? _description : '') + vehicle.torque_nm + 'nm '
            if (vehicle.fuel_type)
                _description = (_description ? _description : '') + vehicle.fuel_type + ' '
            if (vehicle.body_config)
                _description = (_description ? _description : '') + vehicle.body_config + ' '
            if (vehicle.engine_type)
                _description = (_description ? _description : '') + vehicle.engine_type + ' '
            if (vehicle.num_doors)
                _description = (_description ? _description : '') + vehicle.num_doors + 'DR '
            if (vehicle.num_seats)
                _description = (_description ? _description : '') + vehicle.num_seats + 'SEATS '
            if (colour)
                _description = (_description ? _description : '') + colour + ' '
        }

        return _description ? _.trim(_description) : null;
    }

    handleVehicleSelect = async (_data, documentID) => {

        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            html: `Do you want to select <b>${this.bindDescription(_data?.vehicle, _data?.colour)}</b>.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then((result) => {
            if (result.value) {
                this.props.handleClose(_data, this.state.fields);
                let _obj = { 'documentID': documentID, 'vehicle': _data?.vehicle, }
                const updateVehicleID = window.firebase.functions().httpsCallable('autograb-updateVehicleID');
                updateVehicleID(_obj).then((response) => {
                    //console.log('autograb-updateVehicleID', '', '==>', '');
                });
            }
        })

    }

    handleFacetSearch = (e) => {
        e.preventDefault();
        const { autograbVehicles } = this.state;
        this.setState({
            facetSearch:
            {
                show: true,
                data: {
                    ..._.pick(autograbVehicles.vehicles[0], ['make', 'model', 'year', 'vinNo', 'state', 'regNo', 'badge', 'series']),
                    ..._.pick(autograbVehicles, ['extended_data', 'vehicle_age'])
                }
            }
        })

    };

    render() {
        const { regNum, _state, vinNum } = this.state.fields;
        const { show, clientUsers, groupUsers, dealersettings, milesMandatory } = this.props;
        const { license_state, isLoading, showExisting, autograbVehicles, fields, facetSearch, oldfields } = this.state;

        const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;

        const _autograb = Object.assign({}, dealersettings?.client?.integrations.filter(e => e.type === "autograb")[0]);
        const _autoGrabEnabled = _autograb && _autograb.active && _autograb.enabled ? true : false;

        const divStyle = {
            height: '135px',
            overflow: 'auto'
        };
        return (
            <>
                <Modal
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName={`${!_.isEmpty(autograbVehicles) ? 'modal-dialog-scrollable' : ''} modal-dialog-centered modal-ppsr-manager`}>
                    <Modal.Header closeButton>
                        <Modal.Title> <h5 className="modal-title"><Translate text={'searchVehicle'} /></h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="form-style w-100">
                                    <div className={`form-row ${isLoading ? 'form-disable' : ''}`}>
                                        <div className="form-group col-md-6">
                                            <label ><Translate text={'vehicleRegNo'} /></label>
                                            <InputText
                                                name="regNum"
                                                value={regNum}
                                                onChange={this.handleOnChange}
                                                autoComplete="off"
                                                placeholder={'registration number'}
                                                className={`form-control ${this.state.errors["regNum"]}`}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label > <Translate text={'state'} /></label>
                                            <ReactSelect
                                                options={!_.isEmpty(license_state) ? license_state : australian_state}
                                                name={"_state"}
                                                placeholder={'select state'}
                                                onChange={this.handleReactSelectChange}
                                                value={_state}
                                                classNamePrefix={`${this.state.errors["_state"]} basic-select`}
                                            >
                                            </ReactSelect>
                                        </div>

                                    </div>

                                    <div className="ppsr-text-divider">OR</div>

                                    <div className={`form-row ${isLoading ? 'form-disable' : ''}`}>
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'chassisNo'} /></label>
                                            <InputText
                                                type="text"
                                                name="vinNum"
                                                value={vinNum}
                                                onChange={this.handleOnChange}
                                                autoComplete="off"
                                                placeholder={'chassis/vin number'}
                                                className={`form-control ${this.state.errors["vinNum"]}`}
                                            />
                                        </div>

                                        {
                                            milesMandatory && (<div className="form-group col-md-12">
                                                <label><Translate text={'odometerReading'} /> </label>
                                                <NumberFormat
                                                    id={'odometerReading'}
                                                    thousandSeparator={true}
                                                    placeholder={'odometer reading'}
                                                    className={`form-control ${this.state.errors["miles"]}`}
                                                    onValueChange={this.onCurrencyChange.bind(this, 'miles')}
                                                    value={fields['miles'] ? fields['miles'] : null}
                                                    displayType={'input'}
                                                />
                                            </div>)
                                        }

                                    </div>

                                    {
                                        !_.isEmpty(autograbVehicles)
                                            ?
                                            <div className="market-section-wrap">
                                                <div className="market-left-head mt-1 "><div className="float-left"></div><Translate text={'Please select the vehicle model'} /></div>
                                                <div className="market-search-vehiclelist mt-1" style={divStyle}>
                                                    {
                                                        autograbVehicles.vehicles.map((vehicle, index) => {
                                                            return <div key={index} className="market-search-vehicle-splitwrap">
                                                                <div className="market-search-vehicle-loop" >
                                                                    <div className="market-search-vehicle-split">
                                                                        <div>
                                                                            <div className="market-search-vehicle-title">{CommonHelper.bindAutoGrabTitle(vehicle)}</div>
                                                                            <div className="market-search-vehicle-info">Released
                                                                                <span className="badge market-search-yellow ml-1">
                                                                                    {vehicle?.release_month ? this.getMonthNameFromNumber(vehicle?.release_month) : "--"}{", "}{vehicle?.release_year ? vehicle?.release_year : ""} </span>
                                                                            </div>
                                                                        </div>
                                                                        <div> <button type="button" className="btn btn-secondary float-right market-search-vehicle-selectbtn" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.handleVehicleSelect({ 'colour': autograbVehicles.colour, 'vehicle': vehicle }, autograbVehicles.documentID);

                                                                        }}> Select</button></div>

                                                                    </div>
                                                                    <div className="market-search-vehicle-info">
                                                                        {this.bindDescription(vehicle, autograbVehicles.colour)}
                                                                        {/* {`${vehicle?.capacity_cc ? vehicle?.capacity_cc : ''} ${vehicle?.engine}  and ${vehicle?.transmission} or
          ${vehicle?.transmission_type} transmission modes. The engine has ${vehicle?.num_cylinders} cylinders and a capacity of ${vehicle?.capacity_cc ? vehicle?.capacity_cc : ''} cc, providing a power output of ${vehicle?.power_kw} kW and a torque of ${vehicle?.torque_nm} Nm`} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }

                                    {/* {
                                        !_.isEmpty(autograbList)
                                            ?
                                            <>
                                                <div className="ppsr-table-wrap">
                                                    <h3><Translate text={'vehicles'} toUpperCase={true} /></h3>
                                                    <div className="common-table">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className="head-light" width="20%"><Translate text={'date'} toUpperCase={true} /></th>
                                                                    <th scope="col" className="head-light" width="60%"><Translate text={'vehicleModel'} toUpperCase={true} /></th>
                                                                    <th scope="col" className="head-light border-right-0" width="10%"><Translate text={'colour'} /></th>
                                                                    <th scope="col" className="head-light border-left-0" width="10%"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    autograbList.map((_data, index) => {
                                                                        return <tr key={index}>
                                                                            <td>
                                                                                <div className="ppsr-list-items-info pr-0 ">
                                                                                    <h4 title="31/03/2022 01:19 PM">{_data.logDate ? moment.unix(_data.logDate.seconds).format('DD/MM/YYYY hh:mm A') : '--'}</h4>
                                                                                    {_data.owner ? <h5>{CommonHelper.getUserNamebyId(allUsers, _data.owner)}</h5> : <></>}
                                                                                </div>

                                                                            </td>
                                                                            <td>{_data?.data?.vehicle ? CommonHelper.bindAutoGrabDescription(_data.data.vehicle) : '--'}</td>
                                                                            <td className="border-right-0">{_data?.data?.colour ? _data.data.colour : '--'}</td>
                                                                            <td className="border-left-0">
                                                                                <button className="btn btn-sm btn-secondary" style={{ cursor: 'pointer', float: 'right' }}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        this.handleSelect(_data?.data);
                                                                                    }}>
                                                                                    <Translate text={'select'} />
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                    } */}



                                </div>
                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer className={`modal-footer ${isLoading ? 'form-disable' : ''}`}>

                        {
                            _autoGrabEnabled
                                ?
                                <div className="float-left"><div className="autograb-wrap"> Powered by <img src={_images.autograb_logo} /></div> </div>
                                :
                                <></>
                        }


                        {
                            !autograbVehicles?.vehicles?.length || JSON.stringify(fields) !== JSON.stringify(oldfields) ? (
                                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => this.handleSubmit(e)}>
                                    {isLoading ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                                    <Translate text={'Search'} />
                                </button>
                            ) : (
                                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => this.handleFacetSearch(e)}>
                                    {isLoading ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                                    <i className="ico icon-search" style={{ float: 'left', marginTop: '4px', marginRight: '5px' }}></i>
                                    <Translate text={'Manual Search'} />
                                </button>
                            )
                        }
                        <button type="button" className="btn btn-default float-right" onClick={(e) => { e.preventDefault(); this.props.handleClose() }}> <Translate text={'cancel'} /></button>
                    </Modal.Footer>


                </Modal >
                <PopUpModal show={showExisting.show}>
                    <ExistingTIPLIst
                        {...this.props}
                        show={showExisting.show}
                        _keywords={showExisting.keywords}
                        handleClose={(_data) => {
                            if (_data === 'search') this.handleAutoGrabSearch();
                            if (_data === 'expand') this.props.handleClose(null, null, true);
                            this.setState({ showExisting: { show: false, keywords: null } })
                        }}
                    ></ExistingTIPLIst>
                </PopUpModal>
                <PopUpModal show={facetSearch.show}>
                    <AutograbFacet
                        {...this.props}
                        {...facetSearch}
                        handleClose={(_data) => {
                            this.setState({ facetSearch: { show: false, keywords: null } })
                            if (_data)
                                this.props.handleClose(_data, this.state.fields);
                        }}
                    ></AutograbFacet>
                </PopUpModal>

            </>


        );
    }
}

