export const allintegrations = [
    {
        icon: 'd3cx',
        name: '3CX',
        value: '3cx',
        website: 'https://www.3cx.com/',
        fields: [
            {
                type: 'multitext',
                value: 'emails',
                label: 'Please specify the email addresses where the recordings will be private by default and only these users can listen to the call recordings of each other.',
                class: 'col-md-12',
                validateEmail: true,
            }
        ]
    },
    {
        icon: 'easycars',
        name: 'Easycars',
        value: 'easycars',
        website: 'https://www.easycars.com.au/',
        fields: [
            {
                type: 'text',
                value: 'baseUrl',
                label: 'Base Url',
                class: 'col-md-6',
                mandatory: true
            }, {
                type: 'text',
                value: 'ClientID',
                label: 'Client  ID',
                class: 'col-md-6',
                mandatory: true
            }, {
                type: 'text',
                value: 'ClientSecret',
                label: 'Client Secret',
                class: 'col-md-6',
                mandatory: true
            }, {
                type: 'text',
                value: 'AccountNumber',
                label: 'Account Number',
                class: 'col-md-6',
                mandatory: true
            }, {
                type: 'text',
                value: 'ECAccountSecret',
                label: 'EC Account Secret',
                class: 'col-md-6',
                mandatory: true
            }, {
                type: 'select',
                value: 'VehicleType',
                label: 'Vehicle Type',
                class: 'col-md-6',
                options: 'easycarsvehicleType'
            }
        ]
    },
    {
        icon: 'livechatagent',
        name: 'Live Chat Agent',
        value: 'livechatagent',
        website: 'https://www.livechatagent.com.au/',
        fields: [
            {
                type: 'text',
                value: 'baseURL',
                label: 'Base Url',
                class: 'col-md-6',
                mandatory: true
            },
            {
                type: 'multiselect',
                value: 'origin',
                label: 'Origins',
                class: 'col-md-6',
                options: 'origins',
                mandatory: true
            }
        ]
    },
]

export const dynSelectOptions = {
    easycarsvehicleType: [
        { value: 1, label: 'Car', active: true },
        { value: 2, label: 'Motor Bike', active: true },
        { value: 3, label: 'Boat', active: true },
        { value: 4, label: 'Caravan', active: true },
        { value: 5, label: 'TruckOrBus', active: true },
        { value: 6, label: 'Trailer', active: true }
    ]
}