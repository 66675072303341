import React from 'react'

export const pipelineCountVM = {
    monthActivity: [],
    monthDelivered: [],
    monthLost: [],
    monthOpen: [],
    monthNew: [],
    monthTestdrive: [],
    monthTradein: [],
    monthWon: [],
    monthWalkin: [],

    overdueActivity: [],
    todayActivity: [],
    todayDelivered: [],
    todayLost: [],
    todayOpen: [],
    todayTestdrive: [],
    todayTradein: [],
    todayWon: [],
    todayNew: [],
    todayWalkin: [],

    weekActivity: [],
    weekDelivered: [],
    weekLost: [],
    weekOpen: [],
    weekNew: [],
    weekTestdrive: [],
    weekTradein: [],
    weekWon: [],
    weekWalkin: [],
}

export const pipelineCountVMNEW = {
    monthActivity: 0,
    monthDelivered: 0,
    monthLost: 0,
    monthOpen: 0,
    monthNew: 0,
    monthTestdrive: 0,
    monthTradein: 0,
    monthWon: 0,
    monthWalkin: 0,

    overdueActivity: 0,
    todayActivity: 0,
    todayDelivered: 0,
    todayLost: 0,
    todayOpen: 0,
    todayTestdrive: 0,
    todayTradein: 0,
    todayWon: 0,
    todayNew: 0,
    todayWalkin: 0,

    weekActivity: 0,
    weekDelivered: 0,
    weekLost: 0,
    weekOpen: 0,
    weekNew: 0,
    weekTestdrive: 0,
    weekTradein: 0,
    weekWon: 0,
    weekWalkin: 0,
}

export const leadBucketCountVM = {
    pendingInboundleads: [],
    todayConverted: [],
    monthConverted: [],
    lostApprovalList: []
}

export const pipelinestatus = [
    {
        name: "Open",
        value: "open",
        color: "#67b7dc"
    },
    {
        name: "Won",
        value: "won",
        color: "#2bb673"
    },
    {
        name: "Lost",
        value: "lost",
        color: "#ef4136"
    },
    {
        name: "Delivered",
        value: "delivered",
        color: "#049b8c"
    }
]


export const pipelineLabels = [
    {
        name: "Hot",
        value: "hot",
        color: "#ed1c24"
    },
    {
        name: "Warm",
        value: "warm",
        color: "#f7931e"
    },
    {
        name: "Cold",
        value: "cold",
        color: "#29abe2"
    },
    {
        name: "Other/Blank",
        value: "others",
        color: "#67b7dc"
    }
]

export const initialLayoutArr = [
    { x: 0, y: 0, w: 4, h: 11, i: 'LeadsByLabels', minW: 4, maxW: 12, minH: 7, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 11, w: 4, h: 11, i: 'LeadsByOrigin', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 11, w: 4, h: 11, i: 'LeadsByEnquiryType', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 0, w: 4, h: 11, i: 'SalesFunnel', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 11, w: 4, h: 11, i: 'LeadsByCampaign', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 11, y: 4, w: 4, h: 11, i: 'LeadsByStatus', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 22, w: 4, h: 11, i: 'DealerTarget', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
]

export const porscheLayoutArr = [
    { x: 0, y: 0, w: 4, h: 4, i: 'LeadsBucket', minW: 4, maxW: 12, minH: 3, maxH: 3, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 0, w: 4, h: 11, i: 'LeadsByLabels', minW: 4, maxW: 12, minH: 7, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 0, w: 4, h: 11, i: 'LeadsByEnquiryType', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 0, w: 4, h: 11, i: 'SalesFunnel', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },

    { x: 0, y: 11, w: 4, h: 11, i: 'LeadsByOrigin', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 11, w: 4, h: 11, i: 'LeadsByCampaign', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 11, w: 4, h: 11, i: 'LeadsByStatus', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },

    { x: 0, y: 22, w: 4, h: 11, i: 'DealerTarget', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },

     { x: 0, y: 22, w: 4, h: 11, i: 'CampaignPerformance', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
     { x: 4, y: 22, w: 4, h: 11, i: 'OriginPerformance', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },

    { x: 8, y: 22, w: 4, h: 11, i: 'LostLeadsReasons', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 33, w: 4, h: 11, i: 'LostEnquiryReasons', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 33, w: 4, h: 11, i: 'LeadsCreated', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 33, w: 4, h: 11, i: 'TestDriveCompleted', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 44, w: 4, h: 11, i: 'TradeInCompleted', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 44, w: 4, h: 11, i: 'LostEnquiryReignition', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 44, w: 4, h: 11, i: 'EnquiryQualificationDevelopment', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 55, w: 4, h: 11, i: 'LeadQualificationDevelopment', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 55, w: 4, h: 11, i: 'ShowroomVisits', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 55, w: 4, h: 11, i: 'LeadsByModel', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 66, w: 4, h: 11, i: 'EnquiriesByModel', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 66, w: 4, h: 11, i: 'EnquiriesByCampaign', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 66, w: 4, h: 11, i: 'QualifiedEnquiriesByModel', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 77, w: 4, h: 11, i: 'QualifiedEnquiriesByCampaign', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 77, w: 4, h: 11, i: 'LeadsBySource', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 77, w: 4, h: 11, i: 'EnquiriesBySource', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 88, w: 4, h: 11, i: 'LeadsByOriginPorsche', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 88, w: 4, h: 11, i: 'LeadsByEnquiryTypePorsche', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 88, w: 4, h: 11, i: 'NewEnquiries', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 99, w: 4, h: 11, i: 'LeadsCreatedInCAP', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 99, w: 4, h: 11, i: 'TestDrives', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 99, w: 4, h: 11, i: 'EnquiriesByPipeline', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 99, w: 4, h: 11, i: 'LeadsWonInCAP', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
]

export const mclarenLayoutArr = [
    { x: 4, y: 0, w: 8, h: 22, i: "DealerTarget", },
    { x: 0, y: 0, w: 4, h: 11, i: "LeadsByEnquiryType" },
    { x: 0, y: 11, w: 4, h: 11, i: "LeadsByOrigin" },

    { x: -1, i: "LeadsByStatus" },
    { x: -1, i: "LeadsByLabels" },
    { x: -1, i: "LeadsByCampaign" },
]

export const defaultStatsIcons = [
    { name: 'New Leads', icon: 'icon-new-leads', value: 'new' },
    { name: 'Open Leads', icon: 'icon-open-leads', value: 'open', status: true },
    { name: 'Won Leads', icon: 'icon-won-leads', value: 'won', status: true, icoVehicle: true },
    { name: 'Lost Leads', icon: 'icon-lost-leads', value: 'lost', status: true, icoVehicle: true },
    { name: 'Delivered', icon: 'icon-delivered', value: 'delivered', status: true, icoVehicle: true },
    { name: 'Test Drive', icon: 'icon-oem-testdrive', value: 'testdrive', icoVehicle: true },
    { name: 'Trade-In', icon: 'icon-oem-tradein', value: 'tradein', icoVehicle: true },
    { name: 'Unattended Leads', icon: 'icon-overdue-activities', value: 'unattended' },
    { name: 'Pending Deliveries', icon: 'icon-oem-tradein', value: 'delivery', color: '#02abc8', icoVehicle: true },
    { name: 'Overall Pending Deliveries', icon: 'icon-oem-tradein', value: 'pendingdelivery', color: '#f7931e', icoVehicle: true },
    { name: 'Scheduled Appointments', icon: 'icon-activity-calendar', value: 'scheduledAppointments', color: '#57b000' }
]