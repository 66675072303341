export const alerts = [
    { name: 'Lead Allocation', value: 'unactionedLeads' },
    { name: 'Lead Assign', value: 'leadAssign' }
]

export const mentions = [{
    name: 'First Name',
    value: '@FIRST_NAME'
},
{
    name: 'Last Name',
    value: '@LAST_NAME'
},
{
    name: 'Preferred Name',
    value: '@PREF_NAME'
},
{
    name: 'Full Name',
    value: '@FULL_NAME'
},
{
    name: 'Customer Email',
    value: '@CUSTOMER_EMAIL'
},
{
    name: 'Customer Phone',
    value: '@CUSTOMER_PHONE'
},
{
    name: 'Company Name',
    value: '@COMPANY_NAME'
},
{
    name: 'User Name',
    value: '@USER_NAME'
},
{
    name: 'From User Name',
    value: '@FROM_USER_NAME'
},
{
    name: 'User Email',
    value: '@USER_EMAIL'
},
{
    name: 'User Phone',
    value: '@USER_PHONE'
},
{
    name: 'Dealer Name',
    value: '@DEALER_NAME'
},
{
    name: 'Dealer Phone',
    value: '@DEALER_PHONE'
},
{
    name: 'Dealer Email',
    value: '@DEALER_EMAIL'
},
{
    name: 'Dealer Address',
    value: '@DEALER_ADDRESS'
},
{
    name: 'Facebook URL',
    value: '@FACEBOOK_URL'
},
{
    name: 'X URL',
    value: '@X_URL'
},
{
    name: 'Instagram URL',
    value: '@INSTAGRAM_URL'
},
{
    name: 'YouTube URL',
    value: '@YOUTUBE_URL'
},
{
    name: 'Unsubscribe URL',
    value: '@UNSUBSCRIBE_URL'
},
{
    name: 'Dealer Logo',
    value: '@DEALER_LOGO'
},
{
    name: 'Enquiry Link',
    value: '@ENQUIRY_LINK'
},
{
    name: 'Current Month',
    value: '@CURRENT_MONTH'
}]

export const timeSlots = [
    { value: 'wh', label: 'Working Hours', name: 'Working Hours' },
    { value: 'awh', label: 'After Working Hours', name: 'After Working Hours' },
    { value: 'both', label: 'Both', name: 'Both' }
]