import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { InputText, ReactSelect } from '../../../../components'
import Translate from '../../../../constants/translate';
import { roleVM, levelSubOptions } from './viewModel'
import { ClientContext } from '../clientContext'

const AddRole = (props) => {
    const [role, setRole] = useState(props.role);
    const [cloneRole, setCloneRole] = useState('')
    const [errorFields, setErrorFields] = useState({});
    const newRole = !_.isEmpty(props.role) ? false : true
    const [loader, setLoader] = useState(false)
    const [userClients, setUserClients] = useState([])
    const { clients } = useContext(ClientContext);

    useEffect(() => {
        if (_.isEmpty(props.role)) {
            const newroleVM = Object.assign({}, roleVM);
            newroleVM.documentID = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/roles`).doc().id;
            newroleVM.addedDate = window.firebase.firestore.Timestamp.now();
            newroleVM.addedBy = localStorage.uid;
            newroleVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newroleVM.modifiedBy = localStorage.uid;
            newroleVM.oemID = props.dealersettings.client.settingsID;
            setRole(newroleVM)
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(clients))
            return
        setUserClients(clients.filter(c => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), c.documentID) >= 0))
    }, [clients])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setRole({
                ...role,
                [name]: newarray1.join(' ')
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setRole({
                ...role,
                [name]: str
            });
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const handleCloneRoleChange = (e, date) => {
        if (e) {
            setRole({
                ...role,
                ['admin']: e.doc.admin ? e.doc.admin : null,
                ['modules']: e.doc.modules ? e.doc.modules : null,
                ['permissions']: e.doc.permissions ? e.doc.permissions : null,
                ['pipeline']: e.doc.pipeline ? e.doc.pipeline : null,
                ['salesType']: e.doc.salesType ? e.doc.salesType : null,
                ['servicePipeline']: e.doc.servicePipeline ? e.doc.servicePipeline : null,
                ['servicePermissions']: e.doc.servicePermissions ? e.doc.servicePermissions : null,
                ['tradeinProPipeline']: e.doc.tradeinProPipeline ? e.doc.tradeinProPipeline : null,
                ['tradeinProPermissions']: e.doc.tradeinProPermissions ? e.doc.tradeinProPermissions : null,
                ['defaultPipeline']: e.doc.defaultPipeline ? e.doc.defaultPipeline : null,
                ['defaultservicePipeline']: e.doc.defaultservicePipeline ? e.doc.defaultservicePipeline : null,
                ['defaulttradeinProPipeline']: e.doc.defaulttradeinProPipeline ? e.doc.defaulttradeinProPipeline : null
            });
            setCloneRole(e.value)
        }
        else {
            setRole({
                ...role,
                ['admin']: {},
                ['modules']: {},
                ['permissions']: {},
                ['pipeline']: [],
                ['salesType']: [],
                ['servicePipeline']: [],
                ['servicePermissions']: {},
                ['tradeinProPipeline']: [],
                ['tradeinProPermissions']: {}
            });
            setCloneRole('')
        }
    }

    const handleSelectChange = (e, data) => {
        if (data.name === 'clientID') {
            setRole({
                ...role,
                [data.name]: e.value,
            });
        }
        else {
            setRole({
                ...role,
                ['clientID']: null,
                [data.name]: e.value,
            });
        }

        setCloneRole(null)
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const saveRole = (e) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(role['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        if (_.isEmpty(role['level'])) {
            formIsValid = false;
            errors['level'] = errorClass;
        }
        if (role.level === 'individual' && _.isEmpty(role['clientID'])) {
            formIsValid = false;
            errors['clientID'] = errorClass;
        }
        setErrorFields(errors)
        if (newRole && props.allRoles.some(item => item.name === role.name)) {
            Swal.fire('Role with same name already exists', '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        delete role.active;
        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/roles/${role.documentID}`).set(role, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify((newRole === true ? 'Role added successfully' : 'Role updated successfully'), {
                    duration: 2000
                })
                props.handleClose(role);
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    return _.isEmpty(role) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-update-password"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title><Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label ><Translate text={'Role Name'} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'name'}
                                        className={`form-control ${errorFields["name"]}`}
                                        name="name"
                                        onChange={handleOnChange}
                                        value={role.name}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label ><Translate text={'Level'} /></label>
                                    <ReactSelect
                                        options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelSubOptions, { value: props.dealersettings.level })) ? levelSubOptions :
                                            levelSubOptions.filter(e => e.index >= _.find(levelSubOptions, { value: props.dealersettings.level }).index)}
                                        name={"level"}
                                        placeholder={'select level'}
                                        onChange={handleSelectChange}
                                        value={role.level}
                                        classNamePrefix={`${errorFields["level"]} basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>
                                {
                                    role.level === 'individual' ? (
                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'Client'} /></label>
                                            <ReactSelect
                                                options={userClients.map((rec) => {
                                                    return {
                                                        value: rec.documentID,
                                                        label: rec.name,
                                                        doc: rec
                                                    }
                                                })}
                                                name={"clientID"}
                                                placeholder={'select client'}
                                                onChange={handleSelectChange}
                                                value={role.clientID}
                                                classNamePrefix={`${errorFields["clientID"]} basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                    ) : (<></>)
                                }
                                {
                                    newRole ? (<div className="form-group col-md-12">
                                        <label ><Translate text={'Clone'} /></label>
                                        <ReactSelect
                                            options={_.map(props.allRoles.filter(c => !_.isEmpty(role.level) ? c.level === role.level : false), function (c) {
                                                return {
                                                    value: c.documentID,
                                                    searchlabel: c.name,
                                                    doc: c,
                                                    label: <>{c.name}<span className="user-role-span">{_.find(levelSubOptions, { value: c.level }) ? _.find(levelSubOptions, { value: c.level }).label : c.level}</span></>,
                                                };
                                            })}
                                            name={"colneRole"}
                                            placeholder={'select role'}
                                            onChange={handleCloneRoleChange}
                                            value={cloneRole}
                                            classNamePrefix={`cursor-pointer basic-select`}
                                        >
                                        </ReactSelect>
                                    </div>) : (<></>)
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveRole(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} ><Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddRole;