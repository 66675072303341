/** LIBRARIES */
import React, { useState, useEffect, useReducer, Suspense } from 'react';
import { Modal, Tab, Col, Nav, Row } from 'react-bootstrap'
import { Tabs, Radio, Space } from 'antd';
import _ from 'lodash'
import NumberFormat from 'react-number-format';
/** PROVIDERS */
/** VIEW-MODELS */
/** COMPONENTS */
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import { PopUpModal, InputRadio } from '../../../components';
import { default as _images } from '../../../images';
import AddRecommendation from '../details/addRecommendation';
import { firestoreDB } from '../../../services/helper';
import { recommendationChoice, recommendationOption } from '../../../services/enum';

const AddCheckList = React.memo((props) => {
    const { TabPane } = Tabs;
    const [errorFields, setErrorFields] = useState({})
    const [recommendations, setRecommendations] = useState([])
    const [active, setActive] = useState('0')
    const [loader, setLoader] = useState(false);
    const [recommendationModal, setrecommendationModal] = useState({
        show: false,
        docID: '',
        title: '',
        data: null
    });

    const [selectedIds, setSelectedIds] = useState({})
    useEffect(() => {
        const _dataform = Object.assign([], JSON.parse(JSON.stringify(props.recommendations)))
        setRecommendations(_dataform)
    }, [])

    const _settings = !_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.services) ? props.dealersettings.client.services : null;

    const recommendationName = props.dealersettings?.client?.clientSettings?.service?.recommendationName;

    const checklists = _.chain(_.orderBy(recommendations.filter(item => item.type === 'checklist'), ['category'], ['asc']))
        .groupBy("category")
        .map((value, key) => ({ category: key, lists: value }))
        .value()

    const checklistsMandatory = Array.prototype.map.call(_.orderBy(recommendations.filter(item => item.type === 'checklist'), ['category'], ['asc']), function (item) { return item.documentID; })//.join(",").split(',')

    const divStyle = {
        height: '400px',
        overflow: 'auto',
        position: "relative",
        width: '100%'
    };

    //#region RECOMMENDATION CHANGES
    const handleRecommendationClose = (_data) => {
        if (!_.isEmpty(_data)) {
            const _recommendations = Object.assign([], recommendations);

            _recommendations.filter(function (obj) {
                //Update old data
                if (obj.documentID === _data.documentID) {
                    obj.detail = _data.detail;
                }
            });

            setRecommendations(_recommendations)
        }
        setrecommendationModal({
            show: false,
            title: '',
            docID: '',
            data: null
        });
    }

    const handleEditRecommendation = (req) => {
        setrecommendationModal({
            show: true,
            title: `Edit ${_.trim(recommendationName) ? recommendationName : 'Recommendation'}`,
            docID: req ? req.documentID : '',
            data: req
        });
    }
    //#endregion
    const handleTypeChange = (e) => {
        const { name, value } = e.target;

        const _recommendations = Object.assign([], recommendations);

        _recommendations.filter(function (obj) {
            //Update old data
            if (obj.documentID === name) {
                obj.choice = value;
                if (value === 'Required') {
                    let recommendationDetail = Object.assign({}, obj.detail);
                    recommendationDetail.option = recommendationOption.customer;
                    recommendationDetail.status = 'urgent';
                    recommendationDetail.oKToDo = true;
                    obj.detail = recommendationDetail;

                    obj.addedBy = props.dealersettings ? props.dealersettings.id : '';
                    obj.addedDate = window.firebase.firestore.Timestamp.now();
                    obj.addedFrom = 'web';
                    obj.modifiedBy = props.dealersettings ? props.dealersettings.id : '';
                    obj.modifiedDate = window.firebase.firestore.Timestamp.now();
                    obj.modifiedFrom = 'web'
                    handleEditRecommendation(obj);
                }
            }
        });

        setRecommendations(_recommendations)
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleSelectChoice = (_type) => {
        let _selectedIds = Object.assign({}, selectedIds);
        const _recommendations = Object.assign([], recommendations);
        for (let [key] of Object.entries(_selectedIds)) {
            _recommendations.filter(function (obj) {
                //Update old data
                if (obj.documentID === key) {
                    obj.choice = _type;
                }
            });
        }

        setRecommendations(_recommendations)
        setErrorFields({});
        setSelectedIds({});

    }

    const handleSubmit = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let _errorFields = {};
        let _errorFieldTab = null;
        checklistsMandatory.forEach((val) => {
            let data = recommendations.filter(item => item.documentID === val)[0];
            if (data && !data['choice']) {
                if (!_.isNumber(_errorFieldTab)) {
                    let activeStageIndex = _.indexOf(checklists.map(v => v.category), data.category)
                    _errorFieldTab = activeStageIndex;
                }
                formIsValid = false;
                _errorFields[val] = errorClass;
            }
        })
        setErrorFields(_errorFields);
        if (formIsValid === true) {
            setLoader(true);
            let recommendationsData = recommendations.filter(item => item.type === 'checklist');
            const batch = firestoreDB(props.dealersettings).firestore().batch();
            recommendationsData && recommendationsData.forEach(data => {
                if (!data.projectId) data.projectId = (props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null);
                batch.set(firestoreDB(props.dealersettings).firestore().doc(`serviceJobs/${data.serviceID}/recommendations/${data.documentID}`), data, { merge: true });
            })
            batch.commit().then(snapshot => {
                setLoader(false);

                props.handleClose(formIsValid);

                if (props.handleStatusReScheduled)
                    props.handleStatusReScheduled();

            }).catch((error) => {
                console.error("Error updating document: ", error);
            });


        }
        else {
            setActive(_errorFieldTab)
        }
    }


    const handleAllChecked = (event, category) => {
        let _selectedIds = {};
        //console.log('handleAllChecked', event, category)
        if (event.target.checked) {
            !_.isEmpty(recommendations) && recommendations.filter(e => e.category === category).map(file => {
                _selectedIds[file.documentID] = Object.assign({}, file);
            })
        }
        setSelectedIds(_selectedIds);
    }

    const handleCheckChange = (e, id) => {
        const { name, checked } = e.target;

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked) {
            let record = Object.assign({}, recommendations.filter(e => e.documentID === id)[0]);
            if (!_.isEmpty(record))
                _selectedIds[id] = record;
        }
        else {
            delete _selectedIds[id];
        }
        setSelectedIds(_selectedIds);
    }

    return (
        <>

            <Modal
                id="modal-add-checklist"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-checklist">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={props.title ? props.title : 'Check List'} /></h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="checklist-popup-wrapper">

                                    <div className="checklist-popup-column-wraper">


                                        <Tabs tabPosition={'left'} activeKey={`${active}`} onTabClick={(key) => { setActive(key); setSelectedIds({}); }}>
                                            {
                                                !_.isEmpty(checklists)
                                                    ?
                                                    <>
                                                        {
                                                            checklists && checklists.map((info, index) => {
                                                                return <TabPane tab={CommonHelper.getNameByValue(_settings && _settings.categories, info.category, '--')} key={index}>
                                                                    <div className="checklist-popup-column-right-large">

                                                                        <div className="checklist-popup-column-title">
                                                                            <h3>{CommonHelper.getNameByValue(_settings && _settings.categories, info.category, '--')}</h3>
                                                                        </div>

                                                                        <div className="checklist-popup-large-details">
                                                                            {
                                                                                info.lists && props.hasAccess
                                                                                    ?
                                                                                    <>
                                                                                        <div className="file-manager-check mt-2">
                                                                                            <div className="checkbox icheck-success">
                                                                                                <input
                                                                                                    id={info.category}
                                                                                                    name={info.category}
                                                                                                    type="checkbox"
                                                                                                    onChange={(e) => {
                                                                                                        handleAllChecked(e, info.category)
                                                                                                    }}
                                                                                                    checked={(_.isObject(selectedIds) && !_.isEmpty(selectedIds) && !_.isEmpty(info.lists) && Object.keys(selectedIds).length === info.lists.length) ? true : false}
                                                                                                />
                                                                                                <label htmlFor={info.category}><Translate text={'Select All'} /></label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <></>
                                                                            }

                                                                            {
                                                                                (_.isObject(selectedIds) && !_.isEmpty(selectedIds))
                                                                                    ?
                                                                                    <div className={`file-manager-action d-inline-block mb-3 float-right`}>

                                                                                        <div className="float-right ml-2">
                                                                                            <a href="#" className="btn btn-checklist-yellow active" onClick={(e) => { e.preventDefault(); handleSelectChoice('N/A') }}>
                                                                                                <span>  <Translate text={'N/A'} /></span>
                                                                                            </a>
                                                                                        </div>

                                                                                        <div className="float-right">
                                                                                            <a href="#" className="btn btn-checklist-green active" onClick={(e) => { e.preventDefault(); handleSelectChoice('Pass') }}>
                                                                                                <span>  <Translate text={'Pass'} /></span>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <></>
                                                                            }

                                                                            <div className="common-table" style={divStyle}>
                                                                                <table className="table table-bordered">

                                                                                    <tbody>
                                                                                        {
                                                                                            info.lists && _.orderBy(info.lists, ['title'], ['asc']).map((data, index) => {
                                                                                                return <tr key={index} className={errorFields[data.documentID] ? 'checklist-error' : ''}>
                                                                                                    < td width="85%" >
                                                                                                        {
                                                                                                            props.hasAccess
                                                                                                                ?
                                                                                                                <>
                                                                                                                    <div className="file-manager-check-wrap w-100">
                                                                                                                        <div className="checkbox icheck-success">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                id={`checkbox_${data.documentID}`}
                                                                                                                                name={`checkbox_${data.documentID}`}
                                                                                                                                className="uncheck-activity"
                                                                                                                                checked={(_.isObject(selectedIds) && !_.isEmpty(selectedIds) && selectedIds.hasOwnProperty(data.documentID)) ? true : false}
                                                                                                                                onChange={(e) => {
                                                                                                                                    handleCheckChange(e, data.documentID)
                                                                                                                                }} />
                                                                                                                            <label htmlFor={`checkbox_${data.documentID}`}>{data.name}</label>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                                :
                                                                                                                <>{data.name}</>
                                                                                                        }
                                                                                                    </td>

                                                                                                    <td className="border-right-0" width="15%">

                                                                                                        <div className={`checklist-status-buttons ${props.hasAccess ? '' : 'form-disable'}`}>

                                                                                                            <div className="btn-group btn-group-toggle">
                                                                                                                <label className={`btn btn-checklist-red ${data.choice === recommendationChoice.Required ? 'active' : ''}`}>
                                                                                                                    <InputRadio
                                                                                                                        type="radio"
                                                                                                                        value="Required"
                                                                                                                        name={data.documentID}
                                                                                                                        checked={data.choice && data.choice === recommendationChoice.Required ? true : false}
                                                                                                                        onChange={handleTypeChange}
                                                                                                                    />
                                                                                                                    <Translate text={'Required'} />
                                                                                                                </label>
                                                                                                                <label className={`btn btn-checklist-green ${data.choice === recommendationChoice.Pass ? 'active' : ''}`}>
                                                                                                                    <InputRadio
                                                                                                                        type="radio"
                                                                                                                        value="Pass"
                                                                                                                        name={data.documentID}
                                                                                                                        checked={data.choice && data.choice === recommendationChoice.Pass ? true : false}
                                                                                                                        onChange={handleTypeChange}
                                                                                                                    />
                                                                                                                    <Translate text={'Pass'} />
                                                                                                                </label>
                                                                                                                <label className={`btn btn-checklist-yellow ${data.choice === recommendationChoice.NotAvailable ? 'active' : ''}`}>
                                                                                                                    <InputRadio
                                                                                                                        type="radio"
                                                                                                                        value="N/A"
                                                                                                                        name={data.documentID}
                                                                                                                        checked={data.choice && data.choice === recommendationChoice.NotAvailable ? true : false}
                                                                                                                        onChange={handleTypeChange}
                                                                                                                    />
                                                                                                                    <Translate text={'N/A'} />
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            })
                                                                                        }


                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </TabPane>
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <div className="spinner-loader h-100 p-5 w-100">
                                                            <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                                <div className="no-cafe-img">
                                                                    <i className="ico icon-service-checklist"></i> </div>
                                                                <div className="no-data-txt mt-2"> <Translate text={'No checklist available.'} /></div>
                                                            </div>

                                                        </div>
                                                    </>
                                            }




                                        </Tabs>



                                    </div>


                                </div>
                            </div>
                        </div>

                    </>
                </Modal.Body>
                {
                    !_.isEmpty(checklists) && props.hasAccess
                        ?
                        <>
                            <Modal.Footer>

                                <button
                                    type="button"
                                    className="btn btn-default float-left"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.handleClose(null);

                                    }}
                                ><Translate text={'cancel'} />
                                </button>

                                <button
                                    type="button"
                                    className={`btn btn-primary float-right ${loader ? 'form-disable' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                >
                                    {
                                        loader ?
                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                            : (<></>)
                                    }
                                    <Translate text={'save'} />
                                </button>

                            </Modal.Footer>
                        </>
                        :
                        <>
                        </>
                }


            </Modal >

            <PopUpModal show={recommendationModal.show}>
                <AddRecommendation
                    show={recommendationModal.show}
                    docID={recommendationModal.docID}
                    title={recommendationModal.title}
                    recomm={recommendationModal.data}
                    serviceID={props.serviceID}
                    mandatoryFields={props.mandatoryRecommFields}
                    handleClose={handleRecommendationClose}
                    dealersettings={props.dealersettings}
                    isDontSave={true}
                    serviceVideos={props.serviceVideos}
                    handleVideoOpen={props.handleVideoOpen}
                    deleteVideo={props.deleteVideo}
                >
                </AddRecommendation>
            </PopUpModal>
        </>
    )
})

export default AddCheckList